import {
  styled,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StewartSortLabel from "controls/global/stewart-table/StewartSortLabel";
import { FileActivity } from "entities/UIModel";
import React from "react";
import {
  colors,
  padding,
} from "theme/defaultStyle";
import { Order } from "utils/sorting";

interface Props {
  order: Order;
  orderBy: keyof FileActivity;
  onRequestSort: (property: keyof FileActivity) => void;
}

interface HeadCell {
  id: keyof FileActivity;
  label: string;
  width: string;
}

const StyledTableHead = styled(TableHead)({
  "& .MuiTableRow-root": {
    height: "36px",
    "& th": {
      border: "none",
      paddingBottom: padding.zero,
      paddingLeft: padding.medium1,
      paddingRight: padding.zero,
      paddingTop: padding.zero,
    },
  },
});

const StyledTitle = styled("div")({
  color: colors.black,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const headCells: HeadCell[] = [
  { id: "description", label: "Activity", width: "40%" },
  { id: "userName", label: "User", width: "20%" },
  { id: "date", label: "Date/Time", width: "20%" },
  { id: "originationSource", label: "Source", width: "20%" },
];

const StyledTableCell = styled(TableCell)({
  fontSize: 16,
  fontWeight: 600
});

const FileHistoryHead = ({ order, orderBy, onRequestSort }: Props) => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((cell) => (
          <StyledTableCell key={cell.id} style={{ width: cell.width }}>
            <StewartSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : "asc"}
              onRequestSort={() => onRequestSort(cell.id)}
            >
              <StyledTitle>{cell.label}</StyledTitle>
            </StewartSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export default FileHistoryHead;
