import IosShareIcon from "@mui/icons-material/IosShare";
import {
  Box,
  Button,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AdvancedSearch from "controls/global/advanced-search/AdvancedSearch";
import useDocumentStore from "utils/context/ImageArchiveContext";

import ColumnsConfig, {
  MenuColumnItem,
} from "controls/global/columns-config/ColumnsConfig";
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  colors,
  gaps,
  iconSize,
  padding,
} from "theme/defaultStyle";
import {
  PERMISSIONS,
  PageType,
} from "utils/data/enum";
import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";
import { ImageArchiveSearchCriteria } from "entities/ApiModel/ImageArchiveSearchCriteria";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface Props {
  columns: MenuColumnItem<ImageArchiveUI>[];
  hiddenColumns: (keyof ImageArchiveUI)[];
  updateHiddenColumns?: (columns: (keyof ImageArchiveUI)[]) => void;
  handleAdvancedFilterChange: (
    name: keyof ImageArchiveSearchCriteria,
    value: any,
    execSearch: boolean
  ) => void;
  handleAdvanceSearchPanelOpen: (open: boolean) => void;
  storedCriteria?: any;
  onExport?: () => void;
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  marginTop: "-56px",
});

const ActionButtonGroup = styled(Box)({
  display: "flex",
  gap: gaps.large1,
  justifyContent: "flex-end",
  "& .MuiButton-root": {
    color: colors.blue01,
    display: "flex",
    gap: gaps.small1,
    paddingBottom: padding.xsmall1,
    paddingLeft: padding.xsmall2,
    paddingRight: padding.xsmall2,
    paddingTop: padding.xsmall1,
    textTransform: "none",
    "&.Mui-disabled": {
      background: colors.white,
      color: colors.blue12,
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      fontSize: iconSize.medium,
    },
    "& .rotate-90": {
      transform: "rotate(90deg)"
    },
  },
});

const ImageArchiveHeader = ({
  columns,
  hiddenColumns,
  updateHiddenColumns,
  handleAdvancedFilterChange,
  handleAdvanceSearchPanelOpen,
  onExport,
  storedCriteria,

}: Props) => {
  const [{ isEditMode, showToast }, { setAdvanceSearch, setMaxDate, setMinDate, setShowToast, setColumnDefinition }] = useDocumentStore();
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState<boolean>(false);
  const advancedButtonRef: RefObject<HTMLButtonElement> = useRef(null);
  const handleSearchPanel = (open: boolean) => {
    setAdvanceSearch(open)
    setAdvancedSearchOpen(open);
    handleAdvanceSearchPanelOpen(open);
    if (!open && showToast) setShowToast(false)
  };

  // Permission
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  useEffect(() => {
    setMinDate(null);
    setMaxDate(null);
  }, [advancedSearchOpen]);

  const handleColumnChecked = (id: keyof ImageArchiveUI, checked: boolean) => {
    if (checked) {
      updateHiddenColumns?.([...hiddenColumns, id]);
    } else {
      updateHiddenColumns?.([...hiddenColumns].filter((col) => col !== id));
    }
    setColumnDefinition([id], "hidden", checked);
  };
  const handleHideAllColumns = () => {
    if (hiddenColumns.length === columns.length) return;
    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.(fieldIds);
    setColumnDefinition(fieldIds, "hidden", true);
  };
  const handleShowAllColumns = () => {
    if (hiddenColumns.length === 0) return;
    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.([]);
    setColumnDefinition(fieldIds, "hidden", false);
  };

  const handleExport = () => {
    onExport?.();
  }

  return (
    <Container>
      <ActionButtonGroup>
        <Button
          disabled={isEditMode}
          style={{
            backgroundColor: advancedSearchOpen ? colors.blue13 : colors.white,
            opacity: 1
          }}
          onClick={() => handleSearchPanel(!advancedSearchOpen)}
          data-test="myFilesAdvanced"
          ref={advancedButtonRef}
        >
          <>
            Advanced
            <SearchIcon />
          </>
        </Button>
        <ColumnsConfig
          isDisabled={isEditMode}
          options={columns}
          onChecked={handleColumnChecked}
          onHideAll={handleHideAllColumns}
          onShowAll={handleShowAllColumns}
        />
        <Button
          disabled={!permissionCodes?.includes(PERMISSIONS.EXPORT_DATA_PORTAL) || isEditMode}
          style={{
            opacity: 1
          }}
          onClick={() => handleExport()}
        >
          <>
            Export
            <IosShareIcon
              className="icon rotate-90" />
          </>
        </Button>
      </ActionButtonGroup>
      <AdvancedSearch
        isDisableFilters={isEditMode}
        key={`adv_search_${advancedSearchOpen}`}
        open={advancedSearchOpen}
        handleFilterChange={advancedSearchOpen ? handleAdvancedFilterChange : () => { }}
        page={PageType.ImageArchive}
        defaultValues={storedCriteria}
      />
    </Container>
  );
};

export default ImageArchiveHeader;
