import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

interface State {
  isModalOpen: boolean;
  acceptedModal?: boolean;
  modalMessage?: string;
  propertyToClear?: string;
}

type StoreApi = StoreActionApi<State>;

const setIsModalOpen =
  () =>
    ({ setState }: StoreApi) => {
      setState({ isModalOpen: true });
    };
const setMessage =
  (modalMessage: string) =>
    ({ setState }: StoreApi) => {
      setState({ modalMessage: modalMessage });
    };

const onModalAccept =
  () =>
    ({ setState }: StoreApi) => {
      setState({ acceptedModal: true, isModalOpen: false });
    };

const onModalCancel =
  () =>
    ({ setState }: StoreApi) => {
      setState({ isModalOpen: false });
    };

const resetDefaultValues =
  () =>
    ({ setState }: StoreApi) => {
      setState({
        acceptedModal: false,
        isModalOpen: false,
        propertyToClear: undefined,
      });
    };

const setPropertyToClear =
  (property: string) =>
    ({ setState }: StoreApi) => {
      setState({
        propertyToClear: property,
      });
    };

const Store = createStore<State, any>({
  initialState: {
    isModalOpen: false,
    acceptedModal: false,
    propertyToClear: undefined,
  },
  actions: {
    setMessage,
    setIsModalOpen,
    onModalAccept,
    onModalCancel,
    resetDefaultValues,
    setPropertyToClear,
  },
  name: "ModalContext",
});

const hook = createHook(Store);
export const useModalContext = () => { return hook(); };
