import {
  styled,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from "@mui/material";
import { FileActivity } from "entities/UIModel";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import {
  formatCurrency,
  formatDateTime,
} from "utils/shared";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import LightTooltip from "controls/global/light-tooltip";

declare module "@mui/material/TableCell" {
  interface TableCellProps {
    minWidth?: string;
    maxWidth?: string;
  }
}

interface Props {
  record?: FileActivity;
}

const StyledNoRow = styled(TableRow)({
  backgroundColor: colors.white,
  verticalAlign: "top",
});

const StyledTableCell = styled(TableCell)((props: TableCellProps) => ({
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  minWidth: props.minWidth,
  maxWidth: props.maxWidth,
  whiteSpace: "pre-line",
}));

const ItalicBox = styled("div")({
  display: "inline-block",
  fontStyle: "italic",
})

const TruncatedTextCell = styled(Typography)((props: TableCellProps) => ({
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
   maxWidth: "calc(100% - 16px)",
}));

const DenotedBox = styled("span")({
  paddingLeft: padding.xsmall,
});

const InlineContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
});

const FileHistoryBodyRow = ({ record }: Props) => {
  if (!record)
    return (
      <StyledNoRow>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledNoRow>
    );

  const { date, userName, fileActivityID, description } = record;

  const formattedUser = () => userName.replaceAll("|", " ");

  const formatPricing = (recordDescription: string) => {
    const dollarSignPos = recordDescription.indexOf("$");

    if (dollarSignPos > 0) {
      const decimalPos = recordDescription.indexOf(".");

      if (decimalPos > 0) {
        const price = recordDescription.substring(dollarSignPos, decimalPos + 3);
        if(isNaN(Number(price.substring(1)))) {
          return recordDescription
        }
        const formattedPrice = formatCurrency(Number(price.substring(1)));
        const newDescription = recordDescription.replace(price, formattedPrice);
        return newDescription;
        
      } else {
        return recordDescription;
      }
    } else {
      return recordDescription;
    }
  };

  const emphasizedText = (text1: string, text2: string) => {
    const primaryText = text1.split("|");
    const italics = <ItalicBox>{text2}</ItalicBox>;

    if (primaryText.length === 2) {
      return (
        <>
          <div>{primaryText[0]}</div>
          <div style={{ whiteSpace: "nowrap" }}>
            {primaryText[1]}
            {italics}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {primaryText[0]}
            {italics}
          </div>
        </>
      );
    }
  };

  const regularText = (text1: string) => {
    return <span>{text1}</span>;
  };

  const activityText = () => {
    const italicizeText = formatPricing(description).split("~");
    return italicizeText.length === 3
      ? emphasizedText(italicizeText[0], italicizeText[1])
      : regularText(italicizeText[0].replaceAll("|", " "));
  };

  return (
    <TableRow key={fileActivityID}>
      {record.forStaffViewOnly ? (
        <TableCell>
          <InlineContainer>
            <TruncatedTextCell>
              <TooltipCell title={activityText()} />
            </TruncatedTextCell>
            <LightTooltip
              title={"Visible to Stewart staff only"}
              arrow
            >
              <DenotedBox>*</DenotedBox>
            </LightTooltip>
          </InlineContainer>
        </TableCell>
      ) : (
        <StyledTableCell minWidth="400px" maxWidth="calc(40vw)">
          <TooltipCell title={activityText()} />
        </StyledTableCell>
      )}

      <StyledTableCell>{formattedUser()}</StyledTableCell>
      <StyledTableCell>{formatDateTime(date, false)}</StyledTableCell>
      <StyledTableCell>{record.originationSource}</StyledTableCell>
    </TableRow>
  );
};

export default FileHistoryBodyRow;
