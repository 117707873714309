import { styled } from "@mui/material";
import { ZipCodeResponse } from "entities/UIModel";
import React, {
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import theme from "theme/default";
import { gaps } from "theme/defaultStyle";
import { useEsb } from "utils/context/EsbContext";
import { usePricingActions } from "utils/context/PricingContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import { FullAddressProps } from "utils/interfaces/BaseAddress";
import AddressCheckboxes from "./AddressCheckboxes";
import AddressOneField from "./AddressOneField";
import AddressTwoField from "./AddressTwoField";
import CityField from "./CityField";
import CountyField from "./CountyField";
import StateField from "./StateField";
import ZipCodeField from "./ZipCodeField";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  "& > *": {
    flexBasis: "content",
    flexGrow: 1,
    flexShrink: 1,
  },
  "& > .address-one": {
    minWidth: "16.67%",
    maxWidth: "16.67%",
  },
  "& > .address-two": {
    maxWidth: "500px",
    minWidth: "150px",
  },
  "& > .city": {
    maxWidth: "300px",
    minWidth: "190px",
  },
  "& > .state": {
    maxWidth: "92px",
    minWidth: "92px",
  },
  "& > .county": {
    maxWidth: "190px",
    minWidth: "190px",
  },
  "& > .zip-code": {
    maxWidth: "120px",
    minWidth: "120px",
  },
  "& > .address, & > .city, & > .state, & > .zip-code, & > .county": {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
});

const FullAddress = ({
  schema,
  isPropertySection = true,
  showPropertyCheckbox = false,
  isDisabledAddress = false,
  isStatesDisabled = false,
  addressOneLabelPrefix = "",
  addressTwoLabelPrefix = "",
  cityLabelPrefix = "",
  stateLabelPrefix = "",
  zipCodeLabelPrefix = "",
  addressOneLabelSuffix = "",
  addressTwoLabelSuffix = "",
  cityLabelSuffix = "",
  stateLabelSuffix = "",
  zipCodeLabelSuffix = "",
  callbackIndex,
  showForeignAddressCheckbox = false,
  onForeignAddressChange,
  onBlur,
  setNotDefault,
  verifyAddressHandler
}: FullAddressProps) => {
  const { register, getValues, setValue } = useFormWrapper();
  const [, { getZipCode }] = useEsb();
  const [, { setPricingRecalculateRequired }] = usePricingActions();

  const stateFieldName = getNameString(`${schema}.state`);
  const cityFieldName = getNameString(`${schema}.city`);
  const zipCodeFieldName = getNameString(`${schema}.zipCode`);
  const addressOneFieldName = getNameString(`${schema}.addressOne`);
  const addressTwoFieldName = getNameString(`${schema}.addressTwo`);
  const countyFieldName = getNameString(`${schema}.county`);
  const taxCodeFieldName = getNameString(`${schema}.taxCode`);
  const isForeignAddressName = getNameString(`${schema}.isForeignAddress`);

  const [isForeignAddress] = useWatch({
    name: [isForeignAddressName],
  });

  const [currentZipcodeResults, setCurrentZipcodeResults] = useState<ZipCodeResponse>();

  const resetZipcodeData = () => {
    setCurrentZipcodeResults(undefined);
  };

  const getZipcodeData = async (zipCode: string, invokedExplicitly: boolean = false, selectedState: string = "") => {
    if (isPropertySection && invokedExplicitly) {
      setValue(`${schema}.county`, { code: "", name: "" });
    }

    const currentPropertyState = getValues(stateFieldName);
    if(currentPropertyState?.abbr) {
      selectedState = currentPropertyState.abbr;
    }
    const zipCodeResult = await getZipCode(zipCode, selectedState);    
    if (!zipCodeResult) return;    
    setCurrentZipcodeResults(zipCodeResult);
  };

  const handleAutocompleteSelected = async () => {
    const [zipCode,statevalue] = getValues([zipCodeFieldName, stateFieldName]);    
    await getZipcodeData(zipCode, false, statevalue?.abbr);
  };

  const handleZipCodeChange = (value: string) => {
    setNotDefault && setNotDefault();
  };

  // This function to enable the calculate button for only TN when county field value change
  const enbaleCalculateButtonCheck = () => {
    if (setNotDefault) setNotDefault();
    const isPrimaryCounty = countyFieldName === 'properties.0.county';
    const isPrimaryStateTN = getValues('properties.0.state.abbr') === 'TN';
    if (isPrimaryStateTN && isPrimaryCounty) setPricingRecalculateRequired(true);
  };

  return (
    <>
      {(showPropertyCheckbox || showForeignAddressCheckbox) &&
        <AddressCheckboxes
          schema={schema}
          showPropertyCheckbox={showPropertyCheckbox}
          showForeignAddressCheckbox={showForeignAddressCheckbox}
          callbackIndex={callbackIndex}
          isDisabledAddress={isDisabledAddress}
          onForeignAddressChange={onForeignAddressChange}
          onBlurHandler={onBlur}
        />
      }
      {!isForeignAddress && (
        <Container>
          <div className="address address-one">
            <AddressOneField
              setNotDefault={setNotDefault}
              onBlur={onBlur}
              name={addressOneFieldName}
              schema={schema}
              labelPrefix={addressOneLabelPrefix}
              isDisabled={isDisabledAddress}
              isPropertySection={isPropertySection}
              addressTwoFieldName={addressTwoFieldName}
              stateFieldName={stateFieldName}
              cityFieldName={cityFieldName}
              zipCodeFieldName={zipCodeFieldName}
              onAutocompleteSelected={handleAutocompleteSelected}
              verifyAddressHandler={verifyAddressHandler}
            />
          </div>
          <div className="address address-two">
            <AddressTwoField
              name={addressTwoFieldName}
              label={`${addressTwoLabelPrefix} Address Line 2`}
              labelPrefix={addressTwoLabelPrefix}
              labelSuffix={addressTwoLabelSuffix}
              isDisabled={isDisabledAddress}
              onBlur={onBlur}
              onChange={setNotDefault}
            />
          </div>
          <div className="city">
            <CityField
              name={cityFieldName}
              disabled={isDisabledAddress}
              onChange={setNotDefault}
              zipCodeResults={currentZipcodeResults}
              onBlur={onBlur}
            />
          </div>
          <div className="state">
            <StateField
              name={stateFieldName}
              labelPrefix={stateLabelPrefix}
              isDisabled={isDisabledAddress || isStatesDisabled}
              isPropertySection={isPropertySection}
              autosetValue={false}
              zipCodeResults={currentZipcodeResults}
              resetZipcodeData={resetZipcodeData}
              setNoDefault={setNotDefault}
              onBlur={onBlur}
            />
          </div>
          <div className="zip-code">
            <ZipCodeField
              name={zipCodeFieldName}
              labelPrefix={zipCodeLabelPrefix}
              isDisabled={isDisabledAddress}
              isPropertySection={isPropertySection}
              getZipcodeData={getZipcodeData}
              onChange={handleZipCodeChange}
              onBlur={onBlur}
            />
          </div>

          {isPropertySection && (
            <div className="county">
              <CountyField
                name={countyFieldName}
                labelPrefix={cityLabelPrefix}
                isDisabled={isDisabledAddress}
                isPropertySection={isPropertySection}
                onChange={enbaleCalculateButtonCheck}
                zipCodeResults={currentZipcodeResults}
              />
            </div>
          )}
          <input type="hidden" {...register(`${taxCodeFieldName}` as const)} />
        </Container>
      )}
    </>
  );
};

export default FullAddress;
/* eslint-disable @typescript-eslint/no-unused-vars */
