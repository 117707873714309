import CloseIcon from "@mui/icons-material/Close";
import {
    Box,    
    IconButton,
    styled,
    TextField,
} from "@mui/material";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import {
    colors,
    fontSize,
    margin,
    padding,
} from 'theme/defaultStyle';

interface Props {
    name: keyof ReportAndPayCriteriaSearch;
    filterValue?: any;
    disabled?: boolean;
    onChange: (name: keyof ReportAndPayCriteriaSearch, value: string | null) => void;
    inputType?: string;
    label?: string;
    defaultValue?: string; 
}

const StyledInputSearchBox = styled(Box)({
    height: "40px",
    marginBottom: margin.zero,
    paddingRight: padding.zero,
});

const StyledInputSearch = styled(TextField)({
    height: "40px",
    lineHeight: "40px",
    paddingLeft: padding.xsmall2,
    "& label.MuiFormLabel-root.MuiInputLabel-formControl": {
        fontSize: fontSize.large,
        height: "40px",
        lineHeight: "40px",
        paddingLeft: padding.xsmall2,
        transform: "none",
        "&.MuiInputLabel-shrink": {
            transform: "translate(2px, -12px) scale(0.75)",
            fontSize: fontSize.small,
        },
    },
    "& .MuiInputBase-root.MuiInput-underline": {
        lineHeight: "40px",
        marginTop: margin.zero,
        "&.Mui-focused": {
            "&::before": {
                borderBottomStyle: "none",
            },
        },
        "&.Mui-disabled": {
            "&::before": {
                borderBottomColor: colors.grey09,
                bottom: "-1px",
            },
        },
        "&::before": {
            borderBottomStyle: "solid",
            left: `-${padding.xsmall2}`,
        },
        "&::after": {
            bottom: "-1px",
            left: `-${padding.xsmall2}`,
        },
        "& .MuiInput-input": {
            height: "19px",
            paddingBottom: padding.small2,
            paddingTop: padding.small2,
        },
    },
    "& button.MuiIconButton-root": {
        padding: padding.zero,
        color:colors.blue01,
    },
});

const InputSearch = ({ name, filterValue, disabled, onChange, inputType = "text", label = "Search", defaultValue }: Props) => {
    const [value, setValue] = useState<string>(() => {
        if (defaultValue !== undefined) return defaultValue;
        if (filterValue !== undefined) return filterValue;
        return "";
    });
    const searchInputRef = useRef<HTMLInputElement>(null);

    const clearInput = () => {
        setValue("");
        if (searchInputRef.current) {
            searchInputRef.current.value = "";
            const event = new Event('input', { bubbles: true });
            Object.defineProperty(event, 'target', {
                value: searchInputRef.current,
                enumerable: true,
            });
            searchInputRef.current.dispatchEvent(event);
            const changeEvent = {
                target: searchInputRef.current,
            } as React.ChangeEvent<HTMLInputElement>;
            handleInputChange(changeEvent);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name as keyof ReportAndPayCriteriaSearch;
        const currentValue = event.target.value; // .trim() ? event.target.value.trim() : null;
        if (value !== event.target.value.trim()) {
            onChange(name, currentValue);
        }
        setValue(currentValue || "");
    };

    useEffect(() => {
        if (disabled) {
            clearInput();
        }
    }, [disabled]);

    return (
        <StyledInputSearchBox className={disabled ? "disabled-input" : ""}>
            <StyledInputSearch
                {...{ name }}
                id={name}
                onChange={handleInputChange}
                fullWidth
                disabled={disabled}
                label={label}
                defaultValue={defaultValue !== undefined ? defaultValue : filterValue !== undefined ? filterValue : ""}
                inputRef={searchInputRef}
                InputProps={{
                    endAdornment: !!value && (
                        <IconButton onClick={clearInput}>
                            <CloseIcon />
                        </IconButton>
                    ),
                }}
                inputProps={{ type: inputType }}
                InputLabelProps={{
                    shrink: !!value
                }}
                variant="standard"
            />
        </StyledInputSearchBox>
    );
};

export default InputSearch;
