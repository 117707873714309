import SelectFieldWithClearIcon from "controls/global/select-field/SelectFieldWithClearIcon";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import ProductOption from "entities/UIModel/company/ProductOption";
import { isEqual } from "lodash";
import React from "react";
import { useAdditionalPartySearchAction } from "utils/context/AdditionalPartySearchContext";
import { usePartyTypes } from "utils/context/PartyTypesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useProductOption from "utils/custom-hooks/useProductOption";
import {
  PartyType,
  RuleCondition,
} from "utils/data/enum";

type Props = {
  schema: string;
  disabled?: boolean;
};

const getInternalPartyTypes = (
  additionalPartyTypes: SelectFieldOption[],
  letterProduct: ProductOption | undefined
) => {
  return additionalPartyTypes.filter(
    (pt) =>
      (pt.value === PartyType.Attorneys &&
        letterProduct?.approvedAttorney === RuleCondition.IsAllowed) ||
      (pt.value === PartyType.SecondaryAgency &&
        letterProduct?.secondaryAgency === RuleCondition.IsAllowed) ||
      (pt.value === PartyType.SettlementCompany &&
        letterProduct?.settlementCompany === RuleCondition.IsAllowed)
  );
};

export default function PartyTypeCode({ schema, disabled }: Props) {
  const additionalPartyTypesRef = React.useRef<SelectFieldOption[]>([]);
  const { setValue } = useFormWrapper();
  const [, { reset }] = useAdditionalPartySearchAction();
  const [{ additionalPartyTypes }] = usePartyTypes();
  const { getLetterProduct } = useProductOption();
  const letterProduct = getLetterProduct();

  const internalPartyTypes = getInternalPartyTypes(
    additionalPartyTypes,
    letterProduct
  );

  const onChangePartyType = () => {
    reset();

    setValue(`${schema}.addressOne`, "");
    setValue(`${schema}.addressTwo`, "");
    setValue(`${schema}.city`, "");
    setValue(`${schema}.state`, { code: "", abbr: "" });
    setValue(`${schema}.zipCode`, "");
    setValue(`${schema}.companyName`, "");
    setValue(`${schema}.individualAttorney`, "");
    setValue(`${schema}.favorite`, false);
    setValue(`${schema}.selectedAdditionalPartyID`, "");
    setValue(`${schema}.isDirty`, true);
    setValue(`${schema}.filePartyId`, 0);
    setValue(`${schema}.altReferenceID`, "");
    setValue(`${schema}.locationID`, "");
  };

  React.useEffect(() => {
    if (internalPartyTypes.length !== 1) return;
    if (isEqual(additionalPartyTypesRef.current, internalPartyTypes)) return;

    additionalPartyTypesRef.current = internalPartyTypes;
    setValue(`${schema}.partyTypeCode`, internalPartyTypes[0].value);
  }, [internalPartyTypes, schema, setValue]);

  return (
    <SelectFieldWithClearIcon
      options={[{ text: "", value: "" }, ...internalPartyTypes]}
      name={`${schema}.partyTypeCode`}
      label="Party Type"
      allowsDelete={true}
      onChange={onChangePartyType}
      disabled={disabled}
    />
  );
}
