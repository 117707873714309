import { styled } from "@mui/material";
import AddLink from "controls/global/add-link";
import { useSignatureDispatchProvider } from "pages/signatures/providers/manage-signature/SignatureProvider";
import React from "react";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

const LinkContainer = styled("div")({
  marginRight: "auto",
});

export default function AddSignatureLink() {
  const { dispatch } = useSignatureDispatchProvider();
  const handleAddSignature = () => dispatch({ type: "OPEN", mode: "ADD" });
  const [{ profileSettings }] = useProfileSettingsCache();

  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  const hasAddSignatureAccess = permissionCodes?.includes(PERMISSIONS.SIGNATURES_ADD);
  
  return (
    <LinkContainer>
      <AddLink
        disabled={!hasAddSignatureAccess}
        onClick={handleAddSignature}
      >
        Add Signature
      </AddLink>
    </LinkContainer>
  );
}
