import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ConfirmationDialogV3 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV3";
import * as yup from "yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { borderRadius, borderSize, colors, fontSize, fontWeight, gaps, margin, padding } from "theme/defaultStyle";
import StewartTable, { StewartTableColumn } from "controls/global/stewart-table/StewartTable";
import { Box, IconButton, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "theme/icons/ViewIcon";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerSearch from "controls/global/datepicker-search";
import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";
import { ImageArchiveSearchCriteria } from "entities/ApiModel/ImageArchiveSearchCriteria";
import { Order } from "utils/sorting";
import InputSearch from "./InputSearch";
import useDocumentStore from "utils/context/ImageArchiveContext";
import { ActionResultStatus, PERMISSIONS, PageType, UIConstants } from "utils/data/enum";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import CustomTooltip from "controls/global/custom-tooltip";
import CustomTextField from "controls/global/text-input-field/CustomTextField";
import { ActionResult, NavigateToInfo, PdfDocument } from "entities/UIModel";
import { useDocument } from "utils/context/DocumentContext";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import { getCorrelationId } from "pages/file/utils/helper";
import PdfViewer from "controls/global/pdf-viewer";
import { IColumn } from "entities/ApiModel/IColumn";
import { getColumnConfig } from "utils/columnsGrid";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import ConfirmationDialogV2 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV2";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import { getNavigationInfo } from "utils/services/utilsService";

type ImageArchiveGridProps = {
  page: number;
  rows: ImageArchiveUI[];
  onFiltersChange: (name: keyof ImageArchiveSearchCriteria, value: any) => void;
  orderBy?: keyof ImageArchiveUI;
  order: Order;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (count: number) => void;
  rowsPerPage: number;
  noRowsMessage: string[];
  onRequestSort?: (property: any, disableToggling?: boolean) => void;
  performSearch?: () => Promise<void>;
  onColumnResize: (column: any, newWidth: number) => void;
  colsConfig: IColumn[];
  hiddenColumns?: (keyof ImageArchiveUI)[];
};

const MAIN_MESSAGE = "Save changes?";
const ADDITIONAL_MESSAGE = 'Click "Save" to save your changes. Click "Continue" to navigate away without saving.';
const ADDITIONAL_MESSAGE2 =
  'Click "Save & Exit" to save your changes and exit. Click "Continue" to exit without saving.';

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& table.MuiTable-root": {
    "& th.MuiTableCell-root.MuiTableCell-head": {
      color: colors.grey08,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal2,
      "& div.MuiBox-root": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
      },
      "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
        border: 0,
        borderBottom: `1px solid ${colors.grey15}`,
        borderRadius: 0,
        "&:focus-within": {
          borderBottom: `2px solid ${colors.blue01}`,
        },
        "& fieldset": {
          border: 0,
        },
      },
    },
    "& thead": {
      "& tr th div.MuiFormControl-root": {
        width: "100%",
        marginLeft: margin.small1_5,
      },
      "& tr th label.MuiFormLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(14px, 11px) scale(1)",
      },
      "& .MuiTableRow-root.header-actions th": {
        paddingRight: padding.large2,
      },
      "& tr.header-actions th:nth-last-child(1)": {
        boxShadow: "5px 0 2px -2px rgba(87, 87, 87, 0.10) inset",
      },
      "& tr th input": {
        padding: "6px 3px",
      },
    },
    "& tbody": {
      "& tr:not(.editing-row):hover td": {
        background: colors.grey10,
      },
      "& tr td.emptyCell": {
        paddingLeft: "20px",
        boxShadow: "none",
      },
      "& td:not(.emptyCell)": {
        maxWidth: "75px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& tr:last-child": {
        "& td:not(.emptyCell)": {
          borderBottom: `1px solid ${colors.grey09}`,
        },
        "& td:not(.emptyCell):first-child": {
          borderBottomLeftRadius: borderSize.zero,
        },
        "& td:not(.emptyCell):last-child": {
          borderBottomRightRadius: borderSize.zero,
        },
      },
      "& td:nth-last-child(1)": {
        boxShadow: "5px 0 2px -2px rgba(87, 87, 87, 0.10) inset",
        paddingLeft: padding.zero,
        "& div": {
          marginLeft: margin.small2,
          "& button": {
            width: "23px",
          },
        },
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
      "& button.MuiButtonBase-root": {
        margin: `${margin.xxsmall1} ${margin.small1}`,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: `1px solid ${colors.grey09}`,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: `1px solid ${colors.grey09}`,
      borderRight: `1px solid ${colors.grey09}`,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
  "& > .MuiTableContainer-root": {
    maxHeight: "100px",
    minHeight: "auto",
  },
});
const imageArchiveGridSchema = yup.object().shape({
  documentDisplayFileName: yup.string().trim().nullable().required("Required Field"),
});

const ImageArchiveGrid: React.FC<ImageArchiveGridProps> = ({
  page,
  rows,
  onFiltersChange,
  orderBy,
  order,
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  noRowsMessage,
  onRequestSort,
  performSearch,
  onColumnResize,
  colsConfig,
  hiddenColumns,
}) => {
  const methods = useForm({
    resolver: yupResolver(imageArchiveGridSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { setValue, getValues, resetField } = methods;
  const [, { saveDocumentSupplemental }] = useDocumentStore();
  const [rowInEditMode, setRowInEditMode] = useState<any>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [, { deleteDocumentSupplemental }] = useDocumentStore();
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const searchPermission = permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_SEARCH);
  const [idToDelete, setIdToDelete] = useState<number>();
  const [, { getSupplementalDocumentUrl }] = useDocument();
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>("");
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);

  const [openSaveErrorDialog, setOpenSaveErrorDialog] = useState<boolean>(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState<string>("");


  const [{ minDate, maxDate }] = useDocumentStore();
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [{ error, isEditMode }, { setShowToast, setEditMode }] = useDocumentStore();
  const isEditModeRef = useRef<boolean>(isEditMode);
  const disableSaveRef = useRef<boolean>(disableSave);

  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();
  const [openSaveImageDialog, setOpenSaveImageDialog] = useState<boolean>(false);
  const [openSaveExitImageDialog, setOpenSaveExitImageDialog] = useState<boolean>(false);
  const { navigateAfterSaveChangesDialog } = useNavRedirect();

  const navigateTo = useRef<NavigateToInfo>({});

  const onSaveDialogPoliyImagesHandler = useCallback(
    async (event: Event) => {
      const clickedOnPolicyImagesSaveOnEdit = (document.querySelector("#policyImagesSaveOnEdit") as Element)?.contains(
        event.target as Node,
      );

      const navigationInfo = getNavigationInfo(event);
      if (navigationInfo?.navigateToInfo && navigationInfo.navigateToInfo.navigateToType) {
        navigateTo.current = navigationInfo?.navigateToInfo;
      }

      if (isEditModeRef.current && !disableSaveRef.current) {
        // Notes: cannot use useState variables here due to it's occuring on unmanaged block
        if (clickedOnPolicyImagesSaveOnEdit) {
          await saveUpdatedDocument();
          // await performSearch!();
          return;
        }

        if (
          navigationInfo?.navigateToMenuHome ||
          navigationInfo?.navigateToMenuFiles ||
          navigationInfo?.navigateToMenuCreateFile ||
          navigationInfo?.navigateToMenuPaymentsReportPay ||
          navigationInfo?.navigateToMenuPaymentsPendingPayments ||
          // navigationInfo?.navigateToMenuPolicyImages ||
          navigationInfo?.navigateToMenuStewartResources ||
          navigationInfo?.navigateToMenuProfileSettings ||
          navigationInfo?.navigateToMenuManageSignatures ||
          navigationInfo?.navigateToMenuHelpCenter ||
          navigationInfo?.navigateToMenuLogout
        ) {
          event.preventDefault();
          event.stopImmediatePropagation();
          setOpenSaveExitImageDialog(true);
          removeClickListenerDuringEditing();
          return;
        }

        if (
          navigationInfo?.navigateToGlobalAccessAgency ||
          navigationInfo?.clickedOnChangeGlobalAccessAgency ||
          navigationInfo?.clickedOnRemoveGlobalAccessAgency ||
          navigationInfo?.navigateToPolicyImagesUploadImagesTab
          // navigationInfo?.navigateToPolicyImagesImageArhiveTab
        ) {
          event.preventDefault();
          event.stopImmediatePropagation();
          setOpenSaveImageDialog(true);
          removeClickListenerDuringEditing();
          return;
        }
      }

      // Notes: clicking on Image Archive tab or Policy Images menu-- will stay on same the tab without prompting any save changes question
    },
    [isEditMode, disableSave],
  );

  async function onSaveImageHandler() {
    setOpenSaveImageDialog(false);
    setEditMode(false);
    await saveUpdatedDocument();

    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  async function onSaveExitImageHandler() {
    setOpenSaveExitImageDialog(false);
    setEditMode(false);
    await saveUpdatedDocument();

    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  function onSaveImageCancelHandler() {
    setOpenSaveImageDialog(false);
    if (isEditMode && !disableSave) {
      addClickListenerDuringEditing();
    }
  }

  function onContinueSaveImageHandler() {
    setOpenSaveImageDialog(false);
    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  function onContinueSaveExitImageHandler() {
    setOpenSaveExitImageDialog(false);
    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  function onSaveExitImageCancelHandler() {
    setOpenSaveExitImageDialog(false);
    // setEditMode(false);
    if (isEditMode && !disableSave) {
      addClickListenerDuringEditing();
    }
  }

  const handleEdit = (rowData: ImageArchiveUI) => {
    setEditMode(true);
    rowData.isEdit = true;
    // resetField(`documentDisplayFileName`);
    setRowInEditMode(rowData);
  };

  const handleCancel = (rowData: ImageArchiveUI) => {
    setEditMode(false);
    setDisableSave(true);
    rowData.isEdit = false;
    resetField(`documentDisplayFileName`);
    removeClickListenerDuringEditing();
  };

  async function saveUpdatedDocument() {
    openAutomaticProgressDialog(UIConstants.SAVE_DOCUMENT_IN_PROGRESS_MESSAGE);
    setEditMode(false);

    const fileName = getValues("documentDisplayFileName")?.trim();
    const result: ActionResult = await saveDocumentSupplemental({
      DocumentSupplementalID: rowInEditMode?.rowId,
      DocumentDisplayFileName: `${fileName}.${rowInEditMode?.documentFileTypeCode.toLowerCase()}`,
    });
    
    if (result.status === ActionResultStatus.Success) {
      await performSearch!();
      closeAutomaticProgressDialog();
    }
    else {
      closeAutomaticProgressDialog();
      // show error here 
      const errorMessage =
        result.error?.response?.data 
          ? result.error.response.data
          : result.error.response.statusText;

      setSaveErrorMessage(errorMessage);
      setOpenSaveErrorDialog(true);

    }
  }

  const handleDelete = async (data: ImageArchiveUI) => {
    setOpenConfirmationDialog(true);
    setConfirmationMessage(`Delete '${data.documentDisplayFileName}' ?`);
    setIdToDelete(data.rowId);
  };

  const getPdfDocument = (pdfUrl: string, latestDocumentId: number, documentDisplayFileName: string) => {
    const pdfFilename = `${documentDisplayFileName}`;

    let pdfDocument: PdfDocument = {
      fileId: 0,
      productType: "",
      orderId: 0,
      documentId: latestDocumentId,
      pdfUrl: pdfUrl,
      fileName: pdfFilename,
      showHidden: 0,
    };

    return pdfDocument;
  };

  const handleView = async (data: ImageArchiveUI) => {
    const actionResult: ActionResult = await getSupplementalDocumentUrl(data.rowId, 0);

    if (actionResult && (actionResult.error || !actionResult.documentUrl)) {
      setErrorMessage(UIConstants.VIEW_DOCUMENT_ERROR_MESSAGE);
      setCorrelationId(getCorrelationId(actionResult.error.response?.headers));
      setOpenErrorDialog(true);
      return;
    }

    if (actionResult && actionResult.documentUrl && actionResult.documentId) {
      setPdfDocuments([
        getPdfDocument(actionResult.documentUrl, actionResult.documentId, data.documentDisplayFileName || "document"),
      ]);
      setOpenPdfViewer(true);
    }
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };

  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
    setCorrelationId("");
    if (error?.response?.status === 400) performSearch!();
  };

  const handleYes = async () => {
    setOpenConfirmationDialog(false);
    openAutomaticProgressDialog();
    const result = await deleteDocumentSupplemental(idToDelete || 0);
    // performSearch!();

    if (result.status === ActionResultStatus.Success) {
      await performSearch!();
      closeAutomaticProgressDialog();
    }
    else {
      closeAutomaticProgressDialog();
      // show error here 
      const errorMessage =
        result.error?.response?.data 
          ? result.error.response.data
          : result.error.response.statusText;

      setSaveErrorMessage(errorMessage);
      setOpenSaveErrorDialog(true);
    }
  };

  const handleDialogNo = () => {
    setOpenConfirmationDialog(false);
  };

  const onSaveErrorOkHandler = () => {
    setOpenSaveErrorDialog(false);
    performSearch!();
  };

  const onSaveErrorCancelHandler = () => {
    setOpenSaveErrorDialog(false);
    performSearch!();
  };


  const columns: StewartTableColumn<any>[] = [
    {
      field: "documentDisplayFileName",
      valueGetter: ({ documentDisplayFileName }: any) => <TooltipCell title={documentDisplayFileName}></TooltipCell>,
      actionComponent: () => (
        <InputSearch
          name="documentDisplayFileName"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      left: 0,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "documentDisplayFileName"),
    },
    {
      field: "companyName",
      valueGetter: ({ companyName }: any) => <TooltipCell title={companyName}></TooltipCell>,
      actionComponent: () => (
        <InputSearch
          name="companyName"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "companyName"),
    },
    {
      field: "documentUploadedByUserName",
      valueGetter: ({ documentUploadedByUserName }: any) => (
        <TooltipCell title={documentUploadedByUserName}></TooltipCell>
      ),
      actionComponent: () => (
        <InputSearch
          name="documentUploadedByUserName"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "documentUploadedByUserName"),
    },
    {
      field: "documentUploadedDate",
      valueGetter: ({ documentUploadedDate }: any) => <TooltipCell title={documentUploadedDate}></TooltipCell>,
      actionComponent: () => (
        <DatePickerSearch
          handleDateChange={(date: Date | null) => {
            onFiltersChange("documentUploadedDate", date);
          }}
          disabled={isEditMode || !searchPermission ? true : false}
          minDate={minDate}
          maxDate={maxDate}
          page={PageType.ImageArchive}
        />
      ),
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "documentUploadedDate"),
    },
    {
      field: "companyID",
      actionComponent: () => (
        <InputSearch
          name="companyID"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      valueGetter: ({ companyID }) => <TooltipCell title={companyID} />,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "companyID"),
    },
    {
      field: "locationDisplayName",
      valueGetter: ({ locationDisplayName }: any) => <TooltipCell title={locationDisplayName}></TooltipCell>,
      actionComponent: () => (
        <InputSearch
          name="locationDisplayName"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "locationDisplayName"),
    },
    {
      field: "clientFileID",
      actionComponent: () => (
        <InputSearch
          name="clientFileID"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      valueGetter: ({ clientFileID }) => <TooltipCell title={clientFileID} />,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "clientFileID"),
    },
    {
      field: "documentFileTypeCode",
      actionComponent: () => (
        <InputSearch
          name="documentFileTypeCode"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      valueGetter: ({ documentFileTypeCode }) => <TooltipCell title={documentFileTypeCode} />,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "documentFileTypeCode"),
    },
    {
      field: "documentSize",
      actionComponent: () => (
        <InputSearch
          name="documentSize"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      valueGetter: ({ documentSize }) => <TooltipCell title={documentSize} />,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "documentSize"),
    },
    {
      field: "companyLegacyID",
      valueGetter: ({ companyLegacyID }: any) => <TooltipCell title={companyLegacyID}></TooltipCell>,
      actionComponent: () => (
        <InputSearch
          name="companyLegacyID"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "companyLegacyID"),
    },
    {
      field: "locationLegacyID",
      actionComponent: () => (
        <InputSearch
          name="locationLegacyID"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      valueGetter: ({ locationLegacyID }) => <TooltipCell title={locationLegacyID} />,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "locationLegacyID"),
    },
    {
      field: "locationStateAbbr",
      actionComponent: () => (
        <InputSearch
          name="locationStateAbbr"
          onChange={onFiltersChange}
          disabled={isEditMode || !searchPermission ? true : false}
        />
      ),
      valueGetter: ({ locationStateAbbr }) => <TooltipCell title={locationStateAbbr} />,
      ...getColumnConfig<ImageArchiveUI>(colsConfig, "locationStateAbbr"),
    },
    {
      field: "Actions",
      actionComponent: () => <div style={{ height: "40px", width: "130px" }} />,
      name: "",
      width: 130,
      right: -0.5,
      sticky: true,
      draggable: true,
      resizable: true,
      position: colsConfig.length,
      valueGetter: (rowData: ImageArchiveUI) => (
        <>
          {isEditMode && rowInEditMode.rowId === rowData.rowId ? (
            <Box sx={{ marginLeft: "10px" }}>
              <CustomTooltip
                title="Save"
                placement="top"
                aria-label="save"
                arrow
              >
                <IconButton
                  aria-label="Save"
                  disabled={!methods.formState.isValid || !methods.formState.isDirty}
                  sx={{
                    color: methods.formState.isValid ? colors.blue01 : colors.blue11,
                    "&.Mui-disabled": {
                      color: colors.blue11,
                    },
                  }}
                >
                  <SaveIcon id="policyImagesSaveOnEdit" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip
                title="Cancel"
                placement="top"
                aria-label="save"
                arrow
              >
                <IconButton
                  aria-label="Cancel"
                  onClick={() => handleCancel(rowData)}
                >
                  <CloseIcon sx={{ color: colors.blue01 }} />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : (
            <>
              <Box sx={{ marginLeft: "10px" }}>
                {
                  <CustomTooltip
                    title="Edit"
                    placement="top"
                    aria-label="Edit"
                    arrow
                  >
                    <IconButton
                      onClick={() => handleEdit(rowData)}
                      aria-label="Edit"
                      disabled={
                        rowData.documentIsProcessed === 1 || !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_MODIFY)
                          ? true
                          : isEditMode
                      }
                      sx={{
                        color: colors.blue01,
                        "&.Mui-disabled": {
                          color: colors.blue11,
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </CustomTooltip>
                }
                <CustomTooltip
                  title="View"
                  placement="top"
                  aria-label="View"
                  arrow
                >
                  <IconButton
                    aria-label="View"
                    disabled={
                      !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_VIEWDOCS)
                        ? true
                        : isEditMode
                    }
                    onClick={() => handleView(rowData)}
                    sx={{
                      color: colors.blue01,
                      "&.Mui-disabled": {
                        color: colors.blue11,
                      },
                    }}
                  >
                    <ViewIcon  isDisabled ={ !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_VIEWDOCS)
                        ? true
                        : isEditMode}/>
                  </IconButton>
                </CustomTooltip>
                {
                  <CustomTooltip
                    title="Delete"
                    placement="top"
                    aria-label="Delete"
                    arrow
                  >
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDelete(rowData)}
                      sx={{
                        color: colors.blue01,
                        "&.Mui-disabled": {
                          color: colors.blue11,
                        },
                      }}
                      disabled={
                        rowData.documentIsProcessed === 1 || !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_DELETE)
                          ? true
                          : isEditMode
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CustomTooltip>
                }
              </Box>
            </>
          )}
        </>
      ),
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    const updatedColumns = columns.map((column) => {
      if (column.field === "documentDisplayFileName") {
        return {
          ...column,
          valueGetter: ({ rowId, documentDisplayFileName }: any) =>
            isEditMode && rowInEditMode.rowId === rowId ? (
              <CustomTextField
                name={`documentDisplayFileName`}
                defaultValue={documentDisplayFileName.split(".").slice(0, -1).join(".")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setValue(`documentDisplayFileName_${rowId}`, event.target?.value)
                }
                maxLength={256-(rowInEditMode.rowId === rowId ? rowInEditMode?.documentFileTypeCode.toLowerCase()?.length+1 :0)}
              />
            ) : (
              <TooltipCell title={documentDisplayFileName}></TooltipCell>
            ),
        };
      }
      return column;
    });
    setTableColumns(updatedColumns);
  }, [isEditMode, minDate, maxDate, colsConfig]);

  function addClickListenerDuringEditing() {
    document.addEventListener("click", onSaveDialogPoliyImagesHandler, true);
  }

  function removeClickListenerDuringEditing() {
    document.removeEventListener("click", onSaveDialogPoliyImagesHandler, true);
  }

  useEffect(() => {
    if (isEditMode && !disableSave) {
      addClickListenerDuringEditing();
    } else {
      removeClickListenerDuringEditing();
    }
    return () => {
      removeClickListenerDuringEditing();
    };
  }, [isEditMode, disableSave]);

  useEffect(() => {
    resetField(`documentDisplayFileName`);
  }, [rowInEditMode, isEditMode]);

  useEffect(() => {
    setEditMode(false);
    return () => {
      setShowToast(false);
      setEditMode(false);
    };
  }, []);

  useEffect(() => {
    setDisableSave(!methods.formState.isValid || !methods.formState.isDirty);
  }, [methods.formState]);

  useEffect(() => {
    isEditModeRef.current = isEditMode;
  }, [isEditMode]);

  useEffect(() => {
    disableSaveRef.current = disableSave;
  }, [disableSave]);

  useEffect(() => {
    const visibleCols =
      hiddenColumns && hiddenColumns.length > 0
        ? tableColumns.filter((c: any) => !hiddenColumns.includes(c.field))
        : tableColumns;

    const visibleColumnsCount = visibleCols?.length;
    const columConfig =  getColumnConfig<ImageArchiveUI>(colsConfig, "documentDisplayFileName")
    const updatedColumns = columns.map((column) => {
      if (column.field === "documentDisplayFileName") {
        return {
          ...column,
          width: visibleColumnsCount <= 5 ? undefined: columConfig.width,
        };
      }
      return column;
    });
    setTableColumns(updatedColumns);    
  },[hiddenColumns]);
  return (
    <>
      <FormProvider {...methods}>
        <StyledTableContainer
          width={1}
          className={isEditMode ? "grid-edit-mode" : ""}
        >
          <StewartTable
            cols={tableColumns}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            showActionRow={true}
            noRowsMessage={noRowsMessage}
            orderBy={orderBy}
            order={order}
            useDbPagingSorting={true}
            onRequestSort={onRequestSort}
            onColumnResize={onColumnResize}
            hiddenColumns={hiddenColumns}
          />
          <StewartTableFooterPaginator
            editMode={isEditMode}
            totalRows={rows[0]?.totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            totalPages={rows[0]?.totalPages}
          />
        </StyledTableContainer>
      </FormProvider>
      <ConfirmationDialogV2
        isOpen={openSaveImageDialog}
        mainMessage={MAIN_MESSAGE}
        additionalMessage={ADDITIONAL_MESSAGE}
        singleActionButtonText="Save" //"Save"
        onYes={onSaveImageHandler} //{onSaveHandler}
        onNo={onContinueSaveImageHandler}
        buttonNoText={"Continue"}
        onCancel={onSaveImageCancelHandler}
      />
      <ConfirmationDialogV2
        isOpen={openSaveExitImageDialog}
        mainMessage={MAIN_MESSAGE}
        additionalMessage={ADDITIONAL_MESSAGE2}
        singleActionButtonText="Save & Exit"
        onYes={onSaveExitImageHandler}
        onNo={onContinueSaveExitImageHandler}
        buttonNoText={"Continue"}
        onCancel={onSaveExitImageCancelHandler}
      />
      <ConfirmationDialogV2
        isOpen={openSaveErrorDialog}
        mainMessage={saveErrorMessage}
        singleActionButton={true}
        singleActionButtonText="OK"
        onYes={onSaveErrorOkHandler}
        onCancel={onSaveErrorCancelHandler}
      />
      <ConfirmationDialogV3
        confirmationMessage={confirmationMessage}
        isOpen={openConfirmationDialog}
        onYes={handleYes}
        onNo={handleDialogNo}
        onCancel={handleDialogNo}
        cancelActionButton={true}
        singleActionButtonText={"Delete"}
        buttonNoText={"Cancel"}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleOnYesErrorDialog}
      />
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={handleClosePdfViewer}
        pdfDocuments={pdfDocuments}
      />
    </>
  );
};

export default ImageArchiveGrid;
