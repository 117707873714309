const isHomePage = (pathname: string) =>
  pathname === "/" || pathname === "/Home";

const isCreateFilePage = (pathname: string) => {
  const path = pathname.split("/");
  return path.includes("file");
};

const isPendingPayPage = (pathname: string) => {
  const path = pathname.split("/");
  return path.includes("pendingpay");
};

const isReportPayPage = (pathname: string) => {
  const path = pathname.split("/");
  return path.includes("reportpay");
};

const isProfileSettingsPage = (pathname: string) => {
  const path = pathname.split("/");
  return (
    path.includes("profile-settings")
  );
};

const isManageSignaturesPage = (pathname: string) => {
  const path = pathname.split("/");
  return (
    path.includes("manage-signatures")
  );
};

const isHelpCenterPage = (pathname: string) => {
  const path = pathname.split("/");
  return (
    path.includes("help-center")
  );
};

const isChangeAgencyPage = (pathname: string) => {
  return pathname === "/"
};

export { 
  isHomePage, 
  isCreateFilePage, 
  isPendingPayPage,
  isReportPayPage,
  isProfileSettingsPage,
  isManageSignaturesPage,
  isHelpCenterPage,
  isChangeAgencyPage
};
