import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import VoidReason from "entities/UIModel/company/VoidReason";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = { voidReasons: Record<string, SelectFieldOption[]>; };
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const actions = {
  getVoidReasons:
    (productTypeCode: string, defaultValue: string = "") =>
      async ({ setState, getState }: StoreApi) => {
        try {
          const { voidReasons } = getState();

          if (voidReasons[productTypeCode]) return;

          const { data } = await axiosSecuredInstance.get(
            `Company/GetVoidReasons?productTypeCode=${productTypeCode}`
          );

          const mappedData = data.map((reason: VoidReason) => ({
            text: reason.displayVoidReason,
            value: reason.displayVoidReason,
            selected: reason.displayVoidReason === defaultValue,
          }));

          const voidReasonsCopy = { ...voidReasons };
          voidReasonsCopy[productTypeCode] = mappedData;

          setState({
            voidReasons: voidReasonsCopy,
          });

        } catch (error: any) {
          console.error("ERROR: VoidReasonContext.", error);
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: { voidReasons: {} },
  actions,
  name: "companyVoidReasons",
});

const hook = createHook(Store);
export const useVoidReasons = () => { return hook(); };
