import {
  styled,
} from "@mui/material";
import React from "react";
import { gaps } from "theme/defaultStyle";
import { useAdditionalPartiesActions } from "utils/context/AdditionalPartiesContext";
import { useAdditionalPartySearch } from "utils/context/AdditionalPartySearchContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { PartyType } from "utils/data/enum";
import ClearAdditionalParty from "./ClearAdditionalParty";
import CompanyName from "./CompanyName";
import IndividualAttorney from "./IndividualAttorney";
import MakeFavorite from "./MakeFavorite";
import PartyTypeCode from "./PartyTypeCode";
import AddressOne from "./address/AddressOne";
import AddressTwo from "./address/AddressTwo";
import City from "./address/City";
import State from "./address/State";
import ZipCode from "./address/ZipCode";
import theme from "theme/default";

type Props = {
  index: number;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > .container-row": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: gaps.large1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& > *": {
      maxWidth: "100%",
    },
  },
  "& > .party-name-attorney-favorite-clear": {
    maxWidth: "100%",
    "& > div": {
      flexBasis: "content",
      flexGrow: 1,
      flexShrink: 1,
    },
    "& > .party-type": {
      maxWidth: "215px",
      minWidth: "200px",
    },
    "& > .company": {
      maxWidth: "500px",
      minWidth: "200px",
    },
    "& > .attorney": {
      maxWidth: "500px",
      minWidth: "150px",
    },
    "& > .favorite": {
      alignSelf: "center",
      marginLeft: "-9px",
      maxWidth: "140px",
      minWidth: "140px",
    },
    "& > .MuiButton-root": {
      alignSelf: "center",
      marginLeft: "auto",
    },
    "& > .party-type, & > .company, & > .attorney": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
  "& > .full-address": {
    maxWidth: "100%",
    "& > div": {
      flexBasis: "content",
      flexGrow: 1,
      flexShrink: 1,
    },
    "& > .address-one": {
      minWidth: "150px",
    },
    "& > .address-two": {
      maxWidth: "500px",
      minWidth: "150px",
    },
    "& > .city": {
      maxWidth: "300px",
      minWidth: "190px",
    },
    "& > .state": {
      maxWidth: "92px",
      minWidth: "92px",
    },
    "& > .zip-code": {
      maxWidth: "120px",
      minWidth: "120px",
    },
    "& > .address, & > .city, & > .state, & > .zip-code": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
});

export default function AdditionalPartyGrid({ index }: Props) {
  const schema = `additionalParties.${index}`;
  const { getValues } = useFormWrapper();
  const [searchState] = useAdditionalPartySearch();
  const [, { getAdditionalParties }] = useAdditionalPartiesActions();
  const [propertyState, selectedAdditionalPartyID, partyTypeCode] = getValues([
    "properties.0.state.abbr",
    `${schema}.selectedAdditionalPartyID`,
    `${schema}.partyTypeCode`,
  ]);
  const isAttorneyType = partyTypeCode === PartyType.Attorneys;
  const displayFullGrid = partyTypeCode !== "" ?? false;
  const disabled = selectedAdditionalPartyID !== "";

  React.useEffect(() => {
    if (disabled) return;

    getAdditionalParties(partyTypeCode, propertyState, {
      address: searchState.address,
      city: searchState.city,
      state: searchState.state,
      text: searchState.text,
      zip: searchState.zip,
    });
  }, [
    disabled,
    getAdditionalParties,
    partyTypeCode,
    propertyState,
    searchState,
  ]);

  return (
    <Container>
      <div className="container-row party-name-attorney-favorite-clear">
        <div className="party-type">
          <PartyTypeCode schema={schema} disabled={disabled} />
        </div>
        {displayFullGrid &&
          <>
            <div className="company">
              <CompanyName schema={schema} disabled={disabled} />
            </div>
            {isAttorneyType &&
              <div className="attorney">
                <IndividualAttorney schema={schema} disabled={disabled} />
              </div>
            }
            <div className="favorite">
              <MakeFavorite index={index} />
            </div>
            <ClearAdditionalParty schema={schema} />
          </>
        }
      </div>
      {displayFullGrid &&
        <div className="container-row full-address">
          <div className="address address-one">
            <AddressOne schema={schema} disabled={disabled} />
          </div>
          <div className="address address-two">
            <AddressTwo name={`${schema}.addressTwo`} disabled={disabled} />
          </div>
          <div className="city">
            <City schema={schema} disabled={disabled} />
          </div>
          <div className="state">
            <State name={`${schema}.state`} disabled={disabled} />
          </div>
          <div className="zip-code">
            <ZipCode schema={schema} disabled={disabled} />
          </div>
        </div>
      }
    </Container>
  );
}
