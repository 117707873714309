import {
  Box,
  styled,
  Typography,
} from "@mui/material";
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import React from "react";

const Text = styled(Typography)({
  color: colors.black,
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  "&.mainText": {
    paddingBottom: padding.small2,
  },
});

const BoxRoot = styled(Box)({
  textAlign: "center",
});

type Props = {
  mainText: string;
  helperText?: string;
};

export default function ReportDialogMessage({ mainText, helperText }: Props) {

  return (
    <BoxRoot>
      {mainText &&
          <Text
              className="mainText"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: mainText }}
          />
      }
      {helperText &&
          <Text
              dangerouslySetInnerHTML={{ __html: helperText }}
          />
      }
    </BoxRoot>
  );
}
