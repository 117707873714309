import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  colors,
  gaps
} from "theme/defaultStyle";
import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";
import { Order } from "utils/sorting";
import StewartTable from "controls/global/stewart-table";
import {
  Box,
  IconButton,
  styled
} from "@mui/material";
import { StewartTableColumn } from "controls/global/stewart-table/StewartTable";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import useDocumentStore, {
  useFilePolicyUploadStates
} from "utils/context/ImageArchiveContext";
import CustomTooltip from "controls/global/custom-tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from "theme/icons/ViewIcon";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationDialogV3 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV3";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import PdfViewer from "controls/global/pdf-viewer";
import CustomTextField from "controls/global/text-input-field/CustomTextField";
import {
  ActionResult,
  PdfDocument
} from "entities/UIModel";
import { useDocument } from "utils/context/DocumentContext";
import {
  ActionResultStatus,
  PERMISSIONS,
  UIConstants
} from "utils/data/enum";
import { getPdfDocument } from "pages/file/utils/fileUploadHelper";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import ConfirmationDialogV2 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV2";
import { NavigateToInfo } from "entities/UIModel/NavigationInfo";
import { getNavigationInfo } from "utils/services/utilsService";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import { getUrlByMenuId, menuIds } from "pages/home/menu";
import { useFiles } from "utils/context/FilesContext";

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .MuiTableContainer-root": {
    minHeight: "80px",
    maxHeight: "292px",
  },
  "& thead": {
    "& tr th#documentDisplayFileName": {
      minWidth: "40% !important",
    },
    "& tr th#documentUploadedDate": {
      width: "200px !important",
      // textWrap: "nowrap",
      whiteSpace: "nowrap",
    },
    "& tr th#documentUploadedByUserName": {
      width: "140px !important",
      whiteSpace: "nowrap",
    },
    "& tr th#Actions": {
      width: "150px !important",
      whiteSpace: "nowrap",
    },
  },
  "& tr td.actions": {
    whiteSpace: "nowrap",
  },
  "& tbody": {
    "& tr:hover td": {
      background: colors.grey10,
    },
    "& td .actionButton": {
      width: "40px",
    }
  },
  "&.editing-row": {
    marginLeft: "30px !important",
  },
  "& tbody .MuiTableRow-root": {
    "&.editing-row": {
      "& td": {
        ":last-child": {
          "& div.MuiBox-root": {
            "marginLeft": "40px !important",
          }
        },
      },
    },
  },

});

const editGridSchema = yup.object().shape({
  documentDisplayFileName: yup
    .string()
    .trim()
    .nullable()
    .required("Required Field")
});

const MAIN_MESSAGE = "Save changes?";
const ADDITIONAL_MESSAGE = 'Click "Save" to save your changes. Click "Continue" to navigate away without saving.';
const ADDITIONAL_MESSAGE2 = 'Click "Save & Exit" to save your changes and exit. Click "Continue" to exit without saving.';
interface Props {
  page: number;
  rows: ImageArchiveUI[];
  orderBy?: keyof ImageArchiveUI;
  order: Order;
  noRowsMessage: string[];
  onRequestSort?: (property: any, disableToggling?: boolean) => void;
  performSearch?: () => Promise<void>;
  saveFile: () => Promise<void>;
  reloadFile: () => Promise<void>;
  reloadFileActivities: () => Promise<void>;
}

const PolicyUploadGrid: React.FC<Props> = ({
  page,
  rows,
  orderBy,
  order,
  noRowsMessage,
  onRequestSort,
  performSearch,
  saveFile,
  reloadFile,
  reloadFileActivities,
}) => {
  // Form 
  const methods = useForm({
    resolver: yupResolver(editGridSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { setValue, getValues, resetField } = methods;

  // Is Read Only file
  const [{ isReadOnly }]  = useFiles();

  // Permission
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  // Editing row
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();
  const [{ isFilePolicyUploadEditMode }] = useFilePolicyUploadStates();
  const [, { setFileEditMode, saveDocumentSupplemental }] = useDocumentStore();
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [rowInEditMode, setRowInEditMode] = useState<any>();
  const [openSaveImageDialog, setOpenSaveImageDialog] = useState<boolean>(false);
  const [openSaveExitImageDialog, setOpenSaveExitImageDialog] = useState<boolean>(false);
  const [openSaveErrorDialog, setOpenSaveErrorDialog] = useState<boolean>(false);
  const [saveErrorMessage, setSaveErrorMessage] = useState<string>("");
  const { navigateAfterSaveChangesDialog } = useNavRedirect()

  const navigateTo = useRef<NavigateToInfo>({});
  
  const handleEdit = (rowData: ImageArchiveUI) => {
    setFileEditMode(true);
    rowData.isEdit = true;
    resetField(`documentDisplayFileName`);
    setRowInEditMode(rowData);
  };

  // Delete document 
  const [, { deleteDocumentSupplemental }] = useDocumentStore();
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

  // View Confirmation Message
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("")
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // Error dialog
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>("");
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const getCorrelationId = (httpResponseHeaders: any): string => {
    if (httpResponseHeaders && httpResponseHeaders["x-correlation-id"]) {
      return httpResponseHeaders["x-correlation-id"];
    }
    return "";
  };

  // View PDF
  const [, { getSupplementalDocumentUrl }] = useDocument();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);

  const handleView = async (data: ImageArchiveUI) => {
    const actionResult: ActionResult = await getSupplementalDocumentUrl(
      data.rowId,
      0
    );

    if (actionResult && (actionResult.error || !actionResult.documentUrl)) {
      setErrorMessage(UIConstants.VIEW_DOCUMENT_ERROR_MESSAGE);
      setCorrelationId(getCorrelationId(actionResult.error.response?.headers));
      setOpenErrorDialog(true);
      return;
    }

    if (actionResult && actionResult.documentUrl && actionResult.documentId) {
      setPdfDocuments([
        getPdfDocument(actionResult.documentUrl, actionResult.documentId, data.documentDisplayFileName || "document"),
      ]);
      setOpenPdfViewer(true);
    }
  };

  // Define Grid columns
  const columns: StewartTableColumn<any>[] = [
    {
      field: "documentDisplayFileName",
      name: "IMAGE NAME",
      sortable: true,
      // width: 500,
      valueGetter: ({ documentDisplayFileName }: any) => <TooltipCell title={documentDisplayFileName}></TooltipCell>,
    },
    {
      field: "documentUploadedDate",
      name: "UPLOAD DATE/TIME",
      sortable: true,
      valueGetter: ({ documentUploadedDate }: any) => <TooltipCell title={documentUploadedDate}></TooltipCell>,
    },
    {
      field: "documentUploadedByUserName",
      name: "UPLOADED bY",
      sortable: true,
      valueGetter: ({ documentUploadedByUserName }: any) => (
        <TooltipCell title={documentUploadedByUserName}></TooltipCell>
      ),
    },
    {
      field: "Actions",
      name: "",
      sortable: false,
      classname: "actions",
      valueGetter: (rowData: ImageArchiveUI) => (
        <>
          {isFilePolicyUploadEditMode && rowInEditMode?.rowId === rowData?.rowId ? (

            <Box sx={{ marginLeft: "40px" }}>
              <CustomTooltip title="Save" placement="top" aria-label="save" arrow >
                <IconButton
                  id="saveDocumentButton"
                  className="actionButton"
                  aria-label="Save"
                  disabled={!methods.formState.isValid || !methods.formState.isDirty}
                  sx={{
                    color: methods.formState.isValid ? colors.blue01 : colors.blue11,
                    '&.Mui-disabled': {
                      color: colors.blue11,
                    },
                  }}
                >
                  <SaveIcon id="fileSaveOnEdit" className="saveDocumentIcon" />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Cancel" placement="top" aria-label="save" arrow >
                <IconButton className="actionButton" aria-label="Cancel" onClick={() => handleCancel(rowData)}>
                  <CloseIcon sx={{ color: colors.blue01 }} />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : (
            <>
              {
                <CustomTooltip title="Edit" placement="top" aria-label="Edit" arrow >
                  <IconButton onClick={() => handleEdit(rowData)}
                    className="actionButton"
                    aria-label="Edit"
                    disabled={(rowData.documentIsProcessed === 1 || !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_MODIFY)) ? true : isFilePolicyUploadEditMode}
                    sx={{
                      color: colors.blue01,
                      '&.Mui-disabled': {
                        color: colors.blue11,
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              }
              <CustomTooltip title="View" placement="top" aria-label="View" arrow >
                <IconButton
                  className="actionButton"
                  aria-label="View"
                  disabled={( !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_VIEWDOCS)) ? true : isFilePolicyUploadEditMode}
                  onClick={() => handleView(rowData)}
                  sx={{
                    color: colors.blue01,
                    '&.Mui-disabled': {
                      color: colors.blue11,
                    },
                  }}
                >
                  <ViewIcon isDisabled ={( !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_VIEWDOCS)) ? true : isFilePolicyUploadEditMode}/>
                </IconButton>
              </CustomTooltip>
              {
                <CustomTooltip title="Delete" placement="top" aria-label="Delete" arrow >
                  <IconButton
                    className="actionButton"
                    aria-label="Delete"
                    onClick={() => handleDelete(rowData)}
                    sx={{
                      color: colors.blue01,
                      '&.Mui-disabled': {
                        color: colors.blue11,
                      },
                    }}
                    disabled={(rowData.documentIsProcessed === 1 || !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_DELETE)) ? true : isFilePolicyUploadEditMode}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              }
            </>
          )}
        </>
      ),
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  async function save(includedFile:boolean = false, exitAfterSave:boolean = false) {
    openAutomaticProgressDialog(UIConstants.SAVE_DOCUMENT_IN_PROGRESS_MESSAGE);
    setFileEditMode(false);

    const fileName = getValues("documentDisplayFileName")?.trim();
    const result = await saveDocumentSupplemental({
      DocumentSupplementalID: rowInEditMode?.rowId,
      DocumentDisplayFileName: `${fileName}.${rowInEditMode?.documentFileTypeCode.toLowerCase()}`,
      // FileID: rowInEditMode?.fileID, 
    });

    if (result.status === ActionResultStatus.Success) {
      if (includedFile && !isReadOnly) {
        await saveFile();
      }
  
      if (!exitAfterSave) {
        if (!isReadOnly) {
          await reloadFile();
        }
        else {
          await reloadFileActivities();
        }
        await performSearch!();
      }
      closeAutomaticProgressDialog();
    }
    else {
      closeAutomaticProgressDialog();
      // show error here 
      const errorMessage =
        result.error?.response?.data 
          ? result.error.response.data
          : result.error.response.statusText;

      setSaveErrorMessage(errorMessage);
      setOpenSaveErrorDialog(true);
    }
  }

  async function onSaveImageHandler() {
    setOpenSaveImageDialog(false);
    setFileEditMode(false);
    await save(true, false);
    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  async function onSaveExitImageHandler() {
    setOpenSaveExitImageDialog(false);
    setFileEditMode(false);
    await save(true, true);

    navigateAfterSaveChangesDialog(navigateTo.current);

    if (navigateTo.current.url === getUrlByMenuId(menuIds.menuCreateFile)) {
      setTimeout(() => {
        document?.documentElement?.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    }

  }

  function onSaveImageCancelHandler() {
    setOpenSaveImageDialog(false);
    // setFileEditMode(false);
  }

  function onSaveExitImageCancelHandler() {
    setOpenSaveExitImageDialog(false);
    // setFileEditMode(false);
  }

  function onContinueSaveImageHandler() {
    setOpenSaveImageDialog(false)
    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  function onContinueSaveExitImageHandler() {
    setOpenSaveExitImageDialog(false)
    navigateAfterSaveChangesDialog(navigateTo.current);
  }

  const handleCancel = (rowData: ImageArchiveUI) => {
    setFileEditMode(false);
    rowData.isEdit = false;
    resetField(`documentDisplayFileName`);
  };

  const handleDelete = async (data: ImageArchiveUI) => {
    setOpenConfirmationDialog(true);
    setDeleteConfirmationMessage(`
    Delete '${data.documentDisplayFileName}' ?`)
    setIdToDelete(data.rowId);
  };

  const handleYes = async (data: ImageArchiveUI) => {
    setOpenConfirmationDialog(false);

    // Delete document
    openAutomaticProgressDialog();
    const result = await deleteDocumentSupplemental(idToDelete || 0);
  
    if (result.status === ActionResultStatus.Success) {
      if (reloadFileActivities) {
        await reloadFileActivities();
      }
      await performSearch!();
      closeAutomaticProgressDialog();
    }
    else {
      closeAutomaticProgressDialog();
      // show error here 
      const errorMessage =
        result.error?.response?.data 
          ? result.error.response.data
          : result.error.response.statusText;

      setSaveErrorMessage(errorMessage);
      setOpenSaveErrorDialog(true);
    }
  };

  const onSaveErrorOkHandler = () => {
    setOpenSaveErrorDialog(false);
    performSearch!();
  };

  const onSaveErrorCancelHandler = () => {
    setOpenSaveErrorDialog(false);
    performSearch!();
  };

  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const handleClosePdfViewer = () => {
    setOpenPdfViewer(false);
  };

  const handleDialogNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleDialogCancel = () => {
    setOpenConfirmationDialog(false);
  };


  useEffect(() => {
    const updatedColumns = columns.map((column) => {
      if (column.field === "documentDisplayFileName") {
        return {
          ...column,
          valueGetter: ({ rowId, documentDisplayFileName }: any) =>
            isFilePolicyUploadEditMode && rowInEditMode.rowId === rowId ? (
              <CustomTextField
                name={`documentDisplayFileName`}
                defaultValue={documentDisplayFileName.split(".").slice(0, -1).join(".")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setValue(`documentDisplayFileName${rowId}`, event.target?.value)
                }
                maxLength={100}
              />
            ) : (
              <TooltipCell title={documentDisplayFileName}></TooltipCell>
            ),
        };
      }
      return column;
    });
    setTableColumns(updatedColumns);
  }, [isFilePolicyUploadEditMode]);

  useEffect(() => {
    return (() => {
      // setFileShowToast(false);
      setFileEditMode(false);
    })
  }, [])

  useEffect(() => {
    setDisableSave(!methods.formState.isValid || !methods.formState.isDirty)
  }, [methods.formState])

  useEffect(() => {

    const onSaveDialogImageNameEditingHandler = async (event: Event) => {
      const clickedOnFileSaveOnEdit = (document.querySelector("#fileSaveOnEdit") as Element)?.contains(event.target as Node);
      const navigationInfo = getNavigationInfo(event);
  
      // console.log("navigateToInfo:", navigateToInfo);
      if (navigationInfo?.navigateToInfo && navigationInfo.navigateToInfo.navigateToType) {
        navigateTo.current = navigationInfo?.navigateToInfo;
      }

      // -- also add for "Save" button above - will need to add
      if (isFilePolicyUploadEditMode && !disableSave) {
        if (clickedOnFileSaveOnEdit) {
          await save(true, false);
          return;
        }
  
        if (
          navigationInfo?.navigateToMenuHome ||
          navigationInfo?.navigateToMenuFiles ||
          navigationInfo?.navigateToMenuCreateFile ||
          navigationInfo?.navigateToMenuPaymentsReportPay ||
          navigationInfo?.navigateToMenuPaymentsPendingPayments ||
          navigationInfo?.navigateToMenuPolicyImages ||
          navigationInfo?.navigateToMenuStewartResources ||
          navigationInfo?.navigateToMenuProfileSettings ||
          navigationInfo?.navigateToMenuManageSignatures ||
          navigationInfo?.navigateToMenuHelpCenter ||
          navigationInfo?.navigateToMenuLogout 
        ) {
          event.preventDefault();
          setOpenSaveExitImageDialog(true);
          return;
        }
  
        if (
          navigationInfo?.navigateToGlobalAccessAgency ||
          navigationInfo?.clickedOnChangeGlobalAccessAgency ||
          navigationInfo?.clickedOnRemoveGlobalAccessAgency ||
          navigationInfo?.navigateToCplTab ||
          navigationInfo?.navigateToAalTab ||
          navigationInfo?.navigateToJacketTab ||
          navigationInfo?.navigateToSaendoTab
          // navigateToPolicyUploadTab
        ) {
          event.preventDefault();
          setOpenSaveImageDialog(true);
          return;
        }
      }
      // Notes: clicking on Policy Upload tab -- will stay on same the tab without prompting any save changes question
    };

    if (isFilePolicyUploadEditMode && disableSave === false) {
      document.addEventListener("mousedown", onSaveDialogImageNameEditingHandler, true);
    }
    else {
      document.removeEventListener("mousedown", onSaveDialogImageNameEditingHandler, true);
    }
    
    return () => {
      document.removeEventListener("mousedown", onSaveDialogImageNameEditingHandler, true);
    };
  }, [isFilePolicyUploadEditMode, disableSave]);

  return (
    <>
      <FormProvider {...methods}>
        <StyledTableContainer>
          <StewartTable
            cols={tableColumns}
            rows={rows}
            page={page}
            rowsPerPage={999999}
            showActionRow={false}
            onColumnResize={() => { }}
            noRowsMessage={noRowsMessage}
            orderBy={orderBy}
            order={order}
            useDbPagingSorting={true}
            onRequestSort={onRequestSort}
          />
        </StyledTableContainer>
      </FormProvider>
      <ConfirmationDialogV2
        isOpen={openSaveImageDialog}
        mainMessage={MAIN_MESSAGE}
        additionalMessage={ADDITIONAL_MESSAGE}
        singleActionButtonText="Save" //"Save"
        onYes={onSaveImageHandler} //{onSaveHandler}
        onNo={onContinueSaveImageHandler}
        buttonNoText={"Continue"}
        onCancel={onSaveImageCancelHandler}
      />
      <ConfirmationDialogV2
        isOpen={openSaveExitImageDialog}
        mainMessage={MAIN_MESSAGE}
        additionalMessage={ADDITIONAL_MESSAGE2}
        singleActionButtonText="Save & Exit"
        onYes={onSaveExitImageHandler}
        onNo={onContinueSaveExitImageHandler}
        buttonNoText={"Continue"}
        onCancel={onSaveExitImageCancelHandler}
      />
      <ConfirmationDialogV2
        isOpen={openSaveErrorDialog}
        mainMessage={saveErrorMessage}
        singleActionButton={true}
        singleActionButtonText="OK"
        onYes={onSaveErrorOkHandler}
        onCancel={onSaveErrorCancelHandler}
      />
      <ConfirmationDialogV3
        confirmationMessage={deleteConfirmationMessage}
        isOpen={openConfirmationDialog}
        cancelActionButton={true}
        onYes={handleYes}
        onNo={handleDialogNo}
        onCancel={handleDialogCancel}
        singleActionButtonText={"Delete"}
        buttonNoText={"Cancel"}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleOnYesErrorDialog}
      />
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={handleClosePdfViewer}
        pdfDocuments={pdfDocuments}
      />
    </>
  )
}

export default PolicyUploadGrid;

