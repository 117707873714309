import { styled } from "@mui/material";
import capitalize from "@mui/material/utils/capitalize";
import DatePickerSearch from "controls/global/datepicker-search/DatePickerSearch";
import MultiLineCell from "controls/global/stewart-table/MultiLineCell";
import StewartTable, {
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { FileSearchCriteria } from "entities/ApiModel";
import { IColumn } from "entities/ApiModel/IColumn";
import { FilesRecord } from "entities/UIModel";
import React from "react";
import { Link } from "react-router-dom";
import {
  colors,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import {
  getColumnConfig,
  getColumnSearchField,
} from "utils/columnsGrid";
import { 
  AgencyStatus,
  FileStatusType, 
  PERMISSIONS, 
  SessionStorageKeys, 
} from "utils/data/enum";
import {
  formatDate,
} from "utils/shared";
import { Order } from "utils/sorting";
import InputSearch from "../input-search";
import SelectStatus from "../select-status";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
// import AgencySelectStatus from "./AgencySelectStatus";

type Props = {
  colsConfig: IColumn[];
  rows: FilesRecord[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  noRowsMessage: string[];
  fileStatusTypes: FileStatusType[];
  page: number;
  hiddenColumns?: (keyof FilesRecord)[];
  isAdvSearchOpen?: boolean;
  onFiltersChange: (name: keyof FileSearchCriteria, value: any) => void;
  onPageChange: (page: number) => void;
  order: Order;
  orderBy?: keyof FilesRecord;
  onRequestSort?: (property: string, disableToggling?: boolean) => void;
  onColumnResize: (column: keyof FilesRecord, newWidth: number) => void;
  onRowsPerPageChange: (count: number) => void;
  defaultValues? : any;
  companyStatus: AgencyStatus[];
};

const TableWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .header-actions > th:first-of-type": {
    paddingLeft: padding.medium1,
  },
  "& tr:hover td": {
    background: colors.grey10,
  },
});

const StyledLink = styled(Link)({
  fontWeight: fontWeight.bold2,
  cursor: "pointer",
  color: colors.blue01,
  textDecoration: "underline",
});

export default function MyFilesGrid({
  colsConfig,
  rows,
  rowsPerPage,
  loadingProgressPercent,
  noRowsMessage,
  fileStatusTypes,
  page,
  hiddenColumns,
  isAdvSearchOpen,
  onPageChange,
  onFiltersChange,
  order,
  orderBy,
  onRequestSort,
  onColumnResize,
  onRowsPerPageChange,
  defaultValues,
  companyStatus,
}: Props) {

  const getSearchField = (field: keyof FilesRecord) =>
    getColumnSearchField<FilesRecord, FileSearchCriteria>(colsConfig, field);
   const usePriorSearchCriteria = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
   const [{ profileSettings }] = useProfileSettingsCache();
  
   const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
   
   const hasFileViewAccess = permissionCodes.includes(PERMISSIONS.FILES_VIEW)
 
  const columns: StewartTableColumn<FilesRecord>[] = [
    {
      field: "fileNumber",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("fileNumber")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["clientFileID"] : ""}
        />
      ),
      valueGetter: ({ fileNumber, fileId }: FilesRecord) => (
        <TooltipCell title={fileNumber}>
          {hasFileViewAccess ? (
            <StyledLink
              to="/file"
              state={{
                fileID: fileId,
                isAdvSearchOpen,
                clientId: fileNumber,
              }}
            >
              {fileNumber}
            </StyledLink>
          ) : (
            fileNumber
          )}
        </TooltipCell>
      ),      
      ...getColumnConfig<FilesRecord>(colsConfig, "fileNumber"),
    },
    {
      field: "propertyAddress",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("propertyAddress")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["propertyAddress"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.propertyAddress} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "propertyAddress"),
    },
    {
      field: "buyerBorrower",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("buyerBorrower")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["buyerName"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.buyerBorrower} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "buyerBorrower"),
    },
    {
      field: "agencyID",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("agencyID")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["agencyID"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.agencyID} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "agencyID"),
    },
    {
      field: "locationDisplayName",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("locationDisplayName")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["locationDisplayName"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.locationDisplayName} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "locationDisplayName"),
    },
    {
      field: "legacyID",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("legacyID")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["legacyID"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <MultiLineCell names={row.legacyID} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "legacyID"),
    },
    {
      field: "agencyName",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("agencyName")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["agencyName"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.agencyName} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "agencyName"),
    },
    // {
    //   field: "companyStatus",
    //   actionComponent: () => (
    //     <AgencySelectStatus
    //       name="companyStatus"
    //       defaultValue={(defaultValues && usePriorSearchCriteria && defaultValues["companyStatus"]) ? defaultValues["companyStatus"].split("|") : companyStatus}
    //       disabled={isAdvSearchOpen}
    //       onChange={onFiltersChange}
    //     />
    //   ),
    //   valueGetter: (row: FilesRecord) => (<MultiLineCell names={row.companyStatus} />),
    //   ...getColumnConfig<FilesRecord>(colsConfig, "companyStatus"),
    // },
    {
      field: "seller",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("seller")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["sellerName"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.seller} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "seller"),
    },
    {
      field: "lender",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("lender")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["lenderName"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.lender} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "lender"),
    },
    {
      field: "status",
      actionComponent: () => (
        <SelectStatus
          key={`statuses-${fileStatusTypes}`}
          name="fileStatusTypeCode"
          defaultValue={
            defaultValues && usePriorSearchCriteria && defaultValues["fileStatusTypeCode"]
              ? defaultValues["fileStatusTypeCode"].split("|")
              : fileStatusTypes
          }
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: FilesRecord) => {
        const text = row.status === FileStatusType.ReportPending ? "REPORTED/PENDING" : row.status;
        return <TooltipCell title={capitalize(text)} />;
      },
      ...getColumnConfig<FilesRecord>(colsConfig, "status"),
    },
    {
      field: "productNames",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("productNames")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["productName"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <MultiLineCell names={row.productNames} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "productNames"),
    },
    {
      field: "openedTimestamp",
      actionComponent: () => (
        <DatePickerSearch
          header
          disabled={isAdvSearchOpen}
          handleDateChange={(date: Date | null) => {
            onFiltersChange("dateRangeStart", date);
          }}
        />
      ),
      valueGetter: (row: FilesRecord) => formatDate(row.opened),
      ...getColumnConfig<FilesRecord>(colsConfig, "openedTimestamp"),
    },
    {
      field: "stateAbbr",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("stateAbbr")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["stateAbbr"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.stateAbbr} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "stateAbbr"),
    },
    {
      field: "propertyTypeCode",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("propertyTypeCode")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["propertyTypeCode"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.propertyTypeCode} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "propertyTypeCode"),
    },
    {
      field: "transactionTypeCode",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("transactionTypeCode")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["transactionTypeCode"] : ""}
        />
      ),
      valueGetter: (row: FilesRecord) => <TooltipCell title={row.transactionTypeCode} />,
      ...getColumnConfig<FilesRecord>(colsConfig, "transactionTypeCode"),
    },
  ];

  return (
    <TableWrapper>
      <StewartTable
        cols={columns}
        rows={rows}
        rowsPerPage={rowsPerPage}
        loadingProgressPercent={loadingProgressPercent}
        noRowsMessage={noRowsMessage}
        page={page}
        hiddenColumns={hiddenColumns}
        useDbPagingSorting={true}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onColumnResize={onColumnResize}
      />
      <StewartTableFooterPaginator
        totalRows={rows[0]?.totalRows ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        totalPages={rows[0]?.totalPages ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableWrapper>
  );
}
