import React from "react";

interface ViewIconProps {
  isDisabled?: boolean;
}

const ViewIcon: React.FC<ViewIconProps> = ({ isDisabled }) => {
  const fillColor = isDisabled ? '#92C4DB' : '#0075AA';
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="
http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6224 1.6224C4.02091 1.22388 4.56141 1 5.125 1H12.7966C13.327 1 13.8357 1.21071 14.2108 1.58579L19.4142 6.78921C19.7893 7.16429 20 7.67299 20 8.20343V14.2047C19.4024 14.0095 18.7686 13.8944 18.1111 13.8721V8.4375H12.5625V2.88889H5.88889C5.3366 2.88889 4.88889 3.3366 4.88889 3.88889V19.3611C4.88889 19.9134 5.3366 20.3611 5.88889 20.3611H12.8493C13.6516 21.2287 14.6747 21.8884 15.8295 22.25H5.125C4.56141 22.25 4.02091 22.0261 3.6224 21.6276C3.22388 21.2291 3 20.6886 3 20.125V3.125C3 2.56141 3.22388 2.02091 3.6224 1.6224ZM13.283 15.6389C12.6906 16.1756 12.1923 16.8145 11.8156 17.5278H7.25V15.6389H13.283ZM7.25 13.75V11.8611H15.75V13.75H7.25Z"
        fill={fillColor}
      />
      <path
        d="M18.4167 14.875C16.144 14.875 14.2031 16.2886 13.4167 18.2841C14.2031 20.2795 16.144 21.6932 18.4167 21.6932C20.6894 21.6932 22.6303 20.2795 23.4167 18.2841C22.6303 16.2886 20.6894 14.875 18.4167 14.875ZM18.4167 20.5568C17.1621 20.5568 16.144 19.5386 16.144 18.2841C16.144 17.0295 17.1621 16.0114 18.4167 16.0114C19.6712 16.0114 20.6894 17.0295 20.6894 18.2841C20.6894 19.5386 19.6712 20.5568 18.4167 20.5568ZM18.4167 16.9205C17.6621 16.9205 17.0531 17.5295 17.0531 18.2841C17.0531 19.0386 17.6621 19.6477 18.4167 19.6477C19.1712 19.6477 19.7803 19.0386 19.7803 18.2841C19.7803 17.5295 19.1712 16.9205 18.4167 16.9205Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ViewIcon;
