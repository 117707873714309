import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import { PricingConfigKey } from "utils/data/enum";
import { PricingConfig as PC } from "entities/UIModel";

function useLeasehold() {
  const [{ pricingConfigs }] = usePricingConfig();
  const { getValues } = useFormWrapper();
  const isIntegratedPricing = getValues(`pricing.isIntegratedPricing`);

  const showLeasehold = (formType: string, state: string): boolean => {
    const configs = pricingConfigs.filter(
      (cf: PC) => cf.configKey === PricingConfigKey.Leasehold
    );

    const isConfigOn = configs.some((cf: PC) => Number(cf?.configValue) === 1);
    const isValidFormType = configs.some((cf: PC) => formType === cf?.formType);
    const isValidState = configs.some((cf: PC) => state === cf?.stateAbbr);

    return isValidFormType && isValidState && isConfigOn && isIntegratedPricing;
  };

  return { showLeasehold };
}

export default useLeasehold;
