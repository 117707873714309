import React from "react";

const VideoIcon = () => (
  <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 0.5C11.2 0.5 0 11.7 0 25.5C0 39.3 11.2 50.5 25 50.5C38.8 50.5 50 39.3 50 25.5C50 11.7 38.8 0.5 25 0.5ZM20 36.75V14.25L35 25.5L20 36.75Z"
      fill="white"
    />
  </svg>
);
export default VideoIcon;
