import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {
  styled,
  TableCell,
} from "@mui/material";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CustomTooltip from "controls/global/custom-tooltip";
import React from "react";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  padding
} from "theme/defaultStyle";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import {
  JacketFormType,
  PricingType,
  ProductType,
} from "utils/data/enum";
import { hasValue } from "utils/shared";

const StyledTableBodyCell = styled(TableCell)({
  borderTop: `${borderSize.xsmall} solid ${colors.grey17}`,
  fontWeight: fontWeight.normal1,
  fontSize: fontSize.large,
  padding: padding.zero,
  "&:first-child": {
    paddingLeft: padding.zero,
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .MuiIconButton-root": {
    paddingBottom: padding.medium1,
    paddingTop: padding.medium1,
  },
});

const EditIconButton = styled(IconButton)({
  color: colors.blue01,
  "& .fa.fa-edit": {
    fontSize: fontSize.xlarge2,
  },
});

type Props = {
  index: number;
  schema: string;
  isOverride: boolean;
  isFileLocked: boolean;
  isPricingUpdateable: boolean; // for future used if needed
  handleReissueChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleIsReissueClick: (index: number) => void;
};

export default function PricingProductReissueCell({
  index,
  schema,
  isOverride,
  isFileLocked,
  handleReissueChange,
  handleIsReissueClick,
}: Props) {
  const { getValues } = useFormWrapper();
  const isIntegratedPricing = getValues("pricing.isIntegratedPricing");
  const isBilled = getValues(`pricingProducts.${index}.isBilled`);

  const ToolTip = () => {
    return (
      <CustomTooltip
        title={
          isBilled
            ? "Original policy information"
            : "Please enter original policy information."
        }
        placement="top"
        aria-label="lender-contact-info"
        arrow
      >
        <Icon className="fa fa-edit" fontSize="small" />
      </CustomTooltip>
    );
  };

  const showReissueCheckbox = getValues("pricing.showReissueCheckbox"); // used to turn off because by default it's on
  const showReissueEdit = getValues("pricing.showReissueEdit"); // used to turn off because by default it's on

  const disableReissueCheckbox = () => {
    return (isIntegratedPricing && Boolean(isOverride)) || isFileLocked;
  };
  const disableReissueEdit = () => {
    const isReIssueChecked = getValues(getNameString(`pricingProducts.${index}.isReissue`));
    return (isIntegratedPricing && Boolean(isOverride)) ||
      //isFileLocked ||
      (showReissueCheckbox && !isReIssueChecked);
  };

  const renderReissueCheckBox = () => {
    if (showReissueCheckbox) {
      return (
        <CheckboxInputField
          disabled={disableReissueCheckbox()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleReissueChange(event, index);
          }}
          name={`pricingProducts.${index}.isReissue`}
        />
      );
    }
    return null;
  };

  const renderReissueEdit = () => {
    if (showReissueCheckbox) {
      return (
        <Box>
          <EditIconButton
            className={"reissueEditIcon editBox"}
            hidden={!showReissueEdit}
            disabled={disableReissueEdit()}
            onClick={() => handleIsReissueClick(index)}
          >
            {ToolTip()}
          </EditIconButton>
        </Box>
      );
    }
    if (showReissueEdit) {
      return (
        <Box>
          <EditIconButton
            className={"reissueEditIcon editBox"}
            onClick={() => handleIsReissueClick(index)}
            disabled={disableReissueEdit()}
          >
            {ToolTip()}
          </EditIconButton>
        </Box >
      );
    }
    return null;
  };

  const reissueGroupVisible = () => {
    const pricingType = getValues(getNameString(`${schema}.pricingType`));
    if (pricingType !== PricingType.Product) return false;

    // Always show reissue, for manual pricing i.e. isIntegratedPricing = False
    if (!isIntegratedPricing) {
      const productType = getValues(getNameString(`${schema}.productType`));
      const formType = getValues(getNameString(`${schema}.formType`));

      return productType === ProductType.Jacket && (formType === JacketFormType.Loan || formType === JacketFormType.Owners);
    }

    const rateType = getValues(getNameString(`${schema}.pricingRateType`));
    const pricingRateTypeData = getValues(
      getNameString(`${schema}.pricingRateTypeData`)
    );

    const rateTypeData = pricingRateTypeData
      ? JSON.parse(pricingRateTypeData)
      : undefined;

    const result = rateTypeData?.find(
      (rtd: { Alias: string; }) => rtd.Alias === rateType
    );

    //if (result === undefined) return false;    
    if (result && hasValue(result?.IsReissue)) {
      return JSON.parse(result.IsReissue);
    } else {
      return false;
    }
  };

  return (
    <StyledTableBodyCell align="center">
      {reissueGroupVisible() && (
        <Box display="flex" justifyContent="center" alignItems="center">
          {renderReissueCheckBox()}
          {renderReissueEdit()}
        </Box>
      )}
    </StyledTableBodyCell>
  );
}
