import StewartDrawZone from "controls/global/stewart-draw-zone";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import React from "react";
import {
  getImageTypeFromImageDataUrl,
  IMG_MAX_HEIGHT,
  IMG_MAX_WIDTH,
} from "utils/signature/imageFile";
import Body from "../signature-dialog/SignatureDialogBody";

export default function DrawSignature() {
  const {
    state: { signature },
  } = useSignatureProvider();
  const { dispatch } = useSignatureDispatchProvider();

  const getImage = (imageSrc: string) => {
    const imageType = getImageTypeFromImageDataUrl(imageSrc);
    dispatch({
      type: "FILE_UPDATE",
      signatureFile: imageSrc,
      imageType: imageType,
    });
  };

  return (
    <Body
      title="Draw a signature"
      width={300}
      showSignatureName
    >
      <StewartDrawZone
        width={IMG_MAX_WIDTH}
        height={IMG_MAX_HEIGHT}
        getImage={getImage}
        defaultImage={signature.file}
      />
    </Body>
  );
}
