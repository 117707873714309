import { FileActivity } from "entities/UIModel";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  formatDate,
  formatDateTime,
} from "utils/shared";

const DESC = [
  "Auto Undo Report Submitted",
  "Undo Report Submitted",
  "Report Only Submitted",
  "Pay by Check Submitted",
  "Report & Pay by Check Submitted",
  "Premium Reported",
  "File Auto-Reported",
];

function PricingDetails() {
  const { getValues } = useFormContext();

  const getActivity = () => {
    const activities = getValues("fileActivities");
    if (!activities || activities?.length === 0) return null;
    return activities.find((ac: any) => DESC.includes(ac.description));
  };

  const getActivityTime = (activity: FileActivity) => {
    if (!(activity && activity.date)) return null;
    const activityDate = formatDateTime(activity.date, true, true);
    const activityDateParts = activityDate.split(" ");
    if (activityDateParts.length >= 3)
      return activityDate.split(" ")[1] + " " + activityDate.split(" ")[2];
    return null;
  };

  const activity: FileActivity = getActivity();
  const activityTime = getActivityTime(activity);
  const activityUser = activity && activity.userName ? " by " + activity.userName.replace("|", " ") : "";

  return (
    <>
      {activity && activityTime && (
        <>
          {activity.description}{activityUser} on{" "}
          {formatDate(activity.date)} at {activityTime}
        </>
      )}
    </>
  );
}

export default PricingDetails;
