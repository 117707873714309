import {
  styled,
} from "@mui/material";
import ClearButton from "controls/global/clear-btn";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import FullAddress from "controls/global/full-address";
import TaxCodeField from "controls/global/full-address/TaxCodeField";
import {
  PricingProduct,
  Property,
  SCFile,
} from "entities/UIModel";
import {
  hasAdditionalParty,
  hasPendingProducts,
} from "pages/file/utils/helper";
import React, {
  PropsWithChildren,
  useEffect,
} from "react";
import theme from "theme/default";
import { gaps } from "theme/defaultStyle";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import { useCompanyCounties } from "utils/context/CompanyCountiesContext";
import { useCompanyMunicipal } from "utils/context/CompanyMunicipalContext";
import { useCompanyProductOptions } from "utils/context/CompanyProductOptionsContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import { useModalContext } from "utils/context/ModalContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import LegalDescriptionField from "./LegalDescriptionField";
import PropertyTypeField from "./PropertyTypeField";
import TaxDocumentLink from "./TaxDocumentLink";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > .container-row": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: gaps.large1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& > *": {
      maxWidth: "100%",
    },
  },
  "& > .type-legal-tax": {
    maxWidth: "100%",
    "& > div": {
      flexBasis: "content",
      flexGrow: 1,
      flexShrink: 1,
    },
    "& > .property-type": {
      maxWidth: "16.67%",
      minWidth: "16.67%",
    },
    "& > .legal-description": {
      maxWidth: "550px",
      minWidth: "250px",
    },
    "& > .tax-code-info": {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      gap: gaps.large1,
      "& > .tax-code": {
        maxWidth: "175px",
        minWidth: "175px",
      },
      "& > .MuiSvgIcon-root": {
        alignSelf: "center",
      },
    },
    "& > .MuiButton-root": {
      alignSelf: "center",
      marginLeft: "auto",
    },
    "& > .property-type, & > .legal-description, & > .tax-code-info, & > .tax-code-info > .tax-code": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
});

const getFirstCommaIfNeeded = (cityLabel: string, addressLabel: string) => {
  return cityLabel !== "" && addressLabel !== "" ? `, ` : "";
};

const getSecondCommaIfNeeded = (
  zipcodeLabel: string,
  cityLabel: string,
  addressLabel: string
) => {
  return zipcodeLabel !== "" && (cityLabel !== "" || addressLabel !== "")
    ? `, `
    : "";
};

const getPropertyDescription = (property: Property) => {
  if (!property) return;
  const { addressOne, city, state, zipCode } = property;
  const addressLabel = addressOne ? addressOne : "";

  const cityLabel = city ? city : "";
  const stateLabel = state ? state.abbr : "";
  const zipcodeLabel = zipCode ? zipCode : "";

  if (state?.code) {
    return `${addressLabel}${getFirstCommaIfNeeded(
      cityLabel,
      addressLabel
    )}${cityLabel}${getSecondCommaIfNeeded(
      zipcodeLabel,
      cityLabel,
      addressLabel
    )} ${stateLabel} ${zipcodeLabel}`;
  }

  return `${addressLabel}${getFirstCommaIfNeeded(
    cityLabel,
    addressLabel
  )}${cityLabel}${getSecondCommaIfNeeded(
    zipcodeLabel,
    cityLabel,
    addressLabel
  )}${zipcodeLabel}`;
};

type Props = {
  propertyIndex: number;
  onDelete: any;
  fileNameNumber: string;
  agency: any;
  agencyLocation: any;
  additionalParties: any;
  hasIssuedProducts: boolean;
  isPopulatingExistingFile: boolean;
  isStatesDisabled: boolean;
  isPropertyDisabled: boolean;
  handleOnClear: any;
  deleteFileData: any;
  taxDocument: string;
  showTaxCode: boolean;
  setShowTaxCode?: (show: boolean) => void;
};

const PropertyData = ({
  propertyIndex,
  onDelete,
  additionalParties,
  hasIssuedProducts,
  isStatesDisabled,
  isPropertyDisabled,
  handleOnClear,
  deleteFileData,
  taxDocument,
  showTaxCode,
  setShowTaxCode
}: Props) => {
  const { clearErrors, setValue, getValues } = useFormWrapper();
  const [{ agencyStates }] = useAgencyStates();
  const { setPricingPremiumWithPolicyTypes, setSimultaneousRate, resetShowReIssueFlags } =
    useCreateFile();
  const [{ counties }, { resetCounties }] = useCompanyCounties();
  const [, { resetPropertyState }] = useFilePropertyState();
  const [, { resetCompanyProductOptions }] = useCompanyProductOptions();
  const [{ municipal }] = useCompanyMunicipal();
  const [
    { acceptedModal, propertyToClear },
    { setIsModalOpen, setMessage, resetDefaultValues, setPropertyToClear },
  ] = useModalContext();

  const propertyName = `properties.${propertyIndex}`;

  const hasPricingProducts =
    (getValues("pricingProducts") as Array<PricingProduct>)?.length > 0;
  const [{ profileSettings }] = useProfileSettingsCache();
  const defaultPropertyType =
    profileSettings.userProfile.defaultPropertyType !== ""
      ? (profileSettings.userProfile.defaultPropertyType as string)
      : "RESIDENTIAL";
  const isFileDisabled = useFieldDisabler(
    `PropertyDeleteOption${propertyIndex}`
  );

  const setNotDefault = () => {
    return setValue(`${propertyName}.isDefault`, false);
  };

  const handleFullAddressOnChange = (fieldName: string, newValue: any) => {
    if (fieldName === "state") {
      //getAgencyLocations(agencyId, newValue.abbr, true);
      const properties = getValues("properties") as Array<Property>;
      if (properties && properties.length > 1) {
        properties.forEach((p, i) => {
          if (i > 0) setValue(`properties.${i}.state`, newValue);
        });
        setNotDefault();
      }
    }
  };

  React.useEffect(() => {
    setValue(`${propertyName}.showTaxCode`, showTaxCode);
  }, [showTaxCode]);

  const canDeleteProperty = (index: number) => {
    if (index === 0 && hasIssuedProducts) return false;
    return !isFileDisabled;
  };

  const onClearHandler = () => {
    if ((
      hasPendingProducts(getValues() as SCFile) ||
      hasAdditionalParty(additionalParties)
    ) && !hasIssuedProducts) {
      setMessage(
        "By changing the Property State, any Additional Parties and pending products will be lost."
      );
      setIsModalOpen();
      setPropertyToClear(propertyName);
    } else {
      deletetValuesFromProperties();
    }
  };

  const handleOnPropertyTypeChange = async (e: any, value: any) => {
    const isIntegratedPricing = getValues("pricing.isIntegratedPricing");
    if (
      propertyIndex === 0 &&
      isIntegratedPricing &&
      hasPricingProducts &&
      value
    ) {
      setPricingPremiumWithPolicyTypes();
      setSimultaneousRate();
    }
    resetShowReIssueFlags();
    setNotDefault();
  };

  const deletetValuesFromProperties = () => {
    setValue(`${propertyName}.addressOne`, "");
    setValue(`${propertyName}.addressTwo`, "");
    setValue(`${propertyName}.city`, "");
    setValue(`${propertyName}.zipCode`, "");
    setValue(`${propertyName}.taxCode`, { text: "", value: "" });
    //setValue(`${propertyName}.taxDocument`, "");
    // setShowTaxCode && setShowTaxCode(false);

    if (counties?.length !== 1)
      setValue(`${propertyName}.county`, { name: "", code: "" });

    if (municipal?.taxCodeOptions?.length !== 1)
      setValue(`${propertyName}.propertyType`, defaultPropertyType);
    setValue(`${propertyName}.legalDescription`, "");

    if (
      !hasIssuedProducts &&
      agencyStates.length !== 1 &&
      propertyIndex === 0
    ) {
      const primaryProperty = "properties.0";
      setValue(`${primaryProperty}.state`, { abbr: "", code: "" });
      setValue(`${primaryProperty}.stateOriginal`, { abbr: "", code: "" });
      //setValue(`${primaryProperty}.showTaxCode`, false);
      setValue(`${primaryProperty}.isDefault`, false);
      setValue(`${primaryProperty}.isDirty`, true);
      setShowTaxCode && setShowTaxCode(false);

      resetCounties();
      resetPropertyState();
      resetCompanyProductOptions();

      setValue(`hasNonDefaultLetterProduct`, false);
      setValue(`hasNonDefaultJacket`, false);
      setValue(`hasNonDefaultSAE`, false);

      handleFullAddressOnChange("state", { abbr: "", code: "" });
      clearErrors(getNameString(`${primaryProperty}.state`));
      handleOnClear && handleOnClear();

      if (
        hasPendingProducts(getValues() as SCFile) ||
        hasAdditionalParty(additionalParties)
      ) {
        deleteFileData();
      }
    }
  };

  useEffect(() => {
    // Default property type
    const propertyTypeName = `${propertyName}.propertyType`;
    const hasNoValue = getValues(propertyTypeName) === "";
    if (hasNoValue && !isPropertyDisabled) {
      setValue(propertyTypeName, defaultPropertyType);
    }
  }, [
    getValues,
    setValue,
    defaultPropertyType,
    propertyIndex,
    isPropertyDisabled,
  ]);

  useEffect(() => {
    if (acceptedModal && propertyToClear === propertyName) {
      deletetValuesFromProperties();
      resetDefaultValues();

      setValue(`hasNonDefaultLetterProduct`, false);
      setValue(`hasNonDefaultJacket`, false);
      setValue(`hasNonDefaultSAE`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedModal, propertyToClear]);

  return (
    <CollapsableFormSection
      index={propertyIndex}
      title={`Property ${propertyIndex + 1}`}
      label={getPropertyDescription(getValues(propertyName))}
      showDivider={true}
      showDelete={canDeleteProperty(propertyIndex)}
      onDelete={onDelete}
    >
      <Container>
        <FullAddress
          schema={propertyName}
          addressOneLabelPrefix="Property "
          addressTwoLabelPrefix="Property "
          isDisabledAddress={isPropertyDisabled}
          isStatesDisabled={isStatesDisabled}
          isPropertySection={true}
          setNotDefault={setNotDefault}
        />
        <div className="container-row type-legal-tax">
          <PropertyTypeField
            isDisabled={isPropertyDisabled}
            schema={propertyName}
            onChange={handleOnPropertyTypeChange} //{setNotDefault}
          />
          <LegalDescriptionField
            isDisabled={isPropertyDisabled}
            schema={propertyName}
            onChange={setNotDefault}
          />
          {showTaxCode && (
            <div className="tax-code-info">
              <TaxCodeField
                name={`${propertyName}.taxCode`}
                idFieldName={`${propertyName}.id`}
                cityFieldName={`${propertyName}.city`}
                countyFieldName={`${propertyName}.county.name`}
                disabled={isPropertyDisabled}
                taxCodes={municipal?.taxCodeOptions || []}
                setNotDefault={setNotDefault}
              />
              <TaxDocumentLink taxDocument={taxDocument} />
            </div>
          )}
          {!isPropertyDisabled &&
            <ClearButton onClick={onClearHandler} />
          }
        </div>
      </Container>
    </CollapsableFormSection>
  );
};

export default React.memo(PropertyData, ((prevProps: Readonly<PropsWithChildren<Props>>, nextProps: Readonly<PropsWithChildren<Props>>) => {
  return prevProps.propertyIndex === nextProps.propertyIndex &&
    prevProps.fileNameNumber === nextProps.fileNameNumber &&
    prevProps.agency?.id === nextProps?.agency?.id &&
    prevProps.agencyLocation?.id === nextProps.agencyLocation?.id &&
    prevProps.hasIssuedProducts === nextProps.hasIssuedProducts &&
    prevProps.isPopulatingExistingFile === nextProps.isPopulatingExistingFile &&
    prevProps.isStatesDisabled === nextProps.isStatesDisabled &&
    prevProps.isPropertyDisabled === nextProps.isPropertyDisabled &&
    prevProps.taxDocument === nextProps.taxDocument &&
    prevProps.showTaxCode === nextProps.showTaxCode;
}));
