import React from "react";
import { Grid } from "@mui/material";
import DatePickerInputField from "controls/global/datepicker";
import CurrencyInputField from "controls/global/currency-field";
import TextInputField from "controls/global/text-input-field";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import SelectField from "controls/global/select-field";
import { useEndorsementActions } from "utils/context/CompanyEndorsementContext";
import { ProductType } from "utils/data/enum";
import useProductOption, {
  toProductOption,
} from "utils/custom-hooks/useProductOption";

interface Props {
  schema: string;
  disabled: boolean;
  onBlurCallback?: any;
}

const OriginalPolicyForm = ({
  schema,
  disabled = false,
  onBlurCallback,
}: Props) => {
  const fieldName = `${schema}.originalJacketType`;
  const { setValue, getValues } = useFormWrapper();
  const [, { getEndorsements }] = useEndorsementActions();
  const { getProductOptionsByProductType, checkTransCodeRequired } = useProductOption();
  const [companyId, stateAbbr, currentFormType] = getValues([
    "agency.id",
    "properties.0.state.abbr",
    `${fieldName}`,
  ]);
  const productOptions = getProductOptionsByProductType(ProductType.StandaloneEndorsement);
  //const isTransCodeRequired = getValues(`${schema}.isTransCodeRequired`);
  //console.log('OrignalForm-isTransCodeRequired: ', isTransCodeRequired);
  const handleOnChange = (_: any, selectedValue: any) => {
    getEndorsements(companyId, selectedValue, stateAbbr);
    setValue(fieldName, selectedValue);
    setValue(`${schema}.endorsements`, []);
    onBlurCallback && onBlurCallback();
  };

  React.useEffect(() => {
    getEndorsements(companyId, currentFormType, stateAbbr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, currentFormType, stateAbbr]);

  React.useEffect(() => {
    const currentFormType = getValues(`${schema}.originalJacketType`);
    if (!currentFormType) return;

    const isRequired = checkTransCodeRequired(currentFormType, ProductType.StandaloneEndorsement);    
    setValue(`${schema}.isTransCodeRequired`, isRequired);
  }, [
    checkTransCodeRequired,    
    currentFormType,
    getValues,    
    schema,
    setValue,
  ]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextInputField
            label="Original Policy Number"
            name={`${schema}.originalJacketNumber`}
            disabled={disabled}
            inputProps={{ maxLength: 14 }}
            onBlur={(event: any) => {
              if (event.target.value) {
                onBlurCallback();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePickerInputField
            label="Original Policy Date"
            name={`${schema}.originalJacketDate`}
            disabled={disabled}
            onBlurCallback={(evt: any) => {
              onBlurCallback();
            }}
            //onChangeCallback={onBlurCallback}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField
            label="Type"
            name={fieldName}
            options={toProductOption(productOptions)}
            onChange={handleOnChange}
            allowsDelete={false}
            disabled={disabled}
            autosetValue={false}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CurrencyInputField
            label="Liability Amount"
            name={`${schema}.originalJacketLiability`}
            disabled={disabled}
            allowNegative={false}
            onBlur={(value: any) => {
              if (value) {
                onBlurCallback();
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OriginalPolicyForm;
