import React from "react";
import CheckBookIcon from "theme/icons/CheckBookIcon";
import ActionButton from "../action-button";
import { PERMISSIONS } from "utils/data/enum";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

type Props = {
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
};

export default function PayByCheckButton({ disabled, hidden, onClick }: Props) {
  const [{ profileSettings }] = useProfileSettingsCache();

  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const hasPayByCheckAccess = permissionCodes?.includes(PERMISSIONS.BILLING_RPTPAYCHECK);
  return (
    <ActionButton
      hidden={hidden}
      variant="outlined"
      color="primary"
      startIcon={<CheckBookIcon />}
      disabled={disabled || !hasPayByCheckAccess}
      onClick={onClick}
      name="PayByCheck"
    >
      Pay By Check
    </ActionButton>
  );
}
