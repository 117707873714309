import React from "react";
import AdditionalPartiesAutocomplete from "./AdditionalPartiesAutocomplete";

type Props = {
  schema: string;
  disabled: boolean;
};

export default function IndividualAttorney({ schema, disabled }: Props) {
  return (
    <AdditionalPartiesAutocomplete
      label="Individual Attorney"
      name="individualAttorney"
      schema={schema}
      searchProp="text"
      disabled={disabled}
      popperPlacement="top"
    />
  );
}
