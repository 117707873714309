import {
  Box,
  styled,
  Tooltip,
} from "@mui/material";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";

type Props = {
  names: string;
  maxLines?: number;
  separator?: string;
  joinSeparator?: string;
};

const MAX_LINES_DISPLAYED = 2;
const DEFAULT_SEPARATOR = "|";
const JOIN_SEPARATOR = "\n";

const TitleTooltip = styled("div")({
  fontSize: fontSize.large,
  whiteSpace: "pre-line",
  "& .MuiTooltip-arrow": {
    color: colors.white,
  },
});

const BoxBody = styled(Box)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // width: "80%",
});

function MultiLineCell({
  names,
  maxLines = MAX_LINES_DISPLAYED,
  separator = DEFAULT_SEPARATOR,
  joinSeparator = JOIN_SEPARATOR,
}: Props) {
  const [nameList, setNameList] = useState<string[]>([]);
  const [bodyHtml, setBodyHtml] = useState<JSX.Element[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const list = names.split(separator);
    setNameList(list);
    setBodyHtml(
      //list.slice(0, maxLines).map((n) => <Box key={n}>{n}</Box>)
      list.slice(0, maxLines).map((n,index) => (
        <Box key={n}>
          {n}
          {index === (maxLines -1) && list.length > maxLines ? "...":""}
        </Box>
      ))
    );
    setShowTooltip(list.length > maxLines);
  }, [names]);

  return showTooltip
    ? <Tooltip
      arrow
      title={
        <TitleTooltip>
          {nameList.join(joinSeparator)}
        </TitleTooltip>}
    >
      <BoxBody>
        {bodyHtml}
      </BoxBody>
    </Tooltip>
    :
    <>
      {bodyHtml}
    </>;
}

export default MultiLineCell;
