import {  styled } from "@mui/material";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { PaymentBase } from "entities/UIModel/PaymentBase";
import React from "react";
import { Table } from "react-bootstrap";
import {
  colors,
  margin,
} from "theme/defaultStyle";
import {
  formatCurrency,
  formatDate,
} from "utils/shared";

type Props = {
  file: PaymentBase;
};

const StyledTable = styled(Table)({
  "&.details-table": {
    backgroundColor: colors.grey10,
    boxShadow: "0px 2px 5px 0px rgba(87, 87, 87, 0.10) inset",
    margin: margin.zero,
    "& th, & td": {
      border: "none",
    },
    "& .name": {
      paddingLeft: "109px",
      width: "360px",
    },
    "& .currency": {
      textAlign: "end",
      width: "150px",
    },
    "& .code": {
      width: "90px",
    },
    "& .date": {
      width: "120px",
    },
    "& .formName": {
      width: "360px",
    },
  },
});

const PaymentDetailsGrid = ({ file }: Props) => {
  return (
    <StyledTable className="details-table">
      <thead>
        <th className="name">Products</th>
        <th className="formName">Form Name </th>
        <th className="county code">Muni/Cnty</th>
        <th className="transcode code">Transcode</th>
        <th className="liability currency">Liability Amt.</th>
        <th className="issueDate date">Issue Date</th>
        <th className="effectiveDate date">Effective Date</th>
        <th className="premiumFees currency">Premium/Fees</th>
        <th className="taxRiskRate currency">Tax/Risk Rate</th>
        <th className="agentRetention currency">Agent Retention</th>
        <th className="totalDue currency">Total Due</th>
        <th className="filler"></th>
      </thead>
      <tbody>
        {file.details.map((d) => (
          <tr key={d.product}>
            <td className="name">{d.product}</td>
            <td>
              <TooltipCell title={d.form}>
                <span>{d.form}</span>
              </TooltipCell>
            </td>
            <td>
              <TooltipCell title={d.muniCnty}>
                <span>{d.muniCnty}</span>
              </TooltipCell>
            </td>
            <td>
              <TooltipCell title={d.transCode}>
                <span>{d.transCode}</span>
              </TooltipCell>
            </td>
            <td className="currency">
              <TooltipCell title={formatCurrency(d.liability)}>
                <span>{formatCurrency(d.liability)}</span>
              </TooltipCell>
            </td>
            <td>
              <TooltipCell title={formatDate(d.productIssueDate)}>
                <span>{formatDate(d.productIssueDate)}</span>
              </TooltipCell>
            </td>
            <td>
              <TooltipCell title={formatDate(d.productEffectiveDate)}>
                <span>{formatDate(d.productEffectiveDate)}</span>
              </TooltipCell>
            </td>
            <td className="currency">
              <TooltipCell title={formatCurrency(d.actualFee)}>
                <span>{formatCurrency(d.actualFee)}</span>
              </TooltipCell>
            </td>
            <td className="currency">
              <TooltipCell title={formatCurrency(d.taxRiskRate)}>
                <span>{formatCurrency(d.taxRiskRate)}</span>
              </TooltipCell>
            </td>
            <td className="currency">
              <TooltipCell title={formatCurrency(d.actualRetention)}>
                <span>{formatCurrency(d.actualRetention)}</span>
              </TooltipCell>
            </td>
            <td className="currency">
              <TooltipCell title={formatCurrency(d.amountDue)}>
                <span>{formatCurrency(d.amountDue)}</span>
              </TooltipCell>
            </td>
            <td className="filler"></td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default PaymentDetailsGrid;