import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SavedContact } from "entities/ApiModel";
import { SavedContactResponse } from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

interface State {
  isLoading: boolean;
  error: string | null;
}
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const setLoading =
  () =>
    ({ setState }: StoreApi) => {
      setState({ isLoading: true });
    };

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const setSavedContacts =
  (savedContactsFromMiddleware: Array<SavedContact>) =>
    ({ setState }: StoreApi) => {
      if (
        !savedContactsFromMiddleware ||
        savedContactsFromMiddleware.length === 0
      ) {
        setState({ isLoading: false });
        return [];
      }

      let savedContacts: SavedContactResponse[] = savedContactsFromMiddleware.map(
        (contact) => {
          return {
            IdentityKey: contact.IdentityKey || -1,
            PartyRoleTypeCode: contact.PartyRoleTypeCode,
            ContactCompanyName: contact.ContactCompanyName,
            ContactCompanyNameAndAddress: contact.ContactDisplayName,
            Address: contact.Address ?? "",
            Address2: contact.Address2 ?? "",
            City: contact.City ?? "",
            StateCode: contact.StateCode ?? "",
            StateName: contact.StateName ?? "",
            StateAbbr: contact.StateAbbr ?? "",
            Zip: contact.Zip,
            CountryCodeNum: contact.CountryCodeNum,
            ContactAdditionalName: contact.ContactAdditionalName,
            Phone: contact.Phone,
            Mobile: contact.Mobile,
            Fax: contact.Fax,
            Email: contact.Email,
            MigrationKey: contact.MigrationKey || '',
          };
        }
      );

      setState({ isLoading: false });
      return savedContacts;
    };

const setEntriesPerPage =
  (count: number) =>
    ({ setState }: StoreApi) => {
      // setState({ entriesPerPage: count });
      return count;
    };

const actions = {
  getSavedContacts:
    (companyName: string, partyRoleTypeCode: string) =>
      async ({ dispatch }: StoreApi): Promise<SavedContactResponse[]> => {
        try {
          dispatch(setLoading());

          const { data } = await axiosSecuredInstance.get(
            `user/contactsearch?ContactCompanyName=${companyName}&PartyRoleTypeCode=${partyRoleTypeCode}`
          );
          return dispatch(setSavedContacts(data));
        } catch (error: any) {
          dispatch(setError(error));
          return [];
        }
      },

  getRowsPerPageSetting:
    () =>
      async ({ dispatch }: StoreApi) => {
        const { data } = await axiosSecuredInstance.get(
          "/UISettings/grids/rowsPerPageSetting"
        );

        return dispatch(setEntriesPerPage(data ?? 5));
      },
  setRowsPerPageSetting:
    (count: number) =>
      async () => {
        try {
          await axiosSecuredInstance.post(`/UISettings/grids/rowsPerPageSetting?count=${count}`);
        } catch (error) {
          console.error(error);
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    isLoading: false,
    error: null,
  },
  actions,
  name: "user",
});

const hook = createHook(Store);
export const useUser = () => { return hook(); };
