import React from "react";
import ActionButton from "../action-button";
import CheckBookIcon from "theme/icons/CheckBookIcon";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

interface Props {
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
}
export default function ReportAndPayByCheckButton({ disabled, hidden, onClick }: Props) {
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasPayByCheckAccess = permissionCodes?.includes(PERMISSIONS.BILLING_RPTPAYCHECK);
  
  // console.log('disabled:', disabled, 'hasPayByCheckAccess:', hasPayByCheckAccess);  
  return (
    <ActionButton
      variant="outlined"
      color="primary"
      startIcon={<CheckBookIcon />}
      hidden={hidden}
      disabled={disabled || !hasPayByCheckAccess}
      onClick={onClick}
      name="ReportAndPay"
    >
      Report & Pay By Check
    </ActionButton>
  );
}
