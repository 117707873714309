import {
  Box,
  Button,
  styled,
} from "@mui/material";
import ActionButton from "controls/global/action-button/ActionButton";
import SubmitTicketDialog from "controls/global/submit-ticket-dialog/SubmitTicketDialog";
import { LayoutBodyContext } from "layout/LayoutBodyContext";
import React, {
  ChangeEvent,
  useContext,
  useRef,
  useState,
} from "react";
import theme from "theme/default";
import {
  borderRadius,
  boxShadows,
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
 } from "theme/defaultStyle";
import HelpIcon from "theme/icons/HelpIcon";
import useTitle from "utils/custom-hooks/useTitle";
import ScrollToTopArrow from "../../controls/global/scroll-to-top-arrow";
import TextInput from "../../controls/global/text-input-field/TextInput";
import FAQ from "./components/faq";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import QuickReferenceCard from "./components/quick-reference-card";
import RegisterFormDialog from "./components/register-form/RegisterFormDialog";
import ReleaseNote from "./components/release-note";
import cssClasses from "./HelpCenter.module.css";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

export type NavigationItemType = {
  title: string;
  ref: React.MutableRefObject<HTMLElement | undefined>;
  requiredPermission: boolean;
};

const Container = styled("div")({
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: borderRadius.xlarge,
  borderBottomRightRadius: borderRadius.xlarge,
  position: "relative",
});

const StyledBox = styled(Box)({
  borderRadius: borderRadius.medium,
  boxShadow: boxShadows.boxShadow02,
  marginBottom: margin.xlarge1,
  width: "176px",
  "& .MuiOutlinedInput-root": {
    borderRadius: borderRadius.medium,
    "& .MuiOutlinedInput-input": {
      borderRadius: borderRadius.medium,
      fontSize: fontSize.base,
      padding: `${padding.small1} ${padding.large1}`,
    },
  },
});

const NavigationButton = styled(Button)({
  display: "block",
  margin: `${margin.xsmall} auto`,
  minWidth: "150px",
  padding: padding.small,
  textAlign: "left",
  height: "45px",
  borderRadius: "8px",
});

const StyledRegisterBox = styled(Box)({
  marginTop: margin.medium2,
  whiteSpace: "normal",
});

const StyledButton = styled(ActionButton)({
  backgroundImage: `linear-gradient(${colors.blue05}, ${colors.blue01})`,
  fontWeight: fontWeight.normal1,
  height: "200px",
  lineHeight: "150%",
  margin: `${margin.xsmall} 0`,
  padding: padding.xlarge21,
  width: "176px",
  "&.ActionButton-root": {
    borderRadius: borderRadius.xlarge,
  },
});

const HelpCenter = () => {
  useTitle("Stewart Connect - Help Center");
  const bodyRef = useContext(LayoutBodyContext);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [searchTags, setSearchTags] = useState<string>("");
  const [isSubmitTicketDialogOpen, setIsSubmitTicketDialogOpen] = useState(false);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const quickReferenceCardRef = useRef<HTMLElement>();
  const releaseNoteRef = useRef<HTMLElement>();
  const faqRef = useRef<HTMLElement>();
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const quickReferenceAccess = permissionCodes?.includes(PERMISSIONS.HELP_ACCESS_QRC);
  const releaseNoteAccess = permissionCodes?.includes(PERMISSIONS.HELP_ACCESS_RELEASENOTES);
  const faQAccess = permissionCodes?.includes(PERMISSIONS.HELP_ACCESS_FAQ);

  const NAVIGATION_ITEMS: NavigationItemType[] = [
    {
      title: "Training Materials",
      ref: quickReferenceCardRef,
      requiredPermission: quickReferenceAccess,
    },
    {
      title: "Release Notes",
      ref: releaseNoteRef,
      requiredPermission: releaseNoteAccess,
    },
    {
      title: "FAQs",
      ref: faqRef,
      requiredPermission: faQAccess,
    },
  ];

  const filteredNavigationItems = NAVIGATION_ITEMS.filter((item) => item.requiredPermission);

  const onScrollToTop = () => {
    setScrollToTop(!scrollToTop);
  };

  const navigateToPlace = (childRef: React.MutableRefObject<HTMLElement | undefined>) => {
    bodyRef?.current?.scrollTo({
      top: childRef.current ? childRef.current.offsetTop : 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <Box className={cssClasses.helpCenterContent}>
        <Box className={cssClasses.containerMain}>
          <Box className={cssClasses.leftContent}>
            <StyledBox>
              <TextInput
                type="text"
                name="searchInput"
                value={searchTags}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  const { value } = event.target;
                  if (searchTags !== value) {
                    if (value.trim().length === 0) {
                      setSearchTags("");
                    } else {
                      setSearchTags(value);
                    }
                  }
                }}
                placeholder="Keyword Search"
              />
            </StyledBox>
            <Box className={cssClasses.menu}>
              {filteredNavigationItems.map(({ title, ref }: NavigationItemType) => {
                return (
                  <Box key={title}>
                    <NavigationButton
                      className={cssClasses.menuItem}
                      onClick={() => {
                        navigateToPlace(ref);
                      }}
                    >
                      <span className={cssClasses.text}>{title}</span>
                    </NavigationButton>
                  </Box>
                );
              })}
            </Box>
            <Box>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<HelpIcon />}
                onClick={() => setIsSubmitTicketDialogOpen(true)}
              >
                Submit A Ticket
              </StyledButton>
            </Box>
            <StyledRegisterBox>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<PersonAddIcon />}
                onClick={() => setIsRegisterFormOpen(true)}
              >
                Request a New
                <br /> Connect User
              </StyledButton>
            </StyledRegisterBox>
          </Box>
          <Box className={cssClasses.content}>
            {quickReferenceAccess && (
              <div ref={quickReferenceCardRef as any}>
                <QuickReferenceCard searchTags={searchTags || ""} />
              </div>
            )}
            {releaseNoteAccess && (
              <div ref={releaseNoteRef as any}>
                <ReleaseNote searchTags={searchTags || ""} />
              </div>
            )}
            {faQAccess && (
              <div ref={faqRef as any}>
                <FAQ searchTags={searchTags || ""} />
              </div>
            )}
          </Box>
        </Box>
        <br />
        <SubmitTicketDialog
          isOpen={isSubmitTicketDialogOpen}
          onConfirm={() => setIsSubmitTicketDialogOpen(false)}
          onCancel={() => setIsSubmitTicketDialogOpen(false)}
        />
        <RegisterFormDialog
          isOpen={isRegisterFormOpen}
          setIsRegisterFormOpen={setIsRegisterFormOpen}
          onConfirm={() => setIsRegisterFormOpen(false)}
          onCancel={() => setIsRegisterFormOpen(false)}
        />
        <ScrollToTopArrow onScrollToTop={onScrollToTop} />
      </Box>
    </Container>
  );
};

export default HelpCenter;
