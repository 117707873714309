import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog"
import React from "react"
export interface StateChangeConfirmationProps {
    confirmationMessage: string;
    isConfirmationOpen: boolean;
    handleYes: () => void;
    handleNo: () => void;
    singleActionButton?: boolean;
}

const StateChangeConfirmation = ({ confirmationMessage, isConfirmationOpen, handleYes, handleNo, singleActionButton = false }: StateChangeConfirmationProps) => {
    return (
        <ConfirmationDialog
            confirmationMessage={confirmationMessage}
            isOpen={isConfirmationOpen}
            onYes={handleYes}
            onNo={handleNo}
            singleActionButtonText={singleActionButton ? "OK" : "Submit"}
            buttonNoText="Cancel"
            singleActionButton={singleActionButton}
        />
    )
}

export default StateChangeConfirmation