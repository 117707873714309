import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import React, {
  ReactNode,
} from "react";
import theme from "theme/default";
import {
  margin,
  padding,
} from "theme/defaultStyle";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle
} from "../stewart-dialog/StewartDialog";

interface OriginalPolicyProps {
  isOpen: boolean;
  title?: string;
  disabled?: boolean;
  allowSave?: boolean;
  children: ReactNode;
  onClose: () => void;
  onSave: () => void;
}

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    minWidth:"400px",
    maxWidth :"600px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "unset",
      width: "330px",
    },
  }
});

const StyledDialogContent = styled(StewartDialogContent)({
  margin: margin.zero,
  paddingTop: padding.xlarge21,
});

const OriginalPolicyDialog = ({
  isOpen,
  title = "Original Policy",
  disabled = false,
  allowSave = true,
  children,
  onSave,
  onClose,
}: OriginalPolicyProps) => {

  return (
    <StyledDialog
      open={isOpen}
      maxWidth="md"
    >
      <StewartDialogTitle type={"INFO"}>
        {title}
        <DialogTitleCloseButton onClick={onClose}/>
      </StewartDialogTitle>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      <StewartDialogActions>
        {disabled ? (
          <ActionButton
            autoFocus
            variant="outlined"
            color="primary"
            onClick={() => {
              onClose?.();
            }}
            startIcon={<ClearIcon />}
          >
            Close
          </ActionButton>
        ) : (
          <>
            <ActionButton
              autoFocus
              variant="outlined"
              color="primary"
              style={{ minWidth: "100px" }}
              onClick={onSave}
              disabled={!allowSave}
              startIcon={<CheckIcon />}
            >
              Save
            </ActionButton>
          </>
        )}
      </StewartDialogActions>
    </StyledDialog>
  );
};

export default OriginalPolicyDialog;
