import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import React from "react";
import { fontSize } from "theme/defaultStyle";

const StyledFormControlLabel = styled(FormControlLabel)({
  marginBottom: 0,
  "& .MuiTypography-root": {
    fontSize: fontSize.large
  },
});

type Props = {
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

export default function RadioGroupField({
  defaultValue = "N",
  disabled,
  onChange,
}: Props) {
  const [selected, setSelected] = React.useState<string>(defaultValue);

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <RadioGroup row value={selected} onChange={handleChange}>
      <StyledFormControlLabel
        label="Yes"
        value="Y"
        disabled={disabled}
        control={<Radio />}
      />
      <StyledFormControlLabel
        label="No"
        value="N"
        disabled={disabled}
        control={<Radio />}
      />
    </RadioGroup>
  );
}
