import React from "react";

export default function CashClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.67"
      height="23.33"
      viewBox="0 0 24 24"
    >
      <path
        fill="white"
        d="m17.5 16.82l2.44 1.41l-.75 1.3L16 17.69V14h1.5v2.82M24 17c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-.34.03-.67.08-1H2V4h18v6.68c2.36 1.13 4 3.53 4 6.32m-13.32-3c.18-.36.37-.7.6-1.03c-.09.03-.18.03-.28.03c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3c0 .25-.04.5-.1.73c.94-.46 1.99-.73 3.1-.73c.34 0 .67.03 1 .08V8a2 2 0 0 1-2-2H6c0 1.11-.89 2-2 2v4a2 2 0 0 1 2 2h4.68M22 17c0-2.76-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5Z"
      />
    </svg>
  );
}