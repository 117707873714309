import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Box,
  Snackbar,
  SnackbarProps,
  styled,
} from "@mui/material";
import React from "react";
import {
  colors,
  defaultStyle,
  fontSize,
  margin,
} from "theme/defaultStyle";
import { useFlashMessageState } from "utils/context/FlashMessageContext";

const { control } = defaultStyle;

const SnackbarStyled = styled(Snackbar)({
  "& div.MuiSnackbarContent-root": {
    backgroundColor: colors.red01,
    borderRadius: control.borderRadius,
    color: colors.white,
    fontSize: fontSize.base
  },
});

const iconStyles = { fontSize: 20, color: colors.white, marginRight: margin.medium };

const FlashMessage = (props: SnackbarProps) => {
  const [{ open, message }] = useFlashMessageState();


  return (
    <SnackbarStyled {...props}
      open={open}
      message={<Box><ReportProblemIcon style={iconStyles} />{message}</Box>}
    />
  );
};

export default FlashMessage;
