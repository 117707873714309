import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Dialog,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";


const VideoPlayerDialog = styled(Dialog)({
  "& div.MuiPaper-root": {
    maxWidth: "none",
    overflow: "hidden",
  },
  "& .appBarParentRoot": {
    flexGrow: 1,
  },
  "& .title": {
    flexGrow: 1,
    fontSize: fontSize.base,
    fontWeight: fontWeight.bold1,
    lineHeight: 1.6,
    marginBottom: margin.zero,
  },
  "& .pdfContainer": {
    display: "block",
    height: "100%",
    position: "relative",
    width: "100%",
  },
  "& .divWrapper": {
    height: "100%",
    width: "100%",
  },
  "& .videoPlayer": {
    marginBottom: "-6px",
    width: "50vw",
  },
});

type VideoPlayerProps = {};

export type VideoPlayerRefType = {
  open: (title: string, src: string) => Promise<void>;
};

const VideoPlayer = (props: VideoPlayerProps, ref: Ref<VideoPlayerRefType>) => {
  const [open, setOpen] = useState(false);
  const [titleSource, setTitleSource] = useState<string>();
  const [source, setSource] = useState<string>();

  useImperativeHandle(ref, () => ({
    open: async (title, src) => {
      setTitleSource(title);
      setSource(src);
      setOpen(true);
    },
  }));

  function onClose(): void {
    setOpen(false);
    setTitleSource("");
    setSource("");
  }

  return (
    <VideoPlayerDialog
      disableEscapeKeyDown={true}
      open={open}
    >
      <div className={"appBarParentRoot"}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={"title"}>{titleSource}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={"pdfContainer"}>
        <div
          onContextMenu={(e) => e.preventDefault()}
          className={"divWrapper"}
        >
          <video
            className={"videoPlayer"}
            controls
            controlsList="nodownload"
          >
            <source src={source} type="video/mp4" />
            <track src="" kind="captions" />
          </video>
        </div>
      </div>
    </VideoPlayerDialog>
  );
};

export default forwardRef<VideoPlayerRefType, VideoPlayerProps>(VideoPlayer);
