interface SmallNotes {
  textFirst: string;
  textSecond: string;
}

interface DigitalSolutionItem {
  title: string;
  description: string[] | Record<string, any>;
  smallnotes?: { [key: string]: string; };
}

interface DigitalSolutionVideo {
  video: {
    title: string;
    link: string;
  };
}

interface DigitalSolutionButtons {
  text: string;
  link: string;
}

interface DigitalSolutionTableTier {
  tier: string;
  users: string;
  keyServices: string;
  annualPlatformFee: string;
  annualPlatformFeeWithDiscount: string;
  transactionFee: string;
}

interface DigitalSolutionTableInfo {
  columns: string[];
  tiers: DigitalSolutionTableTier[];
  buttons: DigitalSolutionButtons;
  data: string[];
}

interface DigitalSolution {
  id: string;
  heading1: string;
  heading2: string;
  heading3: string[];
  items: DigitalSolutionItem[];
  addtionalInfo: {
    data: string[];
  };
  buttons?: DigitalSolutionButtons;
  tableInfo?: DigitalSolutionTableInfo;
  videos?: DigitalSolutionVideo[];
  smallnotes?: SmallNotes;
  sectionButton?: {
    text: string;
    link: string;
  };
  notaryHeading?: {
    title1: string;
    title2: string;
  };
}

export const digitalSolutionsData: DigitalSolution[] = [
  {
    id: "CertifID",
    heading1: "Keep Your Customers Safe From Wire Fraud",
    heading2: "As a Stewart Trusted Provider™ you get exclusive access to CertifID’s fraud protection software and services",
    heading3: [
      "1 in 3 real estate transactions are targeted by fraudsters. You have the power to avoid those odds by leveraging our partnership with CertifID. Stop worrying and start preventing and mitigating the looming threat of cybercriminals with CertifID’s core solutions:",
    ],
    items: [
      {
        title: "Send secure wire instructions",
        description: [
          "Forget the countless emails and phone calls that put your wire transfers at risk. CertifID makes it easy to manage and share wire instructions securely, keeping your transaction details safe from fraud and saving you time.",
        ],
      },
      {
        title: "Quickly collect & confirm",
        description: [
          "Skip the hassle of trying to track down and validate seller bank information. CertifID enables you collect bank details easily and securely while ensuring they’re accurate and free from tampering.",
        ],
      },
      {
        title: "Protect your payoff",
        description: [
          "Eliminates the risk of payoff fraud with CertifID’s PayoffProtect. Now, you have the power to remove the callback to lenders, streamline internal validation and insure every one of your payoffs for up to $1M",
        ],
      },
    ],
    videos: [
      {
        video: {
          title: "Stewart & CertifID",
          link: "https://www.youtube.com/embed/W_t-yctntC4",
        },
      },
      {
        video: {
          title: "Stewart Trusted Providers Testimonials",
          link: "https://drive.google.com/file/d/1TIPYNQRkzeeGKFn8aE1fxymWC5HLpREy/preview",
        },
      },
    ],
    addtionalInfo: {
      data: [
        " Why take unnecessary risks? Get started today and save 70% as a Stewart Trusted Provider™ ",
      ],
    },
    buttons: {
      text: "Sign Up",
      link: "https://marketing.certifid.com/stewart-trusted-providers",
    },
  },
  {
    id: "Digital Funds Transfer Mobile App - ZOCCAM",
    heading1: "ZOCCAM Streamlines the Closing Process",
    heading2:"ZOCCAM® provides a simple, more secure way to handle earnest money and contract delivery. The mobile deposit feature reduces liability by eliminating manual deposits and allowing users to send earnest money anytime, from anywhere.",
    heading3: [
      "ZOCCAM sends real--time email notifications to all parties upon deposit, keeping the title company branding top of mind. And orders can be opened at a much faster pace.",
      "ZOCCAM is the simple, secure and safe way to create smooth closings.",
    ],
    items: [
      {
        title: "Instant gratification benefits:",
        description: [
          "Take manual delivery, deposit and document management out of your closing process",
          "Reduce risk and transaction time while increasing security and transparency ",
          "Create trust in the transaction by authenticating the borrower’s identification",
        ],
      },
      {
        title: "Our smooth closing offerings:",
        description: [
          "Earnest money deposits",
          "Wiring instructions",
          "ID check",
          "Closing funds",
          "Real-time notifications",
        ],
      },
      {
        title: "Partnership benefits",
        description: [
          "Stewart agents will receive a 25% discount of the initial onboarding fee when choosing ZOCCAM.",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "Don’t wait to give your business a competitive advantage. Sign up for ZOCCAM now by emailing <a href='mailto:info@zoccam.com'>info@zoccam.com</a> and stating that you are a Stewart Agent.",
      ],
    },
    buttons: {
      text: "Sign Up Now",
      link: "mailto:info@zoccam.com",
    },
  },
  {
    id: "eClosing Online Collaboration Platform - Pavaso",
    heading1: "Pavaso",
    heading2:
      "Pavaso provides a digital closing platform enabling collaboration with the stakeholders involved in the closing transaction, primarily the lender, title company and consumer. It supports both hybrid and full eClosing capabilities.",
    heading3: [
      "Through online portal technology, the lender and title company can upload their documents for viewing by the consumer in advance of the closing and the borrower can acknowledge the documents that they have reviewed, approving them in advance.",
      "The platform also enables electronic signatures and dates to be affixed to all documents by the consumer as well as enable the notarial process to be digitized where allowable.",
    ],
    items: [
      {
        title: "Pricing",
        description: [
          "We are happy to announce that Stewart has negotiated a premier discounted rate for our Stewart Trusted Providers.",
        ],
      },
      {
        title: "Transaction Fees",
        description: [
          {
            "Lender Initiated Transaction": ["$20.00 per transaction"],
            "Title Initiated Transaction": [
              "$20.00 – Cash Transaction",
              "$75.00 – Any other transaction involving a lender who is not a Pavaso customer where that agent opts to use the system to manage the closing and lender docs eNotary Fees – only required for notaries in states where eNotary is pemitted.",
              "$99.00 per notary – one time fee. This fee will be waived for all agent notaries onboarded by May, 31, 2018",
              "$50.00 per notary – annually to validate notary with state",
            ],
          },
        ],
      },
      {
        title: "Training",
        description: ["$750.00 per additional training session"],
      },
    ],
    addtionalInfo: {
      data: [
        "<div><h6>Pavaso Contact Request</h6><div>Please contact the following emails in order to receive more information:</div><ul><li><a href='mailto:sales@pavaso.com'>sales@pavaso.com</a></li><li><a href='mailto:Scott.Gillen@stewart.com'>Scott.Gillen@stewart.com</a></li><li><a href='mailto:aturner@stewart.com'>aturner@stewart.com</a></li></ul></div>",
      ],
    },
    // buttons: {
    //   text: "Frequently Asked Questions",
    //   link: "https://www.stewart.com/content/dam/stewart/agency-services/pdfs/Pavaso%20FAQ%20Flyer.pdf",
    // },
  },
  {
    id: "Electronic Signature Platform - DocuSign",
    heading1: "Electronic Signature Platform – DocuSign",
    heading2: "Please Your Clients, Grow Your Business",
    heading3: [
      "Give your business a client-pleasing advantage. As a Stewart Trusted Provider™, you can help clients save time and gain efficiency with electronic signatures powered by DocuSign®. This benefit is yours at a tremendous savings as part of our Stewart Preferred™ program.",
    ],
    items: [
      {
        title:
          "With DocuSign, clients can sign any document not requiring notarization and enjoy a better process as soon as they’re in escrow. The benefits are many:",
        description: [
          "Quicker document return",
          "Improved accuracy of information",
          "Increased associate efficiency",
          " A smoother process your clients will enjoy",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "Say goodbye to printing, scanning and making post office runs. With DocuSign, clients simply sign digitally to get the deal done. That helps establish your agency as one that provides a better, more enjoyable customer experience.",
        "Don’t wait to give your business a competitive advantage. <a href='https://go.docusign.com/cobranded/STEWART-preferred/'target='_Sign up for DocuSign today' >Sign up for DocuSign today.</a>",
        "If you have questions give DocuSign a call at (310) 409-0384 ext 44807",
      ],
    },
  },
  {
    id: "eNotary Log",
    heading1: "eNotary Log: The Online Three-Step Notary",
    heading2:
      "As a Stewart Trusted Provider, you can always count on us to offer tools and resources that help you do business quickly and efficiently. We’re excited to introduce a service that provides everything your business needs to notarize documents in a safe and convenient space: eNotary Log.",
    heading3: [
      "eNotary Log is an online notarization service that provides multiple, remote notary options, including agent notaries, eNotaryLog notaries and signing services. It allows your clients to notarize anytime, anywhere via a simple three-step process: identity verification, upload of documents and notary of documents.",
    ],
    items: [
      {
        title:
          "The platform includes a convenient organizational dashboard with multiple access points that seamlessly integrate into your preexisting systems. With eNotary Log you can access:",
        description: [
          "Previously notarized documents",
          " Notary session videos",
          "Session details",
          "Audit trails ",
        ],
      },
      {
        title:
          "What’s more, the MISMO® certified eNotary Log boasts a host of benefits including:",
        description: [
          "Time and cost reduction",
          "No shipping expenses",
          "Improved recording and delivery",
          "Secure audio/video storage trail",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "Get all this and more with the eNotary Log. Plus, Stewart Trusted Providers <strong>get 10% off.</strong>",
        "<strong>To sign up, contact us at <a href='mailto:enterprise@enotarylog.com'>enterprise@enotarylog.com</a> or 855.255.5808, mention you’re a Stewart agent, and we’ll get you started.</strong>",
      ],
    },
    buttons: {
      text: "Learn More",
      link: "https://enotarylog.com/",
    },
  },
  {
    id: "Loan Origination System Partnerships",
    heading1:
      "Simplify title and mortgage transactions with Mortgage Cadence Collaboration Center",
    heading2:
      "Being a Stewart Trusted Provider™ gives you access to a platform that lets you collaborate seamlessly and safely with all the companies you work with. And everything is integrated within Mortgage Cadence’s Loan Origination System.",
    heading3: [
      "The Collaboration Center brings people and tools together. No more constant switching between inboxes, browsers and production systems. Teams can automate processes, deliver documents, exchange data, upload video files from remote notarizations and securely communicate in real-time-all from within the loan.",
    ],
    items: [
      {
        title: "Use this platform to:",
        description: [
          "Increase productivity by letting everyone work in the transaction",
          " Improve digital security by eliminating emails  ",
          "Help grow your business by helping you provide better digital experiences ",
        ],
      },
      {
        title: "Trusted provider discount",
        description: [
          "In addition to all the benefits, when Stewart Trusted Providers sign up for a Professional Collaboration Center account by December 31, 2020, their $495 first-year membership fee is waived. ",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "For information about Collaboration Center, visit <a href='https://collaborationcenter.com' target='_collaborationcenter.com'>collaborationcenter.com</a> , <a href='https://mortgagecadence.com ' target='_mortgagecadence.com'>mortgagecadence.com </a> or talk to your Stewart Agency representative. , ",
        "To request your invitation for a Professional Collaboration Center account contact: <a href='mailto:cadencecollab@stewart.com' target='_cadencecollab@stewart.com'>cadencecollab@stewart.com</a>",
      ],
    },
  },
  {
    id: "Remote Online Notarization - NotaryCam",
    heading1: "Create Better Digital Closing Experiences",
    heading2:
      "NotaryCam® is a digital closing platform that gives your customers the freedom to close from virtually anywhere. Built by closing professionals for closing professionals, NotaryCam products let you join your signers at an online closing while an NNA Certified Signing Agent walks your clients through the process. The National Association of REALTORS® endorses NotaryCam as a member of the NAR REACH Class of 2015.",
    heading3: [],
    items: [
      {
        title: "At the virtual closing table, NotaryCam:",
        description: [
          "Offers a same-day request option",
          "Eliminates post-close scanning",
          "Improves security, with closing packages sent by encrypted email or secure API transfer",
          "Offers differentiation in a competitive marketplace",
          "Uses NNA Certified Notary Signing Agents trained on your workflow and scripts",
          "Makes it easy for agents and attorneys to attend closings",
          "Improves customer experience",
          "Allows signers to sign remotely from the comfort of their home or hotel",
          "Reduces costs",
        ],
      },
      {
        title: " <b>Stewart Preferred Pricing</b> \n <span style='font-weight: normal'>Being a Stewart Trusted Provider™ makes you eligible for discounted rates on NotaryCam. Here is a rundown of costs.</span> \n \n Transactional Pricing :",
        description: [
          {
            "Closing Ceremony: $125": [
              "For all closing types – seller, buyer, refi",
              "Includes witness/additional attendees, additional tagging, incomplete ID Proofing",
            ],
          },
          {
            "Same Day Request Surcharge: $25": [
              "For requests made 24 hours from submission to requested appointment date",
            ],
          },
          {
            "Same Day Cancellation fee: $75": [
              "Within three hours prior to confirmed appointment",
              "Cancellation fee will be charged if we are unable to complete the closing ceremony due to the following: signer misses appointment, documents are missing, incorrect or both, signer declines to move forward, redraw required, etc.*",
            ],
          },
          {
            "International Signing Surcharge: $25": [
              "Single Doc: $30 Domestic / $80 International",
              "POA/Warranty, Quit Claim Deed <br/><small>Please make arrangements to handle the wet signature of the note if lender is not MERS eRegistry-certified and or does not have an eVault identified.  * If applicable </small>",
            ],
          },
        ],
        smallnotes: {
          textFirst:
            "Please make arrangements to handle the wet signature of the note if lender is not MERS eRegistry-certified and/or does not have an eVault identified",
          textSecond: "No minimum usage required",
          textThird: "Start your NotaryCam transaction.",
        },
      },
      {
        title: "Contract Pricing:",
        description: [
          "Notary Onboarding: $99",
          "Notary Monthly Fee: $50",
          "Closings: $50 ",
        ],
      },
    ],
    sectionButton: {
      text: "See a Demo",
      link: "https://www.screencast.com/t/g6GXOSG7CGsn",
    },
    notaryHeading: {
      title1: "Stewart Preferred Pricing",
      title2:
        "Being a Stewart Trusted Provider™ makes you eligible for discounted rates on NotaryCam. Here is a rundown of costs.",
    },
    buttons: {
      text: "Start your NotaryCam transaction",
      link: "https://www.notarycam.com/stewartpreferredagents/",
    },
    addtionalInfo: {
      data: [
        "<small>No minimum usage required</small>",
      ],
    },
  },
  {
    id: "Remote Online Notarization - Notarize",
    heading1: "Remote Online Notarization with Notarize",
    heading2: "",
    heading3: [
      "<strong>Streamline closings and create better experiences for your customers.</strong>",
      "Customers are always looking for more convenient ways to do things, especially ones that fit their busy schedules. Digital closing solutions provide that, and help create better experiences, for your buyer, seller, lender and real estate agent customers. For example, Remote Online Notarization (RON) allows your customers to sign closing documents online through a video conference with notary via a computer with a webcam. This requires you to have an online platform to host documents and facilitate electronic signatures and electronic notarization. Notarize provides that platform.",
      "Notarize lets your customers securely view documents, connect with a notary agent live, and save and share notarized documents – anytime, from anywhere.",
    ],
    items: [
    ],
    addtionalInfo: {
      data: [
        "As a Stewart Trusted Provider, you can sign up for Notarize at a discounted price. The chart below will give you an idea of your costs.",
      ],
    },
    tableInfo: {
      columns: [
        "Tier",
        "Users",
        "Key Services",
        "Annual Platform Fee",
        "Annual Platform Fee \n w/Stewart Agent Discount",
        "Transaction Fee \n (per Notary Session)",
      ],
      tiers: [
        {
          tier: "Starter",
          users: "1 User",
          keyServices: "Self-serve onboarding \n  Commissioned and insured notary \n ChatSupport ",
          annualPlatformFee: "$0",
          annualPlatformFeeWithDiscount: "$0",
          transactionFee: "$99",
        },
        {
          tier: "Pro",
          users: "Up to 5 Users",
          keyServices: " One (1) session with onboarding specialist \n Commissioned and Insured notary \n Chat Support \n Live Meeting participation",
          annualPlatformFee: "$1,500",
          annualPlatformFeeWithDiscount: "$1,200",
          transactionFee: "$99",
        },
        {
          tier: "Team",
          users: "Up to 10 Users",
          keyServices: "30 day access to Notarize onboarding specialists \n Commissioned and Insured notary \n Shared/pooled Customer Success Support  \n Chat Support \n Live Meeting Participation",
          annualPlatformFee: "$6,000",
          annualPlatformFeeWithDiscount: "$4,800",
          transactionFee: "$99",
        },
        {
          tier: "Enterprise",
          users: "More than 10 Users",
          keyServices: "Ongoing access to Notarize onboarding specialists \n Commissioned and insured notary \n Dedicated Customer Success Support \n Chat Support \n Live Meeting Participation",
          annualPlatformFee: "Call for Pricing.",
          annualPlatformFeeWithDiscount: "",
          transactionFee: "$99",
        },
      ],

      buttons: {
        text: "Learn More",
        link: "https://marketing.certifid.com/stewart-trusted-providers",
      },
      data: [
        "Bring digital convenience to your customers. Learn more and sign up below.",
      ],
    },
  },
];
