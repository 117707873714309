import {
  AAL,
  ActionResult,
  CPL,
  Endorsement,
  Jacket,
  Pricing,
  PricingDataResult,
  PricingProduct,
  SCFile,
  StandaloneEndorsement,
} from "entities/UIModel";
import * as ApiModel from "entities/ApiModel";
import * as withSCFile from "pages/file/utils/fromApi/scFileCreator";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { useFiles, useInitialValuesFiles } from "utils/context/FilesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  ActionResultStatus,
  EndorsementStatusType,
  JacketFormType,
  MapActionType,
  OrderStatusType,
  PERMISSIONS,
  PricingConfigKey,
  PricingType,
  ProductType,
  UIConstants,
} from "utils/data/enum";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import { useRef } from "react";
import usePricing from "utils/custom-hooks/usePricing";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import Form from "entities/UIModel/company/Form";
import React from "react";
import { SelectFieldOptionForms } from "./useForms";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import { checkIfFileIsLocked } from "pages/file/utils/helper";
import { usePricingActions } from "utils/context/PricingContext";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { usePricingCalculatorContext } from "utils/context/PricingCalculatorContext";
import { isFormTypeValidForSimultaneous } from "pages/file/utils/products/jacket";
import { hasValue } from "utils/shared";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

const useCreateFile = () => {
  const { setValue, getValues, clearErrors, nameString } =  useFormWrapper();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [, { getFileById, getFileActivities, saveFile }] = useFiles();
  const [{ initialValues }, ] = useInitialValuesFiles();
  const [, { calculatePremiumsWithPolicyType }] = usePricingCalculatorContext();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [{ pricingConfigs }] = usePricingConfig();
  const {
    copyPricingDetails,
    copyPricingProducts,
    copyEndorsementsPricingDetails,
  } = usePricing();
  const [, { setIsPricingCalculated, setIssuedProductsCountUpdated }] = usePricingActions();
  const [{ companyStateLocations }] = useCompanyLocation();
  const [{ profileSettings },] =
  useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  
  const hasFileViewAccess = permissionCodes?.includes(PERMISSIONS.FILES_VIEW) && !permissionCodes?.includes(PERMISSIONS.FILES_MODIFY) ; 
  const currentActionResult = useRef<ActionResult>({});
  const isLocked = getValues("isLocked");
  const isReadOnly = getValues("isReadOnly") || hasFileViewAccess;
  const isFileLocked = checkIfFileIsLocked(isLocked);

  const isSimulteneousFile = () => {
    let isEligibleForSimulteneous = false;
    const jackets: Array<Jacket> = getValues("jackets");

    const loanCount = jackets?.filter(
      (j) =>
        j.orderStatusTypeCode === OrderStatusType.Issued &&
        j.formType === JacketFormType.Loan
    ).length;

    const ownerCount = jackets?.filter(
      (j) =>
        j.orderStatusTypeCode === OrderStatusType.Issued &&
        j.formType === JacketFormType.Owners
    ).length;

    // Is Multiple Loans?
    let isMultipleLoans = false;

    const propertyType = getValues("properties.0.propertyType");
    const transactionType = getValues("transactionTypeCode");

    const multipleLoansConfig = pricingConfigs?.find(
      (c) =>
        c.configKey === PricingConfigKey.MultipleLoans &&
        (!c.propertyType ||
          c.propertyType.toLowerCase() === propertyType.toLowerCase()) &&
        (!c.transactionType ||
          c.transactionType.toLowerCase() === transactionType.toLowerCase())
    );

    if (multipleLoansConfig && multipleLoansConfig.configValue === "1") {
      isMultipleLoans = true;
    }

    if (
      (isMultipleLoans && loanCount + ownerCount >= 2) ||
      (!isMultipleLoans && loanCount >= 1 && ownerCount >= 1)
    ) {
      isEligibleForSimulteneous = true;
    }

    return isEligibleForSimulteneous;
  };

  // const hasIssuedJackets = () => {
  //   const jackets: Array<Jacket> = getValues("jackets");
  //   return jackets?.filter(
  //     (j) => j.orderStatusTypeCode === OrderStatusType.Issued
  //   ).length > 0
  //     ? true
  //     : false;
  // };

  const updateShowOPNFlags = (pricing: Pricing) => {
    setValue(
      "pricing.showOPNPriorPolicyLast10Yrs",
      pricing.showOPNPriorPolicyLast10Yrs
    );
    setValue(
      "pricing.showOPNLiabilityAmount",
      pricing.showOPNLiabilityAmount
    );
    setValue(
      "pricing.showOPNCoverageType",
      pricing.showOPNCoverageType
    );
    setValue(
      "pricing.showOPNFormType",
      pricing.showOPNFormType
    );
    setValue(
      "pricing.showOPNPolicyDate",
      pricing.showOPNPolicyDate
    );
    setValue(
      "pricing.showOPNPolicyNumber",
      pricing.showOPNPolicyNumber
    );
  };

  const setPricingPremiumWithPolicyTypes = async () => {
    currentActionResult.current = {};
    let receiptId = uuidv4();

    openAutomaticProgressDialog(UIConstants.LOADING_RECALC_PREMIUM_MESSAGE);
    // reset notificaiton message
    setIsPricingCalculated(false);
    setPricingNotificationUpdated(false);
    clearPricingNotification();

    const scfile = getValues() as SCFile;

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      scfile,
      undefined,
      MapActionType.RetrievePricingData
    );

    // let orderIds: string = "";
    // scfile.jackets
    //   ?.filter((j) => j.orderStatusTypeCode === OrderStatusType.Issued)
    //   ?.forEach((j) => {
    //     if (orderIds.length > 0) orderIds += ",";
    //     orderIds += j.orderID;
    //   });

    const stableValues = cloneDeep(scfile);
    currentActionResult.current = await calculatePremiumsWithPolicyType(
      scfile,
      apiSCFile,
      receiptId
    );
    if (currentActionResult.current.pricingDataResult) {
      const pricingData: PricingDataResult =
        currentActionResult.current.pricingDataResult;
      const defaultErrorMessage =
        pricingConfigs?.find(
          (c) => c.configKey === PricingConfigKey.DefaultErrorMessage
        )?.configValue || "";

      // Update Pricing Configuration
      if (pricingData?.pricingConfig) {
        setValue(
          "pricing.disableSimultaneousRate",
          pricingData.pricingConfig.disableSimultaneousRate
        );
        setValue(
          "pricing.coInsurancePct",
          pricingData.pricingConfig.coInsurancePct
        );
        setValue(
          "pricing.isActualCalc",
          pricingData.pricingConfig.isActualCalc
        );
        setValue(
          "pricing.isDisclosed_TRIDCalc",
          pricingData.pricingConfig.isDisclosed_TRIDCalc
        );
        // setValue("pricing.isIntegratedPricing", pricingData.pricingConfig.isIntegratedPricing);
        // setValue("pricing.isOverride", pricingData.pricingConfig.isOverride);
        // setValue(
        //   "pricing.isSimultaneousRate",
        //   pricingData.pricingConfig.isSimultaneousRate
        // );
        setValue(
          "pricing.overrideReason",
          pricingData.pricingConfig.overrideReason
        );
        setValue(
          "pricing.partitionYear",
          pricingData.pricingConfig.partitionYear
        );
        setValue(
          "pricing.showAgentRetention",
          pricingData.pricingConfig.showAgentRetention
        );
        setValue(
          "pricing.showCoInsurance",
          pricingData.pricingConfig.showCoInsurance
        );
        setValue(
          "pricing.showLiabilityAmount",
          pricingData.pricingConfig.showLiabilityAmount
        );
        setValue("pricing.showReissue", pricingData.pricingConfig.showReissue);
        setValue(
          "pricing.showReissueCheckbox",
          pricingData.pricingConfig.showReissueCheckbox
        );
        setValue(
          "pricing.showReissueColumnLabel",
          pricingData.pricingConfig.showReissueColumnLabel
        );
        setValue(
          "pricing.showReissueEdit",
          pricingData.pricingConfig.showReissueEdit
        );
        setValue(
          "pricing.showRiskRate",
          pricingData.pricingConfig.showRiskRate
        );
        setValue("pricing.showTax", pricingData.pricingConfig.showTax);
        setValue("pricing.taxCode", pricingData.pricingConfig.taxCode);

        setValue(
          "pricing.policy.liabilityLabel",
          pricingData.pricingConfig.policy?.liabilityLabel
        );
        setValue(
          "pricing.policy.windowTitle",
          pricingData.pricingConfig.policy?.windowTitle
        );
        setValue(
          "pricing.policy.showCoverageType",
          pricingData.pricingConfig.policy?.showCoverageType
        );
        setValue(
          "pricing.policy.showFormType",
          pricingData.pricingConfig.policy?.showFormType
        );
        setValue(
          "pricing.policy.showLiabilityAmount",
          pricingData.pricingConfig.policy?.showLiabilityAmount
        );
        setValue(
          "pricing.policy.showPolicyDate",
          pricingData.pricingConfig.policy?.showPolicyDate
        );
        setValue(
          "pricing.policy.showOPNPriorPolicyLast10Yrs",
          pricingData.pricingConfig.policy?.showOPNPriorPolicyLast10Yrs
        );

        // Update ShowOPN Flags to refresh the OPN Dialog fields.
        updateShowOPNFlags(pricingData.pricingConfig);
      }

      // ONLY jacket pricing has rate types at this time.
      // Set jacket pricing rate types
      if (
        pricingData?.productPolicyTypes &&
        pricingData.productPolicyTypes.length > 0
      ) {
        // rlo 6/6/2022 - No need to update the object
        //// Update Jacket Pricing Details object
        //let jackets = getValues("jackets");
        // jackets
        //   ?.filter(
        //     (j: Jacket) => j.orderStatusTypeCode === OrderStatusType.Issued
        //   )
        //   ?.map((j: Jacket) => {
        //     const productPolicyType = pricingData.productPolicyTypes?.find(
        //       (r: any) => r.filePricingDetailId === j.filePricingDetailID
        //     );
        //     if (productPolicyType && j.pricingDetail) {
        //       j.pricingDetail.pricingRateType = productPolicyType.pricingRateType;
        //       j.pricingDetail.pricingRateTypeData =
        //         productPolicyType.pricingRateTypeData;
        //       j.pricingDetail.coverageType = productPolicyType.coverageType;
        //       j.pricingDetail.coverageTypeData =
        //         productPolicyType.coverageTypeData;
        //       j.pricingDetail.pricingTransactionTypeCode = productPolicyType.pricingTransactionTypeCode;
        //       j.pricingDetail.pricingFeeTypeCode = productPolicyType.pricingFeeTypeCode;
        //     }
        //     return j;
        //   });

        // Update Jacket Pricing Details's Form
        scfile.jackets?.forEach((j: Jacket, index: number) => {
          if (j.orderStatusTypeCode === OrderStatusType.Issued) {
            const productPolicyType = pricingData.productPolicyTypes?.find(
              (r: any) => r.filePricingDetailId === j.filePricingDetailID
            );
            if (productPolicyType && j.pricingDetail) {
              setValue(
                `jackets.${index}.pricingDetail.pricingRateType`,
                productPolicyType.pricingRateType
              );
              setValue(
                `jackets.${index}.pricingDetail.pricingRateTypeData`,
                productPolicyType.pricingRateTypeData
              );

              setValue(
                `jackets.${index}.pricingDetail.coverageType`,
                productPolicyType.coverageType
              );
              setValue(
                `jackets.${index}.pricingDetail.coverageTypeData`,
                productPolicyType.coverageTypeData
              );
              setValue(
                `jackets.${index}.pricingDetail.pricingTransactionTypeCode`,
                productPolicyType.pricingTransactionTypeCode
              );
              setValue(
                `jackets.${index}.pricingDetail.pricingFeeTypeCode`,
                productPolicyType.pricingFeeTypeCode
              );
            }
          }
        });

        // Update Pricing Products
        // -- Update pricingProduct object
        let pricingProducts = getValues("pricingProducts");
        pricingProducts?.forEach((pp: PricingProduct, index: number) => {
          if (pp.pricingType !== PricingType.Product) return;

          const productPolicyType = pricingData.productPolicyTypes?.find(
            (r: any) => r.filePricingDetailId === pp.filePricingDetailId
          );
          if (!productPolicyType) return;

          if (productPolicyType.lastErrorMessage) {
            setValue(`pricing.errorMessage`, defaultErrorMessage);
          }

          setValue(
            `pricingProducts.${index}.pricingRateType`,
            productPolicyType.pricingRateType || ""
          );

          setValue(
            `pricingProducts.${index}.pricingRateTypeData`,
            productPolicyType.pricingRateTypeData || ""
          );
          setValue(
            `pricingProducts.${index}.rateTypes`,
            productPolicyType.rateTypes || []
          );
          setValue(
            `pricingProducts.${index}.pricingTransactionTypeCode`,
            productPolicyType.pricingTransactionTypeCode || ""
          );
          setValue(
            `pricingProducts.${index}.pricingFeeTypeCode`,
            productPolicyType.pricingFeeTypeCode || ""
          );

          let isReissue: boolean = false;
          if (
            productPolicyType.pricingRateType &&
            productPolicyType.pricingRateTypeData
          ) {
            const rateTypeDataArray = JSON.parse(
              productPolicyType.pricingRateTypeData
            );

            if (rateTypeDataArray) {
              const result = rateTypeDataArray.find(
                (rtd: any) => rtd.Alias === productPolicyType.pricingRateType
              );
              if (result) {
                isReissue = hasValue(result.IsReissue)
                  ? JSON.parse(result.IsReissue)
                  : false;

                setValue(`pricingProducts.${index}.isReissueUpdated`, true);
              }
            }
          }

          // Note: only need to setValue when isReissue is false, otherwise leave it as it's
          if (!isReissue) {
            setValue(`pricingProducts.${index}.isReissue`, false);
          }

          setValue(
            `pricingProducts.${index}.coverageType`,
            productPolicyType.coverageType || ""
          );
          setValue(
            `pricingProducts.${index}.coverageTypeData`,
            productPolicyType.coverageTypeData || ""
          );
        });
      }
    }

    const pendingFile: SCFile = stableValues;
    const calculatedFile = currentActionResult.current.scfile;

    // Calculate doesn't update database so have to set values manually in order to preserve isDirty state
    if (calculatedFile) {
      if (calculatedFile.pricing) {
        setValue("pricing.errorMessage", calculatedFile.pricing.errorMessage);
        setValue("pricing.infoMessage", calculatedFile.pricing.infoMessage);
        setValue("pricing.infoMessageFontColor", calculatedFile.pricing.infoMessageFontColor);
        setValue("pricing.infoMessageFontWeight", calculatedFile.pricing.infoMessageFontWeight);
        setValue(
          "pricing.isCalculationRequired",
          calculatedFile.pricing.isCalculationRequired
        );
        setValue(
          "pricing.isSimultaneousRate",
          calculatedFile.pricing.isSimultaneousRate
        );
      }

      //Note to future Self:  This is what is causing the Reissue, etc. columns to vanish
      // console.log(calculatedFile.pricingProducts, '--calculatedFile.pricingProducts--');
      copyPricingProducts(
        calculatedFile.pricingProducts,
        pendingFile.pricingProducts
      );

      copyPricingDetails(
        pendingFile.jackets,
        calculatedFile.jackets,
        "jackets"
      );

      if (pendingFile.aALProducts && calculatedFile.aALProducts) {
        copyPricingDetails(
          pendingFile.aALProducts,
          calculatedFile.aALProducts,
          "aALProducts"
        );
      }
      copyPricingDetails(pendingFile.cpls, calculatedFile.cpls, "cpls");
      copyPricingDetails(
        pendingFile.standaloneEndorsements,
        calculatedFile.standaloneEndorsements,
        "standaloneEndorsements"
      );

      copyEndorsementsPricingDetails(pendingFile, calculatedFile);
    }

    setPricingNotificationUpdated(true);
    setIsPricingCalculated(true);

    closeAutomaticProgressDialog();
  };

  const resetShowReIssueFlags = () => {
    const propertyType = getValues("properties.0.propertyType");
    const transactionType = getValues("transactionTypeCode");

    const showReIssue =
      pricingConfigs?.find(
        (pc) =>
          pc.configKey === PricingConfigKey.ReIssue &&
          (!pc.transactionType || pc.transactionType === transactionType) &&
          (!pc.propertyType || pc.propertyType === propertyType)
      )?.configValue === "1";

    let showReissueEdit = showReIssue; // default to Reissue config

    const reissueEditConfig = pricingConfigs?.find(
      (pc) =>
        pc.configKey === PricingConfigKey.ReIssueEdit &&
        (!pc.transactionType || pc.transactionType === transactionType) &&
        (!pc.propertyType || pc.propertyType === propertyType)
    );

    if (reissueEditConfig) {
      showReissueEdit = reissueEditConfig.configValue === "1";
    }
    setValue("pricing.showReissue", showReIssue);
    setValue("pricing.showReissueEdit", showReissueEdit);
  };

  const clearPricingNotification = () => {
    setValue("pricing.errorMessage", undefined);
    setValue("pricing.infoMessage", undefined);
    setValue("pricing.infoMessageFontColor", null);
    setValue("pricing.infoMessageFontWeight", null);
  };

  const isSimultaneousRateAllowed = (): boolean => {
    // let isSimulFile = isSimulteneousFile();

    // const propertyType = getValues("properties.0.propertyType");
    // const transactionType = getValues("transactionTypeCode");

    // // Is SimultaneousRate allowed?
    // let isSimultaneousRateAllowed = true;

    // const simultaneousRateConfig = pricingConfigs?.find(
    //   (c) =>
    //     c.configKey === PricingConfigKey.SimultaneousRate &&
    //     (!c.propertyType ||
    //       c.propertyType.toLowerCase() === propertyType.toLowerCase()) &&
    //     (!c.transactionType ||
    //       c.transactionType.toLowerCase() === transactionType.toLowerCase())
    // );

    // if (simultaneousRateConfig && simultaneousRateConfig.configValue?.toLowerCase() === "disallow") {
    //   isSimultaneousRateAllowed = false;
    // }

    //return  (isSimultaneousRateAllowed && isSimulFile)
    var isSimultaneousRateAllowed = getValues("pricing.isSimultaneousRate");
    return isSimultaneousRateAllowed;
  };

  const setSimultaneousRate = () => {
    const isSimulRateAllowed = isSimultaneousRateAllowed();
    const prevUserSimulteneousRate = getValues(
      "pricing.prevUserSimulteneousRate"
    );
    //setValue("pricing.isSimultaneousRateAllowed", isSimulRateAllowed);
    if (!isSimulRateAllowed) {
      setValue("pricing.isSimultaneousRate", false);
    } else {
      setValue("pricing.isSimultaneousRate", prevUserSimulteneousRate);
    }
  };

  const setProductLocationInfo = (
    productType: string,
    schema: string,
    location: CompanyStateLocation,
    isRevising: boolean,
    orderStatusTypeCode: string
  ) => {
    if (location) {
      if (
        isRevising ||
        (orderStatusTypeCode &&
          orderStatusTypeCode !== OrderStatusType.Issued &&
          orderStatusTypeCode !== OrderStatusType.Voided)
      ) {
        setLocationInfo(schema, location);

        // Set location for Endorsements if it's a Jacket or a StandaloneEndorsement
        if (
          productType === ProductType.Jacket ||
          productType === ProductType.StandaloneEndorsement
        ) {
          const locationDisplayName = getValues(
            `${schema}.pricingDetail.locationDisplayName`
          );
          const endorsements: Array<Endorsement> = getValues(
            `${schema}.endorsements`
          );
          endorsements?.forEach((endorsement, index) => {
            if (
              isRevising ||
              (endorsement.endorsementStatusTypeCode !==
                EndorsementStatusType.Issued &&
                endorsement.endorsementStatusTypeCode !==
                EndorsementStatusType.Voided)
            ) {
              const endorsementSchema = `${schema}.endorsements.${index}`;
              setValue(
                `${endorsementSchema}.pricingDetail.locationDisplayName`,
                locationDisplayName || ""
              );
              setLocationInfo(endorsementSchema, location);
            }
          });
        }
      }
    }
  };

  const setLocationInfo = (schema: string, location: CompanyStateLocation) => {    
    // schema level
    setValue(`${schema}.altaRegistryID`, location.altaRegistryID);
    setValue(`${schema}.territoryID`, location.territoryID);
    setValue(`${schema}.underwriterTypeCode`, location.underwriterTypeCode);
    
    // pricingDetail level
    setValue(`${schema}.pricingDetail.contractID`, location.contractID || "");
    setValue(`${schema}.pricingDetail.legacyID`, location.legacyID || "");
    setValue(`${schema}.pricingDetail.locationID`, location.locationID || "");
    setValue(
      `${schema}.pricingDetail.locationLegalName`,
      location.locationLegalName || ""
    );
    setValue(
      `${schema}.pricingDetail.locationUniqueID`,
      location.locationUniqueID || ""
    );
    setValue(`${schema}.pricingDetail.underwriterTypeCode`,location.underwriterTypeCode);
  };

  const setDefaultProductLocation = (
    productType: string,
    schema: string,
    locationDisplayName: string,
    location: CompanyStateLocation,
    orderStatusTypeCode: string,
    isLocationDefault: boolean = false
  ) => {
    if (
      isLocationDefault &&
      orderStatusTypeCode !== OrderStatusType.Issued &&
      orderStatusTypeCode !== OrderStatusType.Voided
    ) {
      setValue(
        `${schema}.pricingDetail.locationDisplayName`,
        locationDisplayName || ""
      );
      setProductLocationInfo(
        productType,
        schema,
        location,
        false,
        orderStatusTypeCode
      );
    }
  };

  const clearFormInfo = React.useCallback(
    (
      schema: string,
      product?: ProductType
    ) => {
      setValue(`${schema}.formID`, "");
      setValue(`${schema}.formVersion`, "");
      setValue(`${schema}.formTemplateName`, "");
      setValue(`${schema}.opaIdentifier`, "");
      setValue(`${schema}.isAgencyExclusive`, "");
      setValue(`${schema}.formPrefix`, "");
      setValue(`${schema}.DocumentOrderData.formPrefix`, "");

      if (product === ProductType.Cpl || product === ProductType.Aal) {
        setValue(`${schema}.liabilityAmount`, "");
      }
    },
    [setValue]
  );

  const setFormInfo = React.useCallback(
    (
      schema: string,
      form: Form | SelectFieldOptionForms | undefined,
      product?: ProductType
    ) => {
      if (!form) return;

      if (form.formID) setValue(`${schema}.formID`, form.formID);
      setValue(`${schema}.formVersion`, form.formVersion);
      setValue(`${schema}.formTemplateName`, form.formTemplateName);
      setValue(`${schema}.opaIdentifier`, form.opaIdentifier);
      setValue(`${schema}.isAgencyExclusive`, form.agencyExclusive);
      if (form.formPrefix) {
        setValue(`${schema}.formPrefix`, form.formPrefix);
        setValue(`${schema}.DocumentOrderData.formPrefix`, form.formPrefix);
      }

      if (product === ProductType.Cpl || product === ProductType.Aal) {
        setValue(`${schema}.liabilityAmount`, form.liabilityAmount);
      }
    },
    [setValue]
  );

  const setValidLocations = (items: CompanyStateLocation[] = companyStateLocations) => {
    setValue("validLocations", items);
  };

  const getCompanyStateLocation = (locationDisplayName: string) => {
    return companyStateLocations?.find(
      (location) => location.locationDisplayName === locationDisplayName
    );
  };

  const resetValidationRequiredFlags = () => {
    const cpls: CPL[] = getValues("cpls");
    cpls?.forEach((cpl, index) => {
      if (cpl.isValidationRequired)
        setValue(`cpls.${index}.isValidationRequired`, false);
      if (cpl.pricingDetail?.isValidatingLocationRequired)
        setValue(
          `cpls.${index}.pricingDetail.isValidatingLocationRequired`,
          false
        );
    });

    const aals: AAL[] = getValues("aALProducts");
    aals?.forEach((aal, index) => {
      if (aal.isValidationRequired)
        setValue(`aALProducts.${index}.isValidationRequired`, false);
      if (aal.pricingDetail?.isValidatingLocationRequired)
        setValue(
          `aALProducts.${index}.pricingDetail.isValidatingLocationRequired`,
          false
        );
    });

    const jackets: Jacket[] = getValues("jackets");
    jackets?.forEach((jacket, index) => {
      if (jacket.isValidationRequired)
        setValue(`jackets.${index}.isValidationRequired`, false);
      if (jacket.pricingDetail?.isValidationRequired)
        setValue(`jackets.${index}.pricingDetail.isValidationRequired`, false);
      if (jacket.pricingDetail?.isValidatingLocationRequired)
        setValue(
          `jackets.${index}.pricingDetail.isValidatingLocationRequired`,
          false
        );
      jacket.endorsements?.forEach((e, eIndex) => {
        if (e.isValidationRequired)
          setValue(
            `jackets.${index}.endorsements.${eIndex}.isValidationRequired`,
            false
          );
      });
    });

    const standaloneEndorsements: StandaloneEndorsement[] = getValues(
      "standaloneEndorsements"
    );
    standaloneEndorsements?.forEach((standaloneEndorsement, index) => {
      if (standaloneEndorsement.isValidationRequired)
        setValue(`standaloneEndorsements.${index}.isValidationRequired`, false);
      if (standaloneEndorsement.pricingDetail?.isValidationRequired)
        setValue(
          `standaloneEndorsements.${index}.pricingDetail.isValidationRequired`,
          false
        );
      if (standaloneEndorsement.pricingDetail?.isValidatingLocationRequired)
        setValue(
          `standaloneEndorsements.${index}.pricingDetail.isValidatingLocationRequired`,
          false
        );
      standaloneEndorsement.endorsements?.forEach((e, eIndex) => {
        if (e.isValidationRequired)
          setValue(
            `standaloneEndorsements.${index}.endorsements.${eIndex}.isValidationRequired`,
            false
          );
      });
    });

    // Pricing
    setValue("pricing.isValidationRequired", false);

    const pricingProducts: PricingProduct[] = getValues("pricingProducts");
    pricingProducts?.forEach((pricingProduct, index) => {
      if (pricingProduct.isValidationRequired)
        setValue(`pricingProducts.${index}.isValidationRequired`, false);

      pricingProduct.productItems?.forEach((pi, piIndex) => {
        if (pi.isValidationRequired)
          setValue(
            `pricingProducts.${index}.productItems.${piIndex}.isValidationRequired`,
            false
          );
      });
    });
  };

  const clearJacketFormData = (schema: string, formType: string) => {
    clearErrors([
      nameString(`${schema}.formType`),
      nameString(`${schema}.form`),
      nameString(`${schema}.pricingDetail.transCodeKey`),
      nameString(`${schema}.pricingDetail.liability`),
      nameString(`${schema}.insuredNames`),
      nameString(`${schema}.formDetails`),
      nameString(`${schema}.endorsements`),
    ]);

    setValue(`${schema}.pricingDetail.transCode`, "");
    setValue(`${schema}.pricingDetail.transCodeDescription`, "");
    setValue(`${schema}.pricingDetail.transCodeKey`, "");
    setValue(`${schema}.insuredNames`, "");
    setValue(`${schema}.endorsements`, []);
    setValue(`${schema}.formDetails`, []);
    setValue(`${schema}.pricingDetail.actualFee`, 0.0);
    setValue(`${schema}.pricingDetail.liability`, 0.0);
    setValue(`${schema}.isFormSelectionChanged`, true);
    setValue(`${schema}.pricingDetail.isSimultaneous`, false);
    setValue(`${schema}.isDefault`, false);

    if (!isFormTypeValidForSimultaneous(formType)) {
      setValue(`${schema}.pricingDetail.isReissue`, false);
      setValue(`${schema}.originalJacketDate`, null);
      setValue(`${schema}.originalJacketNumber`, null);
      setValue(`${schema}.originalJacketType`, null);
      setValue(`${schema}.originalJacketLiability`, null);
      setValue(`${schema}.originalJacketState`, null);
    }
  };

  const reloadFile = async (fileId?: number | string, afterReloadAction?: () => void) => {
    if (!fileId) {
      fileId = getValues("id");
    }
    setTimeout(async () => {
      setIssuedProductsCountUpdated(false); // to triger useEffect  in Pricing Section
      if (fileId) {
        const encodedFileId = encodeURIComponent(btoa(fileId.toString()));
        await getFileById(encodedFileId);
        // await setHasPolicyDocument(Number(fileId));
        afterReloadAction && setTimeout(() => { afterReloadAction(); }, 500);
      }
      setIssuedProductsCountUpdated(true); // to triger useEffect  in Pricing Section
    }, 500);
  };

  const reloadFileActivities = async (fileId: number) => {
    if (!fileId) {
      fileId = getValues("id");
    }
    
    if (fileId) {
      const result = await getFileActivities(fileId);
      if (result.status === ActionResultStatus.Success) {
        const apiFileActivities = result.apiResponse as ApiModel.FileActivity[];
        const fileActivites = withSCFile.createFileActivity(
          apiFileActivities || []
        );
        setValue("fileActivities", fileActivites);
      }
    }
    
  }

  const saveSCFile = async () => {
    const formValues: SCFile = getValues();

    const fileToSave = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.SaveFile
    );

    await saveFile(fileToSave, false);

  }

  return {
    isFileLocked,
    isReadOnly,
    isSimulteneousFile,
    updateShowOPNFlags,
    setPricingPremiumWithPolicyTypes,
    clearPricingNotification,
    setSimultaneousRate,
    setProductLocationInfo,
    setDefaultProductLocation,
    clearFormInfo,
    setFormInfo,
    setValidLocations,
    getCompanyStateLocation,
    resetValidationRequiredFlags,
    clearJacketFormData,
    resetShowReIssueFlags,
    reloadFile,
    saveSCFile,
    reloadFileActivities,
  };
};

export default useCreateFile;
