import {
  styled,
  Typography,
} from "@mui/material";
import React, {
  ReactElement,
} from "react";
import {
  fontSize,
  fontWeight,
  gaps,
  gradients,
  padding,
} from "theme/defaultStyle";
import theme from "theme/default";
import ErrorMessage from "./SectionErrorMessage";

type Props = {
  children: ReactElement;
  icon: ReactElement | Element;
  isError?: boolean;
  isNavigator?: boolean;
  subHeader?: string;
  title: string;
  navItemRef?: any;
};

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.medium1,
  paddingBottom: padding.zero,
});

const HeaderContainer = styled("div")({
  padding: padding.zero,
  [theme.breakpoints.up("sm")]: {
    padding: padding.zero,
  },
});

const TitleContainer = styled("div")({
  ...theme.custom.container.outer.title,
  "& .MuiTypography-root": {
    background: gradients.maroonGradient01,
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
});

const SubHeader = styled("div")({
  ...theme.custom.common.title.info,
});

const ContentContainer = styled("div")({
  ...theme.custom.container.outer.root,
  maxWidth: "100%",
  padding: padding.xlarge1,
});

const FormSection = ({
  children,
  icon,
  title,
  isNavigator = false,
  isError = false,
  subHeader,
  navItemRef,
  ...rest
}: Props) => {

  return (
    <FormContainer
      {...rest}
      ref={navItemRef}
    >
      <HeaderContainer>
        <TitleContainer>
          <>
            {icon}
            <Typography>{title}</Typography>
            {isError && <ErrorMessage />}
          </>
        </TitleContainer>
      </HeaderContainer>
      {subHeader && (
        <p>
          <SubHeader>
            {subHeader}
          </SubHeader>
        </p>
      )}
      {isNavigator ? (
        <>{children}</>
      ) : (
        <ContentContainer>
          {children}
        </ContentContainer>
      )}
    </FormContainer>
  );
};

export default FormSection;
