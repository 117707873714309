import { JacketFormDetail } from "entities/UIModel";
import React, {
  useState,
} from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { TextField } from "@mui/material";

interface Props {
  index: number;
  name: string;
  formDetail: JacketFormDetail;
  disabled?: boolean;
  integersOnly?: boolean;
  fullWidth?: boolean;
}

function NormalTextField({
  index,
  name,
  formDetail,
  disabled,
  integersOnly = false,
  fullWidth = true,
}: Props) {
  const [state, setState] = useState(formDetail.formDetailValue);
  const { setValue } = useFormWrapper();

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    const currentValue = event.target.value;
    currentValue && integersOnly
      ? setState(currentValue.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1"))
      : setState(currentValue);
  };

  const handleOnBlur = (event: React.ChangeEvent<any>) => {
    const newValue = {
      ...formDetail,
      formDetailValue: event.target.value || "",
    };
    setValue(`${name}.${index}`, newValue);
  };

  return (
    <TextField
      value={state}
      variant="outlined"
      size="small"
      fullWidth={fullWidth}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      disabled={disabled}
    />
  );
}

export default NormalTextField;
