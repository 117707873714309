import React from "react";
import { Controller } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import ErrorMessage from "../error-message";
import CurrencyInput from "./CurrencyInput";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";

interface Props {
  label?: string;
  name: any;
  type?: string;
  defaultValue?: any;
  isError?: boolean;
  disabled?: boolean;
  allowNegative?: boolean;
  useFormProvider?: boolean;
  onChange?: any;
  onBlur?: (value: string | null) => void;
}

const CurrencyInputField = ({
  label,
  name,
  type,
  disabled,
  allowNegative,
  defaultValue,
  onBlur,
  onChange,
}: Props) => {
  const { control, setValue } = useFormWrapper();
  const shouldFieldBeDisabled = useFieldDisabler(name);

  const handleOnBlur = (value: string, onBlurForm: any) => {
    onBlurForm();
    onBlur && onBlur(value);
  };

  const handleOnChange = (e: any, val: number | string) => {
    const valueOrDefault = val !== "" ? val : 0;
    setValue(name, valueOrDefault);
    onChange && onChange(e, val);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { name, value, onBlur }, fieldState }) => {
          let cleanedValue = value;
          if (typeof value === "string")
            cleanedValue = (value as string).replace("$", "");
          return (
            <CurrencyInput
              hasError={Boolean(fieldState.error)}
              name={name}
              label={label}
              type={type}
              value={cleanedValue}
              onChange={handleOnChange}
              onBlur={(value: string) => handleOnBlur(value, onBlur)}
              disabled={disabled || shouldFieldBeDisabled}
              allowNegative={allowNegative}
            />
          );
        }}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default CurrencyInputField;
