import LightTooltip from "controls/global/light-tooltip";
import React from "react";
import useHover from "utils/custom-hooks/useHover";

type Props = {
  text: string;
  width: string;
};

const HtmlDiv = ({ text, width }: Props) => {
  const {
    htmlElement: { open },
    evalHover,
    elRef,
  } = useHover();

  return (
    <LightTooltip title={text} open={open} arrow>
      <div
        style={{
          width: width,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        ref={elRef}
        onMouseOver={() => evalHover(true)}
        onMouseOut={() => evalHover(false)}
      >
        {text}
      </div>
    </LightTooltip>
  );
};

export default HtmlDiv;
