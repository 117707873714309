import { 
  Typography, 
  styled,
} from "@mui/material";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import SelectField from "controls/global/select-field";
import { 
  useSignatureDispatchProvider, 
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import React, {
  useEffect,
  useState,
} from "react";
import { 
  colors, 
  fontSize, 
  fontWeight, 
  margin, 
} from "theme/defaultStyle";
import { useLookup } from "utils/context/LookupContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { SignatureLevelType } from "utils/data/enum";

interface SelectOption {
  value: string;
  text: string;
}

const FormField = styled("div")({
  marginTop: margin.xlarge,
  "& .label": {
    color: colors.grey08,
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    marginBottom: margin.small,
  },  
});

const fieldName = "signatureLevelTypeCode";

const UPDATE_DEFAULTED_SIGNATURES_MESSAGE =
  "This signature is defaulted by one or more users. Changing the configuration and/or signature image will affect other users.";

const LevelField = () => {
  const {setValue, getValues} = useFormWrapper()
  const [levelOptions, setLevelOptions] = useState<SelectOption[]>([]);
  const [showEditWarningDialog, setShowEditWarningDialog] = useState(false);
  //const [disableConfirmationDialogYes, setDisableConfirmationDialogYes] = useState(false);
  const [{ signatureLevelTypes }] = useLookup();
  const {
    state: { signature},
  } = useSignatureProvider();  
  const { dispatch } = useSignatureDispatchProvider();


  const handleOnChange = (event: React.ChangeEvent<any>) => {
    const signatureLevelTypeCode = event.target.value;
    if (
      signatureLevelTypeCode === SignatureLevelType.UserOnly &&
      signature.isDefaultedByOther === 1
    ) {
      setShowEditWarningDialog(true);
    } else {
      dispatch({
        type: "LEVEL_UPDATE",
        signatureLevelTypeCode: signatureLevelTypeCode,
      });
    }
  };

  const handleConfirmEdit = async (id?: number) => {
    const signatureLevelType = getValues(fieldName);
    dispatch({
      type: "LEVEL_UPDATE",
      signatureLevelTypeCode: signatureLevelType?.value,
    });    
    setShowEditWarningDialog(false);
  }  

  const handleCancelEdit = () => {
    setValue(fieldName, { value: signature.signatureLevelTypeCode || SignatureLevelType.AgencyWide});    
    setShowEditWarningDialog(false);
  };
  
  useEffect(() => {
    setLevelOptions(signatureLevelTypes.map(t => ({
      value: t.code,
      text: t.name
    })));
  }, [signatureLevelTypes]);

  useEffect(()=> {
    setValue(fieldName, { value: signature.signatureLevelTypeCode || SignatureLevelType.AgencyWide});
  },[]);

  return (
    <FormField>
      <Typography
        className={"label"}
      >
        Who should have access to this signature?
      </Typography>      
      <SelectField
        label="Signature Access"
        name={fieldName}
        options={levelOptions}
        optionsValueField="value"
        optionsTextField="text"
        dataValueField="value"
        dataTextField="text"
        disabled={false}
        onChange={handleOnChange}
      />
      <ConfirmationDialog
        width={550}
        type={"ERROR"}
        confirmationMessage={UPDATE_DEFAULTED_SIGNATURES_MESSAGE}
        isOpen={showEditWarningDialog}
        onYes={async () => await handleConfirmEdit()}
        onNo={handleCancelEdit}
        disableYes={false} 
        singleActionButtonText={"Continue"}
        noActionButton={true}
        buttonNoText="Cancel"
        cancelActionButton={false}
        singleActionButton={false}
        
      />      
    </FormField>
  );
};

export default LevelField;
