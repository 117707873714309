import { AxiosRequestConfig } from "axios";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { SignatureDefault } from "entities/UIModel/SignatureDefault";
import { SignatureDefaultGroup } from "entities/UIModel/SignatureDefaultGroup";
import { SignatureImageAgencyLocation } from "entities/UIModel/SignatureImageAgencyLocation";
import { groupBy } from "lodash";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";

export interface DefaultFormData {
  existingUserAgencySignatureDefaultIDs: string;
  signatureImage: SelectFieldOption;
  level: SelectFieldOption;
  location: SelectFieldOption;
  agency: SelectFieldOption;
  state: SelectFieldOption;
}

type State = {
  error: any;
  isLoading: boolean;
  agencyLocationsByImage: SignatureImageAgencyLocation[];
  signatures: Array<SelectFieldOption>;
  defaults: SignatureDefaultGroup[];
  initialDefault: DefaultFormData;
  agencyWideLevelCode: string;
  userOnlyLevelCode: string;
};

type Actions = typeof actions;

type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | any) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error) {
        dispatch(setError(error));
      }
    };

const setDefaults =
  (data: Array<SignatureDefault>) =>
    ({ setState }: StoreApi) => {
      const grouped = Object.values(
        groupBy(data, d => `${d.SignatureImageName} | ${d.AgencyID} | ${d.StateCode} | ${d.LocationDisplayName} | ${d.SignatureLevelTypeCode}`)
      )
        .map(g => {
          const firstItem = g[0];
          const group: SignatureDefaultGroup = {
            key: `${firstItem.SignatureImageName} | ${firstItem.AgencyID} | ${firstItem.StateCode} | ${firstItem.LocationDisplayName} | ${firstItem.SignatureLevelTypeCode}`,
            existingUserAgencySignatureDefaultIDs: g.map(i => i.UserAgencySignatureDefaultID).sort().join("|"),
            agencyID: firstItem.AgencyID,
            agencyName: firstItem.AgencyName,
            stateCode: firstItem.StateCode,
            state: firstItem.State,
            locationUniqueIDs: g.map(i => i.LocationUniqueID).sort().join("|"),
            locationDisplayName: firstItem.LocationDisplayName,
            signatureLevelTypeCode: firstItem.SignatureLevelTypeCode,
            signatureLevelTypeName: firstItem.SignatureLevelTypeName,
            signatureImageID: firstItem.SignatureImageID,
            signatureImageName: firstItem.SignatureImageName,
            integrationKey: firstItem.integrationKey,
          };
          return group;
        });

      setState({
        defaults: grouped
      });

    };

const setAgencyLocationsByImage =
  (data: Array<any>) =>
    ({ setState }: StoreApi) => {
      const mapped: SignatureImageAgencyLocation[] = data.map(l => ({
        agencyID: l.AgencyID,
        agencyName: l.AgencyName,
        locationDisplayName: l.LocationDisplayName,
        locationID: l.LocationID,
        locationUniqueID: l.LocationUniqueID,
        stateAbbr: l.StateAbbr,
        stateCode: l.StateCode,
      }));
      setState({
        agencyLocationsByImage: mapped
      });
    };

const setSignatures =
  (data: Array<any>) =>
    ({ setState }: StoreApi) => {
      setState({
        signatures: data.map((signature) => ({
          text: signature.SignatureImageName,
          value: signature.SignatureImageID,
        })),
      });
    };

const emptyField = {
  text: "",
  value: "",
};

const initialState = {
  error: false,
  isLoading: false,
  agencyLocationsByImage: [],
  signatures: [],
  defaults: [],
  initialDefault: {
    signatureImage: emptyField,
    level: emptyField,
    location: emptyField,
    agency: emptyField,
    state: emptyField,
    existingUserAgencySignatureDefaultIDs: "",
  },
  agencyWideLevelCode: "",
  userOnlyLevelCode: "",
};

const actions = {
  getDefaults:
    () =>
      async ({ dispatch }: StoreApi) => {
        const { data } = await axiosSecuredInstance.get<SignatureDefault[]>("/Signature/v2/Defaults/GetDefaults");
        dispatch(setDefaults(data));
      },
  getAgencyLocationsByImage:
    (signatureImageID: number) =>
      async ({ dispatch }: StoreApi) => {
        dispatch(
          getData(
            `/Signature/v2/Defaults/GetAgencyLocationsByImage/${signatureImageID}`,
            setAgencyLocationsByImage
          )
        );
      },
  setAgencyWideLevelCode:
    (code: string) =>
      ({ setState }: StoreApi) => {
        setState({
          agencyWideLevelCode: code,
        });
      },
  setUserOnlyLevelCode:
    (code: string) =>
      ({ setState }: StoreApi) => {
        setState({
          userOnlyLevelCode: code,
        });
      },
  saveDefault:
    (signatureDefaultGroup: SignatureDefaultGroup) =>
      async ({ dispatch, setState }: StoreApi) => {
        try {
          const response = await axiosSecuredInstance.post(
            "/Signature/v2/Defaults/Group",
            signatureDefaultGroup
          );

          return response.status;
        } catch (error: any) {
          dispatch(() => {
            setState({ error });
          });
          return error.response!.status;
        }
      },
  deleteDefault:
    (signatureDefaultIds: string) =>
      async ({ dispatch, setState }: StoreApi) => {
        try {
          const ids = signatureDefaultIds.split("|").map(id => Number(id));
          const response = await axiosSecuredInstance.delete("/Signature/v2/Defaults/Group",
            { data: ids }
          );
          return response.status;
        } catch (error: any) {
          dispatch(() => {
            setState({ error });
          });
          return error.response!.status;
        }
      },
  getSignatures:
    (agencyID: string, StateCode: string, LocationID: string) =>
      async ({ dispatch }: StoreApi) => {
        dispatch(
          getData(
            `/Signature/v2/Defaults/GetSignatures/${agencyID}/${StateCode}/${LocationID}`,
            setSignatures
          )
        );
      },
  clearSignatures:
    () =>
      ({ setState }: StoreApi) => {
        setState({
          signatures: [],
        });
      },
  editSetup:
    (signatureDefaultGroup: SignatureDefaultGroup) =>
      ({ setState }: StoreApi) => {
        let initialValue: DefaultFormData = {
          existingUserAgencySignatureDefaultIDs: signatureDefaultGroup.existingUserAgencySignatureDefaultIDs,
          agency: {
            value: signatureDefaultGroup.agencyID,
            text: signatureDefaultGroup.agencyName,
          },
          state: {
            value: signatureDefaultGroup.stateCode,
            text: signatureDefaultGroup.state,
          },
          location: {
            value: signatureDefaultGroup.locationUniqueIDs,
            text: signatureDefaultGroup.locationDisplayName,
          },
          level: {
            value: signatureDefaultGroup.signatureLevelTypeCode,
            text: signatureDefaultGroup.signatureLevelTypeName,
          },
          signatureImage: {
            value: signatureDefaultGroup.signatureImageID.toString(),
            text: signatureDefaultGroup.signatureImageName,
          },
        };
        setState({
          initialDefault: initialValue,
        });
      },
  resetState:
    () =>
      ({ setState, getState }: StoreApi) => {
        const { defaults, agencyWideLevelCode, userOnlyLevelCode } = getState();
        setState({
          ...initialState,
          defaults,
          agencyWideLevelCode,
          userOnlyLevelCode,
        });
      },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: "signatureDefaultsContext",
});

const hook = createHook(Store);
export const useSignatureDefaults = () => { return hook(); };
