import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { Signature } from "pages/signatures/providers/manage-signature/SignatureProvider";
import React, {
  useEffect,
  useRef,
} from "react";
import { colors } from "theme/defaultStyle";
import useDebounce from "utils/custom-hooks/useDebounce";
import { FontConfig } from "./TypeSignature";
import classes from "./TypeSignature.module.css";

type Props = {
  fontConfig: FontConfig;
  signature: Signature;
  handleSelectedSignature: (fontFamilyId: string) => void;
};

const CanvasContainer = styled("canvas")({
  maxHeight: "100%",
  maxWidth: "100%",
});

const PLACEHOLDER_TEXT = "Signature Name";
export default function TypedFont({
  fontConfig,
  signature,
  handleSelectedSignature,
}: Props) {
  const debouncedValue = useDebounce(signature.name, 500);
  const isSelected: boolean = fontConfig.id === signature.fontFamilyID;
  const updatedFont = useRef<number>(0);

  const handleOnSignatureClick = () => {
    if (signature.name.trim() === "") return;
    handleSelectedSignature(fontConfig.id);
  };

  const createCanvas = React.useCallback(
    (fontConfig: FontConfig) => {
      const canvas: HTMLCanvasElement = document.getElementById(
        `${fontConfig.id}_canvas`
      ) as HTMLCanvasElement;

      if (!canvas) return;

      let text = signature.name.trim();
      const signatureEntered = text !== "";
      if (!signatureEntered) {
        text = PLACEHOLDER_TEXT;
      }

      // Use a temp canvas to crop the image to the text width.
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = 1600;
      tempCanvas.height = 120;
      const tempContext = tempCanvas.getContext("2d");
      if (!tempContext) return;
      const textPadding = 20;
      tempContext.font = `${fontConfig.style} ${fontConfig.weight} ${fontConfig.fontSize * 3
        }px ${fontConfig.font}`;
      tempContext.textBaseline = "middle";
      tempContext.fillStyle = colors.grey08;
      tempContext.imageSmoothingEnabled = true;
      tempContext.imageSmoothingQuality = "high";
      tempContext.globalAlpha = signatureEntered ? 1.0 : 0.5;
      const widthMeasurement = tempContext.measureText(text);
      const textWidth = Math.abs(widthMeasurement.actualBoundingBoxLeft) +
        Math.abs(widthMeasurement.actualBoundingBoxRight) + (textPadding * 2);
      tempContext.fillText(text, textPadding * 1.5, tempCanvas.height / 2, textWidth * 3);

      const context = canvas.getContext("2d");
      if (!context) return;
      canvas.width = textWidth + (textPadding * 1);
      context.clearRect(0, 0, canvas.width, canvas.height);
      context?.drawImage(tempCanvas,
        0, 0, textWidth, canvas.height,  // source x,y,w,h
        textPadding / 2, 0, textWidth, canvas.height);      // destination x,y,w,h

    },
    [signature.name, updatedFont.current]
  );

  useEffect(() => {
    updatedFont.current = ++updatedFont.current;
  });

  useEffect(() => {
    createCanvas(fontConfig);
  }, [createCanvas, fontConfig]);

  useEffect(() => {
    if (!debouncedValue || debouncedValue === "" || !isSelected) return;
    handleSelectedSignature(fontConfig.id);
  }, [debouncedValue, fontConfig.id, isSelected]);

  return (
    <Box
      id={fontConfig.id}
      className={`${isSelected ? classes.selected : ""} ${classes.rectangle}`}
      onClick={handleOnSignatureClick}
    >
      <CanvasContainer
        id={`${fontConfig.id}_canvas`}
        height={120}
        width={876}
      />
    </Box>
  );
}
