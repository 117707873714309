/* eslint-disable @typescript-eslint/no-unused-vars */
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  TreeItem,
  TreeView,
} from "@mui/lab";
import {
  Checkbox,
  FormControlLabel,
  styled,
  Typography,
} from "@mui/material";
import LightTooltip from "controls/global/light-tooltip";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import React from "react";
import {
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";
import useHover from "utils/custom-hooks/useHover";

type Props = {
  data?: SignatureConfigurationNode | null;
  onDataChanged: (data: SignatureConfigurationNode) => void;
};

interface NodeSelectionState {
  id: string;
  isSelected: boolean;
  isExpanded?: boolean;
  isExpandedByChild: boolean;
  isActuallyExpanded?: boolean;
}

const StyledTreeItem = styled(TreeItem)({
  "&:hover": {
    backgroundColor: colors.white,
  },
});

const StyledTreeView = styled(TreeView)({
  maxHeight: 650,
});

const StyledFormControlLabel = styled(FormControlLabel)({
  color: colors.black,
  marginBottom: 0,
  "& .text-label": {
    fontSize: fontSize.large,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: 700,
  },
  "& .tree-checkbox": {
    color: colors.blue01,
    "&.MuiCheckbox-root": {
      color: colors.blue01,
      padding: `0 ${padding.small1}`,
    },
    "&.Mui-disabled": {
      color: colors.blue09,
    },
  },
});

interface CheckBoxProps {
  node: SignatureConfigurationNode;
  nodeSelectionState: NodeSelectionState;
  onNodeChecked: (node: SignatureConfigurationNode, checked: boolean) => void;
}

const CheckBox = ({
  node,
  nodeSelectionState,
  onNodeChecked,
}: CheckBoxProps) => {

  const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const target = event.currentTarget as HTMLElement;
    const dataChecked = target.getAttribute("data-checked") === "true";
    onNodeChecked(node, !dataChecked);
  };

  const indeterminate =
    !nodeSelectionState.isSelected && nodeSelectionState.isExpandedByChild;

  return (
    <div onClick={handleChange} data-checked={nodeSelectionState.isSelected}>
      {nodeSelectionState.isSelected && (
        <Checkbox
          className={"tree-checkbox"}
          checked={true}
        />
      )}
      {indeterminate && (
        <Checkbox
          className={"tree-checkbox"}
          indeterminate={true}
        />
      )}
      {!nodeSelectionState.isSelected && !indeterminate && (
        <Checkbox
          className={"tree-checkbox"}
          checked={false}
        />
      )}
    </div>
  );
};

interface CustomTreeItemProps {
  node: SignatureConfigurationNode;
  nodeSelectionStates: NodeSelectionState[];
  onToggleNodeCollapsed: (
    node: SignatureConfigurationNode,
    nodeSelectionState: NodeSelectionState
  ) => void;
  onNodeChecked: (node: SignatureConfigurationNode, checked: boolean) => void;
}

const CustomTreeItem = ({
  node,
  nodeSelectionStates,
  onToggleNodeCollapsed,
  onNodeChecked,
}: CustomTreeItemProps) => {
  const nodeSelectionState = nodeSelectionStates.find(
    (nss) => nss.id === node.ID
  ) || {
    id: node.ID,
    isSelected: false,
    isExpanded: false,
    isExpandedByChild: false,
    isActuallyExpanded: false,
  };

  const handleOnClick = () => {
    onToggleNodeCollapsed(node, nodeSelectionState);
  };

  const {
    htmlElement: { open, text },
    evalHover,
    elRef,
  } = useHover();

  return (
    <StyledTreeItem
      key={node.ID}
      nodeId={node.ID}
      onClick={handleOnClick}
      label={
        <StyledFormControlLabel
          control={
            <CheckBox
              onNodeChecked={onNodeChecked}
              node={node}
              nodeSelectionState={nodeSelectionState}
            />
          }
          label={
            <LightTooltip title={text} open={open}>
              <Typography
                ref={elRef}
                onMouseEnter={() => evalHover(true)}
                onMouseLeave={() => evalHover(false)}
                className={"text-label"}
              >
                {node.Name}
              </Typography>
            </LightTooltip>
          }
        />
      }
    >
      {Array.isArray(node.Children) && node.Children.length > 0
        ? node.Children.map((childNode) => (
          <CustomTreeItem
            key={childNode.ID}
            node={childNode}
            nodeSelectionStates={nodeSelectionStates}
            onToggleNodeCollapsed={onToggleNodeCollapsed}
            onNodeChecked={onNodeChecked}
          />
        ))
        : null}
    </StyledTreeItem>
  );
};

export default function RecursiveTreeView({ data, onDataChanged }: Props) {

  const [nodeSelectionStates, setNodeSelectionStates] = React.useState<
    NodeSelectionState[]
  >([]);

  React.useEffect(() => {
    const clearState = () => {
      setNodeSelectionStates([]);
      expanded.current = [];
      if (data) data.Expanded = false;
    };

    document.addEventListener("reset_data", clearState);

    const getNodeSelectionStates = (node: SignatureConfigurationNode) => {
      let retval: NodeSelectionState[] = [
        {
          id: node.ID,
          isSelected: node.Selected,
          isExpandedByChild: false,
          isExpanded: node.Expanded,
          isActuallyExpanded: undefined,
        },
      ];
      if (
        node.Children &&
        Array.isArray(node.Children) &&
        node.Children.length > 0
      ) {
        let childResults: NodeSelectionState[] = [];
        for (let index = 0; index < node.Children.length; index++) {
          const childNode = node.Children[index];
          const thisChildResults = getNodeSelectionStates(childNode);
          childResults.push(...thisChildResults);
        }

        const allChildrenSelected = childResults.every(
          (result) => result.isSelected
        );
        const someChildrenSelected = childResults.some(
          (result) => result.isSelected
        );
        retval[0].isSelected = allChildrenSelected;
        retval[0].isExpandedByChild = someChildrenSelected;
        retval.push(...childResults);
      }

      retval[0].isActuallyExpanded =
        Boolean(retval[0].isExpanded) ||
        (retval[0].isExpanded === undefined && retval[0].isExpandedByChild);

      return retval;
    };
    if (!data) {
      clearState();
      return;
    }
    const nodeSelectionState = getNodeSelectionStates(data);
    const expandedNodes = nodeSelectionState
      .filter((node) => Boolean(node.isActuallyExpanded))
      .map((node) => node.id);
    setNodeSelectionStates(nodeSelectionState);
    expanded.current = expandedNodes;

    return () => {
      document.removeEventListener("reset_data", clearState);
    };
  }, [data]);

  let expanded = React.useRef<string[]>([]);

  const checkChildNodes = (
    node: SignatureConfigurationNode,
    checked: boolean
  ) => {
    node.Children.forEach((childNode) => {
      childNode.Selected = checked;
      checkChildNodes(childNode, checked);
    });
  };

  const onNodeChecked = (
    node: SignatureConfigurationNode,
    checked: boolean
  ) => {
    if (node) {
      node.Selected = checked;
      checkChildNodes(node, checked);
      data && onDataChanged(data);
    }
  };

  const onToggleNodeCollapsed = (
    node: SignatureConfigurationNode,
    nodeSelectionState: NodeSelectionState
  ) => {
    if (node) {
      const isExpanded = !nodeSelectionState.isActuallyExpanded;
      node.Expanded = isExpanded;
      data && onDataChanged(data);
    }
  };

  return data && nodeSelectionStates.length > 0 ? (
    <StyledTreeView
      defaultCollapseIcon={<RemoveIcon />}
      defaultExpandIcon={<AddIcon />}
      expanded={expanded.current}
    >
      {data && (
        <CustomTreeItem
          node={data}
          nodeSelectionStates={nodeSelectionStates}
          onToggleNodeCollapsed={onToggleNodeCollapsed}
          onNodeChecked={onNodeChecked}
        />
      )}
    </StyledTreeView>
  ) : null;
}
