import { AxiosRequestConfig } from "axios";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

export type JacketSignatureFieldOption = {
  signatureImageName: string;
  signatureImageID?: number;
  default: number;
};

type JacketSignatureFieldOptionApi = {
  SignatureImageName: string;
  SignatureImageID?: number;
  Default: number;
};

type State = {
  error: any;
  isLoading: boolean;
  signatures?: JacketSignatureFieldOption[];
};
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error: any) {
        dispatch(setError(error));
      }
    };

const setSignatures =
  (data: Array<JacketSignatureFieldOptionApi>) =>
    ({ setState }: StoreApi) => {
      setState({
        signatures: data.map((signature) => mapToJacketSignatureFieldOption(signature)),
        isLoading: false,
        error: null,
      });
    };

const mapToJacketSignatureFieldOption = (signature: JacketSignatureFieldOptionApi): JacketSignatureFieldOption => ({
  signatureImageID: signature.SignatureImageID,
  signatureImageName: signature.SignatureImageName,
  default: signature.Default,
});

const initialState = {
  error: null,
  isLoading: false,
};

const actions = {
  getSignatures:
    (agencyID: string, stateCode: string, locationUniqueID: string) =>
      async ({ dispatch, setState }: StoreApi) => {
        setState({ isLoading: true });
        await dispatch(getData(`/Signature/v2/getJacketSignatureImageOptions/${agencyID}/${stateCode}/${locationUniqueID}`, setSignatures));
      },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: "jacketSignaturesContext",
});

const hook = createHook(Store);
export const useJacketSignatures = () => { return hook(); };
