import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import ErrorMessage from "controls/global/error-message";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import {
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { formatCurrency } from "utils/shared";

const StyledTableBodyCellNotUpdateable = styled(TableCell)({
  borderBottom: "none",
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  maxWidth: "150px", // To show up to $999,999,999.11
  padding: `${padding.small} 0`,
  paddingLeft: padding.large1,
  paddingRight: padding.large1,
  textOverflow: "ellipsis",
  "& span": {
    width: "6em",
  },
});

type Props = {
  name: string;
  standalone?: boolean;
  containerClassName?: string;
  errorInfo: any;
};

export default function PricingProductStaticVal({
  name,
  standalone,
  containerClassName,
  errorInfo,
}: Props) {
  const { register, getValues } = useFormWrapper();
  const shouldResetValue = errorInfo?.enableField && errorInfo?.resetField && !errorInfo?.updated;
  const currentValue: number = shouldResetValue ? 0 : getValues(name);

  // Register a hidden input & getValues() to get the latest values from useFieldArray
  // useFieldArray -> PricingTableBody.tsx
  return (
    <StyledTableBodyCellNotUpdateable align="right">
      <input type="hidden" {...register(`${name}` as const)} />
      <span className={containerClassName}>
        {!standalone &&
          <TooltipCell title={formatCurrency(currentValue)} />
        }
      </span>
      <ErrorMessage name={name} className="errorMessageBigRedFloatingLabel" />
    </StyledTableBodyCellNotUpdateable>
  );
}
