import {
  styled,
} from "@mui/material";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import ForeignAddress from "controls/global/foreign-address";
import FullAddress from "controls/global/full-address/FullAddress";
import SelectField from "controls/global/select-field";
import SelectFieldWithClearIcon from "controls/global/select-field/SelectFieldWithClearIcon";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import TextInputField from "controls/global/text-input-field";
import React, {
  useCallback,
} from "react";
import { useWatch } from "react-hook-form";
import theme from "theme/default";
import {
  gaps,
  padding,
} from "theme/defaultStyle";
import { usePartyBuyerBorrowerActions } from "utils/context/PartyBuyerBorrowerContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import {
  PartyType,
  ProductType,
} from "utils/data/enum";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > .container-row": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: gaps.large1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& > *": {
      maxWidth: "100%",
    },
  },
  "& .MuiCheckbox-root": {
    marginLeft: "-9px",// Margin offset to allow checkbox to align, but also show hover effect.
  },
  "& > .party": {
    "& > .party-type": {
      maxWidth: "250px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
  "& > .name-and-excludes": {
    maxWidth: "100%",
    "& > div": {
      flexBasis: "278px",
      flexShrink: 1,
    },
    "& .name": {
      maxWidth: "378px",
      minWidth: "150px",
    },
    "& .companyName": {
      maxWidth: "600px",
      minWidth: "150px",
    },
    "& > .suffix": {
      maxWidth: "100px",
      minWidth: "75px",
    },
    "& > .checkboxWrapper": {
      maxWidth: "170px",
      minWidth: "170px",
    },
    "& > .name, & > .suffix, & > .checkboxWrapper": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    }
  },
});

const StyledTextInputField = styled(TextInputField)({
  "& input.MuiInputBase-input": {
    paddingLeft: padding.small2,
    paddingRight: padding.small2,
  },
});

const BuyerBorrowerData = ({
  field,
  formPrefix,
  index,
  isFileDisabled,
  onDeleteBuyerBorrower,
  partyTypes,
  suffixes,
  letterProductType,
  fileNameNumber,
  agency,
  primaryProperty,
  additionalParties,
  hasIssuedProducts,
  isPopulatingExistingFile,
}: {
  field: any;
  formPrefix: string;
  index: number;
  isFileDisabled: boolean;
  onDeleteBuyerBorrower: any;
  partyTypes: SelectFieldOption[];
  suffixes: SelectFieldOption[];
  letterProductType: string | undefined;
  fileNameNumber: any;
  agency: any;
  primaryProperty: any;
  additionalParties: any;
  hasIssuedProducts: any;
  isPopulatingExistingFile: any;
}) => {
  const { setValue, getValues, register } = useFormWrapper();
  const [, { setBuyerBorrowers, updateZipCodeBuyerBorrower }] = usePartyBuyerBorrowerActions();

  const integrationKeyField = `${formPrefix}.${index}.integrationKey`;
  const filePartyKeyField = `${formPrefix}.${index}.filePartyKey`;
  const partyRoleTypeCodeField = `${formPrefix}.${index}.partyRoleTypeCode`;
  const isForeignAddressName = `${formPrefix}.${index}.isForeignAddress`;
  const partyTypeCodeName = `${formPrefix}.${index}.partyTypeCode`;

  const [partyType, isForeignAddress] = useWatch({
    name: [
      getNameString(partyTypeCodeName),
      getNameString(isForeignAddressName),
    ],
  });

  const getBuyerBorrowerDescription = (() => {
    const party = getValues(`${formPrefix}.${index}`);
    let fullName = "";
    if (!party) return fullName;

    if (partyType === PartyType.Individual) {
      const firstName = party.partyFirstName ? party.partyFirstName : "";
      const middleName = party.partyMiddleName
        ? ` ${party.partyMiddleName}`
        : "";
      const lastName = party.partyLastName ? party.partyLastName : "";
      const suffix = party.suffixType?.name ? ` ${party.suffixType?.name}` : "";
      fullName = `${firstName} ${middleName} ${lastName} ${suffix}`;
      return fullName.trim();
    } else {
      fullName = party.companyName && ` ${party.companyName}`;
      return fullName;
    }
  })();

  const handleOnChange = (_: React.ChangeEvent<any>, index: number) => {
    setTimeout(
      () => setValue(`${formPrefix}.${index}.requirePartyValidation`, false),
      100
    );

    setValue(`${formPrefix}.${index}.companyName`, "");
    setValue(`${formPrefix}.${index}.partyFirstName`, "");
    setValue(`${formPrefix}.${index}.partyMiddleName`, "");
    setValue(`${formPrefix}.${index}.partyLastName`, "");
    setValue(`${formPrefix}.${index}.suffixType.code`, "");
  };

  const handleSuffixChange = (
    selectedOption: SelectFieldOption,
    index: number
  ) => {
    setValue(`${formPrefix}.${index}.suffixType`, selectedOption);
  };

  const updatePartyBuyerBorrowers = useCallback(() => {
    setBuyerBorrowers(getValues("buyerBorrowerParties"));
  }, [getValues, setBuyerBorrowers]);

  return (
    <CollapsableFormSection
      key={field.integrationKey}
      index={index}
      title={`Buyer/Borrower ${index + 1}`}
      label={getBuyerBorrowerDescription}
      showDelete={!isFileDisabled}
      onDelete={onDeleteBuyerBorrower}
    >
      <Container>
        <div className={"container-row party"}>
          <SelectField
            className="party-type"
            label="Party Type"
            name={`${formPrefix}.${index}.partyTypeCode`}
            options={partyTypes}
            onChange={(e: any) => handleOnChange(e, index)}
            defaultValue={partyType || PartyType.Individual}
          />
        </div>
        <div className="container-row name-and-excludes">
          {partyType === PartyType.Individual ? (
            <>
              <StyledTextInputField
                label="First Name"
                className="first-name"
                name={`${formPrefix}.${index}.partyFirstName`}
                defaultValue={field.partyFirstName}
                onBlur={updatePartyBuyerBorrowers}
              />
              <TextInputField
                label="Middle"
                className="middle-name"
                name={`${formPrefix}.${index}.partyMiddleName`}
                defaultValue={field.partyMiddleName}
                onBlur={updatePartyBuyerBorrowers}
              />
              <TextInputField
                label="Last Name"
                className="last-name"
                name={`${formPrefix}.${index}.partyLastName`}
                defaultValue={field.partyLastName}
                onBlur={updatePartyBuyerBorrowers}
              />
              <SelectFieldWithClearIcon
                label="Suffix"
                className="suffix"
                name={`${formPrefix}.${index}.suffixType`}
                options={[ { text: "", value: "" },...suffixes]}
                onChange={(
                  _: React.ChangeEvent<{}>,
                  value: SelectFieldOption
                ) => handleSuffixChange(value, index)}
                defaultValue={{ text: "", value: "" }}
                allowsDelete={true}
                dataTextField="name"
                dataValueField="code"
                onBlur={updatePartyBuyerBorrowers}
              />
            </>
          ) : (
            <TextInputField
              label="Name"
              className="companyName"
              name={`${formPrefix}.${index}.companyName`}
              defaultValue={field.companyName}
              onBlur={updatePartyBuyerBorrowers}
            />
          )}

          {letterProductType &&
            <>
              {
                letterProductType === ProductType.Aal ?
                  <CheckboxInputField
                    label={`Exclude from AAL`}
                    name={`${formPrefix}.${index}.isExcludedOnAAL`}
                    onChange={updatePartyBuyerBorrowers}
                  />
                  :
                  <CheckboxInputField
                    label={`Exclude from CPL`}
                    name={`${formPrefix}.${index}.isExcludedOnCPL`}
                    onChange={updatePartyBuyerBorrowers}
                  />
              }
            </>
          }
        </div>
        <FullAddress
          schema={`${formPrefix}.${index}`}
          isPropertySection={false}
          showPropertyCheckbox={true}
          showForeignAddressCheckbox={true}
          onBlur={updatePartyBuyerBorrowers}
          verifyAddressHandler={(value: string) => {
            updateZipCodeBuyerBorrower(index, value);
          }}
        />
        {(isForeignAddress === 1) && (
          <ForeignAddress schema={`${formPrefix}.${index}`} onBlur={updatePartyBuyerBorrowers} />
        )}
        <input
          type="hidden"
          {...register(`${integrationKeyField}` as const)}
        />
        <input type="hidden" {...register(`${filePartyKeyField}` as const)} />
        <input
          type="hidden"
          {...register(`${partyRoleTypeCodeField}` as const)}
        />
      </Container>
    </CollapsableFormSection>
  );
};

export default BuyerBorrowerData;
