import { TableSortLabel } from "@mui/material";
import { styled } from "@mui/material";
import React, {
  ReactNode
} from "react";
import {
  colors,
  gaps,
  iconSize,
} from "theme/defaultStyle";
import SortIcon from "theme/icons/SortIcon";

type Props = {
  active: boolean,
  direction: "desc" | "asc",
  onRequestSort: () => void;
  children: ReactNode;
};

const StyledSortLabel = styled(TableSortLabel)(
  ({ active, direction }: { active: boolean, direction: string }) => (
    {
      "&:hover .MuiSvgIcon-root": {
        color: active ? colors.blue01 : colors.blue07,
      },
      "& .MuiSvgIcon-root": {
        color: active ? colors.blue01 : "transparent",
        fontSize: iconSize.xxsmall,
        transform: direction === "asc" ? "rotate(180deg)" : "rotate(0deg)",
      },
      flex: 1,
      gap: gaps.small1,
      width: "100%",
    }
  )
);

const StewartSortLabel = ({ active, direction, onRequestSort, children }: Props) => {
  return (
    <StyledSortLabel
      active={active}
      direction={direction}
      onClick={() => onRequestSort()}
      IconComponent={() => <SortIcon />}
    >
      {children}
    </StyledSortLabel>
  );
}

export default StewartSortLabel;
