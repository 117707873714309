import {
  Box,
  styled,
  Typography,
} from "@mui/material";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import React, { useEffect } from "react";
import PaymentSheetSelectionOptions, { PAYMENT_SHEET_OPTION_TYPE, PaymentSheetOption  }  from "./PaymentSheetSelectionOptions";

const Text = styled(Typography)({
  color: colors.black,
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  "&.mainText": {
    paddingBottom: padding.zero,
  },
  "&.helperText": {
    paddingTop: padding.small2,
  },
  marginBottom: margin.zero,
});

const BoxRoot = styled(Box)({
  textAlign: "center",
});



type Props  = {
  mainText?: string;
  helperText?: string;
  showPaymentSheetSelectionOptions?:boolean;
  paymentSheetSelectionTitle?:string;
  paymentSheetSelectionOptions?:PaymentSheetOption[];
  paymentSheetOptionDefault?: PAYMENT_SHEET_OPTION_TYPE;
  setSelectedPaymentSheetOption?: (paymentSheetOptionType: PAYMENT_SHEET_OPTION_TYPE) => void;  
};

export default function PaymentSheetContentMessage({
  mainText,
  helperText,
  showPaymentSheetSelectionOptions,
  paymentSheetSelectionTitle,
  paymentSheetSelectionOptions,
  paymentSheetOptionDefault,
  setSelectedPaymentSheetOption,
}: Props) {

  useEffect(() => {
    if (paymentSheetOptionDefault && setSelectedPaymentSheetOption) {
      setSelectedPaymentSheetOption(paymentSheetOptionDefault);
    }
  },[paymentSheetOptionDefault])

  return (
    <BoxRoot>
      {mainText && (
        <Text
          className="mainText"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: mainText }}
        />
      )}
      {showPaymentSheetSelectionOptions &&
        paymentSheetSelectionOptions &&
        paymentSheetSelectionTitle &&
        setSelectedPaymentSheetOption && (
          <PaymentSheetSelectionOptions
            groupName="payment-sheet-selection"
            options={paymentSheetSelectionOptions}
            title={paymentSheetSelectionTitle} 
            defaultValue={paymentSheetOptionDefault}
            setSelected={setSelectedPaymentSheetOption}
          />
        )}
      {helperText && <Text className="helperText" dangerouslySetInnerHTML={{ __html: helperText }} />}
    </BoxRoot>
  );
}
