import { JacketFormDetail } from "entities/UIModel";
import React from "react";
import { useCompanyForm } from "utils/context/CompanyFormContext";

export default function useFormDetail(
  formId: string | undefined,
  formType: string | undefined
) {
  const [apiFormDetails, setApiFormDetails] = React.useState<
    JacketFormDetail[]
  >([]);
  const [, { getJacketFormDetails }] = useCompanyForm();

  React.useEffect(() => {
    async function getFormDetailsAsync() {
      if (!formId || !formType) return;
      const formDetails = await getJacketFormDetails(formId, formType);
      if (formDetails) {
        setApiFormDetails(formDetails);
      }
    }
    getFormDetailsAsync();
  }, [formId, formType, getJacketFormDetails]);

  return { apiFormDetails };
}
