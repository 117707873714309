import { isValidDate } from "pages/file/utils/helper";
import { useCompanyContext } from "utils/context/CompanyContext";
import { useFiles } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import {
  ProductType,
  UIConstants,
} from "utils/data/enum";
import { addDays } from "utils/shared";

const useEffectiveDateValidation = () => {
  const [, { getComapanyContractEffectiveDates }] = useCompanyContext();
  const { getValues, setValue, setError } = useFormWrapper();
  const [{ isReadOnly }] = useFiles();
  const [{ selectedAgency }] = useGlobalAccess();  

  const validateEffectiveDate = async (
    product: string,
    index: number | undefined,
    newDate: Date | null | undefined,
    isCallingSetError: boolean = true,
    setEffectiveDateValidationMsg: boolean = true,
  ): Promise<string> => {
    let msg = "";
    if (!isReadOnly && newDate && isValidDate(newDate)) {
      newDate.setHours(0, 0, 0);
      const todayPlus30 = addDays(new Date(), 30);
      todayPlus30.setTime(todayPlus30.getTime() + todayPlus30.getTimezoneOffset() * 60000);
      todayPlus30.setHours(0, 0, 0);

      if (newDate > todayPlus30) {
        msg = UIConstants.EFFECTIVE_DATE_NOT_WITHIN_30_DAYS_ERROR_MESSAGE;
      }
      else {
        let productType = "";
        switch (product) {
          case "cpls":
            productType = ProductType.Cpl;
            break;
          case "aALProducts":
            productType = ProductType.Aal;
            break;
          case "jackets":
            productType = ProductType.Jacket;
            break;
          default:
            break;
        }

        let agencyId = getValues("agency.id");
        if(!agencyId) {          
          agencyId = selectedAgency ? selectedAgency[0]?.CompanyID : "";          
        }

        if(!agencyId || !productType) {
          return msg;
        }
        
        const comapanyContractEffectiveDates = await getComapanyContractEffectiveDates(
          agencyId,
          productType
        );
        
        if (comapanyContractEffectiveDates === undefined || !comapanyContractEffectiveDates?.some(cd => {
          let startDate: Date;
          let endDate: Date;
          if (cd.effectiveDate) {
            startDate = new Date(cd.effectiveDate);
            startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
            startDate.setHours(0, 0, 0);
          }
          else {
            startDate = new Date(0);
          }
          if (cd.effectiveEndDate) {
            endDate = new Date(cd.effectiveEndDate);
            endDate.setTime(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
            endDate.setHours(23, 59, 59);
          }
          else {
            endDate = todayPlus30;
          }

          return newDate >= startDate && newDate <= endDate;
        })) {
          msg = UIConstants.EFFECTIVE_DATE_NOT_VALID_ERROR_MESSAGE;
        }
      }

      if (isCallingSetError && msg !== "") {
        setError(
          getNameString(`${product}.${index}.effectiveDate`),
          {
            type: "focus",
            message: msg,
          },
          { shouldFocus: false }
        );
      }

      if (setEffectiveDateValidationMsg) {
        setValue(`${product}.${index}.effectiveDateValidationMsg`, msg);
      }
    }
    return msg;
  };

  return { validateEffectiveDate };
};

export default useEffectiveDateValidation;
