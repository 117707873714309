import { Description } from "@mui/icons-material";
import React from "react";
import { ReportAndPayAction } from "utils/data/enum";
import { getReportAndPayActionText } from "utils/shared";
import ActionButton from "../action-button";

type Props = {
  action: ReportAndPayAction;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
};

const ReportingButton = ({ action, disabled, hidden, onClick }: Props) => {
  const buttonText = getReportAndPayActionText(action);

  return (
    <ActionButton
      hidden={hidden}
      variant="outlined"
      color="primary"
      startIcon={<Description />}
      disabled={disabled}
      onClick={onClick}
      name={action.toString()}
    >
      {buttonText}
    </ActionButton>
  );
};


export default ReportingButton;