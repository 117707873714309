import React, {
  useMemo,
} from "react";
import { useFormState } from "react-hook-form";
import PropertyCollection from "./PropertyCollection";
import PropertyFormSection from "./PropertyFormSection";

const PropertySection = () => {
  const { errors } = useFormState();
  const propertiesKey = 'properties';
  // eslint-disable-next-line no-prototype-builtins
  const hasError = useMemo(() => errors.hasOwnProperty(propertiesKey), [errors.hasOwnProperty(propertiesKey)]);
  return (
    <PropertyFormSection
      title="Properties"
      isError={hasError}
    >
      <PropertyCollection />
    </PropertyFormSection>
  );
};

export default PropertySection;
