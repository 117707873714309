import { ViewColumnOutlined } from "@mui/icons-material";
import {
  ClickAwayListener,
  FormControlLabel,
  Grow,
  // Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from "@mui/material";
import Button from "@mui/material/Button";
import StewartCheckbox from "controls/global/stewart-checkbox";
import { orderBy } from "lodash";
import React from "react";
import {
  borderRadius,
  borderSize,
  colors,
  margin,
  padding,
  scrollBar,
  zIndex,
} from "theme/defaultStyle";
import theme from "theme/default";

export type MenuColumnItem<T> = {
  field: keyof T;
  name: string;
  checked: boolean;
};

type Props<T> = {
  options: MenuColumnItem<T>[] | undefined;
  onChecked: (field: keyof T, checked: boolean) => void;
  onHideAll?: () => void;
  onShowAll?: () => void;
  isDisabled?: boolean;
};

const StyledPopper = styled(Popper)({
  zIndex: zIndex.high2,
  "& .paper": {
    border: `${borderSize.xsmall} solid ${colors.grey09}`,
    marginTop: margin.small1,
    overflow: "hidden",
    "&.MuiPaper-rounded": {
      borderRadius: borderRadius.xlarge,
    },
    "& .MuiList-root": {
      maxHeight: "min(600px, calc(100vh - 300px))",
      minHeight: "100px",
      overflowY: "scroll",
      padding: padding.small0,
      margin: `${margin.small1, margin.xsmall1}`,
      "&::-webkit-scrollbar": {
        width: scrollBar.width1,
        height: scrollBar.height,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: scrollBar.borderRadius1,
        backgroundColor: colors.grey20,
        border: `${borderSize.medium} solid transparent`,
        backgroundClip: "content-box",
      },
      "&::-webkit-scrollbar-track": {
        background: colors.grey21,
        boxShadow: "1px 0px 0px 0px #DEDEDE inset",
      },
      "&::-webkit-scrollbar-button": {
        display: "none",
      },
    },
    "& .MuiMenuItem-root": {
      paddingTop: padding.zero,
      paddingBottom: padding.zero,
      margin: padding.zero,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: colors.grey10,
      color: colors.grey08,
    },
    '& .MuiFormControlLabel-root':{
      marginBottom :padding.zero,
    },
    "& .buttons": {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: padding.small1,
      paddingLeft: padding.large1,
      paddingRight: padding.large1,
      paddingTop: padding.small1,
    },
  },
  "& .column-list": {
    boxShadow: "0px 1px 5px 0px #6A747805, 0px 2px 2px 0px #6A74780A, 0px 3px 10px -2px #6A74781A"
  }
});

const Arrow = styled("div")({
  "&::before, &::after": {
    border: "solid 15px transparent",
    borderBottomColor: theme.palette.background.paper,
    borderTop: 0,
    content: '""',
    display: "block",
    height: 0,
    left: "-10px",
    overflow: "hidden",
    position: "absolute",
    top: "-5px",
    width: 0,
    zIndex: "2",
  },
  "&::before": {
    borderBottomColor: colors.grey09,
    top: "-6.5px",
    zIndex: 1,
  },
});

// const CustomLink = styled(Link)({
//   cursor: "pointer",
//   fontSize: fontSize.large,
//   fontWeight: fontWeight.normal1,
//   textDecoration: "none !important",
// });

export default function ColumnsConfig<T>({
  options,
  onChecked,
  onHideAll,
  onShowAll,
  isDisabled,
}: Props<T>) {
  const [columns, setColumns] = React.useState<
    MenuColumnItem<T>[] | undefined
  >();
  const [colMenuOpen, setColMenuOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<any>();
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const [partialSelect, setPartialSelect] = React.useState<boolean>(false);
  React.useEffect(() => {
    const sortedColumns = orderBy(options, "name");
    setColumns(sortedColumns);
    setInitialSelectAllValue(sortedColumns);
  }, [options]);

  const handleCheckBoxChange = () => {
    if (selectAll) {
      onHideAll && onHideAll(); 
      setSelectAll(false);
      setPartialSelect(false);
    } else {
      onShowAll && onShowAll();
      setSelectAll(true);
      setPartialSelect(false);
    }
  };

  // setting the inital value for select all columns checkbox 
  const setInitialSelectAllValue = (sortedColumns: MenuColumnItem<T>[]) => {
    if (sortedColumns) {
      if (sortedColumns.filter(x => x.checked == true).length == 0) { setSelectAll(false); }
      else if (sortedColumns.filter(x => x.checked == false).length == 0) { setSelectAll(true); }
      else { setPartialSelect(true); }
    }
  }

  // setting the recent checked value in columns
  const setSelectionState = (field: keyof T, checked: boolean) => {
    if (columns) {
      const updatedColumns = columns.map(x => {
        if (x.field === field) { x.checked = !checked; } return x;
      });
      const uncheckedCount = updatedColumns.filter(z => !z.checked).length;
      const allUnchecked = uncheckedCount === (updatedColumns?.length ?? 0);
      setSelectAll(!allUnchecked);
      setPartialSelect(uncheckedCount > 0 && !allUnchecked);
    }
  }
  
  if (!columns) return null;
  return (
    <>
      <Button
        onClick={() => setColMenuOpen(!colMenuOpen)}
        data-test="myFilesColumns"
        ref={anchorRef}
        disabled={isDisabled}
      >
        <>
          Columns
          <ViewColumnOutlined />
        </>
      </Button>
      <StyledPopper
        open={colMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
        placement="bottom-end"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <>
              <Arrow ref={setArrowRef} />
              <Paper
                className={"paper"}
                elevation={3}
              >
                <ClickAwayListener onClickAway={() => setColMenuOpen(false)}>
                  <MenuList
                    autoFocusItem={colMenuOpen}
                    id="menu-list-grow"
                    className= "column-list"
                    // onKeyDown={handleListKeyDown}
                  >
                    <MenuItem style={{ paddingLeft: "6px" }}>
                      <FormControlLabel
                        control={
                          <StewartCheckbox
                            onChange={handleCheckBoxChange}
                            name="select-all"
                            checked={selectAll}
                            indeterminate={partialSelect}
                          />
                        }
                        label="All Columns"
                      />
                    </MenuItem>
                    {columns.map(({ field, name, checked }) => (
                      <MenuItem key={String(field)}>
                        <FormControlLabel
                          control={
                            <StewartCheckbox
                              checked={checked}
                              onChange={() => {
                                onChecked(field, checked);
                                setSelectionState(field, checked);
                              }}
                              name={String(field)}
                            />
                          }
                          label={name}
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
                {/* <div className="buttons">
                  {onHideAll ? <CustomLink onClick={onHideAll}>Hide All</CustomLink> : null}
                  {onShowAll ? <CustomLink onClick={onShowAll}>Show All</CustomLink> : null}
                </div> */}
              </Paper>
            </>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
}
