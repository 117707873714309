import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React from "react";
import { useIsFileLoadingFiles } from "utils/context/FilesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  name: any;
  defaultValue?: string;
  disabled: boolean;
  onBlur?: any;
  labelPrefix?: string;
  onChange?: (_: React.ChangeEvent<{}>, value: SelectFieldOption) => void;
  zipCodeResults: any;
};

const CityField = ({
  name,
  defaultValue,
  disabled = false,
  onBlur,
  labelPrefix = "",
  onChange,
  zipCodeResults,
}: Props) => {
  const [{ isFileLoading }] = useIsFileLoadingFiles();
  const { getValues, setValue } = useFormWrapper();

  React.useEffect(
    function setDefaultCity() {
      if (isFileLoading || getValues(name)?.length > 0) return;
      if (zipCodeResults?.cities.length > 0 && zipCodeResults.city) {
        setValue(name, zipCodeResults?.city ?? zipCodeResults?.cities[0].value);
      }
    },
    [zipCodeResults, isFileLoading, name, setValue]
  );

  return (
    <ComboBoxField
      freeSolo
      label={`${labelPrefix}City/Town/Municipality`}
      options={zipCodeResults?.cities}
      {...{ name, disabled, defaultValue }}
      onBlur={onBlur}
      onChange={onChange}
      roundedCorner={true}
    />
  );
};

export default CityField;
