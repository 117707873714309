import DatePickerField from "controls/global/datepicker";
import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  name: string;
  issued:boolean;
  defaultValue?: any;
  disabled?: boolean;
  onEffectiveDateChange?: (date: Date | null) => void;
  defaultToToday?: boolean;
};

const EffectiveDateField = ({
  name,
  issued,
  defaultValue,
  disabled,
  onEffectiveDateChange,
  defaultToToday,
}: Props) => {
  const {getValues} = useFormWrapper();
  React.useEffect(() => {
    const effectiveDate = getValues(name);
    if (!issued && effectiveDate) {
      onEffectiveDateChange && onEffectiveDateChange(effectiveDate);
    }
  },[])

  return (
    <>
      <DatePickerField
        label="Effective Date"
        showError={true}
        validateOnBlur={false}
        {...{
          disabled,
          name,
          defaultValue,
          onChangeCallback: onEffectiveDateChange,
          onBlurCallback: onEffectiveDateChange,
          defaultToToday,
        }}
      />
    </>
  );
};

export default EffectiveDateField;
