import React from "react";
import TextInputField from "controls/global/text-input-field";
import { BaseAddressChangeProps } from "utils/interfaces/BaseAddress";

const Field = ({
  name,
  label,
  isDisabled = false,
  onBlur,
  onChange,
  size = "medium",
}: BaseAddressChangeProps) => {
  return (
    <>
      <TextInputField
        label={label}
        disabled={isDisabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        size={size}
      />
    </>
  );
};

export default Field;
