import React from "react"
type Action = {type: "initial", payload: any} | {type: "expanded", payload: any} | {type: "collapse", payload: any} | {type: "toggle", payload: any}
type Dispatch = (action: Action) => void
type State = any;
type CollapsableProviderProps = {children: React.ReactNode}

const CollapsableContext = React.createContext<
{stateAccordion: State; accordionAction: Dispatch} | undefined
>(undefined)



function collapseReducer(state:State, action:Action) {
  switch (action.type) {
    case "expanded": {
      return {
        ...state,
        [action.payload]: true
      }
    }
    case "collapse": {
      return {
        ...state,
        [action.payload]: false
      }
    }
    case "toggle": {
      const currentValue =  state[action.payload] !== undefined ?  state[action.payload] : true;
      return {
        ...state,
        [action.payload]: !currentValue
      }
    }
    case "initial": {
      return action.payload;
    }
    default: {
      throw new Error("Unhandled action type for collapseProvider")
    }
  }
}
function CollapsableProvider({children}: CollapsableProviderProps) {
  const [state, dispatch] = React.useReducer(collapseReducer, {})

  const value = {stateAccordion: state, accordionAction: dispatch}
  return <CollapsableContext.Provider value={value}>{children}</CollapsableContext.Provider>
}

function useAccordion() {
  const context = React.useContext(CollapsableContext)
  if (context === undefined) {
    throw new Error("useAccordion must be used within a CollapsableProvider")
  }
  return context
}
export {CollapsableProvider, useAccordion}
