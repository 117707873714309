import { CancelTokenSource } from "axios";
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import Municipal from "entities/UIModel/company/Municipal";
import { MunicipalTaxCode } from "entities/UIModel/company/MunicipalTaxCode";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";
import { uniqBy } from "lodash";

type State = {
  municipal: Municipal;
  axiosCancelToken?: CancelTokenSource;
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const getMunicipalCachedKey = (state: string) => {
  return `MunicipalCachedKey-${state}`;
};

const setMunicipal =
  (data: any, state: string) =>
    ({ setState }: StoreApi) => {
      let taxCodes: Array<MunicipalTaxCode> = [];
      let taxCodeOptions: Array<SelectFieldOption> = [];      

      if (data && data.length > 0) {
        taxCodes = data.map((mc: any) => {
          const taxCode: MunicipalTaxCode = {
            municipalCode: mc.municipalCode,
            displayName: mc.displayName,
            municipalCountyName: mc.municipalityCountyName,
            searchName: mc.searchName,
            isCounty: mc.countyFlag,
            isMunicipality: mc.municipalityFlag,
            minTax: mc.minimumTax,
            tax: mc.premiumTaxPercent,
            default: mc.default && mc.default === 1 ? true : false,
          };
          return taxCode;
        });

        taxCodeOptions = data.map((mc: any) => {
          const taxCodeOption: SelectFieldOption = {
            value: mc.municipalCode,
            text: mc.displayName,
          };
          return taxCodeOption;
        });

        taxCodeOptions = uniqBy(taxCodeOptions, x => x.value);
      }

      setState({
        municipal: {
          state: state,
          taxCodes: taxCodes,
          taxCodeOptions: taxCodeOptions,
        },
      });
    };

const actions = {
  getMunicipal:
    (state: string) =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        const cachedKey = getMunicipalCachedKey(state);
        const cachedData = sessionStorage.getItem(cachedKey);

        if (cachedData) {
          dispatch(setMunicipal(JSON.parse(cachedData), state));
        } else {
          try {
            const token = getState().axiosCancelToken;
            if (token) token.cancel("getMunicipal canceled due to new request");

            const newCancelToken = cancelToken.source();
            setState({ axiosCancelToken: newCancelToken });

            const { data } = await axiosSecuredInstance.get<any>(
              `/Company/GetMunicipalCodes?State=${state}`,
              { cancelToken: newCancelToken.token }
            );

            sessionStorage.setItem(cachedKey, JSON.stringify(data));
            dispatch(setMunicipal(data, state));
          } catch (error) {
            console.error("ERROR: CompanyContext.", error);
          }
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    municipal: {
      state: undefined,
      taxCodes: [],
      taxCodeOptions: [],
    },
  },
  actions,
  name: "companyMunicipal",
});

const hook = createHook(Store);
export const useCompanyMunicipal = () => { return hook(); };
