import {
  AutocompleteInputChangeReason,
  PopperPlacementType,
} from "@mui/material";
import AutocompleteField from "controls/global/custom-autocomplete";
import TextInputField from "controls/global/text-input-field";
import { AdditionalPartyName } from "entities/UIModel/AdditionalPartyName";
import React from "react";
import {
  AdditionalPartySearch,
  useAdditionalPartiesContext,
} from "utils/context/AdditionalPartiesContext";
import { useAdditionalPartySearchAction } from "utils/context/AdditionalPartySearchContext";
import { useLookup } from "utils/context/LookupContext";
import { usePartyAdditionalPartyActions } from "utils/context/PartyAdditionalPartyContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { getStateByText } from "utils/data/state";

type Props = {
  name: string;
  schema: string;
  label: string;
  searchProp: keyof AdditionalPartySearch;
  disabled: boolean;
  popperPlacement?: PopperPlacementType | undefined;
};

export default function AdditionalPartiesAutocomplete({
  name,
  schema,
  label,
  searchProp,
  disabled,
  popperPlacement = "bottom",
}: Props) {
  const fieldName = `${schema}.${name}`;
  const { setValue, getValues } = useFormWrapper();
  const [{ additionalParties, isLoadingAdditionalParties }] =
    useAdditionalPartiesContext();
  const [, { setAdditionalParties }] = usePartyAdditionalPartyActions();
  const [{ states }] = useLookup();
  const [, { reset, update }] = useAdditionalPartySearchAction();

  const handleOnBlur = (e: any) => {
    const cvalue = e?.target?.value;        
    if (cvalue && typeof cvalue === "string" && !disabled) {      
      setTimeout(() => {
        setValue(`${schema}.addressOne`, "");
        setValue(`${schema}.addressTwo`, "");
        setValue(`${schema}.city`, "");
        setValue(`${schema}.state`, { code: "", abbr: "" });
        setValue(`${schema}.zipCode`, "");
        setValue(`${schema}.companyName`, "");
        setValue(`${schema}.individualAttorney`, "");
        setValue(`${schema}.favorite`, false);
        setValue(`${schema}.selectedAdditionalPartyID`, "");
        setValue(`${schema}.isDirty`, true);
        setValue(`${schema}.filePartyId`, 0);
        setValue(`${schema}.altReferenceID`, "");
        setValue(`${schema}.locationID`, "");
        reset();
        const validAdditionalParties = getValues("additionalParties");        
        setAdditionalParties(validAdditionalParties?.filter((p: any) => p.companyName));        
      }, 500);      
    }
  }

  const handleChange = (value: string | AdditionalPartyName | undefined) => {
    if (!value || typeof value === "string") return;

    setValue(`${schema}.addressOne`, value.AddressLine1);
    setValue(`${schema}.addressTwo`, value.AddressLine2);
    setValue(`${schema}.city`, value.City);
    setValue(`${schema}.state`, getStateByText(states, value.State));
    setValue(`${schema}.zipCode`, value.Zip);
    setValue(`${schema}.companyName`, value.ApprovedAttorneyName || value.Name);
    setValue(
      `${schema}.individualAttorney`,
      value.IndividualAttorneyName || value.Name
    );
    setValue(`${schema}.favorite`, value.IsFavorite);
    setValue(`${schema}.selectedAdditionalPartyID`, value.ID);
    setValue(`${schema}.altReferenceID`, value.AltReferenceID);
    setValue(`${schema}.locationID`, value.LocationID);
    setValue(`${schema}.companyID`, value.CompanyID);
    
    reset();
    setAdditionalParties(getValues("additionalParties"));
  };

  if (disabled) {
    return <TextInputField name={fieldName} label={label} disabled />;
  }

  return (
    <AutocompleteField
      name={fieldName}
      label={label}
      loading={isLoadingAdditionalParties}
      minLengthBeforeSearch={2}
      onTypeInput={(
        inputText: string,
        reason: AutocompleteInputChangeReason
      ) => {
        if (reason !== "input") return;
        setValue(fieldName, inputText);
        update(searchProp, inputText);
      }}
      onBlur={(e) => {handleOnBlur(e)}}
      onValueChanged={handleChange}
      getOptionLabel={(option: AdditionalPartyName | string) =>
        typeof option === "string" ? option : option.DisplayName
      }
      getOptionSelected={(
        option: AdditionalPartyName,
        value: AdditionalPartyName
      ) => option.DisplayName === value.DisplayName}
      options={additionalParties.length > 0 ? additionalParties : undefined}
      popperPlacement={popperPlacement}
    />
  );
}
