import { DeleteCriteria } from "entities/ApiModel";
import {
  Property,
  SCFile,
} from "entities/UIModel";
import {
  getPropertiesCount,
  hasAdditionalParty,
  hasPendingProducts,
} from "pages/file/utils/helper";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import { useAutomaticProgressDialogState } from "utils/context/AutomaticProgressDialogContext";
import { useCompanyCountyActions } from "utils/context/CompanyCountiesContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import {
  useFilesActions,
  useIsFileLoadingFiles,
} from "utils/context/FilesContext";
import { useLookup } from "utils/context/LookupContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import { OrderStatusType } from "utils/data/enum";
import StateChangeConfirmation from "./StateChangeConfirmation";
import SelectFieldWithClearIcon from "../select-field/SelectFieldWithClearIcon";

interface Props {
  name: string;
  stateOptions?: any[];
  labelPrefix?: string;
  isDisabled?: boolean;
  isPropertySection?: boolean; // Using to suppress state validation for parties section
  errorOverride?: string;
  autosetValue?: boolean;
  isChangeIsProgress?: boolean;
  onChange?: any;
  onBlur?: () => void;
  zipCodeResults?: any;
  resetZipcodeData?: () => void;
  setNoDefault?: () => void;
  tabIndex?: number;
}

//#region BasicStateField
const BasicStateField = ({
  name,
  labelPrefix = "",
  isDisabled = false,
  onChange,
  stateOptions,
  errorOverride,
  onBlur,
  setNoDefault,
  tabIndex = 0,
}: Props) => {
  const { setValue, getValues } = useFormWrapper();
  const [{ states }, { getStates }] = useLookup();
  if (states.length === 0) getStates();
  
  const statesWithDefaultOption = [{ text: "", value: "" }, ...states];
  const isReadOnly = getValues("isReadOnly");
  
  const handleOnChange = (_: any, item: any) => {
    setValue(name, item);
    setNoDefault && setNoDefault();
  };

  return (
    <SelectFieldWithClearIcon
      label={`${labelPrefix}State`}
      options={ isReadOnly ? statesWithDefaultOption : stateOptions ?? statesWithDefaultOption}
      name={name}
      dataTextField="abbr"
      dataValueField="code"
      disabled={isDisabled}
      onChange={onChange || handleOnChange}
      allowsDelete={statesWithDefaultOption.length > 2}
      autosetValue={false}
      errorOverride={errorOverride}
      onBlur={onBlur}
      tabIndex={tabIndex}
      defaultValue={{ abbr: "", code: "" }}
    />
  );
};
//#endregion

//#region PropertyStateField
/**
 * Requirements of note for StateField in the properties section:
 * 1) When agencyLocation changes, the states are reloaded.  If the current state is not in the new list of states
 *    we need to catch that and trigger the confirmation dialog
 * 2) If any property in the property section has it's state changed, they all must change.  But only fire the warning
 *    dialog once.
 * 3) If the state is changed, the counties must be reset.  Probably handle this in the Counties control, but test it anyway.
 *    Should suffice to only reset them once, since they are all based on the same state.
 * 4) Property State should honor the user's default state in their profile, if set.
 * 5) if there is only one available state, select it by default
 */
const PropertyStateField = ({
  name,
  isPropertySection,
  labelPrefix = "",
  isDisabled = false,
  onChange,
  errorOverride,
  resetZipcodeData,
  setNoDefault,
}: Props) => {
  const { setValue, getValues } = useFormWrapper();
  const { setError, clearErrors } = useFormContext();
  const [filePropertyState, { setFilePropertyState }] = useFilePropertyState();
  const [, { deleteFileData, resetProductAndAdditionalParty }] =
    useFilesActions();

  const [agencyLocationName] = useWatch({
    name: [getNameString("agencyLocation.name")],
  });

  /**
   * Payload and state for managing the confirmation dialog
   */
  const defaultConfirmationDialogProperties = {
    open: false,
    itemValue: undefined,
    prevValue: undefined,
    confirmationMessage: "",
    userDriven: false,
  };
  const [confirmationDialogProperties, setConfirmationDialogProperties] = useState(defaultConfirmationDialogProperties);
  const [failsafeErrorFlag, setFailsafeErrorFlag] = useState<boolean>(false);
  const [userClearedState, setUserClearedState] = useState<boolean>(false);

  const [{ agencyStates }, { getAgencyStates }] = useAgencyStates();
  const states = [{ text: "", value: "" }, ...agencyStates];
  const [{ isFileLoading }] = useIsFileLoadingFiles();
  const [{ open: progressDialogOpen }] = useAutomaticProgressDialogState();
  const [{ profileSettings }] = useProfileSettingsCache();
  const [, { resetCounties }] = useCompanyCountyActions();
  const defaultPropertyState = profileSettings.userProfile
    .defaultPropertyState as string;

  useEffect(
    /**
     * When agencyLocationName has changed and triggered a redraw,
     * we need to get the updated states list
     */
    function refreshAgencyStates() {
      if (isFileLoading) return;
      const [agencyId] = getValues(["agency.id"]);

      if (agencyLocationName) {
        getAgencyStates(agencyId, agencyLocationName);
      }
    },
    [agencyLocationName]
  );

  useEffect(
    /**
     * When the state value has changed for the file property hook
     * or if the states list has changed, we need to update our
     * current state if it doesn't already match or if the state is
     * no longer in the list of states.
     *
     * This allows us to keep all of the property states in alignment
     * and also catch when the value is not in the list of available
     * states
     */
    function synchronizePropertyStates() {
      const scFile: SCFile = getValues();
      if (isFileLoading || progressDialogOpen || scFile.isLocked) return;
      if (agencyStates.length === 0) return;

      if (confirmationDialogProperties.open) {
        resetConfirmationDialogProperties();
      }
      const currentState = getValues(name);
      /**
       * confirm that the current state is in the list of agencyStates
       *
       * Keep in mind that this useEffect will run if the filePropertyState
       * changes OR if the states list changes. Neither *should* happen at
       * the same time and thus we don't need to check if the filePropertyState
       * is inside the current list of states.
       */
      let foundState = states.find((state) => {
        return state.value === (currentState?.code ?? currentState?.value);
      });

      const foundProfileState = states.find((state) => {
        return state.value === defaultPropertyState && state.value !== "";
      });

      const firstValidState = states.length === 2 ? states[1] : undefined;

      /**
       * if the found state is not found or if it is blank,
       * try using the profile state instead.
       */
      if (
        foundState === undefined ||
        (foundState?.value === "" &&
          foundProfileState !== undefined &&
          !userClearedState)
      ) {
        foundState = foundProfileState;
      }

      if (
        (foundState === undefined || foundState?.value === "") &&
        firstValidState !== undefined &&
        !userClearedState
      ) {
        foundState = firstValidState;
      }

      if (foundState === undefined && !userClearedState) {
        /**
         * new state selection is not in the list of available states
         * so we revert to an empty state
         */
        const newStateValue = { abbr: "", code: "" };
        propertyStateOnChange(undefined, newStateValue, false);
      }

      //there is some situations where the object is key/value and others where it is abbr/code.  No time to
      //fix this right now, but maybe someday? CJR 22/Sept/22
      else if (
        foundState &&
        !(
          foundState.value === currentState?.code ||
          foundState.value === currentState?.value
        )
      ) {
        /**
         * new list of states contains our new filePropertyState so we check
         * if the new value is different than our current value and update if so
         */
        propertyStateOnChange(undefined, foundState, false);
      }
      else if (
        filePropertyState?.abbr &&
        filePropertyState.abbr !== (foundState?.text ?? foundState?.value)
      ) {
        propertyStateOnChange(undefined, filePropertyState, false);
      }
      else {
        /**
         * for completeness here.  This clause would be reached if the states list
         * has changed but the state itself has not and the state still exists in
         * the states list.
         */
        resetConfirmationDialogProperties();
      }
    },
    [filePropertyState, agencyStates, isDisabled, progressDialogOpen]
  );

  /**
   * This is the gateway function for changing the state. All of the dialog logic is limited
   * to the first property. This works because if I change the state on any of the other properties
   * the synchronizePropertyStates useEffect will still run for properties.0.state and enter this
   * function. if the dialog is cancelled, it will end up calling the synchronizePropertyStates
   * function on the other properties after the value has been reset.
   *
   * @param item is a SelectItemOption that has the abbr and code for the new state
   */
  const propertyStateOnChange = (
    _: any,
    item: any,
    userDriven: boolean = true
  ) => {
    const userHasClearedState = userDriven && item.code === "";
    if (userDriven) {
      setUserClearedState(userHasClearedState);
    } else if (userClearedState && item.code !== "") return;

    if (name === `properties.0.state`) {
      if (
        /**
         * We only need to confirm a state change if the file has pending (non-default) products
         * or non default additional parties
         */
        userDriven &&
        (hasPendingProducts(getValues() as SCFile) ||
          hasAdditionalParty(getValues(getNameString("additionalParties"))))
      ) {
        confirmStateChange(item, userDriven);
      } else {
        completePropertyStateChange(item, userDriven, userHasClearedState);
      }
    } else {
      completePropertyStateChange(item, userDriven, userHasClearedState);
    }
  };

  /**
   * This method sets the confirmation message and updates the dialog properties
   * to open the dialog
   */
  const confirmStateChange = (item: any, userDriven: boolean) => {
    const currentState = getValues(name);
    const hasMoreThanOneProperty =
      getPropertiesCount(getValues(getNameString("properties"))) > 1;

    let resetAllPropertyStatesMsg = "By changing the Property State, ";
    resetAllPropertyStatesMsg = hasMoreThanOneProperty
      ? resetAllPropertyStatesMsg + "all Property States will be changed and "
      : resetAllPropertyStatesMsg;

    resetAllPropertyStatesMsg +=
      "any Additional Parties and pending products will be lost.";

    const newProps = {
      open: true,
      prevValue: currentState,
      itemValue: item,
      confirmationMessage: resetAllPropertyStatesMsg,
      userDriven: userDriven,
    };

    setConfirmationDialogProperties(newProps);
  };

  /**
   * updates the filePropertyState context,
   * the isPropertiesValidationRequired in the form data, and
   * the property state in the form data
   *
   * Also triggers a required validation if the value.code is blank
   */
  const completePropertyStateChange = (
    value: any,
    userDriven: boolean,
    userHasClearedState: boolean
  ) => {
    //if (isDisabled) return; //Reduce churn during initial property setting

    const newState = {
      abbr: value.text ?? value.abbr,
      code: value.value ?? value.code,
    };

    setValue(name, newState);
    if (isPropertySection) {
      setFilePropertyState(newState.abbr, newState.code);
      setValue("isPropertiesValidationRequired", true);
    }

    if (newState.code === "" && !isDisabled && !userHasClearedState) {
      setFailsafeErrorFlag(true);
      setError(`${name}` as const, { type: "manual", message: "Required" });
    } else {
      clearErrors(`${name}` as const);
    }
    //Reset county field
    const properties = getValues('properties') as Array<Property>;
    if (properties && properties.length > 0) {
      properties.forEach((p, i) => {
        setValue(`properties.${i}.county`, { name: "", code: "" });
      });
    }
    resetZipcodeData?.();
    resetCounties();
    onChange && onChange(null, newState);
    if (userDriven) {
      setNoDefault && setNoDefault();
      if (
        hasPendingProducts(getValues() as SCFile) ||
        hasAdditionalParty(getValues(getNameString("additionalParties")))
      ) {
        deletePendingFileData();
      }
    }
  };

  /**
   * This is triggered if the user clicks the yes button on the dialog
   */
  const confirmPropertyStateChange = () => {
    completePropertyStateChange(
      confirmationDialogProperties.itemValue,
      confirmationDialogProperties.userDriven,
      userClearedState
    );
    resetConfirmationDialogProperties();
  };

  /**
   * Remove pending products and/or additional parties
   */
  const deletePendingFileData = () => {
    const scfile = getValues() as SCFile;

    let sOrderIds = "";
    let sFilePartyIds = "";
    // delete products from database
    scfile.jackets
      ?.filter(
        (p) =>
          (p.orderID || 0) > 0 &&
          p.orderStatusTypeCode === OrderStatusType.Pending
      )
      ?.forEach((p) => {
        sOrderIds += `${p.orderID},`;
      });

    scfile.cpls
      ?.filter(
        (p) =>
          (p.orderID || 0) > 0 &&
          p.orderStatusTypeCode === OrderStatusType.Pending
      )
      ?.forEach((p) => {
        sOrderIds += `${p.orderID},`;
      });

    scfile.aALProducts &&
      scfile.aALProducts
        ?.filter(
          (p) =>
            (p.orderID || 0) > 0 &&
            p.orderStatusTypeCode === OrderStatusType.Pending
        )
        ?.forEach((p) => {
          sOrderIds += `${p.orderID},`;
        });

    scfile.standaloneEndorsements
      ?.filter(
        (p) =>
          (p.orderID || 0) > 0 &&
          p.orderStatusTypeCode === OrderStatusType.Pending
      )
      ?.forEach((p) => {
        sOrderIds += `${p.orderID},`;
      });

    // delete additional parties from database
    scfile.additionalParties
      ?.filter((party) => (party.filePartyId || 0) > 0)
      ?.forEach((party) => {
        sFilePartyIds += `${party.filePartyId},`;
      });

    if (sOrderIds.length > 1)
      sOrderIds = sOrderIds.substring(0, sOrderIds.length - 1);
    if (sFilePartyIds.length > 1)
      sFilePartyIds = sFilePartyIds.substring(0, sFilePartyIds.length - 1);

    // delete file data
    const deleteCriteria: DeleteCriteria = {
      FileID: scfile.id || 0,
      OrderIDs: sOrderIds,
      FilePartyIDs: sFilePartyIds,
    };
    if (
      deleteCriteria &&
      deleteCriteria.FileID > 0 &&
      (deleteCriteria.OrderIDs || deleteCriteria.FilePartyIDs)
    ) {
      deleteFileData(deleteCriteria);
    }

    resetProductAndAdditionalParty(scfile);

    setValue(`hasNonDefaultLetterProduct`, false);
    setValue(`hasNonDefaultJacket`, false);
    setValue(`hasNonDefaultSAE`, false);
  };

  /**
   * This is triggered if the user cancels the dialog or says no
   */
  const cancelPropertyStateChange = () => {
    /**
     * You can get here if the agencyLocation field triggered a change that
     * in turn tripped the refreshAgencyStates use effect which would in turn
     * trip the synchronizePropertyStates useEffect. The way to know if this
     * has happened is to examin the agencyLocationPrevious value in the form.
     *
     * TODO: consider moving this out to a hook somewhere rather than storing
     * in the form data itself.
     */
    const existingAgencyLocation = getValues("agencyLocationPrevious");
    if (existingAgencyLocation && existingAgencyLocation.name) {
      setValue("agencyLocation", existingAgencyLocation);
      setValue(name, confirmationDialogProperties.prevValue);
    }
    resetConfirmationDialogProperties();
  };

  const resetConfirmationDialogProperties = () => {
    setConfirmationDialogProperties(defaultConfirmationDialogProperties);
  };
  if (failsafeErrorFlag) {
    setError(`${name}` as const, { type: "manual", message: "Required" });
    setFailsafeErrorFlag(false);
  }

  const isFieldDisabled = isDisabled || name !== "properties.0.state";

  const tabIndex = agencyStates.length > 1 ? 0 : -1;

  return (
    <>
      <BasicStateField
        {...{
          name,
          labelPrefix,
          errorOverride,
        }}
        tabIndex={tabIndex}
        isDisabled={isFieldDisabled}
        onChange={propertyStateOnChange}
        stateOptions={states}
      />
      {confirmationDialogProperties.open && (
        <StateChangeConfirmation
          isConfirmationOpen={true}
          confirmationMessage={confirmationDialogProperties.confirmationMessage}
          handleYes={confirmPropertyStateChange}
          handleNo={cancelPropertyStateChange}
        />
      )}
    </>
  );
};
//#endregion

const StateField = (props: Props) => {
  const { getValues, setValue } = useFormWrapper();
  const [, { setFilePropertyState }] = useFilePropertyState();
  const [, { getCounties }] = useCompanyCountyActions();
  const [{ isFileLoading }] = useIsFileLoadingFiles();
  const { name, isPropertySection, zipCodeResults } = props;

  React.useEffect(
    function setDefaultStateFromZipCodeResults() {
      if (isFileLoading) return;
      if (zipCodeResults === undefined) return;

      const [currentState] = getValues([name]);

      const [hasIssuedProducts] = getValues(["hasIssuedProducts"]);
      
      if (
        currentState?.code !== zipCodeResults.state.code &&
        !(isPropertySection && hasIssuedProducts)
      ) {
        setValue(name, zipCodeResults.state);
        const agency = getValues(`agency`);
        getCounties(
          agency.id,
          getValues(`properties.0.state`)?.abbr,
          agency.activeContractID
        );        
      }
      if(isPropertySection) {
        setFilePropertyState(
          zipCodeResults.state.abbr,
          zipCodeResults.state.code
        );
      }
    },
    [zipCodeResults]
  );

  /**
   * The difference between a basic state field (for party, etc) and
   * the more robust property state field is enough to merit
   * breaking them apart to avoid wasted redraws
   */
  return isPropertySection ? (
    <PropertyStateField {...props} />
  ) : (
    <BasicStateField {...props} />
  );
};

export default StateField;
