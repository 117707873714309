import React from "react";

const FAQIcon = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM13 18H11V16H13V18ZM13.976 13.115C13.78 13.273 13.591 13.424 13.441 13.574C13.033 13.981 13.001 14.351 13 14.367V14.5H11V14.333C11 14.215 11.029 13.156 12.026 12.159C12.221 11.964 12.463 11.766 12.717 11.56C13.451 10.965 13.933 10.531 13.933 9.933C13.9214 9.42782 13.7125 8.94725 13.3511 8.59412C12.9896 8.24099 12.5043 8.04334 11.999 8.04347C11.4937 8.0436 11.0085 8.2415 10.6472 8.59482C10.286 8.94814 10.0774 9.42881 10.066 9.934H8.066C8.066 7.765 9.831 6 12 6C14.169 6 15.934 7.765 15.934 9.934C15.934 11.531 14.755 12.484 13.976 13.115Z" fill="url(#paint0_linear_142_1185)" />
        <defs>
          <linearGradient id="paint0_linear_142_1185" x1="12" y1="2" x2="12" y2="22" gradientUnits="userSpaceOnUse">
            <stop stopColor="#9E2339" />
            <stop offset="1" stopColor="#620000" />
          </linearGradient>
        </defs>
      </svg>

    </>
  );
};

export default FAQIcon;
