import {
  styled,
} from "@mui/material";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";
import FullAddress from "controls/global/full-address";
import TextInputField from "controls/global/text-input-field";
import {
  Agency,
  GeneralFileParty,
  Property,
  SavedContactResponse,
} from "entities/UIModel";
import React, {
  useCallback,
} from "react";
import theme from "theme/default";
import { gaps } from "theme/defaultStyle";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import { useSuccessorLanguages } from "utils/context/CompanySuccessorLanguageContext";
import { usePartyLenderActions } from "utils/context/PartyLenderContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { IAddressComponent } from "utils/interfaces/BaseAddress";
import CompanyName from "../CompanyName";
import SuccessorLanguageField from "../SuccessorLanguageField";

interface ILenderProps extends IAddressComponent {
  index: number;
  party: GeneralFileParty;
  parentArrayName?: string;
  isAAL?: boolean;
  deleteParty?: (index: number) => void;
  agency: Agency;
  property: Property;
  additionalParties: GeneralFileParty[];
  fileNameNumber: string;
  hasIssuedProducts: boolean;
  isPopulatingExistingFile: boolean;
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > .container-row": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: gaps.large1,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& > *": {
      maxWidth: "100%",
    },
  },
  "& .MuiCheckbox-root": {
    marginLeft: "-9px",// Margin offset to allow checkbox to align, but also show hover effect.
  },
  "& > .company-contact": {
    maxWidth: "100%",
    "& > div": {
      flexBasis: "content",
      flexGrow: 1,
      flexShrink: 1,
    },
    "& > .lender-info": {
      display: "flex",
      flexDirection: "row",
      gap: gaps.large1,
      width: "100%",
      "& > .lenderContact": {
        flexGrow: 1,
        minWidth: "250px",
      },
      "& > .MuiSvgIcon-root": {
        alignSelf: "center",
      },
    },
    "& > .companyName": {
      maxWidth: "400px",
      minWidth: "150px",
    },
    "& > .checkboxWrapper": {
      maxWidth: "160px",
      minWidth: "160px",
    },
    "& > .companyName, & > .lenderContact, & > .checkboxWrapper": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
  "& > .loan-successor-attention": {
    maxWidth: "100%",
    "& > div": {
      flexBasis: "content",
      flexGrow: 1,
      flexShrink: 1,
    },
    "& > .loanNumber": {
      maxWidth: "250px",
      minWidth: "200px",
    },
    "& > .successorLanguage": {
      minWidth: "200px",
    },
    "& > .attentionTo": {
      maxWidth: "160px",
      minWidth: "160px",
    },
    "& > .loanNumber, & > .successorLanguage, & > .attentionTo": {
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
  },
});

const getLenderDescription = (companyName?: string) =>
  companyName ? ` ${companyName}` : "";

const Lender = ({
  index,
  party,
  parentArrayName = "",
  isAAL,
  deleteParty,
  additionalParties,
  fileNameNumber,
  agency,
  property,
  hasIssuedProducts,
  isPopulatingExistingFile,
}: ILenderProps) => {
  const { getValues, setValue, register } = useFormWrapper();
  const [, { setLenders, updateZipCodeLender }] = usePartyLenderActions();
  const [{ successorLanguages }] = useSuccessorLanguages();

  const integrationKeyField = `${parentArrayName}.${index}.integrationKey`;
  const filePartyKeyField = `${parentArrayName}.${index}.filePartyKey`;
  const partyRoleTypeCodeField = `${parentArrayName}.${index}.partyRoleTypeCode`;
  const partyTypeCodeField = `${parentArrayName}.${index}.partyTypeCode`;
  const isFileDisabled = useFieldDisabler("LenderClearButton");

  const handleDeleteParty = () => {
    deleteParty && deleteParty(index);
  };

  const handleOnCompanyNameSelected = (
    index: number,
    value: SavedContactResponse | string
  ) => {
    if (value === null) return;

    if (typeof value === "string") {
      setValue(`${parentArrayName}.${index}.companyName`, value);
    } else {
      setValue(
        `${parentArrayName}.${index}.companyName`,
        value.ContactCompanyName
      );
      setValue(`${parentArrayName}.${index}.addressOne`, value.Address);
      setValue(`${parentArrayName}.${index}.addressTwo`, value.Address2);
      setValue(`${parentArrayName}.${index}.city`, value.City);
      setValue(`${parentArrayName}.${index}.zipCode`, value.Zip);
      setValue(
        `${parentArrayName}.${index}.individualAttorney`,
        value.ContactAdditionalName || ""
      );
      setValue(`${parentArrayName}.${index}.state`, {
        code: value.StateCode,
        abbr: value.StateAbbr,
      });
      setValue(`${parentArrayName}.${index}.phone`, value.Phone);
      setValue(`${parentArrayName}.${index}.mobile`, value.Mobile);
      setValue(`${parentArrayName}.${index}.fax`, value.Fax);
      setValue(`${parentArrayName}.${index}.email`, value.Email);
      setValue(`${parentArrayName}.${index}.migrationKey`, value.MigrationKey);
    }
  };

  const label = getLenderDescription(
    getValues(`${parentArrayName}.${index}.companyName`)
  );

  const updatePartyLenders = useCallback(() => {
    setLenders(getValues("lenderParties"));
  }, [getValues, setLenders]);

  return (
    <CollapsableFormSection
      key={`lender-${index}`}
      index={index}
      title={`Lender ${index + 1}`}
      label={label}
      showDelete={!isFileDisabled}
      onDelete={handleDeleteParty}
    >
      <Container>
        <div className="container-row company-contact">
          <div className="companyName">
            <CompanyName
              name={`${parentArrayName}.${index}.companyName`}
              value={party.companyName}
              index={index}
              onChange={handleOnCompanyNameSelected}
              onBlur={updatePartyLenders}
            />
          </div>
          <div className="lender-info">
            <TextInputField
              label="Lender Contact"
              className="lenderContact"
              name={`${parentArrayName}.${index}.individualAttorney`}
            />
            <CustomTooltip
              title={`Lender Contact will not appear on the ${isAAL ? "AAL" : "CPL"}.`}
              placement="top"
              aria-label="lender-contact-info"
              arrow
            >
              <StyledInfoIcon />
            </CustomTooltip>
          </div>
          <CheckboxInputField
            label="Save to contacts"
            className="saveCheckbox"
            name={`${parentArrayName}.${index}.saveToContacts`}
          />
        </div>
        <FullAddress
          schema={`${parentArrayName}.${index}`}
          isPropertySection={false}
          callbackIndex={index}
          onBlur={updatePartyLenders}
          verifyAddressHandler={(value: string) => {
            updateZipCodeLender(index, value);
          }}
        />
        <div className="container-row loan-successor-attention">
          <TextInputField
            label="Loan Number"
            className="loanNumber"
            name={`${parentArrayName}.${index}.loanNumber`}
            onBlur={updatePartyLenders}
          />
          <SuccessorLanguageField
            name={`${parentArrayName}.${index}.successorLanguage`}
            succesorLanguages={successorLanguages}
            defaultValue={party.successorLanguage}
            onBlur={updatePartyLenders}
          />
          <TextInputField
            label="Attention To"
            className="attentionTo"
            name={`${parentArrayName}.${index}.attentionTo`}
            onBlur={updatePartyLenders}
          />
        </div>
        <input
          type="hidden"
          {...register(`${integrationKeyField}` as const)}
        />
        <input type="hidden" {...register(`${filePartyKeyField}` as const)} />
        <input
          type="hidden"
          {...register(`${partyRoleTypeCodeField}` as const)}
        />
        <input
          type="hidden"
          {...register(`${partyTypeCodeField}` as const)}
        />
      </Container>
    </CollapsableFormSection >
  );
};

export default Lender;
