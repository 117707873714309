import ActionButton from "controls/global/action-button";
import CheckCircleIcon from "theme/icons/CheckCircleIcon";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import React,{
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  styled,
} from "@mui/material";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setIsRegisterFormOpen: any;
};

const StyledDialog = styled(Dialog)({
  "& div.MuiDialog-paper": {
    overflowY: "revert",
    maxWidth: "1000px",
  },
  "& div.MuiPaper-rounded": {
    borderRadius: defaultStyle.section.borderRadius,
  },
  "& div.MuiDialog-paperFullWidth": {
    width: "500px",
  },
});

const email = "CustomerCare@Stewart.com";

const StyledDialogTitleRoot = styled(DialogTitle)({
  color: colors.white,
  fontSize: fontSize.xlarge2,
  fontWeight: fontWeight.bold2,
  margin: `${margin.zero} auto`,
  padding: 0,
  textAlign: "center",
});

const StyledDialogTitle = styled(DialogTitle)({
  alignItems: "center",
  background: `linear-gradient(180deg, ${colors.green} 0%, #006E49 100%)`,
  borderRadius: borderRadius.large,
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  height: "130px",
  justifyContent: "center",
  margin: `${margin.zero} auto`,
  marginTop: "-65px",
  width: "95%",
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: "center",
  paddingTop: padding.zero,
  paddingBottom: padding.xlarge1,
});

const StyledDialogContent = styled(Box)({
  margin: `${margin.zero} auto`,
  marginBottom: margin.xlarge1,
  padding: `${padding.xlarge1} ${padding.zero}`,
  textAlign: "center",
  width: "90%",
});

const StyledDialogContentIcon = styled(Box)({
  margin: margin.xlarge3,
});

const StyledActionButton = styled(ActionButton)({
  height: "36px",
  width: "201px",
});

const LinkColor = styled("a")({
  color: colors.blue01,
});

const StyledPersonAddIcon = styled(PersonAddIcon)({
  "&&": {
    color: colors.white,
    height: "44px",
    marginLeft: margin.xlarge,
    width: "44px",
  },
});

const StyledTypography = styled(Typography)({
  marginBottom: margin.xlarge,
});

const buttonStyle = {
  width: "97px",
};

const RegisterFormSuccessDialog = ({ isOpen, onClose, setIsRegisterFormOpen }: Props) => {
  const [open, setOpen] = useState(false);

  const handleCloseAndReopenForm = () => {
    onClose();
    setIsRegisterFormOpen(true);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <StyledDialog
        open={open}
        maxWidth={"lg"}
      >
        <StyledDialogTitle>
          <StyledPersonAddIcon />
          <StyledDialogTitleRoot>Thank You</StyledDialogTitleRoot>
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledDialogContentIcon>
            <CheckCircleIcon />
          </StyledDialogContentIcon>
          <StyledTypography variant="h4">Thank you for requesting a Stewart Connect account!</StyledTypography>
          <Typography variant="h5">
            We are processing your request. Please allow up to 48 business hours for your account to be processed. For
            immediate assistance, please call (844) 835-1200 or email{" "}
            <LinkColor href={`mailto:${email}`}>{email}</LinkColor>
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledActionButton
            variant="outlined"
            color="secondary"
            onClick={handleCloseAndReopenForm}
            startIcon={<PersonAddIcon />}
          >
            Request AnotherUser
          </StyledActionButton>
          <ActionButton
            variant="outlined"
            color="primary"
            onClick={onClose}
            style={buttonStyle}
          >
            Close
          </ActionButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
};
export default RegisterFormSuccessDialog;
