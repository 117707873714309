import {
  styled,
  Theme,
} from "@mui/material";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import CurrencyInputField from "controls/global/currency-field";
import DatePickerInputField from "controls/global/datepicker";
import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import TextInputField from "controls/global/text-input-field/TextInputField";
import { PricingConfig } from "entities/UIModel";
import { PricingProduct } from "entities/UIModel/PricingProduct";
import React from "react";
import { gaps } from "theme/defaultStyle";
import { usePricingConfig } from "utils/context/PricingConfigContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { PricingConfigKey } from "utils/data/enum";
import theme from "theme/default";
import CoverageTypeField from "./CoverageTypeField";


type ContainerProps = {
  theme?: Theme;
  fieldQtyEven: boolean;
  singleElementCenter?: boolean
};

type CoverageTypeData = {
  Code: string;
  Description: string;
};

type BodyProps = {
  basePath: string;
  product: PricingProduct;
  showPolicyNumber: boolean;
  showFormType: boolean;
  showPolicyDate: boolean;
  showCoverageType: boolean;
  showLiabilityAmount: boolean;
  disabled?: boolean;
};

const InnerContainer = styled("div")<ContainerProps>(({ fieldQtyEven, singleElementCenter }) => ({
  alignItems: "stretch",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  justifyContent: "center",
  "& > *": {
    flexBasis: singleElementCenter ? "auto" : fieldQtyEven ? "33%" : "25%",
    flexGrow: 1,
    maxWidth: singleElementCenter ? "50%" : "100%",
  },
  "& > .checkbox-field": {
    minWidth: "unset",
    flexBasis: "100%",
    marginRight:"20px",
    "& .MuiIconButton-root": {
      marginLeft: "-9px", // Margin offset to allow checkbox to align, but also show hover effect.
    },
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    "& > *": {
      flexBasis: "auto",
      flexGrow: 1,
    },
    "& > .checkbox-field": {
      marginBottom: "-9px", // Margin offsets to allow checkbox to align, but also show hover effect.
      marginTop: "-18px",
    },
  },
}));

function OriginalPolicyBody({
  basePath,
  product,
  showPolicyNumber,
  showFormType,
  showPolicyDate,
  showCoverageType,
  showLiabilityAmount,
  disabled,
}: BodyProps) {
  const [{ pricingConfigs }] = usePricingConfig();
  const { getValues, setValue } = useFormWrapper();
  const showOPNPriorPolicyLast10Yrs = getValues("pricing.showOPNPriorPolicyLast10Yrs");

  const getCoverageTypeData = (
    coverageTypeData: string | undefined,
    coverageType: string | undefined
  ): SelectFieldOption[] => {
    return coverageTypeData
      ? JSON.parse(coverageTypeData).map(
        (item: CoverageTypeData, index: number) => ({
          value: item.Code,
          text: item.Description,
          selected: item.Code === coverageType || index === 0,
        })
      )
      : [];
  };

  const handleOnChange = (name: string, selectedValue: any) => {
    setValue(name, selectedValue ?? null);
  };

  const getLabel = (
    key: PricingConfigKey,
    failSafe: string = "Key Missing"
  ) => {
    const label = pricingConfigs?.find(
      (config: PricingConfig) => config.configKey === key
    )?.configValue;
    return label ? label : failSafe;
  };

  const fieldQtyEven = [
    // showOPNPriorPolicyLast10Yrs,
    showPolicyDate,
    showPolicyNumber,
    showCoverageType,
    showFormType,
    showLiabilityAmount,
  ].filter(Boolean).length % 2 === 0;

  const singleElementCenter = [
    showPolicyDate,
    showPolicyNumber,
    showCoverageType,
    showFormType,
    showLiabilityAmount,
  ].filter(Boolean).length === 1;
  
  return (
    <InnerContainer fieldQtyEven={fieldQtyEven} singleElementCenter={singleElementCenter}>
      {showOPNPriorPolicyLast10Yrs && (
        <div className="checkbox-field">
          <CheckboxInputField
            name={`${basePath}.previouslyIssued`}
            label="Previous loan within the last 10 years"
            disabled={disabled}
          />
        </div>
      )}
      {showPolicyDate && (
        <div>
          <DatePickerInputField
            label={getLabel(
              PricingConfigKey.OPNPolicyDateLabel,
              "Policy Date"
            )}
            name={`${basePath}.date`}
            disabled={disabled}
            isShrinkable
            validateOnBlur={false}
            maxDate={new Date()}
          />
        </div>
      )}
      {
        showPolicyNumber && (
          <div>
            <TextInputField
              label="Policy Number"
              name={`${basePath}.policyNumber`}
              disabled={disabled}
              inputProps={{ maxLength: 14 }}
            />
          </div>
        )
      }
      {
        showFormType && (
          <div>
            <SelectField
              label="Form Type"
              name={`${basePath}.jacketType`}
              onChange={(_: any, selectedValue: any) => {
                handleOnChange(
                  `${basePath}.jacketType`,
                  selectedValue
                );
              }}
              options={[
                {
                  text: "Loan",
                  value: "Loan",
                },
                {
                  text: "Owners",
                  value: "Owners",
                },
              ]}
              disabled={disabled}
              autosetValue={false}
            />
          </div>
        )
      }
      {showCoverageType && (
        <div>
          <CoverageTypeField
            label={getLabel(
              PricingConfigKey.OPNCoverageTypeLabel,
              "Coverage Type"
            )}
            name={`${basePath}.coverageType`}
            disabled={disabled}
            coverageTypeData={getCoverageTypeData(
              product.coverageTypeData,
              product.coverageType
            )}
          />
        </div>
      )}
      {showLiabilityAmount && (
        <div>
          <CurrencyInputField
            label={getLabel(
              PricingConfigKey.LiabilityLabel,
              "Liability Amount"
            )}
            name={`${basePath}.liabilityAmount`}
            disabled={disabled}
            allowNegative={false}
          />
        </div>
      )}
    </InnerContainer>
  );
}

export default OriginalPolicyBody;
