import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AttachMoney from "@mui/icons-material/AttachMoney";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  useMediaQuery,
} from "@mui/material";
import { getMenuIdByMenuName, menuNames } from "pages/home/menu";
import React, {
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import theme from "theme/default";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";

type Props = {
  onNavigate: (url: string) => void;
};

const linkButtonHeightMin = "50px";
const linkButtonHeightMax = "122px";

const StyledPopper = styled(Popper)(() => ({
  alignItems: "center",
  backgroundColor: "transparent",
  borderRadius: borderRadius.xlarge,
  color: colors.white,
  display: "flex",
  gap: gaps.xsmall2,
  minWidth: "160px",
  overflow: "inherit",
  padding: padding.zero,
  top: "15px !important",
  zIndex: zIndex.high1,
  "& .MuiPaper-root ": {
    borderRadius: borderRadius.xlarge,
    "&:before": {
      border: "solid transparent",
      borderBottomColor: colors.blue01,
      borderWidth: borderSize.xxxlarge,
      bottom: "100%",
      content: `""`,
      height: "0",
      left: "50%",
      marginLeft: `-${margin.large}`,
      pointerEvents: "none",
      position: "absolute",
      width: "0",
    },
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
      background: gradients.blueGradient03,
      gap: gaps.xsmall2,
      borderRadius: borderRadius.large,
      "& .MuiMenuItem-root": {
        color: colors.white,
        margin: "auto",
        paddingBottom: padding.large1,
        paddingLeft: padding.large1,
        paddingRight: padding.large1,
        paddingTop: padding.large1,
        width: "90%",
        // "&:hover": {
        //   background: gradients.blueGradient03,
        // },
      },
    },
  },
}));

const StyledNavButton = styled(Box)((props) => ({
  ".rectangle-active": {
    width: "103%",
    position: "relative",
    right: "6px",
  },
  alignItems: "center",
  // background: gradients.blueGradient03,
  display: "flex",
  flexDirection: "column",
  height: linkButtonHeightMin,
  justifyContent: "center",
  minWidth: linkButtonHeightMin,
  padding: padding.zero,
  width: linkButtonHeightMin,
  "& a": {
    borderRadius: borderRadius.small,
    color: colors.white,
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal1,
    lineHeight: 1,
    padding: padding.zero,
    textDecoration: "none",
    textTransform: "initial",
    "&:hover": {
      color: colors.blue12,
      textDecoration: "none",
    },
    "&.disabledLink": {
      color: colors.whiteTransparent01,
      cursor: "default",
      lineHeight: 1,
      "& .hover-content": {
        display: "none",
      },
      "&:hover": {
        background: gradients.blueGradient03,
      },
    },
    "&.disabledLink:hover ": {
      color: colors.whiteTransparent01,
      cursor: "default",
    },
    "&.disabledLink:hover .normal-content": {
      display: "none",
    },
    "&.disabledLink:hover .hover-content": {
      display: "flex",
    },
  },
  "& .disabledLink .icon": {
    color: colors.whiteTransparent01,
  },
  "& a .icon": {
    color: colors.white,
    fontSize: fontSize.xlarge2,
    "&:hover": {
      color: colors.blue12,
      textDecoration: "none",
    },
  },
  "& a div": {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      color: colors.white,
      fontSize: iconSize.medium,
    },
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.disabled": {
    cursor: "default",
    opacity: 1,
  },
  [theme.breakpoints.up("lg")]: {
    width: linkButtonHeightMax,
  },
}));

const PaymentsHeaderDropDown = ({ onNavigate }: Props) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);
  const {
    featureConfig: { enableReportPay },
  } = useConfigContext();

  const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
    setPopperAnchorEl(event?.currentTarget);
    setPopperOpen((previousOpen) => !previousOpen);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopperOpen(false);
    }
  };

  const handlePayments = () => {
    onNavigate("/reportpay");
    setPopperOpen(false);
  };

  const handlePendingPayments = () => {
    onNavigate("/pendingpay");
    setPopperOpen(false);
  };

  useEffect(() => {
    popperAnchorEl?.focus();
  }, [popperOpen]);

  const showButtonLabels = useMediaQuery(theme.breakpoints.up("lg"));

  if (!enableReportPay) {
    return <></>;
  }

  let className = "";
  const paths = ["/reportpay", "/pendingpay"];
  if (paths.includes(window.location.pathname)) {
    className = `${className} active`;
  }

  return (
    <>
      <StyledNavButton
        onClick={handlePopperClick}
        className={className}
      >
        <Link
          data-menu-id={menuNames.lblMenuPayments}
          onClick={(event) => event.preventDefault()}
          to="/"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <AttachMoney />
            {showButtonLabels && (
              <div style={{ display: "flex" }}>
                <span>Payments</span>
                <div>{!popperOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
              </div>
            )}
          </div>
          <Box className={className.includes("active") ? "rectangle-active" : "rectangle-none"}></Box>
        </Link>
      </StyledNavButton>
      <StyledPopper
        open={popperOpen}
        anchorEl={popperAnchorEl}
        role={undefined}
        transition
        disablePortal
      >
   {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={3} sx={{width :"100%"}}>
              <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                <MenuList
                  autoFocusItem={popperOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem data-menu-id={getMenuIdByMenuName(menuNames.lblMenuPaymentsReportPay)} onClick={handlePayments}>
                    {menuNames.lblMenuPaymentsReportPay} 
                  </MenuItem>
                  <MenuItem data-menu-id={getMenuIdByMenuName(menuNames.lblMenuPaymentsPendingPayments)} onClick={handlePendingPayments}>
                    {menuNames.lblMenuPaymentsPendingPayments}
                  </MenuItem>
                  <MenuItem
                    disabled={true}
                    hidden={true}
                  >
                    Save for Later
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
};

export default PaymentsHeaderDropDown;
