import React from "react";
import AdditionalPartiesAutocomplete from "../AdditionalPartiesAutocomplete";

type Props = {
  schema: string;
  disabled: boolean;
};

export default function AddressOne({ schema, disabled }: Props) {
  return (
    <AdditionalPartiesAutocomplete
      label="Address Line 1"
      name="addressOne"
      schema={schema}
      searchProp="address"
      disabled={disabled}
    />
  );
}
