import React from "react";
import StewartDropZone from "controls/global/stewart-drop-zone";
import Body from "../signature-dialog/SignatureDialogBody";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import { getImageTypeFromImageDataUrl } from "utils/signature/imageFile";

export default function UploadSignature() {
  const {
    state: { signature },
  } = useSignatureProvider();
  const { dispatch } = useSignatureDispatchProvider();

  const handleLoadCompleted = async (image: string) => {
    const imageType = getImageTypeFromImageDataUrl(image);
    dispatch({
      type: "FILE_UPDATE",
      signatureFile: image,
      imageType: imageType,
    });
  };

  const handleLoadCancel = () => {
    dispatch({ type: "FILE_UPDATE", signatureFile: "" });
  };

  return (
    <Body
      title="Upload a signature"
      width={300}
      showSignatureName
    >
      <StewartDropZone
        onLoadCompleted={handleLoadCompleted}
        onLoadCancel={handleLoadCancel}
        defaultImage={signature.file}
        height={110} //3/4" at 96 dpi
        formats={['JPG', 'JPEG', 'PNG']}
        limitFileSize={'1MB'}
      />
    </Body>
  );
}
