import { axiosSecuredInstance } from "configurations/axiosConfig";
import { AdditionalPartyName } from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { SearchPartyType } from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";

type State = {
  additionalParties: AdditionalPartyName[];
  isLoadingAdditionalParties: boolean;
};
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;
export type AdditionalPartySearch = {
  text?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
};

const setAdditionalParties =
  (additionalPartiesFromMiddleware: any) =>
    ({ setState }: StoreApi) => {
      if (
        !additionalPartiesFromMiddleware ||
        !additionalPartiesFromMiddleware["SAWI-Search"]["Results"]
      ) {
        setState({ additionalParties: [], isLoadingAdditionalParties: false });
        return [];
      }

      let additionalParties: Array<AdditionalPartyName> =
        new Array<AdditionalPartyName>();
      const additionalPartyResult = getAdditionalPartyResults(
        additionalPartiesFromMiddleware["SAWI-Search"]["@searchtype"],
        additionalPartiesFromMiddleware["SAWI-Search"]["Results"]
      );

      if (Array.isArray(additionalPartyResult)) {
        additionalParties = additionalPartyResult?.map((result: any) => {
          return buildAdditionalParty(result);
        });
      } else {
        additionalParties.push(buildAdditionalParty(additionalPartyResult));
      }

      setState({ additionalParties, isLoadingAdditionalParties: false });
    };

const getAdditionalPartyResults = (searchType: string, results: any) => {
  if (searchType === SearchPartyType.Attorney)
    return results.ApprovedAttorneys?.ApprovedAttorney ?? [];
  if (searchType === SearchPartyType.SettlementCompany)
    return results.SettlementCompanies?.SettlementCompany ?? [];
  if (searchType === SearchPartyType.SecondaryAgency)
    return results.SecondaryAgencies?.SecondaryAgency ?? [];
};

const buildAdditionalParty = (result: any): AdditionalPartyName => {
  return {
    DisplayName: result["@DisplayName"],
    ApprovedAttorneyName: result["@ApprovedAttorneyName"] ?? "",
    IndividualAttorneyName: result["@IndividualAttorneyName"] ?? "",
    AddressLine1: result["@AddressLine1"],
    AddressLine2: result["@AddressLine2"],
    City: result["@City"],
    State: result["@State"],
    Zip: result["@Zip"],
    Country: result["@Country"] ?? "",
    Name: result["@Name"] ?? "",
    IsFavorite: result["@IsFavorite"] ?? false,
    ID: uuidv4(),
    AltReferenceID: result["@AltReferenceID"] ?? "",
    LocationID: result["@LocationID"] ?? "",
    CompanyID: result["@CompanyID"] ?? "",
  };
};

const actions = {
  getAdditionalParties:
    (
      partyTypeCode: string,
      propertyState: string,
      {
        text = "",
        address = "",
        city = "",
        state = "",
        zip = "",
      }: AdditionalPartySearch
    ) =>
      async ({ dispatch, setState }: StoreApi) => {
        try {
          setState({ isLoadingAdditionalParties: true, additionalParties: [] });

          if (!text && !address && !city && !state && !zip) {
            setState({ isLoadingAdditionalParties: false });
            return;
          }

          const { data } = await axiosSecuredInstance.get(
            `StewartAccess/Search?searchtype=${partyTypeCode}&propertyState=${propertyState}&name=${text}&address=${address}&city=${city}&state=${state}&zip=${zip}`
          );

          dispatch(setAdditionalParties(data));
        } catch (error) {
          console.error(error);
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    additionalParties: [],
    isLoadingAdditionalParties: false,
  },
  actions,
  name: "additionalPartiesContext",
});

const hook = createHook(Store);
const useAdditionalPartiesContext = () => {
  return hook();
};
const useAdditionalPartiesActions = createHook(Store, {
  selector: null,
});
const useAdditionalPartiesFavorite = createHook(Store, {
  selector: (state: State) =>
    state.additionalParties.map((p) => ({
      id: p.ID,
      favorite: p.IsFavorite,
    })),
});

export {
  useAdditionalPartiesActions, useAdditionalPartiesContext, useAdditionalPartiesFavorite
};

