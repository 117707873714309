import { GeneralFileParty } from "entities/UIModel/GeneralFileParty";
import { PartyType } from "utils/data/enum";

const getAdditionalPartyDescription = (
  additionalParty: GeneralFileParty | undefined
) => {
  if (!additionalParty) return "";

  if (additionalParty.partyTypeCode === PartyType.Individual) {
    const firstName = additionalParty.partyFirstName
      ? additionalParty.partyFirstName
      : "";
    const middleName = additionalParty.partyMiddleName
      ? ` ${additionalParty.partyMiddleName}`
      : "";
    const lastName = additionalParty.partyLastName
      ? additionalParty.partyLastName
      : "";
    const suffix = additionalParty.suffixType?.name
      ? ` ${additionalParty.suffixType?.name}`
      : "";

    return `${firstName} ${middleName} ${lastName} ${suffix}`;
  }

  return ` ${additionalParty.companyName}`;
};

export { getAdditionalPartyDescription };
