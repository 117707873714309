import IosShareIcon from "@mui/icons-material/IosShare";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Typography,
  styled,
} from "@mui/material";
import AdvancedSearch from "controls/global/advanced-search/AdvancedSearch";
import ColumnsConfig, {
  MenuColumnItem,
} from "controls/global/columns-config/ColumnsConfig";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  colors,
  gaps,
  iconSize,
  margin,
  padding,
} from "theme/defaultStyle";
import { useReportPay } from "utils/context/ReportPayContext";
import {
  PERMISSIONS,
  PageType,
  SessionStorageKeys,
} from "utils/data/enum";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface Props {
  columns: MenuColumnItem<ReportPayFile>[];
  hiddenColumns: (keyof ReportPayFile)[];
  updateHiddenColumns?: (columns: (keyof ReportPayFile)[]) => void;
  handleAdvancedFilterChange: (
    name: keyof ReportAndPayCriteriaSearch,
    value: any,
    execSearch: boolean
  ) => void;
  handleAdvanceSearchPanelOpen: (open: boolean) => void;
  setOpenExportDialog: (open: boolean) => void;
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const StewartHeaderAdvance = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: margin.xlarge50,
}));

const ActionButtonGroup = styled(Box)({
  display: "flex",
  gap: gaps.large1,
  justifyContent: "flex-end",
  "& .MuiButton-root": {
    color: colors.blue01,
    display: "flex",
    gap: gaps.small1,
    paddingBottom: padding.xsmall1,
    paddingLeft: padding.xsmall2,
    paddingRight: padding.xsmall2,
    paddingTop: padding.xsmall1,
    textTransform: "none",
    "&.Mui-disabled": {
      background: colors.white,
      color: colors.blue12,
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      fontSize: iconSize.medium,
    },
    "& .rotate-90": {
      transform: "rotate(90deg)",
    },
  },
});

const ReportPayPageHeader = ({
  columns,
  hiddenColumns,
  updateHiddenColumns,
  handleAdvancedFilterChange,
  handleAdvanceSearchPanelOpen,
  setOpenExportDialog,
}: Props) => {

  const [advancedSearchOpen, setAdvancedSearchOpen] = useState<boolean>(false);
  const [{ onlyShowSelectedFiles }, { setColumnDefinition, setIsAdvSearchOpen }] = useReportPay();
  const advancedButtonRef: RefObject<HTMLButtonElement> = useRef(null);
  const storedCriteriaString = sessionStorage.getItem(SessionStorageKeys.REPORT_PAY_STORED_CRITERIA);
  const storedCriteria: ReportAndPayCriteriaSearch | null = storedCriteriaString
    ? JSON.parse(storedCriteriaString)
    : null;

  const SUB_HEADING_TEXT = `The files listed below contain at least one jacket and are ready to
be reported to Stewart. Once reported, the file will close and
products cannot be revised or voided (with the exception of adding
additional endorsements).`;

// Permission
const [{ profileSettings }] = useProfileSettingsCache();
const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const handleSearchPanel = (open: boolean) => {
    setAdvancedSearchOpen(open);
    handleAdvanceSearchPanelOpen(open);
  };

  const handleColumnChecked = (id: keyof ReportPayFile, checked: boolean) => {
    if (checked) {
      updateHiddenColumns?.([...hiddenColumns, id]);
    } else {
      updateHiddenColumns?.([...hiddenColumns].filter((col) => col !== id));
    }

    setColumnDefinition([id], "hidden", checked);
  };

  const handleHideAllColumns = () => {
    if (hiddenColumns.length === columns.length) return;

    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.(fieldIds);
    setColumnDefinition(fieldIds, "hidden", true);
  };

  const handleShowAllColumns = () => {
    if (hiddenColumns.length === 0) return;

    const fieldIds = columns.map((c) => c.field);
    updateHiddenColumns?.([]);
    setColumnDefinition(fieldIds, "hidden", false);
  };

  useEffect(() => {
    const usePriorSearchCriteriaAdvanceSearch = sessionStorage.getItem(
      SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH,
    );

    if (usePriorSearchCriteriaAdvanceSearch && advancedButtonRef.current) {
      setIsAdvSearchOpen(true);
      advancedButtonRef.current.click();
    }
  }, []);

  return (
    <Container>
      <StewartHeaderAdvance>
        <Typography className="subheading">{SUB_HEADING_TEXT}</Typography>
        <ActionButtonGroup>
          <Button
            onClick={() => handleSearchPanel(!advancedSearchOpen)}
            disabled={onlyShowSelectedFiles}
            data-test="ReportpayFilesAdvanced"
            ref={advancedButtonRef}
          >
            <>
              Advanced
              <SearchIcon />
            </>
          </Button>
          <ColumnsConfig
            options={columns}
            onChecked={handleColumnChecked}
            onHideAll={handleHideAllColumns}
            onShowAll={handleShowAllColumns}
          />
          <Button
            disabled={!permissionCodes?.includes(PERMISSIONS.EXPORT_DATA_PORTAL)}           
            onClick={() => setOpenExportDialog(true)}
            data-test="ReportpayFilesExport"
          >
            <>
              Export
              <IosShareIcon className="icon rotate-90" />
            </>
          </Button>
        </ActionButtonGroup>
      </StewartHeaderAdvance>
      <AdvancedSearch
        key={`adv_search_${advancedSearchOpen}`}
        open={advancedSearchOpen}
        handleFilterChange={advancedSearchOpen ? handleAdvancedFilterChange : () => {}}
        page={PageType.ReportPayPage}
        defaultValues={storedCriteria}
      />
    </Container>
  );
};

export default ReportPayPageHeader;
