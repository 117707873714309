import { styled } from "@mui/material";
import ComboBoxField from "controls/global/combo-box-field/ComboBoxInputField";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { uniqBy } from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { margin } from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";

const FormField = styled("div")({
  marginTop: margin.xlarge,
});

const FIELD_NAME = "agency";

const AgencyField = () => {
  const [availableAgencies, setAvailableAgencies] = useState<SelectFieldOption[]>([]);
  const [{ agencyLocationsByImage }] = useSignatureDefaults();
  const { setValue, getValues } = useFormContext();

  const handleAgencyNameChange = (item: any) => {
    setValue(FIELD_NAME, item.value);
  };

  const handleOnBlur = (eventArgs: any) => {
    const selectedValue = eventArgs?.target?.value;
    if(!selectedValue) return;

    const matchingAgency = availableAgencies?.find((a) => a.text === selectedValue);
    if(!matchingAgency) { 
      setValue(FIELD_NAME, "All");
    }else {
      setValue(FIELD_NAME, matchingAgency.value);
    }
  };

  useEffect(() => {
    let agencies: SelectFieldOption[] = uniqBy(agencyLocationsByImage, a => a.agencyID).map((l) => ({
      value: l.agencyID,
      text: l.agencyName
    }));

    const showAll = agencies.length > 1;
    if (showAll) {
      agencies = [
        { text: "All My Agencies", value: "All" },
        ...agencies
      ];
    }

    setAvailableAgencies(agencies);
  }, [agencyLocationsByImage]);

  useEffect(() => {
    const selectedAgency = getValues(FIELD_NAME);
    if (availableAgencies.length > 0 && availableAgencies.filter(l => l.value === selectedAgency).length === 0) {
      setValue(FIELD_NAME, availableAgencies[0].value);
    }
  }, [availableAgencies]);

  return (
    <FormField>
      <ComboBoxField
        label={"Agency Name"}
        options={availableAgencies}
        {...{ name: FIELD_NAME, disabled: availableAgencies.length < 1 }}
        onChange={(_: any, selectedValue: any) => {
          handleAgencyNameChange(selectedValue);
        }}
        roundedCorner={true}
        onBlur={handleOnBlur}
        freeSolo={true} 
        disableClearable={true}
      />
    </FormField>
  );
};

export default AgencyField;
