import DescriptionIcon from "@mui/icons-material/Description";
import { styled } from "@mui/material";
import PageSection from "controls/global/page-section";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import { FileSearchCriteria } from "entities/ApiModel/FileSearchCriteria";
import { IColumn } from "entities/ApiModel/IColumn";
import { FilesRecord } from "entities/UIModel/FilesRecord";
import { LayoutBodyContext } from "layout/LayoutBodyContext";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { gaps } from "theme/defaultStyle";
import { getColumnSearchField } from "utils/columnsGrid";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useMyFiles } from "utils/context/MyFilesContext";
import { useUser } from "utils/context/UserContext";
import useTitle from "utils/custom-hooks/useTitle";
import {
  AgencyStatus,
  DateTypeCode,
  FileStatusType,
  SessionStorageKeys,
} from "utils/data/enum";
import { Order } from "utils/sorting";
import FilesPageHeader from "./components/files-page-header";
import MyFilesGrid from "./components/my-files-grid/MyFilesGrid";

const InitialMessage = "Loading results...";
const TimeoutMessage = "Sorry, this search took too long. Please modify your search criteria and try again.";
const NoRecordsMessage =
  "Sorry, we couldn't find any files that you have access to. Once you or your agency starts creating files, you can view a summary of those files in Files page.";
const DefaultStatuses = [FileStatusType.Open, FileStatusType.ReportPending];
const AdvancedStatuses = [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending];

const SectionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const ListFilePage = () => {
  useTitle("Stewart Connect - My Files");

  const [
    {
      error: requestError,
      progressPercent: loadingProgressPercent,
    },
    {
      setRequestTimeoutMs,
      cancelRequests,
      getFileList,
      getAdvancedSearchFiles,
      getInitialColumnsDefintion,
      getColumnsDefinition,
      setColumnDefinition,
    }
  ] = useMyFiles();

  const [data, setData] = useState<FilesRecord[]>();
  const DefaultPage = 1;
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [filters, setFilters] = useState<FileSearchCriteria | undefined>(undefined);
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([InitialMessage]);
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);
  const [isAdvSearchOpen, setIsAdvSearchOpen] = useState<boolean>(false);
  const [defaultOpenedDate, setDefaultOpenedDate] = useState<Date>(new Date());
  const [statuses, setStatuses] = useState<FileStatusType[]>(DefaultStatuses);
  const [hiddenColumns, setHiddenColumns] = useState<(keyof FilesRecord)[]>(getInitialColumnsDefintion(true));
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [columns, setColumns] = useState<IColumn[]>(getInitialColumnsDefintion(false));
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof FilesRecord | undefined>("opened");
  const bodyRef = useContext(LayoutBodyContext);
  const { generalConfig } = useConfigContext();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();
  const globalAccessAgencyID = globalAccessAgency?.[0]?.CompanyID;
  const globalAccessAgencyName = globalAccessAgency?.[0]?.CompanyName;
  const storedCriteriaString = sessionStorage.getItem(SessionStorageKeys.LIST_FILE_STORED_CRITERIA);
  const storedCriteria = storedCriteriaString ? JSON.parse(storedCriteriaString) : null;
  const usePriorSearchCriteria = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
  const usePriorSearchCriteriaAdvanceSearch = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
  const defaultValues =
    (storedCriteria && usePriorSearchCriteria) || (storedCriteria && usePriorSearchCriteriaAdvanceSearch)
      ? storedCriteria
      : null;
  const defaultRequestedPage = defaultValues ? storedCriteria["requestedPage"] : DefaultPage;
  const [page, setPage] = useState<number>(defaultRequestedPage);
  const companyStatus = [AgencyStatus.Active, AgencyStatus.Inactive]
 
  const [,
    {
      getRowsPerPageSetting,
      setRowsPerPageSetting,
    }
  ] = useUser();

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs)
    , [userInputDebounceTimeMs]
  );

  // TODO: When merging main in with react18/newuistyles, consolidate the column logic
  //      as these values are hard-coded in different places and may have been updated there already.
  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string; } = {
      fileSearchId: "FileSearchID",
      fileNumber: "ClientFileID",
      agencyId: "AgencyID",
      fileId: "FileID",
      propertyAddress: "PropertyAddress",
      buyerBorrower: "BuyerNames",
      seller: "SellerNames",
      lender: "LenderNames",
      status: "FileStatusTypeCode",
      opened: "OpenDate",
      openedTimestamp: "OpenDate",
      dateRangeStart: "OpenDate",
      productNames: "ProductNames",
      agencyID: "AgencyID",
      locationDisplayName: "LocationDisplayName",
      agencyName: "AgencyName",
      stateAbbr: "StateAbbr",
      propertyTypeCode: "PropertyTypeCode",
      transactionTypeCode: "TransactionTypeCode",
      rowsPerPage: "RowsPerPage",
      currentRow: "CurrentRow",
      currentPage: "CurrentPage",
      totalPages: "TotalPages",
      totalRows: "TotalRows",
    };
    return mappedColumnNames[name] ?? name;
  };

  const getSearchField = (field: keyof FilesRecord) =>
    getColumnSearchField<FilesRecord, FileSearchCriteria>(columns, field);

  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const filterDays = generalConfig.filesSearchDefaultLookupDays;
    const timeoutMs = generalConfig.filesSearchTimeoutMs;

    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }
    if (filterDays) {
      setDefaultOpenedDate(new Date(new Date().getTime() - (filterDays * 24 * 60 * 60 * 1000)));
    }
    if (timeoutMs) {
      setRequestTimeoutMs(timeoutMs);
    }
  };

  const getDefaultFilters = (advanced: boolean): FileSearchCriteria => {
    const statuses = advanced ? AdvancedStatuses : DefaultStatuses;
    const dateType = advanced ? DateTypeCode.Created : DateTypeCode.Modified;
    const startDate = advanced ? undefined : defaultOpenedDate;
    const sortColumn = advanced ? undefined : "-FileChangeDate";

    const defaultFilters: FileSearchCriteria = {
      showInActive: 1,
      fileStatusTypeCode: statuses.join("|"),
      dateTypeCode: dateType,
      listCount: rowsPerPage,
      requestedPage: 1,
      dateRangeStart: startDate,
      sortColumn: sortColumn,
    };
    return defaultFilters;
  };

  const removeInvalidProperties = (object: { [key: string]: any; }) => {
    let deleteCount = 0;
    Object.keys(object).forEach((key) => {

      // Remove null properties and fields that are hidden,
      // with the exception of dateRangeStart which can always be searched even if hidden.
      if (object[key] === null ||
        object[key] === undefined ||
        (!isAdvSearchOpen
          && key !== "dateRangeStart"
          && hiddenColumns.find(c => c === key || getSearchField(c) === key)
        )
      ) {
        delete object[key];
        deleteCount++;
      }
    });

    return { object, deleteCount };
  };

  const resetLimitStartDate = (object: {[key: string]: any; }) => {
    const defaultFields: string[] = [
      "showInActive",
      "listCount",
      "requestedPage",
      "sortColumn",
      "fileStatusTypeCode",
      "dateTypeCode",
      "dateRangeStart",
    ];

    let hasAnyNonDefaultCriteria:Boolean = false;
    Object.keys(object).forEach((key) => {
      // When it has a value
      if (  object[key] && 
            !defaultFields.includes(key) 
        ) {
          hasAnyNonDefaultCriteria = true;
      }
    });

    // Check if dateTypeCode is different from default, and dateRangeStart has a value
    if (!hasAnyNonDefaultCriteria) {
      if (
        object["dateTypeCode"] !== DateTypeCode.Modified &&
        object["dateRangeStart"]
      ) {
        hasAnyNonDefaultCriteria = true;
      }
    }

    // Check if fileStatusTypeCode is different from detault
    if (!hasAnyNonDefaultCriteria) {
      if (
        object["fileStatusTypeCode"] !== DefaultStatuses.join('|')
      ) {
        hasAnyNonDefaultCriteria = true;
      }
    }

    if (hasAnyNonDefaultCriteria) {
      // Remove default dateTypeCode and dateRangeStart
      if (object["dateTypeCode"] === DateTypeCode.Modified) {
        object["dateTypeCode"] = undefined;
        object["dateRangeStart"] = undefined;
      }

      // Remove dateTypeCode when there is no dateRangeStart 
      if (!object["dateRangeStart"]) {
        object["dateTypeCode"] = undefined;
      }

    }
    else {
      object["dateTypeCode"] = DateTypeCode.Modified;
      object["dateRangeStart"] = defaultOpenedDate;
    }

    return object; 
  };


  const handleFilterChange = async (name: keyof FileSearchCriteria, value: any) => {
    await cancelRequests();

    const formattedValue = (typeof value) === "string" ? value.trim().replaceAll(",", "|") : value;
    const updatedFilters = (filters: FileSearchCriteria | undefined) => {
      let dateType;
      if (name === "dateTypeCode") {
        dateType = value;
      }
      else if (!isAdvSearchOpen && name === "dateRangeStart") {
        handleRequestSort("openedTimestamp", true);
        dateType = DateTypeCode.Created;
      } else {
        dateType = filters?.dateTypeCode;
      }
      let adjustedFilters: FileSearchCriteria = {
        ...filters,
        [name]: formattedValue || undefined,
        dateTypeCode: dateType,
      };

      // // If user performs any search that isn't date, we clear the default date filter.
      // if (name !== "dateRangeStart" && adjustedFilters.dateRangeStart === defaultOpenedDate) {
      //   adjustedFilters.dateRangeStart = undefined;
      // }
      adjustedFilters = removeInvalidProperties(adjustedFilters).object;
      return adjustedFilters;
    };

    setPage(defaultRequestedPage ? defaultRequestedPage : DefaultPage);
    setFiltersApplied(true);
    setFilters(updatedFilters);
  };

  const handleAdvancedFilterChange = (
    name: keyof FileSearchCriteria,
    value: any,
    shouldSearch: boolean
  ) => {
    if (isAdvSearchOpen) {
      setPartialFilterUpdate(!shouldSearch);
      handleFilterChange(name, value);
    }
  };

  const handleAdvanceSearchPanelOpen = async (open: boolean) => {
    await cancelRequests();

    const resetFilters = (filters: FileSearchCriteria | undefined) => {
      return getDefaultFilters(open);
    };

    setFiltersApplied(false);
    setFilters(resetFilters);
    setSortColumn(undefined);
    setPartialFilterUpdate(false);
    if (!usePriorSearchCriteriaAdvanceSearch) {
      setPage(1);
    }
    setIsAdvSearchOpen(open);

    if (open) {
      setStatuses(AdvancedStatuses);
    }
    else {
      setStatuses(DefaultStatuses);
    }
  };

  const handlePageChange = async (page: number) => {
    await cancelRequests();

    setPage(page);
  };

  const handleRowsPerPageChange = async (count: number) => {
    await cancelRequests();

    setRowsPerPageSetting(count);
    setRowsPerPage(count);
    setPartialFilterUpdate(false);
    setPage(1);
  };

  const handleRequestSort = async (columnName: string, disableToggling: boolean = false) => {
    await cancelRequests();

    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof FilesRecord);
    setSortColumn(sortBy);
  };

  const handleColumnResize = (column: keyof FilesRecord, newWidth: number) => {
    if (column && newWidth > 0) {
      setColumnDefinition([column], "width", Math.floor(newWidth));
    }
  };

  const canSearch = (): boolean => {
    return settingsLoaded &&
      !partialFilterUpdate &&
      columns?.length > 0 &&
      (filters !== undefined && Object.values(filters).length > 0);
  };

  const getFileSearchCriteria = () => {
    let criteria: FileSearchCriteria;

    if (defaultValues) {
      sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
      sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
      criteria = storedCriteria;
      setFilters(storedCriteria);
    } else {
      criteria = {
        ...filters,
        listCount: rowsPerPage,
        requestedPage: page,
        ...(globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
      };
      if (sortColumn) {
        criteria = {
          ...criteria,
          sortColumn: sortColumn,
        };
      }
    }
    if (readyToSearch) {
      sessionStorage.setItem(SessionStorageKeys.LIST_FILE_STORED_CRITERIA, JSON.stringify(criteria));
    }
    return criteria;
  };

  const getNoRecordsMessage = () => {
    if (!filters) {
      return [NoRecordsMessage];
    }
    let firstMessage = "No Matches Found";
    const secondMessage = "Please adjust your search criteria and search again.";
    if (isAdvSearchOpen) {
      let keyword = (filters as FileSearchCriteria).keyword;
      let searchFields: string[] = [];
      keyword && searchFields.push(keyword);
      globalAccessAgencyName && searchFields.push(globalAccessAgencyName);
      if (searchFields) {
        firstMessage = `No matches found for "${searchFields.join(", ")}"`;
      }
    }
    else {
      const keys: (keyof FileSearchCriteria)[] = [
        "clientFileID",
        "propertyAddress",
        "buyerName",
        "agencyID",
        "locationDisplayName",
        "agencyName",
        "sellerName",
        "lenderName",
        "productName",
        "stateAbbr",
        "propertyTypeCode",
        "transactionTypeCode",
      ];
      let providedFields = keys
        .map(key => filters[key])
        .filter(value => !!value && value !== globalAccessAgencyID);

      if (!filters.agencyID && globalAccessAgencyName && !providedFields.includes(globalAccessAgencyName)) {
        providedFields.push(globalAccessAgencyName);
      }

      if (providedFields.length) {
        firstMessage = `No matches found for "${providedFields.join(", ")}"`;
      }
    }
    return [firstMessage, secondMessage];
  };

  const performSearch = async () => {
    let criteria = getFileSearchCriteria();
    // Clear default dateTypeCode and dateTypeRange if any criteria has been entered 
    if (!isAdvSearchOpen) {
      criteria = resetLimitStartDate({...criteria});
    }    
    
    let files: FilesRecord[] | undefined = [];
    const searchFunction = isAdvSearchOpen ? getAdvancedSearchFiles : getFileList;

    files = await searchFunction({ ...criteria });

    if (files !== undefined) {
      setData(files);
      if (!files?.length) {
        setNoRecordsMessage(getNoRecordsMessage());
        setPage(1);
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      await loadSettings();
      setSettingsLoaded(true);
    };

    init();
  }, []);

  useEffect(() => {
    const defaultFilters = getDefaultFilters(false);
    setFilters(defaultFilters);

  }, [defaultOpenedDate]);

  useEffect(() => {
    let sortColumnChanged = false;
    if (sortColumn) {
      const sortColumnName = sortColumn.replace("-", "");
      hiddenColumns.forEach((c) => {
        const mappedName = getDatabaseSortColumnName(c);
        if (mappedName === sortColumnName) {
          setSortColumn(undefined);
          sortColumnChanged = true;
          return;
        }
      });
    }

    const updatedFilters = removeInvalidProperties({ ...filters });
    if (sortColumnChanged || updatedFilters.deleteCount || data === undefined) {
      setFilters((filters: FileSearchCriteria | undefined) => updatedFilters.object);
    }
  }, [hiddenColumns]);

  useEffect(() => {
    const getColumns = async () => {
      const { gridColumns, gridHiddenColumns } = await getColumnsDefinition();
      setColumns(gridColumns);
      setHiddenColumns(gridHiddenColumns);
    };

    getColumns();
  }, [getColumnsDefinition]);

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [filters, page, sortColumn, filtersApplied, settingsLoaded, columns, rowsPerPage, partialFilterUpdate, globalAccessAgencyName,]);

  useEffect(() => {
    bodyRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [rowsPerPage]);

  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };

    getCount();
  }, [getRowsPerPageSetting]);

  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  useEffect(() => {
    if (requestError === "TIMEOUT") {
      setNoRecordsMessage([TimeoutMessage]);
      setData([]);
    }
  }, [requestError]);

  const records = data ?? [];

  const visibleColumns = columns
    .filter((c) => c.hideable)
    .map((c) => ({
      field: c.field as keyof FilesRecord,
      name: c.name,
      checked: !hiddenColumns.includes(c.field as keyof FilesRecord),
    }));

  return (
    <>
      <PageSection
        icon={<DescriptionIcon />}
        title="Files"
        contentAlignment="beside-header"
      >
        <SectionContainer>
          <FilesPageHeader
            columns={visibleColumns}
            hiddenColumns={hiddenColumns}
            updateHiddenColumns={(columns) => setHiddenColumns(columns)}
            handleAdvancedFilterChange={handleAdvancedFilterChange}
            handleAdvanceSearchPanelOpen={handleAdvanceSearchPanelOpen}
            storedCriteria={storedCriteria}
          />
          <MyFilesGrid
            colsConfig={columns}
            rows={records}
            rowsPerPage={rowsPerPage}
            loadingProgressPercent={loadingProgressPercent}
            noRowsMessage={noRecordsMessage}
            fileStatusTypes={statuses}
            page={page}
            hiddenColumns={hiddenColumns}            
            isAdvSearchOpen={isAdvSearchOpen}
            onFiltersChange={handleFilterChange}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onColumnResize={handleColumnResize}
            defaultValues={storedCriteria}
            companyStatus={companyStatus}
          />
        </SectionContainer>
      </PageSection>
      <ScrollToTopArrow refreshSize={records} />
    </>
  );
};

export default ListFilePage;
