import { TranscodeCriteria } from "entities/UIModel/TranscodeCriteria";
import { TransCodeData } from "entities/ApiModel/TransCodeData";
import { JacketFormType } from "utils/data/enum";
import { ProductType } from "utils/data/enum";

import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";

import TransCode from "entities/UIModel/company/TransCode";

type State = {};
type Actions = typeof actions;

const actions = {
  getTransCodes:
    (formType: JacketFormType | string, stateCode: string, type: ProductType) =>
      async () => {
        try {
          const { data } = await axiosSecuredInstance.get<TransCode[]>(
            `/Company/getTransCodes?JacketFormType=${formType}&State=${stateCode}&TransCodeType=${type}`
          );

          return data;
        } catch (error) {
          console.error(error);
        }
      },
      getEndorsementTransCodes:
    (criteria: TranscodeCriteria) =>
      async () => {
        try {
          const { data } = await axiosSecuredInstance.post<[TransCodeData]>(
            `/Order/DefaultTransCode`,
            { ...criteria  }
          );
          return data;
        } catch (error) {
          console.error(error);
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {},
  actions,
  name: "transCodeContenxt",
});

const hook = createHook(Store);
export const useTransCodeContext = () => { return hook(); };
