import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { ProductType } from "utils/data/enum";

type Props = {
  voidReasons: SelectFieldOption[];
  productIndex: number;
  productType: string;
};

const getName = (
  productIndex: Props["productIndex"],
  productType: Props["productType"]
) =>
  productType === ProductType.Cpl
    ? `cpls[${productIndex}].void.reason`
    : productType === ProductType.Aal
      ? `aALProducts[${productIndex}].void.reason`
      : `jackets[${productIndex}].void.reason`;

const VoidReasonsField = ({
  voidReasons,
  productIndex,
  productType,
}: Props) => {
  const { setValue, getValues } = useFormWrapper();

  const handleOnChange = (event: React.ChangeEvent<{}>, option: any) => {
    setValue(getName(productIndex, productType), option);
  };

  React.useEffect(() => {
    const name = getName(productIndex, productType);
    const selectedVoidReason = getValues(name);
    if (selectedVoidReason) return;
    if (voidReasons && voidReasons.length > 0) {
      setValue(name, voidReasons[0]["text"]);
    }
  }, [voidReasons, productIndex, productType]);

  return (
    <SelectField
      label="Void Reason"
      name={getName(productIndex, productType)}
      options={voidReasons}
      onChange={handleOnChange}
      autosetValue={false}
    />
  );
};

export default VoidReasonsField;
