import {
  AAL,
  CPL,
  filePartyDTO,
  GeneralFileParty,
} from "entities/UIModel";
import ProductOption from "entities/UIModel/company/ProductOption";
import { Product } from "utils/context/StewartAccessContext";
import {
  OrderStatusType,
  OrderStatusTypeName,
  PartyRoleType,
  PartyType,
  ProductType,
} from "utils/data/enum";
import { formatDate } from "utils/shared";
import { ensure } from "utils/validations";

export const getDisplayNameForLender = (party: filePartyDTO | GeneralFileParty) => {
  return party.companyName || '';
};

export const getDisplayNameForBuyerBorrower = (party: filePartyDTO) => {
  const displayNameForBuyerBorrower = party.partyTypeCode === PartyType.Individual
    ? `${getName(party.partyFirstName)}${getName(party.partyMiddleName)}${getName(party.partyLastName)}${party.suffixType?.code ? party.suffixType?.name.trim() : ""}`
    : party.companyName;

  return displayNameForBuyerBorrower ? displayNameForBuyerBorrower?.trimEnd() : "";
};

export const getName = (name?: string) => name ? name + " " : "";

export const getDisplayName = (party: filePartyDTO) =>
  party.partyRoleTypeCode === PartyRoleType.BuyerBorrower ||
    party.partyRoleTypeCode === PartyRoleType.Seller
    ? getDisplayNameForBuyerBorrower(party)
    : getDisplayNameForLender(party);

export const getCurrentProduct = (
  productType: string,
  productIndex: number,
  aALProducts?: AAL[],
  cpls?: CPL[]
) =>
  productType === ProductType.Aal
    ? aALProducts![productIndex]
    : cpls![productIndex];

export const getVoidReasons = (products: Array<Product>, productType: string) =>
  products.length > 0
    ? ensure(
      products.find(
        (product) =>
          product["@Type"].toUpperCase() === productType.toUpperCase()
      )
    ).VoidReasons.VoidReason
    : [];

export const getCoveredParties = (
  products: Array<Product>,
  productType: string
) => {

  if (products.length > 0) {
    const foundProduct = products.find((party) => party["@Type"] === productType);
    if (foundProduct) {
      if (Array.isArray(foundProduct.CoveredParties.CoveredParty)) {
        return foundProduct.CoveredParties.CoveredParty;
      }
      else {
        return [foundProduct.CoveredParties.CoveredParty];
      }
    }
  }
  else {
    return [];
  }

};

export const getDefaultCoveredParty = (defaultProduct: Product) => {
  if (Array.isArray(defaultProduct.CoveredParties.CoveredParty)) {
    return defaultProduct.CoveredParties.CoveredParty[0];
  }
  else {
    return defaultProduct.CoveredParties.CoveredParty;
  }
};

export const getIfIsPrintAttorneyOnly = (
  productOptions: ProductOption[],
  productType: string
): boolean => {
  const allowedProductOption = productOptions?.find(
    (po) =>
      po.product.toUpperCase() === productType.toUpperCase() &&
      (po.printAttorneyOnly === "IsAllowed" ||
        po.printAttorneyOnly === "IsRequired")
  );

  if (allowedProductOption) return true;
  else return false;
};

export const getIfIsAttorneySelected = (
  addresseeNames: Array<string>,
  additionalParties?: Array<filePartyDTO>
): boolean => {
  let isSelected = false;
  addresseeNames &&
    addresseeNames.length > 0 &&
    addresseeNames?.forEach((filePartyKey) => {
      let party = additionalParties?.find(
        (p) =>
          p.filePartyKey === filePartyKey &&
          p.partyTypeCode === PartyType.Attorneys
      );
      if (party) {
        isSelected = true;
      }
    });
  return isSelected;
};

export const getTabDescription = (
  coveredParty: string,
  orderStatusTypeCode: string = "",
  productIssueDate: string,
  currentProductVoidDate: any,
  productOrderStatusTypeName: string
) => {
  if (coveredParty) {
    let statusDate =
      orderStatusTypeCode === OrderStatusType.Voided
        ? formatDate(new Date(String(currentProductVoidDate)))
        : productIssueDate;
    let orderStatusTypeName = productOrderStatusTypeName
      ? productOrderStatusTypeName
      : OrderStatusTypeName.Pending;
    if (orderStatusTypeCode === OrderStatusType.Pending) {
      return ` ${coveredParty} - ${orderStatusTypeName}`;
    }
    return ` ${coveredParty} - ${orderStatusTypeName} ${statusDate}`;
  }
};
