import AgencyIdField from "./AgencyIdField";
import ComboBoxInputField from "controls/global/combo-box-field/ComboBoxInputField";
import { Grid } from "@mui/material";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect,
  useState,
} from "react";
interface AgencyFormFieldProps {
  agencies: SelectFieldOption[];
  methods: any;
  currentAgencyId: string;
  isOpen: boolean;
  name: string;
}

export default function AgencyFormField({ agencies, methods, currentAgencyId, isOpen, name }: AgencyFormFieldProps) {
  const { setValue } = methods;
  const [userSelectedAgency, setUserSelectedAgency] = useState<any>(null);
  // const agencyField = getValues("agency");

  const handleAgencyChange = (_: React.ChangeEvent<{}>, value: any) => {
    setUserSelectedAgency(value);
    if (value?.legacyID) {
      setValue("agencyID", value.legacyID);
    } else {
      setValue("agencyID", "");
    }
  };

  const handleAgencyBlur = (e: any) => {
    setValue("agencyName", e.target.value);
  };

  useEffect(() => {
    if (agencies.length === 1) {
      const singleAgency = agencies[0];
      setUserSelectedAgency(singleAgency);
      setValue("agency", singleAgency.text);
      setValue("agencyID", singleAgency.legacyID);
      setValue("agencyCode", singleAgency.value);
      setValue("agencyName", singleAgency.text);
    }
  }, [agencies, setValue]);

  useEffect(() => {
    if (userSelectedAgency?.legacyID) {
      setValue("agencyCode", userSelectedAgency.value);
      setValue("agencyName", userSelectedAgency.text);
    }
  }, [userSelectedAgency, setValue]);

  return (
    <>
      <Grid
        item
        xs={7}
      >
        <ComboBoxInputField
          options={agencies}
          name="agency"
          label="Agency Name"
          onChange={handleAgencyChange}
          onBlur={handleAgencyBlur}
          placeholder="Agency Name"
          freeSolo
          defaultValue={userSelectedAgency}
        />
      </Grid>
      <Grid
        item
        xs={3}
      >
        <AgencyIdField
          selectedAgency={userSelectedAgency}
          setValue={setValue}
        />
      </Grid>
    </>
  );
}
