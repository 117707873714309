import {
  styled,
  TableCell,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { PricingProductItem } from "entities/UIModel";
import { debounce } from "lodash";
import { usePricingErrorMessageContext } from "pages/file/PricingErrorMessageProvider";
import { isPricingItemUpdateable } from "pages/file/utils/helper";
import React, {
  useCallback,
} from "react";
import {
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { PricingColumnName, ProductType } from "utils/data/enum";
import { convertToNumber } from "utils/shared";
import PricingProductNumberCell from "./PricingProductNumberCell";
import TransCodeCell from "./TransCodeCell";
import CustomTooltip from "controls/global/custom-tooltip";

const StyledTableBodyCell = styled(TableCell)({
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: padding.zero,
  "&:first-child": {
    paddingLeft: padding.zero,
  },
  "& input": {
    width: "6em",
  },
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledTableBodyCellNotUpdateable = styled(TableCell)({
  borderBottom: "none",
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: `${padding.small} 0`,
  paddingLeft: padding.large1,
  paddingRight: padding.large1,
  textOverflow: "ellipsis",
});

const ProductTooltipCell = styled(TooltipCell)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  paddingRight: padding.zero,
  "& .MuiSvgIcon-root": {
    fontSize: fontSize.xxsmall,
    marginRight: padding.medium1,
  },
});

type Props = {
  index: number;
  parentIndex: number;
  item: PricingProductItem;
  isIntegratedPricing: boolean;
  isOverride: boolean;
  isReadOnly: boolean;
  hasErrorMessage: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayTransCodeColumn: boolean;
  displayReissueColumn: boolean;
  displayRiskRateColumn: boolean;
  handleActualFeeItemBlur: (e: any, index: number, itemIndex: number) => Promise<void>;
  handleRiskRateItemBlur: (e: any, index: number, itemIndex: number) => void;
  handleAgentRetentionItemBlur: (e: any, index: number, itemIndex: number) => void;
  handleTotalDueItemBlur: (e: any, index: number, itemIndex: number) => void;
  setIsPricingSectionUpdated: (isUpdated: boolean) => void;
  productType?: string;
};

export default function PricingProductItems({
  index,
  parentIndex,
  item,
  isIntegratedPricing,
  isOverride,
  isReadOnly,
  hasErrorMessage,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  handleActualFeeItemBlur,
  handleRiskRateItemBlur,
  handleAgentRetentionItemBlur,
  handleTotalDueItemBlur,
  productType
}: Props) {
  const { setValue, getValues } = useFormWrapper();
  const { state: { items, codes, displayFieldIcon }, dispatch: pricingErrorMessageDispatch, } = usePricingErrorMessageContext();

  const fieldName = `pricingProducts.${parentIndex}.productItems.${index}`;
  const errorInfo = items.get(item.productItemId);
  const isForceEnabled = !isReadOnly && (errorInfo?.enableField || false);
  const currentItem = getValues(fieldName);
  const isItemUpdateable: boolean = isPricingItemUpdateable(item) && (!isIntegratedPricing || productType === ProductType.StandaloneEndorsement ? true : isOverride) && !isReadOnly;  
  const isItemDisabled = (pricingColumnName: string) => {
    if (isReadOnly || (isIntegratedPricing && !(isOverride || productType === ProductType.StandaloneEndorsement))) return true;
    if (pricingColumnName === PricingColumnName.ActualFee || (displayRiskRateColumn && pricingColumnName === PricingColumnName.RiskRate))
      return false;
    return (!displayRiskRateColumn && convertToNumber(currentItem?.actualFee) !== 0) || (displayRiskRateColumn && convertToNumber(currentItem?.actualRiskRate) !== 0) ? false : true;
  };

  const onUpdateHandle = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!errorInfo?.enableField) return;
    const errorCodes:string[] = ["001","003","005","006"];
    if (errorCodes.includes(errorInfo?.code)) {
      setValue(`${fieldName}.lastErrorMessage`, "");
      setValue(`${fieldName}.isManualFee`, 1);
    }

    if (errorInfo?.code === "004") {
      const updatedMessage = `004 - ${codes.get("004")} - Orange`;
      setValue(`${fieldName}.lastErrorMessage`, updatedMessage);
    }

    pricingErrorMessageDispatch({
      type: "UPDATE",
      item: {
        id: item.productItemId,
        value: convertToNumber(e.target.value),
        code: errorInfo?.code,
      },
    });
  };

  const debouncedChangeHandler = useCallback(
    debounce((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (isForceEnabled && !isNaN(Number(e.target.value))) {
        pricingErrorMessageDispatch({
          type: "CHANGE",
          item: {
            id: item.productItemId,
            value: Number(e.target.value),
            code: errorInfo?.code,
          },
        });
        return;
      }
      pricingErrorMessageDispatch({ type: "CHANGE" });
    }, 500), [errorInfo?.enableField]);

  return (
    <>
      <StyledTableBodyCell align="left">
        <ProductTooltipCell title={item.product}>
          <FiberManualRecordIcon />
          {item.product}
        </ProductTooltipCell>
      </StyledTableBodyCell>
      {displayFieldIcon && (
        <StyledTableBodyCell>
          {errorInfo?.displayFieldIcon && !isOverride && !hasErrorMessage && !isReadOnly && (
            <CustomTooltip
              title={errorInfo?.toolTipMessage}
              disableHoverListener={!errorInfo?.showToolTip}
              placement="top"
              aria-label="lender-contact-info"
              arrow
            >
              <ReportProblemIcon style={{ color: errorInfo?.fieldIconColor }} />
            </CustomTooltip>
          )}
        </StyledTableBodyCell>
      )}
      {displayLiabilityColumn && (
        <StyledTableBodyCellNotUpdateable />
      )}
      {displayRateTypeColumn && (
        <StyledTableBodyCell align="center"></StyledTableBodyCell>
      )}
      {displayReissueColumn && (
        <StyledTableBodyCell align="center"></StyledTableBodyCell>
      )}
      {displayTransCodeColumn && (
        <StyledTableBodyCell align="center">
          <TransCodeCell product={item} />
        </StyledTableBodyCell>
      )}
      <PricingProductNumberCell
        errorInfo={errorInfo}
        hasErrorMessage={hasErrorMessage}
        isOverride={isOverride}
        value={item.actualFee}
        name={`${fieldName}.actualFee`}
        isDisabled={
          isItemDisabled(PricingColumnName.ActualFee) && !isForceEnabled
        }
        isUpdatable={isItemUpdateable || isForceEnabled}
        onChange={debouncedChangeHandler}
        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          onUpdateHandle(e);
          handleActualFeeItemBlur(e, parentIndex, index);
        }}
      />
      {displayRiskRateColumn && (
        <PricingProductNumberCell
          errorInfo={errorInfo}
          hasErrorMessage={hasErrorMessage}
          isOverride={isOverride}
          value={item.actualRiskRate || 0}
          name={`${fieldName}.actualRiskRate`}
          isDisabled={
            isItemDisabled(PricingColumnName.RiskRate) && !isForceEnabled
          }
          isUpdatable={isItemUpdateable || isForceEnabled}
          onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onUpdateHandle(e);
            handleRiskRateItemBlur(e, parentIndex, index);
          }}
        />
      )}
      <PricingProductNumberCell
        errorInfo={errorInfo}
        value={item.agentRetention}
        isOverride={isOverride}
        name={`${fieldName}.agentRetention`}
        isDisabled={isItemDisabled(PricingColumnName.AgentRetention)}
        isUpdatable={isItemUpdateable}
        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          handleAgentRetentionItemBlur(e, parentIndex, index);
        }}
      />
      <PricingProductNumberCell
        errorInfo={errorInfo}
        value={item.totalDue}
        isOverride={isOverride}
        name={`${fieldName}.totalDue`}
        isDisabled={isItemDisabled(PricingColumnName.TotalDue)}
        isUpdatable={isItemUpdateable}
        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          handleTotalDueItemBlur(e, parentIndex, index);
        }}
      />
    </>
  );
}
