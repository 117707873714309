import React, {
  useEffect,
  useState,
} from "react";
import {
  Box,
  Link,
  styled,
  Typography,
} from "@mui/material";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "../dialogs/stewart-dialog/StewartDialog";
import TextInputField from "../text-input-field/TextInputField";
import ActionButton from "controls/global/action-button";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CheckLightIcon from "theme/icons/CheckLightIcon";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import SubmitTicketSuccessDialog from "./SubmitTicketSuccessDialog";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import Loading from "controls/global/loading";
import StewartMaskInput from "../text-mask-input";
import DialogTitleCloseButton from "../dialog-close-btn/DialogTitleCloseButton";

type Props = {
  confirmationMessage?: string | React.ReactNode;
  isOpen: boolean;
  onConfirm: any;
  onCancel: any;
};

type Sender = {
  Name: string;
  Lastname?: string;
  Address: string;
  UserTypeCode?: string;
  ContactNumber?: string;
};

type TicketDataDto = {
  Recipients: Sender[];
  Sender: Sender;
  EmailSubject: string;
  EmailBody: string;
  AttachmentName: string | null;
  Attachment: any;
};

type SupportContanctInfoType = {
  SupportPhone: string;
  SupportEmail: string;
};

const TicketStewartDialog = styled(StewartDialog)({
  "& .dialogSupportTitle": {
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold1,
    padding: `${padding.xsmall1} 0`,
    textAlign: "center",
  },
  "& .dialogPhoneNumber": {
    color: colors.grey11,
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold1,
    margin: margin.zero,
    padding: `${padding.xsmall1} 0`,
    textAlign: "center",
  },
  "& .dialogLink": {
    color: `${colors.blue01}`,
    display: "block",
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold1,
    padding: `${padding.xsmall1} 0`,
    textAlign: "center",
    textDecoration: "none",
  },
  "& .dialogHours": {
    color: colors.grey11,
    fontSize: fontSize.base,
    fontWeight: fontWeight.bold1,
    padding: `${padding.xsmall1} 0`,
    textAlign: "center",
  },
  "& .dialogFormContainer": {
    padding: `0 ${padding.xlarge1}`,
  },
  "& .dialogInputLabel": {
    fontWeight: fontWeight.bold1,
    marginTop: margin.xlarge1,
    textAlign: "left",
  },
  "& .dialogInputFields": {
    border: "none",
    fontSize: fontSize.small,
    width: "100%",
    "& div.MuiInputBase-root": {
      maxHeight: "100px !important",
    },
  },
  "& .dialogInputMask": {
    paddingBottom: "3px",
    "& input.MuiInputBase-input.MuiInput-input": {
      border: "inherit",
      fontSize: "inherit",
      height: "inherit",
      paddingBottom: "inherit",
      paddingLeft: "inherit",
    }
  },
  "& .dialogActionRoot": {
    justifyContent: "center",
    padding: padding.xlarge21,
  }
});

const buttonStyle = {
  fontSize: fontSize.base,
  fontWeight: fontWeight.normal2,
  width: "110px",
};

const ticketValidationSchema = yup.object().shape({
  subject: yup
    .string()
    .required("Field is required")
    .trim()
    .max(128, "128 characters max"),
  message: yup
    .string()
    .required("Field is required")
    .trim()
    .max(500, "500 characters max"),
  contactNumber: yup
    .string()
    .required("Field is required")
    .trim()
    .max(15, "15 characters max"),
});

const INITIAL_VALUES = {
  subject: "",
  message: "",
  contactNumber: ""
};

const SubmitTicketDialog = ({ isOpen, onConfirm, onCancel }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [successfullSubmit, setSuccessfullSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supportContactInfo, setSupportContactInfo] =
    useState<SupportContanctInfoType | null>(null);

  const [{ profileSettings }] = useProfileSettingsCache();
  const methods = useForm({
    resolver: yupResolver(ticketValidationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: INITIAL_VALUES,
  });
  const { reset, getValues, setValue, clearErrors, trigger } =
    methods;

  const getSupportData = async () => {
    return await axiosSecuredInstance.get(
      "helpcenter/getHelpCenterSupportInfo"
    );
  };

  useEffect(() => {
    setOpen(isOpen);
    getSupportData().then((data) => setSupportContactInfo(data?.data));
  }, [isOpen]);

  const onCancelAction = (): void => {
    reset(INITIAL_VALUES);
    onCancel();
  };

  const onSubmit = async (): Promise<void> => {
    clearErrors();

    const isFormValid = await trigger();
    if (!isFormValid) return;

    if (profileSettings.firstName && profileSettings.userName) {
      const ticketData: TicketDataDto = {
        Recipients: [
          {
            Name: "Customer Care",
            Address: "customerCare@Stewart.com",
          },
          {
            Name: profileSettings.firstName,
            Address: profileSettings.userName,
          },
        ],
        Sender: {
          Name: profileSettings.firstName,
          Lastname: profileSettings.lastName,
          Address: profileSettings.userName,
          UserTypeCode: profileSettings.userTypeCode,
          ContactNumber: getValues().contactNumber,
        },
        EmailSubject: `Stewart Connect ${getValues().subject}`,
        EmailBody: `${getValues().message}<br/>
        Default Info:<br/> 
        First Name: ${profileSettings.firstName}<br/>
        Last Name: ${profileSettings.lastName}<br/>
        Username: ${profileSettings.userName}<br/>
        User Type: ${profileSettings.userTypeCode}<br/>
        Contact Number: ${getValues().contactNumber}<br/>`,
        AttachmentName: null,
        Attachment: null,
      };

      setIsLoading(true);
      try {
        await axiosSecuredInstance.post("helpcenter/SubmitTicket", ticketData);
        reset(INITIAL_VALUES);
        onConfirm();
        setSuccessfullSubmit(true);
      } catch (error) {
        reset(INITIAL_VALUES);
        onConfirm();
        setErrorMessage(String(error));
        setIsErrorDialogOpen(true);
      }
    }

    setIsLoading(false);
  };
  return (
    <>
      {isLoading && (
        <Box>
          <Loading />
        </Box>
      )}
      {!successfullSubmit && (
        <TicketStewartDialog
          open={open}
          fullWidth
          height={670}
        >
          <StewartDialogTitle
            type={"INFO"}
            titleMarginTop={"55px"}
          >
            <SupportAgentIcon
              style={{
                display: "block",
                margin: "auto",
                width: "40px",
                height: "40px",
              }}
            />
            Submit A Ticket
            <DialogTitleCloseButton onClick={onCancelAction} />
          </StewartDialogTitle>
          {supportContactInfo && (
            <StewartDialogContent type={"INFO"}>
              <Typography classes={{ root: "dialogSupportTitle" }}>
                Agency Support Center
              </Typography>
              <Typography classes={{ root: "dialogPhoneNumber" }}>
                {`${supportContactInfo?.SupportPhone} - Option 2`}
              </Typography>
              <Link
                className={"dialogLink"}
                href={`mailto:${supportContactInfo?.SupportEmail}`}
              >
                {supportContactInfo?.SupportEmail}
              </Link>
              <Typography classes={{ root: "dialogHours" }}>
                Hours of Operation: 7AM - 7PM CT
              </Typography>
            </StewartDialogContent>
          )}
          <Box className={"dialogFormContainer"}>
            <FormProvider {...methods}>
              <label
                className={"dialogInputLabel"}
                htmlFor="subject"
              >
                Subject
              </label>
              <TextInputField
                name="subject"
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  setValue("subject", e.target.value)
                }
                placeholder="Enter your subject line"
                multiline
                rows={2}
              />
              <label
                className={"dialogInputLabel"}
                htmlFor="message"
              >
                Message
              </label>
              <TextInputField
                name="message"
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  setValue("message", e.target.value)
                }
                placeholder="Please enter a detailed explanation of your question or issue and include your file number if possible"
                multiline
                rows={3}
              />
              <label
                className={"dialogInputLabel"}
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              <TextInputField
                className={"dialogInputFields"}
                name="contactNumber"
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  setValue("contactNumber", e.target.value)
                }
                inputProps={{
                  inputComponent: StewartMaskInput,
                  className: "dialogInputMask"
                }}
                placeholder="Enter your contact number"
              />
            </FormProvider>
          </Box>
          <StewartDialogActions>
            <ActionButton

              variant="outlined"
              color="primary"
              onClick={onSubmit}
              style={buttonStyle}
              startIcon={<CheckLightIcon />}
            >
              Submit
            </ActionButton>
          </StewartDialogActions>
        </TicketStewartDialog>
      )}
      <SubmitTicketSuccessDialog
        isOpen={successfullSubmit}
        onClose={() => setSuccessfullSubmit(false)}
      />
      <ErrorDialog
        confirmationMessage={errorMessage}
        isOpen={isErrorDialogOpen}
        onYes={() => setIsErrorDialogOpen(false)}
      />
    </>
  );
};

export default SubmitTicketDialog;
