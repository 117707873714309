import * as UIModel from "entities/UIModel";
import React from "react";
import { useDocument } from "utils/context/DocumentContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  ProductAction,
  ProductType,
} from "utils/data/enum";

function usePDFDocumentsCPL() {
  const [pdfDocuments, setPdfDocuments] = React.useState<UIModel.PdfDocument[]>([]);
  const [openPdfViewer, setOpenPdfViewer] = React.useState<boolean>(false);
  const { getValues } = useFormWrapper();
  const [, { getDocumentUrl }] = useDocument();

  const getPDFDocuments = React.useCallback(async (
    currentActionResult: UIModel.ActionResult,
    currentProductIntegrationKey: String | undefined,
    currentProductAction: string | undefined,
    currentProductOriginalOrderID: number | undefined,
  ) => {
    let pdfDocuments: Array<UIModel.PdfDocument> = [];
    if (currentActionResult.scfile) {
      const scfile = currentActionResult.scfile as UIModel.SCFile;

      const cpl = scfile.cpls?.find(
        (cpl) =>
          (currentProductAction === ProductAction.Issue &&
            cpl.integrationKey === currentProductIntegrationKey) ||
          (currentProductAction === ProductAction.Revise &&
            cpl.originalOrderID === currentProductOriginalOrderID)
      );
      if (cpl) {
        const documentId =
          cpl.documents && cpl.documents.length > 0
            ? Number(cpl.documents[0].id)
            : 0;

        if (documentId > 0) {
          // get pdf url here
          const actionResult: UIModel.ActionResult = await getDocumentUrl(
            cpl.fileID || 0,
            ProductType.Cpl,
            cpl.orderID || 0,
            documentId,
            0
          );

          if (actionResult && actionResult.pdfUrl) {
            const fileNameNumber = getValues("fileNameNumber");
            const pdfFilename = `${fileNameNumber} ${ProductType.Cpl} ${cpl.coveredParty} ${cpl.productReferenceID}`;
            pdfDocuments.push({
              fileId: scfile.id || 0,
              productType: ProductType.Cpl,
              orderId: cpl.orderID || 0,
              documentId: documentId,
              pdfUrl: actionResult.pdfUrl,
              fileName: pdfFilename,
              showHidden: 0,
            });
          }
        }
      }
    }

    if (pdfDocuments.length === 0) return;
    setPdfDocuments(pdfDocuments);
    setOpenPdfViewer(true);

  }, [getValues]);


  return { getPDFDocuments, openPdfViewer, setOpenPdfViewer, pdfDocuments };
}

export default usePDFDocumentsCPL;
