/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  fuzzyMatch,
  fuzzyMatchWithList,
} from "pages/file/utils/helper";
import React, {
  useEffect,
} from "react";
import { useCompanyCounties } from "utils/context/CompanyCountiesContext";
import {
  useFiles,
  useInitialValuesFiles,
  useIsFileLoadingFiles,
} from "utils/context/FilesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { CountyFieldProps } from "utils/interfaces/BaseAddress";
import SelectFieldWithClearIcon from "../select-field/SelectFieldWithClearIcon";

/**
 * Requirements for County field
 *
 * 1) County is only shown in the property section
 * 2) County list is empty if no state is selected
 * 3) County list is populated based on the state, agency, and contract info
 * 4) County defaults to first county if only one is available
 * 5) County defaults to zip code's default county if available (and there is more than one)
 * 6)
 */
const CountyField = ({
  name,
  labelPrefix = "",
  isDisabled = false,
  onChange,
  zipCodeResults,
}: CountyFieldProps) => {
  const onChangeHandler = (e: any, item: any) => {
    onChange && onChange(e, item);
  };
  /**
   * counties is populated in the PropertyCollection control because they
   * all share the same county list based on state, agency, etc.
   */
  const { getValues, setValue, clearErrors } = useFormWrapper();
  const [{ counties: baseCounties, isCountyLoadCompleted }, { updateCounties, getCounties }] = useCompanyCounties();
  const counties = [ { text: "", value: "" },...baseCounties];
  const [{ isFileLoading }] = useIsFileLoadingFiles();
  const [{ isReadOnly }] = useFiles();
  const [{ initialValues }] = useInitialValuesFiles();
  
  /**
   * This useEffect populates the default county
   * based on the available counties and whether or not
   * there are zipCodeResults with counties and a default
   * county
   */
  useEffect(
    function selectDefaultCounty() {
      if (isFileLoading) return;
      const currentCounty = getValues(name);

      const defaultCounty = { name: "", code: "" };
      /**
       * On Clear button click, County field should be reset and
       * User should be able to type different value into zipcode
       * from State and County should be updated to new value
       * */
      if (counties?.length === 1 &&
        counties[0].value === "") {
        if (currentCounty?.name === "") {
          setValue(name, defaultCounty);
        } else {
          /**
           * If Agency contract is cancelled/InActive
           * Counties will not be returned by API.
           * So Add the County of the property to List to display
           */
          if (isCountyLoadCompleted) {
            const agency = getValues('agency');
            const propertyState = getValues('properties.0.state');
            const countyList: any = [{
              companyID: agency?.id,
              countyName: currentCounty?.name,
              countyCodeNum: currentCounty?.code,
              isHOI: null,
              default: 1
            }];

            updateCounties(
              countyList,
              propertyState?.abbr,
              isReadOnly);
            defaultCounty.name = currentCounty?.name;
            defaultCounty.code = currentCounty?.code;
          }
        }
      }

      if (counties?.length === 2) {
        const county = counties[1];
        if (
          zipCodeResults?.counties &&
          zipCodeResults?.counties?.length > 0 &&
          zipCodeResults?.county
        ) {
          if (fuzzyMatch(zipCodeResults.county, county.text)) {
            /**
             * If there are zipCodeResults with counties and a default
             * county then fuzzy match against the singular county and
             * set it as default
             */
            defaultCounty.name = county.text;
            defaultCounty.code = county.value;
          }
        } else {
          /**
           * If there are no zipCodeResults with counties and a default county
           * select the only available county as default
           */
          defaultCounty.name = county.text;
          defaultCounty.code = county.value;
        }
      } else if (counties.length > 1 && currentCounty?.code) {
        defaultCounty.name = currentCounty?.name;
        defaultCounty.code = currentCounty?.code;
      } else if (counties.length > 1 && zipCodeResults?.county) {
        /**
         * If we have a list of counties and zipCodeResults with counties
         * and a defaulty county, we want to find the one that matches from
         * the list and select it
         */
        const matchingCounty = fuzzyMatchWithList(
          zipCodeResults.county,
          counties
        );
        if (matchingCounty) {
          /**
           * If there are zipCodeResults with counties and a default
           * county then fuzzy match against the singular county and
           * set it as default
           */
          defaultCounty.name = matchingCounty.text;
          defaultCounty.code = matchingCounty.value;
        }
      }

      if (
        defaultCounty.code !== "" &&
        defaultCounty.code !== currentCounty?.code
      ) {
        //setValue(name, defaultCounty, { shouldValidate: true });
        setValue(name, defaultCounty);
        if (defaultCounty.code !== "") {
          clearErrors(`${name}` as const);
        }
      }
    },
    [zipCodeResults, counties]
  );

  /**
   *
   * Update the comments and layout of this control
   * bring in all of the logic from Full Address and
   * document the requirements
   */

  /*
  IF county code is not null but county list is null
  retry retrieving the county list 3 times
  */ 

  useEffect(() => {

    if(counties?.length === 0 && initialValues?.properties?.length > 0) {
      var countyObj = initialValues.properties[0].county;
      if(countyObj?.code) {
        const agency = getValues(`agency`);
        getCounties(
          agency.id,
          getValues(`properties.0.state`)?.abbr,
          agency.activeContractID
        );        
      }
    }
  }, [counties?.length])

  return (
    <SelectFieldWithClearIcon
      label={`${labelPrefix}County/Parish/Borough`}
      options={counties}
      name={name}
      dataTextField="name"
      dataValueField="code"
      disabled={isDisabled}
      allowsDelete
      onChange={onChangeHandler}
      defaultValue={{ text: "", value: "" }}
    />
  );
};

export default CountyField;
