import {
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import capitalize from "@mui/material/utils/capitalize";
import DatePickerSearch from "controls/global/datepicker-search";
import LightTooltip from "controls/global/light-tooltip";
import MultiLineCell from "controls/global/stewart-table/MultiLineCell";
import StewartTable, {
  DetailsViewConfig,
  SelectionConfig,
  StewartTableColumn,
} from "controls/global/stewart-table/StewartTable";
import StewartTableFooterPaginator from "controls/global/stewart-table/StewartTableFooterPaginator";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { IColumn } from "entities/ApiModel/IColumn";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import { orderBy as _orderBy } from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import {
  borderRadius,
  borderSize,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import {
  getColumnConfig,
  getColumnSearchField,
} from "utils/columnsGrid";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useReportPay } from "utils/context/ReportPayContext";
import useReportOptionType from "utils/custom-hooks/useReportOptionType";
import {
  AgencyStatus,
  FileStatusType,
  PERMISSIONS,
  ReportAndPayAction,
} from "utils/data/enum";
import {
  formatCurrency,
  formatDate,
} from "utils/shared";
import { Order } from "utils/sorting";
import InputSearch from "../input-search";
import PaymentDetailsGrid from "../payment-details-grid";
import ReportPay from "./ReportPay";
import ReportPayFooter from "./ReportPayFooter";
import SelectStatus from "./SelectStatus";
import {
  SessionStorageKeys,
} from "utils/data/enum";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

type Props = {
  colsConfig: IColumn[];
  rows: ReportPayFile[];
  rowsPerPage: number;
  loadingProgressPercent?: number;
  noRowsMessage: string[];
  onlyShowSelectedFiles: boolean;
  fileStatusTypes: FileStatusType[];
  page: number;
  hiddenColumns?: (keyof ReportPayFile)[];
  currentFilter?: ReportAndPayCriteriaSearch;
  isAdvSearchOpen?: boolean;
  onPageChange: (page: number) => void;
  onFiltersChange: (name: keyof ReportAndPayCriteriaSearch, value: any) => void;
  selectionConfig?: SelectionConfig<ReportPayFile>;
  onOnlySelectedFilesChange?: (value: boolean) => void;
  onRowsPerPageChange: (count: number) => void;
  onRequestSort?: (property: string, disableToggling?: boolean) => void;
  onColumnResize: (column: keyof ReportPayFile, newWidth: number) => void;
  onColumnsModified?: (columns: IColumn[]) => void;
  onShowPdf: (pdfUrl: string) => void;
  order: Order;
  orderBy?: keyof ReportPayFile;
  defaultValues? : any;
  companyStatus: AgencyStatus[];
};

const ExcludedFileContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  "& > :first-child": {
    display: "inline-block",
    maxWidth: "calc(100% - 16px)"
  },
});

const StyledLink = styled(Link)({
  color: colors.blue01,
  cursor: "pointer",
  fontWeight: fontWeight.bold2,
  textDecoration: "underline",
});

const DenotedBox = styled("span")({
  color: colors.blue01,
  paddingLeft: padding.xsmall,
});

const CurrencyBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const ButtonsBox = styled(Box)({
  display: "flex",
  gap: gaps.small1,
  justifyContent: "flex-end",
});

const StyledTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& > .MuiTableContainer-root > table.MuiTable-root:not(.details-table)": {
    "& > thead": {
      "& th.MuiTableCell-root.MuiTableCell-head": {
        color: colors.grey08,
        fontSize: fontSize.base,
        fontWeight: fontWeight.normal2,
        "& div.MuiBox-root": {
          color: colors.grey08,
          fontSize: fontSize.base,
          fontWeight: fontWeight.normal2,
        },
        "& div.MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-adornedEnd": {
          border: borderSize.zero,
          borderBottom: `${borderSize.xsmall} solid ${colors.grey15}`,
          borderRadius: 0,
          "&:focus-within": {
            borderBottom: `${borderSize.small} solid ${colors.blue01}`,
          },
          "& fieldset": {
            border: borderSize.zero,
          },
        },
      },
    },
    "& > tbody": {
      "& > tr": {
        "&:hover td": {
          background: colors.grey10,
        },
        "&:last-child": {
          "& > td:not(.emptyCell)": {
            borderBottom: `1px solid ${colors.grey08}`,
          },
          "& > td:not(.emptyCell):first-child": {
            borderBottomLeftRadius: borderSize.zero,
          },
          "& > td:not(.emptyCell):last-child": {
            borderBottomRightRadius: borderSize.zero
          },
        },
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body": {
      "& div": {
        color: colors.grey08,
        fontWeight: fontWeight.normal1,
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-footer": {
      borderBottom: defaultStyle.table.border,
      borderBottomLeftRadius: borderRadius.small,
      borderBottomRightRadius: borderRadius.small,
      borderLeft: defaultStyle.table.border,
      borderRight: defaultStyle.table.border,
      padding: padding.xsmall2,
      paddingRight: padding.small2,
    },
  },
  "& .rightColumn": {
    "& span": {
      display: "flex",
      justifyContent: "end",
      paddingRight: padding.small2,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      textAlign: "end",
    },
  },
});

export default function ReportPayGrid({
  colsConfig,
  rows,
  rowsPerPage,
  loadingProgressPercent,
  noRowsMessage,
  onlyShowSelectedFiles,
  fileStatusTypes,
  page,
  hiddenColumns,
  currentFilter,
  isAdvSearchOpen,
  selectionConfig,
  onPageChange,
  onFiltersChange,
  onOnlySelectedFilesChange,
  onRowsPerPageChange,
  onShowPdf,
  onRequestSort,
  onColumnResize,
  onColumnsModified,
  order,
  orderBy,
  defaultValues,
  companyStatus,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const [{ selectedItems }, { refresh, clearSelectedItems }] = useReportPay();
  const { reportOnlyVisible, payByCheckVisible } = useReportOptionType();
  const noSelectedRows = !selectedItems?.length;

  const detailsViewConfig: DetailsViewConfig<ReportPayFile> = {
    enabled: true,
    renderRowDetails: (file) =>
      <PaymentDetailsGrid file={file} />
  };

  const inputSearchLabel = onlyShowSelectedFiles ? "Filter" : "Search";

  const getSearchField = (field: keyof ReportPayFile) =>
    getColumnSearchField<ReportPayFile, ReportAndPayCriteriaSearch>(colsConfig, field);
  const usePriorSearchCriteria = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
  const [{ profileSettings }] = useProfileSettingsCache();

  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  
  const hasReportAccess  = permissionCodes?.includes(PERMISSIONS.BILLING_REPORT);
  const hasReportAndPayByCheckAccess = permissionCodes?.includes(PERMISSIONS.BILLING_RPTPAYCHECK);

  const hasFileViewAccess = permissionCodes.includes(PERMISSIONS.FILES_VIEW)
  const columns: StewartTableColumn<ReportPayFile>[] = _orderBy([
    {
      field: "clientFileId",
      name: "File",
      width: 280,
      sticky: true,
      left: 98,
      sortable: true,
      position: 0,
      actionComponent: () => (
        <InputSearch
          name={getSearchField("clientFileId")}
          label={inputSearchLabel}
          filterValue={currentFilter?.clientFileId}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["clientFileId"] : ""}
        />
      ),
      valueGetter: ({ clientFileId, fileId, isExcludedFromAutoReport }: ReportPayFile) => (
        (isExcludedFromAutoReport
          ? <ExcludedFileContainer>
            <div>
              <TooltipCell title={clientFileId}>
                {hasFileViewAccess ?<StyledLink
                  to={"/file"}
                  state={{
                    fileID: fileId,
                    isAdvSearchOpen: isAdvSearchOpen,
                  }}
                >
                  {clientFileId}
                </StyledLink> :(clientFileId) }
              </TooltipCell>
            </div>
            <LightTooltip
              title={"File excluded from auto-reporting"}
              arrow
            >
              <DenotedBox>*</DenotedBox>
            </LightTooltip>
          </ExcludedFileContainer>
          : <TooltipCell title={clientFileId}>
            {hasFileViewAccess ?<StyledLink
              to={"/file"}
              state={{
                fileID: fileId,
                isAdvSearchOpen: isAdvSearchOpen,
                clientId: clientFileId,
              }}
            >
              {clientFileId}
            </StyledLink> :(clientFileId)}
          </TooltipCell>
        )
      )
    },
    {
      field: "propertyAddresses",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("propertyAddresses")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["propertyAddresses"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (<MultiLineCell names={row.propertyAddresses} />),
      ...getColumnConfig<ReportPayFile>(colsConfig, "propertyAddresses"),
    },
    {
      field: "buyerNames",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("buyerNames")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["buyerName"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (<TooltipCell title={row.buyerNames} />),
      ...getColumnConfig<ReportPayFile>(colsConfig, "buyerNames"),
    },
    {
      field: "agencyID",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("agencyID")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["agencyID"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.agencyID} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "agencyID"),
    },
    {
      field: "locationDisplayNames",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("locationDisplayNames")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["locationDisplayName"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (<MultiLineCell names={row.locationDisplayNames} />),
      ...getColumnConfig<ReportPayFile>(colsConfig, "locationDisplayNames"),
    },
    // {
    //   field: "companyStatus",
    //   actionComponent: () => (
    //     <AgencySelectStatus
    //       name="companyStatus"
    //       defaultValue={(defaultValues && usePriorSearchCriteria && defaultValues["companyStatus"]) ? defaultValues["companyStatus"].split("|") : companyStatus}
    //       disabled={isAdvSearchOpen}
    //       onChange={onFiltersChange}
    //     />
    //   ),
    //   valueGetter: (row: ReportPayFile) => (<MultiLineCell names={row.companyStatus} />),
    //   ...getColumnConfig<ReportPayFile>(colsConfig, "companyStatus"),
    // },
    {
      field: "locationLegacyIDs",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("locationLegacyIDs")}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["locationLegacyID"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (<MultiLineCell names={row.locationLegacyIDs} />),
      ...getColumnConfig<ReportPayFile>(colsConfig, "locationLegacyIDs"),
    },
    {
      field: "agencyName",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("agencyName")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["agencyName"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.agencyName} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "agencyName"),
    },
    {
      field: "sellerNames",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("sellerNames")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["sellerNames"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.sellerNames} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "sellerNames"),
    },
    {
      field: "lenderNames",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("lenderNames")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["lenderName"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.lenderNames} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "lenderNames"),
    },
    {
      field: "fileStatusTypeCode",
      actionComponent: () => (
        <SelectStatus
          key={`statuses-${fileStatusTypes}`}
          name="fileStatusTypeCode"
          defaultValue={(defaultValues && usePriorSearchCriteria && defaultValues["fileStatusTypeCode"]) ? defaultValues["fileStatusTypeCode"].split("|") : fileStatusTypes}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
        />
      ),
      valueGetter: (row: ReportPayFile) => {
        const text =
          row.fileStatusTypeCode === FileStatusType.ReportPending
            ? "REPORTED/PENDING"
            : row.fileStatusTypeCode;
        return <TooltipCell title={capitalize(text)} />;
      },
      ...getColumnConfig<ReportPayFile>(colsConfig, "fileStatusTypeCode"),
    },
    {
      field: "productNames",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("productNames")}
          label={inputSearchLabel}
          filterValue={currentFilter?.productName}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["productNames"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (
        <MultiLineCell names={row.productNames} />
      ),
      ...getColumnConfig<ReportPayFile>(colsConfig, "productNames"),
    },
    {
      field: "firstJacketIssueDate",
      actionComponent: () => (
        <DatePickerSearch
          value={defaultValues && usePriorSearchCriteria ? defaultValues["firstJacketIssueDate"] : currentFilter?.firstJacketIssueDate}
          disabled={isAdvSearchOpen}
          handleDateChange={(date: Date | null) =>
            onFiltersChange("firstJacketIssueDate", date)
          }
          size="small"
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={formatDate(row.firstJacketIssueDate)} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "firstJacketIssueDate"),
    },
    {
      field: "firstJacketEffectiveDate",
      actionComponent: () => (
        <DatePickerSearch
          value={defaultValues && usePriorSearchCriteria ? defaultValues["firstJacketEffectiveDate"] : currentFilter?.firstJacketEffectiveDate}
          disabled={isAdvSearchOpen}
          handleDateChange={(date: Date | null) =>
            onFiltersChange("firstJacketEffectiveDate", date)
          }
          size="small"
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={formatDate(row.firstJacketEffectiveDate)} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "firstJacketEffectiveDate"),
    },
    {
      field: "stateAbbr",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("stateAbbr")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["stateAbbr"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.stateAbbr} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "stateAbbr"),
    },
    {
      field: "propertyTypeCodes",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("propertyTypeCodes")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["propertyTypeCodes"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.propertyTypeCodes} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "propertyTypeCodes"),
    },
    {
      field: "transactionTypeCode",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("transactionTypeCode")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["transactionTypeCode"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => <TooltipCell title={row.transactionTypeCode} />,
      ...getColumnConfig<ReportPayFile>(colsConfig, "transactionTypeCode"),
    },
    {
      field: "totalActualRiskRate",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("totalActualRiskRate")}
          inputType="number"
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalActualRiskRate"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalActualRiskRate, true, false)}
        </CurrencyBox>
      ),
      ...getColumnConfig<ReportPayFile>(colsConfig, "totalActualRiskRate"),
    },
    {
      field: "totalActualFee",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("totalActualFee")}
          inputType="number"
          label={inputSearchLabel}
          filterValue={currentFilter?.totalActualFee}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalActualFee"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalActualFee, true, false)}
        </CurrencyBox>
      ),
      ...getColumnConfig<ReportPayFile>(colsConfig, "totalActualFee"),
    },
    {
      field: "totalActualRetention",
      actionComponent: () => (
        <InputSearch
          name={getSearchField("totalActualRetention")}
          label={inputSearchLabel}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalActualRetention"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalActualRetention, true, false)}
        </CurrencyBox>
      ),
      ...getColumnConfig<ReportPayFile>(colsConfig, "totalActualRetention"),
      width: 180,
    },
    {
      field: "totalAmountDue",
      name: "Total Due",
      width: 180,
      draggable: true,
      sortable: true,
      resizable: true,
      position: colsConfig.length,
      actionComponent: () => (
        <InputSearch
          name={getSearchField("totalAmountDue")}
          inputType="number"
          label={inputSearchLabel}
          filterValue={currentFilter?.totalAmountDue}
          disabled={isAdvSearchOpen}
          onChange={onFiltersChange}
          defaultValue={defaultValues && usePriorSearchCriteria ? defaultValues["totalAmountDue"] : ""}
        />
      ),
      valueGetter: (row: ReportPayFile) => (
        <CurrencyBox>
          {formatCurrency(row.totalAmountDue, true, true)}
        </CurrencyBox>
      ),
    },
  ], "position");

  // Add 1 to column span for expand/select row header.
  //const totalColSpan = columns.length - (hiddenColumns?.length ?? 0) + 1;

  const handleCloseAction = () => {
    refresh();
    clearSelectedItems();
  };

  useEffect(() => {
    setIsLoading(loadingProgressPercent !== undefined && loadingProgressPercent >= 0);
  }, [loadingProgressPercent]);

  return (
    <StyledTableContainer width={1}>
      <StewartTable
        cols={columns}
        rows={rows}
        rowsPerPage={rowsPerPage}
        loadingProgressPercent={loadingProgressPercent}
        noRowsMessage={noRowsMessage}
        page={page}
        hiddenColumns={hiddenColumns}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onColumnResize={onColumnResize}
        onColumnsModified={onColumnsModified}
        useDbPagingSorting={!onlyShowSelectedFiles}
        detailsViewConfig={detailsViewConfig}
        selectionConfig={selectionConfig}
        footerComponent={
          <>
            <ReportPayFooter
              rows={(selectedItems ?? [])}
              onlyShowSelectedFiles={onlyShowSelectedFiles}
              page={page}
              isLoading={isLoading}
              onPageChange={onPageChange}
              onOnlySelectedFilesChange={onOnlySelectedFilesChange}
            />
           </>
      }
      />
      <StewartTableFooterPaginator
        totalRows={rows[0]?.totalRows ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        totalPages={rows[0]?.totalPages ?? 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <ButtonsBox>
        <ReportPay
          action={ReportAndPayAction.ReportOnly}
          selectedItems={(selectedItems ?? [])}
          disabled={!enableReportPay || noSelectedRows || isLoading || !hasReportAccess}
          onCloseAction={handleCloseAction}
          hidden={!reportOnlyVisible}
        />
        <ReportPay
          action={ReportAndPayAction.ReportAndPayByCheck}
          selectedItems={(selectedItems ?? [])}
          disabled={!enableReportPay || noSelectedRows || isLoading || !hasReportAndPayByCheckAccess}
          onCloseAction={handleCloseAction}
          onShowPdf={onShowPdf}
          hidden={!reportOnlyVisible || !payByCheckVisible}
        />
      </ButtonsBox>
    </StyledTableContainer>
  );
}
