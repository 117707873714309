import { yupResolver } from "@hookform/resolvers/yup";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Box,
  Grid,
  styled,
} from "@mui/material";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import ActionButton from "controls/global/action-button";
import AddLink from "controls/global/add-link";
import CustomTooltip from "controls/global/custom-tooltip/CustomTooltip";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogTitle,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import Loading from "controls/global/loading";
import TextInputField from "controls/global/text-input-field";
import { UserRequest } from "entities/UIModel/UserRequest";
import React, {
  useEffect,
  useState,
} from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import theme from "theme/default";
import {
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import CheckLightIcon from "theme/icons/CheckLightIcon";
import StyledInfoIcon from "theme/icons/StyledInfoIcon";
import {
  useCompanyContext,
  useCompanyContextActions,
} from "utils/context/CompanyContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { v4 as uuidv4 } from "uuid";
import AgencyFormField from "./AgencyFormField";
import DisplayedMessage from "./DisplayedMessage";
import RadioButtonField from "./RadioButtonField";
import RegisterFormSuccessDialog from "./RegisterFormSuccessDialog";
import StateFormField from "./StateFormField";
import UserInputGrid from "./UserInputGrid ";
import { mapApiUserRequest } from "./mapApiUserRequest";
import registrationValidationSchema from "./registrationValidationSchema";
import TPSFormField from "./TPSFormField";

type Props = {
  confirmationMessage?: string | React.ReactNode;
  isOpen: boolean;
  onConfirm: any;
  onCancel: any;
  setIsRegisterFormOpen: any;
};

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    [theme.breakpoints.up("lg")]: {
      maxHeight: "unset",
      maxWidth: "1000px",
    },
    justifyContent: "center",
  },
});

const StyledPersonAddIcon = styled(PersonAddIcon)({
  display: "block",
  height: "40px",
  margin: "auto",
  width: "40px",
});

const StyledBox = styled(Box)({
  marginBottom: margin.xlarge,
  marginTop: margin.xlarge,
  padding: `0 ${padding.xlarge1}`,
});

const StyledUserBox = styled(Box)({
  marginBottom: margin.medium,
  marginTop: margin.small,
  maxHeight: "300px",
  minHeight: "80px",
  paddingTop: padding.small,
  overflowY: "auto",
  overflowX: "hidden",

  "&&MuiBox-root.errorMessage": {
    position: "static",
  },
});
const StyledDialogAction = styled(StewartDialogActions)({
  justifyContent: "center",
  paddingBottom: padding.large2,
  paddingTop: padding.zero,
});

const CustomizedTextField = styled(TextInputField)({
  marginTop: margin.large,
});

const buttonStyle = {
  fontSize: fontSize.base,
  fontWeight: fontWeight.normal2,
  width: "110px",
};
const MessageStyledBox = styled(Box)({
  display: "flex",
  "& .MuiSvgIcon-root": {
    marginTop: padding.xlarge3,
    marginLeft: margin.small2,
    paddingLeft: padding.xsmall,
  },
});

const INITIAL_VALUES = {
  agency: { text: "", value: "", activeContractID: "", legacyID: "" },
  agencyID: "",
  agencyName: "",
  agencyCode: "",
  state: { text: "", value: "" },
  stateAbbr: "",
  stateCode: "",
  users: [{ firstName: "", lastName: "", email: "", timeZone: "" }],
  message: "",
  radioSelection: "",
  tpsSoftware: "",
};

const HELP_MESSAGE =
  "Enter specific instructions for this request (ex: if access to multiple agencies is needed, please list those agencies).";

const RegisterFormDialog = ({ isOpen, onConfirm, onCancel, setIsRegisterFormOpen }: Props) => {

  const [open, setOpen] = useState<boolean>(false);
  const [successfullSubmit, setSuccessfullSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [{ profileSettings }] = useProfileSettingsCache();
  const[disable,setDisable]=useState(false)
  const methods = useForm({
    resolver: yupResolver(registrationValidationSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: INITIAL_VALUES,
  });
  const { reset, getValues, setValue, clearErrors, control, trigger, formState } = methods;
  const { append } = useFieldArray({ control, name: "users" });
  const [{ agencies }] = useCompanyContext();
  const [, { getAgencies }] = useCompanyContextActions();

  const [currentAgencyId] = useState("");
  const [selectedStateObject, setSelectedStateObject] = useState({
    text: "",
    value: "",
  });
  const onCancelAction = (): void => {
    reset(INITIAL_VALUES);
    onCancel();
  };

  const handelAddItem = (e: any) => {
    append({ firstName: "", lastName: "", email: "", timeZone: "" });
  };

  const onSubmit = async (): Promise<void> => {
    clearErrors();
    const isFormValid = await trigger();
    if (!isFormValid) {
      console.log(formState.errors);
      return;
    }

    const userRequestDetails = getValues().users.map((user) => {
      return {
        emailAddress: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        userTimeZone: user.timeZone,
      };
    });

    const ticketData: UserRequest = {
      userRequestID: 0,
      requestedUserID: profileSettings.userID,
      agencyID: getValues().agencyCode,
      agencyName: getValues().agencyName,
      legacyID: getValues().agencyID,
      stateAbbr: selectedStateObject?.text,
      stateCode: selectedStateObject?.value,
      additionalInstruction: getValues().message,
      requestedUserAuthorizationType: getValues().radioSelection,
      requestedUserName: profileSettings.userName,
      requestedUserEmailAddress: profileSettings.userEmailAddress,
      requestedUserFirstName: profileSettings.firstName,
      requestedUserLastName: profileSettings.lastName,
      requestedUserDisplayName: profileSettings.displayName,
      requestedUserPhone: profileSettings.userPhone,
      userRequestDetails: userRequestDetails,
      tpsSoftware :getValues().tpsSoftware,
    };
    setIsLoading(true);
    const apiData = mapApiUserRequest(ticketData);
    try {
      await axiosSecuredInstance.post("user/RequestNewAccount", apiData);
      reset(INITIAL_VALUES);
      onConfirm();
      setSuccessfullSubmit(true);
    } catch (error) {
      reset(INITIAL_VALUES);
      onConfirm();
      setErrorMessage(String(error));
      setIsErrorDialogOpen(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (agencies.length === 0) {
      getAgencies();
    }
  }, [getAgencies, isOpen]);

  useEffect(() => {
    setDisable(!formState.isValid);
  }, [formState.isValid]);

  return (
    <>
      {isLoading && <Loading />}
      {!successfullSubmit && (
        <StyledDialog
          open={open}
          maxWidth="lg"
          height={1020}
        >
          <StewartDialogTitle
            type={"INFO"}
            titleMarginTop={"55px"}
          >
            <StyledPersonAddIcon />
            Request a New Connect User
            <DialogTitleCloseButton onClick={onCancelAction}/>
            </StewartDialogTitle>
          <StyledBox>
            <FormProvider {...methods}>
              <Grid
                container
                spacing={1}
              >
                <AgencyFormField
                  currentAgencyId={currentAgencyId}
                  agencies={agencies}
                  methods={methods}
                  isOpen={isOpen}
                  name={`agency`}
                />
                <Grid
                  item
                  xs={2}
                >
                  <StateFormField
                    isOpen={isOpen}
                    setSelectedStateObject={setSelectedStateObject}
                    methods={methods}
                  />
                </Grid>
              </Grid>
              <StyledUserBox>
                {methods.getValues("users").map((user, index) => (
                  <UserInputGrid
                    key={uuidv4()}
                    index={index}
                    control={control}
                  />
                ))}
              </StyledUserBox>
              <AddLink
                disabled={false}
                onClick={handelAddItem}
              >
                Add User
              </AddLink>
              <TPSFormField 
              />
              <MessageStyledBox>
                <CustomizedTextField
                  name="message"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue("message", e.target.value)}
                  placeholder="Additional Instructions"
                  multiline
                  rows={4}
                  inputProps={{
                    maxLength: 1000,
                    minLength: 1,
                  }}
                />
                <CustomTooltip
                  title={HELP_MESSAGE}
                  placement="top"
                  aria-label="info"
                  arrow
                >
                  <StyledInfoIcon />
                </CustomTooltip>
              </MessageStyledBox>
              <DisplayedMessage />
              <RadioButtonField />
            </FormProvider>
          </StyledBox>
          <StyledDialogAction>
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={onSubmit}
              style={buttonStyle}
              startIcon={<CheckLightIcon />}
              type="submit"
              disabled={disable}
            >
              Submit
            </ActionButton>
          </StyledDialogAction>
        </StyledDialog>
      )}
      <RegisterFormSuccessDialog
        isOpen={successfullSubmit}
        onClose={() => setSuccessfullSubmit(false)}
        setIsRegisterFormOpen={setIsRegisterFormOpen}
      />
      <ErrorDialog
        confirmationMessage={errorMessage}
        isOpen={isErrorDialogOpen}
        onYes={() => setIsErrorDialogOpen(false)}
      />
    </>
  );
};
export default RegisterFormDialog;
