import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  gaps,
  margin,
} from "theme/defaultStyle";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

const ActionButtonGroup = styled("div")({
  display: "flex",
  gap: gaps.large1,
  justifyContent: "flex-end",
  "& .MuiSvgIcon-root": {
    height: "18px",
    marginLeft: margin.small,
    width: "16px",
  },
});

const AgencyFieldHeader = () => {
  const navigate = useNavigate();
  const [, { setUserSelectedAgency }] = useGlobalAccess();

  const navigateTo = () => {
    setUserSelectedAgency([]);
    navigate("/Home");
  };

  return (
    <ActionButtonGroup>
      <ActionButton
        variant="outlined"
        color="secondary"
        onClick={navigateTo}
      >
        Skip
        <ArrowForwardIcon />
      </ActionButton>
    </ActionButtonGroup>
  );
};

export default AgencyFieldHeader;
