import {
    SvgIcon,
    SvgIconProps,
} from "@mui/material";
import React from "react";

const DatePickerIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path fill="#0075AA" d="M14.5 2V2.5H15H17C17.663 2.5 18.2989 2.76339 18.7678 3.23223C19.2366 3.70107 19.5 4.33696 19.5 5V17C19.5 17.663 19.2366 18.2989 18.7678 18.7678C18.2989 19.2366 17.663 19.5 17 19.5H3C2.33696 19.5 1.70107 19.2366 1.23223 18.7678C0.763392 18.2989 0.5 17.663 0.5 17V5C0.5 4.33696 0.763392 3.70107 1.23223 3.23223C1.70107 2.76339 2.33696 2.5 3 2.5H5H5.5V2V1C5.5 0.867392 5.55268 0.740215 5.64645 0.646446C5.74021 0.552678 5.86739 0.5 6 0.5C6.13261 0.5 6.25979 0.552678 6.35355 0.646446C6.44732 0.740215 6.5 0.867392 6.5 1V2V2.5H7H13H13.5V2V1C13.5 0.867392 13.5527 0.740215 13.6464 0.646446C13.7402 0.552678 13.8674 0.5 14 0.5C14.1326 0.5 14.2598 0.552679 14.3536 0.646446C14.4473 0.740214 14.5 0.867391 14.5 1V2ZM2 9.5H1.5V10V17C1.5 17.3978 1.65804 17.7794 1.93934 18.0607C2.22065 18.342 2.60218 18.5 3 18.5H17C17.3978 18.5 17.7794 18.342 18.0607 18.0607C18.342 17.7794 18.5 17.3978 18.5 17V10V9.5H18H2ZM18 8.5H18.5V8V5C18.5 4.60218 18.342 4.22064 18.0607 3.93934C17.7794 3.65804 17.3978 3.5 17 3.5H15H14.5V4V5C14.5 5.13261 14.4473 5.25979 14.3536 5.35355C14.2598 5.44732 14.1326 5.5 14 5.5C13.8674 5.5 13.7402 5.44732 13.6464 5.35355C13.5527 5.25978 13.5 5.13261 13.5 5V4V3.5H13H7H6.5V4V5C6.5 5.13261 6.44732 5.25978 6.35355 5.35355C6.25978 5.44732 6.13261 5.5 6 5.5C5.86739 5.5 5.74022 5.44732 5.64645 5.35355C5.55268 5.25978 5.5 5.13261 5.5 5V4V3.5H5H3C2.60217 3.5 2.22064 3.65804 1.93934 3.93934C1.65804 4.22064 1.5 4.60218 1.5 5V8V8.5H2H18Z"
             stroke="#0075AA">
            </path>
        </SvgIcon>
    );
};

export default DatePickerIcon;
