import {
  Box,
  styled,
} from "@mui/material";
import {
  FileRejection,
  FileWithPath,
} from "react-dropzone"
import {
  Agency,
  FileUploadAdditionalInfo,
  FileUploadStatus,
} from "entities/UIModel";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FormProvider,
  useForm,
} from "react-hook-form";
import theme from "theme/default";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
} from "theme/defaultStyle";
import AgencyField from "./AgencyField";
import { useDocument } from "utils/context/DocumentContext";
import { v4 as uuidv4 } from "uuid";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { ProgressImageType } from "utils/data/enum";
import {
  getFormattedErrorMessage,
  mapFileUploaResponse,
} from "pages/file/utils/fileUploadHelper";
import UploadDropZone from "controls/global/upload-files/UploadDropZone";
import UploadStatusGrid from "controls/global/upload-files/UploadStatusGrid";
import ConfirmationDialogV3 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV3";

export interface UploadInfo {
  agency: Agency,
}

const initialValues: UploadInfo = {
  agency: {
    id: "",
    name: "",
    activeContractID: undefined,
    legacyID: undefined,
  }
}

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const SectionTitle = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small2,
});

const SectionTitleText = styled(Box)({
  // background: gradients.maroonGradient01,
  fontSize: fontSize.base,
  fontWeight: fontWeight.normal1,
  color: colors.grey11,
  // WebkitBackgroundClip: "text",
  // WebkitTextFillColor: "transparent",
});

const SectionPanel = styled(Box)({
  alignItems: "center",
  display: "block",
  "& .flex-row ": {
    display: "flex",
    flexDirection: "row",
    gap: gaps.large1,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
  "& .new-row": {
    flexBasis: "100%",
    height: "0",
  },
  "& .agency": {
    minWidth: "200px",
    maxWidth: "400px",
  },
  "& .drop-zone": {
    marginTop: "20px",
    marginBottom: "36px",
    height: "201px",
    border: "1px solid #384EB74D",
    background: "#92C4DB1A",
    minWidth: "100%",
    display: "flex",
  },
  "& .drop-zone-text": {
    margin: "auto",
    textAlign: "center",
    height: "129px",
    width: "300px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  "& .drop-zone-text .file-upload-icon": {
    margin: "auto",
  },
  "& .drop-zone-text .drag-and-drop": {
    fontWeight: fontWeight.normal2,
    fontSize: fontSize.large,
  },
  "& .drop-zone-text .choose-files": {
    fontWeight: fontWeight.normal2,
    fontSize: fontSize.large,
    color: colors.blue01,
    textDecoration: "underline",
  },
  "& .drop-zone-text .supported-formats": {
    fontWeight: fontWeight.normal1,
    fontSize: fontSize.small,
  },
  "& .failed-upload-status": {
    marginTop: "24px",
    marginBottom: "16px",
    // fontFamily: "Inter",
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .success-upload-status": {
    marginTop: "16px",
    marginBottom: "16px",
    // fontFamily: "Inter",
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  }

});

const FormField = styled("div")({
  flexGrow: 1,
});

const UploadImages = () => {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
  });
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [, { uploadFiles, deleteDocument }] = useDocument()
  const { getValues, setError, clearErrors } = methods;
  const [agencyId, setAgencyId] = useState("");

  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
  const [hasSuccessUpload, setHasSuccessUpload] = useState<boolean>(false);
  const [hasFailedUpload, setHasFailedUpload] = useState<boolean>(false);

  const isProgressCompletedRef = useRef<boolean>(false);
  const successUploadFilesRef = useRef<FileUploadStatus[]>([]);
  const failedUploadFilesRef = useRef<FileUploadStatus[]>([]);


  // control show progress 
  const [, { setBypassDefaultLoader, setPercentageComplete }] = useProcessStatusTracking();
  const [openProgressDialog, setOpenProgressDialog] = React.useState<boolean>(false);
  const [runProgress, setRunProgress] = React.useState<boolean>(false);
  const [progressRequestId, setProgressRequestId] = React.useState<string>("");

  // show delete confirmation
  const DEFAULT_MESSAGE = "Delete";
  const [isConfirmatinDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const tobeDeletedDocumentId = useRef<number | undefined>(undefined);

  const handleOnCloseProgressDialog = async () => {
    // -- set progress status
    isProgressCompletedRef.current = true;
    if (uploadCompleted) {
      setOpenProgressDialog(false);
      setRunProgress(false);
      setProgressRequestId("");
    }
  };

  const handleAgencyChange = (agency: Agency) => {
    setAgencyId(agency?.id);
  }

  const handleOnDelete = async (documentId: number) => {
    tobeDeletedDocumentId.current = documentId;
    const tobeDeletedFile = successUploadFilesRef.current?.find((f) => f.documentId === documentId);
    setConfirmationMessage(`${DEFAULT_MESSAGE} '${tobeDeletedFile?.documentOriginalFileName}'?`);
    setConfirmationDialogOpen(true);
  }

  const deleteUploadedImage = async () => {
    if (tobeDeletedDocumentId.current) {
      await deleteDocument(tobeDeletedDocumentId.current);
      // remove from the upload status list
      successUploadFilesRef.current = successUploadFilesRef.current?.filter((f) => f.documentId !== tobeDeletedDocumentId.current)
      setHasSuccessUpload(successUploadFilesRef.current?.length > 0);
    }
    setConfirmationDialogOpen(false);
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    tobeDeletedDocumentId.current = undefined;
  }

  const handleDialogCancel = () => {
    setConfirmationDialogOpen(false);
    tobeDeletedDocumentId.current = undefined;
  };

  const handleOnUpload = async (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
    const agency = getValues("agency");

    if (agency?.id) {
      await runUploadFiles(acceptedFiles, fileRejections);
    }
    else {
      setError(
        "agency",
        {
          type: "required",
          message: "Required",
        },
        { shouldFocus: false },
      );
    }

  }

  useEffect(() => {
    if (agencyId) {
      clearErrors("agency");
      setTimeout(() => {
        (document.activeElement as HTMLElement).blur();
      }, 100);
    }
  }, [agencyId])


  const runUploadFiles = async (acceptedfiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
    // -- set upload status
    setUploadCompleted(false);
    isProgressCompletedRef.current = false;

    // -- show progress dialog
    const progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    const agency = getValues("agency");
    let fileAdditionalInfos: FileUploadAdditionalInfo[] = [];

    const formData = new FormData();
    formData.set("companyID", agency?.id || "");
    formData.set("companyLegacyID", agency?.legacyID || "");

    let index = 0;
    acceptedfiles?.forEach((file) => {
      formData.append("files", file, file.name);
      fileAdditionalInfos.push({
        index: index,
        filePath: file.path || "",
        fileName: file.name,
        fileSize: file.size,
        errorMessage: "",
        isValidFile: true,
      })
      index++;
    })

    rejectedFiles?.forEach((rejectedFile, i) => {
      //formData.append("files", rejectedFile.file, rejectedFile.file.name); // no need to send the binary
      fileAdditionalInfos.push({
        index: index,
        filePath: "",  // not available
        fileName: rejectedFile.file.name,
        fileSize: rejectedFile.file.size,
        errorMessage: getFormattedErrorMessage(rejectedFile.errors),
        isValidFile: false,
      })
      index++;
    })

    formData.append("fileUploadAdditionalInfo", JSON.stringify(fileAdditionalInfos));

    const data = await uploadFiles(progressRequestId, formData);
    // mapResponseData(data);
    const fileUploadStatuses = mapFileUploaResponse(data);

    const hasSuccess = fileUploadStatuses?.some((f) => f.isUploadSuccess);
    const hasFailed = fileUploadStatuses?.some((f) => !f.isUploadSuccess);
    setHasSuccessUpload(hasSuccess);
    setHasFailedUpload(hasFailed);

    successUploadFilesRef.current = fileUploadStatuses?.filter((f) => f.isUploadSuccess);
    failedUploadFilesRef.current = fileUploadStatuses?.filter((f) => !f.isUploadSuccess);

    setPercentageComplete(progressRequestId);
    setUploadCompleted(true);
  }

  useEffect(() => {
    // console.log("useEffect - uploadCompleted:", uploadCompleted, "isProgressCompletedRef:", isProgressCompletedRef.current, "openProgressDialog: ", openProgressDialog);
    //if (uploadCompleted && isProgressCompletedRef.current && openProgressDialog) {
    if (uploadCompleted && openProgressDialog) {
      handleOnCloseProgressDialog();
    }
  }, [uploadCompleted])

  return (
    <FormProvider {...methods}>
      <Section>
        <SectionTitle>
          <SectionTitleText>
            Drag and drop or browse your computer for policy images to upload to
            Stewart. An Agency must be selected before a policy can be uploaded.
          </SectionTitleText>
        </SectionTitle>
        <SectionPanel>
          <FormField className="agency">
            <AgencyField onChange={handleAgencyChange} setUploadDisabled={setUploadDisabled}></AgencyField>
          </FormField>
          <UploadDropZone disabled={uploadDisabled} handleOnUpload={handleOnUpload} />
          {hasFailedUpload && (
            <>
              <div className="failed-upload-status">
                Failed to Upload ({failedUploadFilesRef.current?.length})
              </div>
              <UploadStatusGrid
                uploadRequestId="failed-1"
                fileUploadStatuses={failedUploadFilesRef.current}
              />
            </>
          )}
          {hasSuccessUpload && (
            <>
              <div className="success-upload-status">
                Uploaded ({successUploadFilesRef.current?.length})
              </div>
              <UploadStatusGrid
                uploadRequestId="uploaded-1"
                fileUploadStatuses={successUploadFilesRef.current}
                handleOnDelete={handleOnDelete}
              />
            </>
          )}
        </SectionPanel>
        <ConfirmationDialogWithProgressbar
          autoClose={true}
          title="Please wait while uploading your images ..."
          closeButtonText="Done! Click to Continue"
          isOpen={openProgressDialog}
          onClose={handleOnCloseProgressDialog}
          requestId={progressRequestId}
          runProgress={runProgress}
          progressImageType={ProgressImageType.circular}
        />
        <ConfirmationDialogV3
          isOpen={isConfirmatinDialogOpen}
          confirmationMessage={confirmationMessage}
          cancelActionButton={true}
          onYes={() => deleteUploadedImage()}
          onNo={() => closeConfirmationDialog()}
          onCancel={handleDialogCancel}
          singleActionButtonText={"Delete"}
          buttonNoText={"Cancel"}
        />
      </Section>
    </FormProvider>
  );
};

export default UploadImages;