import { SCFile } from "entities/UIModel/SCFile";
import { v4 as uuidv4 } from "uuid";
import { getDefaultStandaloneEndorsement } from "./defaultStandaloneEndorsement";
import { 
  FileStatusType, 
  FileStatusTypeName,
} from "./enum";
import {
  getDefaultAdditionalPartyObject,
  getDefaultBuyerBorrowerObject,
  getDefaultLenderObject,
  getDefaultSellerObject,
} from "./parties";
import {
  getDefaultAalObject,
  getDefaultCplObject,
  getDefaultJacketObject,
  getDefaultPricingObject,
} from "./products";
import { getDefaultPropertyObject } from "./property";

const getFormikCpls = (cpls?: SCFile["cpls"]) => {
  const defaultCPL = getDefaultCplObject();
  return cpls && cpls.length > 0 ? cpls : [defaultCPL];
};

const getFormikAals = (aals?: SCFile["aALProducts"]) => {
  const defaultAAL = getDefaultAalObject();

  return aals && aals.length > 0 ? aals : [defaultAAL];
};

const getFormikCombinedQNAs = (combinedQNAs?: SCFile["combinedQNAs"]) => {
  return combinedQNAs && combinedQNAs.length > 0 ? combinedQNAs : [];
};

const getPricing = (pricing?: SCFile["pricing"]) => {
  return pricing || getDefaultPricingObject();
};

const getFormikFilePricingDetails = (
  filePricingDetails?: SCFile["filePricingDetails"]
) => {
  return filePricingDetails && filePricingDetails.length > 0
    ? filePricingDetails
    : [];
};

const getFormikPricingProducts = (
  pricingProducts?: SCFile["pricingProducts"]
) => {
  return pricingProducts && pricingProducts.length > 0 ? pricingProducts : [];
};

const getFormikJackets = (jackets?: SCFile["jackets"]) => {
  const emptyJacket = getDefaultJacketObject();
  if (emptyJacket.pricingDetail)
    emptyJacket.pricingDetail.isSimultaneous = false;

  return jackets && jackets.length > 0 ? jackets : [emptyJacket];
};

const getFormikStandaloneEndorsements = (
  standaloneEndorsements?: SCFile["standaloneEndorsements"]
) => {
  return standaloneEndorsements && standaloneEndorsements.length > 0
    ? standaloneEndorsements
    : [getDefaultStandaloneEndorsement()];
};

const getFormikProperties = (properties?: SCFile["properties"]) => {
  return properties && properties.length > 0
    ? properties
    : [getDefaultPropertyObject()];
};

const getFormikBuyerBorrowerParties = (
  buyerBorrowerParties?: SCFile["buyerBorrowerParties"]
) => {
  return buyerBorrowerParties && buyerBorrowerParties.length > 0
    ? buyerBorrowerParties
    : [getDefaultBuyerBorrowerObject()];
};

const getFormikSellerParties = (sellerParties?: SCFile["sellerParties"]) => {
  return sellerParties && sellerParties.length > 0
    ? sellerParties
    : [getDefaultSellerObject()];
};

const getFormikLenderParties = (lenderParties?: SCFile["lenderParties"]) => {
  return lenderParties && lenderParties.length > 0
    ? lenderParties
    : [getDefaultLenderObject()];
};

const getFormikAdditionalParties = (
  additionalParties?: SCFile["additionalParties"]
) => {
  return additionalParties && additionalParties.length > 0
    ? additionalParties
    : [getDefaultAdditionalPartyObject()];
};

const getFormikInitialValues = (
  isPopulatingExistingFile: SCFile["isPopulatingExistingFile"] = false,
  isLocked: SCFile["isLocked"] = 0,
  isReadOnly: SCFile["isReadOnly"] = false,
  fileId?: SCFile["id"],
  identityKey?: SCFile["identityKey"],
  integrationKey?: SCFile["integrationKey"],
  statusTypeCode?: SCFile["statusTypeCode"],
  statusTypeName?: SCFile["statusTypeName"],
  prevStatusTypeCode?: SCFile["prevStatusTypeCode"],
  fileNameNumber?: SCFile["fileNameNumber"],
  filePricingID?: SCFile["filePricingID"],
  transactionTypeCode?: SCFile["transactionTypeCode"],
  agency?: SCFile["agency"],
  agencyLocation?: SCFile["agencyLocation"],
  aALProducts?: SCFile["aALProducts"],
  cpls?: SCFile["cpls"],
  jackets?: SCFile["jackets"],
  standaloneEndorsements?: SCFile["standaloneEndorsements"],
  properties?: SCFile["properties"],
  buyerBorrowerParties?: SCFile["buyerBorrowerParties"],
  lenderParties?: SCFile["lenderParties"],
  additionalParties?: SCFile["additionalParties"],
  combinedQNAs?: SCFile["combinedQNAs"],
  filePricingDetails?: SCFile["filePricingDetails"],
  pricingProducts?: SCFile["pricingProducts"],  
  sellerParties?: SCFile["sellerParties"],
  fileActivites?: SCFile["fileActivities"],
  errorMessage?: SCFile["errorMessage"],
  pricing?: SCFile["pricing"],
  authorityLimit?: SCFile["authorityLimit"],
  legacyID?: SCFile["legacyID"],
  locationUniqueID?: SCFile["locationUniqueID"],
  altFileReferenceId?: SCFile["altFileReferenceId"],
  documentFileData?: SCFile["documentFileData"],
  originationSource?: SCFile["originationSource"],
): SCFile => {
  return {
    // FILE
    isFileDefault: true,
    // isTransTypeSelectedByUser is true when user selected a transaction type or 
    // a default transaction type was saved to the file (implied that user agreed with the default value) 
    isTransTypeSelectedByUser: ((fileId || 0) > 0 && (transactionTypeCode || '') !== '') ? true : false,
    errorMessage: errorMessage,
    id: fileId ? fileId : 0,
    isLocked: isLocked,
    isReadOnly: isReadOnly,
    identityKey: identityKey || 0,
    integrationKey: integrationKey || uuidv4(),
    fileNameNumber: fileNameNumber ? fileNameNumber : "",
    agency: agency ? agency : { id: "", name: "" },
    agencyLocation: agencyLocation
      ? agencyLocation
      : { id: "", name: "", isValidationRequired: false},
    agencyLocationOriginal: agencyLocation
    ? agencyLocation
    : { id: "", name: "", isValidationRequired: false},
    agencyLocationPrevious: agencyLocation
    ? agencyLocation
    : { id: "", name: "", isValidationRequired: false },
    statusTypeCode: statusTypeCode ? statusTypeCode : FileStatusType.Open,
    statusTypeName: statusTypeName ? statusTypeName : FileStatusTypeName.Open,
    prevStatusTypeCode: prevStatusTypeCode ? prevStatusTypeCode : "",
    transactionTypeCode: transactionTypeCode ? transactionTypeCode : "",
    filePricingID: filePricingID || 0,
    authorityLimit: authorityLimit,
    legacyID: legacyID,
    locationUniqueID: locationUniqueID,
    altFileReferenceId: altFileReferenceId,
    originationSource: originationSource,

    // PROPERTIES
    properties: getFormikProperties(properties),
    isPropertiesValidationRequired: false,
    isZipCodeValidationRequired: false,

    // PARTIES
    buyerBorrowerParties: getFormikBuyerBorrowerParties(buyerBorrowerParties),
    lenderParties: getFormikLenderParties(lenderParties),
    additionalParties: getFormikAdditionalParties(additionalParties),
    sellerParties: getFormikSellerParties(sellerParties),

    // PRODUCTS
    jackets: getFormikJackets(jackets),
    cpls: getFormikCpls(cpls),
    aALProducts: getFormikAals(aALProducts),
    standaloneEndorsements: getFormikStandaloneEndorsements(
      standaloneEndorsements
    ),
    combinedQNAs: getFormikCombinedQNAs(combinedQNAs),

    // PRICING
    pricing: getPricing(pricing),
    filePricingDetails: getFormikFilePricingDetails(filePricingDetails),
    pricingProducts: getFormikPricingProducts(pricingProducts),    
    updatedInitialPricingProducts: getFormikPricingProducts(pricingProducts),
    updatedInitialFilePricingDetail: getFormikFilePricingDetails(filePricingDetails),
    fileActivities: fileActivites || [],

    // ADDITINAL FLAGS
    isPopulatingExistingFile: isPopulatingExistingFile,
    hasIssuedProducts: false,
    hasIssuedJackets: false,
    hasUploadedPolicy: false,
    isTransactionTypeRequired: false,   

    //DocumentGeneration
    documentFileData: documentFileData,
  };
};

export {
  getFormikInitialValues,
  getFormikAdditionalParties,
  getFormikLenderParties,
  getFormikBuyerBorrowerParties,
  getFormikSellerParties,
  getFormikJackets,
  getFormikCpls,
  getFormikAals,
  getFormikStandaloneEndorsements,
  getFormikProperties,
};
