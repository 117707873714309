import {
  styled,
  TableCell,
} from "@mui/material";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.black,
  fontSize: fontSize.xlarge1,
  fontWeight: fontWeight.bold1,
  maxWidth: 170,
  overflow: "hidden",
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
  "&.normalPadding": {
    paddingBottom: padding.xsmall2,
    paddingTop: padding.small2,
  },
  "&.bigPadding": {
    paddingBottom: padding.large1,
    paddingTop: padding.large1,
  },
});

const ActualFeeTooltipCell = styled(TooltipCell)({
  "&.totalDueNoUnderline": {
    color: colors.grey11,
    display: "inline-block",
    fontSize: fontSize.xlarge1,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "&.totalDueWhiteUnderline": {
    borderBottom: "none !important",
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

type Props = {
  actualFeeTotal: string;
  hasIssuedProducts: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
};

export default function ActualFeeTotal({ actualFeeTotal, hasIssuedProducts, hasFileLockedWithUpdateablePricingItem }: Props) {

  const footerCellClassname = hasFileLockedWithUpdateablePricingItem
    ? "normalPadding"
    : "bigPadding";

  const totalClassname = !hasFileLockedWithUpdateablePricingItem
    ? "totalDueNoUnderline"
    : "totalDueWhiteUnderline";

  return (
    <StyledTableFooterCell
      align="right"
      className={footerCellClassname}
    >
      {hasIssuedProducts &&
          <ActualFeeTooltipCell
              className={totalClassname}
              title={actualFeeTotal}
          />
      }
    </StyledTableFooterCell>
  );
}
