import ComboBoxField from "controls/global/combo-box-field/ComboBoxInputField";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect,
} from "react";
import { 
  useFormContext, 
  useWatch 
} from "react-hook-form";
import { 
  useCompanyContext, 
  useCompanyContextActions 
} from "utils/context/CompanyContext";

type Props = {  
  name: string;  
  defaultPropertyState?: string;
  userStates?:  SelectFieldOption[];
};

const AgencyField = ({  name, defaultPropertyState, userStates}: Props ) => {
  const { setValue, getValues } = useFormContext();  
  const [stateField] = useWatch({
    name: ["userProfile.defaultPropertyState"],
  }); 
  const [{ agencies, agenciesByState }] = useCompanyContext();
  const [, { getAgencies, getAgenciesByState }] = useCompanyContextActions();  
  
  const [filteredAgencies, setFilteredAgencies] = React.useState<SelectFieldOption[]>([]);
  const [internalValue, setInternalValue] = React.useState<SelectFieldOption>({
    text: "",
    value: "",
  });

  const handleAgencyNameChange = (item: any) => {
    setInternalValue(item);    
    setValue(name, item.value);
  };

  const handleOnBlur = (eventArgs: any) => {        

    let currentInternalValue = "";
    if(typeof(internalValue) === "object"){
      currentInternalValue =internalValue?.value;
    } else {
      currentInternalValue = internalValue;
    }
    if(!currentInternalValue || !eventArgs?.target?.value) {
      setTimeout(() => {
        setValue(name, "");        
      }, 10);
      return;
    }    
    setValue(name, currentInternalValue);   
  };
    
  useEffect(() => {    
    if (filteredAgencies.length === 1) {
      setInternalValue({
        text: filteredAgencies[0].text,
        value: filteredAgencies[0].value
      });
      setValue(name, filteredAgencies[0].value);      
    }
  }, [filteredAgencies, name, setValue]);

  useEffect(() => {
    const defaultAgency = getValues(name);
    if(defaultAgency) {
      setInternalValue(defaultAgency);
    }    
  }, []);

  useEffect(() => {
    if (agencies?.length === 0){
      getAgencies()
    }      
  }, []); 

  useEffect(() => {    
    let currentStateValue = getValues('userProfile.defaultPropertyState') ||                              
                            stateField;    
    if(currentStateValue) {
      const propState = userStates?.find(s => s.value === currentStateValue);     
      if(propState?.text) {      
        getAgenciesByState(propState.text);
      }
    } else if(agencies?.length > 0){
      setFilteredAgencies(agencies);
    } else {
      getAgencies();
    }
  }, [    
    defaultPropertyState,
    stateField,
    userStates
  ]);
  
  useEffect(() => {
    if(agencies?.length > 0){
      setFilteredAgencies(agencies);
    }
  },[ agencies ]);

  useEffect(() => {
    if(agenciesByState?.agencies?.length === 0 ||
        !agenciesByState?.state) return;

    let currentStateValue = getValues('userProfile.defaultPropertyState') ||                              
                            stateField;
    if(currentStateValue) {
      const propState = userStates?.find(s => s.value === currentStateValue);     
      if(propState?.text && agenciesByState?.state === propState?.text) {      
        setFilteredAgencies(agenciesByState.agencies);
      }
    }    
  },[
    agenciesByState
  ]);

  return (
    <ComboBoxField
        label={"Agency Name"}        
        options={filteredAgencies}
        {...{ name }}
        onChange={(_: any, selectedValue: any) => {
          handleAgencyNameChange(selectedValue);
        }}
        roundedCorner={true}
        onBlur={handleOnBlur}
        freeSolo={true} 
        disableClearable={true}
        controlTooltipOnMouseEvents={true}
      />
  );
};

export default AgencyField;
