import {
  styled,
  TableCell,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import LightTooltip from "controls/global/light-tooltip";
import React from "react";
import {
  borderSize,
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";
import {
  capitalize,
  formatDate,
} from "utils/shared";

type Props = {
  hidden: boolean;
  endorsementID: number | undefined;
  statusTypeCode: string | undefined;
  voidDate: Date | null | undefined;
};

const StatusTableCell = styled(TableCell)({
  borderRight: `${borderSize.xsmall} solid ${colors.grey04}`,
  padding: `${padding.zero} !important`,
  width: 80,
});

const StyleCellText = styled(Typography)({
  color: colors.grey06,
  fontSize: fontSize.xlarge,
  padding: `${padding.small2} 0px ${padding.xsmall2} ${padding.small2}`,
});

export default function EndorsementStatusCell({
  hidden,
  endorsementID,
  statusTypeCode,
  voidDate,
}: Props) {

  const status =
    !endorsementID || endorsementID === 0 ? "" : capitalize(statusTypeCode);

  if (voidDate) {
    return (
      <LightTooltip
        title={`Voided ${formatDate(voidDate)}`}
        placement="top"
        arrow
      >
        <StatusTableCell hidden={hidden}>
          <StyleCellText>{status}</StyleCellText>
        </StatusTableCell>
      </LightTooltip>
    );
  }

  return (
    <StatusTableCell hidden={hidden}>
      <StyleCellText>{status}</StyleCellText>
    </StatusTableCell>
  );
}
