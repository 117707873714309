import React from "react";
import TextInputField from "controls/global/text-input-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";

interface AgencyIdFieldProps {
  selectedAgency: SelectFieldOption | null;
  setValue: (name: string, value: any) => void;
}

export default function AgencyIdField({ selectedAgency, setValue }: AgencyIdFieldProps) {
  return (
    <TextInputField
      name="agencyID"
      defaultValue={selectedAgency ? selectedAgency.legacyID : ""}
      placeholder="Agency ID"
      label="Agency ID"
    />
  );
}
