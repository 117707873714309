import React, {
  ReactNode,
} from "react";

type Props = {
  children?: ReactNode;
};

type SignatureDefaultMode = "ADD" | "EDIT" | undefined;

type State = {
  mode: SignatureDefaultMode;
  isOpen: boolean;
  saveEnabled: boolean;
};

type ActionTypes = "ADD" | "EDIT" | "CANCEL" | "SAVE";

type Action = {
  type: ActionTypes;
  saveEnabled?: boolean;
};

type Context = {
  mode: SignatureDefaultMode;
  isOpen: boolean;
  saveEnabled: boolean;
  showAdd: () => void,
  showEdit: () => void,
  updateSaveEnabled: (saveEnabled: boolean) => void,
  cancelDialog: () => void,
};

const initialState: State = {
  mode: "ADD",
  isOpen: false,
  saveEnabled: false,
};

const SignatureDefaultContext = React.createContext<Context | null>(null);//>(initialState);

const signatureReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD":
      return {
        ...state,
        mode: "ADD",
        isOpen: true,
      };
    case "EDIT":
      return {
        ...state,
        mode: "EDIT",
        isOpen: true,
      };
    case "SAVE":
      return {
        ...state,
        saveEnabled: action.saveEnabled || false,
      };
    case "CANCEL":
      return {
        mode: undefined,
        isOpen: false,
        saveEnabled: false,
      };
    default:
      throw new Error(`Not supported action type: ${action.type}`);
  }
};

const SignatureDefaultDialogProvider = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(signatureReducer, initialState);

  const value = {
    mode: state.mode,
    isOpen: state.isOpen,
    saveEnabled: state.saveEnabled,
    showAdd: () => {
      dispatch({ type: "ADD" });
    },
    showEdit: () => {
      dispatch({ type: "EDIT" });
    },
    updateSaveEnabled: (saveEnabled: boolean) => {
      dispatch({ type: "SAVE", saveEnabled });
    },
    cancelDialog: () => {
      dispatch({ type: "CANCEL" });
    },
  };

  return (
    <SignatureDefaultContext.Provider value={value}>
      {children}
    </SignatureDefaultContext.Provider>
  );
};

const useSignatureDefaultDialogProvider = () => {
  const context = React.useContext(SignatureDefaultContext);
  if (!context) {
    throw new Error(
      "useSignatureDefaultDialogProvider must be used within a <SignatureDefaultDialogProvider>"
    );
  }

  return context;
};

export { SignatureDefaultDialogProvider, useSignatureDefaultDialogProvider };
