import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Typography,
  styled,
} from "@mui/material";
import parser, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import React, {
  useState,
} from "react";
import {
  fontSize,
  fontWeight,
} from "theme/defaultStyle";
import cssClasses from "../../HelpCenter.module.css";
import { HelpCenterGetFaqItemsData } from "./FAQ";

const TypographyQuestion = styled(Typography)({
  fontWeight: fontWeight.bold2,
  fontSize: fontSize.xlarge0,
});

const TypographyAnswer = styled(Typography)({
  fontSize: fontSize.medium,
});

const FaqItem = ({ Question, Answer }: HelpCenterGetFaqItemsData) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const replaceHrefTarget: HTMLReactParserOptions = {
    replace: (domNode) => {
      let element = domNode as Element;
      if (element.attribs && element.name === "a") {
        const props = attributesToProps(element.attribs);
        return (
          <a {...props} target="_blank">
            {domToReact(element.children)}
          </a>
        );
      }
      return {};
    },
  };

  return (
    <button
      className={cssClasses.questionItemContainer}
      onClick={() => {
        setIsShown(!isShown);
      }}
    >
      <Box className={cssClasses.QAContainer}>
        <TypographyQuestion className={cssClasses.question}>
          {Question}
        </TypographyQuestion>

        <div>
          {isShown ? (
            <KeyboardArrowDownIcon
              className={cssClasses.keyboardArrowDownIcon}
            />
          ) : (
            <KeyboardArrowDownIcon className={cssClasses.keyboardArrowUpIcon} />
          )}
        </div>
      </Box>

      {isShown && (
        <>
          <TypographyAnswer className={cssClasses.answer}>
            {parser(Answer, replaceHrefTarget)}
          </TypographyAnswer>
        </>
      )}
    </button>
  );
};

export default FaqItem;
