import React from "react";
import {
  styled,
  TableCell,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import ActualFeeNewTotal from "./new-totals-all/ActualFeeNewTotal";
import RiskRateNewTotal from "./new-totals-all/RiskRateNewTotal";
import AgentRetentionNewTotal from "./new-totals-all/AgentRetentionNewTotal";
import TotalDueNewTotal from "./new-totals-all/TotalDueNewTotal";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  isPricingSectionUpdated: boolean;
};

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.black,
  fontSize: fontSize.xlarge1,
  fontWeight: fontWeight.bold1,
  paddingRight: padding.large2,
  textOverFlow: "ellipses",
  overflow: "hidden",
});

const NewTotalName = styled("div")({
  display: "inline-block",
  fontSize: `${fontSize.xlarge1} !important`,
  margin: `0px ${margin.xsmall1} 0px 0px`,
  maxWidth: "100%",
  overflow: "hidden",
  paddingBottom: padding.xsmall2,
  textOverflow: "ellipsis",
  width: "250px !important",
});

const NewTotalTableRow = styled(TableRow)({
  backgroundColor: colors.white,
});

export default function NewTotalAll({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  isPricingSectionUpdated,
}: Props) {
  const { getPricingNewTotal } = usePricingCalculator();
  const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);

  return (
    <NewTotalTableRow>
      <StyledTableFooterCell style={{ paddingLeft: "0" }}>
        <NewTotalName>
          New Totals
        </NewTotalName>
      </StyledTableFooterCell>
      {displayFieldIcon && <StyledTableFooterCell />}
      {displayLiabilityColumn && <StyledTableFooterCell />}
      {displayRateTypeColumn && <StyledTableFooterCell />}
      {displayReissueColumn && <StyledTableFooterCell />}
      {displayTransCodeColumn && <StyledTableFooterCell />}
      <ActualFeeNewTotal hasIssuedProducts={hasIssuedProducts} newTotalActualDue={pricingNewTotal.newTotalActualDue} />
      {displayRiskRateColumn ? (
        <RiskRateNewTotal hasIssuedProducts={hasIssuedProducts} newTotalRiskRate={pricingNewTotal.newTotalRiskRate} />
      ) : null}
      <AgentRetentionNewTotal hasIssuedProducts={hasIssuedProducts} newTotalAgentRetention={pricingNewTotal.newTotalAgentRetention}  />
      <TotalDueNewTotal hasIssuedProducts={hasIssuedProducts} newTotalTotalDue={pricingNewTotal.newTotalTotalDue} />
    </NewTotalTableRow>
  );
}
