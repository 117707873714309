import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import { convertToNumber } from "utils/shared";

function getPendingPayByCriteria(
  paymentPayFile: PendingPayFile,
  filters: PendingPayCriteriaSearch
) {
  let isMatch = true;
  if (filters.clientFileId)
    isMatch =
      isMatch &&
      paymentPayFile.clientFileID
        .toLowerCase()
        .includes(filters.clientFileId.toLowerCase());

  if (filters.locationLegacyID)
    isMatch =
      isMatch &&
      paymentPayFile.locationLegacyIDs
        .toLowerCase()
        .includes(filters.locationLegacyID.toLowerCase());

  if (filters.propertyAddress)
    isMatch =
      isMatch &&
      paymentPayFile.propertyAddresses
        .toLowerCase()
        .includes(filters.propertyAddress.toLowerCase());

  if (filters.lastBilledBy)
    isMatch =
      isMatch &&
      paymentPayFile.lastBilledBy
        .toLowerCase()
        .includes(filters.lastBilledBy.toLowerCase());

  if (filters.orderNumber)
    isMatch =
      isMatch &&
      paymentPayFile.orderNumber ===
      filters.orderNumber;

  if (filters.reportOptionTypeCode) {
    const options = filters.reportOptionTypeCode.split("|");
    isMatch =
      isMatch &&
      options.includes(paymentPayFile.reportOptionTypeCode);
  }

  if (filters.lastBilledDate)
    isMatch =
      isMatch &&
      paymentPayFile.lastBilledDate?.toDateString() ===
      filters.lastBilledDate.toDateString();

  if (filters.totalBilledActualRiskRate)
    isMatch =
      isMatch &&
      convertToNumber(paymentPayFile.totalBilledActualRiskRate) ===
      convertToNumber(filters.totalBilledActualRiskRate);

  if (filters.totalBilledActualFee)
    isMatch =
      isMatch &&
      convertToNumber(paymentPayFile.totalBilledActualFee) ===
      convertToNumber(filters.totalBilledActualFee);

  if (filters.totalBilledAmountDue) {
    isMatch =
      isMatch &&
      convertToNumber(paymentPayFile.totalBilledAmountDue) ===
      convertToNumber(filters.totalBilledAmountDue);
  }

  return isMatch;
}

export { getPendingPayByCriteria };
