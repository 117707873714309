import {
 styled,
} from "@mui/material";
import ProgressbarDialog from "controls/global/dialogs/confirmation-dialog-with-progressbar/ProgressbarDialog";
import CommonStewartDialog from "controls/global/dialogs/stewart-dialog/CommonStewartDialog";
import React from "react";
import { usePendingPay } from "utils/context/PendingPayContext";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import PendingPayFooterTotal from "../pendingpay-footer-total";
import PendingPayFooterAction from "../pendingpay-footer-action";
import { padding } from "theme/defaultStyle";

type FooterProps = {
  rows: PendingPayFile[];
  onlyShowSelectedFiles: boolean;
  page: number;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onOnlySelectedFilesChange?: (value: boolean) => void;
};

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: padding.large2,
  width: "100%",
});

const PendingPayFooter = ({
  rows,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: FooterProps) => {
  const [{ isAdvSearchOpen }] = usePendingPay();

  const noSelectedRows = !rows.length || isAdvSearchOpen;

  return (
    <>
      <Container >
        <PendingPayFooterAction
          disabled={noSelectedRows}
          onlyShowSelectedFiles={onlyShowSelectedFiles}
          onOnlySelectedFilesChange={(value: boolean) =>
            onOnlySelectedFilesChange?.(value)}
        />
        <PendingPayFooterTotal rows={rows} />
        </Container >   
      <CommonStewartDialog />
      <ProgressbarDialog />     
    </>
  );
};

export default PendingPayFooter;
