import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Controller, useFormContext } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "theme/defaultStyle";

interface CustomTextFieldProps {
  name: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  maxLength?: number; // Define maxLength prop
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({ name, defaultValue, onChange, maxLength, ...props }) => {
  const { control } = useFormContext();

  const handleOnChange = (e: React.ChangeEvent<any>, onChangeFormHandler?: any) => {
    onChangeFormHandler && onChangeFormHandler(e);
    onChange && onChange(e);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, onBlur, ref }, fieldState }) => (
        <TextField
          {...props}
          error={Boolean(fieldState.error)}
          onChange={(event: React.ChangeEvent<any>) => handleOnChange(event, onChange)}
          onBlur={onBlur}
          InputProps={{
            endAdornment: fieldState.error ? (
              <InputAdornment position="end">
                <Tooltip
                  title={fieldState.error.message}
                  arrow
                >
                  <IconButton
                    aria-label="error"
                    edge="end"
                    style={{ color: colors.red02 }}
                  >
                    <ErrorOutlineIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ) : null,
          }}
          value={value}
          inputProps={{ maxLength }} // Set maxLength here
          style={{minWidth:"75px",width:"100%"}}
        />
      )}
    />
  );
};

export default CustomTextField;
