import {
  useAccount,
  useMsal,
} from "@azure/msal-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import useDocumentStore from "utils/context/ImageArchiveContext";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, {
  useEffect,
  useState,
} from "react";
import theme from "theme/default";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import { 
  menuIds, 
  menuNames, 
} from "pages/home/menu";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

interface IProps {
  isValidLogin: boolean;
  saveFileChangeRef?: any;
}

const StyledPopper = styled(Popper)({
  alignItems: "center",
  background: gradients.blueGradient03,
  borderRadius: borderRadius.xlarge,
  color: colors.white,
  display: "flex",
  gap: gaps.xsmall2,
  minWidth: "160px",
  overflow: "inherit",
  padding: padding.zero,
  top: "28px !important",
  zIndex: zIndex.high1,
  "& .MuiPaper-root ": {
    borderRadius: borderRadius.xlarge,
    "&:before": {
      border: "solid transparent",
      borderBottomColor: colors.blue01,
      borderWidth: borderSize.xxxlarge,
      bottom: "100%",
      content: `""`,
      height: "0",
      left: "50%",
      marginLeft: `-${margin.large}`,
      pointerEvents: "none",
      position: "absolute",
      width: "0",
    },
    "& .MuiList-root": {
      display: "flex",
      background: gradients.blueGradient03,
      flexDirection: "column",
      borderRadius: borderRadius.xlarge,
      gap: gaps.xsmall2,
      "& .MuiMenuItem-root": {
        borderRadius: borderRadius.small,
        color: colors.white,
        margin: "auto",
        paddingBottom: padding.large1,
        paddingLeft: padding.large1,
        paddingRight: padding.large1,
        paddingTop: padding.large1,
        width: "90%",
        // "&:hover": {
        //   background: gradients.blueGradient03,
        //   color: colors.white,
        // },
      },
    },
  },
});

const UsernameButton = styled(Button)({
  color: colors.white,
  display: "flex",
  flexDirection: "row",
  gap: gaps.xsmall2,
  minWidth: iconSize.small1,
  padding: padding.zero,
  "& .username-container": {
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal1,
    textTransform: "initial",
  },
  "& .MuiSvgIcon-root": {
    fontSize: iconSize.medium,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const EditProfileLink = ({ isValidLogin, saveFileChangeRef }: IProps) => {
  // const [ { isEditMode  }] = useDocumentStore();
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);

  const { featureConfig: { enableSignatures, enableHelpCenter } } = useConfigContext();

  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { redirect } = useNavRedirect();

  const username = account && account.name ? account?.name.split(" ") : [];
  // TODO: Change this to use a named breakpoint instead of a number once the new layout breakpoints are determined.
  const showUserName = useMediaQuery(theme.breakpoints.up(1440));
  const isWideEnoughToUnconditionallyShowIcon = useMediaQuery(theme.breakpoints.up("lg"));
  const longUserName = account?.name ? account?.name.length > 18 : false;
  const showAccountIcon = isWideEnoughToUnconditionallyShowIcon || (!showUserName || (showUserName && !longUserName));
  const [, { clearUserSelectedAgency }] = useGlobalAccess();
  const [{ profileSettings }] = useProfileSettingsCache();
  const isUserProfileSet: boolean = profileSettings.isUserProfileSet || false;
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasManageSignatureAccess= permissionCodes?.includes(PERMISSIONS.SIGNATURES_MANAGE);
  const hasHelpCenterAccess = permissionCodes?.includes(PERMISSIONS.HELP_ACCESS);

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopperOpen(false);
    }
  };

  const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
    // if(!isEditMode) {
      setPopperAnchorEl(event?.currentTarget);
      setPopperOpen((previousOpen) => !previousOpen);
    // }
  };

  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    setPopperOpen(false);
    instance.logout();
    clearUserSelectedAgency();
  };

  const handleProfile = () => {
    redirect("/profile-settings", saveFileChangeRef);
    setPopperOpen(false);
  };

  const handleSignature = () => {
    redirect("/manage-signatures", saveFileChangeRef);
    setPopperOpen(false);
  };

  const handleHelpCenter = () => {
    redirect("/help-center", saveFileChangeRef);
    setPopperOpen(false);
  };

  useEffect(() => {
    popperAnchorEl?.focus();
  }, [popperOpen]);

  return (
    <>
      <UsernameButton
        onClick={handlePopperClick}
        id="profile"
      >
        {showAccountIcon && <AccountCircleIcon />}
        {showUserName && (
          <div className="username-container">
            {username && username.length > 0 ? username[0] : ""} {username && username.length > 1 ? username[1] : ""}
          </div>
        )}
        {!popperOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </UsernameButton>
      <StyledPopper
        open={popperOpen}
        anchorEl={popperAnchorEl}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              elevation={3}
              style={{ width: "100%" }}
            >
              <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                <MenuList
                  autoFocusItem={popperOpen}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {!!isValidLogin && isUserProfileSet && (
                    <MenuItem
                      data-menu-id={menuIds.menuProfileSettings}
                      onClick={handleProfile}
                    >
                      {menuNames.lblMenuProfileSettings}
                    </MenuItem>
                  )}
                  {enableSignatures && !!isValidLogin && isUserProfileSet && hasManageSignatureAccess && (
                    <MenuItem
                      data-menu-id={menuIds.menuManageSignatures}
                      onClick={handleSignature}
                    >
                      {menuNames.lblMenuManageSignatures}
                    </MenuItem>
                  )}
                  {enableHelpCenter && !!isValidLogin && isUserProfileSet && hasHelpCenterAccess && (
                    <MenuItem
                      data-menu-id={menuIds.menuHelpCenter}
                      onClick={handleHelpCenter}
                    >
                      {menuNames.lblMenuHelpCenter}
                    </MenuItem>
                  )}
                  <MenuItem
                    data-menu-id={menuIds.menuLogout}
                    onClick={handleLogout}
                  >
                    {menuNames.lblMenuLogout}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
};

export default EditProfileLink;
