import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import React from "react";
import {
  borderSize,
  defaultStyle,
  margin,
} from "theme/defaultStyle";

type Props = {
  fullName: string;
  showColumn: boolean;
  cellCss: string;
  isShortForm: boolean;
  disabled: boolean;
  onChecked: (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const EndorsementCheckboxInputField = styled(CheckboxInputField)({
  "& .MuiIconButton-label > span": {
    border: `${borderSize.xsmall} solid ${defaultStyle.checkbox.borderColor}`,
    borderRadius: defaultStyle.checkbox.borderRadius,
    "&::before": {
      marginLeft: `-${margin.xxsmall2}`,
      marginTop: `-${margin.xxsmall2}`,
    },
  },
});

export default function EndorsementCheckboxCell({
  fullName,
  showColumn,
  cellCss,
  isShortForm,
  disabled,
  onChecked,
}: Props) {

  if (!showColumn) return null;
  if (!isShortForm) {
    return <TableCell classes={{ sizeSmall: cellCss }} />;
  }

  return (
    <TableCell classes={{ sizeSmall: cellCss }} width="55px" align="center">
      <EndorsementCheckboxInputField
        label=""
        name={fullName}
        onChange={onChecked}
        disabled={disabled}
        defaultValue={false}
        styledWrapper={false}
      />
    </TableCell>
  );
}
