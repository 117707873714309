import React from "react";
import StewartInput from "controls/global/stewart-input";
import InputSearchAdornment from "pages/myFiles/components/input-search/InputSearchAdornment";

interface Props<T> {
  handleFilterChange: (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => void;
  value: any;
}

const KeywordSearchField = <T extends object>({ handleFilterChange, value }: Props<T>) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? event.target.value : undefined;
    const startSearching = newValue ? newValue.length > 2 : true;
    handleFilterChange("keyword" as keyof T, newValue, startSearching);
  };

  return (
    <StewartInput
      data-test="keywordSearch"
      fullWidth
      placeholder="Keyword Search"
      InputProps={{
        disableUnderline: true,
        endAdornment: <InputSearchAdornment />,
      }}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      value={value}
    />
  );
};

export default KeywordSearchField;
