import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Dialog,
  IconButton,
  styled,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import * as UIModel from "entities/UIModel";
import { Reports } from "entities/UIModel";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
  gaps,
} from "theme/defaultStyle";
import PdfTabPanel from "./PdfTabPanel";
import ShowPdf from "./ShowPdf";
import HtmlDiv from "../html-div/HtmlDiv";

const StyledDialog = styled(Dialog)({
  "& div.MuiPaper-root": {
    height: "80%",
    maxWidth: "none",
    width: "80%",
  },
});

const AppBarParent = styled("div")({
  flexGrow: 1,
});

const StyledTypography = styled(Typography)({
  flexGrow: 1,
  fontSize: fontSize.base,
  fontWeight: 600,
  lineHeight: 1.6,
  marginBottom: "0px",
});

const StyledTabs = styled(Tabs)({
  backgroundColor: "white",
  gap: gaps.xsmall1,
  height: "45px",
});

const StyledTab = styled(Tab)({
  backgroundColor: colors.grey03,
  borderRadius: "16px 16px 0px 0px",
  height: "45px",
  maxWidth: "100%",
  opacity: "1",
  padding: "6px 20px",
});

const PDFContainer = styled("div")({
  display: "block",
  height: "100%",
  position: "relative",
  width: "100%",
});

const StyledTabLabel = styled(Typography)({
  fontSize: fontSize.base,
  textTransform: "none",
});

type Props = {
  isOpen: boolean;
  onClose: React.MouseEventHandler<HTMLElement>;
  pdfDocuments: Array<UIModel.PdfDocument>;
  isReports?: boolean;
  reports?: Reports
};

const PdfViewer = ({ isOpen = false, onClose, pdfDocuments, isReports = false, reports }: Props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(1);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (pdfDocuments.length > 0) {
      setValue(pdfDocuments[0].documentId);
    }
  }, [pdfDocuments]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const isSinglePdf = pdfDocuments.length === 1 ? true : false;
  const singlePdfFileName = isSinglePdf ? pdfDocuments[0].fileName : "";
  const isMultiplePdfs = pdfDocuments.length > 1 ? true : false;

  return (
    <StyledDialog
      disableEscapeKeyDown={true}
      open={open}
    >
      <AppBarParent>
        <AppBar position="static">
          <Toolbar>
            <StyledTypography>
              <HtmlDiv text={singlePdfFileName} width="calc(50vw)" />
            </StyledTypography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </AppBarParent>
      {isSinglePdf ? (
        <PDFContainer id="singlePdfContainer">
          <ShowPdf pdf={pdfDocuments[0]} isReports={isReports} reports={reports} />
        </PDFContainer>
      ) : null}
      {isMultiplePdfs ? (
        <PDFContainer id="multiPdfsViewer">
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {pdfDocuments?.map((pdf: UIModel.PdfDocument) => (
              <StyledTab
                value={pdf.documentId}
                label={
                  <StyledTabLabel>
                    {pdf.fileName}
                  </StyledTabLabel>
                }
                key={pdf.documentId}
              />
            ))}
          </StyledTabs>
          {pdfDocuments?.map((pdf: UIModel.PdfDocument) => (
            <PdfTabPanel
              value={value}
              index={pdf.documentId}
              key={pdf.documentId}
            >
              <ShowPdf pdf={pdf} />
            </PdfTabPanel>
          ))}
        </PDFContainer>
      ) : null}
    </StyledDialog>
  );
};

export default PdfViewer;
