import { styled } from "@mui/material";
import { LayoutBodyContext } from "layout/LayoutBodyContext";
import { debounce } from "lodash";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import {
  colors,
  margin,
  padding,
} from "theme/defaultStyle";
import UpArrow from "theme/icons/UpArrow";
import useResizeObserver from "use-resize-observer";

interface Props {
  onScrollToTop?: any;
  refreshSize?: any;
}

const StyledSpan = styled("span")({
  color: colors.blue01,
  display: "inline-block",
  marginLeft: `${margin.large}`,
});

const Wrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "12px",
  userSelect: "none",
});

const PointerDiv = styled("div")({
  cursor: "pointer",
  paddingLeft: padding.small,
  paddingRight: padding.small,
});

const useElementSize = (bodyRef: React.RefObject<HTMLDivElement> | null) => {
  const resizeDebounceTime = 250;
  const [size, setSize] = useState({
    scrollHeight: 0,
    visibleHeight: 0,
  });

  const refreshSizes = () => {
    setSize({
      scrollHeight: bodyRef?.current?.scrollHeight ?? 0,
      visibleHeight: window.innerHeight - (bodyRef?.current?.offsetTop ?? 0),
    });
  };
  const debouncedRefreshSizes = debounce(refreshSizes, resizeDebounceTime);

  useResizeObserver<HTMLDivElement>({
    box: "content-box",
    onResize: () => debouncedRefreshSizes(),
    ref: bodyRef,
  });

  useEffect(() => {
    window.addEventListener("resize", debouncedRefreshSizes);
    debouncedRefreshSizes();
    return () => window.removeEventListener("resize", debouncedRefreshSizes);
  }, []);

  return { size, debouncedRefreshSizes };
};

/**
 * @param onScrollToTop - callback function that is called when scrolling to top (useful for resetting NavBar)
 * @param refreshSize - any object that can change in order to force a refresh (useful for isLoading when data appears after container renders)
 */
export default function ScrollToTopArrow({ onScrollToTop = null, refreshSize = null }: Props) {
  const bodyRef = useContext(LayoutBodyContext);
  const { size, debouncedRefreshSizes } = useElementSize(bodyRef);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);

  const handleScrollToTop = () => {
    setScrollToTop(!scrollToTop);
    document?.documentElement?.scrollTo({ top: 0, behavior: "smooth" });
    onScrollToTop && onScrollToTop();
  };

  useEffect(() => {
    const scrollMultiplier = 2;
    const scrollHeightToShow = size.visibleHeight * scrollMultiplier;
    const show = size && scrollHeightToShow > 0 && size.scrollHeight >= scrollHeightToShow;
    setShowScrollToTop(show);
  }, [size]);

  /**
   * Forces a size refresh (usefull when content dynmically updates without updating scrollHeight)
   */
  useEffect(() => {
    debouncedRefreshSizes();
  }, [refreshSize]);

  return (
    <>
      {showScrollToTop &&
        <Wrapper>
          <PointerDiv onClick={handleScrollToTop}>
            <UpArrow />
            <StyledSpan>Back to Top</StyledSpan>
          </PointerDiv>
        </Wrapper>
      }
    </>
  );
}
