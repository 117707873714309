import React from "react";
import AdditionalPartiesAutocomplete from "../AdditionalPartiesAutocomplete";

type Props = {
  schema: string;
  disabled: boolean;
};

export default function City({ schema, disabled }: Props) {
  return (
    <AdditionalPartiesAutocomplete
      label="City/Town/Municipality"
      name="city"
      schema={schema}
      searchProp="city"
      disabled={disabled}
    />
  );
}
