import {
  Box,
  styled,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import ActionButton from "controls/global/action-button";
import ReportContentMessage from "controls/global/dialogs/stewart-dialog/dialog-content/ReportContentMessage";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";
import React, {
  ReactElement,
  ReactNode,
} from "react";
import { margin } from "theme/defaultStyle";
import { useDialog } from "utils/context/DialogContext";
import { usePayment } from "utils/context/PaymentContext";
import { usePendingPay } from "utils/context/PendingPayContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import { v4 as uuidv4 } from "uuid";
import AgencySupportCenter from "./AgencySupportCenter";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

const MessageSubmittedBox = styled(Box)({
  marginBottom: margin.xlarge1,
  textAlign: "center",
});

type Props = {
  selectedItems: PendingPayFile[];
  disabled?: boolean;
};

const UndoReport = ({ selectedItems, disabled }: Props): ReactElement => {
  const [, { openDialog, onModalCancel }] = useDialog();
  const [, { setRefresh, clearSelectedItems }] = usePendingPay();
  const [, { undoReportPricing }] = usePayment();
  const [, { openProgressbar }] = useProgressbar();

  const mainText = () => {
    return `Are you sure you want to reopen ${selectedItems.length === 1 ? 'this file' : 'these files'}?`;
  };

  const helperText = () => {
    return "By clicking <strong>Submit</strong>, the file(s) will be unreported.";
  };

  const dialogAction = (): ReactNode => {
    return (
      <ActionButton
        variant="outlined"
        color="primary"
        onClick={() => {
          onModalCancel();
          setRefresh();
          clearSelectedItems();
        }}
        startIcon={<CheckIcon />}
      >
        Ok
      </ActionButton>
    );
  };

  /* eslint-disable */
  //TODO Paid status is not implemented yet, in the future should be update onCloseAction in handleSubmitModal method to show this modal
  const refreshAndShowModal = () => {
    openDialog({
      title: "Ticket Submitted",
      content: (
        <Box>
          <MessageSubmittedBox>
            A ticket has been submitted to the Agency Support Center. An email containing the ticket number will be sent to the email address associated with this user account.
          </MessageSubmittedBox>
          <AgencySupportCenter />
        </Box>
      ),
      dialogActions: dialogAction()
    });
  };

  const handleSubmitModal = async () => {
    const ids = selectedItems.map((f) => f.fileId);
    const requestId = uuidv4();

    openProgressbar({
      title: "Please wait while processing Undo Report...",
      requestId,
      onCloseAction: () => {
        onModalCancel();
        setRefresh();
        clearSelectedItems();
      },
    });
    await undoReportPricing(ids, requestId);
  };

  const handleUndoReportClick = () => {
    openDialog({
      title: "Undo Report",
      content: (
        <ReportContentMessage
          mainText={mainText()}
          helperText={helperText()}
        />
      ),
      onSubmitAction: handleSubmitModal,
    });
  };
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  const hasUndoReportAccess   = permissionCodes?.includes(PERMISSIONS.BILLING_UNDOREPORT);
  return (
    <>
      <ActionButton
        color="secondary"
        variant="outlined"
        disabled={disabled || !hasUndoReportAccess }
        startIcon={<RestorePageIcon />}
        onClick={handleUndoReportClick}
      >
        Undo Report
      </ActionButton>
    </>
  );
};

export default UndoReport;
