import { styled } from "@mui/material";
import SelectField from "controls/global/select-field";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useFormContext,
  useWatch
} from "react-hook-form";
import { margin } from "theme/defaultStyle";
import { useLookup } from "utils/context/LookupContext";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";
import { SignatureLevelType } from "utils/data/enum";

interface SelectOption {
  value: string;
  text: string;
}

const FormField = styled("div")({
  marginTop: margin.xlarge,
});

const FIELD_NAME = "level";

const LevelField = () => {
  const [{ signatures }] = useSignatures();
  const [levelOptions, setLevelOptions] = useState<SelectOption[]>([]);
  const { updateSaveEnabled } = useSignatureDefaultDialogProvider();
  const [{ agencyWideLevelCode }] = useSignatureDefaults();
  const [signatureImage, locationField] = useWatch({
    name: ["signatureImage", "location"],
  });
  const [{ signatureLevelTypes }] = useLookup();
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    setLevelOptions(signatureLevelTypes.map(t => (
      {
      value: t.code,
      text: t.name
    })));
  }, [signatureLevelTypes]);

  useEffect(() => {
    if (levelOptions?.length === 1) {
      setValue(FIELD_NAME, {
        text: levelOptions[0].text,
        value: levelOptions[0].value,
      });
      return;
    }

    const levelFieldValue = getValues('level');
    if (!levelFieldValue?.value) {
      const agencyWideOption = levelOptions.find(o => o.value === agencyWideLevelCode);
      if (agencyWideOption) {
        setValue(FIELD_NAME, {
          text: agencyWideOption.text,
          value: agencyWideOption.value,
        });
      }
    }
  }, [levelOptions]);

  // Filter out levelOptions based on signature image's access (signatureLevelTypeCode)
  useEffect(() => {
    const signatureLevelTypeCode = signatures?.find(s => 
      s.signatureImageID === Number(signatureImage.value)
    )?.signatureLevelTypeCode;

    if (signatureLevelTypeCode === SignatureLevelType.UserOnly) {
      const filteredSignatureLevelTypes = signatureLevelTypes?.filter(t => t.code === SignatureLevelType.UserOnly);
      setLevelOptions(filteredSignatureLevelTypes?.map(t => (
        {
        value: t.code,
        text: t.name
      })));

    }
    else {
      setLevelOptions(signatureLevelTypes.map(t => (
        {
        value: t.code,
        text: t.name
      })));

    }

  },[signatureImage])

  useEffect(() => {
    const levelFieldValue = getValues('level');
    if (levelFieldValue?.value && signatureImage.value)
      updateSaveEnabled(true);
  }, [signatureLevelTypes, LevelField, signatureImage]);

  return (
    <FormField>
      <SelectField
        label="Level"
        name={FIELD_NAME}
        options={levelOptions}
        optionsValueField="value"
        optionsTextField="text"
        dataValueField="value"
        dataTextField="text"
        disabled={!locationField?.value}
      />
    </FormField>
  );
};

export default LevelField;
