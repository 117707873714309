import { Description } from "@mui/icons-material";
import React from "react";
import ActionButton from "../action-button";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

type Props = {
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
};

export default function ReportOnlyButton({ disabled, hidden, onClick }: Props) {
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasReportAccess  = permissionCodes?.includes(PERMISSIONS.BILLING_REPORT);

  // console.log('disabled:', disabled, 'hasReportAccess:', hasReportAccess);
  return (
    <ActionButton
      hidden={hidden}
      variant="outlined"
      color="primary"
      startIcon={<Description />}
      disabled={disabled || !hasReportAccess }
      // disabled={disabled}
      onClick={onClick}
      name="Report"
    >
      Report Only
    </ActionButton>
  );
}
