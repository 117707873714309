import ClearIcon from "@mui/icons-material/Clear";
import {
  Collapse,
  styled,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import React, {
  useState,
} from "react";
import {
  colors,
  gaps,
  margin,
} from "theme/defaultStyle";
import {
  DateTypeCode,
  PERMISSIONS,
  PageType,
  SessionStorageKeys,
} from "utils/data/enum";
import DatePickerSearch from "../datepicker-search/DatePickerSearch";
import DateTypeField from "./DateTypeField";
import KeywordSearchField from "./KeywordSearchField";
import OpenCheckbox from "./OpenCheckbox";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useDocumentStore from "utils/context/ImageArchiveContext";

type Props<T> = {
  open: boolean;
  isDisableFilters?: boolean;
  handleFilterChange: (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => void;
  page: PageType;
  defaultValues :any;
};

type InnerContainerProps = {
  page: PageType;
}

const StyledCollapse = styled(Collapse)({
  "&.MuiCollapse-hidden": {
    display: "none",
  },
});

const InnerContainer = styled("div")<InnerContainerProps>(({ page }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: page===PageType.ImageArchive ?"flex-Start" :"space-between",
  gap:page===PageType.ImageArchive ? gaps.large1: gaps.xsmall3,
  "& > :nth-child(1)": {
    flexGrow: 1,
    width:  page===PageType.ImageArchive ? "unset" : "auto",
    maxWidth :page===PageType.ImageArchive ?"200px" :"auto",
  },
  "& > :nth-child(2)": {
    width: "172px",
    minWidth :page===PageType.ImageArchive ?"400px" :"auto",

  },
  "& > :nth-child(3)": {
    width: page===PageType.ImageArchive ? "auto" :"314px",
    minWidth :page===PageType.ImageArchive ?"150px" :"auto",
  },
  "& > :nth-child(4)": {
    width: "unset",
  },
  "& > :nth-child(5)": {
    justifyContent: "flex-end",
  },
}));

const DateRangeContainer = styled("div")({
  alignItems: "center",
  display: "flex",
});

const Dash = styled("div")({
  background: colors.grey11,
  height: "3px",
  margin: margin.small1,
  width: "20px",
});

const AdvancedSearch = <T extends object>({ open, handleFilterChange, page, defaultValues, isDisableFilters }: Props<T>) => {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const usePriorSearchCriteriaAdvanceSearch = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateType, setDateType] = useState<string>("");
  const [keyword, setKeyword] = useState<string>(defaultValues && usePriorSearchCriteriaAdvanceSearch ? defaultValues["keyword"] : "");
  const [checked, setChecked] = useState<boolean>(defaultValues && usePriorSearchCriteriaAdvanceSearch ? defaultValues["userFilter"] : false);
  const [dateFromError, setDateFromError] = useState<boolean>(false);
  const [dateToError, setDateToError] = useState<boolean>(false);
  const [dateReset, setDateReset] = useState<number>(0);
  const [, { setMinDate,setMaxDate }] = useDocumentStore();
  
  const [{ profileSettings },] =
  useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);

  const shouldSearch = (date: Date | null, searchIfNull: boolean = true) =>
    date ? !isNaN(date.getTime()) : searchIfNull;

  const handleFilter = (name: keyof T, date: Date | null) =>
    handleFilterChange(name, date ?? undefined, shouldSearch(date));

  const handleDateFromChange = (newDate: Date | null) => {
    setDateFrom(newDate);
    setDateToError(false);
    handleFilter("dateRangeStart" as keyof T, newDate);
    setMinDate(newDate)
  };

  const handleDateToChange = (newDate: Date | null) => {
    setDateTo(newDate);
    setDateFromError(false);
    handleFilter("dateRangeEnd" as keyof T, newDate);
    setMaxDate(newDate)
  };

  const handleKeywordSearchChange = (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => {
    if (execSearch) {
      setDateFromError(false);
      setDateToError(false);
    }
    setKeyword(value);
    handleFilterChange(name, value, execSearch);
  };

  const handleSetDateFromError = (error: boolean) => setDateFromError(error);
  const handleSetDateToError = (error: boolean) => setDateToError(error);

  const handleDateTypeChange = (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => {
    execSearch = shouldSearch(dateTo, false) || shouldSearch(dateFrom, false);
    setDateType(value);
    handleFilterChange(name, value, execSearch);
  };

  const handleCheckboxChange = (
    name: keyof T,
    value: any,
    execSearch: boolean
  ) => {
    if (execSearch) {
      setDateFromError(false);
      setDateToError(false);
    }
    setChecked(value);
    handleFilterChange(name, value, execSearch);
  };

  const handleResetForDefaultPages = () => {
    if (page === PageType.ImageArchive) {
      setDateFrom(null);
      setDateTo(null);
      setDateReset(dateReset + 1);
      setChecked(false);
      setDateType(DateTypeCode.UploadDate);
      handleFilterChange("dateRangeStart" as keyof T, null, false);
      handleFilterChange("dateRangeEnd" as keyof T, null, false);
      handleFilterChange("userFilter" as keyof T, 0, false);
      handleFilterChange("dateTypeCode" as keyof T, DateTypeCode.UploadDate, true);
      setMaxDate(null)
      setMinDate(null)
    }
    else {
      setDateFrom(null);
      setDateTo(null);
      setDateReset(dateReset + 1);
      setKeyword("");
      setChecked(false);
      setDateType(
        page === PageType.MyFilesPage
          ? DateTypeCode.Created
          : (page === PageType.ReportPayPage
            ? DateTypeCode.IssueDate
            : DateTypeCode.ReportedDate));
      handleFilterChange("keyword" as keyof T, null, false);
      handleFilterChange("dateRangeStart" as keyof T, null, false);
      handleFilterChange("dateRangeEnd" as keyof T, null, false);
      handleFilterChange("userFilter" as keyof T, null, false);
      handleFilterChange("dateTypeCode" as keyof T, DateTypeCode.Created, true);
    }
  };

  return (
    <StyledCollapse
      in={open}
      data-test="advancedSearchPanel"
    >
      <InnerContainer page={page}>
        {page !== PageType.ImageArchive ? (
          <KeywordSearchField
            value={keyword}
            handleFilterChange={handleKeywordSearchChange}
          />
        ) : null}
        <DateTypeField
          dateType={dateType}
          data-test="dateType"
          variant="outlined"
          handleDateTypeChange={handleDateTypeChange}
          page={page}
          disabled={
            (page === PageType.ImageArchive && !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_SEARCH)) ||
            isDisableFilters
          }
        />
        <DateRangeContainer>
          <DatePickerSearch
            data-test="dateFrom"
            value={defaultValues && usePriorSearchCriteriaAdvanceSearch ? defaultValues["dateRangeStart"] : dateFrom}
            maxDate={dateTo ?? new Date()}
            handleDateChange={handleDateFromChange}
            hasError={dateFromError}
            reset={dateReset}
            onError={handleSetDateFromError}
            disabled={
              (page === PageType.ImageArchive && !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_SEARCH)) ||
              isDisableFilters
            }
          />
          <Dash />
          <DatePickerSearch
            data-test="dateTo"
            value={defaultValues && usePriorSearchCriteriaAdvanceSearch ? defaultValues["dateRangeEnd"] : dateTo}
            minDate={dateFrom ?? new Date()}
            handleDateChange={handleDateToChange}
            hasError={dateToError}
            reset={dateReset}
            onError={handleSetDateToError}
            disabled={
              (page === PageType.ImageArchive && !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_SEARCH)) ||
              isDisableFilters
            }
          />
        </DateRangeContainer>
        <OpenCheckbox
          {...{ handleCheckboxChange, checked }}
          data-test="openCheckbox"
          page={page}
          disabled={
            (page === PageType.ImageArchive && !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_SEARCH)) ||
            isDisableFilters
          }
        />
        <ActionButton
          disabled={
            isDisableFilters ||
            (!keyword && !dateFrom && !dateTo && !checked) ||
            (page === PageType.ImageArchive && !permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_SEARCH))
          }
          variant="outlined"
          color="secondary"
          startIcon={<ClearIcon />}
          onClick={handleResetForDefaultPages}
        >
          Clear
        </ActionButton>
      </InnerContainer>
    </StyledCollapse>
  );
};

export default AdvancedSearch;
