import { useMsal } from "@azure/msal-react";
import { NavigateToInfo } from "entities/UIModel";
import {
  useLocation,
  useNavigate,
} from "react-router";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useNavigation } from "utils/context/NavigationContext";
import { usePendingPay } from "utils/context/PendingPayContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useReportPay } from "utils/context/ReportPayContext";
import { NavigateToType } from "utils/data/enum";
import {
  isCreateFilePage,
  isPendingPayPage,
  isProfileSettingsPage,
  isReportPayPage,
} from "utils/routes/pathValidator";
import useGlobal from "./useGlobal";
import { getPermissionCodesByUrl } from "pages/home/menu";

const useNavRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, { getUserPermissions }] = useProfileSettingsCache();
  const { checkIfPermissionIsGranted } = useGlobal();
  const [, { setNextUrl, navigateToNextUrl }] = useNavigation();
  const [, { clearSelectedItems: clearReportPaySelectedItems }] = useReportPay();  
  const [, { clearSelectedItems: clearPendingPaySelectedItems }] = usePendingPay();
  const { instance } = useMsal();
  const [, { clearUserSelectedAgency }] = useGlobalAccess();

  const redirect = async (url: string | null, saveFileChangeRef: any, checkSaveFileChange: boolean | undefined = true, skippedPermissionCheck: boolean | undefined = false) => {
    // clear selected items for Report Pay or Pending Pay page
    const hasAccess = await hasPermission(url, skippedPermissionCheck);
    if (hasAccess) {    
      if (isReportPayPage(url || "")) {
        clearReportPaySelectedItems();
      }
      else if (isPendingPayPage(url || "")) {
        clearPendingPaySelectedItems();
      }
  
      setNextUrl(url);
  
      if (!checkSaveFileChange || (!isCreateFilePage(pathname) && !isProfileSettingsPage(pathname))) {
        navigateToNextUrl(navigate);
      }
      else {
        setTimeout(() => { //timeout is needed to give the React engine trime to update the navUrlHook
          if (saveFileChangeRef?.current) {
            saveFileChangeRef.current.click();
          }
        }, 100);
      }      
    }
    else {
      window.location.href = "/403";
    }

  };

  function navigateAfterSaveChangesDialog(navigateToInfo:NavigateToInfo) {
    if (navigateToInfo.navigateToType) {
      switch (navigateToInfo.navigateToType) {
        case NavigateToType.Url:
          navigateToInfo.url && navigate(navigateToInfo.url);
          break;
        case NavigateToType.Tab:
          navigateToInfo.tabName && document?.getElementById(navigateToInfo.tabName)?.click();
          break;
        case NavigateToType.Button:
          (document.querySelector(`#${navigateToInfo.buttonId}`) as HTMLElement)?.click();
          break;
        case NavigateToType.Adb2c:
          instance.logout();
          clearUserSelectedAgency();
          break;
      }
    }
  }

  async function hasPermission(url: string | null, skippedPermissionCheck: boolean) {
    if (skippedPermissionCheck || !url) {
      return true;
    }

    const permissionCode = getPermissionCodesByUrl(url);
    const permissionCodes = permissionCode ? [permissionCode] : undefined;
    const userPermissions = await getUserPermissions();

    return checkIfPermissionIsGranted(permissionCodes, userPermissions);

  }  

  return { redirect, navigateAfterSaveChangesDialog };
};

export default useNavRedirect;
