import { Typography } from "@mui/material";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import { cloneDeep } from "lodash";
import { useConfigurationProvider } from "pages/signatures/providers/manage-signature/ConfigurationProvider";
import React from "react";
import { colors } from "theme/defaultStyle";
import Body from "../signature-dialog/SignatureDialogBody";
import RecursiveTreeView from "../tree-view";

export default function SignatureConfiguration() {
  const {
    state: { nodes },
    dispatch,
  } = useConfigurationProvider();

  const onDataChanged = (data: SignatureConfigurationNode) => {
    dispatch({ type: "DATA_CHANGED", data: cloneDeep(data) });
  };

  return (
    <Body
      title="Where would you like this signature to be available?"
      width={920}
    >
      {nodes ? (
        <RecursiveTreeView data={nodes} onDataChanged={onDataChanged} />
      ) : (
        <Typography style={{ fontSize: 16, color: colors.grey07 }}>
          {nodes === null ? "No configuration available." : "Loading..."}
        </Typography>
      )}
    </Body>
  );
}
