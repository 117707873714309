import { SupervisedUserCircle } from "@mui/icons-material";
import { CollapsableProvider } from "controls/global/collapsable-form-section/CollapsableProvider";
import FormSection from "controls/global/form-section";
import TabNavigator from "controls/global/tab-navigator/";
import React,
{
  useEffect,
  useState
} from "react";
import { useWatch } from "react-hook-form";
import GradientIcon from "theme/icons/GradientIcon";
import { useLookup } from "utils/context/LookupContext";
import { usePartyAdditionalParties } from "utils/context/PartyAdditionalPartyContext";
import { usePartyBuyerBorrowerActions } from "utils/context/PartyBuyerBorrowerContext";
import { usePartyLenderActions } from "utils/context/PartyLenderContext";
import { usePartySellerActions } from "utils/context/PartySellerContext";
import { usePartyTypes } from "utils/context/PartyTypesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useProductOption from "utils/custom-hooks/useProductOption";
import {
  PartyRoleTypeName,
  ProductTypeName,
} from "utils/data/enum";
import { getIn } from "utils/object";
import AdditionalPartiesSection from "./additional-parties/AdditionalPartiesSection";
import BuyerBorrowerCollection from "./BuyerBorrowerCollection";
import LenderSection from "./lender/LenderSection";
import SellerCollection from "./SellerCollection";
import {
  useSideNavProvider,
  ActionType
} from "pages/file/SideNavProvider";
import { TabNavigatorItem } from "entities/UIModel";

const PartiesSection = () => {
  const { formState, getValues } = useFormWrapper();
  const [{ suffixes }, { getSuffixTypes }] = useLookup();
  const [, { getPartyTypes }] = usePartyTypes();
  const { hasAdditionalParty } = useProductOption();
  const [, { setBuyerBorrowers }] = usePartyBuyerBorrowerActions();
  const [, { setSellers }] = usePartySellerActions();
  const [, { setLenders }] = usePartyLenderActions();
  const [, { setAdditionalParties }] = usePartyAdditionalParties();
  const showAdditionalParties = hasAdditionalParty();
  const { sideNavState, sideNavAction } = useSideNavProvider();

  const partiesRef = React.useRef<HTMLElement>();

  const getTabNames = () => {
    const reorderedTabs: TabNavigatorItem[] = [
      {
        title: PartyRoleTypeName.BuyerBorrower,
        disabled: false,
      },
      {
        title: PartyRoleTypeName.Seller,
        disabled: false,
      },
      {
        title: PartyRoleTypeName.Lender,
        disabled: false,
      },
      
    ];

    if (showAdditionalParties) {
      reorderedTabs.push({
        title: PartyRoleTypeName.AdditionalParty, 
        disabled: false,
      });
    }

    return reorderedTabs;
  };

  const renderComponents = () => {
    const reorderedComponents = [
      <BuyerBorrowerCollection key={"buyer-borrower-data"} />,
      <SellerCollection key={"seller-data"} />,
      <LenderSection key={"lender-section-data"} />,
      // <AdditionalPartiesSection key={"additional-parties-section-data"} />,
    ];

    if (showAdditionalParties) {
      reorderedComponents.push(
        <AdditionalPartiesSection key={"additional-parties-section-data"} />
      );
    }

    return reorderedComponents;
  };

  const resetPartyData = () => {
    setBuyerBorrowers(getValues("buyerBorrowerParties"));
    setSellers(getValues("sellerParties"));
    setLenders(getValues("lenderParties"));
    setAdditionalParties(getValues("additionalParties"));
  };

  const navigatePartyTabs = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey) {
      const element = evt.target as HTMLElement;
      if (element.tabIndex < 0) {
        const nextTab = getTabNames().findIndex(tab => tab.title === element.id) + 1;
        if (nextTab < getTabNames().length) {
          evt.preventDefault();
          document?.getElementById(getTabNames()[nextTab].title)?.focus();
        }
        if (nextTab === getTabNames().length) {
          let element = document?.getElementById(ProductTypeName.CPL);
          if (!element) {
            element = document?.getElementById(ProductTypeName.AAL);
          }

          if (!element) {
            element = document?.getElementById(ProductTypeName.STANDALONE_ENDORSEMENT);
          }

          if (element) {
            evt.preventDefault();
            element?.focus();
          }
        }
      }
    }
  }

  useWatch({ name: "properties.0.state" });
  const [isError, setIsError] = useState(false);
  const [tabsWithErrors, setTabsWithErrors] = useState<string[]>([]);

  useEffect(() => {
    const isBBError = Boolean(getIn(formState.errors, "buyerBorrowerParties"));
    const isSellerError = Boolean(getIn(formState.errors, "sellerParties"));
    const isLenderError = Boolean(getIn(formState.errors, "lenderParties"));
    const isAPError = Boolean(getIn(formState.errors, "additionalParties"));

    const errorTabs = [];
    if (isBBError) errorTabs.push("Buyer/Borrower");
    if (isSellerError) errorTabs.push("Seller");
    if (isLenderError) errorTabs.push("Lender");
    if (isAPError) errorTabs.push("Additonal Parties");

    setTabsWithErrors(errorTabs);
    setIsError(errorTabs.length > 0);
  }, [formState]);

  useEffect(() => {
    getSuffixTypes();
  }, [getSuffixTypes]);

  useEffect(() => {
    if (suffixes.length === 0) getSuffixTypes();
  }, [suffixes.length]);

  useEffect(() => {
    if (!showAdditionalParties) return;
    getPartyTypes();
  }, [getPartyTypes, showAdditionalParties]);

  useEffect(() => {
    document.addEventListener("documentReset", resetPartyData);
    return () => {
      document.removeEventListener("documentReset", resetPartyData);
    };
  });

  useEffect(() => {
    if (sideNavState.items.get("Parties")?.childRef?.current) return;
    sideNavAction({
      type: ActionType.UPDATE,
      item: { name: "Parties", childRef: partiesRef },
    });
  }, [sideNavState.items]);

  return (
    <FormSection
      navItemRef={partiesRef}
      icon={<GradientIcon Icon={SupervisedUserCircle} />}
      isError={isError}
      isNavigator
      title={"Parties"}
    >
      <CollapsableProvider>
        <TabNavigator
          tabsWithErrors={tabsWithErrors}
          tabs={getTabNames()}
          onKeyDown={(evt: KeyboardEvent) => {
            navigatePartyTabs(evt);
          }}>
          {renderComponents()}
        </TabNavigator>
      </CollapsableProvider>
    </FormSection>
  );
};

export default PartiesSection;
