import {
  Box,
  styled,
} from "@mui/material";
import AddLink from "controls/global/add-link";
import React,
{
  useEffect,
  useState,
} from "react";
import {
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { gaps } from "theme/defaultStyle";
import { useSuccessorLanguages } from "utils/context/CompanySuccessorLanguageContext";
import { useParty } from "utils/context/PartyContext";
import { usePartyLenderActions } from "utils/context/PartyLenderContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { getDefaultLenderObject } from "utils/data/parties";
import { v4 as uuidv4 } from "uuid";
import Lender from "./Lender";
import { PartyRoleTypeName } from "utils/data/enum";
import useFocus from "utils/custom-hooks/useFocus";
import ConfirmationDialogV3 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV3";

interface LenderSectionProps { }

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const LenderSection = (props: LenderSectionProps) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [lenderIndex, setLenderIndex] = useState<number>(0);
  const [deleteLenderIndex, setDeleteLenderIndex] = useState(-1);

  const { setFocusInputElement } = useFocus();
  const [, { deleteParty }] = useParty();
  const [{ successorLanguages }, { getSuccessorLanguages }] =
    useSuccessorLanguages();

  const { getValues, setValue } = useFormWrapper();
  const [, { setLenders }] = usePartyLenderActions();
  const watchedFields = useWatch({
    name: [
      "fileNameNumber",
      "agency",
      "properties.0",
      "additionalParties",
      "hasIssuedProducts",
      "isPopulatingExistingFile",
    ],
  });
  const fileNameNumber = watchedFields[0];
  const agency = watchedFields[1];
  const primaryProperty = watchedFields[2];
  const additionalParties = watchedFields[3];
  const hasIssuedProducts = watchedFields[4];
  const isPopulatingExistingFile = watchedFields[5];
  const { lenderParties } = getValues();
  const { fields, append, remove } = useFieldArray({
    name: "lenderParties", // unique name for your Field Array
    keyName: "fieldId",
  });
  const isFileDisabled = useFieldDisabler("AddLenderButton");

  const deleteFileParty = (index: number) => {
    setOpenConfirmationDialog(true);
    setDeleteLenderIndex(index);
  };

  const handleAddItem = (event: any) => {
    event.preventDefault();
    setLenderIndex(lenderIndex + 1);

    let filePartyKey = uuidv4();
    const fileParty = getDefaultLenderObject();
    fileParty.filePartyKey = filePartyKey;
    fileParty.integrationKey = filePartyKey;
    fileParty.isDefault = false;
    fileParty.isDirty = true;

    append(fileParty);

    //once you append a new party, the first is no longer default
    setValue(`lenderParties.0.isDefault`, false);
    setValue(`lenderParties.0.isDirty`, true);

    // set focus to last Lender Parties
    const newItemIndex = fields.length;
    setFocusInputElement(`lenderParties.${newItemIndex}.companyName`);
  };

  const handleYes = () => {
    if (deleteLenderIndex !== -1) {
      const currentParty = lenderParties[deleteLenderIndex];
      if (
        currentParty?.filePartyId &&
        currentParty?.filePartyId > 0 &&
        currentParty?.integrationKey
      ) {
        deleteParty(currentParty?.integrationKey, agency?.id, fileNameNumber);
      }
      if (lenderIndex >= 0) {
        remove(deleteLenderIndex);

        // Update PartyLenderContext
        setLenders(getValues("lenderParties"));
      }
      setDeleteLenderIndex(-1);
    }

    setOpenConfirmationDialog(false);
  };

  const handleNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey && document?.getElementById(PartyRoleTypeName.AdditionalParty)) {
      evt.preventDefault();
      document?.getElementById(PartyRoleTypeName.AdditionalParty)?.focus();
    }
  }

  useEffect(() => {
    if (successorLanguages.length <= 0) {
      getSuccessorLanguages();
    }

    if (lenderParties.length > 0) {
      setLenderIndex(lenderParties.length - 1);
    } else {
      const defaultLenderObject = getDefaultLenderObject();
      append(defaultLenderObject);

      // Update PartyLenderContext
      setLenders(getValues("lenderParties"));

    }
  }, [lenderParties, append]);

  return (
    <>
      <StyledGroup>
        {fields.map((field: any, index: number) => {
          return (
            <Lender
              key={field.fieldId}
              index={index}
              party={field}
              parentArrayName="lenderParties"
              isAAL={primaryProperty?.state?.abbr === "NY"}
              deleteParty={deleteFileParty}
              additionalParties={additionalParties}
              fileNameNumber={fileNameNumber}
              agency={agency}
              property={primaryProperty}
              hasIssuedProducts={hasIssuedProducts}
              isPopulatingExistingFile={isPopulatingExistingFile}
            />
          );
        })}

        <Box>
          <AddLink
            disabled={isFileDisabled}
            onClick={handleAddItem}
            onKeyDown={handleKeyDown}
          >
            Add Lender
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialogV3
        confirmationMessage="Delete this party?"
        isOpen={openConfirmationDialog}
        onYes={handleYes}
        onNo={handleNo}
        onCancel={handleNo}
        cancelActionButton={true}
        singleActionButtonText={"Delete"}
        buttonNoText={"Cancel"}
      />
    </>
  );
};

export default LenderSection;
