import {
  styled,
  TableCell,
} from "@mui/material";
import React from "react";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.black,
  fontSize: fontSize.xlarge1,
  fontWeight: fontWeight.bold1,
  maxWidth: "170px",
  overflow: "hidden",
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
});

type Props = {
  hasIssuedProducts: boolean;
  newTotalRiskRate: string;
};

const RiskRateNewCell = styled(TooltipCell)({
  display: "inline-block",
  fontSize: fontSize.xlarge1,
  maxWidth: "100%",
  overflow: "hidden",
  paddingBottom: padding.xsmall2,
  textOverflow: "ellipsis",
});

export default function RiskRateTotal({ hasIssuedProducts, newTotalRiskRate }: Props) {

  return (
    <StyledTableFooterCell align="right">
      {hasIssuedProducts &&
        <RiskRateNewCell title={newTotalRiskRate} />
      }
    </StyledTableFooterCell>
  );
}
