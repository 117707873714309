import {
    SvgIcon,
    SvgIconProps,
} from "@mui/material";
import React from "react";

const ManageSignaturesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 148.2 132.3" {...props}>
            <g>
                <path d="M113.8,31.4c0.8-0.8,1.2-2,1.2-3.2c0-1.2-0.4-2.3-1.3-3.1l-13.3-12.7c-0.9-0.9-2-1.3-3.2-1.3c-1.2,0-2.2,0.5-3.1,1.4
		l-7,7.3l19.6,18.9L113.8,31.4z"/>
                <path d="M132.1,102.2c-3.1,1-5.9,2-8.7,3.1c-5.4,2.1-10.9,4.2-16.2,4.6c-4.6,0.4-5.5,0.4-5.5,0.4c0-0.5,0.1-1.3,0.1-2.2
		c0.3-2.4,0.5-5.1-0.5-8.2C99.7,95,95,93,88.7,92.7c-4.8-0.2-10.7,0.5-16.6,1.6c-3.2,0.6-7.8,2-13.1,3.7c-5.2,1.6-11,3.4-16.5,4.7
		c-9.2,2.2-14.7,1.8-17.3-0.1c-0.6-0.4-0.9-1-1.1-1.6c-0.2-0.8,0-1.7,0.4-2.8c1.6-3.9,6.2-8,13.2-10.2c2.4-0.7,3.7-3.3,3-5.7
		c-0.7-2.4-3.3-3.7-5.7-3c-9.8,3-16.5,9.3-19,15.5c-1.1,2.7-1.4,5.5-0.8,8c0.6,2.7,2.1,5.1,4.6,7c4.5,3.3,12.6,4.5,24.7,1.6
		c5.8-1.4,11.8-3.2,17-4.9c5-1.6,9.4-2.9,12.1-3.4c5.3-1,10.5-1.7,14.5-1.5c2.5,0.1,4.2,0.4,4.4,0.9c0.4,1.2,0.2,2.9,0.1,4.4
		c-0.1,1.3-0.3,2.6-0.2,3.9c0.4,5.5,3.2,8.9,15.3,7.9c6.6-0.5,12.7-2.8,18.7-5.1c2.8-1.1,5.6-2.2,8.1-2.9c2.4-0.7,3.8-3.2,3-5.7
		C137,102.8,134.5,101.4,132.1,102.2z"/>
                <path d="M49.8,79.8l24-6.4l26.8-28.2L80.9,26.2L54.1,54.5l-5.4,24.3C48.6,79.6,48.9,79.9,49.8,79.8z M53,74.5l3.3-14.9l12,11.6
		l-14.7,4C53.1,75.2,52.9,75,53,74.5z"/>
                <path d="M133.8,75c0-0.9-0.1-1.7-0.2-2.5l4.6-4.1l-4-7l-5.9,2c-1.3-1.1-2.7-1.9-4.4-2.5l-1.2-6h-8.1l-1.2,6
		c-1.6,0.6-3.1,1.5-4.4,2.5l-5.9-2l-4,7l4.6,4.1c-0.1,0.8-0.2,1.7-0.2,2.5s0.1,1.7,0.2,2.5l-4.6,4.1l4,7l5.9-2
		c1.3,1.1,2.7,1.9,4.4,2.5l1.2,6h8.1l1.2-6c1.6-0.6,3.1-1.5,4.4-2.5l5.9,2l4-7l-4.6-4.1C133.7,76.7,133.8,75.8,133.8,75z M118.7,83
		c-4.4,0-8.1-3.6-8.1-8.1c0-1.1,0.2-2.2,0.6-3.1c0.5-1.2,1.3-2.3,2.3-3.1c0.2-0.2,0.4-0.3,0.6-0.5c1.3-0.9,2.8-1.4,4.5-1.4
		s3.2,0.5,4.5,1.4c0.2,0.1,0.4,0.3,0.6,0.5c1,0.8,1.8,1.9,2.3,3.1c0.4,1,0.6,2,0.6,3.1C126.8,79.4,123.1,83,118.7,83z"/>
            </g>
        </SvgIcon>
    );
};

export default ManageSignaturesIcon;
