import {
  Box,
  ListItemText,
  MenuItem,
  styled,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import OverflowTooltip from "controls/global/overflow-tooltip";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import { MultiSelectContainer } from "./MultiSelectContainer";
import ErrorMessage from "controls/global/error-message/ErrorMessage";
import {
  colors,
  fontSize,
} from "theme/defaultStyle";

type Props = {
  label: string;
  name: string;
  options: any[];
  disabled?: boolean;
};

const StyledListItemText = styled(ListItemText)({
  color: colors.black,
  fontSize: fontSize.large,
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  whiteSpace: "nowrap",
});

const ALL_KEY = "All";
const MultiSelectComponent = ({
  label,
  name,
  options,
  disabled = false,
}: Props) => {
  const { control, clearErrors } = useFormWrapper();

  const getRenderValue = (selected: any) => {
    const selectedItem = selected.join(", ");
    return <OverflowTooltip text={selectedItem} tooltip={selectedItem} />;
  };

  const handleChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    clearErrors(`${name}` as const);

    const selectedValues = ev.target.value;
    if (selectedValues.indexOf(ALL_KEY) > -1) {
      onChange([ALL_KEY]);
      return;
    }

    onChange(ev.target.value);
  };

  return (
    <>
      <Controller
        control={control}
        name={`${name}` as const}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => {
          return (
            <MultiSelectContainer
              select
              fullWidth
              label={label}
              disabled={disabled}
              error={Boolean(error?.message)}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                },
                multiple: true,
                value: field.value,
                renderValue: getRenderValue,
              }}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => handleChange(event, field.onChange)}
            >
              {options.map((option: any, index: number) => (
                <MenuItem
                  key={index}
                  value={option.text}
                  disabled={
                    field.value.includes(ALL_KEY) && option.text !== ALL_KEY
                  }
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CheckboxInput
                      name={name}
                      checked={field.value.indexOf(option.text) > -1}
                    />
                    <StyledListItemText
                      primary={option.text}
                    />
                  </Box>
                </MenuItem>
              ))}
            </MultiSelectContainer>
          );
        }}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default MultiSelectComponent;
