import { styled } from "@mui/material";
import CurrencyInputField from "controls/global/currency-field/CurrencyInputField";
import React from "react";

type Props = {
  jacketIndex: number;
  disabled?: boolean;
};

const Container = styled("div")({
  width: "unset",
});

function JacketPremium({ jacketIndex, disabled }: Props) {
  return (
    <Container>
      <CurrencyInputField
        label="Premium (for Sch A only)"
        name={`jackets.${jacketIndex}.newPremiumAmount`}
        disabled={disabled}
        allowNegative={false}
      />
    </Container>
  );
}

export default JacketPremium;
