import React from "react";
import AdditionalPartiesAutocomplete from "./AdditionalPartiesAutocomplete";

type Props = {
  schema: string;
  disabled: boolean;
};

export default function CompanyName({ schema, disabled }: Props) {
  return (
    <AdditionalPartiesAutocomplete
      label="Company Name"
      name="companyName"
      schema={schema}
      searchProp="text"
      disabled={disabled}
      popperPlacement="top"
    />
  );
}
