import {
  Box,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import StewartCheckbox from "controls/global/stewart-checkbox";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontSize,
  margin,
  padding,
} from "theme/defaultStyle";

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const StyledBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  marginRight: margin.medium2,
  paddingRight: padding.xsmall2,
});

interface LabelTypographyProps extends TypographyProps {
  isDisabled?: boolean;
}

const Label = styled(Typography)<LabelTypographyProps>(({ isDisabled }) => ({
  color: isDisabled ? colors.grey11 : colors.grey08,
  fontSize: fontSize.large,
}));

interface Props {
  disabled: boolean;
  onlyShowSelectedFiles: boolean;
  onOnlySelectedFilesChange?: (value: boolean) => void;
}

const ReportPayFooterAction = ({
  disabled,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: Props) => {
  const [onlySelectedFilesChecked, setOnlySelectedFilesChecked] = useState<boolean>(onlyShowSelectedFiles);

  const handleOnlySelectedFilesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setOnlySelectedFilesChecked(isChecked);
    onOnlySelectedFilesChange && onOnlySelectedFilesChange(isChecked);
  };

  useEffect(() => {
    if (!disabled) return;
    setOnlySelectedFilesChecked(false);
    onOnlySelectedFilesChange?.(false);
  }, [disabled]);

  useEffect(() => {
    setOnlySelectedFilesChecked(onlyShowSelectedFiles);
  }, [onlyShowSelectedFiles]);

  return (
    <ColumnBox>
      <StyledBox>
        <StewartCheckbox
          disabled={disabled}
          checked={onlySelectedFilesChecked}
          onChange={handleOnlySelectedFilesChange}
        />
        <Label isDisabled={disabled}>Only show selected files</Label>
      </StyledBox>
      <StyledBox>* Excluded from Auto-reporting</StyledBox>
    </ColumnBox>
  );
};

export default ReportPayFooterAction;
