import React, {
  useEffect,
} from "react";
import {
  Box,
  styled,
  Typography,
} from "@mui/material";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "../../providers/manage-signature/SignatureProvider";
import { useSignatures } from "utils/context/SignaturesContext";
import FileNameInputField from "./FileNameInputField";
import useDebounce from "utils/custom-hooks/useDebounce";

const BoxContainer = styled(Box)({
  margin: "auto",
  maxWidth: "300px",
  width: "100%",
  "& .label": {
    color: colors.grey08,
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    marginBottom: margin.small,
  },
});

export default function SignatureName() {
  const [, { checkName }] = useSignatures();
  const {
    state: { signature, isNameValid },
  } = useSignatureProvider();
  const { dispatch } = useSignatureDispatchProvider();
  const debouncedValue = useDebounce(signature.name, 500);

  const handleNameChange = (event: React.ChangeEvent<any>) => {
    const text = String(event.target.value);
    dispatch({
      type: "NAME_UPDATE",
      signatureName: text,
      isCheckingName: true,
    });
  };

  const checkNameDebounced = React.useCallback(
    (nameToCheck: string) => {
      checkName(nameToCheck).then((matchingNameID) => {
        dispatch({
          type: "SIG_VALIDATE",
          isCheckingName: false,
          isNameValid:
            matchingNameID === 0 || matchingNameID === signature.signatureID,
        });
      });
    },
    [dispatch, checkName, signature.signatureID]
  );

  useEffect(() => {
    const trimmedName = String(debouncedValue).trim();
    if (!trimmedName || trimmedName === "") return;

    // This conditional statement is being used to test the global error handler.
    if (trimmedName === "{{DEBUG A}}") {
      throw new Error("This is a known exception");
    }
    else if (trimmedName === "{{DEBUG B}}") {
      console.log(trimmedName[100].length);
    }

    checkNameDebounced(trimmedName);
  }, [checkNameDebounced, debouncedValue]);

  return (
    <BoxContainer>
      <Typography
        className={"label"}
        style={{ color: isNameValid ? colors.grey08 : colors.red02 }}
      >
        Type the name of your signature below:
      </Typography>
      <FileNameInputField
        title={signature.name}
        handleNameChange={handleNameChange}
      />
      {!isNameValid && (
        <Box className="errorMessage">
          A signature for this name already exists.
        </Box>
      )}
    </BoxContainer>
  );
}
