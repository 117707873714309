import ActionButton from "controls/global/action-button";
import React from "react";
import { OrderStatusType } from "utils/data/enum";
import BlockBoldIcon from "theme/icons/BlockBoldIcon";
import CheckIcon from "@mui/icons-material/Check";
import { ReviseAction } from "../jacket/JacketButtonSection";
import { Cancel } from "@mui/icons-material";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import ReviseIcon from "theme/icons/ReviseIcon";
import ViewPrintIcon from "theme/icons/ViewPrintIcon";

type Props = {
  onIssueLetter?: any;
  onViewLetter?: any;
  onVoidLetter?: any;
  orderStatusTypeCode?: string;
  issueButtonDisabled?: boolean;
  voidButtonDisabled?: boolean;
  revising: boolean;
  onReviseLetter: (action: ReviseAction) => void;
};

const LetterButtons = ({
  onIssueLetter,
  onReviseLetter,
  onViewLetter,
  onVoidLetter,
  orderStatusTypeCode,
  issueButtonDisabled,
  voidButtonDisabled,
  revising,
}: Props) => {
  const isViewVisible = orderStatusTypeCode === OrderStatusType.Issued;
  const isIssueVisible =
    !orderStatusTypeCode ||
    orderStatusTypeCode === OrderStatusType.Pending ||
    orderStatusTypeCode === OrderStatusType.Error;
  const isVoidVisible = orderStatusTypeCode === OrderStatusType.Issued;
  const isViewPrcingButtonDisabled = useFieldDisabler("CPLView/PrintButton");
  const isReviseButtonDisabled = useFieldDisabler("CPLReviseButton");
  const isVoidButtonDisabled = useFieldDisabler("CPLVoidButton");
  
  return (
    <>
      {isViewVisible && !revising ? (
        <ActionButton
          color="secondary"
          onClick={() => onReviseLetter(ReviseAction.OpenRevise)}
          disabled={isReviseButtonDisabled}
          startIcon={<ReviseIcon />}
          variant="outlined"
        >
          Revise
        </ActionButton>
      ) : null}
      {isViewVisible && revising && (
        <>
          <ActionButton
            color="primary"
            // disabled={}
            variant="outlined"
            startIcon={<CheckIcon />}
            onClick={() => {
              onReviseLetter(ReviseAction.SubmitRevise);
            }}
          >
            Submit Revision
          </ActionButton>
          <ActionButton
            color="secondary"
            // disabled={}
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => {
              onReviseLetter(ReviseAction.CancelRevise);
            }}
          >
            Cancel
          </ActionButton>
        </>
      )}
      {isIssueVisible ? (
        <ActionButton
          color="primary"
          onClick={onIssueLetter}
          startIcon={<CheckIcon />}
          variant="outlined"
          disabled={issueButtonDisabled}
        >
          Issue
        </ActionButton>
      ) : null}
      {isVoidVisible && !revising ? (
        <ActionButton
          color="secondary"
          onClick={onVoidLetter}
          startIcon={<BlockBoldIcon />}
          variant="outlined"
          disabled={voidButtonDisabled || isVoidButtonDisabled}
        >
          Void
        </ActionButton>
      ) : null}
      {isViewVisible && !revising ? (
        <ActionButton
          color="primary"
          onClick={onViewLetter}
          startIcon={<ViewPrintIcon />}
          variant="outlined"
          disabled={isViewPrcingButtonDisabled}
        >
          View/Print
        </ActionButton>
      ) : null}
    </>
  );
};

export default LetterButtons;
