/* eslint-disable react/display-name */
import {
  BorderColor,
  Upload,
} from "@mui/icons-material";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button/ActionButton";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import React, {
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  letterSpacing,
  padding,
} from "theme/defaultStyle";
import TypeIcon from "theme/icons/TypeIcon";
import { useLookup } from "utils/context/LookupContext";
import { useSignatures } from "utils/context/SignaturesContext";
import Body from "../signature-dialog/SignatureDialogBody";

const ButtonContainer = styled("div")({
  display: "flex",
  gap: gaps.medium1,
  justifyContent: "center",
});

const StyledButton = styled(ActionButton)({
  "&.ActionButton-root": {
    height: "130px",
    borderColor: colors.blue01,
    borderRadius: borderRadius.medium,
    borderWidth: borderSize.small,
    color: colors.blue01,
    padding: padding.xlarge1,
    width: "164px",
    "&:hover,&:focus,&.selected": {
      backgroundColor: colors.blue08,
      borderColor: colors.blue01,
      borderWidth: borderSize.small,
      color: colors.blue01,
    },
    "&.Mui-disabled": {
      borderWidth: borderSize.small,
    },
    "&.MuiButton-outlinedSecondary:hover svg.MuiSvgIcon-root": {
      color: colors.blue01,
    },
    "&.MuiButton-outlinedSecondary:focus svg.MuiSvgIcon-root": {
      color: colors.blue01,
    },
    "& .icon": {
      alignItems: "center",
      display: "flex",
      height: "40px",
      "& .MuiSvgIcon-root": {
        fontSize: iconSize.large2,
        "&[data-testid='UploadIcon']": {
          fontSize: "53px", // This fontSize is to work around the MUI svg size for consistency.
          marginTop: "2px", // This margin is to work around the MUI svg viewbox for consistency.
        },
        "&[data-testid='BorderColorIcon']": {
          fontSize: "41px", // This fontSize is to work around the MUI svg size for consistency.
          marginTop: "-3px", // This margin is to work around the MUI svg viewbox for consistency.
        },
      },
    },
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: fontSize.xlarge2,
    justifyContent: "space-between",
    lineHeight: fontSize.xlarge2,
    "& .text": {
      fontWeight: fontWeight.normal1,
      letterSpacing: letterSpacing.medium1,
    },
  },
});

type ButtonProps = {
  label: string;
  children: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const DisplayButton = ({ onClick, disabled, selected, children, label }: ButtonProps) => {
  return (
    <>
      <StyledButton
        variant="outlined"
        color="secondary"
        onClick={onClick}
        disabled={disabled}
        className={selected ? "selected" : "not-selected"}
      >
        <div className={"icon"}>
          {children}
        </div>
        <span className={"text"}>
          {label}
        </span>
      </StyledButton>
    </>
  );
};

export default function Menu() {
  const [message, setMessage] = useState("");
  const [{ signatureImageSourceTypes }] = useLookup();
  const [{ isLoading }] = useSignatures();
  const { state: { imageSource, mode }, } = useSignatureProvider();
  const { dispatch: signatureDispatch } = useSignatureDispatchProvider();

  const handleClick = (imageSource: string) => {
    signatureDispatch({ type: "SELECT_OPTION", imageSource });
  };

  const getSelected = (source: string) => {
    return source.toLowerCase() === imageSource?.toLowerCase();
  };

  const renderIcon = (sourceTypeCode: string) => {
    const source = sourceTypeCode.toLowerCase();
    if (source.includes("upload")) {
      return <Upload />;
    }
    else if (source.includes("type")) {
      return <TypeIcon />;
    }
    else if (source.includes("draw")) {
      return <BorderColor />;
    }
    else {
      return <></>;
    }
  };

  useEffect(() => {
    switch (mode) {
      case "ADD":
        setMessage("How would you like to add a new signature?");
        break;
      case "EDIT":
        setMessage("How would you like to add the edited signature?");
        break;
      default:
        break;
    }
  }, [mode]);

  return (
    <Body
      title={message}
      width={570}
      justifyContent="center"
    >
      <ButtonContainer>
        {signatureImageSourceTypes && signatureImageSourceTypes.map(t =>
          <DisplayButton
            key={t.code}
            label={t.name}
            onClick={() => handleClick(t.code)}
            selected={getSelected(t.code)}
            disabled={isLoading}
          >
            {renderIcon(t.code)}
          </DisplayButton>
        )}
      </ButtonContainer>
    </Body>
  );
}
