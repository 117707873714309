import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import clsx from "clsx";
import { Jacket } from "entities/UIModel";
import { JacketFormDetail } from "entities/UIModel/JacketFormDetail";
import { isEqual } from "lodash";
import { combineFileAndLookupFormDetails } from "pages/file/utils/products/jacket";
import React, {
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import theme from "theme/default";
import {
  borderSize,
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";
import { useFiles } from "utils/context/FilesContext";
import { usePartyBuyerBorrowerNames } from "utils/context/PartyBuyerBorrowerContext";
import { useTranslation } from "utils/context/TranslationContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  OrderStatusType,
  ProductType,
  UIConstants,
} from "utils/data/enum";
import DateField from "./form-details/DateField";
import NormalTextField from "./form-details/NormalTextField";
import NumberField from "./form-details/NumberField";
import RadioGroupField from "./form-details/RadioGroupField";
import { formatCurrency } from "utils/shared";

interface Props {
  name: string;
  jacket: Jacket;
  isRevising?: boolean;
  lvFormDetails: JacketFormDetail[];
}

const StyledTableContainer = styled(Box)({
  maxHeight: "432px",
  overflow: "hidden",
  overflowY: "auto",
  "& table.form-details-table": {
    "& tbody td": {
      color: colors.grey08,
      fontSize: fontSize.large,
      "& .MuiFormControl-root": {
        width: "35%",
        [theme.breakpoints.down("lg")]: {
          width: "50%",
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      paddingTop: padding.small,
      paddingBottom: padding.small,
    },
    "& .mortgage_date": {
      height: "35px",
    },
    "& .tableHeaderStyle": {
      minWidth: "100%",
    },
    "& .tableRowStyle": {
      boxSizing: "initial",
    },
    "& .noBottomBorder": {
      borderBottom: borderSize.zero,
      paddingBottom: padding.small1,
    },
    "& .disableBackground": {
      backgroundColor: colors.grey10,
      paddingBottom: padding.small1,
      "& div": {
        color: colors.grey11,
      }
    },
    "& .labelEnabled": {
      color: colors.black,
    },
    "& .labelDisabled": {
      color: colors.grey11,
    }
  },
});

const StyledBodyCell = styled(TableCell)({
  width: "50%",
});

const StyleCellText = styled(Box)({
  color: colors.grey08,
});

const JacketFormDetails = ({
  name,
  jacket,
  isRevising = false,
  // buyerBorrowerParties,
  lvFormDetails,
}: Props) => {

  // Get party context value
  const [buyerBorrowerNames] = usePartyBuyerBorrowerNames(ProductType.Jacket);

  const [combinedFormDetails, setCombinedFormDetails] = useState<
    JacketFormDetail[]
  >([]);
  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState<boolean>(true);

  const formType = jacket?.formType;
  const form = jacket?.form;
  const formID = jacket?.formID;
  const formDetails = jacket?.formDetails;
  const orderStatusTypeCode = jacket?.orderStatusTypeCode;

  const [{ isReadOnly }] = useFiles();
  const { setValue, getValues, register } = useFormWrapper();
  const [{ translations }] = useTranslation();

  const updateFormDetails = (
    formDetail: JacketFormDetail,
    value: any,
    index: number
  ) => {
    const newValue = {
      ...formDetail,
      formDetailValue: value || "",
    };

    setValue(`${name}.${index}`, newValue);
  };

  const getCurrentFormDetail = (params: JacketFormDetail) => {
    const { formDetailName } = params;
    const allFormDetails: JacketFormDetail[] | undefined =
      getValues(formDetailName);

    const formDetail = allFormDetails?.find(
      (f) => f && f.formDetailName === formDetailName
    );

    return formDetail ? formDetail : params;
  };

  const renderRadioButton = (params: JacketFormDetail, index: number) => {
    const currentParam = getCurrentFormDetail(params);

    return (
      <RadioGroupField
        defaultValue={currentParam.formDetailValue}
        disabled={disabled}
        onChange={(value) => updateFormDetails(currentParam, value, index)}
      />
    );
  };

  const renderText = (params: JacketFormDetail, index: number) => {
    return (
      <NormalTextField
        index={index}
        formDetail={params}
        name={name}
        disabled={disabled}
        fullWidth={false}
        integersOnly={params.formDetailType === "Number"}
      />
    );
  };

  const renderCurrency = (params: JacketFormDetail, index: number) => {
    return (
      <NumberField
        index={index}
        formDetail={params}
        name={name}
        fullWidth={false}
        disabled={disabled}
        size="small"
      />
    );
  };

  const renderDate = (params: JacketFormDetail, index: number) => {
    const currentParam = getCurrentFormDetail(params);

    const handleChange = (value: string) => {
      updateFormDetails(currentParam, value, index);
    };

    return (
      <DateField
        defaultValue={currentParam.formDetailValue}
        onChange={handleChange}
        fullWidth={false}
        className={"mortgage_date"}
        size="small"
      />
    );
  };

  useEffect(() => {
    if (!formType || !form || !formDetails) return;

    const validBorrowerName = buyerBorrowerNames?.substring(0, UIConstants.JACKET_FORMDETAIL_BORROWER_NAME_MAX_LENGTH);

    const { fileAndApiFormDetails, missingFormDetails } =
      combineFileAndLookupFormDetails(
        formID || "",
        orderStatusTypeCode,
        validBorrowerName,
        isRevising,
        formDetails,
        lvFormDetails,
        translations
      );

    setCombinedFormDetails(fileAndApiFormDetails);
    setCount(fileAndApiFormDetails.length);

    if (missingFormDetails.length === 0) return;
    setValue(name, [...(formDetails || []), ...missingFormDetails]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    formDetails,
    formType,
    lvFormDetails,
    name,
    orderStatusTypeCode,
    isRevising,
  ]);

  useEffect(() => {
    setDisabled(
      !(orderStatusTypeCode === OrderStatusType.Pending || isRevising) || isReadOnly
    );
  }, [isRevising, orderStatusTypeCode, isReadOnly]);

  return (
    <>
      {combinedFormDetails && combinedFormDetails.length > 0 ? (
        <StyledTableContainer>
          <Table stickyHeader aria-label="sticky table" className="form-details-table">
            <TableHead style={{ height: 40 }}>
              <TableRow className={"tableRowStyle"}>
                <TableCell
                  colSpan={2}
                  className={"tableHeaderStyle"}
                >
                  Additional Form Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedFormDetails.map((formDetail, index) => {
                if (
                  formDetail.formDetailName === "Premium" ||
                  formDetail.formDetailName === "Premium (for Sch A only)"
                ) {
                  return null;
                }

                return (
                  <TableRow
                    key={formDetail.integrationKey}
                    className={
                      count === index + 1 ? "noBottomBorder" : ""
                    }
                  >
                    <StyledBodyCell
                      className={
                        count === index + 1
                          ? clsx(
                            disabled ? "disableBackground" : "",
                            "",
                          )
                          : disabled
                            ? "disableBackground"
                            : ""
                      }
                    >
                      <StyleCellText
                        className={clsx(disabled ? "labelDisabled" : "labelEnabled")}
                      >
                        {formDetail.formDetailDisplayName}
                      </StyleCellText>
                    </StyledBodyCell>
                    <StyledBodyCell
                      className={
                        count === index + 1
                          ? clsx(
                            disabled ? "disableBackground" : "",
                            "",
                          )
                          : disabled
                            ? "disableBackground"
                            : ""
                      }
                    >
                      <StyleCellText>
                        {!disabled
                          ? (
                            (formDetail.formDetailType === "Boolean" && renderRadioButton(formDetail, index))
                            || (formDetail.formDetailType === "Integer" && renderCurrency(formDetail, index))
                            || (formDetail.formDetailType === "Date" && renderDate(formDetail, index))
                            || (formDetail.formDetailType === "Text" && renderText(formDetail, index))
                            || (formDetail.formDetailType === "Number" && renderText(formDetail, index))
                          )
                          : (
                            (formDetail.formDetailType === "Boolean" && renderRadioButton(formDetail, index))
                            || (formDetail.formDetailType === "Integer" && formatCurrency(formDetail.formDetailValue))
                            || formDetail.formDetailValue
                          )
                        }
                      </StyleCellText>
                    </StyledBodyCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      ) : null}
      <input type="hidden" {...register(`${name}` as const)} />
    </>
  );
};

export default React.memo(JacketFormDetails, ((prevProps: Readonly<PropsWithChildren<Props>>, nextProps: Readonly<PropsWithChildren<Props>>) => {
  return isEqual(prevProps, nextProps);
}));
