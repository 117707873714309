import React from "react";

type Props = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const PdfTabPanel = ({ children, value, index, ...other }: Props) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index ? (
      <div
        style={{
          padding: "0%",
          position: "absolute",
          height: "91%",
          width: "100%"
        }}
      >
        {children}
      </div>
    ) : null}
  </div>
);

export default PdfTabPanel;
