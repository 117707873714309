import { PricingProductSortOrder2, ProductItemType } from "./enum";

export const getSortOrder1 = (productItemType: string, displayFileSpecialCharge: number): number => {
  let sortOrder = 0; 
  if (
    displayFileSpecialCharge === 1 &&
    productItemType === ProductItemType.JacketSpecialCharge
  ) {
    sortOrder = 99; // should be at last 
  }

  return sortOrder;
}
export const getSortOrder3 = (productItemType: string):PricingProductSortOrder2 => {
  let sortOrder = PricingProductSortOrder2.Product;
  switch (productItemType) {
    case ProductItemType.Endorsement:
      sortOrder = PricingProductSortOrder2.Endorsement;
      break;
    case ProductItemType.JacketAdditionalCharge:
      sortOrder = PricingProductSortOrder2.AdditionalCharge;
      break;
    case ProductItemType.JacketSpecialCharge:
      sortOrder = PricingProductSortOrder2.SpecialCharges;
      break;        
    default: 
      sortOrder = PricingProductSortOrder2.Product;
  }

  return sortOrder;
}