import React from "react";
import LevelField from "./LevelField";
import { 
  FormProvider, 
  useForm,
} from "react-hook-form";

const SignatureLevel = () => {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      signatureLevelTypeCode: undefined,
    },
  });  
   return (
    <>
      <FormProvider {...methods}>
        <LevelField />
      </FormProvider>
    </>
  );
};

export default SignatureLevel;
