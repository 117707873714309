import {
  Box,
  ListItemText,
  MenuItem,
  styled,
} from "@mui/material";
import { MultiSelectContainer } from "./MultiSelectContainer";
import OverflowTooltip from "controls/global/overflow-tooltip";
import React, {
  useState,
} from "react";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import { fontSize } from "theme/defaultStyle";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";

export interface MultiSelectFieldOption {
  value: string;
  text: string;
  selected?: boolean;
}

type Props = {
  label: string;
  name: string;
  options: MultiSelectFieldOption[];
  value?: string[];
  isError?: boolean;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

const EmptyMenuItem = styled(MenuItem)({
  backgroundColor: "transparent",
  color: "gray",
  pointerEvents: "none",
});

const StyledListItemText = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontSize: fontSize.xlarge,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
});

const MultiSelectInputField = ({
  label,
  name,
  options,
  value: selectedItemsValue,
  isError = false,
  disabled = false,
  onChange,
}: Props) => {
  const [open, setOpen] = useState(false);

  const getRenderValue = (selected: any) => {
    if (options.length === 0 || !selected || selected.length === 0) return "";

    const fieldText = selected
      .map((selectedVal: string) => getSelectedValueName(selectedVal))
      .filter((x: string) => Boolean(x) || (x && x.trim() !== ""))
      .join(", ");

    return <OverflowTooltip tooltip={fieldText} text={fieldText} />;
  };

  const getSelectedValueName = (value: string): string | undefined => {
    return options.find((option) => option.value === value)?.text;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const selectedItems = event.target.value;
    if (selectedItems.length > 1) {
      setOpen(true);
      return;
    }

    onChange?.(event);
  };

  return (
    <div>
      <MultiSelectContainer
        name={name}
        label={label}
        disabled={disabled}
        select
        error={isError}
        fullWidth
        inputProps={{
          "aria-errormessage": isError ? "rhfvalidationerror" : "",
        }}
        variant="outlined"
        InputProps={{ disableUnderline: true }}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          },
          multiple: true,
          value: selectedItemsValue,
          renderValue: getRenderValue,
        }}
        onChange={handleChange}
      >
        {options.length ? (
          options.map((opt) => (
            <MenuItem
              key={`multi-select-${opt.value}`}
              value={opt.value}
              style={{ background: "white", color: "black" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <CheckboxInput
                  checked={
                    selectedItemsValue &&
                    selectedItemsValue.indexOf(opt.value) > -1
                  }
                  name={name}
                />
                <StyledListItemText primary={opt.text} />
              </Box>
            </MenuItem>
          ))
        ) : (
          <EmptyMenuItem>NO DATA FOUND</EmptyMenuItem>
        )}
      </MultiSelectContainer>
      <ErrorDialog
        isOpen={open}
        confirmationMessage="Only one additional party is allowed."
        onYes={() => setOpen(false)}
        onNo={() => setOpen(false)}
      />
    </div>
  );
};

export default MultiSelectInputField;
