import {
  filePartyDTO,
  GeneralFileParty,
} from "entities/UIModel";
import { cloneDeep } from "lodash";
import { isValidParty } from "pages/file/utils/helper";
import { getDisplayNameForBuyerSeller } from "pages/file/utils/partyHelper";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = {
  sellers: GeneralFileParty[];
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setSellers:
    (sellerParties: GeneralFileParty[]) =>
      ({ setState }: StoreApi) => {
        setState({
          sellers: cloneDeep(sellerParties),
        });
      },
  updateZipCodeSeller: (indexSeller: number, value: string) => ({ setState, getState }: StoreApi) => {
    const sellers = getState().sellers.map((seller: GeneralFileParty, index: number) => {
      if (index === indexSeller) {
        seller.zipCode = value;
      }

      return seller;
    });
    setState({ sellers: sellers });
  }
};

const Store = createStore<State, Actions>({
  initialState: {
    sellers: [],
  },
  actions,
  name: "PartySeller",
});

const getPartySellerNames = (state: State) => {
  const sellerNames: string[] = state.sellers
    ?.filter((seller) => isValidParty(seller))
    ?.map((seller: GeneralFileParty) =>
      getDisplayNameForBuyerSeller(seller)?.trim()
    );

  return sellerNames?.join(", ").trim();
};

const getPartySellerDefault = (
  state: State,
) => {
  let defaultSeller: filePartyDTO | undefined;
  const foundSeller = state.sellers?.find((seller: GeneralFileParty) =>
    isValidParty(seller)
  );

  if (foundSeller) {
    defaultSeller = {
      integrationKey: foundSeller.integrationKey,
      isForeignAddress: foundSeller.isForeignAddress,
      addressOne: foundSeller.addressOne,
      addressTwo: foundSeller.addressTwo,
      city: foundSeller.city,
      state: foundSeller.state,
      foreignState: foundSeller.foreignState,
      zipCode: foundSeller.zipCode,
      country: foundSeller.country,
      foreignCountry: foundSeller.foreignCountry,
    };
  }

  return defaultSeller;
};


const hasValidSeller = (state: State) =>
  state.sellers?.some((seller: GeneralFileParty) => isValidParty(seller));

const usePartySellerActions = createHook(Store, {
  selector: null,
});

const usePartySellerNames = createHook(Store, {
  selector: getPartySellerNames,
});

const usePartySellerDefault = createHook(Store, {
  selector: getPartySellerDefault,
});

const usePartySellerHasValidSeller = createHook(Store, {
  selector: hasValidSeller,
});

export {
  usePartySellerActions, usePartySellerDefault,
  usePartySellerHasValidSeller, usePartySellerNames
};

