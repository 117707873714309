import {
  styled,
  TableCell,
} from "@mui/material";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import {
  borderSize,
  colors,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableFooterCell = styled(TableCell)((props) => ({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.grey08,
  fontWeight: fontWeight.bold1,
  maxWidth: "170px",
  overflow: "hidden",
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
  "&.normalPadding": {
    paddingBottom: padding.xsmall2,
    paddingTop: padding.small2,
  },
  "&.bigPadding": {
    paddingBottom: padding.large1,
    paddingTop: padding.large1,
  },
}));

const TotalDueTotalTooltipCell = styled(TooltipCell)({
  "&.totalDueUnderline": {
    borderBottom: `${borderSize.xlarge} solid ${colors.maroon01} !important`,
    display: "inline-block",
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "&.totalDueNoUnderline": {
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

type Props = {
  totalDueTotal: string;
  hasIssuedProducts: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
};

export default function TotalDueTotal({ totalDueTotal, hasIssuedProducts, hasFileLockedWithUpdateablePricingItem }: Props) {

  const footerCellClassname = hasFileLockedWithUpdateablePricingItem
    ? "normalPadding"
    : "bigPadding";

  const totalClassname = hasFileLockedWithUpdateablePricingItem
    ? "totalDueNoUnderline"
    : "totalDueUnderline";


  return (
    <StyledTableFooterCell
      align="right"
      className={footerCellClassname}
    >
      {hasIssuedProducts &&
        <TotalDueTotalTooltipCell
          className={totalClassname}
          title={totalDueTotal}
        />
      }
    </StyledTableFooterCell>
  );
}
