import { styled } from "@mui/material";
import ForeignAddressCheckbox from "controls/global/foreign-address/ForeignAddressCheckbox";
import React from "react";
import theme from "theme/default";
import { gaps } from "theme/defaultStyle";
import TogglePropertyAddress from "./TogglePropertyAddress";

type NewProps = {
  schema: string;
  showPropertyCheckbox: boolean;
  showForeignAddressCheckbox: boolean;
  callbackIndex?: number;
  isDisabledAddress?: boolean;
  onBlurHandler?: any;
  onForeignAddressChange?: any;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  width: "100%",
  "&:empty": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
});

const AddressCheckboxes = ({
  schema,
  showPropertyCheckbox,
  showForeignAddressCheckbox,
  callbackIndex,
  isDisabledAddress = false,
  onForeignAddressChange,
  onBlurHandler,
}: NewProps) => {
  return (
    <Container>
      {showPropertyCheckbox && (
        <TogglePropertyAddress
          schema={schema}
          callbackIndex={callbackIndex}
          onBlur={onBlurHandler}
          disabled={isDisabledAddress}
        />
      )}
      {showForeignAddressCheckbox && (
        <ForeignAddressCheckbox
          disabled={isDisabledAddress}
          schema={schema}
          onForeignAddressChange={onForeignAddressChange}
          onBlur={onBlurHandler}
        />
      )}
    </Container>
  );
};

export default AddressCheckboxes;
