import {
  AAL,
  CPL,
  Jacket,
  Endorsement,
  Pricing,
} from "entities/UIModel";
import { PricingOriginalPolicy } from "entities/UIModel/PricingOriginalPolicy";
import { OrderStatusType } from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";

export function getDefaultJacketObject(isAddClicked?: boolean): Jacket {
  if (!isAddClicked) {
    isAddClicked = false;
  }
  return {
    isDefault: !isAddClicked,
    isLocationDefault: true,
    isDirty: isAddClicked,
    id: 0,
    identityKey: 0,
    fileID: 0,
    orderID: 0,
    formType: "",
    insuredNames: "",
    insuredNamesData: { hasInsuredNamesChanged: false },
    effectiveDate: null,
    form: "",
    integrationKey: uuidv4(),
    orderStatusTypeCode: OrderStatusType.Pending,
    endorsements: [],
    formDetails: [],
    newPremiumAmount: 0,
    pricingDetail: {
      filePricingDetailID: 0,
      fileID: 0,
      filePricingID: 0,
      orderID: 0,
      liability: 0,
      integrationKey: uuidv4(),
      isSimultaneous: false,
      isLeasehold: false,
      isReissue: false,
      locationDisplayName: "",
      isValidatingLocationRequired: false,
    },
    isValidationRequired: false,
    originalJacketDate: null,
    originalJacketLiability: null,
    originalJacketNumber: null,
    originalJacketState: null,
    originalJacketType: null,
    displayOrder: 0,
    excludePremium: false,
  };
}

export function getDefaultPricingObject(): Pricing {
  return {
    integrationKey: uuidv4(),
    fileID: 0,
    id: 0,
    disableSimultaneousRate: false,
    isActualCalc: false,
    isCalculationRequired: false,
    isDisclosed_TRIDCalc: false,
    isExcludedFromAutoReport: false,
    isIntegratedPricing: false,
    isCoInsurance: false,
    isOverride: false,
    isSimultaneousRate: false,
    isSimultaneousRateAllowed: true,
    isEffectiveDateAllowed: true,
    isUpdated: false,
    overrideReason: "",
    rateEffectiveDate: undefined,
    showAgentRetention: false,
    showCoInsurance: false,
    showLiabilityAmount: false,
    showOPNLiabilityAmount: false,
    showOPNCoverageType: false,
    showOPNFormType: false,
    showOPNPolicyDate: false,
    showOPNPolicyNumber: false,
    showOPNPriorPolicyLast10Yrs: false,
    showReissue: false,
    showReissueCheckbox: false,
    showReissueColumnLabel: false,
    showReissueEdit: false,
    showRiskRate: false,
    showTax: false,
  };
}

export const getDefaultPricingPolicyObject = (): PricingOriginalPolicy => {
  return {
    date: undefined,
    coverageType: undefined,
    liabilityAmount: undefined,
    previouslyIssued: undefined,
  };
};

export function getDefaultEndorsementObject(
  isIssuedAfterJacket: string
): Endorsement {
  //Get Product entity

  return {
    integrationKey: uuidv4(),
    endorsementName: "",
    effectiveDate: null,
    isSFE: false,
    isSelected: false,
    endorsementStatusTypeCode: OrderStatusType.Pending,
    isIssuedAfterJacket: isIssuedAfterJacket,
    pricingDetail: {
      integrationKey: uuidv4(),
      fileID: 0,
      orderID: 0,
      filePricingID: 0,
      filePricingDetailID: 0,
      liability: 0,      
      transCode: "",
      transCodeDescription: "",
      transCodeKey: ""
    }
  };
}

export function getDefaultAalObject(isAddClicked?: boolean): AAL {
  if (!isAddClicked) {
    isAddClicked = false;
  }
  return {
    isDefault: !isAddClicked,
    isLocationDefault: true,
    isDirty: isAddClicked,
    id: 0,
    identityKey: 0,
    fileID: 0,
    orderID: 0,
    orderStatusTypeCode: OrderStatusType.Pending,
    integrationKey: uuidv4(),
    effectiveDate: new Date(),
    coveredParty: "",
    addresseeNames: "",
    buyerBorrower: "",
    seller: "",
    addlParties: [],
    form: "",
    includeAllBranches: false,
    displayOrder: 0,
    pricingDetail: {
      filePricingDetailID: 0,
      fileID: 0,
      filePricingID: 0,
      orderID: 0,
      liability: 0,
      integrationKey: uuidv4(),
      locationDisplayName: "",
    },
    isForeignAddress: 0,
    foreignCountry: "",
    foreignState: "",
  };
}

export function getDefaultCplObject(isAddClicked?: boolean): CPL {
  if (!isAddClicked) {
    isAddClicked = false;
  }
  return {
    isDefault: !isAddClicked,
    isLocationDefault: true,
    isDirty: isAddClicked,
    id: 0,
    identityKey: 0,
    fileID: 0,
    orderID: 0,
    orderStatusTypeCode: OrderStatusType.Pending,
    integrationKey: uuidv4(),
    effectiveDate: new Date(),
    coveredParty: "",
    addresseeNames: "",
    buyerBorrower: "",
    seller: "",
    addlParties: [],
    form: "",
    includeAllBranches: false,
    printAttorneyOnly: false,
    displayOrder: 0,
    pricingDetail: {
      filePricingDetailID: 0,
      fileID: 0,
      filePricingID: 0,
      orderID: 0,
      liability: 0,
      integrationKey: uuidv4(),
      locationDisplayName: "",
      isValidatingLocationRequired: false,
    },
    isForeignAddress: 0,
    foreignCountry: "",
    foreignState: "",
    foreignAddressOne: "",
    foreignAddressTwo: "",
    foreignCity: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    county: { code: "", name: "" },
    state: { code: "", abbr: "" },
    zipCode: "",
  };
}
