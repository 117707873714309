interface OfficeSolution {
  id: string;
  heading1: string;
  heading2: string;
  heading3: string[];
  items: OfficeSolutionItem[];
  addtionalInfo: {
    data: string[];
  };
}

interface OfficeSolutionItem {
  title: string;
  description: string[];
}

export const officeSolution: OfficeSolution[] = [
  {
    id: "Check Stock",
    heading1: "Check Stock",
    heading2: "",
    heading3: [
      "Stewart's subsidiary, PropertyInfo Corp., provides check stock to Stewart agencies. PropertyInfo's check stock has ten szecurity features to prevent fraud and theft.",
      "<em>Stewart agencies can receive 25% off their initial order.</em>Pricing is very competitive and usually less expensive than other providers",
      "Check stock is available in three standard colors with same day shipping and is compatible with AIM for Windows, AIM+ and Financial Access.",
    ],
    items: [
      {
        title:
          "For pricing and additional information, email <a href='mailto:TitleTechnology@stewart.com'>TitleTechnology@stewart.com</a> or call 866-608-6657.",
        description: [],
      },
    ],
    addtionalInfo: {
      data: [],
    },
  },
  {
    id: "Copiers and Printers",
    heading1: "Ricoh USA Inc. ",
    heading2: "",
    heading3: [
      "As part of Stewart's effort to help our independent agency network be more cost effective and create improved buying power, Ricoh has been selected as a preferred provider of multifunctional/copier and printer devices. Ricoh creates benefits for our independent agencies that include:",
    ],
    items: [
      {
        title: "",
        description: [
          "Discounts on Ricoh products and services of up to 75% off of retail when you purchase or lease through our National Account Program. The Ricoh product line includes multifunctional copiers, printers, check printers, plotters, scanners, short-throw projectors, and integrated whiteboards.",
          "Ricoh multifunctional products that integrate with Stewart technology, including SureClose and ResWare.",
          "Ricoh MFD's are designed with forward-thinking technology and can be transformed into an information portal by connecting to the cloud from your smart phone, tablet or another mobile device to use the MFD to print, share and discover the value of customized convenience.",
          "Ricoh has an extensive national footprint to deliver a local touch to all of our Stewart agents. The Ricoh program is unique in their industry as they are able to deliver a consistent message to our customers from one servicing company. ",
          "Ricoh provides dedicated national account resources to support all of the Stewart offices and agents. Our Ricoh client executive has 30 years of experience working with Title companies and has a tremendous working knowledge of our industry and our needs. Her team will work with you to recommend Ricoh solutions that will be the right fit for your office.",
          "Data security and encryption features to help protect the vital information of customers and business partners.",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "For more information including pricing and recommendations please reach out to:",
        "<b>Ivie Peck</b>",
        "Senior Technology Specialist",
        "Office: (832) 264-5168",
        "<a href='mailto:ivie.quinn-peck@ricoh-usa.com'>ivie.quinn-peck@ricoh-usa.com</a> ",
        "<br/> <b>Shelley Parker </b>",
        "Client Executive ",
        "Office: (713) 206-9661 ",
        "<a href='mailto:shelley.parker@ricoh-usa.com'>shelley.parker@ricoh-usa.com</a> ",
      ],
    },
  },
  {
    id: "Office Breakroom Solutions",
    heading1:
      "Innovative Refreshment Solutions to Transform the Workplace Experience",
    heading2: "",
    heading3: [
      "Aramark is North America's largest refreshment provider, serving more than one billion cups of coffee per year and more than 100,000 client locations, with full service delivery or online ordering. They offer a suite of customized turnkey solutions for multiple points of service, satisfying needs, driving employee satisfaction, providing a consistent program across all locations and fulfilling all breakroom needs.",
      "Available services include office coffee service, water filtration, ice machine, micro-markets, grab & go snacks and vending service. These services include route delivered coffee, equipment, tea, hot chocolate, creamers, sweeteners, cups/lids, paper/plastic ware, snacks & beverages and much more.",
    ],
    items: [],
    addtionalInfo: {
      data: [
        "If you would like to get more information about Stewart’s breakroom fulfillment program, click <a href='https://www.suppliersolutions.com/aramark/shop/login.asp' target='_Innovative Refreshment'> here</a>",
      ],
    },
  },
  {
    id: "Office Supply Services",
    heading1: "Office Depot",
    heading2: "",
    heading3: [
      "As part of our Stewart independent agency network, we are able to help you save time and money with our preferred supplier, Office Depot. When you shop with Office Depot through Stewart Preferred, you'll receive reduced pricing on more than 25,000 office products and services, including:",
    ],
    items: [
      {
        title: "",
        description: [
          "Office supplies, toner and copy paper",
          "Technology solutions ",
          "Furniture and space planning ",
          "Printing and document services",
          "Cleaning and break room supplies",
          "Free delivery on orders of $50 or more ",
          "Flexible purchase options - online, via catalog, by phone, or in-store pickup.",
        ],
      },
      {
        title:
          "<p style='font-size: 16px; font-weight: normal;'>In addition, Office Depot now offers a Keurig brewer program for our independent agents. Simply order your K-Cup and breakroom supplies through Office Depot and you can qualify for a Keurig Brewer for only $1.00! Office Depot offers various sizes (10-300 users) and types (pour-over or plumbed) of Keurig brewers to best fit your office needs.<br/><br/> There are 3 sizes of brewers to choose from:</p>",
        description: [
          "Office sizes up to 15 staff + client traffic – Model K140 (pour-over only)",
          "Office sizes up to 30 staff + client traffic – Model K150(pour-over or plumbed)",
          "Office sizes greater than 30 + client traffic – Model K3000SE(plumbed only) *",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "* This offer is <u>not</u> available for offices using a turnkey replenishment company for fulfilling your breakroom needs, such as Aramark Refreshment Services. One brewer per breakroom / office.",
        "<strong>How do you get started?</strong>",
        "Click <a href='https://www.stewart.com/content/dam/stewart/stewartpreferred/Docs/stewart-independent-agents-self-registration-guide-for-office-depot_12.3.20.doc' target='_blank'>here</a> and follow the instructions in the Registration Guide to get started.",
        "Please contact Office Depot Account Manager, <a href='mailto:william.duplichan@odpbusiness.com'>William Duplichan</a>, for additional assistance.",
        "<strong>Account Billing</strong>",
        "If you anticipate a large monthly order volume, you may qualify for Account Billing. Please contact Office Depot Account Manager, <a href='mailto:william.duplichan@odpbusiness.com'>William Duplichan</a>, for assistance in setting up your account.",
      ],
    },
  },
];
