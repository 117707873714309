import { styled } from "@mui/material";
import LightTooltip from "controls/global/light-tooltip";
import LinkButton from "controls/global/link-button";
import TextInputField from "controls/global/text-input-field";
import TextInput from "controls/global/text-input-field/TextInput";
import copy from "copy-to-clipboard";
import React from "react";
import { gaps } from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  OrderStatusTypeName,
  ProductType,
} from "utils/data/enum";

type ProductDetailsProps = {
  issueDate: string;
  productIndex: number;
  serialNumber?: string;
  status?: string;
  productType: string;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  "& > :nth-child(1)": {
    flexGrow: 1,
    minWidth: "200px",
    width: "unset",
  },
  "& > :nth-child(3)": {
    flexGrow: 1,
    minWidth: "300px",
    width: "unset",
  },
});

const TooltipButtonContainer = styled("div")({
  flexGrow: 2,
  minWidth: "250px",
  position: "relative",
  "& .tooltip-button": {
    height: "10px",
    position: "absolute",
    right: "10px",
    top: "0px",
    width: "80px",
  },
  " & .MuiButtonBase-root": {
    top: "-13px",
  },
});

const SerialNumberSectionField = (props: ProductDetailsProps) => {
  const { getValues } = useFormWrapper();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const copyButtonDisabled = () => {
    const isDisabled = props.status !== OrderStatusTypeName.Issued;
    return isDisabled;
  };

  const copySerialNumber = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (copyButtonDisabled()) return;

    const serialNumber = getValues(
      `jackets.${props.productIndex}.productReferenceID`
    );
    copy(serialNumber);
  };

  const openTooltip = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (copyButtonDisabled()) return;
    setIsTooltipOpen(true);
  };

  const closeTooltip = () => {
    setIsTooltipOpen(false);
  };

  return (
    <Container>
      {props.productType === ProductType.Cpl ? (
        <TextInput
          label="Issue Date"
          name={`cpls.${props.productIndex}.issueDateName`}
          disabled={true}
          value={props.issueDate}
        />
      ) : (
        <TextInput
          label="Issue Date"
          name={`jackets.${props.productIndex}.issueDateName`}
          disabled={true}
          value={props.issueDate}
        />
      )}
      {props.productType === ProductType.Cpl ? (
        <TextInputField
          label="Serial Number"
          disabled={true}
          name={`cpls.${props.productIndex}.productReferenceID`}
        //value={props.serialNumber}
        />
      ) : (
        <TooltipButtonContainer>
          <TextInputField
            label="Policy Number"
            disabled={true}
            name={`jackets.${props.productIndex}.productReferenceID`}
            actionButton={
              <LightTooltip title="Copy Policy Number" open={isTooltipOpen} arrow>
                <div
                  data-disabled={copyButtonDisabled()}
                  className={"tooltip-button"}
                >
                  <LinkButton
                    onClick={copySerialNumber}
                    buttonStyle="floatRight"
                    tabIndex="-1"
                    disabledTextField={true}
                    onMouseEnter={openTooltip}
                    onMouseLeave={closeTooltip}
                  >
                    <span className="MuiButton-label">Copy</span>
                  </LinkButton>
                </div>
              </LightTooltip>
            }
          />
        </TooltipButtonContainer>
      )}
      {props.productType === ProductType.Cpl ? (
        <TextInput
          label="Status"
          disabled={true}
          value={props.status}
          name={`cpls.${props.productIndex}.statusName`}
        />
      ) : (
        <TextInput
          label="Status"
          disabled={true}
          value={props.status}
          name={`jackets.${props.productIndex}.statusName`}
        />
      )}
    </Container>
  );
};

export default SerialNumberSectionField;
