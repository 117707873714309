
import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";

import React, {
  ReactEventHandler,
  useEffect,  
} from "react";
import {  
  useWatch,
  useFormContext,
} from "react-hook-form";
import { useAgencyLocations, useAgencyLocationsActions } from "utils/context/AgencyLocationsContext";


type Props = {
  name: string;
  userStates?:  SelectFieldOption[];
};

const AgencyLocationField = ({name, userStates}: Props) => {
  const {setValue, getValues } = useFormContext();    
  const [agencyField, stateField] = useWatch({
    name: ["userProfile.defaultAgencyID", "userProfile.defaultPropertyState"],
  });  
  const [, { getAgencyLocations }] =
  useAgencyLocationsActions();

  const [{ fileAgencyLocations }] = useAgencyLocations();

  const handleOnChangeEvent = (_: ReactEventHandler, item: any) => {    
    setValue(name, item);
  };
  
  useEffect(() => {
    if (agencyField) {      
      const currentAgencyID = getValues("userProfile.defaultAgencyID");
      const currentStateField = getValues("userProfile.defaultPropertyState");
      let stateAbbr: string|undefined = ""
      if(currentStateField){
        stateAbbr = userStates?.find((s) => s.value === currentStateField)?.text;
      }
      getAgencyLocations(currentAgencyID, stateAbbr);
    }else {
      setValue(name, {text: "", value: ""});
    }
  }, [agencyField, stateField, getAgencyLocations, getValues]);

  useEffect(() => {
    if(fileAgencyLocations?.length === 1) {
      setValue(name, fileAgencyLocations[0]);
    } else if(fileAgencyLocations?.length > 1) {
      const currentAgencyLocationID = getValues(name);
      if(currentAgencyLocationID?.value) {
        const matchingAgencyLocation = fileAgencyLocations.find((al) => al.value === currentAgencyLocationID.value);
        if(matchingAgencyLocation?.value) {
          setValue(name, matchingAgencyLocation);
        } else {
          setValue(name, {text: "", value: ""});
        }
      }
    }else {
      setValue(name, {text: "", value: ""});      
    }
  },[fileAgencyLocations, setValue, name, getValues]);
 
  return (
    <SelectField
        label="Agency Location"
        name={name}
        options={fileAgencyLocations}
        optionsValueField="value"
        optionsTextField="text"
        dataValueField="value"
        dataTextField="text"
        disabled={fileAgencyLocations.length < 1 || !agencyField}
        onChange={handleOnChangeEvent}
      />
  );
};

export default AgencyLocationField;
