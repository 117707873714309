import { NavigateToInfo, NavigationInfo } from "entities/UIModel/NavigationInfo";
import { GlobalAccessAgencyLinkText, globalAccessButton } from "layout/components/GlobalAccessAgency";
import { getUrlByMenuId, menuIds } from "pages/home/menu";
import { NavigateToType, PolicyImageTabName, ProductTabNameExtension, ProductTabTitle } from "utils/data/enum";

export const parseNumber = (x: any) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1], 10);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1], 10);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const getRoundedValue = (value?: string) => {
  if (!value) return;

  const sanitisedValue = Array.from(value).reduce((currentVal, char, index) => {
    if (
      ((char >= "0" && char <= "9") ||
        (char === "." && !currentVal.includes(".")) ||
        (char === "-" && index === 0)) &&
      (char === "." ? index !== 0 : true)
    ) {
      currentVal += char;
    }
    return currentVal;
  }, "");

  if (!sanitisedValue) {
    return sanitisedValue;
  }

  const integer = Math.trunc(Number(sanitisedValue));
  let decimalValue = value.split(".")[1];
  decimalValue = decimalValue && decimalValue.substring(0, 2);

  return decimalValue && parseNumber(decimalValue)
    ? parseNumber(`${integer}.${decimalValue}`)
    : sanitisedValue;
};

export const getImageType = (base64Data: string) => {
  return base64Data.substring("data:image/".length, base64Data.indexOf(";base64")).toUpperCase();
}

export function getNavigationInfo(event: Event) {
    
  const menuHomeElement = document.querySelector(`[data-menu-id="${menuIds.menuHome}"]`) as Element;
  const navigateToMenuHome = menuHomeElement?.contains(event.target as Node);
  const menuFilesElement = document.querySelector(`[data-menu-id="${menuIds.menuFiles}"]`) as Element;
  const navigateToMenuFiles = menuFilesElement?.contains(event.target as Node);
  const menuCreateFileElement = document.querySelector(`[data-menu-id="${menuIds.menuCreateFile}"]`) as Element;
  const navigateToMenuCreateFile = menuCreateFileElement?.contains(event.target as Node);
  const menuPaymentsReportPayElement = document.querySelector(`[data-menu-id="${menuIds.menuPaymentsReportPay}"]`) as Element;
  const navigateToMenuPaymentsReportPay = menuPaymentsReportPayElement?.contains(event.target as Node);
  const menuPaymentsPendingPaymentsElement = document.querySelector(`[data-menu-id="${menuIds.menuPaymentsPendingPayments}"]`) as Element;
  const navigateToMenuPaymentsPendingPayments = menuPaymentsPendingPaymentsElement?.contains(event.target as Node);
  const menuPolicyImagesElement = document.querySelector(`[data-menu-id="${menuIds.menuPolicyImages}"]`) as Element;
  const navigateToMenuPolicyImages: boolean = menuPolicyImagesElement?.contains(event.target as Node);
  const menuStewartResourcesElement = document.querySelector(`[data-menu-id="${menuIds.menuStewartResources}"]`) as Element;
  const navigateToMenuStewartResources: boolean = menuStewartResourcesElement?.contains(event.target as Node);
  const menuProfileSettingsElement = document.querySelector(`[data-menu-id="${menuIds.menuProfileSettings}"]`) as Element;
  const navigateToMenuProfileSettings = menuProfileSettingsElement?.contains(event.target as Node);
  const menuManageSignaturesElement = document.querySelector(`[data-menu-id="${menuIds.menuManageSignatures}"]`) as Element;
  const navigateToMenuManageSignatures = menuManageSignaturesElement?.contains(event.target as Node);
  const menuHelpCenterElement = document.querySelector(`[data-menu-id="${menuIds.menuHelpCenter}"]`) as Element;
  const navigateToMenuHelpCenter = menuHelpCenterElement?.contains(event.target as Node);
  const menuLogoutElement = document.querySelector(`[data-menu-id="${menuIds.menuLogout}"]`) as Element;
  const navigateToMenuLogout = menuLogoutElement?.contains(event.target as Node);

  const navigateToGlobalAccessAgency = (document.querySelector("#chooseAgency") as Element)?.contains(event.target as Node);
  const globalAccessAgencyLinkText = (document.querySelector("#chooseAgency") as HTMLElement)?.dataset?.agencyLinkText; 

  const clickedOnRemoveGlobalAccessAgency = (document.querySelector(`#${globalAccessButton.removeAgency}`) as Element)?.contains(event.target as Node);
  const clickedOnChangeGlobalAccessAgency = (document.querySelector(`#${globalAccessButton.changeAency}`) as Element)?.contains(event.target as Node);

  // console.log("clickedOnRemoveGlobalAccessAgency:", clickedOnRemoveGlobalAccessAgency);
  // console.log("clickedOnChangeGlobalAccessAgency:", clickedOnChangeGlobalAccessAgency);

  const navigateToCplTab = (event.target as HTMLElement)?.innerText?.toUpperCase() === ProductTabTitle.Cpl.toUpperCase();
  const navigateToAalTab =  (event.target as HTMLElement)?.innerText?.toUpperCase() === ProductTabTitle.Aal.toUpperCase();
  const navigateToJacketTab = (event.target as HTMLElement)?.innerText?.toUpperCase() === ProductTabTitle.Jacket.toUpperCase();
  const navigateToSaendoTab = (event.target as HTMLElement)?.innerText?.toUpperCase() === ProductTabTitle.StandaloneEndorsement.toUpperCase();
  const navigateToPolicyUploadTab = (event.target as HTMLElement)?.innerText === ProductTabTitle.PolicyUpload.toUpperCase();

  const navigateToPolicyImagesUploadImagesTab = (event.target as HTMLElement)?.innerText === PolicyImageTabName.UploadImages.toUpperCase();
  const navigateToPolicyImagesImageArhiveTab  = (event.target as HTMLElement)?.innerText === PolicyImageTabName.ImageArchive.toUpperCase();

  const navigateToInfo: NavigateToInfo = {};
  switch (true) {
    case navigateToMenuHome:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuHome);        
      break;
    case navigateToGlobalAccessAgency && globalAccessAgencyLinkText === GlobalAccessAgencyLinkText.ChooseAgency:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuGlobalAccessAgency);        
      break;
    case clickedOnRemoveGlobalAccessAgency:
        navigateToInfo.navigateToType = NavigateToType.Button;
        navigateToInfo.buttonId = globalAccessButton.removeAgency;
        break;
    case clickedOnChangeGlobalAccessAgency:
      navigateToInfo.navigateToType = NavigateToType.Button;
      navigateToInfo.buttonId = globalAccessButton.changeAency;
      break;          
    case navigateToMenuFiles:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuFiles);
      break;
    case navigateToMenuCreateFile:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuCreateFile);
      break;
    case navigateToMenuPaymentsReportPay:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuPaymentsReportPay);
      break;
    case navigateToMenuPaymentsPendingPayments:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuPaymentsPendingPayments);
      break;        
    case navigateToMenuPolicyImages:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuPolicyImages);
      break;
    case navigateToMenuStewartResources:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuStewartResources);
      break;
    case navigateToMenuProfileSettings:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuProfileSettings);
      break;
    case navigateToMenuManageSignatures:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuManageSignatures);
      break;
    case navigateToMenuHelpCenter:
      navigateToInfo.navigateToType = NavigateToType.Url;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuHelpCenter);
      break;
    case navigateToMenuLogout:
      navigateToInfo.navigateToType = NavigateToType.Adb2c;
      navigateToInfo.url = getUrlByMenuId(menuIds.menuHelpCenter);
      break;
    case navigateToCplTab: 
      navigateToInfo.navigateToType = NavigateToType.Tab;
      navigateToInfo.tabName = ProductTabTitle.Cpl.toUpperCase();
      break;
    case navigateToAalTab: 
      navigateToInfo.navigateToType = NavigateToType.Tab;
      navigateToInfo.tabName = ProductTabTitle.Aal;
      break;
    case navigateToJacketTab:
      navigateToInfo.navigateToType = NavigateToType.Tab;
      navigateToInfo.tabName = ProductTabTitle.Jacket;
      break;
    case navigateToSaendoTab:
        navigateToInfo.navigateToType = NavigateToType.Tab;
        navigateToInfo.tabName = ProductTabTitle.StandaloneEndorsement;
        break;
    case navigateToPolicyUploadTab:
        navigateToInfo.navigateToType = NavigateToType.Tab;
        navigateToInfo.tabName = ProductTabNameExtension.PolicyUpload;
        break;
    case navigateToPolicyImagesUploadImagesTab: 
      navigateToInfo.navigateToType = NavigateToType.Tab;
      navigateToInfo.tabName = PolicyImageTabName.UploadImages;
      break;        
    case navigateToPolicyImagesImageArhiveTab: 
      navigateToInfo.navigateToType = NavigateToType.Tab;
      navigateToInfo.tabName = PolicyImageTabName.ImageArchive;
      break;        
  }

  const navigationInfo: NavigationInfo = {
    navigateToMenuHome,
    navigateToMenuFiles,
    navigateToMenuCreateFile,
    navigateToMenuPaymentsReportPay,
    navigateToMenuPaymentsPendingPayments,
    navigateToMenuPolicyImages,
    navigateToMenuStewartResources,
    navigateToMenuProfileSettings,
    navigateToMenuManageSignatures,
    navigateToMenuHelpCenter,
    navigateToMenuLogout,
    navigateToGlobalAccessAgency,
    globalAccessAgencyLinkText,
    clickedOnRemoveGlobalAccessAgency,
    clickedOnChangeGlobalAccessAgency,
    navigateToCplTab,
    navigateToAalTab,
    navigateToJacketTab,
    navigateToSaendoTab,
    navigateToPolicyUploadTab,
    navigateToPolicyImagesUploadImagesTab,
    navigateToPolicyImagesImageArhiveTab,
    navigateToInfo,
  }

  // console.log(navigationInfo);
  return navigationInfo;
}