import {
  Link,
  styled,
} from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
  gaps,
  padding,
} from "theme/defaultStyle";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row-reverse",
  gap: gaps.xsmall2_5,
  lineHeight: "140%",
  paddingBottom: padding.xlarge1,
  paddingLeft: padding.zero,
  paddingRight: padding.zero,
  paddingTop: padding.xlarge1,
  "& > div": {
    width: "100%",
  },
  "& .footer-links": {
    display: "flex",
    flexDirection: "row",
    justifyContent :"flex-end",
    fontSize: fontSize.medium,
    gap: gaps.large1,
    "& > .MuiLink-root": {
      color: colors.blue01,
      textDecoration: "underline",
    },
  },
  "& .footer-text": {
    fontSize: fontSize.small,
  },
});

const getCurrentYear = () => new Date().getFullYear();

const Footer = () => {

  return (
    <Container>
      <div className="footer-links">
        <Link
          href="http://www.stewart.com/en/privacy.html"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </Link>
        <Link
          href="http://www.stewart.com/en/terms-of-use.html"
          rel="noreferrer"
          target="_blank"
        >
          Terms of Use
        </Link>
        <Link
          href="http://www.stewart.com/en/protecting-customer-information.html#st-mainParsys_generictextcomponent"
          rel="noreferrer"
          target="_blank"
        >
          Protecting Customer Information
        </Link>
      </div>
      <div className={"footer-text"}>
        © {getCurrentYear()} Stewart Title Guaranty Company. All Rights Reserved. Trademarks are the property of their
        respective owners.
      </div>
    </Container>
  );
};

export default Footer;
