import {
  styled,
  Typography,
} from "@mui/material";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import React from "react";
import {
  fontSize,
  gaps,
} from "theme/defaultStyle";
import { getImageTypeFromImageDataUrl } from "utils/signature/imageFile";
import Body from "../signature-dialog/SignatureDialogBody";
import TypedFont from "./TypedFont";
import classes from "./TypeSignature.module.css";

type Fonts =
  | "Freestyle Script"
  | "Palace Script MT"
  | "Brush Script MT"
  | "Pristina"
  | "Alex Brush"
  | "Lucida Handwriting";

export type FontConfig = {
  font: Fonts;
  style: string;
  weight: string;
  id: string;
  fontSize: number;
  topMargin: number;
  leftMargin: number;
};

const fontFamilies: FontConfig[] = [
  {
    font: "Freestyle Script",
    style: "",
    weight: "",
    id: "freestyle",
    fontSize: 38,
    topMargin: 0,
    leftMargin: 0,
  },
  {
    font: "Palace Script MT",
    style: "",
    weight: "",
    id: "palace",
    fontSize: 40,
    topMargin: 0,
    leftMargin: 0,
  },
  {
    font: "Brush Script MT",
    style: "italic",
    weight: "",
    id: "brush",
    fontSize: 36,
    topMargin: 0,
    leftMargin: 0,
  },
  {
    font: "Pristina",
    style: "italic",
    weight: "",
    id: "pristina",
    fontSize: 26,
    topMargin: 0,
    leftMargin: 0,
  },
  {
    font: "Alex Brush",
    style: "italic",
    weight: "",
    id: "alex",
    fontSize: 36,
    topMargin: 0,
    leftMargin: 0,
  },
  {
    font: "Lucida Handwriting",
    style: "",
    weight: "",
    id: "lucida",
    fontSize: 30,
    topMargin: 0,
    leftMargin: 0,
  },
];

const FontPickerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.small1,
  "& > .MuiTypography-root": {
    fontSize: fontSize.large,
  },
  "& > div": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: gaps.medium1,
  }
});

export default function TypeSignature() {
  const {
    state: { signature },
  } = useSignatureProvider();
  const { dispatch } = useSignatureDispatchProvider();

  const handleLoadCompleted = (
    image: string,
    fontFamilyID: string,
    imageType: string
  ) => {
    dispatch({
      type: "FILE_UPDATE",
      signatureFile: image,
      fontFamilyID: fontFamilyID,
      imageType: imageType,
    });
  };

  const handleOnSelectSignature = (fontFamilyID: string) => {
    const container = document.getElementById(fontFamilyID);
    if (!container) return;
    const canvas: HTMLCanvasElement = document.getElementById(
      `${fontFamilyID}_canvas`
    ) as HTMLCanvasElement;
    if (!canvas) return;

    const imageData = canvas.toDataURL();
    const imageType = getImageTypeFromImageDataUrl(imageData);
    handleLoadCompleted(imageData, fontFamilyID, imageType);
  };

  return (
    <Body
      title="Type a signature"
      width={868}
      showSignatureName
    >
      <FontPickerContainer>
        <Typography className={classes.label}>
          Choose a font option:
        </Typography>
        <div>
          {fontFamilies.map((fontFamily) => (
            <TypedFont
              key={fontFamily.id}
              fontConfig={fontFamily}
              signature={signature}
              handleSelectedSignature={handleOnSelectSignature}
            />
          ))}
        </div>
      </FontPickerContainer>
    </Body>
  );
}
