import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";
interface Props {
  width: number;
  height: number;
  getImage: (image: string) => void;
  defaultImage: string | null;
}

const CanvasWrapper = styled("div")({
  position: "relative",
  width: "300px",
});

const ThumbnailText = styled("div")({
  alignItems: "center",
  color: colors.grey12,
  display: "flex",
  fontFamily: "Alex Brush",
  fontSize: fontSize.xlarge6,
  fontStyle: "italic",
  height: "115px",
  justifyContent: "center",
  position: "absolute",
  width: "300px",
});

const StyledCanvas = styled("canvas")({
  backgroundColor: "transparent",
  border: `${borderSize.xsmall} dashed ${colors.grey13}`,
  borderRadius: borderRadius.small,
  boxSizing: "border-box",
  height: "115px",
  position: "relative",
  width: "300px",
});

const Message = styled("div")({
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  marginBottom: margin.small2,
});

function StewartDrawZone({ height, width, getImage, defaultImage }: Props) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const startDrawing = ({ nativeEvent }: any) => {
    const context = canvasRef.current?.getContext("2d");
    const { offsetX, offsetY } = nativeEvent;
    context?.beginPath();
    context?.moveTo(offsetX + 1, offsetY + 1);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    const context = canvasRef.current?.getContext("2d");
    context?.closePath();
    setIsDrawing(false);
    generateSignatureImage();
  };

  const draw = ({ nativeEvent }: any) => {
    if (!isDrawing) return;
    const context = canvasRef.current?.getContext("2d");
    const { offsetX, offsetY } = nativeEvent;
    context?.lineTo(offsetX + 1, offsetY + 1);
    context?.stroke();
    setIsEmpty(false);
  };

  const generateSignatureImage = async () => {
    if (isEmpty) return;
    const imageSrc = canvasRef.current?.toDataURL("image/png");
    getImage(imageSrc as string);
  };

  const clear = () => {
    const context = canvasRef.current?.getContext("2d");
    context?.clearRect(0, 0, width, height);
    setIsEmpty(true);
    getImage("");
  };

  const scaleCanvasContext = (context: CanvasRenderingContext2D) => {
    const scaleWidth = width / 300;
    const scaleHeight = height / 110;
    context?.setTransform(scaleWidth, 0, 0, scaleHeight, 0, 0);
  };

  const cloneImage = (imageSrc: string) => {
    const canvas = canvasRef.current;
    const context = canvasRef.current?.getContext("2d");
    context?.resetTransform();
    if (!context || !canvas) return;
    let img = new Image();
    img.src = imageSrc;
    img.onload = function () {
      // get the scale
      var scale = Math.min(
        canvas.width / img.width,
        canvas.height / img.height
      );
      // get the top left position of the image
      var x = canvas.width / 2 - (img.width / 2) * scale;
      var y = canvas.height / 2 - (img.height / 2) * scale;
      context.drawImage(img, x, y, img.width * scale, img.height * scale);
      scaleCanvasContext(context);
    };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (!context || !canvas) return;
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.lineWidth = 2;
    scaleCanvasContext(context);
  }, []);

  useEffect(() => {
    if (!defaultImage) return;
    cloneImage(defaultImage);
    setIsEmpty(false);
  }, [defaultImage]);

  return (
    <CanvasWrapper>
      <Message>Draw your signature below:</Message>
      {isEmpty && <ThumbnailText>Sign Here</ThumbnailText>}
      <StyledCanvas
        ref={canvasRef}
        width={width}
        height={height}
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
      />
      {!isEmpty &&
        <ActionButton
          onClick={clear}
          variant="outlined"
          color="secondary"
          startIcon={<ClearIcon />}
          style={{ float: "right" }}
        >
          Clear
        </ActionButton>}
    </CanvasWrapper>
  );
}

export default StewartDrawZone;
