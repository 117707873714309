import { CancelTokenSource } from "axios";
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";

type State = {
  isLoadingCompanyStateLocations: boolean;
  companyStateLocations: Array<CompanyStateLocation>;
  axiosCancelToken?: CancelTokenSource;
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const getCompanyStateLocationCachedKey = (agencyId: string, state: string) => {
  return `CompanyStateLocationCachedKey-${agencyId}~${state}`;
};

const setCompanyStateLocations =
  (data: any) =>
    ({ setState }: StoreApi) => {
      let locations: Array<CompanyStateLocation> = [];

      if (data && data.length > 0) {
        locations = data.map((l: any) => {
          const location: CompanyStateLocation = {
            contractID: l.contractID,
            companyID: l.companyID,
            legacyID: l.legacyID,

            locationID: l.locationID,
            locationUniqueID: l.locationUniqueID,
            locationDisplayName: l.locationDisplayName,
            locationLegalName: l.locationLegalName,
            locationLegacyID: l.locationLegacyID,

            locationAddress: l.locationAddress,
            locationAddress2: l.locationAddress2,
            locationCity: l.locationCity,
            locationState: l.locationState,
            locationZipCode: l.locationZipCode,
            locationCountry: l.locationCountry,
            locationMainPhone: l.locationMainPhone,
            locationMobilePhone: l.locationMobilePhone,
            locationFaxNumber: l.locationFaxNumber,
            locationPrimaryEmail: l.locationPrimaryEmail,

            altaRegistryID: l.altaRegistryID,
            territoryID: l.territoryID,

          authorityLimit: l.authorityLimit,
          underwriterTypeCode: l.underwriterTypeCode,
        };
        return location;
      });

      }

      setState({
        companyStateLocations: [...locations],
      });
    };

const actions = {
  getCompanyStateLocations:
    (agencyId: string, state: string) =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        const cachedKey = getCompanyStateLocationCachedKey(agencyId, state);
        const cachedData = sessionStorage.getItem(cachedKey);

        if (cachedData) {
          dispatch(setCompanyStateLocations(JSON.parse(cachedData)));
        } else {
          try {
            const token = getState().axiosCancelToken;
            if (token) token.cancel("getAgencyStateLocations canceled due to new request");

            const newCancelToken = cancelToken.source();
            setState({ axiosCancelToken: newCancelToken });

            setState({ isLoadingCompanyStateLocations: true });
            const { data } = await axiosSecuredInstance.get<any>(
              `/Company/GetLocationsByCompanyState?CompanyID=${agencyId}&State=${state}`,
              { cancelToken: newCancelToken.token }
            );

            sessionStorage.setItem(cachedKey, JSON.stringify(data));
            dispatch(setCompanyStateLocations(data));
            setState({ isLoadingCompanyStateLocations: false });
          } catch (error) {
            setState({ isLoadingCompanyStateLocations: false });
            console.error("ERROR: CompanyContext.", error);
          }
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    isLoadingCompanyStateLocations: false,
    companyStateLocations: [],
  },
  actions,
  name: "companyLocation",
});

const hook = createHook(Store);
const useCompanyLocation = () => {
  return hook();
};
const useCompanyLocationActions = createHook(Store, { selector: null });

export { useCompanyLocation, useCompanyLocationActions };

