import {
  filePartyDTO,
  GeneralFileParty,
} from "entities/UIModel";
import { cloneDeep } from "lodash";
import { isValidParty } from "pages/file/utils/helper";
import { getDisplayNameForBuyerBorrower } from "pages/file/utils/products/letter";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { ProductType } from "utils/data/enum";

type State = {
  buyerBorrowers: GeneralFileParty[];
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setBuyerBorrowers:
    (buyerBorrowers: GeneralFileParty[]) =>
      ({ setState }: StoreApi) => {
        setState({
          buyerBorrowers: cloneDeep(buyerBorrowers),
        });
      },
  updateZipCodeBuyerBorrower: (indexBuyer: number, value: string) => ({ setState, getState }: StoreApi) => {
    const buyerBorrowers = getState().buyerBorrowers.map((buyerBorrower: GeneralFileParty, index: number) => {
      if (index === indexBuyer) {
        buyerBorrower.zipCode = value;
      }

      return buyerBorrower;
    });
    setState({ buyerBorrowers: buyerBorrowers });
  }
};

const Store = createStore<State, Actions>({
  initialState: {
    buyerBorrowers: [],
  },
  actions,
  name: "PartyBuyerBorrower",
});

const getPartyBuyerBorrowerNames = (state: State, productType: string) => {
  const buyerNames = state.buyerBorrowers
    .filter(
      (buyerBorrower) =>
        isValidParty(buyerBorrower) &&
        ((productType === ProductType.Cpl && !buyerBorrower.isExcludedOnCPL) ||
          (productType === ProductType.Aal && !buyerBorrower.isExcludedOnAAL) ||
          (productType !== ProductType.Cpl && productType !== ProductType.Aal))
    )
    ?.map((buyerBorrower: GeneralFileParty) =>
      getDisplayNameForBuyerBorrower(buyerBorrower)?.trim()
    );

  // if(!validBuyerBorrowers) return "";
  // const buyerNames: string[] = validBuyerBorrowers.map(
  //   (buyerBorrower: GeneralFileParty) =>
  //     getDisplayNameForBuyerBorrower(buyerBorrower)?.trim()
  // );

  return buyerNames?.join(", ").trim();
};

const getPartyBuyerBorrowerDefault = (state: State, productType: string) => {
  let defaultBuyer: filePartyDTO | undefined;

  const foundBuyer = state.buyerBorrowers?.find(
    (buyerBorrower: GeneralFileParty) =>
      isValidParty(buyerBorrower) &&
      ((productType === ProductType.Cpl && !buyerBorrower.isExcludedOnCPL) ||
        (productType === ProductType.Aal && !buyerBorrower.isExcludedOnAAL))
  );

  if (foundBuyer) {
    defaultBuyer = {
      integrationKey: foundBuyer.integrationKey,
      isForeignAddress: foundBuyer.isForeignAddress,
      addressOne: foundBuyer.addressOne,
      addressTwo: foundBuyer.addressTwo,
      city: foundBuyer.city,
      state: foundBuyer.state,
      foreignState: foundBuyer.foreignState,
      zipCode: foundBuyer.zipCode,
      country: foundBuyer.country,
      foreignCountry: foundBuyer.foreignCountry,
    };
  }
  return defaultBuyer;
};

const hasValidBuyer = (state: State, productType: string) => (
  state.buyerBorrowers?.some(
    (buyerBorrower: GeneralFileParty) =>
      isValidParty(buyerBorrower) &&
      ((productType === ProductType.Cpl && !buyerBorrower.isExcludedOnCPL) ||
        (productType === ProductType.Aal && !buyerBorrower.isExcludedOnAAL) ||
        (productType !== ProductType.Cpl && productType !== ProductType.Aal))
  )
);


const usePartyBuyerBorrowerActions = createHook(Store, {
  selector: null,
});

const usePartyBuyerBorrowerNames = createHook(Store, {
  selector: getPartyBuyerBorrowerNames,
});

const usePartyBuyerBorrowerDefault = createHook(Store, {
  selector: getPartyBuyerBorrowerDefault,
});

const usePartyBuyerHasValidBuyer = createHook(Store, {
  selector: hasValidBuyer,
});


export {
  usePartyBuyerBorrowerActions, usePartyBuyerBorrowerDefault, usePartyBuyerBorrowerNames, usePartyBuyerHasValidBuyer
};
