import {
  Button,
  styled,
} from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";

interface IProps {
  children: React.ReactElement | string;
  buttonStyle?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler | undefined;
  style?: React.CSSProperties | undefined;
  tabIndex?: string;
  onMouseEnter?: any;
  onMouseLeave?: any;
  disabledTextField?: boolean;
}

const DefaultStyle = styled(Button)(() => ({
  background: "none",
  color: colors.blue01,
  cursor: "pointer",
  fontSize: fontSize.large,
  margin: margin.zero,
  padding: padding.zero,
  textTransform: "none",
  "&.Mui-disabled": {
    background: "none",
    color: colors.blue01,
    opacity: 0.5,
  },
  "&:hover": {
    background: "none",
    color: colors.blue05,
  },
}));

const FloatRightStyle = styled(Button)((props) => ({
  background: "none",
  color: colors.blue01,
  cursor: "pointer",
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal1,
  margin: margin.zero,
  padding: padding.zero,
  position: "absolute",
  right: "10px",
  textTransform: "none",
  top: "-1px",
  "&.Mui-disabled": {
    background: "none",
    color: colors.blue09,
    opacity: 1,
  },
  "&:hover": {
    background: "none",
    color: colors.blue05,
    cursor: "pointer",
  },
  "& .rectangleRightButton": {
    paddingLeft: padding.xsmall1,
    paddingRight: padding.xsmall1,
    paddingTop: padding.xsmall,
    position: "relative",
    "& div": {
      position: "relative",
      zIndex: zIndex.positiveMin,
    },
    "&::before": {
      backgroundColor: colors.white,
      content: "''",
      height: "2px",
      left: 0,
      position: "absolute",
      right: 0,
      top: "50%",
      zIndex: zIndex.zero,
    },
    "&.Mui-disabled::before": {
      backgroundColor: colors.grey10,
    },
  }
}));

const LinkButton = (props: IProps) => {
  const { children, buttonStyle = "default", onClick, disabled, style, tabIndex, onMouseEnter, onMouseLeave, disabledTextField } = props;
  switch (buttonStyle) {
    case "floatRight":
      return <FloatRightStyle
        tabIndex={Number(tabIndex)}
        style={style}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={`rectangleRightButton ${disabledTextField ? "disabled" : ""}`}>
          <div>
            {children}
          </div>
        </div>
      </FloatRightStyle>;
    default:
      return <DefaultStyle
        tabIndex={Number(tabIndex)}
        style={style}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </DefaultStyle>;
  }
};

export default LinkButton;
