import React from "react";

const BallotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="28px"
      viewBox="0 0 24 24"
      width="28px"
      fill="#FFFFFF"
    >
      <rect fill="none" fillRule="evenodd" height="24" width="24" />
      <path
        d="M13,9.5h5v-2h-5V9.5z M13,16.5h5v-2h-5V16.5z M19,21H5c-1.1,0-2-0.9-2-2V5 c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2v14C21,20.1,20.1,21,19,21z M6,11h5V6H6V11z M7,7h3v3H7V7z M6,18h5v-5H6V18z M7,14h3v3H7V14z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BallotIcon;
