import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { ZipCodeFieldProps } from "utils/interfaces/BaseAddress";
import TextInputZipField from "../text-input-field/TextInputZipField";
import PropertyZipCodeField from "./PropertyZipCodeField";

const ZipCodeField = ({
  name,
  labelPrefix = "",
  isDisabled = false,
  getZipcodeData,
  isPropertySection = false,
  onChange,
  onBlur,
}: ZipCodeFieldProps) => {
  const { setValue } = useFormWrapper();
  const handleOnBlur = (e: React.ChangeEvent<any>) => {
    setValue("isZipCodeValidationRequired", true);
    setValue(name, e.target.value);
    getZipcodeData(e.target.value, true);

    onBlur && onBlur();
  };

  const handleOnChange = (value:string) => {
    onChange && onChange(value);
  }

  return isPropertySection ? (
    <PropertyZipCodeField
      label={`${labelPrefix}Zip Code`}
      name={name}
      onBlur={handleOnBlur}
      disabled={isDisabled}
      getZipcodeData={getZipcodeData}
    />
  ) : (
    <TextInputZipField
      label={`${labelPrefix}Zip Code`}
      name={name}
      onBlur={handleOnBlur}
      disabled={isDisabled}
      onChange={handleOnChange}
    />
  );
};

export default ZipCodeField;
