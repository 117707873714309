import { useLookup } from "../context/LookupContext";
import { ReportingOptionType } from "../data/enum";

function useReportOptionType() {
  const [{ reportOptionTypes: options }] = useLookup();
  const ePayVisible = !!options.find(value => value.reportOptionTypeCode === ReportingOptionType.EPay);
  const payByCheckVisible = !!options.find(value => value.reportOptionTypeCode === ReportingOptionType.PayByCheck);
  const reportOnlyVisible = !!options.find(value => value.reportOptionTypeCode === ReportingOptionType.ReportOnly);

  return { ePayVisible, payByCheckVisible, reportOnlyVisible };
}

export default useReportOptionType;
