import {
  Box,
  Grid,
  styled,
} from "@mui/material";
import CollapsableFormSection from "controls/global/collapsable-form-section";
import {
  PricingProduct,
  StandaloneEndorsement,
} from "entities/UIModel";
import React, {
  useEffect,
} from "react";
import { useWatch } from "react-hook-form";
import {
  colors,
  fontSize,
  fontWeight,
  margin
} from "theme/defaultStyle";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useFiles } from "utils/context/FilesContext";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper, {
  getNameString
} from "utils/custom-hooks/useFormWrapper";
import {
  OrderStatusType,
  OrderStatusTypeName,
  ProductType,
} from "utils/data/enum";
import { getDefaultEndorsementObject } from "utils/data/products";
import OriginalPolicyForm from "../common/OriginalPolicyForm";
import ProductAgencyLocationField from "../common/ProductAgencyLocationField";
import StandAloneProductActions from "./StandAloneProductActions";
import EndorsementGrid from "./endorsements/Grid";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import { isIssuedStandalone } from "pages/file/utils/helper";

const InfoBox = styled(Box)({
  color: colors.grey13,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  marginBottom: margin.xlarge,
  marginTop: margin.zero,
});

interface Props {
  productIndex: number;
  standalone: StandaloneEndorsement;
  endorsementEditMode?: boolean;
  submitDisabled: boolean;
  handleStandloneSubmit: () => void;
  handleStandaloneCancel: () => void;
  handleStandaloneDelete: (index: number) => void;
}

const StandAloneProduct = ({
  productIndex,
  standalone,
  endorsementEditMode = true,
  submitDisabled,
  handleStandloneSubmit,
  handleStandaloneCancel,
  handleStandaloneDelete,
}: Props) => {
  const shouldShowDeleteButtom = useFieldDisabler(
    "StandaloneEndorsmentDeleteButton"
  );

  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();
  const [{ isReadOnly }, { resetPricingFields }] = useFiles();
  const { setValue, getValues } = useFormWrapper();
  const { reloadFile } = useCreateFile();
  const standaloneEndorsement: StandaloneEndorsement = useWatch({
    name: getNameString(`standaloneEndorsements.${productIndex}`),
    defaultValue: standalone,
  });

  const tabTitle = `Standalone Endorsement ${productIndex + 1}`;

  const setNotDefault = () => {
    setValue(`standaloneEndorsements.${productIndex}.isDefault`, false);
  };

  const setLocationNotDefault = () => {
    setNotDefault();
    setValue(`standaloneEndorsements.${productIndex}.isLocationDefault`, false);
  };

  const getStandaloneEndorsements = (): StandaloneEndorsement[] =>
    getValues("standaloneEndorsements");

  const getTabDescription = () => {
    const products = getStandaloneEndorsements();
    if (!products || products.length < productIndex + 1) return;
    const { originalJacketType, originalJacketNumber, orderStatusTypeCode } =
      products[productIndex];

    if (originalJacketType && originalJacketNumber) {
      let description = `${originalJacketType} ${originalJacketNumber}`;

      description +=
        !orderStatusTypeCode || orderStatusTypeCode === OrderStatusType.Pending
          ? ` - ${OrderStatusTypeName.Pending}`
          : "";
      return description;
    }
  };

  const issuedMode = Boolean(
    standalone?.endorsements &&
    standalone?.endorsements?.length > 0 &&
    // standaloneEndorsement.orderStatusTypeCode is undefined for unknown reasons.
    // props -> standalone has the latest value
    standalone?.orderStatusTypeCode !== OrderStatusType.Pending
  );

  const allFieldsCompleted = Boolean(
    standaloneEndorsement?.originalJacketNumber &&
    standaloneEndorsement?.originalJacketDate &&
    standaloneEndorsement?.originalJacketType &&
    standaloneEndorsement?.originalJacketLiability
  );

  const isIssuable = Boolean(
    allFieldsCompleted &&
    Number(standaloneEndorsement?.originalJacketLiability) >= 0
  ) && !isReadOnly;

  // FIXME: CurrencyInputField should handle only NUMBER properties.
  const isDisabled = Boolean(!issuedMode && !allFieldsCompleted) || isReadOnly;

  const isProductIssued = () => {
    return standaloneEndorsement &&
      standalone?.endorsements &&
      standalone?.endorsements?.length > 0
      ? isIssuedStandalone(standalone)
      : false;
  };

  // Set to true ONLY if there are any new entered endorsements
  const handleEndorsementChange = (productIndex: number) => {
    const products = getStandaloneEndorsements();
    if (!products || products.length < productIndex + 1) return;
  };

  const reloadFileWithProgress = async () => {
    openAutomaticProgressDialog();
    await reloadFile();
    resetPricingInitialValues();
    closeAutomaticProgressDialog();
  };

  const handleEndorsementVoidComplete = async () => {
    await reloadFileWithProgress();
  };

  const resetPricingInitialValues = () => {
    const pricingProducts = getValues("pricingProducts") as Array<PricingProduct>;
    const noIssuedProducts = !pricingProducts.length;
    if (noIssuedProducts) {
      setValue("pricing.isSimultaneousRate", false);
      setValue("pricing.isOverride", false);
      setValue("pricing.overrideReason", "");
      setValue("pricing.rateEffectiveDate", undefined);
      resetPricingFields({
        isSimultaneousRate: false,
        isOverride: false,
        overrideReason: "",
        rateEffectiveDate: undefined
      });
    }
  };

  const showDelete = () => {
    if (shouldShowDeleteButtom) return false;
    return (
      //standalone?.orderStatusTypeCode === OrderStatusType.Pending
      !isProductIssued()
    );
  };

  // useEffect(() => {
  //   if (standaloneEndorsement?.isTransCodeRequired)
  //     setValue(
  //       `standaloneEndorsements.${productIndex}.isTransCodeRequired`,
  //       false
  //     );
  // }, [productIndex, setValue, standaloneEndorsement?.isTransCodeRequired]);

  useEffect(() => {
    if (
      standaloneEndorsement?.endorsements &&
      standaloneEndorsement?.endorsements?.length === 0
    ) {
      setValue(`standaloneEndorsements.${productIndex}.endorsements`, [
        getDefaultEndorsementObject("1"),
      ]);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIndex, standaloneEndorsement?.endorsements]);

  return (
    <CollapsableFormSection
      index={productIndex}
      showDelete={showDelete()}
      onDelete={() => handleStandaloneDelete(productIndex)}
      label={getTabDescription()}
      title={tabTitle}
      isProductIssuePending={
        standalone?.orderStatusTypeCode === OrderStatusType.Pending
      }
    >
      <>
        <InfoBox>
          <span>
            Standalone Endorsements are used to add an endorsement to a policy
            that does not exist in Stewart Connect.
          </span>
        </InfoBox>
        <OriginalPolicyForm
          schema={`standaloneEndorsements.${productIndex}`}
          disabled={isProductIssued()}
          onBlurCallback={setNotDefault}
        />
        <EndorsementGrid
          index={productIndex}
          formType={standalone?.originalJacketType}
          editMode={endorsementEditMode}
          minDate={standalone?.originalJacketDate}
          parentOrderID={standalone?.orderID || 0}
          disabled={isDisabled}
          issuedMode={issuedMode}
          isIssuable={isIssuable}
          handleEndorsementChange={handleEndorsementChange}
          onVoidComplete={handleEndorsementVoidComplete}          
          isTransCodeRequired={standaloneEndorsement?.isTransCodeRequired}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ProductAgencyLocationField
              label="Agency Location"
              productType={ProductType.StandaloneEndorsement}
              schema={`standaloneEndorsements.${productIndex}`}
              name={`standaloneEndorsements.${productIndex}.pricingDetail.locationDisplayName`}
              disabled={isProductIssued()}
              onChange={setLocationNotDefault}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <StandAloneProductActions
              fullName={`standaloneEndorsements.${productIndex}`}
              isIssuable={isIssuable}
              submitDisabled={submitDisabled}
              handleStandloneSubmit={handleStandloneSubmit}
              handleStandaloneCancel={handleStandaloneCancel}
              isProductIssued={isProductIssued()}
            />
          </Grid>
        </Grid>
      </>
    </CollapsableFormSection>
  );
};

export default StandAloneProduct;
