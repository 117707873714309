import TextInput from "controls/global/text-input-field/TextInput";
import React from "react";
import { ZipCodeFieldProps } from "utils/interfaces/BaseAddress";
import ErrorMessage from "controls/global/error-message";
import { useWatch } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { getIn } from "utils/object";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
// import { useIsFileLoadingFiles } from "utils/context/FilesContext";

const PropertyZipCodeField = ({
  name,
  value,
  getZipcodeData,
  labelPrefix = "",
  disabled,
}: ZipCodeFieldProps) => {
  //Because so many things react to properties.zipCode changing, we are using a custom control to manage when the field is updated.
  const [internalValue, setInternalValue] = React.useState<string>("");
  const { setValue, formState } = useFormWrapper();
  const [zipCode] = useWatch({ name: [name] });
  // const [{ isFileLoading }] = useIsFileLoadingFiles();
  const shouldFieldBeDisabled = useFieldDisabler(name);

  const handleOnBlur = (e: React.ChangeEvent<any>) => {
    setValue(name, internalValue);

    getZipcodeData(internalValue, true);
  };

  const handleOnChange = (e: any) => {
    setInternalValue(e.target.value);
  };

  React.useEffect(() => {
    if (zipCode === undefined) return;
    setInternalValue(zipCode);
  }, [zipCode]);

  const hasErrors = getIn(formState.errors, name);

  return (
    <>
      <TextInput
        label={`${labelPrefix}Zip Code`}
        hasError={hasErrors}
        value={internalValue}
        name={name}
        type="text"
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        disabled={disabled || shouldFieldBeDisabled}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default PropertyZipCodeField;
