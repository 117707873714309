import React, { 
  useState
} from "react";
import { styled } from "@mui/styles";
// import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import useTitle from "utils/custom-hooks/useTitle";
import theme from "theme/default";
import { 
  borderRadius, 
  fontSize, 
  fontWeight, 
  gradients,
  padding,
} from "theme/defaultStyle";
import {
  Box,
  Typography,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import GradientIcon from "theme/icons/GradientIcon";
import TabNavigator from "controls/global/tab-navigator";
import ImageArchive from "./ImageArchive/ImageArchive";
import UploadImages from "./upload-images/UploadImages";
import { TabNavigatorItem } from "entities/UIModel";
import { PolicyImageTabName } from "utils/data/enum";

const Container = styled("div")({
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: borderRadius.xlarge,
  borderBottomRightRadius: borderRadius.xlarge,
  position: "relative",
  "& .header": {
    display: "flex",
    alignItems: "center",
  },
  "& .headerContainer": {
    display: "flex",
    alignItems: "center",
    height: "28px",
    margin: "0px 0px 20px 0px",
    justifyContent: "space-between",
  },
  "& .titleQuickReferenceCard": {
    marginLeft: "10px",
    background: gradients.redGradient03, 
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: fontWeight.bold2,
    fontSize: fontSize.xlarge2,
    paddingLeft: "10px",
  },
});

const PolicyImages = () => {
  useTitle("Stewart Connect - Image Archive");
  const [tabsWithErrors ] = useState<string[]>([]);  
  
  const tabNames: TabNavigatorItem[] = [
    {
      title: PolicyImageTabName.UploadImages,
      disabled: false,
    },
    {
      title: PolicyImageTabName.ImageArchive,
      disabled: false,
    },
  ];
  const navigatePolicyTabs = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey) {
      const element = evt.target as HTMLElement;
      if (element.tabIndex < 0) {
        const nextTab = tabNames.findIndex(tab => tab.title === element.id) + 1;
        if (nextTab < tabNames.length) {
          evt.preventDefault();
          document?.getElementById(tabNames[nextTab].title)?.focus();
        }
        if (nextTab === tabNames.length) {
          let element = document?.getElementById("Upload Images");
          if (!element) {
            element = document?.getElementById("Image Archive");
          }

          if (element) {
            evt.preventDefault();
            element?.focus();
          }
        }
      }
    }
  }  

  const renderComponents = () => {
    const reorderedComponents = [
      <UploadImages key={"upload-images-data"} />,
      <ImageArchive key={"image-archive-data"} />,
    ];

    return reorderedComponents;
  };

  return (
    <Container>
      <Box className="headerContainer">
        <Box className="header">    
          <GradientIcon Icon={NoteAddIcon} />
          <Typography className="titleQuickReferenceCard" variant="h6">
            Policy Images
          </Typography>
        </Box>
        {/* <ScrollToTopArrow /> */}
      </Box>
      <TabNavigator
          tabsWithErrors={tabsWithErrors}
          tabs={tabNames}
          padding={padding.medium2}
          onKeyDown={(evt: KeyboardEvent) => {
            navigatePolicyTabs(evt);
          }}>
          {renderComponents()}
        </TabNavigator>      
    </Container>
  );
};

export default PolicyImages;
