import {
  styled,
  TableCell,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React from "react";
import {
  fontWeight,
  padding,
} from "theme/defaultStyle";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import ActualFeeTotal from "./totals-all/ActualFeeTotal";
import AgentRetentionTotal from "./totals-all/AgentRetentionTotal";
import RiskRateTotal from "./totals-all/RiskRateTotal";
import TotalDueTotal from "./totals-all/TotalDueTotal";

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  isPricingSectionUpdated: boolean;
};

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  fontWeight: fontWeight.bold2,
  overflow: "hidden",
  paddingRight: padding.large1,
  textOverFlow: "ellipses",
  "&:first-of-type": {
    paddingTop: `${padding.small2} !important`,
  },
  "&.normalPadding": {
    paddingTop: padding.small2,
  },
  "&.bigPadding": {
    paddingBottom: padding.large1,
    paddingTop: padding.large1,
  },
});

const TotalCell = styled("div")({
  display: "inline-block",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&.bigPadding": {
    fontStyle: "normal",
    fontWeight: fontWeight.bold2,
    lineHeight: "140%",
  },
});

export default function TotalAll({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  hasFileLockedWithUpdateablePricingItem,
  isPricingSectionUpdated,
}: Props) {
  const footerCellClassname = hasFileLockedWithUpdateablePricingItem
    ? "normalPadding"
    : "bigPadding";

  const { getPricingTotal } = usePricingCalculator();
  const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);

  return (
    <TableRow>
      <StyledTableFooterCell className={footerCellClassname}>
        <TotalCell>
          Totals
        </TotalCell>
      </StyledTableFooterCell>
      {displayFieldIcon && <StyledTableFooterCell className={footerCellClassname} />}
      {displayLiabilityColumn && <StyledTableFooterCell className={footerCellClassname} />}
      {displayRateTypeColumn && <StyledTableFooterCell className={footerCellClassname} />}
      {displayReissueColumn && <StyledTableFooterCell className={footerCellClassname} />}
      {displayTransCodeColumn && <StyledTableFooterCell className={footerCellClassname} />}
      <ActualFeeTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} actualFeeTotal={pricingTotal.actualFeeTotal} />
      {displayRiskRateColumn ? (
        <RiskRateTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} riskRateTotal={pricingTotal.riskRateTotal} />
      ) : null}
      <AgentRetentionTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} agentRetentionTotal={pricingTotal.agentRetentionTotal} />
      <TotalDueTotal hasIssuedProducts={hasIssuedProducts} hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem} totalDueTotal={pricingTotal.totalDueTotal} />
    </TableRow>
  );
}
