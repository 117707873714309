import React from "react";
import { useWatch } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  useCompanyLocation,
  useCompanyLocationActions,
} from "utils/context/CompanyLocationContext";
// import { useFilePropertyStateAbbr } from "utils/context/FilePropertyStateContext";

const HiddenFileItems = () => {
  const { register, setValue } = useFormWrapper();
  const [{ companyStateLocations }] = useCompanyLocation();
  const [, { getCompanyStateLocations }] = useCompanyLocationActions();
  const [
    agencyId,
    fileAgencyLocationName,
    fileAgencyLocationLocationUniqueID,
    filePropertyStateAbbr,
  ] = useWatch({
    name: [
      "agency.id",
      "agencyLocation.name",
      "agencyLocation.locationUniqueID",
      "properties.0.state.abbr",
    ],
  });
  //FIXME: uncomment the following line once the FilePropertyStateContext is updated for existing files.
  // const [filePropertyStateAbbr] = useFilePropertyStateAbbr();

  React.useEffect(
    function getCompanyStateLocationData() {
      if (!agencyId || !filePropertyStateAbbr) return;
      getCompanyStateLocations(agencyId, filePropertyStateAbbr);
    },
    [filePropertyStateAbbr, agencyId, getCompanyStateLocations]
  );

  React.useEffect(
    function setHiddenAgencyData() {
      if (companyStateLocations.length > 0 && fileAgencyLocationName) {
        const companyStateLocation = companyStateLocations.find(
          (companyLocation) =>
            companyLocation.locationUniqueID ===
              fileAgencyLocationLocationUniqueID ||
            companyLocation.locationDisplayName === fileAgencyLocationName
        );
        if (companyStateLocation) {
          //setValue("legacyID", companyStateLocation.legacyID);
          setValue("locationID", companyStateLocation.locationID);
          setValue("locationUniqueID", companyStateLocation.locationUniqueID);
          setValue("authorityLimit", companyStateLocation.authorityLimit);

          setValue(
            "agencyLocation.altaRegistryID",
            companyStateLocation.altaRegistryID
          );
          setValue(
            "agencyLocation.authorityLimit",
            companyStateLocation.authorityLimit
          );
          setValue(
            "agencyLocation.contractID",
            companyStateLocation.contractID
          );
          setValue("agencyLocation.legacyID", companyStateLocation.legacyID);
          setValue(
            "agencyLocation.locationAddress",
            companyStateLocation.locationAddress
          );
          setValue(
            "agencyLocation.locationAddress2",
            companyStateLocation.locationAddress2
          );
          setValue(
            "agencyLocation.locationCity",
            companyStateLocation.locationCity
          );
          setValue(
            "agencyLocation.locationCountry",
            companyStateLocation.locationCountry
          );
          setValue(
            "agencyLocation.locationFaxNumber",
            companyStateLocation.locationFaxNumber
          );
          setValue(
            "agencyLocation.locationLegacyID",
            companyStateLocation.locationLegacyID
          );
          setValue(
            "agencyLocation.locationLegalName",
            companyStateLocation.locationLegalName
          );
          setValue(
            "agencyLocation.locationMainPhone",
            companyStateLocation.locationMainPhone
          );
          setValue(
            "agencyLocation.locationMobilePhone",
            companyStateLocation.locationMobilePhone
          );
          setValue(
            "agencyLocation.locationPrimaryEmail",
            companyStateLocation.locationPrimaryEmail
          );
          setValue(
            "agencyLocation.locationState",
            companyStateLocation.locationState
          );
          setValue(
            "agencyLocation.locationUniqueID",
            companyStateLocation.locationUniqueID
          );
          setValue(
            "agencyLocation.locationZipCode",
            companyStateLocation.locationZipCode
          );
          setValue(
            "agencyLocation.territoryID",
            companyStateLocation.territoryID
          );
        }
      }
    },
    [
      companyStateLocations,
      fileAgencyLocationLocationUniqueID,
      fileAgencyLocationName,
      setValue,
    ]
  );

  //
  return (
    <>
      <input type="hidden" {...register("id")} />
    </>
  );
};

export default HiddenFileItems;
