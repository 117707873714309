import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { styled } from "@mui/material";
import StewartCheckbox from "controls/global/stewart-checkbox";
import { PendingPayCriteriaSearch } from "entities/UIModel/PendingPayCriteriaSearch";
import { ReportOptionType } from "entities/UIModel/ReportOptionType";
import React, {
  useEffect,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import { ReportingOptionType } from "utils/data/enum";
import { useLookup } from "utils/context/LookupContext";

interface Props {
  name: keyof PendingPayCriteriaSearch;
  defaultValue: Array<ReportingOptionType>;
  disabled?: boolean;
  onChange: (
    name: keyof PendingPayCriteriaSearch,
    value: string | null,
  ) => void;
}

const StyledSelect = styled(Select)({
  maxWidth: "160px",
  minWidth: "150px",
  "&.Mui-disabled": {
    backgroundColor: colors.grey10,
    "& .MuiSelect-root": {
      color: colors.grey09,
    },
    "& .MuiSvgIcon-root": {
      color: colors.blue11,
    },
  },
});

const ReportingOption = ({ name, defaultValue, disabled, onChange }: Props) => {
  const [selectedValues, setSelectedValues] =
    useState<ReportingOptionType[]>(defaultValue);
  const [{ reportOptionTypes: options }] = useLookup();

  const handleOnChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
    const elValue = event.target.value as ReportingOptionType[];
    let selectedValues =
      elValue.length > 0
        ? elValue.join(",")
        : [
          ReportingOptionType.ReportOnly,
          ReportingOptionType.AutoReport,
          ReportingOptionType.PayByCheck,
          // ReportingOptionType.EPay,
        ].join(",");

    setSelectedValues(elValue);
    onChange(name, selectedValues);
  };

  const getRenderValue = (selected: unknown) => {
    return (selected as ReportingOptionType[])
      .map((s) => {
        const selectedOption = options.find((opt) => opt.reportOptionTypeCode === s);
        return selectedOption ? selectedOption.reportOptionTypeName : "";
      })
      .join(", ");
  };

  const isSelected = (reportOptionTypeCode: string) => {
    return selectedValues
      .map((value) => value.toString())
      .includes(reportOptionTypeCode);
  };

  useEffect(() => {
    if (disabled) {
      setSelectedValues(defaultValue);
    }
  }, [disabled]);

  return (
    <FormControl size="small">
      <StyledSelect
        name={name}
        disableUnderline
        fullWidth
        multiple
        variant="outlined"
        value={selectedValues}
        disabled={disabled}
        onChange={handleOnChange}
        renderValue={getRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map((opt: ReportOptionType) => (
          <MenuItem
            key={opt.reportOptionTypeCode}
            value={opt.reportOptionTypeCode}
          >
            <StewartCheckbox checked={isSelected(opt.reportOptionTypeCode)} />
            <ListItemText primary={opt.reportOptionTypeName} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default ReportingOption;
