import { CPL } from "entities/UIModel/CPL";
import { SCFile } from "entities/UIModel/SCFile";
import {
  MapActionType,
  ProductType,
} from "utils/data/enum";

interface ValidationProps {
  values: SCFile;
  trigger: any;
  setValue: any;
  productType?: ProductType;
  productAction?: MapActionType;
}

const runValidation = async ({
  values,
  trigger,
  setValue,
  productType,
  productAction = MapActionType.ProductAction,
}: ValidationProps) => {
  let validationSuccessful = false;

  if (productAction === MapActionType.SaveFile) {
    if(values.hasIssuedProducts){
      setValue("isPropertiesValidationRequired", true);
      validationSuccessful = await trigger(["fileNameNumber", "agency.id", "agencyLocation", "properties.0.county", "properties.0.state"]);
    }else{
      validationSuccessful = await trigger(["fileNameNumber", "agency.id", "agencyLocation"]);
    }    
  } else {
    setRequiredFields(values, productType, setValue);
    validationSuccessful = await trigger();
  }

  if (!validationSuccessful) focusFirstInvalidElement();
  return validationSuccessful;
};

const focusFirstInvalidElement = () => {
  setTimeout(() => {
    let firstElement = document.querySelector<HTMLInputElement>(
      '[aria-errormessage="rhfvalidationerror"]'
    );

    if (!firstElement) {
      firstElement = document.querySelector<HTMLInputElement>(
        '[aria-invalid="true"]'
      )
    }

    if (!firstElement) return;

    firstElement.scrollIntoView({ behavior: "smooth", block: "center" });
    firstElement.focus();
  }, 100);
};

const setRequiredFields = (
  values: SCFile,
  productType: string = "",
  setValue: any
) => {
  setValue("agencyLocation.isValidationRequired", true);
  setValue("isPropertiesValidationRequired", true);
  setValue("isZipCodeValidationRequired", false);

  if (values.pricing.isIntegratedPricing) {
    setValue("isTransactionTypeRequired", true);
  }

  if (productType === ProductType.Cpl) {
    values.cpls.forEach((cpl) => {
      if (cpl.isValidationRequired) {
        validateAddreesseeSelection(cpl, values);
      }
    });
  }
};

const validateAddreesseeSelection = (cpl: CPL, scfile: SCFile) => {
  const segments: any = cpl?.addresseeNamesData?.segments;
  if (
    (cpl.addresseeNamesData && segments?.source?.length === 0) ||
    !cpl.addresseeNames
  ) {
    cpl.isValidAddresseeSelected = false;
    return;
  }

  cpl.isValidAddresseeSelected = true;
};

export { runValidation };
