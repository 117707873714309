import React from "react";
import ActionButton from "../action-button";
import ClearIcon from "@mui/icons-material/Clear";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";

type Props = {
  onClick: React.MouseEventHandler;
  disabled?: boolean | undefined;
};

export default function ClearButton({ onClick, disabled }: Props) {
  const isFileDisabled = useFieldDisabler("ClearButton");
  return (
    <ActionButton
      variant="outlined"
      color="secondary"
      onClick={onClick}
      startIcon={<ClearIcon />}
      disabled={disabled || isFileDisabled}
    >
      Clear
    </ActionButton>
  );
}
