import {
  styled,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableBodyCell = styled(TableCell)({
  fontWeight: fontWeight.normal1,
  fontSize: fontSize.large,
  padding: padding.zero,
  color: colors.grey09,
  lineHeight: "unset",
  "& .MuiInputBase-root": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default function PricingEmptyBodyRow() {
  return (
    <TableBody>
      <TableRow>
        <StyledTableBodyCell colSpan={5} className="empty-td">
          There are currently no products issued in this file.
        </StyledTableBodyCell>
      </TableRow>
    </TableBody>
  );
}
