import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import {
  Box,
  styled,
  Typography,
} from "@mui/material";
import { BoxProps } from "@mui/material/Box/Box";
import ActionButton from "controls/global/action-button/ActionButton";
import ErrorBox from "pages/signatures/components/ErrorBox";
import React, {
  DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  iconSize,
  margin,
  padding,
} from "theme/defaultStyle";
import {
  isFileSizeValid,
  isFileTypeValid,
} from "utils/signature/imageFile";
import CropImage from "../crop-image";

interface DropContainerProps extends BoxProps {
  dragging?: boolean;
  height?: any;
}

const OuterContainer = styled("div")({
  "& .upload-message": {
    color: colors.grey08,
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    paddingLeft: padding.small,
  }
});

const UploadButton = styled(ActionButton)({
  "&.MuiButtonBase-root": {
    fontSize: fontSize.small1,
    height: "30px",
    padding: padding.zero,
    width: "88px",
  }
});

const DropContainer = styled(Box)<DropContainerProps>((props) => ({
  backgroundPosition: "center", borderColor: props.dragging ? colors.blue01 : colors.grey14,
  backgroundRepeat: "no-repeat",
  borderRadius: borderRadius.medium,
  borderStyle: "dashed",
  borderWidth: 1,
  height: props.height,
  marginBottom: margin.small2,
  marginTop: margin.medium,
  width: "100%",
}
));

const CustomAddPhotoAlternateOutlinedIcon = styled(AddPhotoAlternateOutlinedIcon)({
  "&.MuiSvgIcon-root": {
    color: colors.grey12,
    fontSize: iconSize.xlarge,
  },
});

const SupportedFormats = styled(Box)({
  color: colors.grey11,
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal1,
  textAlign: 'center',
  gap: gaps.small2,
});

type Props = {
  onLoadCompleted: (image: string) => void;
  onLoadCancel: () => void;
  defaultImage: string | null;
  height?: number;
  formats?: string[];
  limitFileSize?: string;
};

type DropZoneImageState = {
  image: string;
  hasImage: boolean;
  dragging: boolean;
  error: string;
  openCropView: boolean;
};
export default function StewartDropZone({
  onLoadCompleted,
  onLoadCancel,
  defaultImage,
  height = 150,
  formats = [],
  limitFileSize = "",
}: Props) {
  const divRef = useRef<any>();
  const inputRef = useRef<any>();
  // Check if the container has an image
  const [dropZoneImageState, setDropZoneImageState] = useState<DropZoneImageState>({
    image: '',
    hasImage: false,
    dragging: false,
    error: "",
    openCropView: false,
  });

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDropZoneImageState(prevState => ({ ...prevState, dragging: true }));
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDropZoneImageState(prevState => ({ ...prevState, dragging: false }));
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) =>
    event.preventDefault();

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { files } = event.dataTransfer;

    if (files.length === 0) return;
    if (files.length > 1) {
      setDropZoneImageState(prevState => ({
        ...prevState,
        dragging: false,
        error: "One file at the time is allowed",
      }));
      return;
    }

    handleFile(files[0]);
  };

  const handleFile = (file: File) => {
    if (!isFileTypeValid(file.type)) {
      setDropZoneImageState(prevState => ({
        ...prevState,
        dragging: false,
        error: `Stewart Connect could not open ${file.name} because it is either not a supported file type or because the file has been damaged. The image must be in one of the following formats: JPG, JPEG or PNG.`,
      }));
      return;
    }

    if (!isFileSizeValid(file.size)) {
      setDropZoneImageState(prevState => ({
        ...prevState,
        dragging: false,
        error: `${file.name} is too large, maximum file size is 1MB.`,
      }));
      return;
    }

    processImage(file);
  };

  const processImage = async (file: File) => {
    setDropZoneImageState(prevState => ({ ...prevState, dragging: false, error: "" }));

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function (e) {
      if (!e.target) return;
      setDropZoneImageState((prevState: any) => ({ ...prevState, image: e.target?.result, openCropView: true, }));
    };
  };

  const cloneImage = useCallback(async (imageSrc: string) => {
    setDropZoneImageState((prevState: any) => ({ ...prevState, image: imageSrc, hasImage: true }));
    await setBackgroundImage(imageSrc);
  }, []);

  const handleInputClicked = () => {
    inputRef.current.click();
  };

  const handleSelectedFile = () => {
    const { files } = inputRef.current;
    if (files.length) {
      handleFile(files[0]);
    }
  };

  const handleClear = () => {
    divRef.current.style.backgroundSize = "auto";
    divRef.current.style.backgroundImage = "none";

    setDropZoneImageState((prevState: any) => ({ ...prevState, image: undefined, hasImage: false }));

    onLoadCancel();
    setDropZoneImageState(prevState => ({ ...prevState, dragging: false, error: "" }));
  };

  const handleCrop = (image: string) => {
    setBackgroundImage(image);
    onLoadCompleted(image);
    setDropZoneImageState(prevState => ({ ...prevState, openCropView: false, }));
  };

  const setBackgroundImage = async (imageSrc: string) => {
    divRef.current.style.backgroundSize = "contain";
    divRef.current.style.backgroundImage = `url(${imageSrc})`;
    setDropZoneImageState((prevState: any) => ({ ...prevState, hasImage: true }));
  };

  const handleCropCancel = () => {
    setDropZoneImageState(prevState => ({ ...prevState, openCropView: false, }));
  };

  const handleDoubleClick = () => {
    if (!dropZoneImageState.image || !dropZoneImageState.hasImage) return;
    setDropZoneImageState(prevState => ({ ...prevState, openCropView: true, }));
  };

  useEffect(() => {
    if (!defaultImage) return;
    cloneImage(defaultImage);
  }, [cloneImage, defaultImage]);

  return (
    <OuterContainer>
      <ErrorBox message={dropZoneImageState.error} />
      <Typography className="upload-message">
        Drop your image below or {" "}
        <UploadButton
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleInputClicked}
          startIcon={<UploadFileRoundedIcon />}
        >
          Upload
        </UploadButton>
      </Typography>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleSelectedFile}
        // Allow the input to re-open the same file
        // Ref: https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
        onClick={(event: any) => (event.target.value = "")}
      />
      <DropContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        ref={divRef}
        dragging={dropZoneImageState.dragging || dropZoneImageState.hasImage}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDoubleClick={handleDoubleClick}
        height={height}
      >
        {!dropZoneImageState.image && (
          <CustomAddPhotoAlternateOutlinedIcon />
        )}
      </DropContainer>
      <CropImage
        imageUrl={dropZoneImageState.image}
        open={dropZoneImageState.openCropView}
        onCrop={handleCrop}
        onCancel={handleCropCancel}
      />
      {dropZoneImageState.image && (
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <ActionButton
            onClick={handleClear}
            variant="outlined"
            color="secondary"
            startIcon={<ClearIcon />}
          >
            Clear
          </ActionButton>
        </Box>
      )}
      {formats?.length > 0 &&
        <SupportedFormats>
          Supported formats: {formats?.join(', ')}
        </SupportedFormats>
      }
      {limitFileSize &&
        <SupportedFormats>
          Size limit: {limitFileSize}
        </SupportedFormats>
      }
    </OuterContainer>
  );
}
