import SelectField from "controls/global/select-field";
import { PricingProduct } from "entities/UIModel";
import { find } from "lodash";
import React from "react";
import { useWatch } from "react-hook-form";
import { useLookup } from "utils/context/LookupContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

const FIELD_NAME = "transactionTypeCode";

const TransactionTypeCodeField = ({ ...rest }) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  const { setValue, getValues } = useFormWrapper();
  const [{ transactionTypes }, { getTransactionTypes }] = useLookup();
  const { setPricingPremiumWithPolicyTypes, setSimultaneousRate, resetShowReIssueFlags } = useCreateFile();
  const [fileField, agencyIdField, isIntegratedPricingField] = useWatch({
    name: [
      "fileNameNumber",
      "agency.id",
      "pricing.isIntegratedPricing"
    ],
  });

  // Note - useWatch does not work right after issued
  const isIntegratedPricing = getValues("pricing.isIntegratedPricing");
  const selectedByUser = getValues("isTransTypeSelectedByUser");
  const hasPricingProducts = (getValues("pricingProducts") as Array<PricingProduct>)?.length > 0 ? true : false;

  const handleOnChange = async (e: any, value: any) => {
    setValue("isFileDefault", false);
    setValue("isTransTypeSelectedByUser", true);
    if (isIntegratedPricing && hasPricingProducts && value) {
      setPricingPremiumWithPolicyTypes();
      setSimultaneousRate();
    }
    resetShowReIssueFlags();
  };

  // Disable field based on agencyIdField and fileField values
  React.useEffect(function disableField() {
    const shouldDisable = !agencyIdField || !fileField;
    if (shouldDisable === isDisabled) return;
    setIsDisabled(shouldDisable);
  }, [agencyIdField, fileField, isDisabled]);

  // Set default field value for select field
  React.useEffect(function setDefaultSelectOption() {
    if (transactionTypes.length === 0 ||
      !fileField ||
      !agencyIdField ||
      selectedByUser) return;
    const tType: any = find(transactionTypes, ["value", "PURCHASE"]);
    setTimeout(() => {
      setValue(FIELD_NAME, tType.value, {
        shouldValidate: true,
      });
    }, 10);
  }, [transactionTypes, selectedByUser, setValue, fileField, agencyIdField]);

  // Fetch the options for select field
  React.useEffect(function fetchSelectOptions() {
    if (transactionTypes.length === 0) getTransactionTypes();
  }, [transactionTypes, getTransactionTypes, isIntegratedPricing, isIntegratedPricingField]);

  return (
    <SelectField
      label="Transaction Type"
      name={FIELD_NAME}
      options={transactionTypes}
      disabled={isDisabled}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

export default TransactionTypeCodeField;
