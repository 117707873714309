import { utcToZonedTime } from "date-fns-tz";
import { getTimeZoneOffset } from "pages/file/utils/profileSettings";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

function useProfileSettings() {
  const [{ profileSettings }] = useProfileSettingsCache();

  function getCurrentDate() {
    const offset = getTimeZoneOffset(profileSettings);
    const zonedTime = utcToZonedTime(new Date(), offset);

    return zonedTime;
  }

  return { getCurrentDate };
}

export default useProfileSettings;
