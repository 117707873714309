import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  colors,
  fontSize,
  margin,
} from "theme/defaultStyle";

const ErrorContentBox = styled(Box)({
  marginBottom: margin.small2,
  marginTop: margin.small2,
  "& .error": {
    color: colors.red02,
    fontSize: fontSize.xlarge,
  },
});

type Props = {
  message?: string;
};

export default function ErrorBox({ message }: Props) {

  if (!message) return null;
  return (
    <ErrorContentBox>
      <Typography className="error">{message}</Typography>
    </ErrorContentBox>
  );
}
