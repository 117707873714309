import {
  styled,
  TextField,
} from "@mui/material";
import { colors } from "theme/defaultStyle";
import theme from "theme/default";

export const MultiSelectContainer = styled(TextField)({
  "& div.MuiSelect-select": theme.custom.textField.inputOutlined,
  "& div.MuiOutlinedInput-root path": { fill: colors.blue01 },
  "& div.MuiOutlinedInput-input.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
  "& div.MuiOutlinedInput-root.Mui-disabled path": { fill: colors.blue09 },
  "& div.Mui-error path": { fill: colors.red01 },
});
