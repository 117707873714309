import React from "react";
import SelectField from "controls/global/select-field";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

interface StateData {
  text: string;
  value: string;
}

const StateDropdown: React.FC = () => {
  const [{ stateList }] = useGlobalAccess();

  const transformedStateData: StateData[] = stateList
    .map((state) => ({
      text: state.StateAbbr,
      value: state.StateAbbr,
    }))
    .sort((a: StateData, b: StateData) => a.text.localeCompare(b.text));

  const optionsWithAll = [{ text: "All", value: "All" }, ...transformedStateData];

  return (
    <SelectField
      label="State"
      name="state"
      options={optionsWithAll}
    />
  );
};

export default StateDropdown;
