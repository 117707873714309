import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import React from "react";

type Props = {
  isNewFile: boolean;
  noIssuedProducts: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  isLocked: boolean;
};

export default function OverrideField({
  isNewFile,
  noIssuedProducts,
  onChange,
  isLocked = false,
}: Props) {

  return (
    <CheckboxInputField
      disabled={isNewFile || noIssuedProducts || isLocked}
      label="Override"
      name="pricing.isOverride"
      onChange={onChange}
    />
  );
}
