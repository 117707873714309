import LightTooltip from "controls/global/light-tooltip";
import React, {
  useState,
} from "react";

type Props = {
  product: any;
};

function TransCodeCell({ product }: Props) {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const { transCode, transCodeDescription } = product;

  if (!transCodeDescription) return <span>{transCode}</span>;

  const title = transCode + " - " + transCodeDescription;

  return (
    <div
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      <LightTooltip title={title} open={openTooltip} placement="bottom" arrow>
        <span>{product.transCode}</span>
      </LightTooltip>
    </div>
  );
}

export default TransCodeCell;
