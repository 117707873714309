import * as UIModel from "entities/UIModel";

import { ProductAction } from "utils/data/enum";

import { errorMessageContent } from "pages/file/components/product-section/jacket/JacketProductCollection";
import { errorMessageArray, getCorrelationId } from "pages/file/utils/helper";

import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useConfigContext } from "utils/context/ConfigContextProvider";

function useAALErrorHandler() {
  const [errorMessage, setErrorMessage] = React.useState<string | React.ReactNode>("");
  const [openErrorDialog, setOpenErrorDialog] = React.useState<boolean>(false);
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const { getValues } = useFormWrapper();
  const { generalConfig } = useConfigContext();  

  const aals: Array<UIModel.AAL> = getValues("aALProducts");

  // const getErrorMessage = (
  //   currentActionResult: UIModel.ActionResult,
  //   currentProductIntegrationKey: string | undefined,
  //   currentProductAction: string | undefined
  // ) => {
  //   // Check for any exception error
  //   if (
  //     currentActionResult &&
  //     currentActionResult.error &&
  //     currentActionResult.error.response &&
  //     currentActionResult.error.response.status !== 200
  //   ) {
  //     return currentActionResult.error.response.data &&
  //       currentActionResult.error.response.data.errorMessage
  //       ? currentActionResult.error.response.data.errorMessage
  //       : currentActionResult.error.response.statusText;
  //   }

  //   // Check for File level error
  //   const scfile = currentActionResult.scfile as UIModel.SCFile;
  //   if (!scfile) return null;

  //   if (scfile.errorMessage) {
  //     return getErrorMessageByProductAction(currentProductAction as string, scfile.errorMessage);
  //   }

  //   // Check for Product level error
  //   const aal = scfile.aALProducts?.find(
  //     (aal) =>
  //       aal.integrationKey === currentProductIntegrationKey &&
  //       aal.errorMessage &&
  //       (currentProductAction !== ProductAction.Void ||
  //         (currentProductAction === ProductAction.Void &&
  //           aal.errorMessage?.trimStart().startsWith(`${ProductAction.Void}:`)))
  //   );
  //   if (aal && aal.errorMessage) {
  //     return getErrorMessageByProductAction(currentProductAction as string, aal.errorMessage);
  //   }

  //   return null;
  // };

  const getActionCorrelationHeaders = (
    currentActionResult: UIModel.ActionResult,
    currentProductIntegrationKey: string | undefined,
    currentProductAction: string | undefined
  ): any => {
    // Check for any exception error
    if (
      currentActionResult &&
      currentActionResult.error &&
      currentActionResult.error.response &&
      currentActionResult.error.response.status !== 200
    ) {
      return currentActionResult.error.response.headers;
    }

    // Check for File level error
    const scfile = currentActionResult.scfile as UIModel.SCFile;
    if (!scfile) return null;

    if (scfile.errorMessage) {
      return currentActionResult.headers;
    }

    // Check for Product level error
    const aal = scfile.aALProducts?.find(
      (aal) =>
        aal.integrationKey === currentProductIntegrationKey &&
        aal.errorMessage &&
        (currentProductAction !== ProductAction.Void ||
          (currentProductAction === ProductAction.Void &&
            aal.errorMessage?.trimStart().startsWith(`${ProductAction.Void}:`)))
    );
    if (aal && aal.errorMessage) {
      return currentActionResult.headers;
    }

    return null;

  };
  const handleError = React.useCallback(
    (
      currentActionResult: UIModel.ActionResult,
      currentProductIntegrationKey: string | undefined,
      currentProductAction: string | undefined
    ) => {
      const errorMessage = `AAL failed to ${currentProductAction?.toLocaleLowerCase()}. Please try again or contact the Agency Support Center for assistance.`;
      setErrorMessage(errorMessage);
      setErrorMessage(errorMessage);
      setErrorMessage(errorMessageContent(
      errorMessageArray(generalConfig),errorMessage));
      const correlationHeaders = getActionCorrelationHeaders(
        currentActionResult,
        currentProductIntegrationKey,
        currentProductAction
      );
      if (correlationHeaders) {
        const correlationId = getCorrelationId(correlationHeaders);
        setCorrelationId(correlationId);
      }

      setOpenErrorDialog(true);
    },
    []
  );

  const checkError = React.useCallback(
    (
      currentActionResult: UIModel.ActionResult,
      currentProductIntegrationKey: string | undefined,
      currentProductAction: string | undefined
    ) => {
      // check bad request error and file level error
      if (currentActionResult.error || currentActionResult.scfile?.errorMessage)
        return true;

      // check product level error
      const scfile = currentActionResult.scfile as UIModel.SCFile;
      if (!scfile) return false;

      const aalError = scfile.aALProducts?.find(
        (aal) =>
          aal.integrationKey === currentProductIntegrationKey &&
          aal.errorMessage &&
          (currentProductAction !== ProductAction.Void ||
            (currentProductAction === ProductAction.Void &&
              aal.errorMessage
                ?.trimStart()
                .startsWith(`${ProductAction.Void}:`)))
      );

      if (aalError) return true;
      const ALLErrors = currentActionResult?.scfile?.aALProducts?.find(
        (aALProducts, index) =>
          aALProducts.originalOrderID === currentActionResult?.scfile?.aALProducts?.[index]?.originalOrderID &&
          aALProducts.errorMessage !== "" && aALProducts.errorMessage!==null
      );       
      if(ALLErrors) return true

      return false;
    },
    []
  );

  const clearError = React.useCallback(() => {
    setErrorMessage("");
    aals?.forEach((aal: UIModel.AAL) => {
      aal.errorMessage = "";
    });
  }, []);

  return {
    errorMessage,
    correlationId,
    setErrorMessage,
    setCorrelationId,
    openErrorDialog,
    checkError,
    handleError,
    setOpenErrorDialog,
    clearError,
  };
}

export default useAALErrorHandler;
