import {
  Box,
  styled,
} from "@mui/material";
import AddLink from "controls/global/add-link";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import QADialog from "controls/global/dialogs/qa-dialog";
import * as UIModel from "entities/UIModel";
import {
  ActionResult,
  Endorsement,
  Question,
  SCFile,
  StandaloneEndorsement,
  UserInput,
} from "entities/UIModel";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import React,
{
  useEffect,
  useRef,
  useState,
} from "react";
import { gaps } from "theme/defaultStyle";
import { useFiles } from "utils/context/FilesContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { getDefaultStandaloneEndorsement } from "utils/data/defaultStandaloneEndorsement";
import {
  EndorsementStatusType,
  MapActionType,
  OrderStatusType,
  ProductAction,
  ProductType,
} from "utils/data/enum";
import { hasValue } from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import StandAloneProduct from "./StandAloneProduct";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useFieldArray } from "react-hook-form";
import { runValidation } from "pages/file/utils/yup/validator";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import {
  errorMessageArray,
  getCorrelationId,
  isIssuableEndorsement,
  isIssuedStandalone,
} from "pages/file/utils/helper";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import { usePricingActions } from "utils/context/PricingContext";
import useFocus from "utils/custom-hooks/useFocus";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import { useConfigContext } from "utils/context/ConfigContextProvider";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const StandAloneCollection = () => {
  // persist local variable
  let currentActionParameter = useRef<{
    Ppe?: {
      product: StandaloneEndorsement;
      productType: string;
      integrationKey: string;
      orderId: number;
      productIndex?: number;
    };
  }>({});
  let processingKey = useRef<string>();
  let currentAction = useRef("");
  let currentActionResult = useRef<ActionResult>({});
  const companyStateLocationsRef = React.useRef<CompanyStateLocation[]>([]);
  const isFileDisabled = useFieldDisabler("AddStandaloneEndorsmentButton");

  // Progress Bar
  const [progressBaseline, setProgressBaseline] = useState(0);
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [submitStandaloneRequestId, setSubmitStandaloneRequestId] =
    useState("");
  const [runProgress, setRunProgress] = useState(false);

  // QA Dialog
  const [isQAOpen, setIsQAOpen] = useState(false);
  const [isQASubmitButtonDisabled, setIsQASubmitButtonDisabled] =
    useState(false);

  // Error Dialog
  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(
    ""
  );
  const [correlationId, setCorrelationId] = React.useState<string>("");
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);

  // Delete Confirmation
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteProductIndex, setDeleteProductIndex] = useState<number>(-1);
  const { generalConfig } = useConfigContext();  

  const [
    ,
    {
      submitStandaloneEndorsement,
      setIssuedProductDirty,
      deleteOrder,
    },
  ] = useFiles();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [{ initialValues }] = useFiles();
  const { setValue, getValues, trigger, clearErrors } = useFormWrapper();
  const [{ companyStateLocations }] = useCompanyLocation();
  const { resetValidationRequiredFlags, setValidLocations } = useCreateFile();
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const { setFocusInputElement } = useFocus();

  const standaloneEndorsements: StandaloneEndorsement[] = getValues(
    "standaloneEndorsements"
  );

  const { fields, append, remove } = useFieldArray({
    name: "standaloneEndorsements",
    keyName: "fieldId",
  });

  const isEndorsementEditMode = (index: number) => {
    const products = getValues("standaloneEndorsements");
    if (products?.length > 0) {

      const product = products[index];
      const hasPendingProduct =
        !!product?.endorsements &&
        product?.endorsements?.length > 0 &&
        product?.endorsements?.filter(
          (e: any) =>
            !e.endorsementID ||
            (e.endorsementStatusTypeCode &&
              e.endorsementStatusTypeCode === EndorsementStatusType.Pending)
        )?.length > 0;

      return hasPendingProduct;
    } else {
      return false;
    }
  };
  const errorMessageContent = (
    errorMessages: string[],
    errorHeader: string
  ): React.ReactNode => {
    const renderHTML = (htmlString: string) => ({ __html: htmlString });
  
    if (errorHeader.length > 0) {
      return (
        <>
          <div dangerouslySetInnerHTML={renderHTML(errorHeader)} />
          <div style={{ textAlign: 'center' }}>
            {errorMessages?.map((e) => (
              <div key={e} dangerouslySetInnerHTML={renderHTML(e)} />
            ))}
          </div>
        </>
      );
    } else {
      if (errorMessages.length > 1) {
        return (
          <div style={{ textAlign: 'center' }}>
            {errorMessages?.map((e) => (
              <div key={e} dangerouslySetInnerHTML={renderHTML(e)} />
            ))}
          </div>
        );
      } else {
        return <span dangerouslySetInnerHTML={renderHTML(errorMessages[0])} />;
      }
    }
  };

  const clearError = (scFile: SCFile) => {
    setIsError(false);
    setValue("errorMessage", "");
    scFile?.standaloneEndorsements?.forEach(
      (standalone: StandaloneEndorsement) => {
        standalone.errorMessage = "";
        standalone.endorsements?.forEach((endorsement: Endorsement) => {
          endorsement.errorMessage = "";
        });
      }
    );
  };

  const hasError = () => {
    if (
      currentActionResult.current.error ||
      currentActionResult.current.scfile?.errorMessage
    )
      return true;
    if (!(currentActionParameter && currentActionParameter.current))
      return false;

    let isError = false;
    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as UIModel.SCFile;
      switch (currentAction.current) {
        case ProductAction.Ppe: {
          if (
            currentActionParameter.current.Ppe?.product &&
            currentActionParameter.current.Ppe?.productType &&
            hasValue(currentActionParameter.current.Ppe?.orderId) &&
            currentActionParameter.current.Ppe?.integrationKey
          ) {
            if (
              currentActionParameter.current.Ppe?.productType ===
              ProductType.StandaloneEndorsement
            ) {
              // Only check error message in the parent level
              const standalone = scfile.standaloneEndorsements?.find(
                (standalone) =>
                  standalone.integrationKey ===
                  currentActionParameter.current.Ppe?.integrationKey
              );
              if (
                standalone &&
                standalone.errorMessage &&
                standalone.errorMessage.length > 0
              )
                isError = true;

              const errorEndorsement = standalone?.endorsements?.some(
                (endorsement) => endorsement.errorMessage
              );

              if (errorEndorsement) isError = true;              

            } else if (
              currentActionParameter.current.Ppe?.productType ===
              ProductType.Endorsement
            ) {
              // Only check the error message in children level
              const standalone = scfile.standaloneEndorsements?.find(
                (standalone) =>
                  standalone.orderID ===
                  currentActionParameter.current.Ppe?.orderId
              );
              if (standalone) {
                currentActionParameter.current.Ppe?.product?.endorsements
                  ?.filter((e) => isIssuableEndorsement(e))
                  ?.forEach((e) => {
                    const errorEndorsement = standalone.endorsements?.find(
                      (endorsement) =>
                        endorsement.integrationKey === e.integrationKey &&
                        endorsement.errorMessage
                    );

                    if (errorEndorsement) isError = true;
                  });

                // const endorsement = standalone.endorsements?.find(
                //   (endorsement) =>
                //     endorsement.errorMessage &&
                //     endorsement.errorMessage.length > 0
                // );

                // if (endorsement) isError = true;
              }
            }
          }

          break;
        }
      }
    }
    return isError;
  };

  const handleError = () => {
    // Check for any exception error
 
     const errorMessage = `Standalone Endorsement failed to issue. Please try again or contact the Agency Support Center for assistance.`;
      setErrorMessage(errorMessage);
      setCorrelationId(getCorrelationId(currentActionResult?.current?.error?.response?.headers))
      setOpenErrorDialog(true);
      setErrorMessage(errorMessageContent(
      errorMessageArray(generalConfig),errorMessage));
      setCorrelationId(getCorrelationId(currentActionResult?.current?.headers));
      setOpenErrorDialog(true);
      return;

    // Check for File level error
    // if (currentActionResult.current.scfile) {
    //   const scfile = currentActionResult.current.scfile as UIModel.SCFile;
    //   if (scfile.errorMessage) {
    //     setErrorMessage(scfile.errorMessage);
    //     setCorrelationId(getCorrelationId(currentActionResult.current.headers))
    //     setOpenErrorDialog(true);
    //     return;
    //   }

    //   // Check for Product level error
    //   let errorMessages: string[] = [];

    //   switch (currentAction.current) {
    //     case ProductAction.Ppe: {
    //       if (
    //         currentActionParameter.current.Ppe?.product &&
    //         currentActionParameter.current.Ppe?.productType &&
    //         hasValue(currentActionParameter.current.Ppe?.orderId) &&
    //         currentActionParameter.current.Ppe?.integrationKey
    //       ) {
    //         if (
    //           currentActionParameter.current.Ppe?.productType ===
    //           ProductType.StandaloneEndorsement
    //         ) {
    //           // Only check error message in the parent level
    //           const standalone = scfile.standaloneEndorsements?.find(
    //             (standalone) =>
    //               standalone.integrationKey ===
    //               currentActionParameter.current.Ppe?.integrationKey
    //           );
    //           if (
    //             standalone &&
    //             standalone.errorMessage &&
    //             standalone.errorMessage.length > 0
    //           ) {
    //             const errors = standalone.errorMessage?.split("||");
    //             errors?.forEach((e) => {
    //               errorMessages.push(e);
    //             });
    //           }
    //         } else if (
    //           currentActionParameter.current.Ppe?.productType ===
    //           ProductType.Endorsement
    //         ) {
    //           const standalone = scfile.standaloneEndorsements?.find(
    //             (standalone) =>
    //               standalone.orderID ===
    //               currentActionParameter.current.Ppe?.orderId
    //           );
    //           if (standalone) {
    //             // Only check the error message in children level
    //             currentActionParameter.current.Ppe?.product?.endorsements
    //               ?.filter((e) => isIssuableEndorsement(e))
    //               ?.forEach((e) => {
    //                 const errorEndorsement = standalone.endorsements?.find(
    //                   (endorsement) =>
    //                     endorsement.integrationKey === e.integrationKey &&
    //                     endorsement.errorMessage
    //                 );

    //                 if (errorEndorsement) {
    //                   errorMessages.push(
    //                     `${errorEndorsement.endorsementName
    //                     }; ${errorEndorsement.errorMessage!}`
    //                   );
    //                 }
    //               });

    //             // const endorsements = standalone.endorsements?.filter(
    //             //   (endorsement) =>
    //             //     endorsement.errorMessage &&
    //             //     endorsement.errorMessage.length > 0
    //             // );
    //             // endorsements?.forEach((endorsement) => {
    //             //   errorMessages.push(
    //             //     `${
    //             //       endorsement.endorsementName
    //             //     }; ${endorsement.errorMessage!}`
    //             //   );
    //             // });
    //           }
    //         }

    //         if (errorMessages.length > 0) {
    //           setErrorMessage(
    //             errorMessageContent(
    //               errorMessages,
    //               currentActionParameter.current.Ppe.productType
    //             )
    //           );
    //           setCorrelationId(getCorrelationId(currentActionResult.current.headers))
    //           setOpenErrorDialog(true);
    //         }
    //       }

    //       break;
    //     }
    //   }
    // }
  };

  const handleStandaloneSubmit = async (
    product: StandaloneEndorsement,
    integrationKey: string,
    orderId: number,
    productIndex?: number
  ) => {
    // For adding PPEs to issued standalone endorsement, set ProductType to Endorsement.
    const productType =
      product.orderStatusTypeCode === OrderStatusType.Issued
        ? ProductType.Endorsement
        : ProductType.StandaloneEndorsement;

    const scFile = getValues();

    // clear previous action error
    clearError(scFile);
    currentActionResult.current = {};

    // Set validation
    // clear  previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    const standaloneEndorsements = getValues("standaloneEndorsements");
    standaloneEndorsements.forEach(
      (se: StandaloneEndorsement, index: number) => {
        if (se.integrationKey === integrationKey) {
          setValue(
            `standaloneEndorsements.${index}.isValidationRequired`,
            true
          );
          if (se.pricingDetail) {
            // rlo-3/9/2022 - Only validate for Pending Standalone, cannot change the location after the issued.
            setValue(
              `standaloneEndorsements.${index}.pricingDetail.isValidatingLocationRequired`,
              se.orderStatusTypeCode !== OrderStatusType.Issued
            );
          }
          se.endorsements?.forEach((sendo, endorsementIndex) => {
            if (sendo.endorsementName) {
              setValue(
                `standaloneEndorsements.${index}.endorsements.${endorsementIndex}.isValidationRequired`,
                true
              );
            }
          });
        } else {
          setValue(
            `standaloneEndorsements.${index}.isValidationRequired`,
            false
          );
          if (se.pricingDetail) {
            setValue(
              `standaloneEndorsements.${index}.pricingDetail.isValidatingLocationRequired`,
              false
            );
          }

          se.endorsements?.forEach((sendo, endorsementIndex) => {
            setValue(
              `standaloneEndorsements.${index}.endorsements.${endorsementIndex}.isValidationRequired`,
              false
            );
          });
        }
      }
    );

    setValidLocations(companyStateLocationsRef.current);
    const values = getValues();
    const isValid = await runValidation({
      values,
      trigger,
      setValue,
      productType: ProductType.Cpl,
    });

    if (!isValid) return;

    processingKey.current = integrationKey;
    currentAction.current = ProductAction.Ppe;
    currentActionParameter.current = {
      Ppe: {
        ...{ product, productType, integrationKey, orderId, productIndex },
      },
    };

    let receiptId = uuidv4();
    setSubmitStandaloneRequestId(receiptId);
    setStatusMessage("Please wait while submitting your Endorsement(s)...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      scFile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Issue,
      ProductType.StandaloneEndorsement,
      [integrationKey!]
    );

    removeOldQA();
    setPricingNotificationUpdated(false);
    currentActionResult.current = (await submitStandaloneEndorsement(
      apiSCFile,
      productType,
      receiptId,
      orderId
    )) as ActionResult;
    setPricingNotificationUpdated(true);
  };
  const handleStandaloneCancel = (index: number) => {
    const product: StandaloneEndorsement = standaloneEndorsements[index];
    const existingEndorsements = product.endorsements?.filter(
      (e) => !!e.endorsementID
    );

    setValue(
      `standaloneEndorsements.${index}.endorsements`,
      existingEndorsements
    );
    clearErrors();
  };

  const disableAddLink = () => {
    const disable = standaloneEndorsements
      ? standaloneEndorsements.filter(
        (se: StandaloneEndorsement) =>
          isIssuedStandalone(se) &&
          hasNewOrPendingEndorsements(se.endorsements)
      ).length > 0
      : false;

    setIssuedProductDirty(disable);
    return disable;
  };

  const hasNewOrPendingEndorsements = (
    endorsements: Endorsement[] | undefined
  ) => {
    if (!endorsements) return false;

    const newOrPendingItems: Endorsement[] = endorsements.filter(
      (e) =>
        (!e.endorsementID ||
          !e.endorsementStatusTypeCode ||
          e.endorsementStatusTypeCode === EndorsementStatusType.Pending) &&
        e.altEndorsementID
    );
    return newOrPendingItems.length > 0;
  };

  const hasQA = () => {
    if (currentActionResult.current.scfile) {
      const scfile = currentActionResult.current.scfile as UIModel.SCFile;
      return (
        scfile.combinedQNAs?.filter((ui: UserInput) =>
          ui.questions?.filter(
            (question: Question) => question && !question.answerValue
          )
        ).length > 0
      );
    } else return false;
  };

  const OnProgressDialogClosed = () => {
    setOpenProgressDialog(false);
    setRunProgress(false);
    setSubmitStandaloneRequestId("");

    // Enable back the Submit button
    setIsQASubmitButtonDisabled(false);
    processingKey.current = undefined;

    // check for error
    if (hasError()) {
      setIsError(true);
      handleError();
    } else if (hasQA()) {
      // show QnA if exists
      setBypassDefaultLoader(true);
      setIsQAOpen(true);
    }

    // keep bypass default loader when QnA exists
    if (!hasQA()) {
      setTimeout(() => setBypassDefaultLoader(false), 1000);
    }
  };

  const handleCompletedQnA = async () => {
    async function handleReissue() {
      if (
        !(
          currentAction &&
          currentAction.current &&
          currentActionParameter &&
          currentActionParameter.current
        )
      )
        return;
      switch (currentAction.current) {
        case ProductAction.Ppe: {
          setProgressBaseline(50);
          handleStandaloneSubmit(
            currentActionParameter.current.Ppe?.product!,
            currentActionParameter.current.Ppe?.integrationKey!,
            currentActionParameter.current.Ppe?.orderId!
          );
          setProgressBaseline(0);
          break;
        }
      }
    }
    setIsQASubmitButtonDisabled(true);
    setIsQAOpen(false);
    handleReissue();
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
    setCorrelationId("");
  };

  const isSubmitDisabled = (key: string) => {
    return processingKey && processingKey.current && !isError
      ? processingKey.current === key
      : false;
  };

  const removeOldQA = () => {
    setValue("combinedQNAs", []);
  };

  const handleCloseQnA = () => {
    removeOldQA();
    processingKey.current = undefined;
    setValue(
      `standaloneEndorsements.${currentActionParameter.current?.Ppe?.productIndex}.orderStatusTypeCode`,
      currentActionParameter.current?.Ppe?.product?.orderStatusTypeCode
    );
    setTimeout(() => setBypassDefaultLoader(false), 1000);
    setIsQAOpen(false);
  };

  const addNewStandAlone = () => {
    var newStandalone = getDefaultStandaloneEndorsement();
    newStandalone.integrationKey = uuidv4();
    newStandalone.isDefault = false;
    newStandalone.isDirty = true;
    append(newStandalone);
    // set 1st standalone as dirty
    setValue(`standaloneEndorsements.0.isDefault`, false);
    setValue(`standaloneEndorsements.0.isDirty`, true);

    // set focus to last Standalone Endorsement
    const newStandaloneEndorsementsIndex = standaloneEndorsements.length;
    setFocusInputElement(`standaloneEndorsements.${newStandaloneEndorsementsIndex}.originalJacketNumber`);
  };

  // Delete
  const deleteStandalone = (index: number) => {
    setOpenConfirmationDialog(true);
    setDeleteProductIndex(index);
  };

  const handleYes = (e: any) => {
    if (deleteProductIndex >= 0) {
      const deleteOrderId = standaloneEndorsements[deleteProductIndex].orderID;
      if (deleteOrderId && deleteOrderId > 0)
        deleteOrder(standaloneEndorsements[deleteProductIndex].orderID);

      clearErrors();
      remove(deleteProductIndex);

      standaloneEndorsements.splice(deleteProductIndex, 1);
    }

    setOpenConfirmationDialog(false);
    setDeleteProductIndex(-1);
  };

  const handleNo = () => {
    setOpenConfirmationDialog(false);
    setDeleteProductIndex(-1);
  };

  useEffect(() => {
    if (standaloneEndorsements.length === 0) {
      const newProduct = getDefaultStandaloneEndorsement();
      newProduct.integrationKey = uuidv4();
      append(newProduct);
      setValue(`standaloneEndorsements.0`, newProduct);
    }
  }, [standaloneEndorsements, append]);

  React.useEffect(() => {
    companyStateLocationsRef.current = companyStateLocations;
  }, [companyStateLocations]);

  return (
    <>
      <StyledGroup>
        {fields.map((field: any, index: number) => (
          <StandAloneProduct
            key={field.fieldId}
            productIndex={index}
            standalone={standaloneEndorsements[index]}
            endorsementEditMode={isEndorsementEditMode(index)} //field)}
            submitDisabled={isSubmitDisabled(field.integrationKey)}
            handleStandloneSubmit={() =>
              handleStandaloneSubmit(
                field,
                field.integrationKey,
                field.orderID || 0,
                index
              )
            }
            handleStandaloneCancel={() => handleStandaloneCancel(index)}
            handleStandaloneDelete={() => deleteStandalone(index)}
          />
        ))}

        <Box>
          <AddLink
            onClick={addNewStandAlone}
            disabled={disableAddLink() || isFileDisabled}
          >
            Add Standalone Endorsement
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialogWithProgressbar
        autoClose={true}
        title={statusMessage}
        closeButtonText="Done! Click to Continue"
        isOpen={openProgressDialog}
        onClose={OnProgressDialogClosed}
        requestId={submitStandaloneRequestId}
        runProgress={runProgress}
        progressBaseline={progressBaseline}
      />
      <QADialog
        isOpen={isQAOpen}
        isQASubmitButtonDisabled={isQASubmitButtonDisabled}
        onClose={handleCloseQnA}
        onSubmit={handleCompletedQnA}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleErrorDialogClose}
      />
      <ConfirmationDialog
        confirmationMessage="Are you sure you want to delete this pending Standalone Endorsement?"
        isOpen={openConfirmationDialog}
        onYes={handleYes}
        onNo={handleNo}
      />
    </>
  );
};

export default StandAloneCollection;
