import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect,
} from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type CoverageTypeProps = {
  label: string;
  name: string;
  coverageTypeData?: SelectFieldOption[];
  disabled?: boolean;
};

function CoverageTypeField({
  label,
  name,
  coverageTypeData = [],
  disabled,
}: CoverageTypeProps) {
  const { getValues, setValue } = useFormWrapper();

  useEffect(() => {
    const currentValue = getValues(name);
    if (
      currentValue &&
      coverageTypeData.some((coverage) => coverage.value === currentValue)
    )
      return;

    const selectedItem = coverageTypeData.filter((item) => item.selected);
    if (selectedItem && selectedItem.length > 0)
      setValue(`${name}`, selectedItem[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SelectField
        label={label}
        name={name}
        options={coverageTypeData}
        disabled={disabled}
      />
    </div>
  );
}

export default CoverageTypeField;
