import {
  AutocompleteInputChangeReason,
  PopperPlacementType,
} from "@mui/material";
import ErrorMessage from "controls/global/error-message";
import React from "react";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import AutocompleteInput from "./AutocompleteInput";

interface Props<T> {
  name: any;
  label: string;
  loading?: boolean;
  freeSolo?: boolean;
  minLengthBeforeSearch?: number;
  disabled?: boolean;
  errorClass?: string;
  maxLength?: number;
  stateName?: string;
  options?: any[];
  open?: boolean;
  debounceTime?: number;
  popperPlacement?: PopperPlacementType | undefined;
  onFocus?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  onValueChanged: (value: any, event?: React.ChangeEvent<any>) => void;
  onGetOptions?: (inputText: string) => Promise<T[]>;
  onTypeInput?: (
    inputText: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  getOptionSelected: (option: any, value: any) => boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: any,
    ownerState: any,
  ) => React.ReactNode;
  resetDefaultValue?: string;
  forceEnable?: boolean;
}

const AutocompleteField = <T,>(props: Props<T>) => {
  const { errorClass, name, disabled, forceEnable = false, ...rest } = props;
  const { control } = useFormContext();
  const shouldFieldBeDisabled = useFieldDisabler(name, forceEnable);

  return (
    <>
      <Controller
        render={({
          field: { name, value, onBlur, ref },
          fieldState: { error },
        }) => {
          return (
            <AutocompleteInput
              name={name}
              value={value}
              hasError={Boolean(error)}
              onBlurForm={onBlur}
              fieldRef={ref}
              disabled={disabled || shouldFieldBeDisabled}
              {...rest}
            />
          );
        }}
        name={name}
        defaultValue=""
        control={control}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default AutocompleteField;
