import { FileSearchCriteria } from "entities/ApiModel";
import { FilesRecord } from "entities/UIModel/FilesRecord";

export type Order = "asc" | "desc";
export type SortColumn = keyof Omit<FilesRecord, "opened">;
export type Pagination = {
  page: number;
  totalPages: number;
  rowsPerPage: number;
};

function getFormattedValue<T>(value: T) {
  if (value == null || value === undefined) {
    return "";
  }
  else if (typeof value === "string") {
    return value.toLowerCase();
  }
  else {
    return value;
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aVal = getFormattedValue(a[orderBy]);
  const bVal = getFormattedValue(b[orderBy]);
  if (bVal < aVal) return -1;
  if (bVal > aVal) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getFilesByCriteria(record: FilesRecord, filters: FileSearchCriteria) {
  let isMatch = true;

  if (filters.productName)
    isMatch =
      isMatch &&
      record.productNames
        .toLowerCase()
        .includes(filters.productName.toLowerCase());
  if (filters.clientFileID)
    isMatch =
      isMatch &&
      record.fileNumber
        .toLowerCase()
        .includes(filters.clientFileID.toLowerCase());
  if (filters.propertyAddress)
    isMatch =
      isMatch &&
      record.propertyAddress
        .toLowerCase()
        .includes(filters.propertyAddress.toLowerCase());
  if (filters.buyerName)
    isMatch =
      isMatch &&
      record.buyerBorrower
        .toLowerCase()
        .includes(filters.buyerName.toLowerCase());
  if (filters.lenderName)
    isMatch =
      isMatch &&
      record.lender.toLowerCase().includes(filters.lenderName.toLowerCase());
  if (filters.sellerName)
    isMatch =
      isMatch &&
      record.seller.toLowerCase().includes(filters.sellerName.toLowerCase());
  if (filters.dateRangeStart)
    isMatch =
      isMatch &&
      record.opened?.toDateString() === filters.dateRangeStart.toDateString();
  if (filters.agencyID)
    isMatch =
      isMatch &&
      record.agencyID?.toLowerCase().includes(filters.agencyID.toLowerCase());
  if (filters.locationDisplayName)
    isMatch =
      isMatch &&
      record.locationDisplayName?.toLowerCase().includes(filters.locationDisplayName.toLowerCase());
  if (filters.legacyID)
    isMatch =
      isMatch &&
      record.legacyID
        .toLowerCase()
        .includes(filters.legacyID.toLowerCase());
  if (filters.agencyName)
    isMatch =
      isMatch &&
      record.agencyName?.toLowerCase().includes(filters.agencyName.toLowerCase());
  if (filters.stateAbbr)
    isMatch =
      isMatch &&
      record.stateAbbr?.toLowerCase().includes(filters.stateAbbr.toLowerCase());
  if (filters.propertyTypeCode)
    isMatch =
      isMatch &&
      record.propertyTypeCode?.toLowerCase().includes(filters.propertyTypeCode.toLowerCase());
  if (filters.transactionTypeCode)
    isMatch =
      isMatch &&
      record.transactionTypeCode?.toLowerCase().includes(filters.transactionTypeCode.toLowerCase());

  isMatch =
    isMatch &&
    (filters.fileStatusTypeCode !== "no-status"
      ? filters.fileStatusTypeCode?.indexOf(record.status) !== -1
      : !record.status);

  return isMatch;
}

export { getComparator, stableSort, getFilesByCriteria };
