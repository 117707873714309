import { ShoppingCart } from "@mui/icons-material";
import { CollapsableProvider } from "controls/global/collapsable-form-section/CollapsableProvider";
import FormSection from "controls/global/form-section";
import TabNavigator from "controls/global/tab-navigator";
import { TabNavigatorItem } from "entities/UIModel";
import { isEqual } from "lodash";
import {
  ActionType,
  useSideNavProvider
} from "pages/file/SideNavProvider";
import { isProductSectionAvailable } from "pages/file/utils/helper";
import React, {
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  useFormState,
  useWatch,
} from "react-hook-form";
import GradientIcon from "theme/icons/GradientIcon";
import { useCompanyProductOptions } from "utils/context/CompanyProductOptionsContext";
import { useSuccessorLanguages } from "utils/context/CompanySuccessorLanguageContext";
import { useProductAgenycLocations } from "utils/context/ProductAgencyLocationsContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useTranslation } from "utils/context/TranslationContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  PERMISSIONS,
  ProductTabNameExtension,
  ProductType,
  ProductTypeName,
} from "utils/data/enum";
import { tabMapping } from "utils/tabNavigator";

const ProductSection = () => {
  const [{ stateProductConfig }] = useCompanyProductOptions();
  const { errors } = useFormState();
  const [, { getTranslations }] = useTranslation();
  const [, { getSuccessorLanguages }] = useSuccessorLanguages();
  const { getValues } = useFormWrapper();
  const [, { getProductAgencyLocations }] = useProductAgenycLocations();
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const productsRef = React.useRef<HTMLElement>();
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasPolicyUploadPermission = permissionCodes?.includes(PERMISSIONS.POLICYIMAGES_ACCESS);
    
  // converted to array for performance reasons
  const [
    fileId,
    fileNameNumber,
    agencyId,
    agencyLocationId,
    stateAbbr,
    aalIsDefault,
    aalIdentityKey,
    cplIsDefault,
    cplIdentityKey,
    jacketIsDefault,
    jacketIdentityKey,
    standaloneEndorsementIsDefault,
    standaloneEndorsementIdentityKey,
  ] = useWatch({
    name: [
      "id",
      "fileNameNumber",
      "agency.id",
      "agencyLocation.id",
      "properties.0.state.abbr",
      "aALProducts.0.isDefault",
      "aALProducts.0.identityKey",
      "cpls.0.isDefault",
      "cpls.0.identityKey",
      "jackets.0.isDefault",
      "jackets.0.identityKey",
      "standaloneEndorsements.0.isDefault",
      "standaloneEndorsements.0.identityKey",
    ],
  });

  // const uniqueProductOptions = getUniqueProductOptions();
  const [availableProducts, setAvailableProducts] = React.useState<string[]>(
    []
  );

  const shouldShowSection = isProductSectionAvailable(
    availableProducts,
    fileNameNumber,
    agencyId,
    agencyLocationId,
    stateAbbr
  );

  useEffect(() => {
    const getAvailableProducts = () => {
      // Get latest products. UseWatch variables are not getting update between product issue.
      const aalsLatest = getValues("aALProducts");
      const cplsLatest = getValues("cpls");
      const jacketsLatest = getValues("jackets");
      const standaloneEndorsementsLatest = getValues("standaloneEndorsements");

      //1) Trim it down to a unique set
      const potentialProducts = [
        ...new Set(
          stateProductConfig.productOptions?.map((option) => option.product)
        ),
      ];

      const hasNonDefaultAALs =
        aalsLatest?.length > 0 &&
        aalsLatest.filter(
          (entity: { isDefault: any; identityKey: number; }) =>
            entity.isDefault && entity.identityKey === 0
        ).length === 0;
      const hasNonDefaultCPLs =
        cplsLatest?.length > 0 &&
        cplsLatest.filter(
          (entity: { isDefault: any; identityKey: number; }) =>
            entity.isDefault && entity.identityKey === 0
        ).length === 0;
      const hasNonDefaultLetterProduct = hasNonDefaultAALs || hasNonDefaultCPLs;
      const hasNonDefaultJacket =
        jacketsLatest?.length > 0 &&
        jacketsLatest.filter(
          (entity: { isDefault: any; identityKey: number; }) =>
            entity.isDefault && entity.identityKey === 0
        ).length === 0;
      const hasNonDefaultSAE =
        standaloneEndorsementsLatest?.length > 0 &&
        standaloneEndorsementsLatest.filter(
          (entity: { isDefault: any; identityKey: number; }) =>
            entity.isDefault && entity.identityKey === 0
        ).length === 0;

      //2) Go through that unique set and apply the rule that SAE is not simultaneously
      //   available with any other product unless they preexist this change.
      const productOptions: string[] = [];
      potentialProducts.forEach((product) => {
        switch (product) {
          case ProductType.Aal:
          case ProductType.Cpl:
            if (
              hasNonDefaultLetterProduct ||
              hasNonDefaultJacket ||
              !hasNonDefaultSAE
            ) {
              productOptions.push(product);
            }
            break;
          case ProductType.Jacket:
            if (
              hasNonDefaultLetterProduct ||
              hasNonDefaultJacket ||
              !hasNonDefaultSAE
            ) {
              productOptions.push(product);
            }
            break;
          case ProductType.StandaloneEndorsement:
            if (
              hasNonDefaultSAE ||
              (!hasNonDefaultJacket && !hasNonDefaultLetterProduct)
            ) {
              productOptions.push(product);
            }
            break;
        }
      });

      // Must have access
      if (hasPolicyUploadPermission) { 
        productOptions.push(ProductTabNameExtension.PolicyUpload);
      }

      setAvailableProducts(productOptions);
    };

    if (stateProductConfig.productOptions.length > 0) {
      getAvailableProducts();
    }
  }, [
    aalIsDefault,
    aalIdentityKey,
    cplIsDefault,
    cplIdentityKey,
    getValues,
    jacketIsDefault,
    jacketIdentityKey,
    standaloneEndorsementIsDefault,
    standaloneEndorsementIdentityKey,
    stateProductConfig,
    fileId,
  ]);

  const useRefTabs = useRef<string[]>([]);
  const useRefTabsChanged = useRef<number>(Date.now());

  if (!isEqual(useRefTabs.current, availableProducts)) {
    useRefTabs.current = availableProducts;
    useRefTabsChanged.current = Date.now();
  }

  const getTabNames = useMemo(() => {
    return availableProducts.map((product) =>  {
      let  tab:TabNavigatorItem = {
        title: "",
        disabled: false,
      };
      switch (product) {
        case ProductType.Aal:
          tab.title = "AAL";
          break;
        case ProductType.Cpl:
          tab.title =  "CPL";
          break;
        case ProductType.Jacket:
          tab.title =  "Jacket";
          break;
        case ProductType.StandaloneEndorsement:
          tab.title =  "Standalone Endorsement";
          break;
        case ProductTabNameExtension.PolicyUpload:
          tab.title =  "Policy Upload";
          tab.disabled = fileId > 0 ? false : true;
          break;
        default:
          tab.title = "";
      }
      return tab;
    });
  }, [useRefTabsChanged.current, fileId]);

  const renderComponents = useMemo(() => {
    return availableProducts.map((product: string) => {
      let Component: any = null;
      switch (product) {
        case ProductType.Aal:
          Component = tabMapping["AALProductCollection"];
          break;
        case ProductType.Cpl:
          Component = tabMapping["CPLProductCollection"];
          break;
        case ProductType.Jacket:
          Component = tabMapping["JacketProductCollection"];
          break;
        case ProductType.StandaloneEndorsement:
          Component = tabMapping["StandAloneCollection"];
          break;
        case ProductTabNameExtension.PolicyUpload:
          Component = tabMapping["PolicyUpload"];
          break;
        default:
          break;
      }

      return Component && <Component key={product} />;
    });
  }, [useRefTabsChanged.current]);

  const isCPLError = Boolean(errors.cpls);
  const isAALError = Boolean(errors.aALProducts);
  const isJacketError = Boolean(errors.jackets);
  const isStandaloneError = Boolean(errors.standaloneEndorsements);
  const errorTabs = () => {
    const tabs: string[] = [];

    if (isCPLError) tabs.push(ProductTypeName.CPL);
    if (isAALError) tabs.push(ProductTypeName.AAL);
    if (isJacketError) tabs.push(ProductTypeName.JACKET);
    if (isStandaloneError) tabs.push(ProductTypeName.STANDALONE_ENDORSEMENT);

    return tabs;
  };

  const memoizedErrorTabs = useMemo(
    () => errorTabs(),
    [isCPLError, isAALError, isJacketError, isStandaloneError]
  );

  const navigateProductTabs = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey) {
      const element = evt.target as HTMLElement;
      if (element.tabIndex < 0) {
        const nextTab = getTabNames.findIndex(tab => tab.title === element.id) + 1;
        if (nextTab < getTabNames.length) {
          evt.preventDefault();
          document?.getElementById(getTabNames[nextTab].title)?.focus();
        }
      }
    }
  };

  useEffect(() => {
    getSuccessorLanguages();
  }, []);

  useEffect(() => {
    getTranslations("STEWARTACCESS_API", "AdditionalFormDetail");
  }, [getTranslations]);

  useEffect(() => {
    if (agencyId && stateAbbr) {
      getProductAgencyLocations(agencyId, stateAbbr);
    }
  }, [agencyId, stateAbbr]);

  useEffect(() => {
    if (shouldShowSection) {
      if (sideNavState.items.get("Products")?.childRef?.current) return;
      sideNavAction({
        type: ActionType.UPDATE,
        item: { name: "Products", childRef: productsRef },
      });
    } else {
      sideNavAction({
        type: ActionType.UPDATE,
        item: { name: "Products", childRef: undefined },
      });
    }
  }, [shouldShowSection, sideNavState.items]);

  if (!shouldShowSection) return null;
  
  return (
    <>
      <FormSection
        navItemRef={productsRef}
        icon={<GradientIcon Icon={ShoppingCart} />}
        isError={memoizedErrorTabs.length > 0}
        isNavigator
        title="Products"
      >
        <CollapsableProvider>
          <TabNavigator
            tabsWithErrors={memoizedErrorTabs}
            tabs={getTabNames}
            onKeyDown={(evt: KeyboardEvent) => {
              navigateProductTabs(evt);
            }}
          >
            {renderComponents}
          </TabNavigator>
        </CollapsableProvider>
      </FormSection>
    </>
  );
};

export default ProductSection;
