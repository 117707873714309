import React from "react";
import { Controller } from "react-hook-form";
import ErrorMessage from "../error-message";
import CheckboxInput from "./CheckboxInput";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

interface IProps {
  label?: string;
  name: any;
  defaultValue?: boolean;
  type?: string;
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onBlur?: any;
  className?: any;
  inputProps?: any;
  styledWrapper?: boolean;
  hidden?: boolean;
  autoSetValue?: boolean;
}

const CheckboxInputField = (props: IProps) => {
  const {
    label,
    name,
    type,
    disabled,
    className,
    inputProps,
    styledWrapper,
    hidden,
    autoSetValue = true,
  } = props;
  const { control } = useFormWrapper();
  const shouldFieldBeDisabled = useFieldDisabler(name);
  return (
    <>
      <Controller
        control={control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ field: { name, value, onChange, onBlur } }) => {
          const handleOnChange = (
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            if (autoSetValue) onChange(event);
            props.onChange && props.onChange(event, checked);
          };

          const handleOnBlur = (e: any) => {
            onBlur();
            props.onBlur && props.onBlur(e);
          };

          return (
            <CheckboxInput
              {...{
                label,
                name,
                type,
                className,
                inputProps,
                styledWrapper,
                hidden,
              }}
              onChange={handleOnChange}
              disabled={disabled || shouldFieldBeDisabled}
              onBlur={handleOnBlur}
              checked={value}
            />
          );
        }}
      />
      <ErrorMessage name={props.name} />
    </>
  );
};

export default CheckboxInputField;
