import React,
{
  useEffect,
  useState,
} from "react";
import {
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  padding,
} from "theme/defaultStyle";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  isCreateFilePage,
  isChangeAgencyPage,
} from "utils/routes/pathValidator";
import { useFiles } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { 
  AgencyStatus, 
  PERMISSIONS, 
  SessionStorageKeys,   
} from "utils/data/enum";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface IProps {
  selectedAgencyName?: string;
  saveFileChangeRef?: any;
  selectedAgencyStatus?: string;
  isDisabled?: boolean;
}

const Container = styled("div")({
  alignItems :"flex-end",
  backgroundColor: colors.grey01,
  borderRadius: borderRadius.small,
  color: colors.blue06,
  fontSize: fontSize.large,
  fontWeight: fontWeight.bold1,
  gap: gaps.xsmall2,
  margin: "auto",
  "& .right-content": {
    "& .urlLink": {
      color: colors.blue01,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    "& .urlLinkRemove": {
      color: colors.blue01,
      height: "20px !important",
      width: "20px !important",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    "& .iconButton": {
      padding: padding.zero,
    },
   
  },
  "& .number": {
    color: colors.blue06,
    fontWeight: fontWeight.normal1,
  },
});

export enum globalAccessButton {
  removeAgency = "REMOVE_GLOBAL_ACCESS_AGENCY",
  changeAency = "CHANGE_GLOBAL_ACCESS_AGENCY",
}

export enum GlobalAccessAgencyLinkText {
  ChooseAgency = "Choose Agency",
  ChangeAgency = "ChangeAgency"
}

const CHANGE_AGENCY_MESSAGE = "Are you sure you want to change agencies?";
const REMOVE_AGENCY_MESSAGE = "Are you sure you want to remove this agency?";
const CHOOSE_AGENCY = GlobalAccessAgencyLinkText.ChooseAgency; // "Choose Agency";
const CHANGE_AGENCY = GlobalAccessAgencyLinkText.ChangeAgency; // "ChangeAgency";
const REMOVE_AGENCY = "RemoveAgency";

const GlobalAccessAgency = ({ selectedAgencyName, saveFileChangeRef, selectedAgencyStatus, isDisabled }: IProps) => {
  const { redirect } = useNavRedirect();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [{ isReadOnly }, { setInvokingUrl, setIsReadOnly }] = useFiles();  
  const [{ showChangeAgency }, { clearUserSelectedAgency,  }] = useGlobalAccess();  
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [agencyLinkText, setAgencyLinkText] = useState(CHOOSE_AGENCY);
  const [confirmationMessage, setConfirmationMessage] = useState(CHANGE_AGENCY_MESSAGE);
  const [{ profileSettings }] = useProfileSettingsCache();
  
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  const hasFileViewAccess = permissionCodes?.includes(PERMISSIONS.FILES_VIEW) && !permissionCodes?.includes(PERMISSIONS.FILES_MODIFY) ;
  const [actionRequested, setActionRequested] = useState("");
  const changeAgencyPathname = "/";
  const landingPage = sessionStorage.getItem(SessionStorageKeys.LANDING_PAGE);
  const handleDialogYes = async () => {    
    setOpenConfirmationDialog(false);
    setInvokingUrl(pathname, "");
    setActionRequested("");
    
    let redirectPathName = changeAgencyPathname;    
    if (actionRequested === CHANGE_AGENCY) {
      redirectPathName = changeAgencyPathname;
      redirect(redirectPathName, saveFileChangeRef);
    } else if (actionRequested === REMOVE_AGENCY) {
      clearUserSelectedAgency();
      // return;  
      // if (pathname !== "/Home") {
      //   redirectPathName = "/file";
      // }
    }
    // redirect(redirectPathName, saveFileChangeRef);
  };

  const handleDialogNo = () => {
    setInvokingUrl(pathname, "");
    setOpenConfirmationDialog(false);
    setActionRequested("");
  };

  const handleClick = (action: string) => {
    setActionRequested(action);
    setInvokingUrl(pathname, action);

    if (isCreateFilePage(pathname)) {
      if (selectedAgencyStatus?.toUpperCase() === AgencyStatus.Inactive) {
        showConfirmationMessage(action);
      } else {
        let redirectPath = changeAgencyPathname;
        if (action === REMOVE_AGENCY) {
          redirectPath = "/file";
        }
        redirect(redirectPath, saveFileChangeRef);
      }
    } else if (pathname !== changeAgencyPathname && agencyLinkText !== CHOOSE_AGENCY) {
      showConfirmationMessage(action);
    } else {
      navigate(changeAgencyPathname);
    }
  };

  const showConfirmationMessage = (action: string) => {
    setConfirmationMessage(action === CHANGE_AGENCY ? CHANGE_AGENCY_MESSAGE : REMOVE_AGENCY_MESSAGE);
    setOpenConfirmationDialog(true);
  };

  useEffect(() => {
    if (selectedAgencyName) setAgencyLinkText(CHANGE_AGENCY);
    else {
      setAgencyLinkText(CHOOSE_AGENCY);
    }
  }, [selectedAgencyName, setIsReadOnly]);

  useEffect(() => {
    if (selectedAgencyStatus?.toUpperCase() === AgencyStatus.Inactive && !isReadOnly) {
      setIsReadOnly(true);

    }   
    else if (hasFileViewAccess) {
      setIsReadOnly(true);
    }
    else if (selectedAgencyStatus?.toUpperCase() === AgencyStatus.Active && isReadOnly && !hasFileViewAccess) {
      setIsReadOnly(false);
    }
  }, [selectedAgencyStatus, isReadOnly, setIsReadOnly]);

  return (
    <>
      <Container>
        <div
          className="right-content"
          id="chooseAgency"
          data-agency-link-text={agencyLinkText}
        >
          {selectedAgencyName}
          {showChangeAgency &&
            agencyLinkText === CHOOSE_AGENCY &&
            ((isChangeAgencyPage(pathname) &&
              landingPage !== "GlobalAccessHome") ||
              !isChangeAgencyPage(pathname)) && (
              <span
                id="changeGlobalAccessAgency"
                className="urlLink"
                onClick={() => handleClick(CHOOSE_AGENCY)}
              >
                {agencyLinkText}
              </span>
            )}
          {agencyLinkText === CHANGE_AGENCY &&
            (!isChangeAgencyPage(pathname) ||
              landingPage !== "GlobalAccessHome") && (
              <>
                <Tooltip title="Remove">
                  <IconButton
                    className="iconButton"
                    id={globalAccessButton.removeAgency}
                    onClick={() => handleClick(REMOVE_AGENCY)}
                  >
                    <CloseIcon className="urlLinkRemove" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Change">
                  <IconButton
                    className="iconButton"
                    id={globalAccessButton.changeAency}
                    onClick={() => handleClick(CHANGE_AGENCY)}
                  >
                    <SwapHorizOutlinedIcon className="urlLinkRemove" />
                  </IconButton>
                </Tooltip>
              </>
            )}
        </div>
      </Container>

      <ConfirmationDialog
        confirmationMessage={confirmationMessage}
        isOpen={openConfirmationDialog}
        onYes={handleDialogYes}
        onNo={handleDialogNo}
      />
    </>
  );
};

export default GlobalAccessAgency;
