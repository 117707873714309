import React from "react";

export enum ActionType {
  UPDATE = "UPDATE",
  RESET = "RESET",
}

export type Item = {
  name: string;
  childRef: React.MutableRefObject<HTMLElement | undefined> | undefined;
};

type State = {
  items: Map<string, Item>;
};

type Action = {
  type: ActionType;
  item?: Item;
};

type Context = {
  sideNavState: State;
  sideNavAction: React.Dispatch<Action>;
};

const defaultItemValue: Item = {
  name: "",
  childRef: undefined,
};

const initialState: State = {
  items: new Map<string, Item>([
    ["File", defaultItemValue],
    ["Properties", defaultItemValue],
    ["Parties", defaultItemValue],
    ["Products", defaultItemValue],
    ["Pricing & Reporting", defaultItemValue],
    ["File History", defaultItemValue],
  ]),
};

const SideNavContext = React.createContext<Context | undefined>(undefined);

SideNavContext.displayName = "SideNavContext";

const sideNavReducer = ({ items }: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UPDATE: {
      if (action.item) items.set(action.item.name, action.item);
      return {
        items,
      };
    }
    case ActionType.RESET: {
      return { ...initialState };
    }
    default:
      throw new Error("Invalid action type");
  }
};

const SideNavProvider = (props: any) => {
  const [state, dispatch] = React.useReducer(sideNavReducer, initialState);
  const value = { sideNavState: state, sideNavAction: dispatch };
  return <SideNavContext.Provider {...props} value={value} />;
};

const useSideNavProvider = () => {
  const context = React.useContext(SideNavContext);
  if (!context) {
    throw new Error(
      "useSideNavProvider must be used within a <SideNavProvider>"
    );
  }

  return context;
};

export { SideNavProvider, useSideNavProvider };
