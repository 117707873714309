import { styled } from "@mui/material";
import React from "react";
import { gaps } from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricing from "utils/custom-hooks/usePricing";
import { convertToNumberWithNegative } from "utils/shared";
import CoInsuranceField from "./CoInsuranceField";
import OverrideField from "./OverrideField";
import OverrideReasonField from "./OverrideReasonField";
import RateEffectiveDateField from "./RateEffectiveDateField";
import SimultaneousRateField from "./SimultaneousRateField";

type Props = {
  noIssuedProducts: boolean;
  isOverride: boolean;
  overrideReasonError: boolean;
  enableCalculate: (ignoreOverride?: boolean, enableOnly?: boolean) => void;
  onIsOverrideChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onOverrideReasonChange: (event: React.ChangeEvent<any>) => void;
  isOverrideLocked: boolean;
  isFileLocked: boolean;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  "& .MuiInputBase-input": {
    fontSize: "14px"
  },
  "& .MuiOutlinedInput-root": {
    height: "50px",
  }
});

export default function IntegratedPricing({
  noIssuedProducts,
  isOverride,
  overrideReasonError,
  enableCalculate,
  onIsOverrideChange,
  onOverrideReasonChange,
  isOverrideLocked,
  isFileLocked,
}: Props) {
  const { getValues, setValue } = useFormWrapper();
  const { isCoInsuranceEnabled } = usePricing();

  const isNewFile: boolean = getValues("id") === 0;

  const hasIssuedJackets: boolean = getValues("hasIssuedJackets");

  const setCoInsurancePct = () => {
    const isOverride: boolean = getValues("pricing.isOverride");
    const overrideReason: string = getValues("pricing.overrideReason");

    const coInsuranceEnabled = isCoInsuranceEnabled(hasIssuedJackets, isOverride, overrideReason);

    const currentIsCoInsurance: boolean = getValues("pricing.isCoInsurance");

    if (!coInsuranceEnabled) {
      if (currentIsCoInsurance) {
        setValue(
          "pricing.coInsurancePctPrevious",
          getValues("pricing.coInsurancePct")
        );
      }
      setValue("pricing.coInsurancePct", 0); // ""
      // setValue("pricing.isCoInsurance", false);
    } else {
      const coInsurancePctPrevious = convertToNumberWithNegative(
        getValues("pricing.coInsurancePctPrevious")
      );
      if (coInsurancePctPrevious >= 0) {
        setValue("pricing.coInsurancePct", coInsurancePctPrevious);
      } else {
        setValue("pricing.coInsurancePct", 0); // 100
        setValue("pricing.coInsurancePctInitial", 0); // 100
      }
      // setValue("pricing.isCoInsurance", true);
    }
  };

  const handleOverrideReasonChange = (event: React.ChangeEvent<any>) => {
    onOverrideReasonChange(event);
    setCoInsurancePct();
  };

  const handleIsOverrideChange = (event: React.ChangeEvent<any>, checked: boolean) => {
    onIsOverrideChange(event, checked);
    setCoInsurancePct();
  };

  return (
    <Container>
      <SimultaneousRateField
        noIssuedProducts={noIssuedProducts}
        isOverride={isOverride}
        isNewFile={isNewFile}
        enableCalculate={enableCalculate}
      />
      <OverrideField
        isNewFile={isNewFile}
        noIssuedProducts={noIssuedProducts}
        onChange={handleIsOverrideChange}
        isLocked={isOverrideLocked}
      />
      <OverrideReasonField
        isNewFile={isNewFile}
        noIssuedProducts={noIssuedProducts}
        isOverride={isOverride}
        overrideReasonError={overrideReasonError}
        onChange={handleOverrideReasonChange}
        isLocked={isOverrideLocked}
      />
      <CoInsuranceField
        isLocked={isFileLocked}
      />
      <RateEffectiveDateField
        isNewFile={isNewFile}
        noIssuedProducts={noIssuedProducts}
        isOverride={isOverride}
        overrideReasonError={overrideReasonError}
        enableCalculate={enableCalculate}
        isLocked={isOverrideLocked}
      />
    </Container>
  );
}
