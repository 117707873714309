import { styled } from "@mui/material";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import * as UIModel from "entities/UIModel";
import PDFObject from "pdfobject";
import React, {
  useEffect,
  useState,
} from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useDocument } from "utils/context/DocumentContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useStewartAccess } from "utils/context/StewartAccessContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";

const DivRoot = styled("div")({
  height: "100%",
  width: "100%",
});

type Props = {
  pdf: UIModel.PdfDocument;
  isReports?: boolean;
  reports?: UIModel.Reports;
};

type ErrorReport = {
  hasError: boolean;
  message: string;
};

const ShowPdf = ({ pdf, isReports = false, reports }: Props) => {
  const [, { getDocumentUrl }] = useDocument();
  const [, { getProfileSettings }] = useProfileSettingsCache();
  const [, { getReportsString }] = useStewartAccess();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [reportStatus, setReportStatus] = useState<ErrorReport>({ hasError: false, message: '' });
  const { redirect } = useNavRedirect();
  
  useEffect(() => {
    const showDocument = async () => {
      let pdfUrl = "";

      if (isReports && reports) {
        openAutomaticProgressDialog();
        const reportData = await getReportsString(reports);
        if (reportData && reportData.reportString && !reportData.error) {
          const pdfBlob = `data:application/pdf;headers=filename%3D${pdf.documentId};base64,${reportData.reportString}`;

          PDFObject.embed(pdfBlob, `#pdfDiv${pdf?.documentId}`, {
            pdfOpenParams: { view: "FitH" },
          });
        }
        closeAutomaticProgressDialog();

        if (reportData.error) {
          if (reportData?.error?.response?.status === 403) {
            await getProfileSettings();
            redirect("/403", null);
          } else {
            setReportStatus((prevState) => ({
              ...prevState,
              hasError: true,
              message: reportData.error?.response?.statusText,
            }));
          }
        }
      } else {
        //const azureUrl = await getDocumentUrl(pdf.fileId, pdf.productType, pdf.orderId, pdf.documentId, pdf.showHidden);
        // Note: the last "/" before ?url is necessary to hide the url's path name (GetPdfByUrl) within pdf viewer
        let filename = pdf.fileName;
        if (!pdf.fileName.toLowerCase().endsWith(".pdf")) {
          filename = `${filename}.pdf`;
        }
        pdfUrl = `/api/Documents/GetPdfByUrl/?url=${encodeURIComponent(
          pdf.pdfUrl!
        )}&fileName=${encodeURIComponent(filename)}`;

        PDFObject.embed(pdfUrl, `#pdfDiv${pdf?.documentId}`, {
          pdfOpenParams: { view: "FitH" },
        });
      }
    };
    showDocument();
  }, [pdf, getDocumentUrl]);

  return (
    <>
      <ErrorDialog
        isOpen={reportStatus.hasError}
        confirmationMessage={reportStatus.message}
        onYes={() => {
          setReportStatus(prevState => ({ ...prevState, hasError: false }));
        }}
      />
      <DivRoot id={`pdfDiv${pdf.documentId}`} />
    </>
  );
};

export default ShowPdf;
