import React from "react";
import { colors } from "theme/defaultStyle";

export default function CheckCircleIcon() {
  return (
    <svg
      width="84"
      height="85"
      viewBox="0 0 84 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.9997 0.804443C18.9997 0.804443 0.333008 19.4711 0.333008 42.4711C0.333008 65.4711 18.9997 84.1378 41.9997 84.1378C64.9997 84.1378 83.6664 65.4711 83.6664 42.4711C83.6664 19.4711 64.9997 0.804443 41.9997 0.804443ZM41.9997 75.8045C23.6247 75.8045 8.66634 60.8461 8.66634 42.4711C8.66634 24.0961 23.6247 9.13778 41.9997 9.13778C60.3747 9.13778 75.333 24.0961 75.333 42.4711C75.333 60.8461 60.3747 75.8045 41.9997 75.8045ZM61.1247 24.0544L33.6663 51.5128L22.8747 40.7628L16.9997 46.6378L33.6663 63.3044L66.9997 29.9711L61.1247 24.0544Z"
        fill={colors.green}
      />
    </svg>
  );
}
