import { styled } from "@mui/material";
import React from "react";
import {
  borderRadius,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import {
  Box,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import useTitle from "utils/custom-hooks/useTitle";

const StyledBoxContainer = styled(Box)({
  backgroundColor: colors.white,
  borderRadius: borderRadius.xlarge,
  fontFamily: fontFamily.primary,
  padding: padding.medium2,
  paddingLeft: padding.xlarge1,
});

const PrimaryHeading = styled(Typography)({
  color: colors.maroon02,
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge6,
  fontWeight: fontWeight.bold2,
  lineHeight: lineHeight.xlarge,
  padding: padding.xlarge,
  paddingLeft: padding.small1,
  textAlign: "left",
});

const SecondaryHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge0,
  padding: padding.small1,
});

const ThirdHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  padding: padding.small1,
});

const ListContainer = styled("ul")({
  paddingLeft: padding.xlarge,
});

const ListItem = styled("li")({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  listStyleType: "disc",
  marginBottom: margin.small2,
  marginLeft: margin.xlarge,
  padding: padding.xsmall,
  "& a": {
    color: colors.blue01,
    textDecoration: "underline",
  },
});

const sections = [
  {
    heading:
      "The Stewart Preferred Program provides products and services to Stewart Trusted Providers at nationally discounted prices.",
    subheadings: [
      "Stewart Preferred is your discount tool for business and personal transactions. Browse the site to find out how you can save money through our discounted vendor programs.",
      "The Stewart Preferred Program is managed by Procurement at Stewart's headquarters in Houston. The department works to provide services and support to Stewart offices and agencies nationwide.",
    ],
  },
  {
    heading: "Digital Solutions",
    items: [
      "CertifID",
      "Digital Funds Transfer Mobile App - ZOCCAM",
      "eClosing Online Collaboration Platform - Pavaso",
      "Electronic Signature Platform - DocuSign",
      "eNotary Log",
      "Loan Origination System Partnerships",
      "Remote Online Notarization - NotaryCam",
    ],
  },
  {
    heading: "Office Solutions",
    items: [
      "Check Stock",
      "Copiers and Printers",
      "Office Breakroom Solutions",
      "Office Supply Services",
    ],
  },
  {
    heading: "Business Solutions",
    items: [
      "ASK Services",
      "Background Checks",
      "Car Rental",
      "Insurance Services",
      "Mobility Market Intelligence",
      "Secure Email Encryption",
      "Title Industry Training",
      "Video Email Marketing",
      "Wireless Devices",
    ],
  },
];

const StewartPreferred = () => {
  useTitle("Stewart Preferred")
  return (
    <>
      <StyledBoxContainer>
        <PrimaryHeading variant="h1">
          Welcome to Stewart Preferred
        </PrimaryHeading>
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            {section.heading !== "Welcome to Stewart Preferred" && (
              <SecondaryHeading variant="h6">
                {section.heading}
              </SecondaryHeading>
            )}
            {section.subheadings &&
              section.subheadings.map((subheading, i) => (
                <ThirdHeading key={i}>{subheading}</ThirdHeading>
              ))}
            {section.items && (
              <ListContainer>
                {section.items.map((item, i) => (
                  <ListItem key={i}>
                    {section.heading == "Business Solutions" ? (
                      <Link to={`/stewartPreferredBusinessSolution/${item}`}>
                        {item}
                      </Link>
                    ) : section.heading == "Office Solutions" ? (
                      <Link to={`/stewartPreferredOfficeSolution/${item}`}>
                        {item}
                      </Link>
                    ) : (
                      <Link to={`/stewartPreferredDigitalSolution/${item}`}>
                        {item}
                      </Link>
                    )}
                  </ListItem>
                ))}
              </ListContainer>
            )}
          </React.Fragment>
        ))}
      </StyledBoxContainer>
    </>
  );
};

export default StewartPreferred;
