import { Paid } from "@mui/icons-material";
import ProgressbarDialog from "controls/global/dialogs/confirmation-dialog-with-progressbar/ProgressbarDialog";
import CommonStewartDialog from "controls/global/dialogs/stewart-dialog/CommonStewartDialog";
import FormSection from "controls/global/form-section";
import { PricingProduct } from "entities/UIModel/PricingProduct";
import {
  ActionType,
  useSideNavProvider,
} from "pages/file/SideNavProvider";
import React, {
  useEffect,
  useState,
} from "react";
import { useFormState } from "react-hook-form";
import GradientIcon from "theme/icons/GradientIcon";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import usePricing from "utils/custom-hooks/usePricing";
import PricingData from "./PricingData";

type Props = {
  onNavigate: (url: string) => void;
};

const PricingSection = ({ onNavigate }: Props) => {
  const [isError, setIsError] = useState(false);
  const { errors } = useFormState();
  const { showPricing } = usePricing();
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const { sideNavState, sideNavAction } = useSideNavProvider();
  const pricingReportingsRef = React.useRef<HTMLElement>();

  useEffect(() => {
    const pricingProductsErrors: any = errors.pricingProducts;
    const hasErrors: any =
      pricingProductsErrors &&
      pricingProductsErrors.filter(
        (product: PricingProduct) => !product.originalPolicy // Don't display any error message when OPN validation fails
      ).length > 0;

    if (isError !== hasErrors) setIsError(hasErrors);
  }, [errors, isError]);

  useEffect(() => {
    if (showPricing()) {
      if (sideNavState.items.get("Pricing & Reporting")?.childRef?.current)
        return;
      sideNavAction({
        type: ActionType.UPDATE,
        item: { name: "Pricing & Reporting", childRef: pricingReportingsRef },
      });
    } else {
      if (!sideNavState.items.get("Pricing & Reporting")?.childRef?.current)
        return;
      sideNavAction({
        type: ActionType.UPDATE,
        item: { name: "Pricing & Reporting", childRef: undefined },
      });
    }
  }, [showPricing, sideNavState.items]);

  if (!showPricing()) return null;

  return (
    <>
      <FormSection
        navItemRef={pricingReportingsRef}
        icon={<GradientIcon Icon={Paid} />}
        isError={isError}
        title={enableReportPay ? "Pricing & Reporting" : "Pricing"}
      >
        <PricingData onNavigate={onNavigate} />
      </FormSection>
      <CommonStewartDialog />
      <ProgressbarDialog />
    </>
  );
};

export default PricingSection;
