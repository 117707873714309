import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Reports } from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { tabMapping } from "utils/tabNavigator";

export type MetaTab = {
  Name: string;
  ComponentName: keyof typeof tabMapping;
};

export type Product = {
  CoveredParties: any;
  VoidReasons: any;
  "@Type": string;
};

export interface Metadata {
  products: Array<Product>;
  addresseeName: any;
  forms?: Array<FormType>;
  tabs: Array<MetaTab>;
  //contractedCounties: Array<SelectFieldOption>;
  stateCode: string;
  authorityLimit?: string;
  legacyID?: string;
  territoryID?: string;
  locations?: Array<Location>;
  taxCodes?: Array<TaxCode>;
  taxCodeOptions?: Array<SelectFieldOption>;
  source?: string;
}

export interface Location {
  altaRegistryID?: string;
  contractID?: string;
  legacyID?: string;
  locationID?: string;
  locationUniqueID?: string;
  name?: string;
  locationLegalName?: string;
  territoryID?: string;
}

//Called MunicipalCodes in Metadata
export interface TaxCode {
  displayName?: string;
  isCounty?: string;
  isMunicipality?: string;
  minTax?: string;
  municipalCountyName?: string;
  name?: string;
  searchName?: string;
  tax?: string;
}

interface ActionResult {
  reportString?: string;
  error?: any;
}

interface State {
  error: string | null;
  reportString?: string;
}

export type FormItem = {
  "@Name": string;
  "@Default": string;
  "@AgencyExclusive": string;
  "@FormID": string;
  "@FormVersion": number;
  "@FormTemplateName": string;
  "@OPAIdentifier": string;
};

export interface FormData {
  name?: string;
  formID?: string;
  formVersion?: string;
  formTemplateName?: string;
  opaIdentifier?: string;
  agencyExclusive?: string;
  liabilityAmount?: string;
}

export interface FormDetailData {
  formID?: string;
  formDetailID?: string;
  formDetailName?: string;
}

export type TransCode = {
  "@Value": string;
  "@Description": string;
};

export type Form = {
  Form: any;
};

export type FormTypeTransCode = {
  TransCode: Array<TransCode>;
};

type EndorsementItem = {
  "@EndorsementName": string;
  "@EndorsementID": string;
  "@EndorsementVersion": string;
  "@OPAIdentifier": string;
};

type FormTypeEndorsement = {
  Endorsement: EndorsementItem[];
};

export type FormType = {
  Forms: Form;
  FormTypeTransCodes: FormTypeTransCode;
  FormTypeEndorsements: FormTypeEndorsement;
  VoidReasons: any;
  "@Type": string;
  "@Default": string;
  "@TransCode": string;
};

type StoreApi = StoreActionApi<State>;

const setReportString =
  (reportString: any) =>
    ({ setState }: StoreApi) => {
      setState({
        reportString: reportString,
      });

      return reportString;
    };

const Store = createStore<State, any>({
  initialState: {
    error: null,
  },
  actions: {
    getReportsString:
      (reports: Reports) =>
        async ({ dispatch }: StoreApi): Promise<ActionResult> => {
          try {
            const { data } = await axiosSecuredInstance.get(
              `stewartaccess/getreportstring?agency=${encodeURIComponent(
                reports.agency
              )}&state=${encodeURIComponent(
                reports.state
              )}&agencyLocation=${encodeURIComponent(
                reports.agencyLocation
              )}&dateType=${encodeURIComponent(
                reports.dateType
              )}&beginDate=${encodeURIComponent(
                reports.beginDate
              )}&endDate=${encodeURIComponent(
                reports.endDate
              )}&reportFormat=${encodeURIComponent(reports.reportFormat)}`
            );

            const reportData = dispatch(setReportString(data.fileResponse));
            return {
              reportString: reportData,
            };
          } catch (error: any) {
            return {
              reportString: "",
              error,
            };
          }
        },
  },
  name: "stewartaccess",
});

const hook = createHook(Store);
export const useStewartAccess = () => {
  return hook();
};
