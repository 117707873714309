import {
  styled,
} from "@mui/material";
import React, {
  useEffect,
} from "react";
import { useWatch } from "react-hook-form";
import {
  useAgencyLocations,
  useAgencyLocationsActions,
} from "utils/context/AgencyLocationsContext";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import ViewPendingPayment from "../common/ViewPendingPayment";
import AgencyField from "./AgencyField";
import AgencyLocationField from "./AgencyLocationField";
import FileNameNumberField from "./FileNameNumberField";
import theme from "theme/default";
import {
  gaps,
  margin,
  padding,
} from "theme/defaultStyle";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import HiddenFileItems from "./HiddenFileItems";
import TransactionTypeCodeField from "./TransactionTypeCodeField";
import TextInputField from "controls/global/text-input-field";
import { useFiles } from "utils/context/FilesContext";
// import { useGlobalAccess } from "utils/context/GlobalAccessContext";
// import useDocumentStore from "utils/context/ImageArchiveContext";
// import { ImageArchiveSearchCriteria } from "entities/ApiModel/ImageArchiveSearchCriteria";
// import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";

type Props = {
  onNavigate: (url: string) => void;
};

const FieldContainer = styled("div")({
  display: "grid",
  gridGap: gaps.large1,
  gridTemplateColumns: "1fr 1fr minmax(380px, 2fr)",
  gridTemplateRows: "1fr 1fr",
  margin: margin.zero,
  padding: padding.zero,
  position: "relative",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "minmax(380px, 1fr) 1fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "100%",
  },
  "& > .viewPendingPayment": {
    alignSelf: "center",
  },
  "& > div > div": {
    width: "100%",
  },
});

const FileData = ({ onNavigate }: Props) => {
  const { getValues } = useFormWrapper();
  const { isFileLocked, isReadOnly } = useCreateFile();
  const [ {isReadOnly: isReadOnlyByInactiveAgency ,isReadOnlyMode} ] = useFiles();
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const [{ agencyStates }, { updateAgencyStates }] = useAgencyStates();
  const [{ fileAgencyLocations, isRetrievingAgencyLocations }] =
    useAgencyLocations();
  const [, { updateAgencyLocations }] = useAgencyLocationsActions();
    
  const [fileId] = useWatch({
    name: ["id"],
  });
  const isFileReadOnly = 
  (!isReadOnlyMode && isReadOnly)
    ? isReadOnlyMode
    : (isReadOnlyByInactiveAgency || isReadOnly );

  useEffect(() => {
    if (isRetrievingAgencyLocations || fileId <= 0) return;
    const hasIssuedProducts = getValues("hasIssuedProducts");
    const currentAgencyLocation = getValues(`agencyLocationOriginal`);    
    if (
      fileAgencyLocations &&
      fileAgencyLocations.length === 0 &&
      currentAgencyLocation?.name &&
      (hasIssuedProducts || isReadOnly)
    ) {
      let updatedAgencyLocationList: any[] = [];
      updatedAgencyLocationList.push({
        companyID: currentAgencyLocation?.id,
        locationDisplayName: currentAgencyLocation?.name,
        isDisabled: true,
        locationUniqueID: currentAgencyLocation?.currentAgencyLocation
      });
      updateAgencyLocations(updatedAgencyLocationList);
    }
  }, [fileAgencyLocations, fileAgencyLocations.length]);

  useEffect(() => {
    const currentPropertyState = getValues(`properties.0.stateOriginal`);
    const hasIssuedProducts = getValues("hasIssuedProducts");
    if (
      agencyStates &&
      agencyStates.length >= 0 &&
      currentPropertyState?.code &&
      hasIssuedProducts
    ) {
      const findStateInTheList = agencyStates.filter(
        (al) => al.value === currentPropertyState?.code
      );
      let updatedAgencyStatesList: any[] = [];
      if (findStateInTheList.length === 0) {
        updatedAgencyStatesList.push({
          stateAbbr: currentPropertyState?.abbr,
          stateCode: currentPropertyState?.code,
        });
        agencyStates.forEach((al) => {
          updatedAgencyStatesList.push({
            stateAbbr: al.text,
            stateCode: al.value,
          });
        });
        updateAgencyStates(updatedAgencyStatesList);
      }
    }
  }, [agencyStates, agencyStates.length]);

  // useEffect(() => {
  //   if (fileId > 0) {
  //     const criteria: ImageArchiveSearchCriteria = {
  //       fileId: fileId,
  //       listCount: 0,
  //       requestedPage: 1,
  //     };     
  //     let files: ImageArchiveUI[] | undefined = [];
  //     const searchDocument = async() => {
  //       try {
  //         files = await getSearchDocuments({ ...criteria });
  //       } catch (error) {
  //         // do nothing here
  //       }
  //       setValue("hasUploadedPolicy", (files && files.length > 0));
  
  //     };

  //     searchDocument();
  //   }
  // },[fileId])

  return (
    <FieldContainer>
      <HiddenFileItems />
      <div className="fileNameNumberField">
        <FileNameNumberField data-test="fileNameNumber" />
      </div>
      <div className="agencyField">
        <AgencyField data-test="agency" />
      </div>
      <div className="agencyLocationField">
        <AgencyLocationField
          agencyLocations={fileAgencyLocations}
          data-test="agencyLocation"
        />
      </div>
      <div className="transactionTypeCodeField">
        <TransactionTypeCodeField data-test="transactionTypeCode" />
      </div>
      <div className="statusTypeField">
        <TextInputField
          label="Status"
          name="statusTypeName"
          disabled={true}
        />
      </div>
      {isFileLocked && enableReportPay &&
        <div className="viewPendingPayment">
          <ViewPendingPayment onNavigate={onNavigate} disabled={isFileReadOnly} />
        </div>
      }
    </FieldContainer>
  );
};

export default FileData;
