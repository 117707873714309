import {
  AppBar,
  Dialog,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";

const StyledDialog = styled(Dialog)({
  "& div.MuiPaper-root": {
    height: "80%",
    maxWidth: "none",
    overflow: "hidden",
    width: "80%",
    "& .appBarParentRoot": {
      flexGrow: 1,
    },
    "& .title": {
      flexGrow: 1,
      fontSize: fontSize.base,
      fontWeight: fontWeight.bold1,
      lineHeight: 1.6,
      marginBottom: margin.zero,
      marginLeft: margin.xlarge35,
      marginRight: margin.xlarge35,
      marginTop: margin.zero,
      overflowWrap: "anywhere",
    },
    "& .pdfContainer": {
      display: "block",
      height: "100%",
      position: "relative",
      width: "100%",
    },
    "& .diwWrapper": {
      height: "100%",
      width: "100%",
    },
  },
});

type EmbedViewerProps = {};

export type EmbedViewerRefType = {
  open: (title: string, src: string) => Promise<void>;
};

const EmbedViewer = (props: EmbedViewerProps, ref: Ref<EmbedViewerRefType>) => {
  const [open, setOpen] = useState(false);
  const [titleSource, setTitleSource] = useState<string>();
  const [source, setSource] = useState<string>();
  const [blobUrlSource, setBlobUrlSource] = useState<string>();

  useImperativeHandle(ref, () => ({
    open: async (title, src) => {
      setTitleSource(title);
      setSource(src);
    },
  }));

  useEffect(() => {
    if (source) {
      fetch(source)
        .then((res) => res.blob())
        .then(URL.createObjectURL)
        .then((blobUrl) => {
          setBlobUrlSource(blobUrl);
          setOpen(true);
        });
    }
  }, [source]);

  function onClose(): void {
    setOpen(false);
    setTitleSource("");
    setSource("");
  }

  return (
    <StyledDialog
      disableEscapeKeyDown={true}
      open={open}
    >
      <div className={"appBarParentRoot"}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={"title"}>
              {titleSource}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div className={"pdfContainer"}>
        <div className={"diwWrapper"}>
          <embed
            src={`${blobUrlSource}#toolbar=0`}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </StyledDialog>
  );
};

export default forwardRef<EmbedViewerRefType, EmbedViewerProps>(EmbedViewer);
