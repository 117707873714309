import { JacketFormDetail } from "entities/UIModel";
import NumberInput from "controls/global/number-input-field/NumberInput";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import React, {
  useEffect,
  useState,
} from "react";

interface Props {
  index: number;
  name: string;
  formDetail: JacketFormDetail;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: "small" | "medium";
}
const defaultValue: string = "$0.00";
function NumberField({ index, name, formDetail, disabled, size = "medium", fullWidth = true }: Props) {
  const [state, setState] = useState(formDetail.formDetailValue);
  const { setValue } = useFormWrapper();

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    const currentValue = event.target.value;
    if(currentValue) setState(currentValue);
    else setState(defaultValue);
  };

  const handleOnBlur = (event: React.ChangeEvent<any>) => {
    const newValue = {
      ...formDetail,
      formDetailValue: event.target.value || "",
    };
    setValue(`${name}.${index}`, newValue);
  };

useEffect(() =>{
  if(!state) setState(formDetail.formDetailValue);
}
,[formDetail.formDetailValue, state])
  return (
    <NumberInput
      name={`${name}.${index}.formDetailValue`}
      value={state}
      disabled={disabled}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      prefix="$"
      size={size}
      fullWidth={fullWidth}
      placeholder={defaultValue}
    />
  );
}

export default NumberField;
