import { CompanyLocationState as ApiCompanyLocationState } from "entities/ApiModel/CompanyLocationState";
import { CompanyLocationState as UiCompanyLocationState } from "entities/UIModel/CompanyLocationState";

export const mapApiToUi = (apiDataArray: ApiCompanyLocationState[]): UiCompanyLocationState[] => {
  const uiDataArray = apiDataArray.map((apiData) => {
    const territoryStates = apiData.TerritoryStates
      ? apiData.TerritoryStates
          .split("|")
          .map((state) => state.trim())
          .sort()
          .join(", ")
      : undefined;

    const uiData: UiCompanyLocationState = {
      companyID: apiData.CompanyID,
      companyName: apiData.CompanyName,
      companyLegacyID: apiData.CompanyLegacyID,
      locationLegacyIDs: apiData.LocationLegacyIDs?.replace(/\|/g, ", "),
      locationDisplayNames: apiData.LocationDisplayNames,
      territoryStates: territoryStates,
      status: apiData.Status,
    };
    return uiData;
  });
  return uiDataArray;
};
