import SelectFieldWithClearIcon from "controls/global/select-field/SelectFieldWithClearIcon";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";

import React, {
useEffect, useState,  
} from "react";
import {  
useWatch,
useFormContext,
} from "react-hook-form";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

type Props = {
name: string;
userStates?:  SelectFieldOption[];
onChange?: (_: React.ChangeEvent<{}>) => void;
};

const PropertyStateField = ({name, userStates, onChange}: Props) => {
const { setValue, getValues } = useFormContext();
const [{ profileSettings }] = useProfileSettingsCache();

const [agencyField, agencyLocationField] = useWatch({
    name: ["userProfile.defaultAgencyID", "userProfile.defaultAgencyLocation"],
});  
const [{ agencyStates } , { getAgencyStates }] = useAgencyStates();
const [stateOptions, setStateOptions] = useState<SelectFieldOption[]>([]);
const [stateCleared, setStateCleared] = useState<boolean>(false);
// eslint-disable-next-line react-hooks/exhaustive-deps
// const states = agencyStates?.length > 0 ? [{ text: "Select", value: "" }, ...agencyStates] : 
//              userStates && userStates?.length > 0 ? userStates : [];

const handleOnChange = (e: React.ChangeEvent<any>) => {    
    if(e?.target?.value){        
        setValue(name, e.target.value);       
        onChange?.(e);
        setStateCleared(false);
    } else {
        if(stateOptions?.length > 1){
            setValue(name, "");
            setStateCleared(true);       
        }        
    }
}

useEffect(() => {
    const currentAgencyID = getValues("userProfile.defaultAgencyID");
    const currentAgencyLocationID = getValues("userProfile.defaultAgencyLocation");

    if (currentAgencyID) {
        getAgencyStates(currentAgencyID, currentAgencyLocationID?.value);
    }else{
        setStateOptions(userStates || []);
    }
}, [agencyField, agencyLocationField, getValues]);

useEffect(() => {

    const currentAgencyID = getValues("userProfile.defaultAgencyID");
    //const currentAgencyLocationID = getValues("userProfile.defaultAgencyLocation");
    
    if (currentAgencyID && agencyStates?.length > 0) {        
        setStateOptions(agencyStates);
    } 
}, 
[agencyStates, agencyField, agencyLocationField]);

useEffect(() => {   
    if(!stateOptions) return;
    if(stateOptions?.length === 1) {
        setValue(name, stateOptions[0].value);    
    } else if(stateOptions?.length >= 2 && !stateCleared) {
        const currentState = getValues(name) || profileSettings?.userProfile?.defaultPropertyState;            
                
        if(currentState) {
            const matchingState = stateOptions.find((s) => s.value === currentState);
            if(matchingState) {
                setValue(name, matchingState.value);
            } else {
                setValue(name, "");
            }
        } else {
            setValue(name, "");
        }
    }
},
[stateOptions, setValue, name, getValues]);

return (
    <SelectFieldWithClearIcon
        label="Property State"
        name={name}
        options={stateOptions}
        allowsDelete={true}
        onChange={handleOnChange}
    />
);
};

export default PropertyStateField;
                  