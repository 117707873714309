import {
  ActionResult,
  Endorsement,
  FilePricingDetail,
  Jacket,
  PricingProduct,
  PricingProductDetail,
  SCFile,
  SCOrder,
  StandaloneEndorsement,
} from "entities/UIModel";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { useFiles } from "utils/context/FilesContext";
import {
  // JacketFormType,
  MapActionType,
  OverrideReasonType,
  PaymentSheetOptionType,
  PricingType,
  ProductAction,
  ProductType,  
} from "utils/data/enum";
import useFormWrapper from "./useFormWrapper";
import { getNameString } from "utils/custom-hooks/useFormWrapper";
import cloneDeep from "lodash/cloneDeep";
// import { roundToPrecision } from "utils/shared";
import useProductOption from "./useProductOption";
import { isPricingItemUpdateable } from "pages/file/utils/helper";
import { usePricingActions } from "utils/context/PricingContext";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { usePricingCalculatorContext } from "utils/context/PricingCalculatorContext";
import { PAYMENT_SHEET_OPTION_TYPE } from "pages/payments/components/payment-sheet-option/PaymentSheetSelectionOptions";
import { PendingPaymentItem } from "entities/ApiModel/PendingPaymentItem";
import { uniqBy } from "lodash";

const usePricing = () => {
  const { setValue, getValues } = useFormWrapper();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [{ initialValues }] = useFiles();
  const [, { calculateItems }] = usePricingCalculatorContext();
  const [, { setIsPricingCalculated, setPricingNotificationUpdated }] = usePricingActions();
  const { getUniqueProductOptions } = useProductOption();

  // Show Pricing section ONLY when Product is available
  const showPricing = (): boolean => {
    const currentFile: SCFile | undefined = getValues();
    const uniqueProductOptions = getUniqueProductOptions();

    return currentFile &&
      currentFile.fileNameNumber &&
      currentFile.agency?.id &&
      currentFile.agencyLocation?.id &&
      currentFile.properties?.length > 0 &&
      currentFile.properties[0].state?.code &&
      uniqueProductOptions.length > 0
      ? true
      : false;
  };

  const copyPricingProducts = (
    calculatedPricingProducts: PricingProduct[],
    pendingPricingProducts: PricingProduct[]
  ) => {
    calculatedPricingProducts?.forEach((pp) => {
      const matchingIndex = pendingPricingProducts?.findIndex(
        (a) =>
          a.pricingType === pp.pricingType &&
          a.filePricingDetailId === pp.filePricingDetailId
      );

      if (matchingIndex >= 0) {
        try {
          setValue(getNameString(`pricingProducts.${matchingIndex}`), pp);
        } catch (e) {
          console.error("CopyPricingProducts: ", e);
        }
      }
    });
  };

  const copyUpdatedInitialPricingProducts = (
    calculatedPricingProducts: PricingProduct[],
    pendingPricingProducts: PricingProduct[]
  ) => {
    calculatedPricingProducts?.forEach((pp) => {
      const matchingIndex = pendingPricingProducts?.findIndex(
        (a) =>
          a.pricingType === pp.pricingType &&
          a.filePricingDetailId === pp.filePricingDetailId
      );
      if (matchingIndex >= 0)
        setValue(`updatedInitialPricingProducts.${matchingIndex}`, pp);
    });
  };

  const copyUpdatedInitialFilePricingDetails = (
    calculatedFilePricingDetails: FilePricingDetail[],
    currentFilePricingDetails: FilePricingDetail[]
  ) => {
    calculatedFilePricingDetails?.forEach((fpd) => {
      const matchingIndex = currentFilePricingDetails?.findIndex(
        (a) => a.id === fpd.id );

      if (matchingIndex >= 0)    {          
        setValue(`updatedInitialFilePricingDetail.${matchingIndex}`, fpd);
      }
    });
  };

  const copyPricingDetails = (
    pendingProducts: SCOrder[],
    calculatedProducts: SCOrder[],
    productCollection: string
  ) => {
    calculatedProducts.forEach((product) => {
      const matchedIndex = pendingProducts.findIndex(
        (p) => p.integrationKey === product.integrationKey
      );
      const matched =
        matchedIndex > -1 ? pendingProducts[matchedIndex] : undefined;
      if (matched) {
        if (!matched.pricingDetail) {
          matched.pricingDetail = product.pricingDetail;
        } else {
          matched.pricingDetail.actualFee = product.pricingDetail?.actualFee;
          matched.pricingDetail.calculatedFee =
            product.pricingDetail?.calculatedFee;
          matched.pricingDetail.actualRemittance =
            product.pricingDetail?.actualRemittance;
          matched.pricingDetail.calculatedRemittance =
            product.pricingDetail?.calculatedRemittance;
          matched.pricingDetail.actualRiskRate =
            product.pricingDetail?.actualRiskRate;
          matched.pricingDetail.calculatedRiskRate =
            product.pricingDetail?.calculatedRiskRate;
          matched.pricingDetail.actualPremiumTax =
            product.pricingDetail?.actualPremiumTax;
          matched.pricingDetail.calculatedPremiumTax =
            product.pricingDetail?.calculatedPremiumTax;
          matched.pricingDetail.actualRetention =
            product.pricingDetail?.actualRetention;
          matched.pricingDetail.calculatedRetention =
            product.pricingDetail?.calculatedRetention;
          matched.pricingDetail.chargeType = product.pricingDetail?.chargeType;
          matched.pricingDetail.coverageType =
            product.pricingDetail?.coverageType;
          matched.pricingDetail.coverageTypeData =
            product.pricingDetail?.coverageTypeData;
          matched.pricingDetail.pricingRateType =
            product.pricingDetail?.pricingRateType;
          matched.pricingDetail.pricingRateTypeData =
            product.pricingDetail?.pricingRateTypeData;
          matched.pricingDetail.isReissue = product.pricingDetail?.isReissue;
          matched.pricingDetail.altPricingReferenceID =
            product.pricingDetail?.altPricingReferenceID;
          matched.pricingDetail.lastErrorMessage =
            product.pricingDetail?.lastErrorMessage;
          matched.pricingDetail.isFeeError =
            product.pricingDetail?.isFeeError;
          matched.pricingDetail.isManualFee =
            product.pricingDetail?.isManualFee;                        
        }

        setValue(
          getNameString(`${productCollection}.${matchedIndex}.pricingDetail`),
          matched.pricingDetail
        );
      }
    });
  };

  const copyEndorsementsPricingDetails = (
    pendingFile: SCFile,
    calculatedFile: SCFile
  ) => {
    const calculatedJackets = calculatedFile.jackets;
    const pendingJackets = pendingFile.jackets;

    calculatedJackets.forEach((calculatedJacket) => {
      const matchedPendingJacketIndex = pendingJackets.findIndex(
        (p) => p.integrationKey === calculatedJacket.integrationKey
      );
      const matchedPendingJacket =
        matchedPendingJacketIndex > -1
          ? pendingJackets[matchedPendingJacketIndex]
          : undefined;

      if (matchedPendingJacket) {
        matchedPendingJacket.endorsements?.forEach(
          (pendingEndo: Endorsement, index) => {
            const calculatedEndo = calculatedJacket.endorsements?.find(
              (ce) => ce.endorsementID === pendingEndo.endorsementID
            );
            if (calculatedEndo && pendingEndo)
              updateEndorsementPricingDetails(
                pendingEndo,
                calculatedEndo,
                index,
                `jackets.${matchedPendingJacketIndex}`
              );
          }
        );
      }
    });

    const calculatedSAProducts = calculatedFile.standaloneEndorsements;
    const pendingSAProducts = pendingFile.standaloneEndorsements;

    calculatedSAProducts.forEach((calculatedSAProduct) => {
      const matchedPendingSAIndex = pendingSAProducts.findIndex(
        (p) => p.integrationKey === calculatedSAProduct.integrationKey
      );
      const matchedPendingSAProduct =
        matchedPendingSAIndex > -1
          ? pendingSAProducts[matchedPendingSAIndex]
          : undefined;

      if (matchedPendingSAProduct) {
        matchedPendingSAProduct.endorsements?.forEach(
          (pendingSAEndo: Endorsement, index) => {
            const calculatedSAEndo = calculatedSAProduct.endorsements?.find(
              (ce) => ce.endorsementID === pendingSAEndo.endorsementID
            );
            if (calculatedSAEndo && pendingSAEndo)
              updateEndorsementPricingDetails(
                pendingSAEndo,
                calculatedSAEndo,
                index,
                `standaloneEndorsements.${matchedPendingSAIndex}`
              );
          }
        );
      }
    });
  };

  const updateEndorsementPricingDetails = (
    matched: Endorsement,
    product: Endorsement,
    matchedIndex: number,
    productCollection: string
  ) => {
    if (matched) {
      if (!matched.pricingDetail) {
        matched.pricingDetail = product.pricingDetail;
      } else {
        matched.pricingDetail.actualFee = product.pricingDetail?.actualFee;
        matched.pricingDetail.calculatedFee =
          product.pricingDetail?.calculatedFee;
        matched.pricingDetail.actualRemittance =
          product.pricingDetail?.actualRemittance;
        matched.pricingDetail.calculatedRemittance =
          product.pricingDetail?.calculatedRemittance;
        matched.pricingDetail.actualRiskRate =
          product.pricingDetail?.actualRiskRate;
        matched.pricingDetail.calculatedRiskRate =
          product.pricingDetail?.calculatedRiskRate;
        matched.pricingDetail.actualPremiumTax =
          product.pricingDetail?.actualPremiumTax;
        matched.pricingDetail.calculatedPremiumTax =
          product.pricingDetail?.calculatedPremiumTax;
        matched.pricingDetail.actualRetention =
          product.pricingDetail?.actualRetention;
        matched.pricingDetail.calculatedRetention =
          product.pricingDetail?.calculatedRetention;
        matched.pricingDetail.chargeType = product.pricingDetail?.chargeType;
        matched.pricingDetail.coverageType =
          product.pricingDetail?.coverageType;
        matched.pricingDetail.coverageTypeData =
          product.pricingDetail?.coverageTypeData;
        matched.pricingDetail.pricingRateType =
          product.pricingDetail?.pricingRateType;
        matched.pricingDetail.pricingRateTypeData =
          product.pricingDetail?.pricingRateTypeData;
        matched.pricingDetail.altPricingReferenceID =
          product.pricingDetail?.altPricingReferenceID;
        matched.pricingDetail.lastErrorMessage =
          product.pricingDetail?.lastErrorMessage;
        matched.pricingDetail.isFeeError =
          product.pricingDetail?.isFeeError;
        matched.pricingDetail.isManualFee =
          product.pricingDetail?.isManualFee;          
      }

      setValue(
        getNameString(
          `${productCollection}.endorsements.${matchedIndex}.pricingDetail`
        ),
        matched.pricingDetail
      );
    }
  };

  const calculateTaxes = async () => {
    openAutomaticProgressDialog();
    setIsPricingCalculated(false);
    // rlo 3/31/2021 -- Must call setPricingNotificationUpdated with false and true in order to refresh the pricing notification
    setPricingNotificationUpdated(false);

    // map scfile for calling api
    const scfile = getValues() as SCFile;

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      scfile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.CalculateItems
    );

    //Need this clone because pricing products in values is changing
    //a result of calculate pricing.
    const stableValues = cloneDeep(scfile);

    const actionResult: ActionResult = await calculateItems(
      scfile,
      apiSCFile,
      true,
      true
    );

    // the api (calculateItems)  doesn't update database so have to set values manually in order to preserve isDirty state
    if (!actionResult.error) {
      const pendingFile: SCFile = stableValues;
      const calculatedFile = actionResult.scfile;
      if (calculatedFile) {
        if (calculatedFile.pricing) {
          setValue("pricing.errorMessage", calculatedFile.pricing.errorMessage);
          setValue("pricing.infoMessage", calculatedFile.pricing.infoMessage);
          setValue("pricing.infoMessageFontColor", calculatedFile.pricing.infoMessageFontColor);
          setValue("pricing.infoMessageFontWeight", calculatedFile.pricing.infoMessageFontWeight);
        }

        copyPricingProducts(
          calculatedFile.pricingProducts,
          pendingFile.pricingProducts
        );

        // rlo 3/30/2022 - should not update updatedInitialPricingProducts, because updatedInitialPricingProducts should be the same like the record in database
        // and calculateItems does not update the database
        // copyUpdatedInitialPricingProducts(
        //   calculatedFile.updatedInitialPricingProducts,
        //   pendingFile.updatedInitialPricingProducts
        // );

        if (pendingFile.aALProducts && calculatedFile.aALProducts) {
          copyPricingDetails(
            pendingFile.aALProducts,
            calculatedFile.aALProducts,
            "aALProducts"
          );
        }
        copyPricingDetails(pendingFile.cpls, calculatedFile.cpls, "cpls");

        copyPricingDetails(
          pendingFile.jackets,
          calculatedFile.jackets,
          "jackets"
        );

        copyPricingDetails(
          pendingFile.standaloneEndorsements,
          calculatedFile.standaloneEndorsements,
          "standaloneEndorsements"
        );

        copyEndorsementsPricingDetails(pendingFile, calculatedFile);
      }
    }

    setPricingNotificationUpdated(true);
    setIsPricingCalculated(true);
    closeAutomaticProgressDialog();
  };

  // rlo 11/15/2022 -- It's intended to comment out and leave it here for now
  // const calculateCoInsurance = (
  //   pp: PricingProductDetail,
  //   coInsurancePct: number
  // ) => {
  //   pp.actualFee = roundToPrecision(
  //     (pp.calculatedFee || 0) * (coInsurancePct / 100),
  //     2
  //   );
  //   pp.actualFeeInitial = pp.actualFee;
  //   pp.actualPremiumTax = roundToPrecision(
  //     (pp.calculatedPremiumTax || 0) * (coInsurancePct / 100),
  //     2
  //   );
  //   pp.actualRiskRate = roundToPrecision(
  //     (pp.calculatedRiskRate || 0) * (coInsurancePct / 100),
  //     2
  //   );
  //   pp.actualRiskRateInitial = pp.actualRiskRate;
  //   pp.totalDue = roundToPrecision(
  //     (pp.calculatedTotalDue || 0) * (coInsurancePct / 100),
  //     2
  //   );
  //   pp.totalDueInitial = pp.totalDue;

  //   // To prevent error rounding -- need to apply this calculation where Agent Retention = Premium - Total Due (Remittance)
  //   pp.agentRetention = pp.actualFee - pp.totalDue;
  //   pp.agentRetentionInitial = pp.agentRetention;
  // };

  // const applyCoInsurance = (
  //   coInsurancePct: number,
  //   setIsPricingSectionUpdatedStatus: () => void
  // ) => {
  //   const pricingProducts: PricingProduct[] = getValues("pricingProducts");
  //   pricingProducts?.forEach((pp, index) => {
  //     if (
  //       coInsurancePct >= 0 &&
  //       coInsurancePct <= 100 &&
  //       ((pp.productType === ProductType.Jacket &&
  //         (pp.formType === JacketFormType.Loan ||
  //           pp.formType === JacketFormType.Owners)) ||
  //         pp.productType === ProductType.StandaloneEndorsement)
  //     ) {
  //       // Calculate coInsurance for Product
  //       calculateCoInsurance(pp, coInsurancePct);

  //       // Calculate CoInsurance for ProductItems
  //       pp.productItems?.forEach((pi) => {
  //         calculateCoInsurance(pi, coInsurancePct);
  //       });

  //       setValue(`pricingProducts[${index}]`, pp);
  //     }
  //   });

  //   if (setIsPricingSectionUpdatedStatus) setIsPricingSectionUpdatedStatus();
  // };

  const isFileLockedWithUpdateablePricingItem = (
    pricingItem: PricingProductDetail,
    isFileLocked: boolean,
    isReadOnly: boolean = false
  ): boolean => {    
    if(isReadOnly) return false;
    const lockedUpdatable =
      isPricingItemUpdateable(pricingItem) && isFileLocked;

    return lockedUpdatable;
  };

  const hasUpdateblePricingProducts = (pricingProducts: PricingProduct[]) => {
    const isPricingProductUpdatable = pricingProducts.some(
      (pp) =>
        //pp.isReadyToBeBilled === 1 &&
        //pp.isBilled === 0 &&
        isPricingItemUpdateable(pp) &&
        pp.pricingType === PricingType.Product &&
        pp.productType !== ProductType.StandaloneEndorsement
    );

    let isPricingProductItemUpdatable = false;
    for (let i = 0; i < pricingProducts.length; i++) {
      const isPpiUpdateable = pricingProducts[i].productItems?.some((ppi) =>
        // ppi.isReadyToBeBilled === 1 && ppi.isBilled === 0
        isPricingItemUpdateable(ppi)
      );
      if (isPpiUpdateable) {
        isPricingProductItemUpdatable = true;
        break;
      }
    }

    return isPricingProductUpdatable || isPricingProductItemUpdatable;
  };

  const isFileLockedWithoutUpdateablePricingProducts = (
    isFileLocked: boolean,
    pricingProducts: PricingProduct[],
    isReadOnly: boolean = false
  ) => {    
    if(isReadOnly) return false;
    return isFileLocked && !hasUpdateblePricingProducts(pricingProducts);
  };

  const isFileLockedWithUpdateblePricingProducts = (
    isFileLocked: boolean,
    pricingProducts: PricingProduct[],
    isReadOnly: boolean = false
  ) => {    
    if(isReadOnly) return false;
    return isFileLocked && hasUpdateblePricingProducts(pricingProducts);
  };

  const setPricingValidationRequiredFlags = () => {
    // validation for CoInsurnacePct
    setValue("pricing.isValidationRequired", true);

    const pricingProducts: PricingProduct[] = getValues("pricingProducts");
    pricingProducts?.forEach((pricingProduct, index) => {
      setValue(`pricingProducts.${index}.isValidationRequired`, true);

      pricingProduct.productItems?.forEach((pi, piIndex) => {
        setValue(
          `pricingProducts.${index}.productItems.${piIndex}.isValidationRequired`,
          true
        );
      });
    });
  };

  const showCalculateButton = (integratedPricing: boolean, isFileLocked: boolean, isReadOnly: boolean = false) => {
    if(isReadOnly) return false;
    if(!integratedPricing) return false;
    return showReportButton(isFileLocked);
  }

  const showReportButton = (isFileLocked: boolean, isReadOnly: boolean = false) => {
    if(isReadOnly) return true;
    if (!isFileLocked) return true;
    const pricingProducts = getValues("pricingProducts");
    let show = pricingProducts.some((p: any) =>
      isFileLockedWithUpdateablePricingItem(p, isFileLocked)
    );
    if (show) return true;
    for (const product of pricingProducts) {
      if (product.productItems)
        show = product.productItems.some((item: any) =>
          isFileLockedWithUpdateablePricingItem(item, isFileLocked)
        );

      if (show) return true;
    }
    return false;
  };

  const isCoInsuranceEnabled = (hasIssuedJackets: boolean, isOverride: boolean, overrideReason: string) => {
    return  hasIssuedJackets && isOverride && overrideReason === OverrideReasonType.CoInsurance;
  }

  const getSelectedPaymentSheetItems = (selectedPaymentSheetOption:PAYMENT_SHEET_OPTION_TYPE) => {
    const fileId = getValues("id");

    // For default and Product payment sheet option
    let paymentSheetItems: PendingPaymentItem[] = [
      ({
        FileID: fileId,
      })   
    ];
    if (selectedPaymentSheetOption === PaymentSheetOptionType.File) {
      // Entired File
      const filePricingDetails: FilePricingDetail[] = getValues("filePricingDetails");
      if (filePricingDetails?.length > 0) {
        const uniqFilePricingDetails = uniqBy(
          filePricingDetails.filter((pd) => pd.isBilled === 1),
          (pd) => pd.paymentSheetID
        );
        if (uniqFilePricingDetails?.length > 0) {
          paymentSheetItems = uniqFilePricingDetails.map((pd) => {
            const paymentSheetItem: PendingPaymentItem = {
              FileID: pd.fileID,
              PaymentSheetID: pd.paymentSheetID,
            };
            return paymentSheetItem;
          });
        }
      }
    }    

    return paymentSheetItems;
  }

  const getProductIndex = (
    pricingProduct: PricingProduct,
    pricingIndex: number
  ) => {
    
    if(pricingProduct.pricingType !== "Product") return pricingIndex;

    if(pricingProduct.productType === ProductType.Jacket) {
      const jackets = getValues('jackets') as Jacket[];
      if(jackets && jackets.length > 0) {
        const jacketIndex = jackets.findIndex((j) => j.orderID === pricingProduct.orderId && j.filePricingDetailID === pricingProduct.filePricingDetailId);        
        return jacketIndex ?? pricingIndex;
      }      
    }
    if(pricingProduct.productType === ProductType.StandaloneEndorsement) {
      const saProducts = getValues('standaloneEndorsements') as StandaloneEndorsement[];
      if(saProducts && saProducts.length > 0) {
        const saIndex = saProducts.findIndex((a) => a.orderID === pricingProduct.orderId && a.filePricingDetailID === pricingProduct.filePricingDetailId);          
        return saIndex ?? pricingIndex;      
      }      
    }
    return pricingIndex;
  }

  return {
    showPricing,
    copyPricingProducts,
    copyUpdatedInitialPricingProducts,
    copyUpdatedInitialFilePricingDetails,
    copyPricingDetails,
    copyEndorsementsPricingDetails,
    calculateTaxes,
    // applyCoInsurance,
    isFileLockedWithUpdateablePricingItem,
    isFileLockedWithoutUpdateablePricingProducts,
    isFileLockedWithUpdateblePricingProducts,
    setPricingValidationRequiredFlags,
    showCalculateButton,
    showReportButton,
    isCoInsuranceEnabled,
    getSelectedPaymentSheetItems,
    getProductIndex,
  };
};

export default usePricing;
