import React from "react";

const FileUploadIcon = () => {
  return (
    <>
      <svg
        width="59"
        height="67"
        viewBox="0 0 59 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.25"
          d="M56.0524 18.3909L38.7449 2.49217C37.4191 1.27426 35.5916 0.583008 33.6924 0.583008H7.99992C4.05825 0.583008 0.869086 3.54551 0.869086 7.16634L0.833252 59.833C0.833252 63.4538 4.02242 66.4163 7.96408 66.4163H50.9999C54.9416 66.4163 58.1666 63.4538 58.1666 59.833V23.0651C58.1666 21.3205 57.4141 19.6418 56.0524 18.3909ZM39.5333 43.3747H33.0833V53.2497C33.0833 55.0601 31.4708 56.5413 29.4999 56.5413C27.5291 56.5413 25.9166 55.0601 25.9166 53.2497V43.3747H19.5024C17.8899 43.3747 17.1016 41.5972 18.2483 40.5768L28.2816 31.393C28.9983 30.7676 30.1091 30.7676 30.8258 31.393L40.8232 40.5768C41.8983 41.5972 41.1099 43.3747 39.5333 43.3747ZM36.6666 23.6247C34.6958 23.6247 33.0833 22.1434 33.0833 20.333V5.52051L52.7916 23.6247H36.6666Z"
          fill="url(#paint0_linear_3023_28472)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3023_28472"
            x1="29.4999"
            y1="0.583008"
            x2="29.4999"
            y2="66.4163"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0075AA" />
            <stop offset="1" stopColor="#004D82" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}

export default FileUploadIcon;