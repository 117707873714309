import SelectField from "controls/global/select-field";
import ProductOption from "entities/UIModel/company/ProductOption";
import React, {
  useEffect,
} from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { PartyRoleType } from "utils/data/enum";

type Props = {
  name: string;
  defaultValue?: any;
  coveredParties: ProductOption[];
  productIndex: number;
  productEntity: any;
  disabled?: boolean;
  isRevising?: boolean;
  onChange?: any;
};

const getOptions = (coveredParties: ProductOption[]) => {
  const retval =
    coveredParties && coveredParties.length > 0
      ? coveredParties.map((coveredParties: ProductOption) => ({
        value: coveredParties.formType,
        text: coveredParties.formType,
      }))
      : [];
  return retval;
};

const CoveredPartyField = ({
  name,
  defaultValue,
  coveredParties,
  productIndex,
  productEntity,
  disabled,
  isRevising,
  onChange,
}: Props) => {
  const { setValue, getValues, clearErrors, nameString } = useFormWrapper();

  const handleOnChange = (event: React.ChangeEvent<{}>, value: any) => {
    setValue(`${productEntity}.${productIndex}.isValidAddresseeSelected`, true);
    setValue(`${productEntity}.${productIndex}.isValidationRequired`, false);

    let coveredPartySelected = value;

    if (
      ((coveredPartySelected.toUpperCase() === PartyRoleType.BuyerBorrower &&
        getValues("buyerBorrowerParties")?.length > 0) ||
      (coveredPartySelected.toUpperCase() === PartyRoleType.Seller &&
        getValues("sellerParties")?.length > 0) ||
      (coveredPartySelected.toUpperCase() === PartyRoleType.Lender &&
        getValues("lenderParties")?.length > 0)) && !isRevising
    ) {
      // Reset Product fields
      setValue(`${productEntity}.${productIndex}.addressOne`, "");
      setValue(`${productEntity}.${productIndex}.addressTwo`, "");
      setValue(`${productEntity}.${productIndex}.city`, "");
      setValue(`${productEntity}.${productIndex}.state`, {
        code: "",
        abbr: "",
        required: false,
      });
      setValue(`${productEntity}.${productIndex}.zipCode`, "");
      setValue(`${productEntity}.${productIndex}.loanNumber`, "");
      setValue(`${productEntity}.${productIndex}.successorLanguage`, "");
      setValue(`${productEntity}.${productIndex}.attentionTo`, "");
    }

    setValue(name, coveredPartySelected);
    setValue(`${productEntity}.${productIndex}.form`, "");
    clearErrors(nameString(`${productEntity}.${productIndex}`));

    onChange && onChange(coveredPartySelected);

    if (coveredPartySelected.toUpperCase() === PartyRoleType.Lender) {
      setValue(`${productEntity}.${productIndex}.isForeignAddress`, 0);
    }
  };

  useEffect(() => {
    const currentCoveredParty = getValues(name);
    const existingParty =
      coveredParties.filter((p: ProductOption) => p.formType === currentCoveredParty)
        .length > 0;

    if (currentCoveredParty && existingParty) return;

    const selectedValue = coveredParties.find(
      (party: ProductOption) => party.default === 1
    );

    if (selectedValue) setValue(name, selectedValue.formType);
  }, [coveredParties, getValues, name, setValue]);

  return (
    <>
      <SelectField
        label="Covered Party"
        className="covered-party"
        {...{ name, defaultValue, disabled }}
        options={getOptions(coveredParties)}
        onChange={handleOnChange}
      />
    </>
  );
};

export default CoveredPartyField;
