import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { State } from "entities/UIModel/State";

const getStateByText = (states: SelectFieldOption[], abbr: string): State => {
  const state = states.filter((s) => s.text === abbr);
  if (!state || state.length === 0) return { code: "", abbr: "" };

  return { code: state[0].value, abbr: state[0].text };
};

export { getStateByText };
