import { ActionResultStatus, DateTypeCode, DownloadFileType } from "utils/data/enum";
import { EditImageNameBody } from "entities/UIModel/ImageArchiveUI";
import { ImageArchive } from "entities/ApiModel/ImageArchive";
import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";
import { StoreActionApi } from "react-sweet-state";

import { axiosSecuredInstance } from "configurations/axiosConfig";
import { createHook } from "react-sweet-state";
import { createStore } from "react-sweet-state";
import { dateTimeAsFilename, downloadByBase64, formatDateRegEx, getBase64DataType } from "utils/shared";
import {
  LocalStorageKeys,
} from "utils/data/enum";
import {
  ImageArchiveSearchCriteria
} from "entities/ApiModel/ImageArchiveSearchCriteria";
import {
  ActionResult,
} from "entities/UIModel";
import {
  Column,
  IColumn,
} from "entities/ApiModel/IColumn";
import { ImageArchiveExportCriteria } from "entities/ApiModel/ImageArchiveExportCriteria";
type State = {
  isLoading: boolean;
  documents: ImageArchiveUI[];
  minDate: Date | null;
  maxDate: Date | null;
  showToast: boolean;
  isEditMode: boolean;
  isAdvanceSearch: boolean;
  fileDocuments: ImageArchiveUI[];
  isFilePolicyUploadEditMode: boolean;
  isFilePolicyUploadShowToast: boolean;
  error?: any;
};

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;
const setError =
  (error: any) =>
    ({ setState }: StoreApi) => {
      error && setState({ error });
    };

const setFilesRecordList = (filesSearchResponse: ImageArchive[]) => (): ImageArchiveUI[] => {
  if (!filesSearchResponse || filesSearchResponse.length === 0) {
    return [];
  }

  return filesSearchResponse.map((file: ImageArchive) => {
    return {
      rowId: file.IdentityKey || 0,
      callingUser: file.CallingUser || "",
      clientFileID: file.ClientFileID || "",
      companyID: file.CompanyID || "",
      companyLegacyID: file.CompanyLegacyID || "",
      companyName: file.CompanyName || "",
      documentDisplayFileName: file.DocumentDisplayFileName || "",
      documentFileName: file.DocumentDisplayFileName || "",
      documentFileTypeCode: file.DocumentFileTypeCode || "",
      documentOriginalFileName: file.DocumentOriginalFileName || "",
      documentSize: file.DocumentSize || "0",
      documentSourceTypeCode: file.DocumentSourceTypeCode || "",
      documentStatusTypeCode: file.DocumentStatusTypeCode || "",
      documentTypeCode: file.DocumentTypeCode || "",
      documentUploadedByUserID: file.DocumentUploadedByUserID || "",
      documentUploadedByUserName: file.DocumentUploadedByUserName || "",
      documentUploadedDate: formatDateRegEx(file.DocumentUploadedDate) || "",
      fileID: file.FileID || 0,
      documentIsProcessed: file.IsProcessed || 0,
      locationDisplayName: file.LocationDisplayName || "",
      locationLegacyID: file.LocationLegacyID || "",
      locationStateAbbr: file.LocationStateAbbr || "",
      locationStateCode: file.LocationStateCode || "",
      locationUniqueID: file.LocationUniqueID || "",
      propertyStateCode: file.LocationUniqueID || "",
      userFilter: 0,
      rowsPerPage: file.RowsPerPage || 0,
      currentRow: file.CurrentRow || 0,
      currentPage: file.CurrentPage || 0,
      totalPages: file.TotalPages || 0,
      totalRows: file.TotalRows || 0,
      dateTypeCode: DateTypeCode.UploadDate
    };
  });
};

const setColumns =
  (columns: any[]) =>
    () => {
      const gridColumns: IColumn[] = columns.filter((c) => Column.isValid(c));
      const gridHiddenColumns = gridColumns
        .filter((c) => c.hidden)
        .map((m) => m.field as keyof ImageArchiveUI);
      localStorage.setItem(LocalStorageKeys.COLUMNS_IMAGE_ARCHIVE, JSON.stringify(gridColumns));
      localStorage.setItem(LocalStorageKeys.COLUMNS_IMAGE_ARCHIVE_HIDDEN, JSON.stringify(gridHiddenColumns));
      return { gridColumns, gridHiddenColumns };
    };
const actions = {
  getSearchDocuments:
    (
      payload: ImageArchiveSearchCriteria // ImageArchiveUI) =>
    ) =>
    async ({ dispatch, setState }: StoreApi) => {
      try {
        setState({ isLoading: true });

        const { data } = await axiosSecuredInstance.post(
          "/Documents/Search",
          payload
        );
        const mappedData = dispatch(setFilesRecordList(data));
        if ((payload.fileId || 0) > 0) {
          setState({
            fileDocuments: mappedData,
          });
        }
        return mappedData; // dispatch(setFilesRecordList(data));
      } catch (error: any) {
        console.log(error);
      }
    },
  deleteDocumentSupplemental:
    (documentSupplmentalID: number) =>
    async ({ dispatch, setState }: StoreApi): Promise<ActionResult> => {
      const actionResult: ActionResult = {};
      try {
        setState({ isLoading: true });
        const { data, headers } = await axiosSecuredInstance.delete(
          `/Documents/DeleteDocumentSupplemental?documentSupplementalID=${documentSupplmentalID}`
        );
        setState({ isLoading: false });
        actionResult.status = ActionResultStatus.Success;
        actionResult.apiResponse = data;
        actionResult.headers = headers;
        return actionResult;
      } catch (error: any) {
        // console.log(error);
        actionResult.status = ActionResultStatus.Failed;
        actionResult.error = error;
        return actionResult;
      }
    },
  saveDocumentSupplemental:
    (payload: EditImageNameBody) =>
    async ({ dispatch, setState }: StoreApi): Promise<ActionResult> => {
      try {
        const { data, headers } = await axiosSecuredInstance.post(
          `/Documents/SaveDocumentSupplemental`,
          payload
        );
        return {
          status: ActionResultStatus.Success,
          headers: headers,
          apiResponse: data,
        };
      } catch (error: any) {
        dispatch(setError(error));
        return {
          status: ActionResultStatus.Failed,
          error: error,
        };
      }
    },
  exportFiles: (criteria: ImageArchiveExportCriteria) => async () => {
    const getExportFilename = (): string => {
      let filename = `Image Archive ${dateTimeAsFilename()}.${
        DownloadFileType.Xlsx
      }`;
      return filename;
    };
    try {
      const { data } = await axiosSecuredInstance.post<string>(
        "/Documents/ExportImageArchive",
        criteria
      );
      const filename = getExportFilename();
      downloadByBase64(
        `data:${getBase64DataType(DownloadFileType.Xlsx)};base64,${data}`,
        filename
      );
      return {};
    } catch (error: any) {
      console.error(error);
    }
  },
  setMinDate:
    (minDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ minDate });
    },
  setMaxDate:
    (maxDate: Date | null) =>
    ({ setState }: StoreApi) => {
      setState({ maxDate });
    },
  setShowToast:
    (showToast: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ showToast });
    },
  setEditMode:
    (isEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isEditMode });
    },
  setFileShowToast:
    (isFilePolicyUploadShowToast: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isFilePolicyUploadShowToast });
    },
  setFileEditMode:
    (isFilePolicyUploadEditMode: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isFilePolicyUploadEditMode });
    },
  setAdvanceSearch:
    (isAdvanceSearch: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isAdvanceSearch });
    },
  getInitialColumnsDefintion: (hidden: boolean) => () => {
    const key = hidden
      ? LocalStorageKeys.COLUMNS_IMAGE_ARCHIVE_HIDDEN
      : LocalStorageKeys.COLUMNS_IMAGE_ARCHIVE;
    const existingColumns = localStorage.getItem(key) || "[]";
    return JSON.parse(existingColumns);
  },
  getColumnsDefinition:
    () =>
    async ({ dispatch }: StoreApi) => {
      const { data } = await axiosSecuredInstance.get(
        "/UISettings/grids/columnSettings/ImageArchive"
      );
      return dispatch(setColumns(data));
    },
  setColumnDefinition:
    (
      fields: (keyof ImageArchiveUI)[],
      propName: keyof Omit<IColumn, "field" | "name">,
      value: string | number | boolean | undefined
    ) =>
    async ({ dispatch }: StoreApi) => {
      const data: IColumn[] = fields.map((field) => ({
        field,
        name: "", //NOT REQUIRED,
        [propName]: value,
      }));

      try {
        const response = await axiosSecuredInstance.post(
          "/UISettings/grids/columnSettings/ImageArchive",
          data
        );
        if (response.data) {
          dispatch(setColumns(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
};

const initialState: State = {
  isLoading: false,
  documents: [],
  minDate: null,
  maxDate: null,
  showToast: false,
  isEditMode: false,
  isAdvanceSearch: false,
  fileDocuments: [],
  isFilePolicyUploadEditMode: false,
  isFilePolicyUploadShowToast: false,
  error: null,
};

const documentStore = createStore<State, Actions>({
  initialState,
  actions,
  name: "documentStore",
});

const getFileDocuments = (state: State) => {
  return state.fileDocuments;
}

const getFilePolicyUploadStates = (state: State) => ({
  isFilePolicyUploadEditMode: state.isFilePolicyUploadEditMode,
  isFilePolicyUploadShowToast: state.isFilePolicyUploadShowToast,
})

const useDocumentStore = createHook(documentStore);

const useFileDocuments = createHook(documentStore, {
  selector: getFileDocuments,
})

const useFilePolicyUploadStates = createHook(documentStore, {
  selector: getFilePolicyUploadStates,
})

export default useDocumentStore;

export {
  useFileDocuments,
  useFilePolicyUploadStates,
}
