import { 
  FileUploadStatus, 
  PdfDocument 
} from "entities/UIModel";
import { 
  ErrorCode, 
  FileError 
} from "react-dropzone";

const mapFileUploaResponse = (data: any) => {
  let fileUploadStatuses: FileUploadStatus[] = [];
  fileUploadStatuses = data?.map((d: any) => {
    const fileUploadStatus: FileUploadStatus = {
      documentId: d.DocumentId,
      documentIndex: d.DocumentIndex,
      documentOriginalFileName: d.DocumentOriginalFileName,
      errorMessage: d.ErrorMessage,
      isUploadSuccess: d.IsUploadSuccess,
    };
    return fileUploadStatus;
  });

  return fileUploadStatuses;
};

const getFormattedErrorMessage = (errors: FileError[]) => {
  let errorMessage = "Unexpected error";
  errors?.forEach((error) => {
    switch(error.code) {
      case ErrorCode.FileInvalidType:
        errorMessage = "This format is unsupported";
        break;
      case ErrorCode.FileTooLarge:
        errorMessage = error.message
        break;
      case ErrorCode.FileTooSmall:
        errorMessage = error.message
        break;
    }
  })

  return errorMessage;
}

const getPdfDocument = (pdfUrl: string, latestDocumentId: number, documentDisplayFileName: string) => {
  const pdfFilename = `${documentDisplayFileName}`;

  let pdfDocument: PdfDocument = {
    fileId: 0,
    productType: "",
    orderId:  0,
    documentId: latestDocumentId,
    pdfUrl: pdfUrl,
    fileName: pdfFilename,
    showHidden: 0,
  };

  return pdfDocument;
};

export {
  mapFileUploaResponse,
  getFormattedErrorMessage,
  getPdfDocument
}