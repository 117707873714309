import {
  AALProduct,
  CPLProduct,
  FilePricing,
  FilePricingDetail,
  JacketProduct,
  Party,
  Property,
  SCFile,
  StandaloneEndorsementProduct,
} from "entities/ApiModel";

const getErrorMessage = (result: any) =>
  result.response.data && result.response.data.errorMessage
    ? result.response.data.errorMessage
    : result.response.statusText;

const checkFormDirty = (file: SCFile): boolean => {
  const fileDirty = isFileDirty(file);
  const propertiesDirty = isArraySectionDirty(file.Properties);
  const partiesDirty = isArraySectionDirty(file.Parties);
  const cplProductsDirty = isArraySectionDirty(file.CPLProducts);
  const aalProductsDirty = isArraySectionDirty(file.AALProducts);
  const jacketsDirty = isJacketDirty(file.JacketProducts);
  const standaloneEndorsementsDirty = isStandaloneEndorsementDirty(
    file.StandaloneEndorsementProducts
  );
  const filePricingDetailsDirty = isArraySectionDirty(file.FilePricingDetails);
  const filePricingDirty = isFilePricingDirty(file.FilePricing);

  const retval =
    fileDirty ||
    partiesDirty ||
    propertiesDirty ||
    cplProductsDirty ||
    aalProductsDirty ||
    jacketsDirty ||
    standaloneEndorsementsDirty ||
    filePricingDetailsDirty ||
    filePricingDirty;

    if(retval){
      console.log(`Dirty Sections:`);
      console.log(`file: ${fileDirty}`);
      console.log(`parties: ${partiesDirty}`);
      console.log(`properties: ${propertiesDirty}`);
      console.log(`cplProductsDirty: ${cplProductsDirty}`);
      console.log(`aalProductsDirty: ${aalProductsDirty}`);
      console.log(`jacketsDirty: ${jacketsDirty}`);
      console.log(`standaloneEndorsementsDirty: ${standaloneEndorsementsDirty}`);
      console.log(`filePricingDetailsDirty: ${filePricingDetailsDirty}`);
      console.log(`filePricingDirty: ${filePricingDirty}`);
    }

    return retval;
};

const isFileDirty = (file: SCFile) =>
  Boolean(file?.IsDirty) || Boolean(file?.IsNew && file.ClientFileID);

const isFilePricingDirty = (filePricing: any) => Boolean(filePricing?.IsDirty);

const isArraySectionDirty = (
  values:
    | Property[]
    | Party[]
    | CPLProduct[]
    | AALProduct[]
    | FilePricingDetail[]
    | undefined
) => (values ? values.some(isNewOrDirty) : false);

const isJacketDirty = (values: JacketProduct[] | undefined) =>
  values
    ? values.some(
        (product) =>
          isNewOrDirty(product) ||
          product.Endorsements?.some(isNewOrDirty) ||
          product.JacketFormDetails?.some(isNewOrDirty) ||
          product.JacketSignatures?.some(isNewOrDirty)
      )
    : false;

const isStandaloneEndorsementDirty = (
  values: StandaloneEndorsementProduct[] | undefined
) =>
  values
    ? values.some(
        (standalone) =>
          isNewOrDirty(standalone) ||
          standalone.Endorsements?.some(isNewOrDirty)
      )
    : false;

const isNewOrDirty = (
  product:
    | Property
    | Party
    | CPLProduct
    | AALProduct
    | JacketProduct
    | StandaloneEndorsementProduct
    | FilePricing
) => Boolean(product.IsDirty) || Boolean(product.IsNew);

export { getErrorMessage, checkFormDirty };
