import {
  styled,
  TableCell,
  Typography,
} from "@mui/material";
import { Endorsement } from "entities/UIModel";
import React from "react";
import { EndorsementStatusType } from "utils/data/enum";
import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { colors } from "theme/defaultStyle";
import theme from "theme/default";

type Props = {
  endorsement: Endorsement;
  fullName: string;
  cellCss: string;
  disabled: boolean;
  issuedMode: boolean;
  options: SelectFieldOption[];
  checked: boolean;
  handleEndorsementChange: (
    _: React.ChangeEvent<{}>,
    selectedOptionValue: SelectFieldOption
  ) => void;
  handleEndorsementOnBlur: (
    event: React.ChangeEvent<{}>
  ) => void;
};

const StyleCellText = styled(Typography)({
  fontSize: "18px",
  padding: "10px 0px 5px 10px",
  color: colors.grey07,
  "&.disabled": {
    color: colors.grey06,
  },
});

const StyledTableCell = styled(TableCell)({
  minWidth: 340,
  "& .MuiInputBase-root.MuiInput-root.MuiAutocomplete-inputRoot": {
    border: "none",
  },
  "& .MuiInputBase-root.MuiInput-root.MuiAutocomplete-inputRoot.Mui-focused": {
    border: `1px solid ${theme.palette.primary.main}`,
  },
});

export default function EndorsementNameCell({
  endorsement: {
    isSFE,
    endorsementName,
    endorsementID,
    endorsementStatusTypeCode,
  },
  fullName,
  cellCss,
  disabled,
  issuedMode,
  options,
  handleEndorsementChange,
  handleEndorsementOnBlur,
  checked =false,
}: Props) {

  const isCellDisabled: boolean =
    disabled ||
    Boolean(
      endorsementID &&
        endorsementStatusTypeCode !== EndorsementStatusType.Pending &&
        issuedMode
    )
    const isCheckboxDisabled: boolean =
    disabled ||
    Boolean(
      endorsementID &&
        endorsementStatusTypeCode !== EndorsementStatusType.Pending &&
        issuedMode
    ) || !checked;

  if (isSFE) {
    return (
      <TableCell classes={{ sizeSmall: cellCss }} style={{ minWidth: 340 }}>
        <StyleCellText className={isCheckboxDisabled ? "disabled" : ""}>
          {endorsementName}...
        </StyleCellText>
      </TableCell>
    );
  }
  
  return (
    <StyledTableCell classes={{ sizeSmall: cellCss }}>
      <>
        <ComboBoxField
          label=""
          size="small"
          noBorder
          placeholder="Select or Type Endorsement"
          name={fullName}
          defaultValue={endorsementName}
          options={options}
          onChange={handleEndorsementChange}
          onBlur={handleEndorsementOnBlur}
          disabled={isCellDisabled}
          controlTooltipOnMouseEvents = {!(isCellDisabled)} // Disregard tooltip in enabled mode. It is interfering with autocomplete popper
          freeSolo={true}
        />
      </>
    </StyledTableCell>
  );
}
