import { DialogActions } from "@mui/material";
import LinearProgressWithLabel from "controls/global/linear-progress-with-label";
import React, {
  useEffect,
} from "react";
import {
  useProcessStatusTracking,
  useProcessStatusTrackingState,
} from "utils/context/ProcessStatusContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import {
  StewartDialog,
  StewartDialogContent,
  StewartDialogTitle,
} from "../stewart-dialog/StewartDialog";

const CONFIG = {
  TIMEOUT_SEC: 30, //Counter to reset Interval for polling
  MAX_COMPLETED: 100,
};

const ProgressbarDialog = () => {
  const [{ open, requestId, title }, { closeProgresbar }] = useProgressbar();
  const [{ pctComplete, maxWait }] = useProcessStatusTrackingState();
  const [, { getProcessStatus, reset }] = useProcessStatusTracking();

  useEffect(() => {
    if (!open || !requestId) return;

    const interval = setInterval(() => {
      getProcessStatus(requestId);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, requestId]);

  useEffect(() => {
    if (
      open &&
      (pctComplete === CONFIG.MAX_COMPLETED || maxWait >= CONFIG.TIMEOUT_SEC)
    ) {
      setTimeout(() => {
        closeProgresbar();
        reset();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWait, pctComplete]);

  if (!open) return null;

  return (
    <StewartDialog open={open} maxWidth="md">
      <StewartDialogTitle type={"INFO"}>
        {title}
      </StewartDialogTitle>
      <StewartDialogContent>
        <LinearProgressWithLabel value={pctComplete} />
      </StewartDialogContent>
      <DialogActions />
    </StewartDialog>
  );
};

export default ProgressbarDialog;
