import {
  Box,
  styled,
} from "@mui/material";
import React from "react";
import {
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import AddLink from "controls/global/add-link";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import ErrorDialog from "controls/global/dialogs/error-dialog";
import PdfViewer from "controls/global/pdf-viewer";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useFiles } from "utils/context/FilesContext";
import { usePricingActions } from "utils/context/PricingContext";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import useCPLErrorHandler from "utils/custom-hooks/useCPLErrorHandler";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useEffectiveDateValidation from "utils/custom-hooks/useEffectiveDateValidation";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePDFDocumentsCPL from "utils/custom-hooks/usePDFDocumentsCPL";
import {
  MapActionType,
  ProductAction,
  ProductType,
  ProductTypeName,
} from "utils/data/enum";
import { getDefaultCplObject } from "utils/data/products";
import { formatDate } from "utils/shared";
import LetterProduct from "pages/file/components/product-section/common/LetterProduct";
import VoidLetterForm from "pages/file/components/product-section/common/VoidLetterForm";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import * as UIModel from "entities/UIModel";
import CompanyStateLocation from "entities/UIModel/company/CompanyStateLocation";
import { gaps } from "theme/defaultStyle";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";
import useFocus from "utils/custom-hooks/useFocus";
import ConfirmationDialogV3 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV3";

const StyledGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const CPLProductCollection = () => {
  const [openVoidCPLDialog, setOpenVoidCPLDialog] = React.useState<boolean>(false);
  const [voidCplButtonDisabled, setVoidCplButtonDisabled] = React.useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = React.useState<UIModel.CPL>(getDefaultCplObject());
  const [currentDefaultVoidReason, setCurrentDefaultVoidReason] = React.useState<string>("");
  const [currentProductIndex, setCurrentProductIndex] = React.useState<number>(0);
  const [submitVoidButtonDisabled, setSubmitVoidButtonDisabled] = React.useState<boolean>(false);
  const [issueCplButtonDisabled, setIssueCplButtonDisabled] = React.useState<boolean>(false);
  const [submitReviseDisabled, setSubmitReviseDisabled] = React.useState<boolean>(false);
  const [openProgressDialog, setOpenProgressDialog] = React.useState<boolean>(false);
  const [progressMessage, setProgressMessage] = React.useState<string>("");
  const [progressRequestId, setProgressRequestId] = React.useState<string>("");
  const [runProgress, setRunProgress] = React.useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const currentActionResult = React.useRef<UIModel.ActionResult>({});
  const currentProductIntegrationKey = React.useRef<string | undefined>("");
  const currentProductOriginalOrderID = React.useRef<number | undefined>(0);
  const currentProductAction = React.useRef<string | undefined>("");
  const indexToDeleteRef = React.useRef<number | undefined>();
  const companyStateLocationsRef = React.useRef<CompanyStateLocation[]>([]);

  const { setFocusInputElement } = useFocus();
  const [{ companyStateLocations }] = useCompanyLocation();
  const [, { setBypassDefaultLoader }] = useProcessStatusTracking();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] = useAutomaticProgressDialogActions();
  const [, { setPricingNotificationUpdated }] = usePricingActions();
  const [{ initialValues }, { voidProduct, deleteOrder, revise, issueCpl },] = useFiles();
  const { getValues, trigger, setValue, clearErrors } = useFormWrapper();
  const { resetValidationRequiredFlags, setValidLocations, reloadFile } = useCreateFile();
  const { getPDFDocuments, openPdfViewer, setOpenPdfViewer, pdfDocuments } = usePDFDocumentsCPL();
  const { errorMessage, correlationId, setErrorMessage, setCorrelationId, openErrorDialog, setOpenErrorDialog, checkError, handleError, clearError } = useCPLErrorHandler();
  const { validateEffectiveDate } = useEffectiveDateValidation();
  const isFileDisabled = useFieldDisabler("AddCPLButton");
  const [fileNameNumber, hasIssuedProducts, isPopulatingExistingFile] = useWatch({
    name: ["fileNameNumber", "hasIssuedProducts", "isPopulatingExistingFile",],
  });
  const { fields, append, remove } = useFieldArray({ name: "cpls", keyName: "fieldId" });
  const cpls: Array<UIModel.CPL> = getValues("cpls");

  const handleIssueCpl = async (
    product: UIModel.CPL,
    integrationKey: string
  ) => {
    // Store current action parameters
    currentProductIntegrationKey.current = integrationKey;
    currentProductAction.current = ProductAction.Issue;

    // Clear previous action error
    clearError();
    currentActionResult.current = {};

    // Validate CPL
    product.isDefault = false;

    // clear previous validaiton errors and reset validation required flags
    clearErrors();
    resetValidationRequiredFlags();

    const cpls: Array<UIModel.CPL> = getValues("cpls");
    for (const [index, cpl] of cpls.entries()) {
      if (cpl.integrationKey === product.integrationKey) {
        setValue(`cpls.${index}.isValidationRequired`, true);
        //-- Validate againts contract date, this is needed due to the user may click on Issue button without losing the focus from EffectiveDate field
        await validateEffectiveDate("cpls", index, cpl.effectiveDate, false);

        if (cpl.pricingDetail) {
          setValue(
            `cpls.${index}.pricingDetail.isValidatingLocationRequired`,
            true
          );
        }
        if (!cpl.isForeignAddress) {
          if (cpl.state?.code || cpl.state?.abbr) {
            setValue(`cpls.${index}.state.required`, false);
          } else {
            setValue(`cpls.${index}.state`, {
              code: "",
              abbr: "",
              required: true,
            });
          }
        }
      } else {
        // cpl.isValidAddresseeSelected = false;
        setValue(`cpls.${index}.isValidationRequired`, false);
        if (cpl.pricingDetail) {
          setValue(
            `cpls.${index}.pricingDetail.isValidatingLocationRequired`,
            false
          );
        }
      }
    }

    setValidLocations(companyStateLocationsRef.current);
    const currentValues: UIModel.SCFile = getValues();
    const isValid = await runValidation({
      values: currentValues,
      trigger,
      setValue,
      productType: ProductType.Cpl,
    });

    if (!isValid) return;

    // Disaable Issue button
    setIssueCplButtonDisabled(true);

    // Issue CPL
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      currentValues,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Issue,
      ProductType.Cpl,
      [product.integrationKey]
    );

    // -- show progress dialog
    let progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setProgressMessage("Please wait while issuing your CPL...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    // -- calling issue cpl
    const submit = async () => {
      setPricingNotificationUpdated(false);
      currentActionResult.current = await issueCpl(
        apiSCFile,
        progressRequestId
      );
      setPricingNotificationUpdated(true);
    };

    submit();
  };

  const handleReviseCpl = async (
    integrationKey: string,
    originalOrderID: number
  ) => {
    currentProductIntegrationKey.current = integrationKey;
    currentProductOriginalOrderID.current = originalOrderID;
    currentProductAction.current = ProductAction.Revise;
    currentActionResult.current = {};

    clearError();

    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues() as UIModel.SCFile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Revise,
      ProductType.Cpl,
      [integrationKey]
    );

    const requestId = uuidv4();
    setProgressRequestId(requestId);
    setProgressMessage("Please wait while we revise your CPL...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    setPricingNotificationUpdated(false);
    currentActionResult.current = await revise(
      apiSCFile,
      ProductType.Cpl,
      requestId,
      integrationKey
    );
    setPricingNotificationUpdated(true);

    const hasError = checkError(
      currentActionResult.current,
      currentProductIntegrationKey.current,
      currentProductAction.current
    );

    if (hasError) {
      setSubmitReviseDisabled(false);
      return
    }

    await reloadFile();
    setSubmitReviseDisabled(true);
  };

  const handleVoidCpl = async (
    product: UIModel.CPL,
    productIndex: number,
    defaultVoidReason: string
  ) => {
    setVoidCplButtonDisabled(true);
    // set void form parameter here
    setCurrentProduct(product);
    setCurrentDefaultVoidReason(defaultVoidReason);
    setCurrentProductIndex(productIndex);
    setOpenVoidCPLDialog(true);
  };

  const handleVoidSubmit = async (
    product: UIModel.CPL,
    defaultVoidReason: string
  ) => {

    //Validate form
    const isFormValid = await runValidation({
      values: getValues(),
      trigger,
      setValue,
      productAction: MapActionType.ProductAction,
    });

    if (!isFormValid) {      
      resetVoidData();
      return;
    }    

    // Disaable submit button on VoidLetterForm
    setSubmitVoidButtonDisabled(true);

    // Store current action parameters
    currentProductIntegrationKey.current = product.integrationKey;
    currentProductAction.current = ProductAction.Void;

    // initialiaze
    clearError();
    currentActionResult.current = {};

    if (product.void && !product.void.reason)
      product.void.reason = defaultVoidReason;

    // Void CPL
    const apiSCFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues() as UIModel.SCFile,
      undefined,
      MapActionType.ProductAction,
      ProductAction.Void,
      ProductType.Cpl,
      [product.integrationKey]
    );

    let progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setProgressMessage("Please wait while voiding your CPL...");
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    // -- calling issue cpl api
    const submit = async () => {
      setPricingNotificationUpdated(false);
      currentActionResult.current = await voidProduct(
        apiSCFile,
        progressRequestId,
        ProductType.Cpl,
        product.integrationKey
      );
      setPricingNotificationUpdated(true);
    };

    submit();
  };

  const handleEffectiveDateChange = async (index: number, newDate: Date | null) => {
    if(isFileDisabled) return;
    // setTimeout is currently needed to handle timing issue. Investigate if this is necessary in react18.
    setTimeout(async () => {
      await validateEffectiveDate("cpls", index, newDate);
    });
  };

  const resetVoidData = () => {
    setValue(`cpls[${currentProductIndex}].void.reason`, "");
    setOpenVoidCPLDialog(false);
    setVoidCplButtonDisabled(false);
  }

  const handleVoidCancel = () => {
    resetVoidData();
  };

  const handleCloseVoidCpl = () => {
    setOpenVoidCPLDialog(false);
    setVoidCplButtonDisabled(false);
  };

  const handleOnYesErrorDialog = () => {
    setOpenErrorDialog(false);
    setErrorMessage("");
    setCorrelationId("");
    setValue(`cpls[${currentProductIndex}].void.reason`, "");
  };

  const handleOnCloseProgressDialog = async () => {
    setOpenProgressDialog(false);
    setRunProgress(false);
    setProgressRequestId("");

    // Enable back the Issue and Submit button
    setIssueCplButtonDisabled(false);
    setSubmitVoidButtonDisabled(false);

    // check for error
    const hasError = checkError(
      currentActionResult.current,
      currentProductIntegrationKey.current,
      currentProductAction.current
    );

    if (hasError) {
      handleError(currentActionResult.current, currentProductIntegrationKey.current, currentProductAction.current);
      return;
    }

    //open the document(s) for the issued Cpls
    if (
      currentProductAction.current === ProductAction.Issue ||
      currentProductAction.current === ProductAction.Revise
    ) {
      getPDFDocuments(
        currentActionResult.current,
        currentProductIntegrationKey.current,
        currentProductAction.current,
        currentProductOriginalOrderID.current
      );
    }
  };

  const addNewCpl = () => {
    const newCpl = getDefaultCplObject(true);
    newCpl.integrationKey = uuidv4();
    const newItemIndex = cpls.length;
    append(newCpl);
    setValue(`cpls.${newItemIndex}.addresseeNamesData`, null);

    // set 1st cpl as dirty
    setValue(`cpls.0.isDefault`, false);
    setValue(`cpls.0.isDirty`, true);

    // set focus to last cpl
    setFocusInputElement(`cpls.${newItemIndex}.effectiveDate`);
  };

  const openDeleteDialog = (deleteProdIndex: number) => {
    indexToDeleteRef.current = deleteProdIndex;
    setDialogOpen(true);
  };

  const deleteItem = async () => {
    if (indexToDeleteRef.current === undefined) return;
    const deleteItemIndex = indexToDeleteRef.current;

    if (cpls) {
      remove(deleteItemIndex);

      // Check if the product is saved already
      // If yes, delete from db
      if (cpls.length - 1 >= deleteItemIndex) {
        const deleteOrderId = cpls[deleteItemIndex].orderID;
        if ((deleteOrderId || 0) > 0) {
          setDialogOpen(false);
          openAutomaticProgressDialog();

          await deleteOrder(cpls[deleteItemIndex].orderID);
          await reloadFile();
          closeAutomaticProgressDialog();
        }
      }
    }

    indexToDeleteRef.current = undefined;
    setDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    indexToDeleteRef.current = undefined;
    setDialogOpen(false);
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !evt.shiftKey && document?.getElementById(ProductTypeName.JACKET)) {
      evt.preventDefault();
      document?.getElementById(ProductTypeName.JACKET)?.focus();
    }
  }

  React.useEffect(() => {
    companyStateLocationsRef.current = companyStateLocations;
  }, [companyStateLocations]);

  React.useEffect(() => {
    if (cpls.length > 0) return;
    const newCpl = getDefaultCplObject();
    newCpl.integrationKey = uuidv4();
    append(newCpl);
    setValue(`cpls.0`, newCpl);
    setValue(`cpls.0.addresseeNamesData`, null);
  }, [append, cpls.length, setValue]);
  
  return (
    <>
      <StyledGroup>
        {fields.map((field: any, index: number) => {
          return (
            <LetterProduct
              key={field.fieldId}
              product={field}
              productEntity="cpls"
              productType={ProductType.Cpl}
              productIndex={index}
              onDelete={openDeleteDialog}
              issueButtonDisabled={issueCplButtonDisabled || isFileDisabled}
              voidButtonDisabled={voidCplButtonDisabled}
              submitReviseDisabled={submitReviseDisabled}
              issueLetter={async (integrationKey: string) => {
                await handleIssueCpl(field, integrationKey);
              }}
              reviseLetter={async (
                integrationKey: string,
                originalOrderID: number
              ) => {
                await handleReviseCpl(integrationKey, originalOrderID);
              }}
              voidLetter={async (defaultVoidReason: string) => {
                await handleVoidCpl(field, index, defaultVoidReason);
              }}
              fileNameNumber={fileNameNumber}
              hasIssuedProducts={hasIssuedProducts}
              isPopulatingExistingFile={isPopulatingExistingFile}
              cpls={cpls}
              aALProducts={[]}
              loadCityList={true}
              onEffectiveDateChange={(date: Date | null) =>
                handleEffectiveDateChange(index, date)
              }
            />
          );
        })}

        <Box>
          <AddLink
            disabled={isFileDisabled}
            onClick={addNewCpl}
            onKeyDown={handleKeyDown}
          >
            Add CPL
          </AddLink>
        </Box>
      </StyledGroup>

      <ConfirmationDialogV3
        confirmationMessage="Delete this pending CPL?"
        isOpen={isDialogOpen}
        onYes={deleteItem}
        onNo={closeDeleteDialog}
        onCancel={closeDeleteDialog}
        cancelActionButton={true}
        singleActionButtonText={"Delete"}
        buttonNoText={"Cancel"}
      />

      <ConfirmationDialogWithProgressbar
        autoClose={true}
        title={progressMessage}
        closeButtonText="Done! Click to Continue"
        isOpen={openProgressDialog}
        onClose={handleOnCloseProgressDialog}
        requestId={progressRequestId}
        runProgress={runProgress}
        onCloseAction={handleCloseVoidCpl}
      />
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        {...{ pdfDocuments }}
      />
      <ErrorDialog
        isOpen={openErrorDialog}
        confirmationMessage={errorMessage}
        correlationId={correlationId}
        onYes={handleOnYesErrorDialog}
      />
      <VoidLetterForm
        productType={ProductType.Cpl}
        coveredParty={currentProduct.coveredParty}
        serialNumber={currentProduct.productReferenceID || ""}
        effectiveDate={formatDate(
          new Date(String(currentProduct.effectiveDate))
        )}
        defaultVoidReason={currentDefaultVoidReason}
        productIndex={currentProductIndex}
        isOpen={openVoidCPLDialog}
        onSubmit={(e: any) =>
          handleVoidSubmit(currentProduct, currentDefaultVoidReason)
        }
        onCancel={handleVoidCancel}
        submitButtonDisabled={submitVoidButtonDisabled}
      />
    </>
  );
};

export default CPLProductCollection;
