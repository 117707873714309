import { 
  Table, 
  styled,
 } from "@mui/material";
import { FileActivity } from "entities/UIModel";
import React, {
  useState,
} from "react";
import { Order } from "utils/sorting";
import FileHistoryBody from "./FileHistoryBody";
import FileHistoryHead from "./FileHistoryHead";
import { 
  colors, 
  fontSize, 
  fontWeight,
  padding,
  fontFamily,
 } from "theme/defaultStyle";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface Props {
  records?: FileActivity[];
}

const FileHistory = ({ records = [] }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof FileActivity>("date");
  const [ { profileSettings },] = useProfileSettingsCache();

  const handleRequestSort = (property: keyof FileActivity) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const StyledVisibilityMessage = styled("div")({
    color: colors.grey11,
    fontFamily: fontFamily.primary,
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    paddingTop: padding.large2,
  });
  
  return (
    <Table className="history-table">
      <FileHistoryHead {...{ order, orderBy, onRequestSort: handleRequestSort }} />
      <FileHistoryBody {...{ order, orderBy, records }} />
      {profileSettings?.isStaffUser === 1 && (
        <StyledVisibilityMessage>* This activity is visible to Stewart staff only.</StyledVisibilityMessage>
      )}
    </Table>
  );
};

export default React.memo(FileHistory);
