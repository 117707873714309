import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, { useEffect, useState } from "react";
import { useRegistrationLookup } from "utils/context/RegistrationContext";

interface StateFormFieldProps {
  isOpen: boolean;
  setSelectedStateObject: any;
  defaultPropertyState?: string;
  methods: any;
}

export default function StateFormField({
  isOpen,
  setSelectedStateObject,
  defaultPropertyState = "",
  methods,
}: StateFormFieldProps) {
  const [{ agencyStates }, { getAgencyStates }] = useRegistrationLookup();
  const [statesWithDefaultOption, setStatesWithDefaultOption] = useState<any[]>([]);
  const [selectedState, setSelectedState] = useState<any>(null);
  const { setValue, getValues } = methods;

  const handleAgencyChange = (_: React.ChangeEvent<{}>, value: SelectFieldOption | null) => {
    setSelectedState(value);
  };

  useEffect(() => {
    if (isOpen && agencyStates.length === 0) {
      getAgencyStates(getValues("agencyCode"));
    }
  }, [isOpen, agencyStates, getAgencyStates, getValues]);

  useEffect(() => {
    setStatesWithDefaultOption(agencyStates);
  }, [agencyStates]);

  useEffect(() => {
    const selectedStateObject = statesWithDefaultOption.find((obj) => obj.value === selectedState);
    setSelectedStateObject(selectedStateObject);
  }, [selectedState, statesWithDefaultOption, setSelectedStateObject]);

  useEffect(() => {
    if (defaultPropertyState) {
      setValue("state", defaultPropertyState);
      setSelectedState(defaultPropertyState);
    }
  }, [defaultPropertyState, setValue]);

  useEffect(() => {
    if (statesWithDefaultOption.length === 1) {
      const singleState = statesWithDefaultOption[0];
      setValue("state", singleState.value);
      setSelectedState(singleState.value);
      setSelectedStateObject(singleState);
    }
  }, [statesWithDefaultOption, setValue, setSelectedStateObject]);

  return (
    <>
      <SelectField
        label="State"
        name={"state"}
        options={statesWithDefaultOption}
        onChange={handleAgencyChange}
      />
    </>
  );
}
