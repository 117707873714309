import NotesIcon from "@mui/icons-material/Notes";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

const ReviseIcon = () => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "Center",
        alignItems: "Center",
        marginLeft: "5px"
      }}
    >
      <NotesIcon style={{ fontSize: 16 }} />
      <EditIcon style={{ fontSize: 12, marginTop: -8, marginRight: -15 }} />
    </span>
  );
};

export default ReviseIcon;
