import { styled } from "@mui/material";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";
import { NotificationType } from "utils/data/enum";
import React from "react";

type StyledLabelProps = {
  infoMessageFontColor?: string;
  infoMessageFontWeight?: string;
};

const StyledLabel = styled("label")<StyledLabelProps>(
  ({
    infoMessageFontColor,
    infoMessageFontWeight,
  }) => ({
    "&.error": {
      color: colors.red01,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal1,
      marginLeft: margin.small2,
    },
    "&.info": {
      color: infoMessageFontColor ? infoMessageFontColor : colors.grey13,
      marginLeft: margin.small2,
      fontWeight: infoMessageFontWeight ? infoMessageFontWeight :fontWeight.normal1,
    },
  })
);

type Props = {
  message: string | undefined;
  notificationType: NotificationType;
  show: boolean;
  infoMessageFontColor?: string;
  infoMessageFontWeight?: string;
};

export default function PricingTableHeaderNotification({
  message,
  notificationType,
  show,
  infoMessageFontColor,
  infoMessageFontWeight,
}: Props) {
  if (!show) return null;

  return (
    <StyledLabel
      className=
      {notificationType === NotificationType.Error 
        ? "error" : 
        "info"}
      infoMessageFontColor ={infoMessageFontColor}
      infoMessageFontWeight={infoMessageFontWeight}
    >
      {message} 
    </StyledLabel>
  );
}
