import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";

function getSelectedNodes(
  node: SignatureConfigurationNode | null | undefined,
  arr: string[] = []
): string[] {
  if (!node) return arr;

  if (node.Selected) arr.push(node.ID);

  node.Children?.forEach((child) => getSelectedNodes(child, arr));
  return arr;
}

function hasSelectedNodes(
  node: SignatureConfigurationNode | null | undefined
): boolean {
  const selectedNodes = getSelectedNodes(node, []);
  const anySelectedNodes = selectedNodes.length > 0;
  return anySelectedNodes
}

function selectAllLocationNodes(node: SignatureConfigurationNode) {
  if (Array.isArray(node.Children) && node.Children.length > 0) {
    node.Children.forEach((childNode) => selectAllLocationNodes(childNode));
  } else {
    node.Selected = true;
  }
}

export { getSelectedNodes, hasSelectedNodes, selectAllLocationNodes };
