import {
  GeneralFileParty,
  filePartyDTO,
} from "entities/UIModel";
import { cloneDeep } from "lodash";
import { isValidParty } from "pages/file/utils/helper";
import { getDisplayNameForLender } from "pages/file/utils/partyHelper";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";

type State = {
  lenders: GeneralFileParty[];
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setLenders:
    (lenderParties: GeneralFileParty[]) =>
      ({ setState }: StoreApi) => {
        setState({
          lenders: cloneDeep(lenderParties),
        });
      },
  updateZipCodeLender: (indexLender: number, value: string) => ({ setState, getState }: StoreApi) => {
    const lenders = getState().lenders.map((lender: GeneralFileParty, index: number) => {
      if (index === indexLender) {
        lender.zipCode = value;
      }

      return lender;
    });
    setState({ lenders: lenders });
  }
};

const Store = createStore<State, Actions>({
  initialState: {
    lenders: [],
  },
  actions,
  name: "PartyLender",
});

const getPartyLenderNames = (state: State) => {
  const buyerNames: string[] = state.lenders
    ?.filter((lender) => isValidParty(lender))
    ?.map(
      (lender) =>
        getDisplayNameForLender(lender)?.trim()
    );

  return buyerNames?.join(", ").trim();
};

const getPartyLenderDefault = (state: State) => {
  let defaultLender: filePartyDTO | undefined;
  const foundLender = state.lenders?.find((lender: GeneralFileParty) =>
    isValidParty(lender)
  );

  if (foundLender) {
    defaultLender = {
      integrationKey: foundLender.integrationKey,
      addressOne: foundLender.addressOne,
      addressTwo: foundLender.addressTwo,
      city: foundLender.city,
      state: foundLender.state,
      zipCode: foundLender.zipCode,
      country: foundLender.country,
      loanNumber: foundLender.loanNumber,
      successorLanguage: foundLender.successorLanguage,
      attentionTo: foundLender.attentionTo,
    };
  }

  return defaultLender;
};

const hasValidLender = (state: State) =>
  state.lenders?.some((lender: GeneralFileParty) => isValidParty(lender));

const usePartyLenderActions = createHook(Store, {
  selector: null,
});

const usePartyLenderNames = createHook(Store, {
  selector: getPartyLenderNames,
});

const usePartyLenderDefault = createHook(Store, {
  selector: getPartyLenderDefault,
});

const usePartyLenderHasValidLender = createHook(Store, {
  selector: hasValidLender,
});

export {
  usePartyLenderActions, usePartyLenderDefault,
  usePartyLenderHasValidLender, usePartyLenderNames
};

