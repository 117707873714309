import { styled } from "@mui/material";
import React from "react";
import {
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import AgencyField from "./AgencyField";
import AgencyLocationField from "./AgencyLocationField";
import AgencyStateField from "./AgencyStateField";
import LevelField from "./LevelField";

const FieldTitle = styled("div")({
  fontFamily: "Roboto, sans-serif",
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  marginTop: margin.xlarge1,
  padding: `${padding.zero} ${padding.small}`,
});

const DefaultInfoWrapper = styled("div")({
  padding: `${padding.zero} ${padding.small}`,
});

const DefaultInfo = () => {
  return (
    <DefaultInfoWrapper>
      <FieldTitle>Choose where you’d like the signature to default.</FieldTitle>
      <AgencyField />
      <AgencyStateField />
      <AgencyLocationField />
      <LevelField />
    </DefaultInfoWrapper>
  );
};

export default DefaultInfo;
