import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import {
  createHook,
  createStore,
  defaultRegistry,
  StoreActionApi,
} from "react-sweet-state";

export interface TimeZone {
  Common_UTC_Offset: string;
  Current_UTC_Offset: string;
  Display_Name: string;
  Is_Currently_DST: number;
  Name: string;
}

export interface ProfileSettings {
  userID: string;
  userNumber: number;
  displayName: string;
  stewartAccessUsername: string;
  userTimeZone: string;
  timeZones: TimeZone[];
  saAccount: any;
  isUserProfileSet?: boolean;
  userProfile: UserProfile;
  userPermissionItemTypes :any;
  hasError: boolean;
  firstName?: string;
  lastName?: string;
  userName?: string;
  userTypeCode?: string;
  userEmailAddress?: string;
  userPhone?: string;
  userAgencyCount: number;
  hasGlobalAccess?: number;
  isStaffUser?: number;
}

export interface UserProfile {
  defaultAgencyID?: string;
  defaultAgencyLocation?: SelectFieldOption;
  defaultPropertyType?: string;
  defaultPropertyState?: string;
  useAddressSuggestion: string;
  skipAgencySelection?: string;  
}

interface State {
  profileSettings: ProfileSettings;
  isLoading: boolean;
  isSaveDone: boolean;
  error: string | null;
}

type StoreApi = StoreActionApi<State>;

const setLoading =
  (isLoadingFlag?: boolean) =>
  ({ setState }: StoreApi) => {
    if (!isLoadingFlag) {
      isLoadingFlag = true;
    }
    setState({ isLoading: isLoadingFlag });
  };

const setError =
  (error: string | null) =>
  ({ setState }: StoreApi) => {
    setState({ error });
  };

const setProfileData =
  (profile: any) =>
  ({ setState }: StoreApi) => {
    if (!profile || profile.length < 2) {
      setState({
        isLoading: false,
        profileSettings: {
          userID: "",
          userNumber: 0,
          displayName: "",
          stewartAccessUsername: "",
          userTimeZone: "",
          userTypeCode: profile.UserTypeCode,
          userPermissionItemTypes: profile.UserPermissionItemTypes,
          timeZones: [],
          saAccount: null,
          isUserProfileSet: false,
          userProfile: {
            defaultAgencyLocation: { text: "", value: "" },
            defaultPropertyType: "",
            defaultPropertyState: "",
            useAddressSuggestion: "On",
            skipAgencySelection: "Yes",
            defaultAgencyID: "",
          },
          userAgencyCount: 0,
          userEmailAddress: "",
          userPhone: "",
          hasError: false,
          hasGlobalAccess: 0,
          isStaffUser: 0,
        },
        isSaveDone: true,
      });
    } else {
      const saAccounts = profile[0]
        ? profile[0].ExternalSystemAccounts
        : profile.stewartAccessObj;
      const stewartAccessObj = profile[0]
        ? saAccounts &&
          saAccounts.find(
            (account: any) =>
              account.ExternalSystemTypeCode === "STEWARTACCESS" &&
              account.IsPrimary === 1
          )
        : profile.stewartAccessObj;

      const timeZones = profile[1] ? profile[1] : profile.timeZones;
      let values;

      if (profile[0]) {
        values = {
          userID: profile[0].UserID,
          userNumber: profile[0].UserNumber,
          displayName: profile[0].DisplayName,
          firstName: profile[0].FirstName,
          lastName: profile[0].LastName,
          userName: profile[0].UserName,
          userTypeCode: profile[0].UserTypeCode,
          stewartAccessUsername:
            stewartAccessObj && stewartAccessObj.ExternalUserName,
          userTimeZone: profile[0].UserTimeZone,
          userEmailAddress: profile[0].EmailAddress,
          userPhone: profile[0].Phone,
          timeZones: timeZones,
          saAccount: stewartAccessObj,
          hasError: profile[0].HasError,
          isUserProfileSet: profile[0].UserProfile ? true : false,
          userProfile: {
            defaultAgencyID: profile[0].UserProfile?.DefaultAgencyID ?? "",
            defaultAgencyLocation: {
              text: profile[0].UserProfile?.DefaultAgencyLocation ?? "",
              value: profile[0].UserProfile?.DefaultAgencyLocation ?? "",
            },
            defaultPropertyState:
              profile[0].UserProfile?.DefaultPropertyState ?? "",
            defaultPropertyType:
              profile[0].UserProfile?.DefaultPropertyType ?? "RESIDENTIAL",
            useAddressSuggestion:
              profile[0].UserProfile?.UseAddressSuggestion === 1 ? "On" : "Off",
            skipAgencySelection:
              profile[0].UserProfile?.SkipAgencySelection === 1 ? "Yes" : "No",
          },
          userAgencyCount: profile[0].UserAgencyCount,
          hasGlobalAccess: profile[0].HasGlobalAccess ?? 0,
          isStaffUser: profile[0].IsStaffUser ?? 0,
          userPermissionItemTypes: profile[0].UserPermissionItemTypes,
        };
      } else {
        values = {
          userID: profile.userID,
          userNumber: profile.userNumber,
          displayName: profile.displayName,
          stewartAccessUsername:
            stewartAccessObj && stewartAccessObj.ExternalUserName,
          userTimeZone: profile.userTimeZone,
          timeZones: timeZones,
          userEmailAddress: profile.EmailAddress,
          userPhone: profile.Phone,
          saAccount: stewartAccessObj,
          hasError: false,
          isUserProfileSet: profile.UserProfile ? true : false,
          userProfile: {
            defaultAgencyID: profile.UserProfile?.defaultAgencyID,
            defaultAgencyLocation: {
              text: profile.UserProfile?.DefaultAgencyLocation ?? "",
              value: profile.UserProfile?.DefaultAgencyLocation ?? "",
            },
            defaultPropertyType: profile.userProfile.defaultPropertyType,
            defaultPropertyState: profile.userProfile.defaultPropertyState,
            useAddressSuggestion: profile.userProfile.useAddressSuggestion,
            skipAgencySelection: profile.userProfile.skipAgencySelection,
          },
          userAgencyCount: profile.UserAgencyCount,
          hasGlobalAccess: profile.HasGlobalAccess ?? 0,
          isStaffUser: profile.IsStaffUser ?? 0,
          userPermissionItemTypes: profile.UserPermissionItemTypes,
        };
      }

      setState({
        isLoading: false,
        isSaveDone: true,
        profileSettings: values,
      });
    }
  };

const setUserPermissions =
  (userPermissionItemTypes: string[]) =>
  ({ setState, getState }: StoreApi) => {
    const profileSettings = getState().profileSettings;
    if (userPermissionItemTypes && userPermissionItemTypes.length > 0) {
      // const profileSettings = getState().profileSettings;
      profileSettings.userPermissionItemTypes = userPermissionItemTypes;
      setState({ profileSettings });
    }
    return profileSettings.userPermissionItemTypes;
  };

const Store = createStore<State, any>({
  initialState: {
    profileSettings: {
      userID: "",
      userNumber: 0,
      displayName: "",
      stewartAccessUsername: "",
      userTimeZone: "",
      timeZones: [],
      saAccount: null,
      isUserProfileSet: undefined,
      userProfile: {
        defaultAgencyID: "",
        defaultAgencyLocation: { text: "", value: "" },
        defaultPropertyType: "",
        defaultPropertyState: "",
        useAddressSuggestion: "On",
        skipAgencySelection: "Yes",
      },
      userPermissionItemTypes: [],
      hasError: false,
      userEmailAddress: "",
      userPhone: "",
      userAgencyCount: 0,
      hasGlobalAccess: 0,
      isStaffUser: 0,
    },
    isLoading: false,
    isSaveDone: false,
    error: null,
  },
  actions: {
    getProfileSettings:
      () =>
      async ({ dispatch }: StoreApi) => {
        try {
          dispatch(setLoading());
          //const [{UserNumber, DisplayName, UserTimeZone, ExternalSystemAccounts}, timeZones]: any = await Promise.all([axiosSecuredInstance.get("/user/getUser").then(resp=>resp.data), axiosSecuredInstance.get("/lookup/gettimezonelist").then(resp=>resp.data)]).finally(() => {
          const result: any = await Promise.all([
            axiosSecuredInstance.get("/user/getUser").then((resp) => resp.data),
            axiosSecuredInstance
              .get("/lookup/gettimezonelist")
              .then((resp) => resp.data),
          ]).finally(() => {
            setLoading(false);
          });

          dispatch(setProfileData(result));
        } catch (error: any) {
          dispatch(setError(error));
        }
      },
    getUserPermissions:
      () =>
      async ({ dispatch }: StoreApi) => {
        try {
          const { data } = await axiosSecuredInstance.get<string[]>(
            "/user/GetUserPermissions"
          );
          return (dispatch(setUserPermissions(data)));
        } catch (error: any) {
          dispatch(setError(error));
        }
      },
    onSubmit:
      (values: any) =>
      async ({ dispatch, setState }: StoreApi) => {
        setState({ isSaveDone: false });
        const primaryAccount = values.ExternalSystemAccounts.find(
          (acct: any) => acct.IsPrimary
        );
        if (primaryAccount) values.saAccount = primaryAccount;

        return axiosSecuredInstance
          .post("/user/saveuser", {
            UserNumber: values.userNumber,
            DisplayName: values.displayName,
            UserTimeZone: values.userTimeZone,
            ExternalSystemAccounts: values.ExternalSystemAccounts,
            UserProfile: {
              DefaultAgencyID: values.userProfile.defaultAgencyID,
              DefaultAgencyLocation:
                values.userProfile?.defaultAgencyLocation?.value,
              DefaultPropertyType: values.userProfile.defaultPropertyType,
              DefaultPropertyState: values.userProfile.defaultPropertyState,
              UseAddressSuggestion:
                values.userProfile.useAddressSuggestion === "On" ? 1 : 0,
              SkipAgencySelection:
                values.userProfile.skipAgencySelection === "Yes" ? 1 : 0,
            },
          })
          .then(() => {})
          .finally(() => {})
          .catch((ex) => {
            console.log(ex);
            dispatch(
              setProfileData({
                userNumber: values.userNumber,
                displayName: values.displayName,
                stewartAccessObj: values.saAccount,
                userTimeZone: values.userTimeZone,
                timeZones: values.timeZones,
                userTypeCode: values.UserTypeCode,
                userEmailAddress: values.EmailAddress,
                userPhone: values.Phone,
                hasGlobalAccess: values.hasGlobalAccess,
                isStaffUser: values.isStaffUser,
                userProfile: {
                  defaultAgencyID: values.userProfile.defaultAgencyID,
                  defaultAgencyLocation: {
                    text: values.userProfile.defaultAgencyLocation || "",
                    value: values.userProfile.defaultAgencyLocation || "",
                  },
                  defaultPropertyType: values.userProfile.defaultPropertyType,
                  defaultPropertyState: values.userProfile.defaultPropertyState,
                  useAddressSuggestion: values.userProfile.useAddressSuggestion,
                  skipAgencySelection: values.userProfile.skipAgencySelection,
                },
                hasError: true,
              })
            );
          });
      },
    validate: (values: any) => async () => {
      const errors: any = {};

      if (values.displayName.trim() === "") {
        errors.displayName = "Enter the display name";
      }

      if (values.stewartAccessUsername.trim() === "") {
        errors.stewartAccessUsername = "Enter the stewart access display name";
      }

      if (values.userTimeZone.trim() === "") {
        errors.userTimeZone = "Select user timezone";
      }

      return errors;
    },
  },
  name: "userProfileSettings",
});

const hook = createHook(Store);
export const GlobalProfileStore = defaultRegistry.getStore(Store);
export const useProfileSettingsCache = () => {
  return hook();
};
