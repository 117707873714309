import { styled } from "@mui/material";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import React from "react";
import { useReportPay } from "utils/context/ReportPayContext";
import ReportPayFooterAction from "../reportpay-footer-action";
import ReportPayFooterTotal from "../reportpay-footer-total";
import { padding } from "theme/defaultStyle";

type FooterProps = {
  rows: ReportPayFile[];
  onlyShowSelectedFiles: boolean;
  page: number;
  isLoading?: boolean;
  onPageChange: (page: number) => void;
  onOnlySelectedFilesChange?: (value: boolean) => void;
};

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: padding.large2,
  width: "100%",
});

const ReportPayFooter = ({
  rows,
  onlyShowSelectedFiles,
  onOnlySelectedFilesChange,
}: FooterProps) => {
  const [{ isAdvSearchOpen }] = useReportPay();
  const handleSelectedFilesChange = (value: boolean) =>
    onOnlySelectedFilesChange?.(value);

  const noSelectedRows = !rows.length || isAdvSearchOpen;

  return (
    <>
      <Container>
        <ReportPayFooterAction
          disabled={noSelectedRows}
          onlyShowSelectedFiles={onlyShowSelectedFiles}
          onOnlySelectedFilesChange={handleSelectedFilesChange}
        />
        <ReportPayFooterTotal rows={rows} />
      </Container>
    </>
  );
};

export default ReportPayFooter;
