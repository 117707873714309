import React, { 
  useCallback, 
  useEffect, 
  useState 
} from "react";
import { 
  colors, 
  fontSize, 
  fontWeight, 
  gaps 
} from "theme/defaultStyle";
import { 
  debounce, 
  styled 
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ActionButton from "controls/global/action-button";
import PolicyUploadDialog from "./PolicyUploadDialog";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import PolicyUploadGrid from "./PolicyUploadGrid";
import { ImageArchiveUI } from "entities/UIModel/ImageArchiveUI";
import { ImageArchiveSearchCriteria } from "entities/ApiModel/ImageArchiveSearchCriteria";
import { Order } from "utils/sorting";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useDocumentStore from "utils/context/ImageArchiveContext";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useMyFiles } from "utils/context/MyFilesContext";
// import { useSideNavProvider } from "pages/file/SideNavProvider";
// import { useFilePage } from "utils/context/FilePageContext";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  "& .uploadButton": {
    width: "180px",
  },
  "& .policyUploadInfo": {
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    color: colors.grey22,
  },
});

const NO_RECORD_MESSAGE = "There are currently no policy images uploaded to this file";

const PolicyUpload = () => {
  const [policyUploadDialogOpen, setPolicyUploadDialogOpen] = useState<boolean>(false);
  const { setValue, getValues } = useFormWrapper();
  const { reloadFile, reloadFileActivities, saveSCFile } = useCreateFile();
  // const { sideNavState  } = useSideNavProvider();
  // const [, { setScrollTo }] = useFilePage();
    
  // configuration
  const { generalConfig } = useConfigContext();  
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const [, { setRequestTimeoutMs }] = useMyFiles();

  // Global Access Agency
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();
  const globalAccessAgencyID = globalAccessAgency?.[0]?.CompanyID;

  // Policy Upload Grid
  const [{ isEditMode }, { getSearchDocuments }] = useDocumentStore();
  const InitialMessage = "Loading results...";
  const [data, setData] = useState<ImageArchiveUI[]>();
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([InitialMessage]);
  const [filters, setFilters] = useState<ImageArchiveSearchCriteria | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof ImageArchiveUI | undefined>("documentUploadedDate");
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);

  const hasUploadedPolicy:boolean = getValues("hasUploadedPolicy");

  const getDefaultFilters = (advanced: boolean): ImageArchiveSearchCriteria => {
    const sortColumn = "-documentUploadedDate";

    const defaultFilters: ImageArchiveSearchCriteria = {
      fileId: getValues("id"),
      listCount: 0,
      requestedPage: 1,
      isInitialLoad: false,
      sortColumn: sortColumn,
    };
    return defaultFilters;
  };

  const getFileSearchCriteria = () => {
    let criteria: ImageArchiveSearchCriteria;

    criteria = {
      ...filters,
      listCount: 0,
      requestedPage: 1,
      ...(globalAccessAgencyID && { companyId: globalAccessAgencyID }),
    };
    if (sortColumn) {
      criteria = {
        ...criteria,
        sortColumn: sortColumn,
      };
    }

    return criteria;
  };
  
  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const timeoutMs = generalConfig.filesSearchTimeoutMs;

    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }
    if (timeoutMs) {
      setRequestTimeoutMs(timeoutMs);
    }
  };

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs),
    [userInputDebounceTimeMs],
  );

  const performSearch = async () => {
    let criteria = getFileSearchCriteria();
    let files: ImageArchiveUI[] | undefined = [];
    try {
      files = await getSearchDocuments({ ...criteria });
      if (files !== undefined) {
        setData(files);
        if (!files?.length) {
          setNoRecordsMessage([NO_RECORD_MESSAGE]);
          // setPage(1);
        }
        setValue("hasUploadedPolicy", (files && files.length > 0) ? true:false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string } = {
      documentDisplayFileName: "DocumentDisplayFileName",
      documentUploadedDate: "DocumentUploadedDate",
      documentUploadedByUserName: "DocumentUploadedByUserName",
    };
    return mappedColumnNames[name] ?? name;
  };

  const handleRequestSort = async (columnName: any, disableToggling: boolean = false) => {
    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof ImageArchiveUI);
    setSortColumn(sortBy);
  };  

  const handleClickOnUpload = () => {
    setPolicyUploadDialogOpen(true);
  }

  // function scrollToProductSectionAfterReload() {
  //   // console.log("calling scrollToProductSectionAfterReload");
  //   // Scroll to Product section
  //   const productElement =  sideNavState.items.get("Products")?.childRef?.current;
  //   const scrollY = productElement?.offsetTop

  //   setScrollTo(true, productElement);

  //   setTimeout(() => {
  //     // console.log("calling scrollTo Product section");
  //     document?.documentElement?.scrollTo({ top: scrollY, behavior: "smooth" });      
  //   }, 300);
  // }

  const handleOnReloadFile = async() => {
    const fileId = getValues("id");
    await reloadFile(fileId);  // happen after Save File
    // await reloadFileActivities(fileId);

    // scrollToProductSectionAfterReload();

  }

  const handleOnReloadFileActivities = async() => {
    const fileId = getValues("id");
    await reloadFileActivities(fileId);  // happen after Save File
    // await reloadFileActivities(fileId);

    // scrollToProductSectionAfterReload();

  }  

  const handleOnSaveFile = async() => {
    await saveSCFile();
  }

  const closeUploaddialog = async() => {
    // refresh file and close dialog
    const fileId = getValues("id");
    //await reloadFile(fileId);
    await reloadFileActivities(fileId);
    setPolicyUploadDialogOpen(false);
    // debounceSetReadyToSearch();
    performSearch();

    // scrollToProductSectionAfterReload();
    
  }

  
  const canSearch = (): boolean => {
    return !partialFilterUpdate && filters !== undefined && Object.values(filters).length > 0 && !isEditMode;
  };  

  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [
    filters,
    sortColumn,
    // filtersApplied,
    settingsLoaded,
    // globalAccessAgencyName,
  ]);  

  useEffect(() => {
    const defaultFilters = getDefaultFilters(false);
    setFilters(defaultFilters);
  }, []);  

  useEffect(() => {
    const init = async () => {
      await loadSettings();
      setSettingsLoaded(true);
    };

    init();
  }, []);

  // useEffect(() => {
  //   console.log("outside - sscrollToAfterReload:", scrollToAfterReload, "scrollToSideNavigationItemRef:", scrollToSideNavigationItemRef);
  // },[scrollToAfterReload])

  return (
    <>
      <Container>
        {hasUploadedPolicy ? (
          <PolicyUploadGrid 
            page={1}
            rows={data ? data:[]}
            orderBy={orderBy}
            order={order}
            noRowsMessage={noRecordsMessage}
            onRequestSort={handleRequestSort}
            performSearch={performSearch}
            reloadFile={handleOnReloadFile}
            reloadFileActivities={handleOnReloadFileActivities}
            saveFile={handleOnSaveFile}
          />
        ):(
          <div className="policyUploadInfo">There are currently no policy images uploaded to this file. Click the Upload Policy Images button below to select policies to upload.</div>
        )}
        
        <ActionButton
          className="uploadButton"
          color="secondary"
          onClick={handleClickOnUpload}
          startIcon={<NoteAddIcon />}
          variant="outlined"
        >
          Upload Policy Images
        </ActionButton>        
        <PolicyUploadDialog 
          isOpen={policyUploadDialogOpen}
          onClose={closeUploaddialog}
        />

      </Container>
    </>

  )
}

export default PolicyUpload;