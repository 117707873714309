import SelectField from "controls/global/select-field";
import React,
{
  useEffect,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import isEqual from "lodash/isEqual";
import { SCFile } from "entities/UIModel";
import { isPropertyStateChangeWarningRequired } from "pages/file/utils/helper";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import { useCompanyLocation } from "utils/context/CompanyLocationContext";
import {
  AgencyLocation,
  useAgencyLocations,
  useAgencyLocationsActions,
} from "utils/context/AgencyLocationsContext";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import useFocus from "utils/custom-hooks/useFocus";
import { useFiles } from "utils/context/FilesContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
// import { useFilePage } from "utils/context/FilePageContext";

interface Props {
  agencyLocations: AgencyLocation[];
}

const name = "agencyLocation";
const AgencyLocationField = ({ agencyLocations, ...rest }: Props) => {
  const { setValue, setError, clearErrors, getValues } = useFormWrapper();
  const [{ profileSettings }] = useProfileSettingsCache();
  const [{ agencyStates }, { getAgencyStates, updateAgencyStates }] =
    useAgencyStates();
  const [{ isLoadingCompanyStateLocations, companyStateLocations }] =
    useCompanyLocation();
  const [{ isRetrievingAgencyLocations }] = useAgencyLocations();
  const [, { getAgencyLocations, updateAgencyLocations }] =
    useAgencyLocationsActions();
  const [{ isReadOnly }] = useFiles();  
  // const [{ scrollToAfterReload }, {setScrollTo}] = useFilePage();

  const [isDisabled, setIsDisabled] = useState(false);
  const [tempAgencyLocation, setTempAgencyLocation] = useState<string>();

  const uniqueAgencyLocations = agencyLocations
    .map((loc: AgencyLocation) => {
      return { value: `${loc.text}`, text: loc.text, disabled: loc.disabled };
    })
    .sort((a, b) => a.text.localeCompare(b.text));

  const { getCompanyStateLocation } = useCreateFile();
  const { setFocusInputElement } = useFocus();
  const [
    fileNameNumberField,
    watchedAgencyId,
    agencyLocationField,
    stateAbbr,
    hasIssuedProducts,
  ] = useWatch({
    name: [
      "fileNameNumber",
      "agency.id",
      "agencyLocation",
      "properties.0.state.abbr",
      "hasIssuedProducts",
    ],
  });

  const handleOnChange = (e: any, item: AgencyLocation) => {
    const match = agencyLocations.find((loc) => loc.text === item.id);
    if (match) {
      const existingAgencyLocation = getValues(name);
      if (existingAgencyLocation)
        setValue("agencyLocationPrevious", existingAgencyLocation);

      const hasIssuedProducts = getValues("hasIssuedProducts");
      const hasPendingProductsOrAddlParties =
        isPropertyStateChangeWarningRequired(getValues() as SCFile);

      if (!hasIssuedProducts && !hasPendingProductsOrAddlParties)
        setValue("properties.0.stateOriginal", undefined);
      const matchingMetadataLocation = getCompanyStateLocation(match.text);
      const newValue = {
        name: match.text,
        id: match?.id,
        legacyId: matchingMetadataLocation?.legacyID,
        locationUniqueID: matchingMetadataLocation?.locationUniqueID,
      };

      setValue(name, newValue);
      setTempAgencyLocation(match.text);
      setValue("isFileDefault", false);
      getAgencyStates(watchedAgencyId, match.text);
      getAgencyLocations(watchedAgencyId, stateAbbr, true);
    }
  };

  useEffect(() => {
    if (watchedAgencyId && hasIssuedProducts && stateAbbr) {
      getAgencyLocations(watchedAgencyId, stateAbbr, false);
    } else if (watchedAgencyId) {
      getAgencyLocations(watchedAgencyId);
    }

    if(watchedAgencyId) {
      setFocusInputElement(name);
      // // console.log("sscrollToAfterReload:", scrollToAfterReload, "scrollToSideNavigationItemRef:", scrollToSideNavigationItemRef);
      // if (!scrollToAfterReload) {
      //   // console.log("calling setFocusInputElement");
      //   setFocusInputElement(name);
      // }
      // else {
      //   setTimeout(() => {
      //     // console.log("setScrollto false");
      //     setScrollTo(false);
      //   }, 500);
      // }
    }
  }, [watchedAgencyId, getAgencyLocations, hasIssuedProducts, stateAbbr]);

  useEffect(() => {
    const disabled =
      !fileNameNumberField ||
      !watchedAgencyId ||
      isReadOnly ||
      (agencyLocationField &&
        !agencyLocationField?.name &&
        isRetrievingAgencyLocations);

    setIsDisabled(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fileNameNumberField,
    agencyLocationField,
    watchedAgencyId,
    setError,
    isRetrievingAgencyLocations,
  ]);

  useEffect(() => {
    if (!watchedAgencyId) {
      const resetAgencyLocation = {
        id: "",
        name: "",
        isValidationRequired: false,
        isDisabled: false,
      };

      setValue(name, resetAgencyLocation);
      setValue("agencyLocationOriginal", resetAgencyLocation);
      setTempAgencyLocation("");
      if (agencyLocations && agencyLocations.length > 0)
        updateAgencyLocations([]);
      if (agencyStates && agencyStates.length > 0) updateAgencyStates([]);
      return;
    }
    const currentAgencyLocation = getValues(name);
    const fileId = getValues("id");

    if (agencyLocations && agencyLocations.length === 1) {
      setValue(name, {
        id: agencyLocations[0].value,
        name: agencyLocations[0].text,
      });
      setTempAgencyLocation(agencyLocations[0].text);
      clearErrors(name);
    } else if (
      agencyLocations.length >= 1 &&
      fileId > 0 &&
      !isLoadingCompanyStateLocations &&
      companyStateLocations.length > 0 &&
      currentAgencyLocation?.id
    ) {
      // Check if AgencyLocation is present in the AgencyLocations list from DB Otherwise
      //Match AgencyLocation in companyStateLocations by
      // 1. name OR
      // 2. LocationUniqueID OR
      // 3. LocationID
      // If not found, in any of the above then set it to first location in the list

      const setMatchingAgencyLocation = (agencyLocationToSet: any): boolean => {
        if (matchingLocation && matchingLocation.text) {
          setValue(name, {
            id: matchingLocation.value,
            name: matchingLocation.text,
          });
          return true;
        }
        return false;
      };

      const firstAgencyLocation = {
        id: agencyLocations[0].value,
        name: agencyLocations[0].text,
      };

      // Matching against ALS source list which comes from DB -- agencyLocations
      let matchingLocation: any = agencyLocations.find((al) =>
        isEqual(al.text, currentAgencyLocation?.name)
      );

      if (setMatchingAgencyLocation(matchingLocation)) {
        clearErrors(name);
        return;
      } else if (currentAgencyLocation?.locationUniqueID)
        matchingLocation = agencyLocations.find((al) =>
          isEqual(al.locationUniqueID, currentAgencyLocation?.locationUniqueID)
        );

      if (setMatchingAgencyLocation(matchingLocation)) {
        clearErrors(name);
        return;
      } else if (companyStateLocations.length > 0) {
        // Matching against companyStateLocations
        // Match By LocationDisplayName
        matchingLocation = companyStateLocations.find((ml) =>
          isEqual(ml.locationDisplayName, currentAgencyLocation?.name)
        );

        if (!matchingLocation) {
          // Match By LocationUniqueID
          if (currentAgencyLocation?.locationUniqueID)
            matchingLocation = companyStateLocations?.find((ml) =>
              isEqual(
                ml.locationUniqueID,
                currentAgencyLocation?.locationUniqueID
              )
            );
          if (!matchingLocation && currentAgencyLocation?.locationID) {
            // Match By LocationID
            const matchingLocationsByLocationID = companyStateLocations.filter(
              (ml) => isEqual(ml.locationID, currentAgencyLocation?.locationID)
            );
            //Match By  LocationID might return more than one from list.
            //So further filter by doing substring search to find close-to-accurate
            if (
              matchingLocationsByLocationID &&
              matchingLocationsByLocationID.length > 0
            ) {
              if (matchingLocationsByLocationID.length === 1) {
                matchingLocation = matchingLocationsByLocationID[0];
              } else {
                // Do a substring match
                matchingLocation = companyStateLocations.find((ml) =>
                  ml.locationDisplayName?.includes(currentAgencyLocation?.name)
                );
                if (!matchingLocation)
                  matchingLocation = matchingLocationsByLocationID[0];
              } //SubString Search End
            } // Further refined Search By LocationID List
          } // Search By LocationID
        } // Search By LocationUniqueID
      } //Search Metadata locations

      if (
        matchingLocation &&
        (matchingLocation.text || matchingLocation.name)
      ) {
        //This is because, File Agency Locations source is not Metadata Locations.
        //So we need to make sure the location found from companyStateLocations must be part of ALS list
        //Otherwise it will be blank
        const matchingLocationName =
          matchingLocation.text || matchingLocation.name;
        const matchFromALS = agencyLocations.find((al) =>
          isEqual(al.text, matchingLocationName)
        );
        if (matchFromALS && matchFromALS.text)
          setValue(name, {
            id: matchFromALS.value,
            name: matchFromALS.text,
          });
        else setValue(name, firstAgencyLocation);
      } else setValue(name, firstAgencyLocation);
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchedAgencyId,
    agencyLocations,
    agencyLocations.length,
    setValue,
    companyStateLocations,
    isLoadingCompanyStateLocations,
  ]);

  useEffect(() => {
    const fileId = getValues("id");
    if (agencyLocations?.length > 1 && fileId === 0) {
      const currentAgencyLocation = getValues(name);
      const currentAgency = getValues('agency');
      const profileDefaultAgencyLocation = profileSettings?.userProfile?.defaultAgencyLocation?.value;

      if (currentAgencyLocation.id) {        
        const matchingLocation = agencyLocations.find((al) =>
          isEqual(al.value, currentAgencyLocation?.name)
        );
        if (!matchingLocation) {
          const resetAgencyLocation = {
            id: "",
            name: "",
            isValidationRequired: false,
            isDisabled: false,
          };
          setValue(name, resetAgencyLocation);
        }
      } else if(currentAgency?.id && profileDefaultAgencyLocation && fileNameNumberField){        
        const matchingAgencyLocation = agencyLocations.find((al) => al.value === profileDefaultAgencyLocation);        
        
        if(matchingAgencyLocation) {
          setValue(name, {
            name: matchingAgencyLocation.text,
            id: matchingAgencyLocation.value
          });
        }
      }
    }
  }, [
    agencyLocations, 
    getValues, 
    profileSettings?.userProfile?.defaultAgencyLocation?.value, 
    setValue, 
    fileNameNumberField
  ]);

  useEffect(() => {
    if (agencyLocationField?.name) {
      const selectedItem = agencyLocations.find(
        (l) => l.text === agencyLocationField?.name
      );
      const matchingMetadataLocation = getCompanyStateLocation(
        agencyLocationField?.name
      );

      if (selectedItem && matchingMetadataLocation) {
        agencyLocationField.id = selectedItem?.id ?? watchedAgencyId;
        if (selectedItem?.disabled)
          agencyLocationField.isDisabled = selectedItem?.disabled;
        agencyLocationField.legacyID = matchingMetadataLocation?.legacyID;
        agencyLocationField.locationUniqueID =
          matchingMetadataLocation?.locationUniqueID;
      }
      setTempAgencyLocation(agencyLocationField?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyLocations, agencyLocationField, agencyLocationField?.name,]);

  useEffect(() => {
    if (
      !fileNameNumberField &&
      tempAgencyLocation &&
      agencyLocations?.length !== 1 
    ) {
      setValue(name, { id: "", name: "", isValidationRequired: false });
      setTempAgencyLocation(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileNameNumberField, tempAgencyLocation, profileSettings?.userProfile?.defaultAgencyLocation?.value]);

  return (
    <SelectField
      options={uniqueAgencyLocations}
      optionsValueField="value"
      optionsTextField="text"
      dataValueField="id"
      dataTextField="text"
      name={name}
      label="Agency Location"
      disabled={isDisabled || isRetrievingAgencyLocations}
      delaySearching={500}
      autosetValue={false}
      onChange={handleOnChange}
      // onBlur ={handleOnBlur}
      overrideValue={{
        text: agencyLocationField?.name,
        id: `${agencyLocationField?.name}`,
      }}
      {...rest}
    />
  );
};

export default AgencyLocationField;
