import React from "react";
import MultiSelectField, {
  MultiSelectFieldOption,
} from "controls/global/multi-select-field/MultiSelectField";

type Props = {
  disabled?: boolean;
  label: string;
  name: any;
  defaultValue?: any;
  options: MultiSelectFieldOption[];
  coveredParty?: string;
  productIndex: number;
  onTouched?: () => void;
};

const AdditionalPartiesField = ({
  disabled,
  label,
  name,
  defaultValue,
  options,
  productIndex,
  onTouched,
}: Props) => (
  <MultiSelectField
    label={label}
    name={name}
    defaultValue={defaultValue}
    options={options}
    productIndex={productIndex}
    onTouched={onTouched}
    disabled={disabled}
  />
);

export default AdditionalPartiesField;
