import BlockIcon from "@mui/icons-material/Block";
import { styled } from "@mui/material";
import React from "react";

const StyledBlockIcon = styled(BlockIcon)({
  "-moz-transform": "scaleX(-1)",
  "-ms-transform": "scaleX(-1)",
  "-webkit-transform": "scaleX(-1)",
  stroke: "inherit",
  strokeWidth: 2,
  transform: "scaleX(-1)",
});

const BlockBoldIcon = () => <StyledBlockIcon />;

export default BlockBoldIcon;
