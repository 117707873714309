import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { Endorsement } from "entities/UIModel/Endorsement";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import usePricing from "utils/custom-hooks/usePricing";

function useVoidableLockedFile() {
  const { isFileLocked, isReadOnly } = useCreateFile();
  const { isFileLockedWithUpdateablePricingItem } = usePricing();
  const { getValues } = useFormWrapper();

  const shouldVoidableWhenLocked = React.useCallback(
    (endorsement: Endorsement): boolean => {
      if(isReadOnly) return false;
      const pricingProducts = getValues("pricingProducts");
      if (!pricingProducts || pricingProducts.length === 0 || !isFileLocked)
        return true;
      let matchedItem = null;
      for (const product of pricingProducts) {
        if (product.productItems) {
          matchedItem = product.productItems.find(
            (item: any) => item.productItemId === endorsement.identityKey
          );
        }
        if (matchedItem)
          return isFileLockedWithUpdateablePricingItem(matchedItem, isFileLocked);
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFileLocked, getValues, isFileLockedWithUpdateablePricingItem]
  );

  return { shouldVoidableWhenLocked };
}

export default useVoidableLockedFile;
