import {
  styled,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AutocompleteField from "controls/global/custom-autocomplete/AutocompleteField";
import { AutocompleteResponse } from "entities/UIModel";
import React, {
  useRef,
} from "react";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useEsb } from "utils/context/EsbContext";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import { useLookup } from "utils/context/LookupContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useSmartyStreets } from "utils/context/SmartyStreetsContext";
import useFocus from "utils/custom-hooks/useFocus";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

export interface AddressOneProps {
  name: string;
  schema?: string;
  labelPrefix?: string;
  isDisabled?: boolean;
  isPropertySection?: boolean;
  addressTwoFieldName: string;
  stateFieldName: string;
  cityFieldName: string;
  zipCodeFieldName: string;
  options?: AutocompleteResponse[];
  open?: boolean;
  onBlur?: (event: React.ChangeEvent<any>) => void;
  onFocus?: (event: React.ChangeEvent<any>) => void;
  setNotDefault?: () => void;
  onAutocompleteSelected?: () => void;
  verifyAddressHandler?: (value: string) => void;
}

const StyledOption = styled(Typography)({
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 1,
});

const AddressOneField = ({
  name,
  labelPrefix = "",
  isDisabled = false,
  addressTwoFieldName,
  stateFieldName,
  cityFieldName,
  zipCodeFieldName,
  isPropertySection = false,
  setNotDefault,
  onAutocompleteSelected,
  verifyAddressHandler,
  schema,
  ...rest
}: AddressOneProps) => {
  const [{ agencyStates }] = useAgencyStates();
  const [{ states }] = useLookup();
  const [, { autocomplete }] = useSmartyStreets();
  const [, { verifyAddress }] = useEsb();
  const { getValues, setValue } = useFormWrapper();
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const [, { setFilePropertyState }] = useFilePropertyState();

  const selectedAddressOneRef = useRef<AutocompleteResponse>();
  const secondaryListOpenRef = useRef<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const [{ profileSettings }] = useProfileSettingsCache();
  const { goElementFocus } = useFocus();

  const isAutoSuggestionOn =
    profileSettings.userProfile.useAddressSuggestion === "On";

  const elementFocusRef = useRef<any>(null);

  const onGetProperties = async (newInputValue: string) => {
    const currentValue = getValues(name);
    if (!isAutoSuggestionOn || currentValue === newInputValue) {
      setOpen(false);
      return [];
    }

    if (!secondaryListOpenRef.current) {
      const results: AutocompleteResponse[] = await autocomplete({
        search: newInputValue,
        cities: getCity(),
        states: getStateParam(),
        zipcodes: getValues(zipCodeFieldName),
      }).then((options: AutocompleteResponse[]) => {
        selectedAddressOneRef.current = undefined;

        return options;
      });

      const addressOneElement = document.getElementsByName(name)[0];
      setOpen(results?.length > 0 && addressOneElement === document.activeElement );
      return results;
    } else {
      const results: AutocompleteResponse[] = await getSecondaryList();
      secondaryListOpenRef.current = false;

      return results;
    }
  };

  // Smarty Streets expects City to be in City, State Format
  const getCity = () => {
    let city: string = getValues(cityFieldName);
    const stateField = getValues(stateFieldName);
    if (city && stateField && stateField.code !== "") {
      city = city + "," + stateField.abbr;
    }
    return city;
  };

  const getStateParam = () => {
    const stateField = getValues(stateFieldName);
    //Check State Code being empty.  Abbr will have the word "Select"
    if (stateField?.code !== "") {
      return stateField?.abbr;
    }

    // If non-property section, return blank value.
    if (!isPropertySection) {
      return "";
    }

    if (!agencyStates.length) return "";
    return agencyStates
      .filter((state) => state.value)
      .map((state) => state.text)
      .join(";");
  };

  const getStateValue = (stateAbbr: string) => {
    if (isPropertySection) {
      let agencyState = agencyStates.find((state) => state.text === stateAbbr);
      return {
        code: agencyState?.value,
        abbr: agencyState?.text,
      };
    } else {
      let state = states.find((state) => state.text === stateAbbr);

      return {
        code: state?.value,
        abbr: state?.text,
      };
    }
  };

  const getSecondaryList = (search?: string) => {
    const searchParams: any = {
      search,
    };

    if (!search) {
      searchParams.search = selectedAddressOneRef.current?.Text;
      searchParams.selected = selectedAddressOneRef.current?.Selected;
    }

    return autocomplete(searchParams).then((options: any[]) => {
      options = options.map((opt) => {
        opt.isSecondary = true;
        return opt;
      });
      setOpen(options.length > 0);
      return options;
    });
  };

  const onPropertySelected = (newValue: AutocompleteResponse | string) => {
    setOpen(false);
    setNotDefault?.();

    if (typeof newValue === "string") {
      setValue(name, newValue);
      onAutocompleteSelected?.();
      return;
    }

    if (typeof newValue !== "object") {
      return;
    } else {
      selectedAddressOneRef.current = newValue;
      /* If the selected address has 'Selected' property, keep Property Address Line 1 autocomplete
         dropdown open and load the seondary options in the dropdown.
      */
      if (newValue.Selected) {
        console.log("alpha");
        secondaryListOpenRef.current = true;
        setValue(name, `${newValue.Street} ${newValue.Secondary}`);
        onAutocompleteSelected?.();
        return;
      }
    }

    if (!newValue) {
      setValue(name, "");
      onAutocompleteSelected?.();
    } else {
      setOpen(false);
      newValue.Secondary && setValue(addressTwoFieldName, newValue.Secondary);
      setValue(name, newValue.Street ?? newValue, {
        shouldValidate: true,
      });
      onAutocompleteSelected?.();
      newValue.City &&
        setValue(cityFieldName, newValue.City, {
          shouldValidate: true,
        });

      if (newValue.State) {
        // Disallow setting of state field on property if products are issued.
        if (!isPropertySection || !getValues("hasIssuedProducts")) {
          const stateValue = getStateValue(newValue.State);
          setValue(stateFieldName, stateValue, {
            shouldValidate: true,
          });
          onAutocompleteSelected?.();

          //TODO: This might require us to refactor this control to have a
          //PropertySection version to avoid redraws in parties and letter
          //products
          if (isPropertySection) {
            setFilePropertyState(stateValue.abbr, stateValue.code);
          }
        }
      }

      if (newValue.ZipCode !== "") {
        setValue(zipCodeFieldName, newValue.ZipCode, { shouldValidate: true });
        onAutocompleteSelected?.();
        verifyAddressAsync(newValue);
      }
    }
  };

  const verifyAddressAsync = async (value: AutocompleteResponse) => {
    openAutomaticProgressDialog();
    const code = await verifyAddress({
      address1: value?.Street ?? "",
      address2: "",
      city: value?.City ?? "",
      state: value?.State ?? "",
      zipCode: value?.ZipCode ?? "",
    });
    closeAutomaticProgressDialog();
    let zip = code ? code : value?.ZipCode;

    if (zip) {
      verifyAddressHandler && verifyAddressHandler(zip);
      setValue(zipCodeFieldName, zip, { shouldValidate: true });
    }
    if (schema) {
      const county = getValues(`${schema}.county`);
      elementFocusRef.current = document.getElementById(`${schema}.propertyType`);
      if (!county?.code) {
        elementFocusRef.current = document.getElementById(`${schema}.county`);
      }
      goElementFocus(elementFocusRef);
    }
  };

  const getOptionSelected = (
    option: AutocompleteResponse,
    value: AutocompleteResponse
  ) => option.Street === value.Street;

  const getOptionLabel = (option: any) => option.Street || option;

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutocompleteResponse) => {
    return (
      <StyledOption {...props}>
        {option.Text}{" "}
        {option.Selected && <ArrowForwardIosIcon />}
      </StyledOption>
    );
  };

  return (
    <AutocompleteField
      debounceTime={500}
      {...rest}
      open={open}
      label={`${labelPrefix}Address Line 1`}
      name={name}
      disabled={isDisabled}
      onGetOptions={onGetProperties}
      onValueChanged={onPropertySelected}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
    />
  );
};

export default AddressOneField;
