interface BusinessSolutionItem {
  title: string;
  description: string[] | Record<string, any>;
}

interface BusinessSolutionButtons {
  text: string;
  link: string;
}

interface BusinessSolutionAdditionalFooter {
  tittle: string;
  description: string[];
}

interface BusinessSolutionTableInfo {
  columns: string[];
  tiers: BusinessSolutionTableTier[];
}

interface BusinessSolutionTableTier {
  id: Number;
  courseName: string;
  price: string;
  memberPrice: string;
  discountedRate: string;
  SDiscountedRate: string;
}

interface BusinessSolution {
  id: string;
  heading1: string;
  heading2?: string;
  heading3?: string[];
  items?: BusinessSolutionItem[];
  addtionalInfo?: {
    data: string[];
  };
  additionalFooter?: BusinessSolutionAdditionalFooter[];
  buttons?: BusinessSolutionButtons;
  tableInfo?: BusinessSolutionTableInfo;
}

export const businessSolutionData: BusinessSolution[] = [
  {
    id: "ASK Services",
    heading1:
      "Outsource Your Title Policy Production with Stewart and ASK Services",
    heading2:
      "As a title agent your days are busy, your schedules are full and your tasks are everchanging. That’s why it’s important to have a title partner that provides the tools and resources that help make doing business easier. ASK Services is Stewart’s own innovative public record research firm that provides title policy production services.",
    heading3: [],
    items: [
      {
        title: "How ASK Helps Your Business",
        description: [
          "Keeps you current on policy typing despite closing volume fluctuation",
          "Moves policy typing from a fixed cost model to a variable cost model",
          "Allows you to spend time on things that drive your business forward",
        ],
      },
      {
        title: "The sign-up process is as easy as:",
        description: [
          "Reviewing of your process for producing policies. Observed via screen share or share job-aid.",
          "Getting finalized price quote per policy. This typically ranges from $10-$18, depending on the timing.*",
          "Receiving credentials for client’s Title Production System (TPS) and performing a few tests to ensure alignment and quality.",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "<strong>Let Stewart handle your policy production tasks, so you can focus your staff’s time and energy on the tasks that help differentiate you from the competition.</strong>",
        "<small>*Plus access fees if applicable.</small>",
        "<strong>For additional information email <a href='mailto:sales@ask-services.com' target='_contact your Stewart'>sales@ask-services.com </a> or contact your Stewart Agency Representative.</strong>",
      ],
    },
  },
  {
    id: "Background Checks",
    heading1: "Background Checks",
    heading2:
      "Business Information Group (BIG) is a leading screening service provider that works with some of the world's largest insurance, securities and financial services firms to provide standard background checks, occupational health screenings and compliance evaluations.",
    heading3: [
      "At Stewart we use BIG as part of our ongoing agency due diligence and risk mitigation program. In partnership with BIG, we've developed a program specifically for you. Take advantage of BIG's online portal to request background checks on applicants and employees alike while protecting your company from financial risk. Each request is only $31* per name searched and includes:",
    ],
    items: [
      {
        title: "",
        description: [
          "Social Security Number Validation (verifies valid SSN, place and issue data, death index search)",
          "ActionsBase (proprietary search covering terrorist watch lists; OFAC; disciplinary/administrative actions from numerous financial regulators including FINRA, SEC, NYSE OTS and more)",
          "Analyzed TransUnion Credit/Financial History (includes analyzed SSN info, financial data, tax liens, bankruptcies, copy of credit file)",
          "County Criminal Records Search(search conducted in county of primary residence)",
          "Federal Criminal Records Search(search conducted in district of primary residence)",
          "Insurance License Verification/Disciplinary Search (search conducted in state of primary residence)",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "To get started, visit <a href=' https://applicationstation.bigreport.com/signup' target='blank'>https://applicationstation.bigreport.com/signup </a> . Enter Application Station Code \"StewartTrustedProvider\" when prompted.",
        "If you have any questions about the program, please contact your  <a href='https://www.stewart.com/en/customer-type/title-agents/contact-us/agency-services-contacts.html' target='_agency'>Stewart Agency Services representative</a> or email  <a href='mailto:investigations@stewart.com'>investigations@stewart.com</a>. ",
        "* Additional fees may apply due to additional court fees assessed by various jurisdictions. <strong> A required one-time $100 on-site inspection fee will also be applied when signing up for BIG services.</strong>",
        "<strong>&nbsp Debra W. Ferguson</strong> -<small>&nbsp Advance Title & Abstract Inc.</small> <br/> “ We have used Business Information Group (BIG) to run employee background checks for the past eight months. Their turnaround time is great, and the people are very friendly and professional. Stewart partnered with a great company. ”",
      ],
    },
  },
  {
    id: "Car Rental",
    heading1: "Car Rental",
    heading2:
      "Stewart currently offers amazing discounts via Avis and Budget. <a href='https://www.stewart.com/content/dam/stewart/stewartpreferred/Avis-Budget_Preferred_Overview.pdf' target='blank' download> Download our PDF </a> for details regarding these offers.",
    items: [],
  },
  {
    id: "Insurance Services",
    heading1: "Insurance Services",
    heading2:
      "Thanks to Stewart Insurance and Risk Management agents now have access to the following lines of insurance coverage designed to protect your business and your customers lifestyle:",
    items: [
      {
        title: "",
        description: [
          "Personal coverage, including homeowner's, flood, auto, life, RV and umbrella",
          "Home warranty",
          "Natural Hazard Disclosure (CA only)",
          "Errors and omissions",
          "Bonds",
          "Commercial",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "We work with a variety of carriers to ensure competitive prices. Stewart Insurance and Risk Management will continue to offer Title Industry Assurance Company when their offerings provide the best value to our customers. And existing SureClose® users may even qualify for a five-percent discount on Errors & Omissions policy premiums. Fast quotes, a 24/7 claim reporting hotline and convenient payment options are just a few more of the benefits you and your customers will receive when you partner with Stewart Insurance and Risk Management. Contact us today at (866) 845-4626 or visit us online at  <a href='https://www.stewart.com/en/what-we-offer/insurance.html' target='blank'> www.stewart.com/en/stewart-insurance.html </a>",
      ],
    },
  },
  {
    id: "Mobility Market Intelligence",
    heading1:
      "Access Real-Time Transaction Data You Can Trust With Mobility Market Intelligence",
    heading2:
      "As a Stewart Trusted Provider™, you can enjoy the next level in transaction data access with Mobility Market Intelligence (MMI). MMI provides title agencies with real-time, credible mortgage transaction data. By plugging into an unrivaled database of comprehensive production information, you can instantly access historical real estate and mortgage transaction data.<br/><br/>With speed and accuracy, MMI helps you:",
    items: [
      {
        title: "",
        description: [
          "Learn more about list-side and buy-side real estate agent/title office relationships",
          "Know which real estate agents and loan officers your competition works with",
          "Target new business with same-day listing alerts from your network",
          "Analyze current real estate agents wallet share to spot growth opportunities",
          "Target new agent relationships based on location and deal volume",
          "Perform competitive analysis with data from thousands of title companies",
        ],
      },
      {
        title: "Quick delivery",
        description: [
          "MMI provides fast data turnaround – some real estate agents listings can even arrive in your inbox the same day.",
        ],
      },
      {
        title: "National coverage of all title insurance data",
        description: [
          "With 95% coverage of U.S. title data, you will gain quick insight into transactions across the country.",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "<strong>MMI is the competitive advantage title agents need in a challenging market. Discover how MMI can help you grow your title business and take advantage of a special rate for Stewart Trusted Providers.</strong>",
      ],
    },
    buttons: {
      text: "Learn more",
      link: "https://mmi.io/stewart/",
    },
  },
  {
    id: "Secure Email Encryption",
    heading1:
      "Protect your business by protecting your customers' most important information.",
    heading2:
      "Zix is offering Secure Email Encryption services to Stewart independent agencies at a discounted rate.",
    heading3: [
      "In today’s ever-changing regulatory environment, it's important to protect your customers – and your business – by ensuring the security of their non-public personal information (NPI).",
    ],
    items: [],
    addtionalInfo: {
      data: [
        "<strong>ZixMail for Stewart Independent Agencies </strong>",
        "As a Stewart policy-issuing agency, Virtual Graffiti, a partner of ZixCorp®, brings you ZixMail®, an innovative secure email solution that helps agencies like yours maintain a privacy and information security program that complies with federal law and the American Land Title Association® Best Practices.<br/><br/>ZixMail provides two convenient email solutions – integration with Microsoft® Outlook®, or as a standalone application on your desktop for use with any email provider. To view a short demonstration of the ZixMail solutions as well as a demo on what your customer will experience when receiving an email from your agency, click on the option below that best meets your agency’s needs.",
        "<strong>User Experience Demos</strong>",
        "Sometimes it's just easier to see how things work for yourself. We've put together a step by step video for both receiving and sending encrypted messages with ZixMail. We also have <a href='https://www.securemailencryption.com/ZixMail-Trial.asp' target='blank'> free 30 day trials of ZixMail, click here!</a>",
        "<button style='background: linear-gradient(#0075AA, #004D82); color: #FFFFFF; border: none; padding: 10px 20px; margin-right: 10px; text-decoration: none; display: inline-block; box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12); border-radius: 4px;'><a href='https://www.securemailencryption.com/ZixMail-Video-Send.asp' target='_blank' style='color: #FFFFFF; text-decoration: none;'>Sending an Encrypted Email</a></button> <button style='background: linear-gradient(#0075AA, #004D82); color: #FFFFFF; border: none; padding: 10px 20px; text-decoration: none; display: inline-block; box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12); border-radius: 4px;'><a href='https://www.securemailencryption.com/ZixMail-Video-Receive.asp' target='_blank' style='color: #FFFFFF; text-decoration: none;'>Receiving an Encrypted Email</a></button>",
        "<strong><em>Zix is offering this valuable service to Stewart independent agencies at a 40% discount off the standard cost. Simply pay $75.00 per email address, per year, for a contract term of three years, to protect your customers’ NPI and maintain your agency’s data security. There is a 3 user minimum.</em></strong>",
        "",
      ],
    },
    additionalFooter: [
      {
        tittle: "ZixCorp ZixMail, Standalone Licenses ",
        description: [
          "<p>ZixCorp ZixMail, Single User License, 3 Year (3 - 100 Users)</p> <p>*Price per license, paid annually. Quantity must be 3 or greater</p>",
          "<p>#101002-ST-03</p> <p>Our Price: $75.00</p>",
          "Add To Cart",
        ],
      },
    ],
  },
  {
    id: "Title Industry Training",
    heading1:
      "Stewart Title Guaranty (STG) is proud to sponsor The Land Title Institute's Correspondence Courses through the Agent Plan.",
    heading2:
      "The Agent Plan enables Stewart to provide a discount on ALTA Land Title Institute courses to our independent agency network.",
    heading3: [
      "Stewart’s independent agents are eligible for a 50% discount depending on the agent’s membership status with ALTA. ALTA members can enroll in Title 101 or Title 201 for only $99.50 each, or enroll in Ethics in the Title Industry or Escrow Accounting Procedures for only $75 each. Non-ALTA members are still eligible for the 50% discount at the nonmember prices per course.",
    ],
    items: [],
    tableInfo: {
      columns: [
        "Course Name",
        "ALTA Member Price",
        "Stewart Discounted Rate",
        "Non-ALTA Member Price",
        "Stewart Discounted Rate",
      ],
      tiers: [
        {
          id: 1,
          courseName: "Title 101",
          price: "$199.00",
          discountedRate: "$99.50",
          memberPrice: "$299.00",
          SDiscountedRate: "$149.50",
        },
        {
          id: 2,
          courseName: "Title 201",
          price: "$199.00",
          discountedRate: "$99.50",
          memberPrice: "$299.00",
          SDiscountedRate: "$149.50",
        },
        {
          id: 3,
          courseName: "Ethics in the Title Industry ",
          price: "$150.00",
          discountedRate: "$75.00",
          memberPrice: "$225.00",
          SDiscountedRate: "$112.50",
        },
        {
          id: 4,
          courseName: "Escrow Accounting Procedures",
          price: "$150.00",
          discountedRate: "$99.50",
          memberPrice: "$225.00",
          SDiscountedRate: "$112.50",
        },
      ],
    },
    addtionalInfo: {
      data: [
        "For ALTA online course descriptions and registration enrollment, go to <a href='https://www.alta.org/education/online-courses.cfm' target='blank'> https://www.alta.org/education/online-courses.cfm. </a> The general-access discount code for Stewart agents is<strong> Stewart</strong>.",
        "Questions? Please contact the education department at <a href='mailto:education@alta.org.com'>education@alta.org</a>  or call 800-787-2582 for more information.",
      ],
    },
  },
  {
    id: "Video Email Marketing",
    heading1: "Discover the Power of Simple Videos",
    heading2:
      "You already know that people using video in their online marketing are crushing it. What you may not know is that recording and sending video in email is an easy — yet incredibly powerful — way to build relationships and convert more leads. It’s like being there in person when you can’t be there in person.",
    heading3: [
      "<button style='color: #fff; border: none; background: linear-gradient(#0075AA, #004D82); box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);height: 35px; font-size: 14px; font-weight: 500; white-space: nowrap; padding-left: 16px; border-radius: 4px; padding-right: 16px; text-transform: none;'> <a href='https://bombbomb.com/trial/?promo=STEWARTAGENCY' style='color: #fff;' target='blank'>Start your free trial</a> </button>",
    ],
    items: [
      {
        title: "As a Stewart Trusted Provider you get:",
        description: [
          "Unlimited video emails up to 3500 contacts",
          "Automate your follow-up with Automations and video forms",
          "Front-of-the-line phone and email support",
          "Gmail app & Chrome extension",
          "20% off annual price",
          "Mobile apps for iOS & Android devices",
          "Tracking your opportunities with opens, clicks, plays",
          "Group coaching session",
        ],
      },
      {
        title: "How BombBomb Helps Your Business:",
        description: [
          "Easily create and send videos from your computer or mobile device for one-to-one messages or send to your entire list.",
          "Track and analyze email performance and get instant open and play notifications.",
          "Create, manage, and automate both traditional text and video email autoresponders and drip campaigns.",
          "Build business relationships through natural human connection.",
        ],
      },
      {
        title: "*How You Benefit from BombBomb:",
        description: [
          "81% get more email replies",
          "87% increased clicks on their website",
          "68% of customers convert more leads",
          "56% report more referrals",
          "90% stay in touch more effectively",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "*Survey of 576 BombBomb customers who’ve sent at least 10 videos | Conducted July-August 2015 | Results projected to all customers with 95% confidence and +/- 4% margin of error.",
        "Get BombBomb today and see how video email transforms your business. Packages start at only $39 per month* (billed annually).",
        "*Price provided by BombBomb and subject to change without notice. Please see BombBomb website, <a href='https://bombbomb.com/stewartagency' target='blank'> https://bombbomb.com/stewartagency, </a> for current pricing.",
      ],
    },
    buttons: {
      text: "Buy it today",
      link: "https://bombbomb.com/stewartagency/",
    },
  },
  {
    id: "Wireless Devices",
    heading1: "National Wireless Program",
    heading2:
      "As Stewart continues to look for cost saving opportunities for our independent agency network, we have selected Verizon as our national wireless provider. You are free to establish and/or maintain individual agreements with other wireless carriers.",
    heading3: [
      "Verizon Wireless has established the Stewart Independent Agency Program for you to receive the following:",
    ],
    items: [
      {
        title: "",
        description: [
          "19% discount* on monthly service for company lines ",
          "10% discount* on agency employee personal accounts with a signed company agreement",
          "25% discount on eligible wireless accessories and more<br/>○*applicable to monthly rate plans starting at $34.99",
        ],
      },
    ],
    addtionalInfo: {
      data: [
        "Take a moment to review the  <a href='https://www.stewart.com/content/dam/stewart/stewartpreferred/FAQ%20-%20VZW%20Stewart%20Agency%20Program%204-24-09.pdf' target='blank'>Frequently Asked Question (FAQ) </a> document that will answer many questions you may have about the agency agreement with Verizon Wireless.",
        "If your company has an existing Verizon Wireless business account or you'd like to establish a business account with Verizon Wireless, click here. ",
        "Agency employees can receive the 10% personal discount by visiting a Verizon Wireless store with valid proof of agency employment, contact telesales at 800-482-0890 or visit <a href ='https://www.verizon.com/discounts/'target='blank'>verizonwireless.com/discounts.</a>",
        "Should you have any questions, please contact Verizon at <a href='mailto:salessupportstewarttitle@verizonwireless.com'>salessupportstewarttitle@verizonwireless.com. </a>",
      ],
    },
  },
];
