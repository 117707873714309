import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ProgressbarDialog from "controls/global/dialogs/confirmation-dialog-with-progressbar/ProgressbarDialog";
import CommonStewartDialog from "controls/global/dialogs/stewart-dialog/CommonStewartDialog";
import PageSection from "controls/global/page-section/PageSection";
import PdfViewer from "controls/global/pdf-viewer/PdfViewer";
import ScrollToTopArrow from "controls/global/scroll-to-top-arrow";
import { SelectionConfig } from "controls/global/stewart-table/StewartTable";
import { FileSearchCriteria } from "entities/ApiModel";
import { IColumn } from "entities/ApiModel/IColumn";
import { PaymentsFileExportCriteria } from "entities/ApiModel/PaymentsFileExportCriteria";
import { PdfDocument } from "entities/UIModel";
import { FilePaymentSearch } from "entities/UIModel/FilePaymentSearch";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import { ReportAndPayKeywordSearch } from "entities/UIModel/ReportAndPayKeywordSearch";
import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import {
  orderBy as _orderBy,
  debounce,
} from "lodash";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { getColumnSearchField } from "utils/columnsGrid";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useLookup } from "utils/context/LookupContext";
import { usePayment } from "utils/context/PaymentContext";
import { useReportPay } from "utils/context/ReportPayContext";
import { useUser } from "utils/context/UserContext";
import useDidMountEffect from "utils/custom-hooks/useDidMountEffect";
import useTitle from "utils/custom-hooks/useTitle";
import {
  AgencyStatus,
  DateTypeCode,
  FileStatusType,
  PaymentsFileExportCriteriaType,
  SessionStorageKeys,
} from "utils/data/enum";
import { Order } from "utils/sorting";
import { v4 as uuidv4 } from "uuid";
import FileExportDialog from "./components/file-export-dialog";
import ReportPayGrid from "./components/reportpay-grid/ReportPayGrid";
import ReportPayPageHeader from "./components/reportpay-header/ReportPayPageHeader";

const DefaultPage = 1;
const TotalRows = 100;
const NoRecordsMessage = "Loading...";
const TimeoutMessage = "Sorry, this search took too long. Please modify your search criteria and try again.";
const DefaultStatuses = [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending];
const defaultSortColumn = "-firstJacketIssueDate";
const defaultOrderBy = "firstJacketIssueDate";
const defaultOrder = "desc";
const companyStatus = [AgencyStatus.Active, AgencyStatus.Inactive]

const ReportPayPage = () => {
  useTitle("Stewart Connect - Report & Pay");

  const [
    {
      error: requestError,
      progressPercent: loadingProgressPercent,
      refreshData,
      onlyShowSelectedFiles,
      isAdvSearchOpen,
      selectedItems,
    },
    {
      getReportPayList,
      getReportPayListLocal,
      getInitialColumnsDefintion,
      getColumnsDefinition,
      setColumnDefinition,
      getAdvancedSearchFiles,
      cancelRequests,
      setRequestTimeoutMs,
      updateSelectedItem,
      updateSelectedItems,
      setOnlyShowSelectedFiles,
      setIsAdvSearchOpen,
    }
  ] = useReportPay();

  const defaultReportAndPayCriteriaSearch: ReportAndPayCriteriaSearch = {
    listCount: TotalRows,
    sortColumn: defaultSortColumn,
  };
  const [, { exportFiles }] = usePayment();
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [reportPayList, setReportPayList] = useState<ReportPayFile[]>([]);
  const [filters, setFilters] = useState<ReportAndPayCriteriaSearch>(defaultReportAndPayCriteriaSearch);
  const [noRecordsMessage, setNoRecordsMessage] = useState<string[]>([NoRecordsMessage]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState<IColumn[]>(getInitialColumnsDefintion(false));
  const [hiddenColumns, setHiddenColumns] = useState<(keyof ReportPayFile)[]>(getInitialColumnsDefintion(true));
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [partialFilterUpdate, setPartialFilterUpdate] = useState<boolean>(false);
  const [readyToSearch, setReadyToSearch] = useState<boolean>(false);
  const [userInputDebounceTimeMs, setUserInputDebounceTimeMs] = useState<number>(750);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof ReportPayFile | undefined>(defaultOrderBy);
  const [statuses, setStatuses] = useState<FileStatusType[]>(DefaultStatuses);
  const { generalConfig } = useConfigContext();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState<PdfDocument[]>([]);
  const [exportedColumns, setExportedColumns] = useState<string[]>([]);

  const storedCriteriaString = sessionStorage.getItem(SessionStorageKeys.REPORT_PAY_STORED_CRITERIA);
  const storedCriteria = storedCriteriaString ? JSON.parse(storedCriteriaString) : null;
  const usePriorSearchCriteria = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
  const usePriorSearchCriteriaAdvanceSearch = sessionStorage.getItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
  const defaultValues =
    (storedCriteria && usePriorSearchCriteria) || (storedCriteria && usePriorSearchCriteriaAdvanceSearch)
      ? storedCriteria
      : null;
  const defaultRequestedPage = defaultValues ? storedCriteria["requestedPage"] : DefaultPage;
  const [page, setPage] = useState<number>(defaultRequestedPage);
  const selectedStoredFiles = sessionStorage.getItem(SessionStorageKeys.SELECTED_FILES_REPORT_PAY);
  const parsedStoredFiles: ReportPayFile[] | null = selectedStoredFiles ? JSON.parse(selectedStoredFiles) : null;
  const storedSelectedItems = (parsedStoredFiles && usePriorSearchCriteria) ? parsedStoredFiles : selectedItems;

  const [,
    {
      getRowsPerPageSetting,
      setRowsPerPageSetting,
    }
  ] = useUser();
  const [, { getReportOptionTypes }] = useLookup();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();
  const globalAccessAgencyID = globalAccessAgency?.[0]?.CompanyID;
  const globalAccessAgencyName = globalAccessAgency?.[0]?.CompanyName;

  const records = reportPayList ?? [];
  const AdvancedStatuses = [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending];

  const loadSettings = async () => {
    const debounceTime = generalConfig.userInputDebounceTimeMs;
    const timeoutMs = generalConfig.filesSearchTimeoutMs;

    if (debounceTime) {
      setUserInputDebounceTimeMs(debounceTime);
    }

    if (timeoutMs) {
      setRequestTimeoutMs(timeoutMs);
    }
  };

  const getDefaultFilters = (advanced: boolean): ReportAndPayCriteriaSearch => {
    const dateType = advanced ? DateTypeCode.IssueDate : undefined;

    return {
      dateTypeCode: dateType,
      listCount: TotalRows,
      requestedPage: 1,
      sortColumn: defaultSortColumn,
    };
  };

  const debounceSetReadyToSearch = useCallback(
    debounce(() => setReadyToSearch(true), userInputDebounceTimeMs)
    , [userInputDebounceTimeMs]
  );

  const getDatabaseSortColumnName = (name: string) => {
    const mappedColumnNames: { [key: string]: string; } = {
      fileSearchId: "FileSearchID",
      fileId: "ClientFileID",
      clientFileId: "ClientFileID",
      agencyId: "AgencyID",
      fileStatusTypeCode: "FileStatusTypeCode",
      transactionTypeCode: "TransactionTypeCode",
      propertyTypeCode: "PropertyTypeCode",
      propertyAddress: "PropertyAddress",
      paymentOrderNumber: "FileID",
      stateAbbr: "StateAbbr",
      openDate: "OpenDate",
      closingDate: "ClosingDate",
      productSerialNumbers: "ProductSerialNumbers",
      locationDisplayName: "LocationDisplayName",
      productNames: "ProductNames",
      user: "User",
      currentRow: "CurrentRow",
      currentPage: "CurrentPage",
      totalPages: "TotalPages",
      totalRows: "TotalRows",
    };
    return mappedColumnNames[name] ?? name;
  };

  const getSearchField = (field: keyof ReportPayFile) =>
    getColumnSearchField<ReportPayFile, ReportAndPayCriteriaSearch>(columns, field);

  const handleRequestSort = async (columnName: string, disableToggling: boolean = false) => {
    await cancelRequests();

    let sortBy = getDatabaseSortColumnName(columnName);
    if (!disableToggling && sortColumn === sortBy) {
      sortBy = `-${sortBy}`;
    }

    setOrder(sortBy.startsWith("-") ? "desc" : "asc");
    setOrderBy(columnName.replace("-", "") as keyof ReportPayFile);
    setSortColumn(sortBy);
  };

  const handleColumnResize = (column: keyof ReportPayFile, newWidth: number) => {
    if (column && newWidth > 0) {
      setColumnDefinition([column], "width", Math.floor(newWidth));
    }
  };

  const canSearch = (): boolean => {
    return settingsLoaded &&
      !partialFilterUpdate &&
      columns?.length > 0 &&
      (filters !== undefined && Object.values(filters).length > 0);
  };

  const handleAdvancedFilterChange = (
    name: keyof ReportAndPayCriteriaSearch,
    value: any,
    shouldSearch: boolean
  ) => {
    if (isAdvSearchOpen) {
      setPartialFilterUpdate(!shouldSearch);
      handleFilterChange(name, value);
    }
  };

  const handleAdvanceSearchPanelOpen = async (open: boolean) => {
    await cancelRequests();

    const resetFilters = () => {
      setOrderBy(defaultOrderBy);
      setOrder(defaultOrder);
      return getDefaultFilters(open);
    };

    setFiltersApplied(false);
    setFilters(resetFilters);
    setSortColumn(undefined);
    setPartialFilterUpdate(false);
    if (!usePriorSearchCriteriaAdvanceSearch) {
      setPage(1);
    }
    setIsAdvSearchOpen(open);

    if (open) {
      setStatuses(AdvancedStatuses);
    }
    else {
      setStatuses(DefaultStatuses);
    }
  };

  const getSearchCriteria = () => {
    let criteria: ReportAndPayCriteriaSearch ={};
    if (usePriorSearchCriteria && storedCriteria) {
      criteria =storedCriteria;
      setFilters(storedCriteria);
      sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA);
      sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
    } else {
        criteria = {
          ...filters,
          listCount: rowsPerPage,
          requestedPage: page,
          ...(!filters.agencyID && globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
      }      
      if (sortColumn) {
        criteria = {
          ...criteria,
          sortColumn: sortColumn,
        };
      }

      for (const key in criteria) {
        const criteriaKey = key as keyof ReportAndPayCriteriaSearch;
        if (criteria[criteriaKey]) {
          criteria[criteriaKey] = convertValue(criteriaKey, criteria[criteriaKey]);
        }
      }

      if (readyToSearch) {
        sessionStorage.setItem(SessionStorageKeys.REPORT_PAY_STORED_CRITERIA, JSON.stringify(criteria));
      }
    }
    return criteria;
  };

  const getAdvancedSearchCriteria = () => {
    let criteria: ReportAndPayCriteriaSearch;
    if (usePriorSearchCriteriaAdvanceSearch && storedCriteria) {
      sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA_ADVANCE_SEARCH);
      sessionStorage.removeItem(SessionStorageKeys.REPORT_PAY_STORED_CRITERIA);
      criteria = storedCriteria;
      setFilters(storedCriteria);
    } else {
      criteria = {
        ...filters,
        listCount: rowsPerPage,
        requestedPage: page,
        ...(globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
      };
      if (sortColumn) {
        criteria = {
          ...criteria,
          sortColumn: sortColumn,
        };
      }
    }
    if (readyToSearch) {
      sessionStorage.setItem(SessionStorageKeys.REPORT_PAY_STORED_CRITERIA, JSON.stringify(criteria));
      sessionStorage.removeItem(SessionStorageKeys.USE_PRIOR_SEARCH_CRITERIA)
    }
    return criteria;
  };

  const visibleColumns = columns
    .filter((c) => c.hideable)
    .map((c) => ({
      field: c.field as keyof ReportPayFile,
      name: c.name,
      checked: !hiddenColumns.includes(c.field as keyof ReportPayFile),
    }));

  const removeInvalidProperties = (object: { [key: string]: any; }) => {
    let deleteCount = 0;
    Object.keys(object).forEach((key) => {

      // Remove null properties and fields that are hidden,
      // with the exception of dateRangeStart which can always be searched even if hidden.
      if (object[key] === null ||
        object[key] === undefined ||
        (!isAdvSearchOpen
          && key !== "dateRangeStart"
          && hiddenColumns.find(c => c === key || getSearchField(c) === key)
        )
      ) {
        delete object[key];
        deleteCount++;

      }
    });

    return { object, deleteCount };
  };

  const convertValue = (name: string, value: any) => {
    const fields = ["totalAmountDue", "totalActualFee", "totalActualRiskRate", "totalActualRetention"];
    if (value !== null && fields.includes(name)) {
      return Number(value);
    }
    return value;
  };

  const handleFilterChange = async (
    name: keyof ReportAndPayCriteriaSearch,
    value: any
  ) => {
    await cancelRequests();
    const formattedValue = (typeof value) === "string" ? value.trim().replaceAll(",", "|") : value;
    const updatedFilters = (filters: ReportAndPayCriteriaSearch | undefined) => {
      let dateType;
      if (name === "dateTypeCode") {
        dateType = value;
      }
      else if (!isAdvSearchOpen && name === "dateRangeStart") {
        handleRequestSort("openedTimestamp", true);
        dateType = DateTypeCode.Created;
      }
      else {
        dateType = filters?.dateTypeCode;
      }

      let adjustedFilters: ReportAndPayCriteriaSearch = {
        ...filters,
        [name]: formattedValue || undefined,
        dateTypeCode: dateType,
      };
      adjustedFilters = removeInvalidProperties(adjustedFilters).object;
      return adjustedFilters;
    };

    setPage(defaultRequestedPage ? defaultRequestedPage : DefaultPage);
    setFiltersApplied(true);
    setFilters(updatedFilters);
  };

  const reloadLocalSearch = () => {
    if (onlyShowSelectedFiles) {
      setPage(DefaultPage);
      performSearch();
    }
  };

  const handleToggleAllRows = async () => {
    if (reportPayList) {
      updateSelectedItems(reportPayList); //(reportPayList.filter((list :ReportPayFile)=>list.companyStatus==="Active"));
      reloadLocalSearch();
    }
  };

  const handleToggleRow = (file: ReportPayFile) => {
    updateSelectedItem(file);
    reloadLocalSearch();
  };

  const selectionConfig: SelectionConfig<ReportPayFile> = {
    enabled: true,
    selectedRows: storedSelectedItems?.map(s => s.fileId.toString()) ?? [],
    onToggleAllRows: handleToggleAllRows,
    onToggleRow: handleToggleRow,
    isRowSelected: (file: ReportPayFile) => storedSelectedItems?.some(f => f.fileId == file.fileId),
  };

  const handleOnlySelectedFilesChange = (value: boolean) => {
    setOnlyShowSelectedFiles(value);
  };

  const getNoRecordsMessage = () => {
    if (!filters) {
      return [NoRecordsMessage];
    }
    let firstMessage = "No Matches Found";
    const secondMessage = "Please adjust your search criteria and search again.";
    if (isAdvSearchOpen) {
      const keyword = (filters as ReportAndPayKeywordSearch).keyword;
      let searchFields: string[] = [];
      keyword && searchFields.push(keyword);
      globalAccessAgencyName && searchFields.push(globalAccessAgencyName);
      if (searchFields) {
        firstMessage = `No matches found for "${searchFields.join(", ")}"`;
      }
    }
    else {
      const keys: (keyof ReportAndPayCriteriaSearch)[] = [
        "clientFileId",
        "propertyAddress",
        "buyerName",
        "agencyID",
        "locationDisplayName",
        "locationLegacyID",
        "agencyName",
        "sellerName",
        "lenderName",
        "productName",
        "stateAbbr",
        "propertyTypeCode",
        "transactionTypeCode",
        "totalActualRiskRate",
        "totalActualFee",
        "totalActualRetention",
        "totalAmountDue",
      ];
      const providedFields = keys
        .map(key => filters[key])
        .filter(value => !!value && value !== globalAccessAgencyID);

      if (!filters.agencyID && globalAccessAgencyName && !providedFields.includes(globalAccessAgencyName)) {
        providedFields.push(globalAccessAgencyName);
      }

      if (providedFields.length) {
        firstMessage = `No matches found for "${providedFields.join(", ")}"`;
      }
    }
    return [firstMessage, secondMessage];
  };

  const performSearch = async () => {
    let files: ReportPayFile[] | undefined = [];

    if (isAdvSearchOpen) {
      const criteria = getAdvancedSearchCriteria();
      files = await getAdvancedSearchFiles({ ...criteria });
    } else {
      const criteria = getSearchCriteria();
      if (onlyShowSelectedFiles) {
        files = await getReportPayListLocal({ ...criteria });
      } else {
        files = await getReportPayList({ ...criteria });
      }
    }

    if (files !== undefined) {
      setReportPayList(files);
      if (!files?.length) {
        setNoRecordsMessage(getNoRecordsMessage());
        setPage(1);
      }
    }
  };

  const handleRowsPerPageChange = async (count: number) => {
    await cancelRequests();
    setRowsPerPageSetting(count);
    setRowsPerPage(count);
    setPartialFilterUpdate(false);
    setPage(DefaultPage);
  };

  const handleShowPaymentSheetPdf = (pdfUrl: string) => {
    let pdfs: PdfDocument[] = [];
    if (pdfUrl) {
      pdfs.push({
        fileId: 0,
        productType: "",
        orderId: 0,
        documentId: 0,
        pdfUrl: pdfUrl,
        fileName: `Payment Sheet ${pdfUrl.split("?")[0].split("/")?.pop() ?? ""}`,
        showHidden: 0,
      });
    }
    setPdfDocuments(pdfs);
    setOpenPdfViewer(pdfs.length > 0);
  };

  const handleExport = async (allFiles: boolean, itemizeProducts: boolean) => {
    const requestId = uuidv4();

    let searchCriteria: FilePaymentSearch = {};
    if (allFiles) {
      searchCriteria = isAdvSearchOpen ? getAdvancedSearchCriteria() : getSearchCriteria();
      searchCriteria = {
        ...searchCriteria,
        criteriaType: isAdvSearchOpen
          ? PaymentsFileExportCriteriaType.ReportAndPayKeyword
          : PaymentsFileExportCriteriaType.ReportAndPayCriteria,
        ...(globalAccessAgencyID && { agencyID: globalAccessAgencyID }),
      };
    }
    else {
      searchCriteria.criteriaType = PaymentsFileExportCriteriaType.ReportAndPayCriteria;
    }

    const exportCriteria: PaymentsFileExportCriteria = {
      FileIds: allFiles
        ? []
        : storedSelectedItems.map(s => s.fileId),
      ExportColumns: [
        "clientFileId",
        ...exportedColumns.filter(c => c !== "clientFileId" && c !== "totalAmountDue"),
        "totalAmountDue",
      ],
      ItemizeEachProduct: itemizeProducts,
      SearchCriteria: searchCriteria,
    };

    await exportFiles(exportCriteria, requestId);

    setOpenExportDialog(false);
  };

  const handleColumnsModified = (columns: IColumn[]) => {
    const exportedCols = columns.filter(c => !hiddenColumns.includes(c.field as keyof ReportPayFile))
      .map(c => c.field);
    setExportedColumns(exportedCols);
  };

  const handleUpdateHiddenColumns = (columns: (keyof ReportPayFile)[]) => {
    setHiddenColumns(columns);
  };

  useEffect(() => {
    const exportedCols = _orderBy(columns.filter(c => !hiddenColumns.includes(c.field as keyof ReportPayFile)), "position")
      .map(c => c.field);

    setExportedColumns(exportedCols);
  }, [columns]);

  useEffect(() => {
    if (!defaultValues) setPage(DefaultPage);
  }, [onlyShowSelectedFiles]);

  useEffect(() => {
    if (requestError === "TIMEOUT") {
      setNoRecordsMessage([TimeoutMessage]);
      setReportPayList([]);
    }
  }, [requestError]);

  useEffect(() => {
    if (readyToSearch) {
      const search = async () => {
        await performSearch();
        setReadyToSearch(false);
      };

      search();
    }
  }, [readyToSearch]);

  useEffect(() => {
    const init = async () => {      
      setIsAdvSearchOpen(false);
      await loadSettings();
      await getReportOptionTypes();
      setSettingsLoaded(true);
    };

    init();
  }, []);

  useEffect(() => {
    let sortColumnChanged = false;
    if (sortColumn) {
      const sortColumnName = sortColumn.replace("-", "");
      hiddenColumns.forEach((c) => {
        const mappedName = getDatabaseSortColumnName(c);
        if (mappedName === sortColumnName) {
          setSortColumn(undefined);
          sortColumnChanged = true;
          return;
        }
      });
    }

    const updatedFilters = removeInvalidProperties({ ...filters });
    if (sortColumnChanged || updatedFilters.deleteCount || reportPayList === undefined) {
      setFilters((filters: FileSearchCriteria | undefined) => updatedFilters.object);
    }
  }, [hiddenColumns]);

  useEffect(() => {
    const getColumns = async () => {
      const { gridColumns, gridHiddenColumns } = await getColumnsDefinition();
      setColumns(gridColumns);
      setHiddenColumns(gridHiddenColumns);
    };
    getColumns();
  }, []);

  useEffect(() => {
    if (canSearch()) {
      if (partialFilterUpdate) {
        setPartialFilterUpdate(false);
      }

      debounceSetReadyToSearch();
    }
  }, [
    columns,
    filters,
    filtersApplied,
    page,
    partialFilterUpdate,
    refreshData,
    rowsPerPage,
    settingsLoaded,
    sortColumn,
    globalAccessAgencyName,
  ]);

  useEffect(() => {
    const getCount = async () => {
      const count = await getRowsPerPageSetting();
      setRowsPerPage(count);
    };

    getCount();
  }, [getRowsPerPageSetting]);

  useEffect(() => {
    handleColumnsModified(columns);
  }, [hiddenColumns]);

  useDidMountEffect(() => {
    performSearch();
  }, [onlyShowSelectedFiles]);

  useEffect(() => {
    if (!usePriorSearchCriteria) sessionStorage.setItem(SessionStorageKeys.SELECTED_FILES_REPORT_PAY, JSON.stringify(storedSelectedItems));
  }, [storedSelectedItems])

  return (
    <>
      <PageSection
        icon={
          <MonetizationOnIcon />
        }
        title="Report & Pay"
        isError={false}
        contentAlignment="beside-header"
      >
        <>
          <ReportPayPageHeader
            columns={visibleColumns}
            hiddenColumns={hiddenColumns}
            updateHiddenColumns={handleUpdateHiddenColumns}
            handleAdvancedFilterChange={handleAdvancedFilterChange}
            handleAdvanceSearchPanelOpen={handleAdvanceSearchPanelOpen}
            setOpenExportDialog={setOpenExportDialog}
          />
          <ReportPayGrid
            colsConfig={columns}
            rows={records}
            loadingProgressPercent={loadingProgressPercent}
            noRowsMessage={noRecordsMessage}
            page={page}
            hiddenColumns={hiddenColumns}
            fileStatusTypes={statuses}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            currentFilter={filters}
            selectionConfig={selectionConfig}
            isAdvSearchOpen={isAdvSearchOpen}
            onFiltersChange={handleFilterChange}
            onOnlySelectedFilesChange={handleOnlySelectedFilesChange}
            onlyShowSelectedFiles={onlyShowSelectedFiles}
            onRowsPerPageChange={handleRowsPerPageChange}
            onShowPdf={handleShowPaymentSheetPdf}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onColumnResize={handleColumnResize}
            onColumnsModified={handleColumnsModified}
            defaultValues={storedCriteria}
            companyStatus={companyStatus}
          />
          <FileExportDialog
            open={openExportDialog}
            onCancel={() => setOpenExportDialog(false)}
            onExport={handleExport}
            showSelectionPrompt={storedSelectedItems?.some(s => s)}
            selectionPrompt="Would you like to include the selected file(s) only or all reportable files?"
            showItemizedPrompt={true}
            itemizedPrompt="Would you like this export to display itemized products?"
          />
        </>
      </PageSection >
      <PdfViewer
        isOpen={openPdfViewer}
        onClose={() => setOpenPdfViewer(false)}
        pdfDocuments={pdfDocuments}
      />
      <CommonStewartDialog />
      <ProgressbarDialog />
      <ScrollToTopArrow refreshSize={records} />
    </>
  );
};

export default ReportPayPage;
