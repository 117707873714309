import React from "react";
import Link, { 
  LinkBaseProps 
} from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { 
  colors, 
  fontWeight 
} from "theme/defaultStyle";

const StyledLink = styled(Link)({
  color: colors.blue01,
  fontWeight: fontWeight.bold2,
  cursor: "pointer",
  textDecoration: "underline",
});

const StewartLink = (props: LinkBaseProps) => {
  return (
    <StyledLink {...props} />
  )
}

export default StewartLink;