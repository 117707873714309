import {
  Checkbox,
  CheckboxProps,
  styled,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import {
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";

interface Props extends CheckboxProps {
  label?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  checked: boolean | undefined;
  styledWrapper?: boolean;
  hidden?: boolean;
  className?: string;
}

type CheckboxWrapperProps = {
  hidden?: boolean;
  disabled?: boolean;
};

const CheckboxWrapper = styled("div")<CheckboxWrapperProps>(({ hidden, disabled }) => ({
  "&.checkboxWrapper": {
    alignItems: "center",
    display: hidden ? "none" : "flex",
    "& label": {
      color: disabled ? colors.grey11 : "inherit",
      fontSize: fontSize.large,
      paddingTop: padding.small2,
      whiteSpace: "nowrap",
    }
  },
}));

const CheckboxInput = (props: Props) => {
  const {
    label,
    onChange,
    name,
    checked,
    disabled,
    className,
    styledWrapper = true,
    hidden = false,
  } = props;

  return (
    <CheckboxWrapper
      hidden={hidden}
      disabled={disabled}
      className={styledWrapper ? "checkboxWrapper" : ""}
    >
      <Checkbox
        id={name}
        className={clsx(className)}
        disabled={disabled}
        checked={!!checked}
        {...{ name, onChange, hidden }}
      />
      <label htmlFor={name}>
        {label}
      </label>
    </CheckboxWrapper>
  );
};

export default CheckboxInput;
