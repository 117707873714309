import CheckIcon from "@mui/icons-material/Check";
import {
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import React from "react";


type Props = {
  isOpen: boolean;
  endorsementName: string;
  onSubmit: () => void;
  onCancel: () => void;
};

const VoidEndorsementForm = ({
  isOpen,
  endorsementName,
  onSubmit,
  onCancel,
}: Props) => {
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StewartDialog
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <StewartDialogTitle type={"INFO"}>
        Void Endorsement
        <DialogTitleCloseButton onClick={onCancel}/>
      </StewartDialogTitle>
      <StewartDialogContent>
        <Typography gutterBottom>
          The following endorsement will be voided:
        </Typography>
        <Typography gutterBottom>
          {endorsementName}
        </Typography>
      </StewartDialogContent>
      <StewartDialogActions>
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          startIcon={<CheckIcon />}
        >
          Submit
        </ActionButton>
      </StewartDialogActions>
    </StewartDialog>
  );
};

export default VoidEndorsementForm;
