import React from "react";
import { useLookup } from "utils/context/LookupContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useAdditionalPartySearchAction } from "utils/context/AdditionalPartySearchContext";
import SelectFieldWithClearIcon from "controls/global/select-field/SelectFieldWithClearIcon";

type Props = {
  name: string;
  disabled: boolean;
};

export default function State({ name, disabled }: Props) {
  const { setValue } = useFormWrapper();
  const [, { update }] = useAdditionalPartySearchAction();
  const [{ states }] = useLookup();
  const statesWithDefaultOption = [{ text: "", value: "" }, ...states];

  const handleChange = (_: any, { abbr, code }: any) => {
    update("state", abbr);
    setValue(name, {
      abbr,
      code,
    });
  };

  return (
    <SelectFieldWithClearIcon
      label="State"
      name={name}
      options={statesWithDefaultOption}
      autosetValue={false}
      onChange={handleChange}
      dataTextField="abbr"
      dataValueField="code"
      disabled={disabled}
      defaultValue={{ text: "", value: "" }}
    />
  );
}
