import { styled } from "@mui/material";
import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { uniqBy } from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useFormContext,
  useWatch,
} from "react-hook-form";
import { margin } from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";

const FormField = styled("div")({
  marginTop: margin.xlarge,
});

const FIELD_NAME = "state";

const AgencyStateField = () => {
  const [availableStates, setAvailableStates] = useState<SelectFieldOption[]>([]);
  const [{ agencyLocationsByImage }] = useSignatureDefaults();
  const { setValue, getValues } = useFormContext();
  const [agencyField] = useWatch({
    name: ["agency"],
  });

  useEffect(() => {
    const agency = agencyField;
    const allAgencies = (agency === "All");

    let states: SelectFieldOption[] = uniqBy(agencyLocationsByImage
      .filter(s => (allAgencies || s.agencyID === agency)), s => s.stateCode)
      .map((l) => ({
        value: l.stateCode,
        text: l.stateAbbr
      }));

    if (states.length > 1) {
      states = [
        { text: "All States", value: "All" },
        ...states
      ];
    }

    setAvailableStates(states);
  }, [agencyField, agencyLocationsByImage]);


  useEffect(() => {
    const selectedState = getValues(FIELD_NAME).value;
    if (availableStates.length > 0 && availableStates.filter(l => l.value === selectedState).length === 0) {
      setValue(FIELD_NAME, {
        text: availableStates[0].text,
        value: availableStates[0].value,
      });
    }
  }, [availableStates]);

  return (
    <FormField>
      <SelectField
        label="Agency State"
        name={FIELD_NAME}
        options={availableStates}
        optionsValueField="value"
        optionsTextField="text"
        dataValueField="value"
        dataTextField="text"
        disabled={availableStates.length < 1}
      />
    </FormField>
  );
};

export default AgencyStateField;
