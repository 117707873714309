import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button/ActionButton";
import React from "react";
import { digitalSolutionsData } from "../data/digitalSolutionsData";
import { useParams } from "react-router-dom";
import {
  Box,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import {
  Table,
  TableCell,
  TableRow ,
} from "@mui/material";
import {
  borderRadius,
  boxShadows,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  gaps,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import useTitle from "utils/custom-hooks/useTitle";

interface Tier {
  tier: string;
  users: string;
  keyServices: string;
  annualPlatformFee: string;
  annualPlatformFeeWithDiscount: string;
  transactionFee: string;
}

const StyledBoxContainer = styled(Box)({
  borderRadius: borderRadius.xlarge,
  backgroundColor: colors.white,
  fontFamily: fontFamily.primary,
  padding: padding.medium2,
  paddingLeft: padding.xlarge1,
  paddingBottom: padding.xlarge2,
  "& a": {
    color: colors.blue01,
    textDecoration: "none",
  },
});

const PrimaryHeading = styled(Typography)({
  color: colors.maroon02,
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge6,
  fontWeight: fontWeight.bold2,
  lineHeight: lineHeight.xxlarge,
  padding: padding.xlarge,
  paddingLeft: padding.small1,
  textAlign: "left",
});

const ButtonStyles = styled(ActionButton)({
  paddingTop: padding.small2,
  paddingLeft: padding.small1,
});

const SecondaryHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  padding: padding.small1,
  whiteSpace: "pre-wrap",
});

const NestedHeading = styled("li")({
  fontWeight: fontWeight.bold1,
  fontSize: fontSize.medium,
  listStyleType: "disc",
  marginLeft: margin.xlarge2,
  paddingLeft: padding.zero,
  padding: padding.small1,
});
const ThirdHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  padding: padding.small1,
});

const ListContainer = styled("ul")({
  paddingLeft: padding.zero,
});

const ListItem = styled("li")({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  listStyleType: "disc",
  marginBottom: margin.small2,
  marginLeft: margin.xlarge2,
});

const NestedListItem = styled("li")({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  listStyleType: "circle",
  marginBottom: margin.small2,
  marginLeft: margin.xlarge2,
});

const VideoContainer = styled("div")({
  display: "flex",
  gap: gaps.small1,
  padding: padding.large1,
});

const VideoWrapper = styled(Paper)({
  boxShadow: boxShadows.boxShadow02,
  height: "141px",
  padding: padding.xsmall1,
  marginRight: margin.small2,
  width: "262px",
});

const VideoIframe = styled("iframe")({
  height: "100%",
  width: "100%",
});
const VideoTitle = styled(Typography)({
  color: colors.blue01,
  fontWeight: fontWeight.bold1,
  padding: padding.xsmall,
  textAlign: "center",
  textDecorationStyle: "solid",
});

const handleClick = (link: any) => {
  if (link) {
    try {
      const url = new URL(link);
      window.open(url.href, "_blank");
    } catch (error) {
      console.error("Invalid link:", link);
    }
  }
};

const StyledTable = styled(Table)({
  minWidth: 650,
  whiteSpace: "pre-wrap",
});

const BoldTableCell = styled(TableCell)(() => ({
  fontWeight: fontWeight.bold1,
  fontSize: fontSize.medium,
}));

const StyledTableCell = styled(TableCell)({
  fontSize: fontSize.medium,
  whiteSpace: "pre-wrap",
});

const StyledTableRow = styled(TableRow)(() => ({
  "& > *": {
    textAlign: "center",
  },
}));

const StewartPreferredDigitalSolution = () => {
  const { item } = useParams();
  const selectedItem = digitalSolutionsData.find((obj) => obj.id === item);
  useTitle(selectedItem?.id || "Stewart Preferred");

  if (!selectedItem) {
    return <div>Item not found</div>;
  }
  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <StyledBoxContainer>
      <PrimaryHeading variant="h3">{selectedItem.heading1}</PrimaryHeading>
      <SecondaryHeading>{selectedItem.heading2}</SecondaryHeading>
      {selectedItem.heading3 &&
        selectedItem.heading3.map((item, index) => (
          <ThirdHeading
            key={index}
            dangerouslySetInnerHTML={renderHTML(item)}
          />
        ))}
      <ListContainer>
        {selectedItem.items.map((item, i) => (
          <React.Fragment key={i}>
            {typeof item === "string" ? (
              item
            ) : (
              <>
                <SecondaryHeading
                  variant="h6"
                  dangerouslySetInnerHTML={renderHTML(item.title)}
                />
                {Array.isArray(item.description) ? (
                  item.description.map((descriptionItem, index) =>
                    typeof descriptionItem === "object" ? (
                      <div key={index}>
                        {Object.entries(descriptionItem).map(([key, value]) => (
                          <React.Fragment key={key}>
                            <NestedHeading>{key}</NestedHeading>
                            {Array.isArray(value) ? (
                              value.map((nestedItem, nestedIndex) => (
                                <NestedListItem
                                  key={nestedIndex}
                                  dangerouslySetInnerHTML={renderHTML(
                                    nestedItem,
                                  )}
                                />
                              ))
                            ) : (
                              <ListItem>{value}</ListItem>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <ListItem key={index}>{descriptionItem}</ListItem>
                    ),
                  )
                ) : (
                  <div>
                    {Object.entries(item.description).map(
                      ([key, value], index) => (
                        <React.Fragment key={index}>
                          <SecondaryHeading variant="h6">
                            {key}
                          </SecondaryHeading>
                          {Array.isArray(value) ? (
                            value.map((nestedItem, nestedIndex) => (
                              <ListItem key={nestedIndex}>
                                {nestedItem}
                              </ListItem>
                            ))
                          ) : (
                            <ListItem>{value}</ListItem>
                          )}
                        </React.Fragment>
                      ),
                    )}
                  </div>
                )}
              </>
            )}
            {item.title === "At the virtual closing table, NotaryCam:" && (
              <ButtonStyles>
                <ActionButton
                  color="primary"
                  variant="outlined"
                  onClick={() => handleClick(selectedItem.sectionButton?.link)}
                >
                  {selectedItem.sectionButton?.text}
                </ActionButton>
              </ButtonStyles>
            )}
          </React.Fragment>
        ))}
      </ListContainer>
      {selectedItem.addtionalInfo &&
        selectedItem.addtionalInfo.data.map((item, i) => (
          <ThirdHeading key={i} dangerouslySetInnerHTML={renderHTML(item)} />
        ))}
      {typeof selectedItem.buttons == "object" && (
        <ButtonStyles>
          <ActionButton
            color="primary"
            variant="outlined"
            onClick={() => handleClick(selectedItem.buttons?.link)}
          >
            {selectedItem.buttons.text}
          </ActionButton>
        </ButtonStyles>
      )}
      {selectedItem.videos && selectedItem.videos.length > 0 && (
        <VideoContainer>
          {selectedItem.videos.map((video, i) => (
            <VideoWrapper key={i}>
              <VideoIframe src={video.video.link} allowFullScreen />
              <VideoTitle>{video.video.title}</VideoTitle>
            </VideoWrapper>
          ))}
        </VideoContainer>
      )}
      {selectedItem?.tableInfo && (
        <>
          <TableContainer>
            <StyledTable>
              <TableHead>
                <StyledTableRow>
                  {selectedItem.tableInfo.columns?.map((column, index) => (
                    <BoldTableCell key={index}>{column} </BoldTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {selectedItem.tableInfo.tiers?.map(
                  (tier: Tier, index: number) => (
                    <StyledTableRow key={index}>
                      {Object.keys(tier).map((key) => (
                        <StyledTableCell key={key}>
                          {tier[key as keyof Tier]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ),
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <ThirdHeading>{selectedItem.tableInfo.data}</ThirdHeading>
          <ButtonStyles>
            <ActionButton color="primary" variant="outlined">
              {selectedItem.tableInfo.buttons?.text}
            </ActionButton>
          </ButtonStyles>
        </>
      )}
    </StyledBoxContainer>
  );
};

export default StewartPreferredDigitalSolution;
