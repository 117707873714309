import { axiosSecuredInstance } from "configurations/axiosConfig";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

interface State {
  error?: unknown;
}

type StoreApi = StoreActionApi<State>;

const Store = createStore<State, any>({
  initialState: {},
  actions: {
    deleteParty:
      (partyId: string, agencyId: string, clientFileId: string) =>
        async ({ setState }: StoreApi) => {
          try {
            await axiosSecuredInstance.delete(
              `/files/fileparty?integrationKey=${partyId}&agencyId=${agencyId}&clientFileId=${encodeURIComponent(
                clientFileId
              )}`
            );
          } catch (error) {
            setState({ error });
          }
        },
  },
  name: "party",
});

const hook = createHook(Store);
export const useParty = () => { return hook(); };
