import CheckIcon from '@mui/icons-material/Check';
import {
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import { TextInput } from "controls/global/text-input-field";
import React from "react";
import { padding } from "theme/defaultStyle";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import VoidReasonsField from "../common/VoidReasonsField";

type Props = {
  productType: string;
  formType?: string;
  form?: string;
  serialNumber: string;
  effectiveDate?: string;
  productIndex: number;
  isOpen: boolean;
  onSubmit: any;
  onCancel: any;
  submitButtonDisabled: boolean;
};

const gridStyle = {
  paddingTop: padding.small2,
  paddingRight: padding.small2,
};

const voidGridStyle = {
  paddingTop: padding.xlarge,
  paddingRight: "20%",
  paddingLeft: "20%",
};

const VoidJacketForm = ({
  productType,
  formType,
  form,
  serialNumber,
  effectiveDate,
  productIndex,
  isOpen,
  onSubmit,
  onCancel,
  submitButtonDisabled,
}: Props) => {
  const [{ voidReasons }] = useVoidReasons();

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StewartDialog
      open={isOpen}
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <StewartDialogTitle type={"INFO"}>
        Void Jacket
        <DialogTitleCloseButton onClick={() => onCancel(false)} />
      </StewartDialogTitle>
      <StewartDialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} style={gridStyle}>
            <TextInput
              label="Type"
              name={formType}
              disabled={true}
              value={formType}
            />
          </Grid>

          <Grid item xs={12} md={6} style={gridStyle}>
            <TextInput
              label="Policy Number"
              name={serialNumber}
              disabled={true}
              value={serialNumber}
            />
          </Grid>

          <Grid item xs={12} md={3} style={gridStyle}>
            <TextInput
              label="Effective Date"
              name={effectiveDate}
              disabled={true}
              value={effectiveDate}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ ...gridStyle, paddingTop: "20px" }}
          >
            <TextInput label="Form" name={form} disabled={true} value={form} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={voidGridStyle}>
            <VoidReasonsField
              voidReasons={voidReasons[productType]}
              productIndex={productIndex}
              productType={productType}
            />
          </Grid>
        </Grid>
      </StewartDialogContent>
      <StewartDialogActions>
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          disabled={submitButtonDisabled}
          startIcon={<CheckIcon />}
        >
          Submit
        </ActionButton>
      </StewartDialogActions>
    </StewartDialog>
  );
};

export default VoidJacketForm;
