import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { 
  SCFile 
} from "entities/UIModel";
import { cloneDeep } from "lodash";
import {
  hasAdditionalParty,
  hasPendingProducts,
} from "pages/file/utils/helper";
import React, {
  useEffect,
  useRef,
} from "react";
import { useWatch } from "react-hook-form";
import { useAgencyStates } from "utils/context/AgencyStatesContext";
import {
  useCompanyContext,
  useCompanyContextActions,
} from "utils/context/CompanyContext";
import { 
  useDuplicateFileCheckActions, 
  useDuplicatedFileCheck ,
} from "utils/context/DuplicatedFileCheckContext";
import { 
  useFiles, 
  useFilesActions,
 } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useModalContext } from "utils/context/ModalContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import useFocus from "utils/custom-hooks/useFocus";
import { AgencyStatus, PERMISSIONS } from "utils/data/enum";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

type Props = {};

const name = "agency";
const AgencyField = (props: Props) => {
  const [idField, fileField, agencyField] = useWatch({
    name: ["id", "fileNameNumber", "agency"],
  });
  const { setValue, getValues, clearErrors, resetField ,setError } = useFormWrapper();
  const [, { resetNonFileSections, resetInitialValues }] = useFilesActions();
  const [{isReadOnly}, {setIsReadOnly}] = useFiles();
  const fileNameValueRef = useRef();
  const [,
    {
      getUniqueFileByAgencyClientFileId,
      setIsDuplicatedFile,
      setShowAlertDuplicatedFile,
      setToggleFileAgencyFocus,
    },
  ] = useDuplicateFileCheckActions();

  const [
    {
      isDuplicatedFile,
      prevFileNameValueState,
      isToggleFileAgencyFocus,
    },
  ] = useDuplicatedFileCheck();

  const [{ acceptedModal }, { setIsModalOpen, setMessage, resetDefaultValues }] = useModalContext();  
  const [{ agencies, error }] = useCompanyContext();
  const [, { getAgencies, updateAgencies }] = useCompanyContextActions();
  const [{ profileSettings }] = useProfileSettingsCache();
  
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);
  
  const hasFileViewAccess = permissionCodes?.includes(PERMISSIONS.FILES_VIEW) && !permissionCodes?.includes(PERMISSIONS.FILES_MODIFY) ;
  const [, { updateAgencyStates }] = useAgencyStates();
  const [{ selectedAgency }] = useGlobalAccess();    
  const { setFocusInputElement } = useFocus();
  const currentAgencyRef = useRef({});
  const [filteredAgencies, setFilteredAgencies] = React.useState<SelectFieldOption[]>([]);

  const agency = agencies.find((a) => a.value === agencyField.id);
  const AgencyFieldName = "agency";
  

  const checkIfDuplicatedFile = React.useCallback(
    async (agency: any) => {      
      let fileId = 0;
      if (agency?.value && fileField) {
        fileId = await getUniqueFileByAgencyClientFileId(agency.value, fileField);
      }      
      if (fileId === 0) {
        setIsDuplicatedFile(false);
      }

      if (fileId > 0) setShowAlertDuplicatedFile(true);
      else if (agency && agency.value !== "") {
        resetNonFileSections(0, fileField, agency);
        setIsDuplicatedFile(false);
        clearErrors(name);
      }
    },
    [
      agencies, 
      clearErrors, 
      fileField, 
      getUniqueFileByAgencyClientFileId, 
      resetNonFileSections, 
      setIsDuplicatedFile, 
      setShowAlertDuplicatedFile
    ]
  );

  const checkValidationFileName = () => {
    clearErrors(AgencyFieldName);
    if (isDuplicatedFile && isToggleFileAgencyFocus == "agency") {
      setError(
        "agency",
        {
          type: "isDuplicatedAgencyName",
          message: " This File Name/Number already exists for this Agency. ",
        },
        { shouldFocus: false },
      );
    }
  };

  useEffect(() => {
    checkValidationFileName();
  }, [isDuplicatedFile]);

  useEffect(() => {
    fileNameValueRef.current = undefined;
  }, [prevFileNameValueState]);

  const handleChange = (_: any, selectedAgency: any) => {

    const agency = buildAgency(selectedAgency);
    currentAgencyRef.current =  agency
        
    if (currentAgencyRef.current) {
      const additionalParties = getValues("additionalParties");
      const hasIssuedProducts = getValues("hasIssuedProducts");
      if ((
        hasPendingProducts(getValues() as SCFile) ||
        hasAdditionalParty(additionalParties)
      ) && !hasIssuedProducts ) {
        setMessage("By changing the Agency, any Additional Parties and pending products will be lost.");
        setIsModalOpen();
      } else {        
        setValue(name, agency);
        setFocusOnNextElement();        
      }
    } else {      
      setValue(name, agency); 
      setFocusOnNextElement();
    }
  };

  const handleOnBlur = async (e: any) => {    
    const selectedAgency = e.target.value;
    setToggleFileAgencyFocus("agency")
    if (typeof getValues(name) == "string") { 
      setTimeout(() => {
        setValue(name, "");        
      }, 10);      
      return;
    }
    if((idField && idField > 0) || !selectedAgency || filteredAgencies?.length === 1) return;
    const currentSelectedAgency = getValues(name);
    const agency = {
      text: currentSelectedAgency?.name,
      value: currentSelectedAgency?.id,
      activeContractID: currentSelectedAgency?.activeContractID,
      legacyID: currentSelectedAgency?.legacyID
    };
    if (fileField) await checkIfDuplicatedFile(agency);    
    setValue("isFileDefault", false);
   
  };

  const setFocusOnNextElement = () => {    
    setTimeout(() => {
      setFocusInputElement("agencyLocation");
    }, 500);  
  };

  const buildAgency = (selectedAgencyOption: any) => {    
    return {
      id: selectedAgencyOption.value,
      name: selectedAgencyOption.text,
      activeContractID: selectedAgencyOption.activeContractID,
      legacyID: selectedAgencyOption.legacyID,
    };
  };  
  useEffect(() => {    
    // User has GlobalAccessAgency Selected && Agency field is not populated yet
    // Then filterdown AgencyList to GA Selected Agency                         
    if(selectedAgency&& selectedAgency[0]?.CompanyID ) {      
      if(agencies?.length > 0 
        && idField === 0 
        && !agencyField?.id 
        && selectedAgency[0]?.Status?.toUpperCase() === AgencyStatus.Active
        && !isReadOnly) {         
        const defaultAgency = agencies?.find((a) => a.value === selectedAgency[0]?.CompanyID);      
        if (defaultAgency) {              
              setValue(name, buildAgency(defaultAgency));            
              setFilteredAgencies([defaultAgency]);                  
        }        
      }else if(selectedAgency[0]?.Status?.toUpperCase() === AgencyStatus.Inactive && 
              selectedAgency[0]?.CompanyID !== agencyField?.id){        
          const inactiveAgency = {
            value: selectedAgency[0]?.CompanyID || "",
            text: selectedAgency[0]?.CompanyName || "",
            legacyID: selectedAgency[0]?.CompanyLegacyID,
          }
          setValue(name, buildAgency(inactiveAgency));            
          setFilteredAgencies([inactiveAgency]); 
      }      
    }else if(profileSettings?.userProfile.defaultAgencyID && 
      idField === 0 &&
      (!agencyField?.id)) {
      const profileDefaultAgency = agencies?.find((a) => a.value === profileSettings.userProfile.defaultAgencyID);      
      if(profileDefaultAgency) {
        setValue(name, buildAgency(profileDefaultAgency));        
        if (!hasFileViewAccess) setIsReadOnly(true);
        else {
          setIsReadOnly(false);
        }
        setFilteredAgencies(agencies);
      } 
    }
    else if(agencies?.length > 0 && 
            idField === 0 && 
            (!agencyField?.id ||
            agencies.length > filteredAgencies.length)) 
    {       
      if(agencyField?.id){     
        setTimeout(() => {
          setValue(name, "");
          if (!hasFileViewAccess) setIsReadOnly(true);
          else {
            setIsReadOnly(false);
          }
        }, 10);
      }
      setFilteredAgencies(agencies);      
    }else if(filteredAgencies.length > 1 && idField > 0 && !isReadOnly){      
      if(!agencyField?.id){
        setTimeout(() => {
          setValue(name, "");
          if (!hasFileViewAccess) setIsReadOnly(true);
          else {
            setIsReadOnly(false);
          }
        }, 10);
      }
      setFilteredAgencies(agencies);      
    }
  }, 
  [
    selectedAgency, 
    setValue, 
    idField, 
    agencyField, 
    agencies, 
    filteredAgencies?.length,
    isReadOnly,
    profileSettings
  ]);

  useEffect(() => {
    getAgencies();
  }, [getAgencies]);

  useEffect(() => {

    if (idField > 0 && agencyField?.id) {
      // Get agencyLocation from SCFile and add to agencies list
      const isFileReadOnly = getValues('isReadOnly');
      const matchAgencyInList = agencies?.find((a) => a.value === agencyField.id);      
      
      if (!matchAgencyInList && (isReadOnly || isFileReadOnly)) {        
        const newAgency = {
          value: agencyField?.id || "",
          text: agencyField?.name,
        };        
        setFilteredAgencies([newAgency]);       
      }
      else if(matchAgencyInList) {
        setFilteredAgencies(
          [{
            value: matchAgencyInList?.value || "",
            text: matchAgencyInList?.text,
        }]);
      }
    }
  }, [idField, agencies, agencyField, updateAgencies, setValue, isReadOnly])
  //AUTO-SET AGENCY IF UNIQUE
  useEffect(() => {    
    if (
      idField > 0 ||
      !agencies ||
      agencies.length !== 1 ||
      agencyField.id !== ""
    )
      return;

    const agency = {
      id: agencies[0].value,
      name: agencies[0].text,
      activeContractID: agencies[0].activeContractID || "",
      legacyID: agencies[0].legacyID || "",
    };    
    setValue(name, agency);
    checkIfDuplicatedFile(agency);
  }, [idField, agencies, setValue, checkIfDuplicatedFile, agencyField.id]);

  useEffect(() => {    
    if(selectedAgency?.length <= 0) return;
    if(filteredAgencies.length === 1 && !agencyField?.id) {
      if(agencies?.length > 0) {
        const defaultAgency = agencies?.find((a) => a.value === selectedAgency[0]?.CompanyID);      
        if (defaultAgency) {              
              setValue(name, buildAgency(defaultAgency));            
              //setFilteredAgencies([defaultAgency]);                  
        }  
      } 
      else {
        setValue(name, {
          id: filteredAgencies[0]?.value,
          name: filteredAgencies[0]?.text,
          activeContractID: filteredAgencies[0]?.activeContractID || "",
          legacyID: filteredAgencies[0]?.legacyID || "",
        });
      }      
    }     
  }, [filteredAgencies, selectedAgency, agencyField?.id, setValue, getValues, agencyField, agencies])

  //ACTIVECONTRACTID IS USED LATER IN THE APP
  //i.e. TO SHOW/HIDE THE PRODUCT/PRICING SECTIONS
  useEffect(() => {
    if (idField > 0 && agencyField.id !== "") {
      setValue(`${name}.activeContractID`, agency?.activeContractID ?? "");
      setValue(`${name}.legacyID`, agency?.legacyID ?? "");
    }
  }, [agency?.activeContractID, agency?.legacyID, agencyField.id, idField, setValue]);

  //LOAD AND AUTO-SELECT AGENCY FOR AN EXISTING FILE
  //WHEN NO AGENCIES ARE RETURNED FROM THE API
  useEffect(() => {
    if (idField > 0 && error && agencyField.id !== "") {
      getAgencies(agencyField);
    }
  }, [agencyField, error, getAgencies, idField]);

  // agency.activeContractID is reset to null after saved or any action by scFileCreator.ts
  useEffect(() => {
    if ((!agencyField?.activeContractID || !agencyField?.legacyID) && agencyField?.id) {
      const matchingAgency = agencies?.find((a: any) => a?.value === agencyField.id);
      setValue(`${name}.activeContractID`, matchingAgency?.activeContractID ?? "");
      setValue(`${name}.legacyID`, matchingAgency?.legacyID ?? "");
    }
  }, [agencyField?.activeContractID, agencyField?.legacyID, agencyField?.id, agencies, setValue]);

  const restoreFileInitial = () => {    
    const payload = cloneDeep({
      fileNameNumber: getValues("fileNameNumber"),
    });
    resetInitialValues();
    updateAgencyStates([]);    
    setTimeout(() => {
      setValue("fileNameNumber", payload.fileNameNumber)            
      resetField("properties");
    }, 500);
  }  

  useEffect(() => {
    if (acceptedModal) {
      restoreFileInitial();
      resetDefaultValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedModal]);  
  
  return (    
    <ComboBoxField
        label={"Agency"}
        options={filteredAgencies}
        {...{ name: name, disabled: Boolean(idField && agencyField.id) }}
        onChange={(_: any, selectedValue: any) => {
          handleChange(_, selectedValue);
        }}
        roundedCorner={true}
        onBlur={handleOnBlur}
        freeSolo={true} 
        disableClearable={true}
        controlTooltipOnMouseEvents = {!(idField && agencyField.id)} // Disregard tooltip in enabled mode. It is interfering with autocomplete popper
      />
  );
};

export default AgencyField;

