import CheckIcon from "@mui/icons-material/Check";
import {
  Grid,
  styled,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import TextInputField from "controls/global/text-input-field";
import React, {
  useEffect,
} from "react";
import { padding } from "theme/defaultStyle";
import { useVoidReasons } from "utils/context/CompanyVoidReasonContext";
import { ProductType } from "utils/data/enum";
import VoidReasonsField from "./VoidReasonsField";

type Props = {
  productType: string;
  coveredParty: string;
  serialNumber: string;
  effectiveDate: string;
  productIndex: number;
  isOpen: boolean;
  onSubmit: any;
  onCancel: any;
  submitButtonDisabled: boolean;
  defaultVoidReason: string;
};

const gridStyle = {
  paddingTop: padding.small2,
  paddingRight: padding.small2,
};

const voidGridStyle = {
  paddingTop: padding.xlarge,
  paddingRight: "25%",
  paddingLeft: "25%",
};

const CustomDialogContent = styled(StewartDialogContent)({
  overflowY: "visible",
});

const VoidLetterForm = ({
  productType,
  coveredParty,
  serialNumber,
  effectiveDate,
  productIndex,
  isOpen,
  onSubmit,
  onCancel,
  submitButtonDisabled,
  defaultVoidReason,
}: Props) => {
  const [{ voidReasons }, { getVoidReasons }] = useVoidReasons();

  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getVoidReasons(ProductType.Cpl, defaultVoidReason);
  }, [defaultVoidReason]);

  return (
    <StewartDialog
      open={isOpen}
      maxWidth="md"
      fullScreen={fullScreen}
    >
      <StewartDialogTitle type={"INFO"}>
        Void {productType}
        <DialogTitleCloseButton onClick={() => onCancel(false)}/>
      </StewartDialogTitle>
      <CustomDialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} style={gridStyle}>
            <TextInputField
              label="Covered Party"
              name={coveredParty}
              disabled={true}
              defaultValue={coveredParty}
            />
          </Grid>

          <Grid item xs={12} md={4} style={gridStyle}>
            <TextInputField
              label="Serial Number"
              name={serialNumber}
              disabled={true}
              defaultValue={serialNumber}
            />
          </Grid>

          <Grid item xs={12} md={4} style={gridStyle}>
            <TextInputField
              label="Effective Date"
              name={effectiveDate}
              disabled={true}
              defaultValue={effectiveDate}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={voidGridStyle}>
            <VoidReasonsField
              voidReasons={voidReasons[ProductType.Cpl]}
              productIndex={productIndex}
              productType={productType}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <StewartDialogActions>
        <ActionButton
          autoFocus
          variant="outlined"
          color="primary"
          onClick={onSubmit}
          disabled={submitButtonDisabled}
          startIcon={<CheckIcon />}
        >
          Submit
        </ActionButton>
      </StewartDialogActions>
    </StewartDialog>
  );
};

export default VoidLetterForm;
