import React, {
  ReactNode,
  useReducer,
} from "react";
import SignatureConfiguration from "../../components/signature-configuration";
import Menu from "../../components/signature-menu";
import SignatureTypes from "../../components/signature-types";
import SignatureLevel from "pages/signatures/components/signature-level";

export type StepKeys = "MENU" | "TYPE" | "CONFIG" | "LEVEL";
type Step = {
  key: StepKeys;
  Component: ReactNode | any;
};

const steps: Step[] = [
  { key: "MENU", Component: <Menu key="MENU:1" /> },
  { key: "TYPE", Component: <SignatureTypes key="TYPE:2" /> },
  { key: "CONFIG", Component: <SignatureConfiguration key="CONFIG:3" /> },
  { key: "LEVEL", Component: <SignatureLevel key="LEVEL:4" /> },
];

type State = {
  activeStep: Step;
};

type ActionTypes = "BACK" | "NEXT" | "RESET";

type Action = {
  type: ActionTypes;
  skip?: number;
};

type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const WizardContext = React.createContext<Context | undefined>(undefined);
WizardContext.displayName = "WizardContext";

const useWizardProvider = () => {
  const context = React.useContext(WizardContext);
  if (!context) {
    throw Error("useWizardProvider must be used within a <WizardProvider>");
  }
  return context;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "BACK": {
      const skip = action.skip || 1;
      const index = steps.findIndex((x) => x.key === state.activeStep.key);
      if (index === 0 || !steps[index - skip]) return state;
      return { activeStep: steps[index - skip] };
    }
    case "NEXT": {
      const skip = action.skip || 1;
      const index = steps.findIndex((x) => x.key === state.activeStep.key);
      if (!steps[index + skip]) return state;
      return { activeStep: steps[index + skip] };
    }
    case "RESET": {
      return { activeStep: steps[0] };
    }
    default:
      throw new Error(`Not supported action type: ${action.type}`);
  }
};

const WizardProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, {
    activeStep: steps[0],
  });

  return <WizardContext.Provider {...props} value={{ state, dispatch }} />;
};

export default WizardProvider;
export { useWizardProvider };

