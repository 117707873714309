import React from "react";
import { Controller } from "react-hook-form";
import ErrorMessage from "controls/global/error-message";
import TextInputZip from "./TextInputZip";
import useFormWrapper, {
  getNameString,
} from "utils/custom-hooks/useFormWrapper";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";

interface Props {
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  size?: "small" | "medium";
}

const TextInputZipField = ({
  defaultValue,
  name,
  onBlur,
  onChange,
  disabled,
  size = "medium",
  ...others
}: Props) => {
  const { control, setValue } = useFormWrapper();

  const handleOnChange = (value: string) => {
    setValue(name, value);
    onChange && onChange(value);
  };

  const handleOnBlur = (e: React.ChangeEvent<any>, onBlurFormHandler: any) => {
    onBlurFormHandler();
    onBlur && onBlur(e);
  };
  const shouldFieldBeDisabled = useFieldDisabler(name);

  return (
    <>
      <Controller
        control={control}
        name={getNameString(name)}
        defaultValue={defaultValue}
        render={({
          field: { name, value, onBlur, ref },
          fieldState: { error },
        }) => {
          return (
            <TextInputZip
              hasError={Boolean(error)}
              onChange={handleOnChange}
              onBlur={(event: React.ChangeEvent<any>) =>
                handleOnBlur(event, onBlur)
              }
              disabled={disabled || shouldFieldBeDisabled}
              fieldRef={ref}
              size={size}
              {...{ name, value, ...others }}
            />
          );
        }}
      />
      <ErrorMessage name={name} style={{ maxWidth: 200 }} />
    </>
  );
};

export default TextInputZipField;
