import SelectField from "controls/global/select-field";
import TextInputField from "controls/global/text-input-field";
import React, {
  useEffect,
  useRef,
} from "react";
import { useWatch } from "react-hook-form";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useForms from "utils/custom-hooks/useForms";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { ProductType } from "utils/data/enum";

type Props = {
  name: string;
  schema: string;
  productType: ProductType;
  isIssued: boolean;
  disabled?: boolean;
  onChange?: () => void;
};

const FormField = ({
  name,
  schema,
  productType,
  isIssued,
  disabled,
  onChange,
}: Props) => {
  const { setValue, getValues, nameString } = useFormWrapper();
  const { setFormInfo, clearFormInfo } = useCreateFile();
  const effectiveDataField = `${schema}.effectiveDate`;
  const currentFormType = getValues(`${schema}.coveredParty`);
  const currentForm = getValues(name);

  const [
    effectiveDate,
  ] = useWatch({
    name: [
      nameString(effectiveDataField),
    ],
  });

  const { options } = useForms(productType, currentFormType, effectiveDate, disabled);

  const handleOnChange = (_: any, value: any) => {
    const foundForm = options?.find((o) => o.value === value);
    setFormInfo(schema, foundForm, ProductType.Cpl);
    onChange?.();
  };

  useEffect(() => {
    if (options?.length === 0) {
      return;
    }

    let formInfo = null;
    if (options?.length === 1) {
      const { value } = options[0];
      formInfo = options?.find((o) => o.value === value);
      setValue(`${name}`, value);
    }
    else {
      formInfo = options?.find((o) => o.value === currentForm);
      if (!formInfo && !isIssued) {
        setValue(`${schema}.form`, "");
        clearFormInfo(schema, ProductType.Cpl);
      }
    }
    setFormInfo(schema, formInfo, ProductType.Cpl);

  }, [name, options, schema, setFormInfo, clearFormInfo, setValue]);

  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    if (mountedRef.current) {
      if (!isIssued && options?.length === 0 && currentForm) {
        setValue(`${schema}.form`, "");
        clearFormInfo(schema, ProductType.Cpl);
      }
    }
    mountedRef.current = true;
  }, [effectiveDate, currentFormType, options?.length]);

  if (disabled) {
    return <TextInputField label="Form" name={name} disabled />;
  }

  return (
    <SelectField
      label="Form"
      name={name}
      options={options}
      onChange={handleOnChange}
    />
  );
};

export default FormField;
