import * as UIModel from "entities/UIModel";
import { PartyType } from "utils/data/enum";

const getName = (name: string) => name ? name + " " : "";

const getDisplayNameForBuyerSeller = (party: UIModel.GeneralFileParty) => {
  const displayNameForBuyerBorrower =  party.partyTypeCode === PartyType.Individual
    ? `${getName(party.partyFirstName)}${getName(party.partyMiddleName)}${getName(party.partyLastName)}${party.suffixType?.code ? party.suffixType?.name.trim() : ""}`
    : party.companyName;

  return displayNameForBuyerBorrower.trimEnd();
};

const getDisplayNameForLender = (party: UIModel.GeneralFileParty) => {
  return party.companyName;
};

export {
  getDisplayNameForBuyerSeller,
  getDisplayNameForLender
}
