import {
  styled,
  TextField,
} from "@mui/material";
import LightTooltip from "controls/global/light-tooltip";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import theme from "theme/default";
import { useSignatureProvider } from "../../providers/manage-signature/SignatureProvider";

interface Props {
  title: string;
  handleNameChange: (e: React.ChangeEvent<any>) => void;
}

const CssTextField = styled(TextField)({
  "& input.MuiInputBase-input": theme.custom.textField.inputOutlined,
  "& input.MuiInputBase-input.Mui-disabled": theme.custom.textField.inputOutlinedDisabled,
  "& div.Mui-error>input": theme.custom.textField.selectError,
  "& label.MuiInputLabel-shrink.Mui-error": theme.custom.textField.colorError,
  "& .Mui-error .arrow": theme.custom.textField.arrowError,
});

const CustomTooltip = styled(LightTooltip)({
  maxWidth: "500px",
});

const FileNameInputField = ({ title, handleNameChange }: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const {
    state: { isNameValid },
  } = useSignatureProvider();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOpenTooltip = () => {
    const inputField = inputRef?.current?.children[0]?.children[0];
    const scrollWidth = inputField?.scrollWidth || 0;
    const clientWidth = inputField?.clientWidth || 0;
    const isScrollPresent = scrollWidth > clientWidth;
    setOpenTooltip(isScrollPresent);
  };

  const handleCloseTooltip = () => setOpenTooltip(false);

  useEffect(() => {
    if (isFocused && openTooltip) setOpenTooltip(false);
  }, [isFocused]);

  useEffect(() => {
    const inputField = inputRef?.current?.children[0]?.children[0] as HTMLInputElement;
    inputField?.focus();
  }, []);

  return (
    <div
      onMouseEnter={() => handleOpenTooltip()}
      onMouseLeave={() => handleCloseTooltip()}
    >
      <CustomTooltip {...{ title }} arrow open={openTooltip}>
        <CssTextField
          value={title}
          onChange={handleNameChange}
          fullWidth
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          error={!isNameValid}
          ref={inputRef}
          variant="outlined"
        />
      </CustomTooltip>
    </div>
  );
};

export default React.memo(FileNameInputField);
