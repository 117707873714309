import IconButton from "@mui/material/IconButton";
import {
  styled,
  TableCell,
} from "@mui/material";
import { BlockOutlined } from "@mui/icons-material";
import React from "react";
import {
  borderSize,
  colors,
  padding,
} from "theme/defaultStyle";

type Props = {
  disabled: boolean;
  hidden: boolean;
  isIssued: boolean;
  onRequestVoid: () => void;
};

const StyledBlockIcon = styled(BlockOutlined)({
  transform: "scaleX(-1)",
});

const VoidTableCell = styled(TableCell)({
  borderRight: `${borderSize.xsmall} solid ${colors.grey04}`,
  padding: `${padding.zero} !important`,
  "& .disabledVoidButton": {
    opacity: 0.5,
  },
  width: 60,
});

export default function EndorsementVoidCell({
  disabled,
  hidden,
  isIssued,
  onRequestVoid,
}: Props) {

  if (!isIssued) {
    return (
      <VoidTableCell
        hidden={hidden}
        align="center"
      />
    );
  }

  return (
    <>
      <VoidTableCell
        hidden={hidden}
        align="center"
      >
        <IconButton
          classes={{
            disabled: "disabledVoidButton",
          }}
          disabled={disabled}
          aria-label="void-endorsement"
          onClick={onRequestVoid}
        >
          <StyledBlockIcon htmlColor={colors.blue01} />
        </IconButton>
      </VoidTableCell>
    </>
  );
}
