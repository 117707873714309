import { Box } from "@mui/material";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import React from "react";
import { Controller } from "react-hook-form";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { PartyRoleTypeName } from "utils/data/enum";

type Props = {
  schema: string;
  disabled?: boolean;
  onForeignAddressChange?: any;
  onBlur?: () => void;
};

const ADDRESS_FIELDS = [
  "state",
  "city",
  "zipCode",
  "addressOne",
  "addressTwo",
  "county",
  "foreignCountry",
  "foreignState",
];

function ForeignAddressCheckbox({ schema, disabled = false, onForeignAddressChange, onBlur }: Props) {
  const { control, getValues, setValue, nameString } = useFormWrapper();

  const fieldName = nameString(`${schema}.isForeignAddress`);
  const coverPartyFieldName = nameString(`${schema}.coveredParty`);
  const shouldFieldBeDisabled = useFieldDisabler(fieldName);

  const defaultValue = getValues(fieldName);
  const coveredParty = getValues(coverPartyFieldName);

  const onChange = (e: any, checked: boolean) => {
    setValue(`${schema}.isDefault`, false);
    setValue(fieldName, checked ? 1 : 0);
    //updateCheckbox(checked, schema);
    resetFieldValues();
    onForeignAddressChange && onForeignAddressChange(undefined, fieldName, e.target.value);
    onBlur && onBlur();
  };

  const resetFieldValues = () => {
    ADDRESS_FIELDS.forEach((field: string) => {
      if (field === "state") {
        setValue(nameString(`${schema}.${field}`), { abbr: "", code: "" });
      } else setValue(nameString(`${schema}.${field}`), "");
    });
  };

  // React.useEffect(() => {
  //   updateCheckbox(defaultValue === 1 ? true : false, schema);
  // }, [defaultValue]);

  if (coveredParty === PartyRoleTypeName.Lender) return null;

  return (
    <Box>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        render={({ field: { name, value } }) => {
          return (
            <CheckboxInput
              label="Foreign Address"
              disabled={disabled || shouldFieldBeDisabled}
              {...{ name }}
              onChange={onChange}
              checked={!!value}
            />
          );
        }}
      />
    </Box>
  );
}

export default ForeignAddressCheckbox;
