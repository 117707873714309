import React from "react";
import { Controller } from "react-hook-form";
import useFieldDisabler from "utils/custom-hooks/useFieldDisabler";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import ErrorMessage from "../error-message";
import MultiSelectInputField from "./MultiSelectInputField";

export interface MultiSelectFieldOption {
  value: string;
  text: string;
  selected?: boolean;
}

type Props = {
  label: string;
  name: any;
  defaultValue?: any;
  options: Array<MultiSelectFieldOption>;
  disabled?: boolean;
  productIndex?: number;
  onTouched?: () => void;
};

const MultiSelectField = ({
  label,
  name,
  defaultValue = [],
  options,
  disabled = false,
  productIndex,
  onTouched,
}: Props) => {
  const { control, setValue } = useFormWrapper();
  const shouldFieldBeDisabled = useFieldDisabler(name);

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange }, fieldState }) => {
          return (
            <MultiSelectInputField
              name={name}
              label={label}
              options={options}
              value={value}
              onChange={(event) => {
                setValue(`cpls.${productIndex}.isValidAddresseeSelected`, true);
                onChange(event);
                onTouched?.();
              }}
              disabled={disabled || shouldFieldBeDisabled}
              isError={Boolean(fieldState.error)}
            />
          );
        }}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default MultiSelectField;
