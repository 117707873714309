import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import CheckCircleIcon from "theme/icons/CheckCircleIcon";
import HelpIcon from "theme/icons/HelpIcon";

const { section } = defaultStyle;
type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const StyledSuccessDialog = styled(Dialog)({
  "& div.MuiDialog-paper": {
    overflowY: "revert",
  },
  "& div.MuiPaper-rounded": {
    borderRadius: section.borderRadius,
  },
  "& div.MuiDialog-paperFullWidth": {
    width: "500px",
  },
  "& .dialogTitleRoot": {
    color: colors.white,
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
    margin: "0 auto",
    padding: padding.zero,
    textAlign: "center",
  },
  "& .dialogActionRoot": {
    justifyContent: "center",
    paddingBottom: padding.xlarge1,
    paddingTop: padding.zero,
  },
  "& .dialogTitle": {
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.green} 0%, #006E49 100%)`,
    borderRadius: borderRadius.large,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    height: "130px",
    justifyContent: "center",
    margin: "0 auto",
    marginTop: "-65px",
    width: "90%",
  },
  "& .dialogContent": {
    margin: "0 auto",
    marginBottom: margin.xlarge1,
    padding: "24px 0",
    textAlign: "center",
    width: "90%",
  },
  "& .dialogContentIcon": {
    margin: margin.xlarge3,
  },
});

const buttonStyle = {
  width: "97px",
};
const SubmitTicketSuccessDialog = ({ isOpen, onClose }: Props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <>
      <StyledSuccessDialog
        open={open}
        fullWidth
      >
        <Box className={"dialogTitle"}>
          <HelpIcon />
          <DialogTitle
            classes={{
              root: "dialogTitleRoot",
            }}
          >
            Thank You
          </DialogTitle>
        </Box>
        <Box className={"dialogContent"}>
          <Box className={"dialogContentIcon"}>
            <CheckCircleIcon />
          </Box>
          <Typography>
            Your Ticket has been submitted. <br /> Someone from Stewart&apos;s
            Agency Support Center will contact you shortly.{" "}
          </Typography>
        </Box>
        <DialogActions
          classes={{
            root: "dialogActionRoot",
          }}
        >
          <ActionButton

            variant="outlined"
            color="primary"
            onClick={onClose}
            style={buttonStyle}
          >
            Close
          </ActionButton>
        </DialogActions>
      </StyledSuccessDialog>
    </>
  );
};
export default SubmitTicketSuccessDialog;
