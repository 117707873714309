import { styled } from "@mui/material";
import SelectField from "controls/global/select-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import {
  groupBy,
  sortBy,
} from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useFormContext,
  useWatch,
} from "react-hook-form";
import { margin } from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";

const FormField = styled("div")({
  marginTop: margin.xlarge,
});

const FIELD_NAME = "location";

const AgencyLocationField = () => {
  const [{ agencyLocationsByImage }] = useSignatureDefaults();
  const [agencyField, stateField] = useWatch({
    name: ["agency", "state"],
  });
  const [availableLocations, setAvailableLocations] = useState<SelectFieldOption[]>([]);
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    const agency = agencyField;
    const state = String(stateField.value);
    const allAgencies = (agency === "All");
    const allStates = (state === "All");

    const filteredLocations = agencyLocationsByImage
      .filter(l => (allAgencies || l.agencyID === agency) && (allStates || l.stateCode === state));

    const groupedLocations = groupBy(
      filteredLocations.map(g => ({ locationDisplayName: g.locationDisplayName, locationUniqueID: g.locationUniqueID })),
      a => a.locationDisplayName
    );

    let locations: SelectFieldOption[] = sortBy(
      Object.values(groupedLocations).map(g => ({
        value: g.map(i => i.locationUniqueID).join("|"),
        text: g[0].locationDisplayName
      })
      ), i => i.text);

    if (locations.length > 1) {
      locations = [
        { text: "All Agency Locations", value: "All" },
        ...locations
      ];
    }

    setAvailableLocations(locations);
  }, [agencyField, stateField, agencyLocationsByImage]);

  useEffect(() => {
    if (availableLocations.length > 0) {
      const selectedLocation = getValues(FIELD_NAME).text;
      const selections = availableLocations.filter(l => l.text === selectedLocation);
      const selection = selections.length ? selections[0] : availableLocations[0];
      setValue(FIELD_NAME, {
        text: selection.text,
        value: selection.value,
      });
    }
  }, [availableLocations]);

  return (
    <FormField>
      <SelectField
        label="Agency Location"
        name={FIELD_NAME}
        options={availableLocations}
        optionsValueField="value"
        optionsTextField="text"
        dataValueField="value"
        dataTextField="text"
        disabled={availableLocations.length < 1}
      />
    </FormField>
  );
};

export default AgencyLocationField;
