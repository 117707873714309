import { ProfileSettings } from "utils/context/ProfileSettingsContext";

const getTimeZoneOffset = (profileSettings: ProfileSettings) => {
  return (
    profileSettings.timeZones.find(
      ({ Name }: { Name: string }) => Name === profileSettings.userTimeZone
    )?.Current_UTC_Offset || "+00:00"
  );
};

export { getTimeZoneOffset };
