import {
  IData,
  ISegment,
} from "controls/global/editable-segment-control";
import {
  format,
  parseISO,
} from "date-fns";
import {
  AALProduct,
  CPLProduct,
  FilePricingDetail,
  JacketProduct,
  Party,
  StandaloneEndorsementProduct,
  UserInput as APIUserInput,
  FilePricing,
  DocumentFileData,
  DocumentOrderData,
} from "entities/ApiModel";
import { Endorsement as EndorsementApi } from "entities/ApiModel/Endorsement";
import {
  AAL,
  CPL,
  Document,
  FileActivity,
  DocumentFileData as DocumentFileDataUI,
  DocumentOrderData as DocumentOrderDataUI,
  FilePricingDetail as FilePricingDetailUi,
  GeneralFileParty,
  OrderParty,
  Property,
  Pricing,
  PricingProduct,
  PricingProductItem,
  StandaloneEndorsement,
  UserInput,
} from "entities/UIModel";
import { Endorsement } from "entities/UIModel/Endorsement";
import { List } from "linq-typescript";
import {
  EndorsementStatusType,
  JacketFormType,
  OrderStatusType,
  PartyRoleType,
  PricingProductSortOrder,
  PricingProductSortOrder2,
  PricingType,
  ProductType,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";
import {
  getSumPricingTaxes,
  mapPricingProduct,
  mapPricingProductItem,
  mapPricingTax,
  maskZipCode,
} from "../helper";
import { buildJacket } from "./jacketMapper";

export const createCombinedQNAs = (
  combinedQNAs: Array<APIUserInput> | undefined
) => {
  let mappedUserInputs = new Array<UserInput>();
  if (combinedQNAs) {
    mappedUserInputs = combinedQNAs?.map((cqna) => {
      return {
        questionsAnswersID: cqna.QuestionsAnswersID,
        orderID: cqna.OrderID,
        filePricingDetailID: cqna.FilePricingDetailID,
        questions: cqna.Questions.map((question) => {
          return {
            uniqueIdentifier: uuidv4(),
            questionID: question.QuestionID,
            questionText: question.QuestionText,
            questionDescription: question.QuestionDescription || "",
            inputMethod: question.InputMethod || "",
            answerValue: question.AnswerValue,
            answers: question.Answers?.map((option) => {
              return {
                answer: option.Answer,
                default: option.Default,
              };
            }),
          };
        }),
      };
    });
  }
  return mappedUserInputs;
};

const parseJsonToIData = (json: string) => {
  let outputData: IData = {
    buyerBorrowerModified: false,
    sellerModified: false,
    segments: new List<ISegment>([]),
    deselectedOptions: [],
  };

  try {
    let tempData: any = JSON.parse(json);

    //Patch to convert old data format
    if (tempData.changedAddressId) tempData.hasAddressChanged = true;

    if (!tempData.segments) {
      outputData.segments.pushRange(tempData);
    } else {
      if (tempData.segments?.source) {
        outputData.segments.pushRange(tempData.segments.source);
      }
      if (tempData.deselectedOptions) {
        outputData.deselectedOptions = tempData.deselectedOptions;
      } else {
        outputData.deselectedOptions = [];
      }
    }
    outputData.hasAddressChanged = tempData.hasAddressChanged;
    outputData.hasAddresseeNameChanged = tempData.hasAddresseeNameChanged;
    outputData.hasLoanNumberChanged = tempData.hasLoanNumberChanged;
    outputData.hasSuccessorLanguageChanged = tempData.hasSuccessorLanguageChanged;
    outputData.hasAttentionToChanged = tempData.hasAttentionToChanged;
    outputData.buyerBorrowerModified = tempData.buyerBorrowerModified
      ? tempData.buyerBorrowerModified
      : false;
    outputData.sellerModified = tempData.sellerModified
      ? tempData.sellerModified
      : false;
    return outputData;
  }
  catch (e) {
    return outputData;
  }
};

const parseDateString = (dateStr: string | undefined | null) => {
  const dateInput = dateStr
    ? format(parseISO(dateStr), "MM/dd/yyyy")
    : undefined;
  return dateInput ? new Date(dateInput) : undefined;
};

export const createCpls = (cplproducts: Array<CPLProduct> | undefined) => {
  let mappedCpls = new Array<CPL>();
  cplproducts?.forEach((cpl) => {
    let selectedParties = new Array<string>();
    let selectedAddlParties = new Array<string>();
    //let selectedBuyerBorrower = new Array<string>();
    let orderParties = new Array<OrderParty>();
    cpl.OrderParties?.forEach((p) => {
      if (
        p.IntegrationKey &&
        (p.PartyRoleTypeCode === cpl.CoveredParty.toUpperCase() ||
          p.PartyRoleTypeCode === PartyRoleType.AdditionalParty)
      ) {
        selectedParties.push(p.IntegrationKey || "");
        if (p.PartyRoleTypeCode === PartyRoleType.AdditionalParty) {
          selectedAddlParties.push(p.IntegrationKey);
        }
      }

      orderParties.push({
        filePartyId: p.FilePartyID || 0,
        identityKey: p.IdentityKey || 0,
        fileID: p.FileID || 0,
        integrationKey: p.IntegrationKey,
        filePartyKey: p.IntegrationKey,
        filePartyDataID: p.FilePartyDataID || 0,
        partyRoleTypeCode: p.PartyRoleTypeCode || "",
        partyTypeCode: p.PartyTypeCode || "",
        orderPartyID: p.OrderPartyID,
        orderPartyDataID: p.OrderPartyDataID,
        orderID: p.OrderID,
        addressOne: p.Address || "",
        addressTwo: p.Address2 || "",
        addressThree: p.Address3 || "",
        city: p.City || "",
        state: {
          code: p.StateCode || "",
          abbr: p.StateAbbr || "",
        },
        zipCode: maskZipCode(p.Zip),
        suffixType: {
          code: p.SuffixTypeCode || "",
          name: p.SuffixTypeName || "",
        },
        isExcludedOnCPL: p.IsExcludedOnCPL ? !!p.IsExcludedOnCPL : false,
        isExcludedOnAAL: p.IsExcludedOnAAL ? !!p.IsExcludedOnAAL : false,
        partyFirstName: p.PartyFirstName || "",
        partyMiddleName: p.PartyMiddleName || "",
        partyLastName: p.PartyLastName || "",
        companyName: p.PartyCompanyName || "",
        individualAttorney: p.PartyAdditionalName || "",
        loanNumber: p.LoanNumber || "",
        attentionTo: p.AttentionTo || "",
        successorLanguage: p.SuccessorLanguage || "",
      });
    });

    let documents = new Array<Document>();
    cpl.Documents?.forEach((d) => {
      documents.push({
        integrationKey: d.IntegrationKey,
        id: d.DocumentID,
        fileID: d.FileID,
        orderID: d.OrderID,
        isHidden: d.IsHidden,
        fileTypeCode: d.DocumentFileTypeCode,
        fileName: d.DocumentFileName,
        sourceTypeCode: d.DocumentSourceTypeCode,
        typeCode: d.DocumentTypeCode,
        createdDate: d.DocumentCreatedDate,
        createdByUserName: d.DocumentCreatedByUserName,
      });
    });

    mappedCpls.push({
      isDefault: true,
      isLocationDefault: false,
      isDirty: cpl.IsDirty,
      id: cpl.ProductID,
      fileID: cpl.FileID,
      orderID: cpl.OrderID,
      originalOrderID: cpl.OriginalOrderID,

      identityKey: cpl.IdentityKey,
      integrationKey: cpl.IntegrationKey,
      orderStatusTypeCode: cpl.OrderStatusTypeCode,
      orderStatusTypeName: cpl.OrderStatusTypeName,
      addresseeNames: cpl.AddresseeNames ? cpl.AddresseeNames : "",
      addresseeNamesData: cpl.AddresseeNameData
        ? parseJsonToIData(cpl.AddresseeNameData)
        : { segments: new List<ISegment>([]), deselectedOptions: [] },
      originalAddresseeSegment: cpl.AddresseeNameData
        ? parseJsonToIData(cpl.AddresseeNameData)
        : { segments: new List<ISegment>([]), deselectedOptions: [] },
      addlParties: selectedAddlParties,
      buyerBorrower: cpl.BuyerBorrower ? cpl.BuyerBorrower : "",
      seller: cpl.Seller ? cpl.Seller : "",
      effectiveDate: parseDateString(cpl.EffectiveDate),
      coveredParty: cpl.CoveredParty,
      productReferenceID: cpl.ProductReferenceID,
      issueDate: cpl.IssueDate,
      issueDateTime: cpl.IssueDateTime
        ? new Date(cpl.IssueDateTime)
        : undefined,
      revisionDate: cpl.RevisionDate,
      revisionDateTime: cpl.RevisionDateTime
        ? new Date(cpl.RevisionDateTime)
        : undefined,
      form: cpl.Form || "",
      formID: cpl.FormID,
      formVersion: cpl.FormVersion,
      formTemplateName: cpl.FormTemplateName,
      opaIdentifier: cpl.OPAIdentifier,
      isAgencyExclusive: cpl.IsAgencyExclusive,
      liabilityAmount: cpl.LiabilityAmount,
      altaRegistryID: cpl.ALTARegistryID,
      // contractID: cpl.ContractID,
      // legacyID: cpl.LegacyID,
      // locationUniqueID: cpl.LocationUniqueID,
      territoryID: cpl.TerritoryID,
      // temporary until db team is ready
      locationID: cpl.PricingDetail?.LocationID,
      //locationName: cpl.LocationName,
      locationDisplayName: cpl.LocationDisplayName,
      locationLegalName: cpl.LocationLegalName,
      includeAllBranches: cpl.Branch === "None" ? false : true,
      filePricingDetailID: cpl.FilePricingDetailID,
      pricingDetail: {
        integrationKey: cpl.PricingDetail?.IntegrationKey || uuidv4(),
        orderID: cpl.OrderID || 0,
        fileID: cpl.FileID || 0,
        filePricingID: cpl.PricingDetail?.FilePricingID || 0,
        transCode: cpl.PricingDetail?.TransCode,
        filePricingDetailID: cpl.FilePricingDetailID || 0,
        liability: cpl.PricingDetail?.Liability || 0.0,
        actualFee: cpl.PricingDetail?.ActualFee || 0.0,
        originalActualFee: cpl.PricingDetail?.ActualFee || 0.0,
        calculatedFee: cpl.PricingDetail?.CalculatedFee || 0.0, // cpl.PricingDetail?.ActualFee || 0.0,
        actualRiskRate: cpl.PricingDetail?.ActualRiskRate || 0.0,
        calculatedRiskRate: cpl.PricingDetail?.CalculatedRiskRate || 0.0,
        actualRemittance: cpl.PricingDetail?.ActualRemittance || 0.0,
        calculatedRemittance: cpl.PricingDetail?.CalculatedRemittance || 0.0,
        actualRetention: cpl.PricingDetail?.ActualRetention || 0.0,
        calculatedRetention: cpl.PricingDetail?.CalculatedRetention || 0.0,
        actualPremiumTax: cpl.PricingDetail?.ActualPremiumTax || 0.0,
        calculatedPremiumTax: cpl.PricingDetail?.CalculatedPremiumTax || 0.0,

        isReissue: cpl.PricingDetail?.IsReissue || false,
        isSimultaneous: cpl.PricingDetail?.IsSimultaneous || false,
        isLeasehold: cpl.PricingDetail?.IsLeasehold || false,

        // isReported: cpl.PricingDetail?.IsReported,
        // lastReportedDate: cpl.PricingDetail?.LastReportedDate,
        // isReportable: cpl.PricingDetail?.IsReportable,
        // isReadyToBeReported: cpl.PricingDetail?.IsReadyToBeReported,

        isBilled: cpl.PricingDetail?.IsBilled,
        isBillable: cpl.PricingDetail?.IsBillable,
        isReadyToBeBilled: cpl.PricingDetail?.IsReadyToBeBilled,
        lastBilledDate: cpl.PricingDetail?.LastBilledDate,

        altPricingReferenceID: cpl.PricingDetail?.AltPricingReferenceID,
        lastErrorMessage: cpl.PricingDetail?.LastErrorMessage,
        isFeeError: cpl.PricingDetail?.IsFeeError,
        //isManualFee: cpl.PricingDetail?.IsManualFee,

        // Location info
        contractID: cpl.PricingDetail?.ContractID,
        legacyID: cpl.PricingDetail?.LegacyID,
        locationUniqueID: cpl.PricingDetail?.LocationUniqueID,
        locationID: cpl.PricingDetail?.LocationID,
        //locationName: cpl.PricingDetail?.LocationName,
        locationDisplayName: cpl.PricingDetail?.LocationDisplayName,
        locationLegalName: cpl.PricingDetail?.LocationLegalName,
        internalFileNumber: cpl.PricingDetail?.InternalFileNumber,
      },
      documents: documents,
      documentOrderData: createDocumentOrderData(cpl.DocumentOrderData),
      orderParties: orderParties,
      printAttorneyOnly: cpl.PrintAttorneyOnly ? true : false,
      void: {
        date: cpl.VoidedDate,
        dateTime: cpl.VoidedDateTime ? new Date(cpl.VoidedDateTime) : undefined,
        reason: cpl.VoidReason,
      },
      errorMessage: cpl.ErrorMessage,
      isValidationRequired: false,

      addressOne: cpl.Address,
      addressTwo: cpl.Address2,
      city: cpl.City,
      state: {
        code: cpl.StateCode || "",
        abbr: cpl.StateAbbr || "",
      },
      zipCode: maskZipCode(cpl.Zip),
      loanNumber: cpl.LoanNumber,
      successorLanguage: cpl.SuccessorLanguage,
      attentionTo: cpl.AttentionTo,
      displayOrder: cpl.DisplayOrder,

      isForeignAddress: cpl.IsForeignAddress || 0,
      foreignCountry: cpl.ForeignCountry,
      foreignState: cpl.ForeignState,
    });
  });

  return mappedCpls;
};

export const createAals = (aalproducts: Array<AALProduct> | undefined) => {
  let mappedAals = new Array<AAL>();
  aalproducts?.forEach((aal) => {
    let selectedParties = new Array<string>();
    //let selectedBuyerBorrower = new Array<string>();
    let selectedAddlParties = new Array<string>();
    let orderParties = new Array<OrderParty>();
    aal.OrderParties?.forEach((p) => {
      if (
        p.IntegrationKey &&
        (p.PartyRoleTypeCode === aal.CoveredParty.toUpperCase() ||
          p.PartyRoleTypeCode === PartyRoleType.AdditionalParty)
      ) {
        selectedParties.push(p.IntegrationKey || "");
        if (p.PartyRoleTypeCode === PartyRoleType.AdditionalParty) {
          selectedAddlParties.push(p.IntegrationKey);
        }
      }

      orderParties.push({
        filePartyId: p.FilePartyID || 0,
        identityKey: p.IdentityKey || 0,
        fileID: p.FileID || 0,
        integrationKey: p.IntegrationKey,
        filePartyKey: p.IntegrationKey,
        filePartyDataID: p.FilePartyDataID || 0,
        partyRoleTypeCode: p.PartyRoleTypeCode || "",
        partyTypeCode: p.PartyTypeCode || "",
        orderPartyID: p.OrderPartyID,
        orderPartyDataID: p.OrderPartyDataID,
        orderID: p.OrderID,
        addressOne: p.Address || "",
        addressTwo: p.Address2 || "",
        addressThree: p.Address3 || "",
        city: p.City || "",
        state: {
          code: p.StateCode || "",
          abbr: p.StateAbbr || "",
        },
        zipCode: maskZipCode(p.Zip),
        suffixType: {
          code: p.SuffixTypeCode || "",
          name: p.SuffixTypeName || "",
        },
        isExcludedOnCPL: p.IsExcludedOnCPL ? !!p.IsExcludedOnCPL : false,
        isExcludedOnAAL: p.IsExcludedOnAAL ? !!p.IsExcludedOnAAL : false,
        partyFirstName: p.PartyFirstName || "",
        partyMiddleName: p.PartyMiddleName || "",
        partyLastName: p.PartyLastName || "",
        companyName: p.PartyCompanyName || "",
        individualAttorney: p.PartyAdditionalName || "",
        loanNumber: p.LoanNumber || "",
        attentionTo: p.AttentionTo || "",
        successorLanguage: p.SuccessorLanguage || "",
      });
    });

    let documents = new Array<Document>();
    aal.Documents?.forEach((d) => {
      documents.push({
        integrationKey: d.IntegrationKey,
        id: d.DocumentID,
        fileID: d.FileID,
        orderID: d.OrderID,
        isHidden: d.IsHidden,
        fileTypeCode: d.DocumentFileTypeCode,
        fileName: d.DocumentFileName,
        sourceTypeCode: d.DocumentSourceTypeCode,
        typeCode: d.DocumentTypeCode,
        createdDate: d.DocumentCreatedDate,
        createdByUserName: d.DocumentCreatedByUserName,
      });
    });

    mappedAals.push({
      isDefault: true,
      isDirty: aal.IsDirty,
      id: aal.ProductID,
      fileID: aal.FileID,
      orderID: aal.OrderID,
      originalOrderID: aal.OriginalOrderID,

      identityKey: aal.IdentityKey,
      integrationKey: aal.IntegrationKey,
      orderStatusTypeCode: aal.OrderStatusTypeCode,
      orderStatusTypeName: aal.OrderStatusTypeName,
      addresseeNames: aal.AddresseeNames ? aal.AddresseeNames : "",
      addresseeNamesData: aal.AddresseeNameData
        ? parseJsonToIData(aal.AddresseeNameData)
        : { segments: new List<ISegment>([]), deselectedOptions: [] },
      buyerBorrower: aal.BuyerBorrower,
      seller: aal.Seller ? aal.Seller : "",
      addlParties: selectedAddlParties,
      effectiveDate: parseDateString(aal.EffectiveDate),
      coveredParty: aal.CoveredParty,
      productReferenceID: aal.ProductReferenceID,
      issueDate: aal.IssueDate,
      issueDateTime: aal.IssueDateTime
        ? new Date(aal.IssueDateTime)
        : undefined,
      revisionDate: aal.RevisionDate,
      revisionDateTime: aal.RevisionDateTime
        ? new Date(aal.RevisionDateTime)
        : undefined,
      form: aal.Form || "",
      formID: aal.FormID,
      formVersion: aal.FormVersion,
      formTemplateName: aal.FormTemplateName,
      opaIdentifier: aal.OPAIdentifier,
      isAgencyExclusive: aal.IsAgencyExclusive,
      liabilityAmount: aal.LiabilityAmount,

      // Location Info
      altaRegistryID: aal.ALTARegistryID,
      // contractID: aal.ContractID,
      // legacyID: aal.LegacyID,
      // locationUniqueID: aal.LocationUniqueID,
      territoryID: aal.TerritoryID,
      locationID: aal.PricingDetail?.LocationID,
      //locationName: aal.PricingDetail?.LocationName,
      locationDisplayName: aal.LocationDisplayName,
      locationLegalName: aal.LocationLegalName,

      includeAllBranches: aal.Branch === "None" ? false : true,
      documents: documents,
      orderParties: orderParties,
      //printAttorneyOnly: aal.PrintAttorneyOnly ? true : false,
      void: {
        date: aal.VoidedDate,
        dateTime: aal.VoidedDateTime ? new Date(aal.VoidedDateTime) : undefined,
        reason: aal.VoidReason,
      },
      errorMessage: aal.ErrorMessage,
      isValidationRequired: false,
      addressOne: aal.Address,
      addressTwo: aal.Address2,
      city: aal.City,
      state: {
        code: aal.StateCode || "",
        abbr: aal.StateAbbr || "",
      },
      zipCode: maskZipCode(aal.Zip),
      counsel: aal.Counsel,
      loanNumber: aal.LoanNumber,
      successorLanguage: aal.SuccessorLanguage,
      attentionTo: aal.AttentionTo,
      displayOrder: aal.DisplayOrder,
      documentOrderData: createDocumentOrderData(aal.DocumentOrderData),
      pricingDetail: {
        integrationKey: aal.PricingDetail?.IntegrationKey || uuidv4(),
        orderID: aal.OrderID || 0,
        fileID: aal.FileID || 0,
        filePricingID: aal.PricingDetail?.FilePricingID || 0,
        transCode: aal.PricingDetail?.TransCode,
        filePricingDetailID: aal.FilePricingDetailID || 0,
        liability: aal.PricingDetail?.Liability || 0.0,
        actualFee: aal.PricingDetail?.ActualFee || 0.0,
        originalActualFee: aal.PricingDetail?.ActualFee || 0.0,
        calculatedFee: aal.PricingDetail?.CalculatedFee || 0.0,
        actualRiskRate: aal.PricingDetail?.ActualRiskRate || 0.0,
        calculatedRiskRate: aal.PricingDetail?.CalculatedRiskRate || 0.0,
        actualRemittance: aal.PricingDetail?.ActualRemittance || 0.0,
        calculatedRemittance: aal.PricingDetail?.CalculatedRemittance || 0.0,
        actualRetention: aal.PricingDetail?.ActualRetention || 0.0,
        calculatedRetention: aal.PricingDetail?.CalculatedRetention || 0.0,
        actualPremiumTax: aal.PricingDetail?.ActualPremiumTax || 0.0,
        calculatedPremiumTax: aal.PricingDetail?.CalculatedPremiumTax || 0.0,

        isReissue: aal.PricingDetail?.IsReissue || false,
        isSimultaneous: aal.PricingDetail?.IsSimultaneous || false,
        isLeasehold: aal.PricingDetail?.IsLeasehold || false,

        // isReported: aal.PricingDetail?.IsReported,
        // lastReportedDate: aal.PricingDetail?.LastReportedDate,
        // isReportable: aal.PricingDetail?.IsReportable,
        // isReadyToBeReported: aal.PricingDetail?.IsReadyToBeReported,

        isBilled: aal.PricingDetail?.IsBilled,
        isBillable: aal.PricingDetail?.IsBillable,
        isReadyToBeBilled: aal.PricingDetail?.IsReadyToBeBilled,
        lastBilledDate: aal.PricingDetail?.LastBilledDate,

        altPricingReferenceID: aal.PricingDetail?.AltPricingReferenceID,
        lastErrorMessage: aal.PricingDetail?.LastErrorMessage,
        isFeeError: aal.PricingDetail?.IsFeeError,
        //isManualFee: aal.PricingDetail?.IsManualFee,

        // Location Info
        contractID: aal.PricingDetail?.ContractID,
        legacyID: aal.PricingDetail?.LegacyID,
        locationUniqueID: aal.PricingDetail?.LocationUniqueID,
        locationID: aal.PricingDetail?.LocationID,
        //locationName: aal.PricingDetail?.LocationName,
        locationDisplayName: aal.PricingDetail?.LocationDisplayName,
        locationLegalName: aal.PricingDetail?.LocationLegalName,
        internalFileNumber: aal.PricingDetail?.InternalFileNumber,
      },

      isForeignAddress: aal.IsForeignAddress || 0,
      foreignCountry: aal.ForeignCountry,
      foreignState: aal.ForeignState,
    });
  });

  return mappedAals;
};

export const createJackets = (
  jackets: Array<JacketProduct> | undefined
) => {
  if (!jackets) return;
  return jackets.map(buildJacket);
};

export const createStandaloneEndorsements = (
  standaloneEndorsements: Array<StandaloneEndorsementProduct> | undefined
) => {
  if (!standaloneEndorsements) return;
  const mappedStandaloneEndorsements: StandaloneEndorsement[] =
    standaloneEndorsements.map((standalone) => {
      return {
        isLocationDefault: false,
        errorMessage: standalone.ErrorMessage,
        isDirty: standalone.IsDirty,
        id: standalone.ProductID,
        identityKey: standalone.IdentityKey,
        fileID: standalone.FileID,
        orderID: standalone.OrderID,
        integrationKey: standalone.IntegrationKey,
        orderStatusTypeCode: standalone.OrderStatusTypeCode,
        orderStatusTypeName: standalone.OrderStatusTypeName,
        issueDate: standalone.IssueDate ?? null,
        issueDateTime: standalone.IssueDateTime
          ? new Date(standalone.IssueDateTime)
          : null,
        originalJacketDate: standalone.OriginalJacketDate
          ? new Date(standalone.OriginalJacketDate)
          : null,
        originalJacketLiability: standalone.OriginalJacketLiability,
        originalJacketNumber: standalone.OriginalJacketNumber,
        originalJacketType: standalone.OriginalJacketType,
        displayOrder: standalone.DisplayOrder,

        altaRegistryID: standalone.ALTARegistryID,
        territoryID: standalone.TerritoryID,
        locationID: standalone.PricingDetail?.LocationID,
        //locationName: standalone.PricingDetail?.LocationName,
        locationDisplayName: standalone.PricingDetail?.LocationDisplayName,
        underwriterTypeCode: standalone.PricingDetail?.UnderwriterTypeCode,

        endorsements: mapStandaloneEndorsementsFromApi(standalone.Endorsements),
        pricingDetail: {
          integrationKey: standalone.PricingDetail?.IntegrationKey || uuidv4(),
          orderID: standalone.OrderID || 0,
          fileID: standalone.FileID || 0,
          filePricingID: standalone.PricingDetail?.FilePricingID || 0,
          filePricingDetailID:
            standalone.PricingDetail?.FilePricingDetailID || 0,
          liability: standalone.PricingDetail?.Liability || 0,

          // Location info
          contractID: standalone.PricingDetail?.ContractID,
          legacyID: standalone.PricingDetail?.LegacyID,
          locationID: standalone.PricingDetail?.LocationID,
          locationUniqueID: standalone.PricingDetail?.LocationUniqueID,
          //locationName: standalone.PricingDetail?.LocationName,
          locationDisplayName: standalone.PricingDetail?.LocationDisplayName,
          locationLegalName: standalone.PricingDetail?.LocationLegalName,
          underwriterTypeCode: standalone.PricingDetail?.UnderwriterTypeCode,
          isBilled: standalone.PricingDetail?.IsBilled,
          isBillable: standalone.PricingDetail?.IsBillable,
          isReadyToBeBilled: standalone.PricingDetail?.IsReadyToBeBilled,
          lastBilledDate: standalone.PricingDetail?.LastBilledDate,
          internalFileNumber: standalone.PricingDetail?.InternalFileNumber,
        },
      };
    });

  return mappedStandaloneEndorsements;
};

const mapStandaloneEndorsementsFromApi = (endorsements?: EndorsementApi[]) => {
  if (!endorsements || endorsements.length === 0) return [];

  endorsements.sort((a: EndorsementApi, b: EndorsementApi) =>
    a.EndorsementStatusTypeCode === EndorsementStatusType.Pending
      ? 0
      : a.EndorsementName > b.EndorsementName
        ? 1
        : -1
  );
  const mappedEndorsements: Endorsement[] = endorsements.map((e, index) => {
    return {
      errorMessage: e.ErrorMessage,
      isDirty: e.IsDirty,
      identityKey: e.IdentityKey,
      effectiveDate: e.EffectiveDate ? new Date(e.EffectiveDate) : null,
      endorsementID: e.EndorsementID,
      productItemID: e.ProductItemID,
      endorsementName: e.EndorsementName,
      endorsementStatusTypeCode: e.EndorsementStatusTypeCode,
      endorsementNumber: e.EndorsementNumber,
      isSFE: e.IsSFE === 1 ? true : false,
      isSelected: e.IsSFE === 1 ? true : false,
      issueDate: e.IssueDate,
      issueDateTime: e.IssueDateTime ? new Date(e.IssueDateTime) : null,
      revisionDate: e.RevisionDate,
      revisionDateTime: e.RevisionDateTime
        ? new Date(e.RevisionDateTime)
        : null,
      voidedDate: e.VoidedDate,
      voidedDateTime: e.VoidedDateTime ? new Date(e.VoidedDateTime) : null,
      filePricingDetailID: e.PricingDetail?.FilePricingDetailID || 0,
      integrationKey: e.IntegrationKey,
      altEndorsementID: e.AltEndorsementID,
      endorsementVersion: e.EndorsementVersion,
      opaIdentifier: e.OPAIdentifier,
      isIssuedAfterJacket: e.IsIssuedAfterJacket,
      pricingDetail: {
        filePricingDetailID: e.PricingDetail?.FilePricingDetailID
          ? e.PricingDetail?.FilePricingDetailID
          : 0,
        fileID: e.PricingDetail?.FileID ? e.PricingDetail?.FileID : 0,
        filePricingID: e.PricingDetail?.FilePricingID || 0,
        orderID: e.PricingDetail?.OrderID ? e.PricingDetail?.OrderID : 0,
        liability: e.PricingDetail?.Liability
          ? e.PricingDetail?.Liability
          : 0.0,
        integrationKey: e.PricingDetail?.IntegrationKey
          ? e.PricingDetail?.IntegrationKey
          : uuidv4(),
        transCode: e.PricingDetail?.TransCode,
        transCodeDescription: e.PricingDetail?.TransCodeDescription,
        sortId: index,
        altPricingReferenceID: e.PricingDetail?.AltPricingReferenceID,
        pricingRateType: e.PricingDetail?.PricingRateType,
        pricingRateTypeData: e.PricingDetail?.PricingRateTypeData,
        actualFee: e.PricingDetail?.ActualFee || 0.0,
        originalActualFee: e.PricingDetail?.ActualFee || 0.0,
        calculatedFee: e.PricingDetail?.CalculatedFee || 0.0,
        actualRiskRate: e.PricingDetail?.ActualRiskRate || 0.0,
        calculatedRiskRate: e.PricingDetail?.CalculatedRiskRate || 0.0,
        actualRemittance: e.PricingDetail?.ActualRemittance || 0.0,
        calculatedRemittance: e.PricingDetail?.CalculatedRemittance || 0.0,
        actualRetention: e.PricingDetail?.ActualRetention || 0.0,
        calculatedRetention: e.PricingDetail?.CalculatedRetention || 0.0,
        actualPremiumTax: e.PricingDetail?.ActualPremiumTax || 0.0,
        calculatedPremiumTax: e.PricingDetail?.CalculatedPremiumTax || 0.0,

        // isReported: e.PricingDetail?.IsReported,
        // lastReportedDate: e.PricingDetail?.LastReportedDate,
        // isReportable: e.PricingDetail?.IsReportable,
        // isReadyToBeReported: e.PricingDetail?.IsReadyToBeReported,

        isBilled: e.PricingDetail?.IsBilled,
        isBillable: e.PricingDetail?.IsBillable,
        isFeeError: e.PricingDetail?.IsFeeError,
        //isManualFee: e.PricingDetail?.IsManualFee,
        isReadyToBeBilled: e.PricingDetail?.IsReadyToBeBilled,
        lastBilledDate: e.PricingDetail?.LastBilledDate,

        // Location info
        contractID: e.PricingDetail?.ContractID,
        legacyID: e.PricingDetail?.LegacyID,
        locationID: e.PricingDetail?.LocationID,
        //locationName: e.PricingDetail?.LocationName,
        locationDisplayName: e.PricingDetail?.LocationDisplayName,
        locationLegalName: e.PricingDetail?.LocationLegalName,
        locationUniqueID: e.PricingDetail?.LocationUniqueID,
      },

      // Also need to set endormsement's locaiton info
      // Location info
      contractID: e.PricingDetail?.ContractID,
      legacyID: e.PricingDetail?.LegacyID,
      locationID: e.PricingDetail?.LocationID,
      //locationName: e.PricingDetail?.LocationName,
      locationDisplayName: e.PricingDetail?.LocationDisplayName,
      locationLegalName: e.PricingDetail?.LocationLegalName,
      locationUniqueID: e.PricingDetail?.LocationUniqueID,
    };
  });

  return mappedEndorsements;
};

export const createFileActivity = (fileActivities: Array<any>) => {
  const hasFileActivities = fileActivities && fileActivities.length > 0;
  if (!hasFileActivities) return;

  let mappedActivity = new Array<FileActivity>();
  fileActivities?.forEach((activity) => {
    mappedActivity.push({
      fileActivityID: activity.FileActivityID,
      description: activity.Activity,
      date: new Date(activity.ActivityDateTime),
      userID: activity.ActivityUserID,
      userName: activity.ActivityUserName,
      fileID: activity.FileID,
      originationSource: activity.OriginationSource ?? "",
      forStaffViewOnly :activity.ForStaffViewOnly,
    });
  });

  return mappedActivity;
};

export const createProperties = (properties: any) => {
  let mappedProperties = new Array<Property>();
  properties.forEach((p: any) => {
    mappedProperties.push({
      id: p.PropertyID,
      fileID: p.FileID,
      identityKey: p.IdentityKey,
      integrationKey: p.IntegrationKey,
      addressOne: p.Address || "",
      addressTwo: p.Address2 || "",
      city: p.City || "",
      county: {
        code: p.CountyCode || "",
        // p.CountyName === "District Of Columbia"
        //   ? "District of Columbia"
        //   : p.CountyCode || "",
        name:
          p.CountyName === "District Of Columbia"
            ? "District of Columbia"
            : p.CountyName || "",
      },
      legalDescription: p.PropertyIdentifiers || "",
      propertyType: p.PropertyTypeCode || "",
      propertyAddressTypeCode: p.PropertyAddressTypeCode,
      state: {
        abbr: p.StateAbbr || "",
        code: p.StateCode || "",
      },
      stateOriginal: {
        abbr: p.StateAbbr || "",
        code: p.StateCode || "",
      },
      zipCode: maskZipCode(p.Zip),
      taxCode: { text: p.TaxCode || "", value: p.TaxCode },
      showTaxCode: p.TaxCode ? true : false,
      isDefault: true,
    });
  });
  return mappedProperties;
};

export const createParties = (
  partyRoleTypeCode: string,
  parties?: Array<Party>
) => {
  const hasParties = parties && parties.length > 0;
  if (!hasParties) return;

  let mappedParties = new Array<GeneralFileParty>();
  parties?.forEach((party) => {
    if (party.PartyRoleTypeCode !== partyRoleTypeCode) return;

    mappedParties.push({
      filePartyId: party.FilePartyID || 0,
      identityKey: party.IdentityKey || 0,
      fileID: party.FileID || 0,
      integrationKey: party.IntegrationKey,
      filePartyKey: party.IntegrationKey,
      filePartyDataID: party.FilePartyDataID || 0,
      partyRoleTypeCode: party.PartyRoleTypeCode || "",
      partyTypeCode: party.PartyTypeCode || "",
      addressOne: party.Address || "",
      addressTwo: party.Address2 || "",
      addressThree: party.Address3 || "",
      city: party.City || "",
      state: {
        code: party.StateCode || "",
        abbr: party.StateAbbr || "",
      },
      zipCode: maskZipCode(party.Zip),
      suffixType: {
        code: party.SuffixTypeCode || "",
        name: party.SuffixTypeName || "",
      },

      isForeignAddress: party.IsForeignAddress || 0,
      foreignCountry: party.ForeignCountry,
      foreignState: party.ForeignState,

      isExcludedOnCPL: party.IsExcludedOnCPL ? !!party.IsExcludedOnCPL : false,
      isExcludedOnAAL: party.IsExcludedOnAAL ? !!party.IsExcludedOnAAL : false,
      saveToContacts: party.SaveToContacts ? !!party.SaveToContacts : false,
      partyFirstName: party.PartyFirstName || "",
      partyMiddleName: party.PartyMiddleName || "",
      partyLastName: party.PartyLastName || "",
      companyName: party.PartyCompanyName || "",
      individualAttorney: party.PartyAdditionalName || "",
      loanNumber: party.LoanNumber || "",
      attentionTo: party.AttentionTo || "",
      successorLanguage: party.SuccessorLanguage || "",
      selectedAdditionalPartyID: party.FilePartyID
        ? party.FilePartyID.toString()
        : "",
      favorite: party.Favorite === 1 ? true : false,
    });
  });

  return mappedParties;
};

export const createFilePricing = (filePricing: FilePricing | undefined) => {
  let pricing: Pricing = {
    disableSimultaneousRate: filePricing?.DisableSimultaneousRate || false,
    isCoInsurance: filePricing?.IsCoInsurance || false,
    coInsurancePct: filePricing?.CoInsurancePct,
    coInsurancePctInitial: filePricing?.CoInsurancePct,
    coInsurancePctPrevious: undefined,
    integrationKey: filePricing?.IntegrationKey || "",
    fileID: filePricing?.FileID || 0,
    paymentSheetID: filePricing?.PaymentSheetID,
    identityKey: filePricing?.IdentityKey || 0,
    id: filePricing?.FilePricingID || 0,
    isActualCalc: filePricing?.IsActualCalc || false,
    isCalculationRequired: filePricing?.IsCalculationRequired || false,
    isDisclosed_TRIDCalc: filePricing?.IsDisclosed_TRIDCalc || false,
    isExcludedFromAutoReport: !!(filePricing?.IsExcludedFromAutoReport || false),
    isIntegratedPricing: filePricing?.IsIntegratedPricing || false,
    isOverride: filePricing?.IsOverride || false,
    isSimultaneousRate: filePricing?.IsSimultaneousRate || false,
    isEffectiveDateAllowed: true,
    prevUserSimulteneousRate: filePricing?.IsSimultaneousRate || false,
    isSimultaneousRateAllowed: !(filePricing?.DisableSimultaneousRate || false), //default for UI
    overrideReason: filePricing?.OverrideReasonTypeCode || "",
    overrideReasonInitial: filePricing?.OverrideReasonTypeCode || "",
    partitionYear: filePricing?.PartitionYear,
    rateEffectiveDate: parseDateString(filePricing?.RateEffectiveDate),
    showAgentRetention: filePricing?.ShowAgentRetention || false,
    showCoInsurance: filePricing?.ShowCoInsurance || false,
    showLiabilityAmount: filePricing?.ShowLiabilityAmount || false,
    showOPNLiabilityAmount: filePricing?.ShowOPNLiabilityAmount || false,
    showOPNCoverageType: filePricing?.ShowOPNCoverageType || false,
    showOPNFormType: filePricing?.ShowOPNFormType || false,
    showOPNPolicyDate: filePricing?.ShowOPNPolicyDate || false,
    showOPNPolicyNumber: filePricing?.ShowOPNPolicyNumber || false,
    showOPNPriorPolicyLast10Yrs: filePricing?.ShowOPNPriorPolicyLast10Yrs || false,
    showReissue: filePricing?.ShowReissue || false,
    showReissueCheckbox: filePricing?.ShowReissueCheckbox || false,
    showReissueColumnLabel: filePricing?.ShowReissueColumnLabel || false,
    showReissueEdit: filePricing?.ShowReissueEdit || false,
    showRiskRate: filePricing?.ShowRiskRate || false,
    showTax: filePricing?.ShowTax || false,
    taxCode: filePricing?.TaxCode,
    errorMessage: filePricing?.ErrorMessage,
    infoMessage: filePricing?.InfoMessage,
    infoMessageFontColor: filePricing?.InfoMessageFontColor,
    infoMessageFontWeight: filePricing?.InfoMessageFontWeight,

    policy: {
      liabilityLabel: filePricing?.OPNLiabilityLabel,
      windowTitle: filePricing?.OPNWindowTitle,
      showCoverageType: filePricing?.ShowOPNCoverageType || false,
      showFormType: filePricing?.ShowOPNFormType || false,
      showLiabilityAmount: filePricing?.ShowOPNLiabilityAmount || false,
      showPolicyDate: filePricing?.ShowOPNPolicyDate || false,
      showOPNPriorPolicyLast10Yrs:
        filePricing?.ShowOPNPriorPolicyLast10Yrs || false,
    },
    isUpdated: true,

    agencyName: filePricing?.AgencyName,
    legacyID: filePricing?.LegacyID,
    locationID: filePricing?.LocationID,
    //locationName: filePricing?.LocationName,
    locationDisplayName: filePricing?.LocationDisplayName,
    locationUniqueID: filePricing?.LocationUniqueID,

    isBilled: filePricing?.IsBilled,
    isBillable: filePricing?.IsBillable,
    isReadyToBeBilled: filePricing?.IsReadyToBeBilled,
    reportOptionTypeCode: filePricing?.ReportOptionTypeCode,
    lastBilledDate: filePricing?.LastBilledDate,

    // Total amounts
    totalActualCombinedFee: filePricing?.TotalActualCombinedFee,
    totalActualFee: filePricing?.TotalActualFee,
    totalActualPremiumTax: filePricing?.TotalActualPremiumTax,
    totalActualRemittance: filePricing?.TotalActualRemittance,
    totalActualRetention: filePricing?.TotalActualRetention,
    totalActualRiskRate: filePricing?.TotalActualRiskRate,
    totalAmountDue: filePricing?.TotalAmountDue,
    totalAmountPaidSoFar: filePricing?.TotalAmountPaidSoFar,
    totalBilledActualCombinedFee: filePricing?.TotalBilledActualCombinedFee,
    totalBilledActualFee: filePricing?.TotalBilledActualFee,
    totalBilledActualPremiumTax: filePricing?.TotalBilledActualPremiumTax,
    totalBilledActualRemittance: filePricing?.TotalBilledActualRemittance,
    totalBilledActualRetention: filePricing?.TotalBilledActualRetention,
    totalBilledActualRiskRate: filePricing?.TotalBilledActualRiskRate,
    totalBilledAmountDue: filePricing?.TotalBilledAmountDue,
    totalRemainingBalance: filePricing?.TotalRemainingBalance,

    isValidationRequired: false,
  };
  return pricing;
};

export const createFilePricingDetails = (
  filePricingDetails: Array<FilePricingDetail> | undefined,
  cpls: Array<CPLProduct> | undefined,
  aals: Array<AALProduct> | undefined,
  jackets: Array<JacketProduct> | undefined,
  standaloneEndorsements: Array<StandaloneEndorsementProduct> | undefined
): FilePricingDetailUi[] => {
  let filePricingDetailsUi: Array<FilePricingDetailUi> = [];
  let allPricings: Array<{
    filePricingDetailID: number | undefined;
    productTypeCode: string;
    orderStatusTypeCode: string;
    effectiveDate?: string;
  }> = [];
  cpls?.forEach((p) => {
    allPricings.push({
      filePricingDetailID: p.FilePricingDetailID,
      productTypeCode: ProductType.Cpl,
      orderStatusTypeCode: p.OrderStatusTypeCode,
      effectiveDate: p.EffectiveDate ?? undefined,
    });
  });
  aals?.forEach((p) => {
    allPricings.push({
      filePricingDetailID: p.FilePricingDetailID,
      productTypeCode: ProductType.Aal,
      orderStatusTypeCode: p.OrderStatusTypeCode,
      effectiveDate: p.EffectiveDate ?? undefined,
    });
  });
  jackets?.forEach((p) => {
    allPricings.push({
      filePricingDetailID: p.FilePricingDetailID,
      productTypeCode: ProductType.Jacket,
      orderStatusTypeCode: p.OrderStatusTypeCode,
      effectiveDate: p.EffectiveDate ?? undefined,
    });
    p.Endorsements?.forEach((e) => {
      allPricings.push({
        filePricingDetailID: e.FilePricingDetailID,
        productTypeCode: ProductType.Endorsement,
        orderStatusTypeCode: p.OrderStatusTypeCode,
        effectiveDate: e.EffectiveDate ?? undefined,
      });
    });
  });
  standaloneEndorsements?.forEach((p) => {
    allPricings.push({
      filePricingDetailID: p.FilePricingDetailID,
      productTypeCode: ProductType.StandaloneEndorsement,
      orderStatusTypeCode: p.OrderStatusTypeCode,
      effectiveDate: p.OriginalJacketDate ?? undefined,
    });
    p.Endorsements?.forEach((e) => {
      allPricings.push({
        filePricingDetailID: e.FilePricingDetailID,
        productTypeCode: ProductType.Endorsement,
        orderStatusTypeCode: p.OrderStatusTypeCode,
        effectiveDate: e.EffectiveDate ?? undefined,
      });
    });
  });

  filePricingDetails?.forEach((papi) => {
    const pricing = allPricings.find(
      (ap) => ap.filePricingDetailID === papi.FilePricingDetailID
    );

    filePricingDetailsUi.push({
      actualFee: papi.ActualFee,
      actualPremiumTax: papi.ActualPremiumTax,
      actualRemittance: papi.ActualRemittance,
      actualRetention: papi.ActualRetention,
      actualRiskRate: papi.ActualRiskRate,
      amountDue: papi.AmountDue,
      calculatedFee: papi.CalculatedFee,
      calculatedPremiumTax: papi.CalculatedPremiumTax,
      calculatedRemittance: papi.CalculatedRemittance,
      calculatedRetention: papi.CalculatedRetention,
      calculatedRiskRate: papi.CalculatedRiskRate,
      chargeType: papi.ChargeType,
      coverageType: papi.CoverageType,
      coverageTypeData: papi.CoverageTypeData,
      fileID: papi.FileID,
      filePricingID: papi.FilePricingID,
      id: papi.FilePricingDetailID,
      identityKey: papi.IdentityKey,
      integrationKey: papi.IntegrationKey,
      isConsent: papi.IsConsent,
      isDeleted: papi.IsDeleted,
      isDirty: papi.IsDirty,
      isHidden: papi.IsHidden,
      isNew: papi.IsNew,
      isPrevPolicyLast10Yrs: papi.IsPrevPolicyLast10Yrs,
      isReadOnly: papi.IsReadOnly,
      isReissue: papi.IsReissue,
      isSimultaneous: papi.IsSimultaneous,
      isLeasehold: papi.IsLeasehold,
      liability: papi.Liability || 0,
      orderID: papi.OrderID,
      partitionYear: papi.PartitionYear,
      paymentSheetID: papi.PaymentSheetID,
      pricingRateType: papi.PricingRateType,
      pricingRateTypeData: papi.PricingRateTypeData,
      pricingTransactionTypeCode: papi.PricingTransactionTypeCode,
      pricingFeeTypeCode: papi.PricingFeeTypeCode,
      product: papi.Product,
      productItemID: papi.ProductItemID,
      productTypeCode:
        pricing && pricing.productTypeCode
          ? pricing.productTypeCode
          : undefined,
      transCode: papi.TransCode,
      transCodeDescription: papi.TransCodeDescription,
      orderStatusTypeCode:
        pricing && pricing.orderStatusTypeCode
          ? pricing.orderStatusTypeCode
          : undefined,
      altPricingReferenceID: papi.AltPricingReferenceID,
      lastErrorMessage: papi.LastErrorMessage,
      isFeeError: papi.IsFeeError,
      //isManualFee: papi.IsManualFee,
      // isReportable: papi.IsReportable,
      // isReadyToBeReported: papi.IsReadyToBeReported,
      // isReported: papi.IsReportable,

      isBillable: papi.IsBillable,
      isReadyToBeBilled: papi.IsReadyToBeBilled,
      isBilled: papi.IsBilled,
      lastBilledDate: papi.LastBilledDate,

      productEffectiveDate: pricing?.effectiveDate,
    });
  });

  return filePricingDetailsUi;
};

export const updateJacketFilePricingDetails = (
  currentFilePricingDetailsUI: Array<FilePricingDetailUi>,
  jackets: Array<JacketProduct> | undefined,
): FilePricingDetailUi[] => {

  // UI existing model: currentFilePricingDetailsUI
  // API model new: newFilePricingDetails

  const newFilePricingDetails: Array<FilePricingDetail> = [];

  jackets?.forEach((j) => {
    if (j.PricingDetail) newFilePricingDetails.push(j.PricingDetail);
    j.Endorsements?.forEach((je) => {
      if (je.PricingDetail) newFilePricingDetails.push(je.PricingDetail);
    });
  });

  currentFilePricingDetailsUI?.forEach((uiFPD) => {
    const apiMatch = newFilePricingDetails?.find((apiFPD) => apiFPD.FilePricingDetailID === uiFPD.id);
    if (apiMatch) {
      uiFPD.actualFee = apiMatch.ActualFee;
      uiFPD.actualPremiumTax = apiMatch.ActualPremiumTax;
      uiFPD.actualRemittance = apiMatch.ActualRemittance;
      uiFPD.actualRetention = apiMatch.ActualRetention;
      uiFPD.actualRiskRate = apiMatch.ActualRiskRate;
      uiFPD.calculatedFee = apiMatch.CalculatedFee;
      uiFPD.calculatedPremiumTax = apiMatch.CalculatedPremiumTax;
      uiFPD.calculatedRemittance = apiMatch.CalculatedRemittance;
      uiFPD.calculatedRetention = apiMatch.CalculatedRetention;
      uiFPD.calculatedRiskRate = apiMatch.CalculatedRiskRate;
      uiFPD.chargeType = apiMatch.ChargeType;
      uiFPD.coverageType = apiMatch.CoverageType;
      uiFPD.coverageTypeData = apiMatch.CoverageTypeData;
      uiFPD.isReissue = apiMatch.IsReissue;
      uiFPD.isSimultaneous = apiMatch.IsSimultaneous;
      uiFPD.isLeasehold = apiMatch.IsLeasehold;
      uiFPD.liability = apiMatch.Liability || 0;
      uiFPD.pricingRateType = apiMatch.PricingRateType;
      uiFPD.pricingRateTypeData = apiMatch.PricingRateTypeData;
      uiFPD.pricingTransactionTypeCode = apiMatch.PricingTransactionTypeCode;
      uiFPD.pricingFeeTypeCode = apiMatch.PricingFeeTypeCode;
      uiFPD.transCode = apiMatch.TransCode;
      uiFPD.transCodeDescription = apiMatch.TransCodeDescription;
      uiFPD.altPricingReferenceID = apiMatch.AltPricingReferenceID;
      uiFPD.lastErrorMessage = apiMatch.LastErrorMessage;
      uiFPD.isFeeError = apiMatch.IsFeeError;
      //uiFPD.isManualFee = apiMatch.IsManualFee;
      uiFPD.isBillable = apiMatch.IsBillable;
      uiFPD.isReadyToBeBilled = apiMatch.IsReadyToBeBilled;
      uiFPD.isBilled = apiMatch.IsBilled;
      uiFPD.lastBilledDate = apiMatch.LastBilledDate;
    }
  });

  return currentFilePricingDetailsUI;
};

export const createDocumentFileData = (
  documentFileData: DocumentFileData | undefined
) => {
  let documentFileDataUI: DocumentFileDataUI = {
    documentFileDataID: documentFileData?.DocumentFileDataID || 0,
    identityKey: documentFileData?.IdentityKey,
    integrationKey: documentFileData?.IntegrationKey || "",
    filePricingID: documentFileData?.FilePricingID,
    fileID: documentFileData?.FileID,
    companyAddress: documentFileData?.CompanyAddress || "",
    companyAddress2: documentFileData?.CompanyAddress2 || "",
    companyCity: documentFileData?.CompanyCity || "",
    companyZipCode: documentFileData?.CompanyZipCode || "",
    companyCountry: documentFileData?.CompanyCountry || "",
    companyMailPhone: documentFileData?.CompanyMailPhone || "",
    companyMobilePhone: documentFileData?.CompanyMobilePhone || "",
    companyFaxNumber: documentFileData?.CompanyFaxNumber || "",
    companyPrimaryEmail: documentFileData?.CompanyPrimaryEmail || "",
  };
  return documentFileDataUI;
};

export const createDocumentOrderData = (
  documentOrderData: DocumentOrderData | undefined
) => {
  let documentOrderDataUI: DocumentOrderDataUI = {
    documentOrderDataID: documentOrderData?.DocumentOrderDataID || 0,
    identityKey: documentOrderData?.IdentityKey,
    integrationKey: documentOrderData?.IntegrationKey || "",
    filePricingID: documentOrderData?.FilePricingID,
    filePricingDetailID: documentOrderData?.FilePricingDetailID,
    fileID: documentOrderData?.FileID,
    orderID: documentOrderData?.OrderID,
    formPrefix: documentOrderData?.FormPrefix || "",
    locationAddress: documentOrderData?.LocationAddress || "",
    locationAddress2: documentOrderData?.LocationAddress2 || "",
    locationCity: documentOrderData?.LocationCity || "",
    locationZipCode: documentOrderData?.LocationZipCode || "",
    locationCountry: documentOrderData?.LocationCountry || "",
    locationMailPhone: documentOrderData?.LocationMailPhone || "",
    locationMobilePhone: documentOrderData?.LocationMobilePhone || "",
    locationFaxNumber: documentOrderData?.LocationFaxNumber || "",
    locationPrimaryEmail: documentOrderData?.LocationPrimaryEmail || "",
  };
  return documentOrderDataUI;
};

const hasPricingTax = (
  showTax: boolean,
  productType: string,
  policyType: string = ""
) => {
  return (
    showTax &&
    (productType === ProductType.Cpl ||
      productType === ProductType.Aal ||
      productType === ProductType.StandaloneEndorsement ||
      (productType === ProductType.Jacket &&
        policyType !== JacketFormType.Commitment))
  );
};

const getPricingTaxDescription = (
  productType: string,
  policyType: string | null = ""
) => {
  let TaxDescription = "";
  switch (productType) {
    case ProductType.Aal:
    case ProductType.Cpl:
      TaxDescription = `Closing Protection Letter Tax`;
      break;
    case ProductType.Jacket:
      TaxDescription = `${policyType} Policy Tax`;
      break;
    case ProductType.StandaloneEndorsement:
      TaxDescription = `Standalone Tax`;
      break;
  }

  return TaxDescription;
};

export const createPricingProducts = (
  isFileLocked: boolean,
  // isIntegratedPricing: boolean,
  showTax: boolean,
  jackets: Array<JacketProduct> | undefined,
  cpls: Array<CPLProduct> | undefined,
  aals: Array<AALProduct> | undefined,
  standaloneEndorsements: Array<StandaloneEndorsementProduct> | undefined,
  showFileSpecialCharges: boolean,
) => {

  let pricingProducts: PricingProduct[] = [];

  // Jackets
  jackets
    ?.filter((jacket) => jacket.OrderStatusTypeCode === OrderStatusType.Issued)
    ?.forEach((jacket) => {
      // Endorsements
      let productItems: PricingProductItem[] = [];

      jacket.Endorsements?.filter(
        (endorsement) =>
          endorsement.EndorsementStatusTypeCode === EndorsementStatusType.Issued
      )?.forEach((endorsement) => {
        productItems.push(
          mapPricingProductItem(
            endorsement.PricingDetail?.FilePricingDetailID ||
            endorsement.FilePricingDetailID,
            endorsement.EndorsementID,
            endorsement.PricingDetail?.Product ||
            endorsement.EndorsementName ||
            "",
            endorsement.PricingDetail
          )
        );
      });

      const originalPolicy = {
        coverageType: jacket.PricingDetail?.PrevPolicyCoverageType,
        date: jacket.OriginalJacketDate ?? undefined,
        liabilityAmount: jacket.OriginalJacketLiability ?? undefined,
        previouslyIssued: jacket.PricingDetail?.IsPrevPolicyLast10Yrs,
        policyNumber: jacket.OriginalJacketNumber ?? undefined,
        jacketType: jacket.OriginalJacketType ?? undefined,
      };

      pricingProducts.push(
        mapPricingProduct(
          PricingType.Product,
          ProductType.Jacket,
          jacket.OrderID || 0,
          jacket.ProductID,
          jacket.PricingDetail?.FilePricingDetailID ||
          jacket.FilePricingDetailID ||
          0,
          jacket.FormType,
          jacket.PricingDetail?.Product || jacket.Form || "",
          jacket.FormType === JacketFormType.Loan ||
            jacket.FormType === JacketFormType.Owners
            ? PricingProductSortOrder.JacketLoanOwner
            : PricingProductSortOrder.JacketOther,
          PricingProductSortOrder2.Product,
          jacket.IssueDate || new Date(0),
          jacket.OrderStatusDate || new Date(0),
          productItems,
          jacket.PricingDetail,
          jacket.RateTypes
            ? jacket.RateTypes?.map((type) => {
              return {
                text: type,
                value: type,
              };
            })
            : [],
          jacket.IntegrationKey,
          originalPolicy,
          undefined
        )
      );

      // Additional Charge rows
      jacket.JacketAdditionalCharges?.forEach((additionalCharge) => {
        pricingProducts.push(
          mapPricingProduct(
            PricingType.AdditionalCharge,
            ProductType.Jacket,
            jacket.OrderID || 0,
            jacket.ProductID,
            additionalCharge.FilePricingDetailID || additionalCharge.PricingDetail.FilePricingDetailID || 0,
            jacket.FormType,
            additionalCharge.PricingDetail?.Product || additionalCharge.AdditionalChargeType || "",
            jacket.FormType === JacketFormType.Loan ||
              jacket.FormType === JacketFormType.Owners
              ? PricingProductSortOrder.JacketLoanOwner
              : PricingProductSortOrder.JacketOther,
            PricingProductSortOrder2.AdditionalCharge,
            jacket.IssueDate || new Date(0),
            jacket.OrderStatusDate || new Date(0),
            [],
            additionalCharge.PricingDetail,
            [],
            undefined,
            undefined,
            undefined
          )
        );
  
      });

      // Special Charges rows
      jacket.JacketSpecialCharges?.forEach((specialCharge) => {
        pricingProducts.push(
          mapPricingProduct(
            PricingType.SpecialCharge,
            ProductType.Jacket,
            jacket.OrderID || 0,
            jacket.ProductID,
            specialCharge.FilePricingDetailID ||
              specialCharge.PricingDetail.FilePricingDetailID ||
              0,
            jacket.FormType,
            specialCharge.PricingDetail?.Product ||
              specialCharge.TransCodeDescription ||
              "",
            showFileSpecialCharges === true
              ? PricingProductSortOrder.FileSpecialCharge
              : jacket.FormType === JacketFormType.Loan ||
                jacket.FormType === JacketFormType.Owners
              ? PricingProductSortOrder.JacketLoanOwner
              : PricingProductSortOrder.JacketOther,
            PricingProductSortOrder2.SpecialCharges,
            jacket.IssueDate || new Date(0),
            jacket.OrderStatusDate || new Date(0),
            [],
            specialCharge.PricingDetail,
            [],
            undefined,
            undefined,
            undefined
          )
        );
  
      });      

      // add Tax row
      if (
        hasPricingTax(
          showTax,
          ProductType.Jacket,
          jacket.FormType
        )
      ) {
        const currentIndex = pricingProducts.length - 1;
        const { actualPremiumTaxSum, calculatedPremiumTaxSum, actualFeeSum, isBilled, isReadyToBeBilled } =
          getSumPricingTaxes(pricingProducts[currentIndex], isFileLocked);

        pricingProducts.push(
          mapPricingProduct(
            PricingType.Tax,
            ProductType.Jacket,
            jacket.OrderID || 0,
            jacket.ProductID,
            jacket.PricingDetail?.FilePricingDetailID ||
            jacket.FilePricingDetailID ||
            0,
            jacket.FormType,
            getPricingTaxDescription(ProductType.Jacket, jacket.FormType),
            jacket.FormType === JacketFormType.Loan ||
              jacket.FormType === JacketFormType.Owners
              ? PricingProductSortOrder.JacketLoanOwner
              : PricingProductSortOrder.JacketOther,
            PricingProductSortOrder2.Tax,              
            jacket.IssueDate || new Date(0),
            jacket.OrderStatusDate || new Date(0),
            [],
            mapPricingTax(actualPremiumTaxSum,
              calculatedPremiumTaxSum,
              jacket.PricingDetail?.IsBillable || 0,
              isBilled,
              isReadyToBeBilled),
            [],
            undefined,
            undefined,
            actualFeeSum
          )
        );
      }
    });

  // Cpls
  cpls
    ?.filter((cpl) => cpl.OrderStatusTypeCode === OrderStatusType.Issued)
    ?.forEach((cpl) => {
      pricingProducts.push(
        mapPricingProduct(
          PricingType.Product,
          ProductType.Cpl,
          cpl.OrderID || 0,
          cpl.ProductID || 0,
          cpl.PricingDetail?.FilePricingDetailID ||
          cpl.FilePricingDetailID ||
          0,
          "", // formType is only used for Jacket
          cpl.PricingDetail?.Product || "",
          PricingProductSortOrder.CPL,
          PricingProductSortOrder2.Product,
          cpl.IssueDate || new Date(0),
          cpl.OrderStatusDate || new Date(0),
          [], // CPL does not have productItem
          cpl.PricingDetail,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );

      // add Tax row
      if (hasPricingTax(showTax, ProductType.Cpl)) {
        const currentIndex = pricingProducts.length - 1;
        const { actualPremiumTaxSum, calculatedPremiumTaxSum, actualFeeSum, isBilled, isReadyToBeBilled } =
          getSumPricingTaxes(pricingProducts[currentIndex], isFileLocked);

        pricingProducts.push(
          mapPricingProduct(
            PricingType.Tax,
            ProductType.Cpl,
            cpl.OrderID || 0,
            cpl.ProductID || 0,
            cpl.PricingDetail?.FilePricingDetailID ||
            cpl.FilePricingDetailID ||
            0,
            "", // formType is only used for Jacket
            getPricingTaxDescription(ProductType.Cpl),
            PricingProductSortOrder.CPL,
            PricingProductSortOrder2.Tax,
            cpl.IssueDate || new Date(0),
            cpl.OrderStatusDate || new Date(0),
            [], // Tax does not have productItem
            mapPricingTax(actualPremiumTaxSum,
              calculatedPremiumTaxSum,
              cpl.PricingDetail?.IsBillable || 0,
              isBilled,
              isReadyToBeBilled),
            undefined,
            undefined,
            undefined,
            actualFeeSum
          )
        );
      }
    });

  // Aals
  aals
    ?.filter((aal) => aal.OrderStatusTypeCode === OrderStatusType.Issued)
    ?.forEach((aal) => {
      pricingProducts.push(
        mapPricingProduct(
          PricingType.Product,
          ProductType.Aal, // old value: ProductType.Cpl -- make sure this is okay.
          aal.OrderID || 0,
          aal.ProductID || 0,
          aal.PricingDetail?.FilePricingDetailID ||
          aal.FilePricingDetailID ||
          0,
          "", // formType is only used for Jacket
          aal.PricingDetail?.Product || "",
          PricingProductSortOrder.AAL,
          PricingProductSortOrder2.Product,
          aal.IssueDate || new Date(0),
          aal.OrderStatusDate || new Date(0),
          [], // AAL does not have productItem
          aal.PricingDetail,
          undefined,
          undefined,
          undefined,
          undefined
        )
      );

      // add Tax row
      if (hasPricingTax(showTax, ProductType.Aal)) {
        const currentIndex = pricingProducts.length - 1;
        const { actualPremiumTaxSum, calculatedPremiumTaxSum, actualFeeSum, isBilled, isReadyToBeBilled } =
          getSumPricingTaxes(pricingProducts[currentIndex], isFileLocked);

        pricingProducts.push(
          mapPricingProduct(
            PricingType.Tax,
            ProductType.Aal,
            aal.OrderID || 0,
            aal.ProductID || 0,
            aal.PricingDetail?.FilePricingDetailID ||
            aal.FilePricingDetailID ||
            0,
            "", // formType is only used for Jacket
            getPricingTaxDescription(ProductType.Aal),
            PricingProductSortOrder.AAL,
            PricingProductSortOrder2.Tax,            
            aal.IssueDate || new Date(0),
            aal.OrderStatusDate || new Date(0),
            [], // Tax does not have productItems
            mapPricingTax(actualPremiumTaxSum,
              calculatedPremiumTaxSum,
              aal.PricingDetail?.IsBillable || 0,
              isBilled,
              isReadyToBeBilled),
            undefined,
            undefined,
            undefined,
            actualFeeSum
          )
        );
      }
    });

  // Standalone Endorsement
  // Notes: Pending response from Tammy
  //        Standalone itself does not have Form and any pricing

  standaloneEndorsements
    ?.filter(
      (standalone) => standalone.OrderStatusTypeCode === OrderStatusType.Issued
    )
    ?.forEach((standalone) => {
      // Endorsements
      let productItems: PricingProductItem[] = [];

      standalone.Endorsements?.filter(
        (endorsement) =>
          endorsement.EndorsementStatusTypeCode === EndorsementStatusType.Issued
      )?.forEach((endorsement) => {
        productItems.push(
          mapPricingProductItem(
            endorsement.PricingDetail?.FilePricingDetailID ||
            endorsement.FilePricingDetailID,
            endorsement.EndorsementID,
            endorsement.PricingDetail?.Product ||
            endorsement.EndorsementName ||
            "",
            endorsement.PricingDetail
          )
        );
      });
      if (productItems.length > 0) {
        pricingProducts.push(
          mapPricingProduct(
            PricingType.Product,
            ProductType.StandaloneEndorsement,
            standalone.OrderID || 0,
            standalone.ProductID,
            standalone.PricingDetail?.FilePricingDetailID ||
            standalone.FilePricingDetailID ||
            0,
            standalone.OriginalJacketType || "",
            standalone.PricingDetail?.Product || "",
            standalone.OriginalJacketType === JacketFormType.Loan ||
              standalone.OriginalJacketType === JacketFormType.Owners
              ? PricingProductSortOrder.StandaloneLoanOwner
              : PricingProductSortOrder.StandaloneOther,
            PricingProductSortOrder2.Product,
            standalone.IssueDate || new Date(0),
            standalone.OrderStatusDate || new Date(0),
            productItems,
            standalone.PricingDetail,
            undefined,
            undefined,
            undefined,
            undefined
          )
        );

        // add Tax row
        if (
          hasPricingTax(
            showTax,
            ProductType.StandaloneEndorsement
          )
        ) {
          const currentIndex = pricingProducts.length - 1;
          const { actualPremiumTaxSum, calculatedPremiumTaxSum, actualFeeSum, isBilled, isReadyToBeBilled } =
            getSumPricingTaxes(pricingProducts[currentIndex], isFileLocked);

          pricingProducts.push(
            mapPricingProduct(
              PricingType.Tax,
              ProductType.StandaloneEndorsement,
              standalone.OrderID || 0,
              standalone.ProductID,
              standalone.PricingDetail?.FilePricingDetailID ||
              standalone.FilePricingDetailID ||
              0,
              standalone.OriginalJacketType || "",
              getPricingTaxDescription(
                ProductType.StandaloneEndorsement,
                standalone.OriginalJacketType
              ),
              standalone.OriginalJacketType === JacketFormType.Loan ||
                standalone.OriginalJacketType === JacketFormType.Owners
                ? PricingProductSortOrder.StandaloneLoanOwner
                : PricingProductSortOrder.StandaloneOther,
              PricingProductSortOrder2.Tax,                
              standalone.IssueDate || new Date(0),
              standalone.OrderStatusDate || new Date(0),
              [], // Tax does not have productItems
              mapPricingTax(actualPremiumTaxSum,
                calculatedPremiumTaxSum,
                standalone.PricingDetail?.IsBillable || 0,
                isBilled,
                isReadyToBeBilled),
              undefined,
              undefined,
              undefined,
              actualFeeSum
            )
          );
        }
      }
    });

  // sort by sortOrder, orderStatusDate, and sortOrder2
  return pricingProducts?.sort(
    (a, b) =>
      a.sortOrder.localeCompare(b.sortOrder) || // 1st order
      +new Date(a.issueDate.toString()) - +new Date(b.issueDate.toString()) || // 2nd order
      a.sortOrder2 - b.sortOrder2 // 3rd order
  );
};
