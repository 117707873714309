import { styled } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography ,
} from "@mui/material";
import {
  borderRadius,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { officeSolution } from "../data/officeSolutionsData";
import useTitle from "utils/custom-hooks/useTitle";

const StyledBoxContainer = styled(Box)({
  borderRadius: borderRadius.xlarge,
  backgroundColor: colors.white,
  fontFamily: fontFamily.primary,
  padding: padding.medium2,
  paddingLeft: padding.xlarge1,
  "& a": {
    color: colors.blue01,
    textDecoration: "none",
  },
});

const PrimaryHeading = styled(Typography)({
  color: colors.maroon02,
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge6,
  fontWeight: fontWeight.bold2,
  lineHeight: lineHeight.xxlarge,
  padding: padding.xlarge,
  paddingLeft: padding.small1,
  textAlign: "left",
});

const SecondaryHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.xlarge0,
  padding: padding.small1,
});

const ThirdHeading = styled(Typography)({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  padding: padding.small1,
});

const ListContainer = styled("ul")({
  paddingLeft: padding.zero,
});

const ListItem = styled("li")({
  fontFamily: fontFamily.primary,
  fontSize: fontSize.large,
  listStyleType: "disc",
  marginBottom: margin.small2,
  marginLeft: margin.xlarge2,
});

const StewartPreferredOfficeSolution = () => {
  const { item } = useParams();
  const selectedItem = officeSolution.find((obj) => obj.id === item);
  useTitle(selectedItem?.id || "Stewart Preferred");

  if (!selectedItem) {
    return <div>Item not found</div>;
  }

  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <StyledBoxContainer>
      <PrimaryHeading variant="h3">{selectedItem.heading1}</PrimaryHeading>
      {selectedItem.heading2 !== undefined && (
        <SecondaryHeading variant="h4">
          {selectedItem.heading2}
        </SecondaryHeading>
      )}
     {selectedItem.heading3 &&
        selectedItem.heading3.map((item, index) => (
          <ThirdHeading
            key={index}
            dangerouslySetInnerHTML={renderHTML(item)}
          />
        ))}
      <ListContainer>
        {selectedItem.items.map((item, i) => (
          <React.Fragment key={i}>
            {typeof item === "string" ? (
              item
            ) : (
              <>
                {item.title && (
                  <SecondaryHeading
                    variant="h6"
                    dangerouslySetInnerHTML={renderHTML(item.title)}
                  />
                )}
                {item.description.map((item, index) => (
                  <ListItem
                    key={index}
                    dangerouslySetInnerHTML={renderHTML(item)}
                  />
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </ListContainer>
      {selectedItem.addtionalInfo &&
        selectedItem.addtionalInfo.data.map((item, i) => (
          <ThirdHeading key={i} dangerouslySetInnerHTML={renderHTML(item)} />
        ))}
    </StyledBoxContainer>
  );
};

export default StewartPreferredOfficeSolution;
