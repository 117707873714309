import { CancelTokenSource } from "axios";
import {
  axiosSecuredInstance,
  cancelToken,
} from "configurations/axiosConfig";
import County from "entities/UIModel/company/County";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = {
  stateCode: string | undefined;
  counties: Array<County>;
  axiosCancelToken?: CancelTokenSource;
  isCountyLoading: boolean;
  isCountyLoadCompleted: boolean;
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const getCountiesCachedKey = (state: string, companyID: string) => {
  return `CountiesCachedKey-${state}-${companyID}`;
};

const setCounties =
  (data: any, state: string) =>
  ({ setState }: StoreApi) => {
    let counties: Array<County> = [];

    if (data && data.length > 0) {
      counties = data.map((c: any) => {
        const county: County = {
          companyID: c.companyID,

          text: c.countyName,

          value: c.countyCodeNum,
          isHOI: c.isHOI,
          default: c.default,
        };
        return county;
      });
    }

    setState({
      stateCode: state,
      counties: counties,
      isCountyLoading: false,
      isCountyLoadCompleted: true,
    });
  };

const actions = {
  getCounties:
    (companyID: string, state: string, contractID: string) =>
    async ({ dispatch, getState, setState }: StoreApi) => {
      setState({ isCountyLoading: true });
      const cachedKey = getCountiesCachedKey(state, companyID);
      const cachedData = sessionStorage.getItem(cachedKey);
      let cachedDataAsArray = [];

      if (cachedData) {
        cachedDataAsArray = JSON.parse(cachedData);
      }

      if (cachedDataAsArray && cachedDataAsArray.length > 0) {
        dispatch(setCounties(cachedDataAsArray, state));
      } else {
        try {
          const token = getState().axiosCancelToken;
          if (token) token.cancel("GetCounties canceled due to new request");

          const newCancelToken = cancelToken.source();
          setState({ axiosCancelToken: newCancelToken });

          const { data } = await axiosSecuredInstance.get<any>(
            `/Company/GetCounties?State=${state}&CompanyID=${companyID}&contractID=${contractID}`,
            { cancelToken: newCancelToken.token }
          );

          sessionStorage.setItem(cachedKey, JSON.stringify(data));
          dispatch(setCounties(data, state));
        } catch (error) {
          console.error("ERROR: useCompanyCounties.", error);
          setState({
            isCountyLoading: false,
            isCountyLoadCompleted: true
          });
        }
      }
    },
  resetCounties:
    () =>
    async ({ setState }: StoreApi) => {
      // const cachedKey = getCountiesCachedKey(state, companyID);
      // const cachedData = sessionStorage.getItem(cachedKey);
      // if(cachedData){
      //     sessionStorage.removeItem(cachedKey);
      // }
      setState({
        stateCode: undefined,
        counties: [],
        isCountyLoading: false,
        isCountyLoadCompleted: false
      });
    },
  updateCounties:
    (countyList: any, state: any, isReadOnly: boolean = false) =>
      async({ dispatch, getState } : StoreApi) => {
        const counties = [...countyList];
        if (isReadOnly) {
          const items = getState().counties.map(value => ({
            companyID: value?.companyID,
            countyName: value?.text,
            countyCodeNum: value?.value,
            isHOI: value?.isHOI,
            default: value?.default,
          }));
          counties.push(...items);
        }
        dispatch(setCounties(counties, state));
  },
};

const Store = createStore<State, Actions>({
  initialState: {
    stateCode: undefined,
    counties: [],
    isCountyLoading: false,
    isCountyLoadCompleted: false,
  },
  actions,
  name: "CompanyCountiesContext",
});

const hook = createHook(Store);
const useCompanyCounties = () => {
  return hook();
};
const useCompanyCountyActions = createHook(Store, { selector: null });

export { useCompanyCounties, useCompanyCountyActions };
