import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import {
  getComparator,
  Order,
  stableSort,
} from "pages/myFiles/utils/filesTable";

interface State {
  error: string | null;
  isLoading: boolean;
  order: Order;
  orderBy: string;
  fileHeader: string[];
  fileData: Record<string, any>[];
  columnsOrder: string[];
}

type StoreApi = StoreActionApi<State>;

const defaultState = {
  error: null,
  isLoading: false,
  orderBy: "issuedDate",
  order: "desc" as Order,
  fileHeader: [],
  fileData: [],
  columnsOrder: [],
};

const getOrder = (columns: any) => {
  let result = {};
  columns
    .filter((col: any) => col.field !== "selected" && col.hidden === false)
    .forEach(({ name, field }: any) => {
      result = {
        ...result,
        [field]: name,
      };
    });
  return result;
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    setFileData:
      (cols: any, rows: Record<string, any>[], orderBy: string, order: Order) =>
      ({ setState }: StoreApi) => {
        const sortedData = orderBy
          ? stableSort<any>(rows, getComparator(order, orderBy))
          : rows;
        setState({
          fileData: sortedData,
          fileHeader: Object.values(getOrder(cols)),
          columnsOrder: Object.keys(getOrder(cols)),
        });
      },
  },
  name: "fileExport",
});

const hook = createHook(Store);
export const useFileExport = () => { return hook() };
