import { Typography } from "@mui/material";
import AutocompleteField from "controls/global/custom-autocomplete/AutocompleteField";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { SCFileResponse } from "entities/UIModel";
import { isSCFileResponse } from "pages/file/utils/autocomplete";
import React,
{
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDuplicatedFileCheck } from "utils/context/DuplicatedFileCheckContext";
import { useFileAutocomplete } from "utils/context/FileAutocompleteContext";
import { useFiles } from "utils/context/FilesContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { AgencyStatus } from "utils/data/enum";
import {
  REGEX_NONE_ASCII,
} from "utils/shared";

const FileNameNumberFieldName = "fileNameNumber";
const FileNameNumberField = ({ ...rest }) => {
  const fileNameValueRef = useRef();
  const agencyIdValueRef = useRef();
  const [optionSelected, setOptionSelected] = useState<SCFileResponse>();

  const navigate = useNavigate();
  const [{ selectedAgency, }, { setFileNumber }] = useGlobalAccess();  

  const { setValue, getValues, setError, clearErrors } = useFormWrapper();
  const watchFields = useWatch({ name: ["id", "agency", "fileNameNumber"] });
  const idField = watchFields[0];
  const agencyField = watchFields[1];
  const fileNameNumber = watchFields[2];

  const [, { resetNonFileSections, resetInitialValues }] = useFiles();
  const [, { getFiles }] = useFileAutocomplete();  
  const [
    {
      isDuplicatedFile,
      showAlertDuplicatedFile,
      prevFileNameValueState,
      uniqueFileId,
      isToggleFileAgencyFocus,
    },
    {
      getUniqueFileByAgencyClientFileId,
      setIsDuplicatedFile,
      setShowAlertDuplicatedFile,
      setToggleFileAgencyFocus,
    },
  ] = useDuplicatedFileCheck();

  const getAgencySelected = () => {
    if(selectedAgency?.length > 0){
      return selectedAgency[0].CompanyID;
    }    
  };

  const isAgencyIssueable = () => {
    if(selectedAgency?.length > 0){
      return selectedAgency[0].Status?.toUpperCase() === AgencyStatus.Active;
    }    
    return true;
  };

  useEffect(() => {
    checkValidationFileName();
    // if(isDuplicatedFile) {
    //   clearErrors(FileNameNumberFieldName);
    // }
  }, [isDuplicatedFile]);

  useEffect(() => {
    fileNameValueRef.current = undefined;
  }, [prevFileNameValueState]);

  const checkValidationFileName = () => {
    clearErrors(FileNameNumberFieldName);
    if (isDuplicatedFile &&  isToggleFileAgencyFocus==="fileNameNumber") {
      setError(
        FileNameNumberFieldName,
        {
          type: "isDuplicatedFilename",
          message :" This File Name/Number already exists for this Agency.",
        },
        { shouldFocus: true }
      );
    }
  };

  const onGetFiles = (newInputValue: string): Promise<SCFileResponse[]> => {
    if (!newInputValue) return new Promise<SCFileResponse[]>(() => []);
    return getFiles(newInputValue, agencyField?.id);
  };

  const onValueChanged = (newValue: SCFileResponse | string) => {
    setOptionSelected(undefined);
    if (typeof newValue !== "string") {
      const { fileId } = newValue;
      resetInitialValues();
      navigate("/file", { state: { fileID: fileId } });
      return;
    }

    setValue(FileNameNumberFieldName, newValue);
    setValue("isFileDefault", false);
    setFileNumber(getValues(FileNameNumberFieldName))
  };

  const handleYes = async (e: any) => {
    setShowAlertDuplicatedFile(false);
    resetInitialValues();
    fileNameValueRef.current = undefined;
    agencyIdValueRef.current = undefined;
    navigate("/file", { state: { fileID: uniqueFileId } });
  };

  const handleNo = (e: any) => {
    resetNonFileSections(0, getValues(FileNameNumberFieldName), {
      id: "",
      name: "",
    });
    setShowAlertDuplicatedFile(false);
    setIsDuplicatedFile(true);
    setFileNumber(getValues(FileNameNumberFieldName));
  };

  const onBlurHandler = async (e: any) => {
    setToggleFileAgencyFocus("fileNameNumber")
    clearErrors('agency')
    const fileValue = e.target.value;    
    if (!fileValue) {
      fileNameValueRef.current = undefined;
      agencyIdValueRef.current = undefined;
      return;
    }
    const defaultedAgency = getAgencySelected();    
    if (!agencyField || agencyField?.id === "") {
      return;
    }
    
    const prevFileNameValue = fileNameValueRef.current;
    const prevAgencyIdValue = agencyIdValueRef.current;
    // Avoid calls to the API when values are the same between onBlur events
    if (
      prevFileNameValue === fileValue &&
      prevAgencyIdValue === agencyField?.id
    ) {
      return;
    }

    fileNameValueRef.current = fileValue;
    agencyIdValueRef.current = agencyField?.id ?? defaultedAgency;
    
    const fileId = await getUniqueFileByAgencyClientFileId(
      agencyField?.id,
      e.target.value
    );

    if (fileId === 0 || !fileId) {
      setIsDuplicatedFile(false);
    }
    if (fileId > 0) {
      setShowAlertDuplicatedFile(true);
    } else if ((idField || 0) > 0)
      resetNonFileSections(0, e.target.value, { id: "", name: "" });

    if(!isAgencyIssueable() && !optionSelected){
      clearErrors(FileNameNumberFieldName);
      setError(
        FileNameNumberFieldName,
        {
          type: "manual",
          message: "New files cannot be created for inactive agencies." ,
        },
        { shouldFocus: true }
      );
    }
    setFileNumber(getValues(FileNameNumberFieldName))
  };

  const onFocusHandler = (evt: ChangeEvent<any>) => {
    if (fileNameNumber) {
      fileNameValueRef.current = fileNameNumber;
    }

    if (agencyField && agencyField?.id) {
      agencyIdValueRef.current = agencyField.id;
    }
  };

  const getOptionLabel = (option: SCFileResponse | string) =>
    isSCFileResponse(option) ? option.clientFileId : option;

  const getOptionSelected = (option: SCFileResponse, value: SCFileResponse) => {
    setOptionSelected(value);
    return option.fileId === value.fileId;
  }

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SCFileResponse,
  ) => (
    <Typography
      data-test="fileNameNumber_MenuItem"
      data-fileid={option.fileId}
      component="li" {...props}
    >
      {`${option.clientFileId} - ${option.agencyName}`}
    </Typography>
  );

  const onTypeInputHandler = (inputText: string) => {
    const validValue = inputText.replace(REGEX_NONE_ASCII, "");
    const currentValue: string = getValues(FileNameNumberFieldName);
    setFileNumber(inputText)
    if (currentValue === validValue) {
      setValue(FileNameNumberFieldName, inputText);
    }
    if (inputText !== validValue) {
      setTimeout(() => {
        setValue(FileNameNumberFieldName, validValue);
      }, 10);
    }
  };

  return (
    <>
      <AutocompleteField
        name={FileNameNumberFieldName}
        label="File Name/Number"
        minLengthBeforeSearch={3}
        onGetOptions={onGetFiles}
        onValueChanged={onValueChanged}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onBlur={onBlurHandler}
        onFocus={(e) => {
          onFocusHandler(e);
        }}
        maxLength={100}
        disabled={Boolean((idField || 0) > 0)}
        forceEnable={Boolean((idField || 0) <= 0)}
        onTypeInput={onTypeInputHandler}
        resetDefaultValue={FileNameNumberFieldName}
        {...rest}
      />
      {showAlertDuplicatedFile && (
        <ConfirmationDialog
          confirmationMessage="The file already exists for your selected agency. Would you like to open the existing file?"
          isOpen={showAlertDuplicatedFile}
          onYes={handleYes}
          onNo={handleNo}
        />
      )}
    </>
  );
};

export default FileNameNumberField;
