import React, { useState } from 'react';
import { Table } from '@mui/material';
import { Order } from 'utils/sorting';
import { ReleaseNoteDto } from './ReleaseNote';
import ReleaseNoteHead from './ReleaseNoteHead';
import ReleaseNoteBody from './ReleaseNoteBody';
interface Props {
  records?: ReleaseNoteDto[];
}

const ReleaseNoteHistory = ({ records = [] }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof ReleaseNoteDto>("ReleaseNotesDate");

  const handleRequestSort = (property: keyof ReleaseNoteDto) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Table className="history-table">
      <ReleaseNoteHead {...{ order, orderBy, onRequestSort: handleRequestSort }} />
      <ReleaseNoteBody {...{ order, orderBy, records }} />
    </Table>
  );
};

export default React.memo(ReleaseNoteHistory);
