import React, {
  useEffect,
  useState,
} from "react";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";
import { useWatch } from "react-hook-form";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  noIssuedProducts: boolean;
  isOverride: boolean;
  isNewFile: boolean;
  enableCalculate: (ignoreOverride?: boolean, enableOnly?: boolean) => void;
};
export default function SimultaneousRateField({
  noIssuedProducts,
  isOverride,
  isNewFile,
  enableCalculate,
}: Props) {
  const { setValue, getValues } = useFormWrapper();
  const [isSimultaneousDisabled, SetIsSimultaneousDisabled] = useState(true);
  const { disableSimultaneousRate } = useWatch({
    name: "pricing",
  });
  const isIntegratedPricing = getValues("pricing.isIntegratedPricing");

  const handleOnSimultaneousCheckedChanged = (e: any, _: boolean) => {
    const { checked } = e.target;
    setValue("pricing.prevUserSimulteneousRate", checked);
    enableCalculate();
  };
  useEffect(() => {
    const disableSimultaneousRateAPI = getValues('pricing.disableSimultaneousRate');
    if (disableSimultaneousRateAPI !== undefined) SetIsSimultaneousDisabled(disableSimultaneousRateAPI);
  }, [disableSimultaneousRate, getValues]);

  return (
    <CheckboxInputField
      disabled={
        !isIntegratedPricing ||
        noIssuedProducts ||
        isNewFile ||
        Boolean(isOverride) ||
        disableSimultaneousRate ||
        isSimultaneousDisabled
      }
      onChange={handleOnSimultaneousCheckedChanged}
      label="Simultaneous Rate"
      name="pricing.isSimultaneousRate"
    />
  );
}
