import * as yup from "yup";

const requiredErrorMessage: string = "Required";

const ReportsValidationSchema = yup.object().shape({
  report: yup.object({
    agency: yup.object({
      id: yup.string().required(requiredErrorMessage),
      name: yup.string().required(requiredErrorMessage),
    }),
    agencyLocation: yup.array().min(1, requiredErrorMessage).of(yup.string()),
    state: yup.array().min(1, requiredErrorMessage).of(yup.string()),
    dateType: yup.object({
      name: yup.string().required(requiredErrorMessage),
      code: yup.string().required(requiredErrorMessage),
    }),
    format: yup.string().required(requiredErrorMessage),
  }),
});

export default ReportsValidationSchema;
