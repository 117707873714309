import React from "react";
import CheckboxInputField from "controls/global/checkbox-field/CheckboxInputField";

type Props = {
  show: boolean;
  index: number;
  defaultValue?: boolean;
  disabled: boolean;
  revising: boolean;
  onChange: (index: number, checked: boolean) => void;
};

export default function JacketSimultaneous({
  show,
  index,
  defaultValue = false,
  disabled,
  revising,
  onChange,
}: Props) {
  const schema = `jackets.${index}`;

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (revising) return;
    onChange(index, checked);
  };

  if (!show) return null;
  return (
    <CheckboxInputField
      label="Simultaneous Issue"
      name={`${schema}.pricingDetail.isSimultaneous`}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={handleChange}
    />
  );
}
