import {
  styled,
  TableCell,
  TableRow,
} from "@mui/material";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";

const StyledTableFooterSubCellBorderTop = styled(TableCell)({
  borderBottom: "none",
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.bold1,
  lineHeight: "normal",
  overflow: "hidden",
  paddingBottom: padding.small2,
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
});

const TaxSubtotal = styled("div")({
  margin: `0 ${margin.xsmall1} 0 0`,
  width: "250px !important",
});

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  isPricingSectionUpdated: boolean;
};

export default function TotalTax({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  isPricingSectionUpdated,
}: Props) {
  const {
    getPricingTaxTotal,
  } = usePricingCalculator();
  const pricingTaxtotal = getPricingTaxTotal(isPricingSectionUpdated);

  return (
    <TableRow>
      <StyledTableFooterSubCellBorderTop style={{ paddingLeft: 10 }}>
        <TaxSubtotal>Tax Subtotal</TaxSubtotal>
      </StyledTableFooterSubCellBorderTop>
      {displayFieldIcon && <StyledTableFooterSubCellBorderTop />}
      {displayLiabilityColumn && <StyledTableFooterSubCellBorderTop />}
      {displayRateTypeColumn && <StyledTableFooterSubCellBorderTop />}
      {displayReissueColumn && <StyledTableFooterSubCellBorderTop />}
      {displayTransCodeColumn && <StyledTableFooterSubCellBorderTop />}
      <StyledTableFooterSubCellBorderTop
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={pricingTaxtotal.actualFeeTotalTax} />
        }
      </StyledTableFooterSubCellBorderTop>
      {displayRiskRateColumn && (
        <StyledTableFooterSubCellBorderTop
          align="right"
          style={{ maxWidth: "170px" }}
        >
          {hasIssuedProducts &&
            <TooltipCell title={pricingTaxtotal.riskRateTotalTax} />
          }
        </StyledTableFooterSubCellBorderTop>
      )}
      <StyledTableFooterSubCellBorderTop
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts && (
          <TooltipCell title={pricingTaxtotal.agentRetentionTotalTax} />
        )}
      </StyledTableFooterSubCellBorderTop>
      <StyledTableFooterSubCellBorderTop
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={pricingTaxtotal.totalDueTotalTax} />
        }
      </StyledTableFooterSubCellBorderTop>
    </TableRow>
  );
}
