import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextInputField from "controls/global/text-input-field";

const Container = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  marginTop: theme.spacing(1.25),
}));

const CustomTypography = styled(Typography)({
  fontSize: "0.9rem",
});

const TPSFormField = () => {
  return (
    <Container
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
        sm={3}
      >
        <CustomTypography variant="subtitle1">Current title production software :</CustomTypography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={9}
      >
        <TextInputField
          name={"tpsSoftware"}
          label="Title Production Software (e.g., Qualia, SoftPro, RamQuest, etc.)"
        />
      </Grid>
    </Container>
  );
};

export default TPSFormField;
