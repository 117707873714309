import React from "react";
import TextInputField from "controls/global/text-input-field";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

type Props = {
  name: string;
  disabled: boolean;
};

export default function AddressTwo({ name, disabled }: Props) {
  const { setValue } = useFormWrapper();
  const handleOnBlur = (e: any) => {    
    setValue(name, "");
  }
  return (
    <TextInputField 
    label="Address Line 2" 
    name={name} 
    onBlur={(e:any) => handleOnBlur(e)}
    disabled={disabled} />
  );
}
