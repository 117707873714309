import {
  styled,
  TextField
} from "@mui/material";
import { colors } from "theme/defaultStyle";

const StewartSelect = styled(TextField)(({ theme }) => ({
  "& div.MuiSelect-root": theme.custom.textField.inputOutlined,
  "& div.MuiOutlinedInput-root path": { fill: colors.blue01 },
  "& div.MuiSelect-select.Mui-disabled":
    theme.custom.textField.inputOutlinedDisabled,
  "& div.MuiOutlinedInput-root.Mui-disabled path": { fill: colors.blue09 },
  "& .MuiSelect-icon:hover": {
    backgroundColor: colors.grey10,
    borderRadius: "50%",
    pointerEvents: "auto",
  },
  "& div.Mui-error path": { fill: colors.red01 },
  "& div.MuiInputBase-adornedEnd": {
    cursor: "pointer",
    paddingRight: "84px",
    "&.Mui-disabled": {
      backgroundColor: "#f5f5f5",
    },
    "& div.MuiSelect-root": {
      paddingRight: 0,
    },
    "& button.MuiButtonBase-root": {
      height: "25.15px",
      padding: "4px",
      position: "absolute",
      right: "36px",
      "& > svg": {
        fontSize: "1.0714285714285714rem",
      },
    },
  },
  "& svg.MuiSelect-icon:not(.Mui-disabled)": {
    pointerEvents: "auto",
    cursor: "pointer",
  },
}));

export default StewartSelect;
