import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import {
  County,
  ZipCode,
} from "entities/ApiModel";
import {
  Address,
  ZipCodeResponse,
} from "entities/UIModel";
import {
  isValidZipCode,
  maskZipCode,
} from "pages/file/utils/helper";
import {
  StoreActionApi,
  createHook,
  createStore,
} from "react-sweet-state";

interface State {
  isEsbLoading: boolean;
  error: string | null;
}
type StoreApi = StoreActionApi<State>;

const setLoading =
  () =>
    ({ setState }: StoreApi) => {
      setState({ isEsbLoading: true });
    };

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({
        isEsbLoading: false,
        error,
      });
    };

const setZipcode =
  (data: any, zipcodeFromAutoComplete: any) =>
    ({ setState }: StoreApi) => {
      if (!data || !Array.isArray(data) || data.length === 0) return "";
      const zipCode = data[0].components.zipcode || zipcodeFromAutoComplete;
      const zipPlus4Code = data[0].components.plus4_code || "";

      setState({ isEsbLoading: false });
      return maskZipCode(`${zipCode}${zipPlus4Code}`);
    };

const setZipCodeResponse =
  (response: ZipCode, requestedZip: string) =>
    ({ setState }: StoreApi) => {
      if (
        !response ||
        response?.DefaultCity?.CountyName === "" ||
        response?.States?.length === 0
      ) {
        setState({ isEsbLoading: false });
        return;
      }

      const counties: SelectFieldOption[] = response.Counties.map(
        (county: County, i: number) => {
          return {
            value: county.CountyCode,
            text: county.CountyName,
            selected: i === 0,
          };
        }
      );
      const city = response.DefaultCity?.CityName ?? "";
      const cities: SelectFieldOption[] = response.Cities.map((cityItem) => ({
        value: cityItem.CityName,
        text: cityItem.CityName,
        selected: cityItem.CityName === city,
      }));

      const county = response.DefaultCity?.CountyName ?? "";
      const state = response.States.length
        ? {
          code: response.States[0].StateCode,
          abbr: response.States[0].StateName,
        }
        : { code: "", abbr: "" };

      const zipCodeResponse: ZipCodeResponse = {
        counties,
        city,
        cities,
        county,
        state,
      };
      
      sessionStorage.setItem(requestedZip, JSON.stringify(zipCodeResponse));

      setState({
        isEsbLoading: false,
      });

      return zipCodeResponse;
    };

const Store = createStore<State, any>({
  initialState: {
    isEsbLoading: false,
    error: null,
  },
  actions: {
    verifyAddress:
      (scAddress: Address) =>
        async ({ dispatch }: StoreApi) => {
          try {
            if (scAddress.address1 === "") return "";

            dispatch(setLoading());
            const { data } = await axiosSecuredInstance.post(
              "/smartystreets/verifyaddress",
              scAddress
            );

            return dispatch(setZipcode(data, scAddress?.zipCode));
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
    getZipCode:
      (zip: string, state:string) =>
        async ({ dispatch }: StoreApi) => {
          const isInvalidZip = !zip || zip?.length < 5;
          
          const firstFiveDigits = zip.substring(0, 5);
          let cacheKey = firstFiveDigits + (state ? "_" + state : "");          
          let cachedResponse = sessionStorage.getItem(cacheKey);              
          if (isInvalidZip || cachedResponse === "catched") return;

          try {
            cachedResponse = cachedResponse ? JSON.parse(cachedResponse) : null;
            
            if (cachedResponse || !isValidZipCode(zip)) return cachedResponse;
            sessionStorage.setItem(cacheKey, "cached");
            dispatch(setLoading());
            const { data } = await axiosSecuredInstance.get(
              "/smartystreets/zipcode",
              { params: { zip: zip, state: state } }
            );

            return dispatch(setZipCodeResponse(data, cacheKey));
          } catch (error: any) {
            dispatch(setError(error));
          }
        },
  },
  name: "esb",
});

const hook = createHook(Store, {
  selector: null,
});

export const useEsb = () => {
  return hook();
};
