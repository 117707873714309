import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect,
} from "react";
import {
  useFormContext,
  useWatch,
} from "react-hook-form";
import ErrorMessage from "controls/global/error-message";
import { getIn } from "utils/object";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import ComboBoxField from "controls/global/combo-box-field";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

interface Props {
  agencies: SelectFieldOption[];
  onChange?: any;
}

const name = "report.agency";
const ReportAgencyField = ({ agencies, onChange, ...rest }: Props) => {
  const [agencyField] = useWatch({
    name: ["report.agency"],
  });

  const [internalValue, setInternalValue] = React.useState<SelectFieldOption>({
    text: "",
    value: "",
  });
  const { setValue } = useFormWrapper();
  const [filteredAgencies, setFilteredAgencies] = React.useState<SelectFieldOption[]>([]);
  const [{ selectedAgency }] = useGlobalAccess();  

  const { formState } = useFormContext();
  const hasError = getIn(formState.errors, name);

  const handleOnChange = (_: any, selectedAgency: any) => {
    setValue(`report.state`, []);
    setValue(`report.agencyLocation`, []);
    setInternalValue(selectedAgency);
    onChange && onChange(selectedAgency);
  };

  const handleOnBlur = () => {
    if (agencyField && agencyField.id === internalValue.value) return;
  };

  useEffect(() => {
    if (agencies && agencies.length === 1) 
        setInternalValue({ text: agencies[0].text, value: agencies[0].value });
    if (selectedAgency?.length) {
      const globalAccessAgency = agencies?.find((a) => a.value === selectedAgency[0].CompanyID);
      if(globalAccessAgency?.value) {
         setValue(name, {
          id: selectedAgency[0].CompanyID,
          name: selectedAgency[0].CompanyName,
        });
        setInternalValue({ 
          text: selectedAgency[0].CompanyID || "", 
          value: selectedAgency[0].CompanyName || "" 
        });
        setFilteredAgencies([globalAccessAgency]);
      }  
      else {
        setFilteredAgencies(agencies);
      }    
    }
    else {
      setFilteredAgencies(agencies);
    }
  }, [agencies, selectedAgency, setValue]);

  return (
    <>
      <ComboBoxField
        label={"Agency"}
        options={filteredAgencies}
        {...{ name: name, disabled: false }}
        onChange={(_: any, selectedValue: any) => {
          handleOnChange(_, selectedValue);
        }}
        roundedCorner={true}
        onBlur={handleOnBlur}
        freeSolo={true}
      />
      {hasError && <ErrorMessage name={name} />}
    </>
  );
};

export default ReportAgencyField;
