import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";

function useGlobal() {
  const [{ profileSettings }] = useProfileSettingsCache();
  let permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const checkIfPermissionIsGranted = (
    permissions: PERMISSIONS[] | undefined,
    userPermissions: string[] | undefined = undefined, 
  ) => {
    let hasItemAccessPermission = false;

    if (userPermissions) {
      permissionCodes = userPermissions;
    }

    const hasSiteAcessPermission = permissionCodes?.includes(PERMISSIONS.SITE_ACCESS);          
    if (permissions && permissions.length > 0) {
      for (let permission of permissions) {
        if (permissionCodes?.includes(permission)) {
          // return true;
          hasItemAccessPermission = true;
          break;
        }
      }
    }
    else {
      hasItemAccessPermission = true;
    }

    return hasSiteAcessPermission && hasItemAccessPermission;
  };

  return { checkIfPermissionIsGranted };
}

export default useGlobal;
