import { styled } from "@mui/material";
import NumberInputFieldPricing from "controls/global/number-input-field/pricing/NumberInputFieldPricing";
import React from "react";
import { useWatch } from "react-hook-form";
import {
  gaps,
  margin,
} from "theme/defaultStyle";
import { useFlashMessageActions } from "utils/context/FlashMessageContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricing from "utils/custom-hooks/usePricing";
import { convertToNumberWithNegative } from "utils/shared";

type Props = {
  isLocked: boolean;
  // onIsCoInsuranceChange: () => void;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: gaps.small1,
  maxWidth: "150px",
  "& .inputTextAlign": {
    textAlign: "end",
    "& .MuiInput-input": {
      textAlign: "end",
    },
  },
  "& label": {
    alignItems: "center",
    display: "flex",
    margin: margin.zero,
  },
});

export default function CoInsuranceField({
  isLocked,
  // onIsCoInsuranceChange,
}: Props) {
  const { getValues, setValue } = useFormWrapper();
  const [, { showFlashMessage }] = useFlashMessageActions();
  const { isCoInsuranceEnabled } = usePricing();

  const [overrideReason, isOverride] = useWatch({
    name: ["pricing.overrideReason", "pricing.isOverride"],
  });

  const hasIssuedJackets: boolean = getValues("hasIssuedJackets");
  const coInsuranceEnabled = isCoInsuranceEnabled(hasIssuedJackets, isOverride, overrideReason);


  const handleCoInsuranceOnBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    let coInsurancePct = convertToNumberWithNegative(value);
    const oldCoInsurancePct = convertToNumberWithNegative(
      getValues("pricing.coInsurancePctInitial")
    );

    if (coInsurancePct !== Math.abs(oldCoInsurancePct)) {
      const isValid = coInsurancePct >= 0 && coInsurancePct <= 100;
      if (!isValid) {
        setValue(
          "pricing.coInsurancePct",
          oldCoInsurancePct >= 0 && oldCoInsurancePct <= 100
            ? oldCoInsurancePct
            : 0,
          {
            shouldDirty: true,
          }
        );

        showFlashMessage(
          `Co-Insurance Percentage Amount of ${coInsurancePct} is not allowed, please enter a different amount between 0 to 100.`
        );
        return;
      }

      // save new value as initial
      setValue("pricing.coInsurancePctInitial", coInsurancePct);

      // // recalculate pricing amounts
      // applyCoInsurance(coInsurancePct, setIsPricingSectionUpdatedStatus);
    }
  };

  React.useEffect(() => {
    setValue("pricing.isCoInsurance", coInsuranceEnabled);
  }, [coInsuranceEnabled]);

  return (
    <>
      {coInsuranceEnabled && (
        <Container>
          <NumberInputFieldPricing
            prefix=""
            placeholder=""
            isZeroAllowed={true}
            name="pricing.coInsurancePct"
            disabled={isLocked}
            onBlur={handleCoInsuranceOnBlur}
            inputClassName={"inputTextAlign"}
            errorClassName="errorMessageBigRedFloatingCoInsurance"
            label="Percentage"
          />
          <label>%</label>
        </Container>
      )}
    </>
  );
}
