import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  margin,
  padding,
} from "theme/defaultStyle";
import { PaymentSheetOptionType } from "utils/data/enum";

export type PAYMENT_SHEET_OPTION_TYPE =  PaymentSheetOptionType.File | PaymentSheetOptionType.Product;

export type PaymentSheetOption = {
  label: string;
  value: PAYMENT_SHEET_OPTION_TYPE;
};

const Title = styled("div")({
  color: colors.grey08,
  display: "flex",
  fontFamily: "Roboto",
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  justifyContent: "center",
  maxWidth: "375px",
  "& span": {
    textAlign: "center",
  },
  marginBottom: "0px",
  paddingBottom: padding.small2
});

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.zero,
  marginLeft: margin.xlarge40,
  justifyContent: "center",
  "& label.MuiFormControlLabel-root": {
    marginRight: margin.zero,
  },
});


type Props = {
  groupName: string;
  title: string;
  options: PaymentSheetOption[];
  defaultValue?: string;
  setSelected: (paymentSheetOptionType: PAYMENT_SHEET_OPTION_TYPE) => void;  
};

function PaymentSheetSelectionOptions({
  groupName,
  options,
  title,
  defaultValue,
  setSelected,
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value as PAYMENT_SHEET_OPTION_TYPE);
  };

  return (
    <>
      <Title>
        <span>{title}</span>
      </Title>
      <RadioGroup name={groupName} defaultValue={defaultValue}  onChange={handleChange}>
        <Container>
          {options.map((c) => (
            <>
              <FormControlLabel
                key={c.value as string}
                value={c.value}
                control={<Radio />}
                label={c.label}
              />
            </>
          ))}
        </Container>
      </RadioGroup>
    </>
  );
}

export default PaymentSheetSelectionOptions;
