import {
  Box,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
} from "theme/defaultStyle";
import { ProductType } from "utils/data/enum";

type Props = {
  productType: string;
};

const InfoBox = styled(Box)({
  color: colors.grey13,
  marginBottom: margin.zero,
  marginTop: margin.zero,
  "& .infoTypography": {
    fontSize: `${fontSize.large} !important`,
    fontWeight: fontWeight.normal1,
  },
});

const LetterSectionHeader = ({ productType }: Props) => {
  return (
    <>
      {productType === ProductType.Cpl && (
        <InfoBox>
          <span>
            <Typography className={"infoTypography"}>
              Edits made below will not update in the Parties section.
            </Typography>
          </span>
        </InfoBox>
      )}

      {productType === ProductType.Aal && (
        <InfoBox>
          <span>
            <Typography className={"infoTypography"}>
              Should you have any questions, please contact us at{" "}
              <a href="mailto:NYAgentLetter@Stewart.com">
                NYAgentLetter@Stewart.com
              </a>{" "}
              or (212) 922-0050.
            </Typography>
            <Typography className={"infoTypography"}>
              Closing Protection Letters are not permitted in New York State per
              NY Insurance Dept. Letter 18.
            </Typography>
          </span>
        </InfoBox>
      )}
    </>
  );
};

export default LetterSectionHeader;
