import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  borderRadius,
  colors,
  filters,
  fontSize,
  fontWeight,
  height,
  iconSize,
  lineHeight,
  margin,
  padding,
} from "theme/defaultStyle";
import useTitle from "utils/custom-hooks/useTitle";
import { MenuDefinition, menuDefinitions, menuType } from "./menu";
import { useReportPay } from "utils/context/ReportPayContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";

const HomeMenu = styled("div")({
  borderRadius: borderRadius.xlarge,
  display: "flex",
  flexDirection: "column",
  margin: `${padding.large2} auto 0 auto`,
  maxWidth: "1272px",
  padding: padding.xlarge3,
  gap :"24px",
});

const MenuItemContainer = styled("div")((props) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  gap :"24px",
  padding: padding.zero,
  // [theme.breakpoints.down(901)]: {
  //   display: "grid",
  //   gridTemplateColumns: "repeat(2, 1fr)",
  // },
  // [theme.breakpoints.down(581)]: {
  //   display: "grid",
  //   gridTemplateColumns: "repeat(1, 1fr)",
  // },
}));

const FlexContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  minWidth :"275px",
  gap :"24px",
});


const MenuItem = styled("div")({
  backgroundColor: colors.white,
  height: height.xsmall,
  margin: margin.zero,
  padding: padding.zero,
  textAlign: "center",
  borderRadius :borderRadius.xlarge,
  minWidth :"275px",
  maxWidth :"275px",
  heigth :"250px",
  "&.disabled-menu-item *": {
    color: colors.blue11,
  },
  "& a": {
    alignItems: "center",
    color: colors.blue01,
    display: "flex",
    flexDirection: "column",
    fontSize: fontSize.xlarge5,
    fontWeight: fontWeight.bold2,
    height: "100%",
    justifyContent: "center",
    padding: padding.large1,
    textDecoration: "none",
    ":hover": {
      color: colors.blue01,
      textDecoration: "none",
    },
    "&.disabledLink": {
      cursor: "default",
      "& .hover-content": {
        display: "none",
      },
    },
    "&.disabledLink:hover .normal-content": {
      display: "none",
    },
    "&.disabledLink:hover .hover-content": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  "& a .icon": {
    color: colors.blue01,
    fontSize: iconSize.xlarge,
  },
  "&:hover:not(.disabled-menu-item)": {
    backgroundColor: colors.blue07,
    borderRadius: borderRadius.small,
    filter: filters.filter01,
  },
});

const TitleMenu = styled("p")({
  lineHeight: lineHeight.xlarge,
});

const MenuItems = ({ menuItems }: { menuItems: MenuDefinition[] }) => {
  const { redirect } = useNavRedirect();    
  const [{ profileSettings },] = useProfileSettingsCache();
  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[])

  const handleNavigate = (url: string) => {
    redirect(url, null); 
  };  

  return (
    <>
      {menuItems.filter((data: MenuDefinition) => {
        return permissionCodes?.includes(data.permissionCode!);
      }).map(
        ({ menuId, menuName, url, type, icon: Icon }, index) => {
          return (
            <MenuItem
              key={menuId}
              onClick={() => handleNavigate(url)}
            >
              <Link to={url} onClick={(event) => event.preventDefault()}>
                <Box>
                  {Icon && <Icon className="icon" />}
                  <TitleMenu className="title">{menuName}</TitleMenu>
                </Box>
              </Link>
            </MenuItem>
          );
        }
      )}
    </>
  );
};

const HomePage = () => {
  const [, { clearSelectedItems: clearReportPaySelectedItems }] = useReportPay();  
  useTitle("Stewart Connect - Home");

  React.useEffect(() => {
    // Need to clear selected Report Pay here, because use can open Report Pay page from Home page
    clearReportPaySelectedItems();
  },[])

  const mainMenus = menuDefinitions?.filter((menu) => menu.type === menuType.mainMenu);

  return (
    (<HomeMenu id="homeMenu">
      <MenuItemContainer>
        <MenuItems menuItems={mainMenus?.slice(0, 3)} />
      </MenuItemContainer>
      <FlexContainer>
        <MenuItems menuItems={mainMenus?.slice(3)} />
      </FlexContainer>
    </HomeMenu>)
  );
};

export default HomePage;
