import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Endorsement } from "entities/UIModel/Endorsement";
import React, { useState } from "react";
import {
  borderSize,
  colors,
  fontSize,
  padding,
} from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import {
  OrderStatusType,
  ProductType,
} from "utils/data/enum";
import { formatDateTime } from "utils/shared";
import EndorsementCheckboxCell from "./EndorsementCheckboxCell";
import EndorsementEffectiveDateCell from "./EndorsementEffectiveDateCell";
import EndorsementNameCell from "./EndorsementNameCell";
import EndorsementStatusCell from "./EndorsementStatusCell";
import EndorsementVoidCell from "./EndorsementVoidCell";
import EndorsementTranscodeDropDown from "./EndorsementTranscodeDropDown";

interface Props {
  name: string;
  index: number;
  minDate?: Date | null;
  endorsement: Endorsement;
  endorsementOptions?: SelectFieldOption[];
  issuedMode: boolean;
  editMode: boolean;
  isRevising?: boolean;
  handleOnRemove: (index: number) => void;
  voidable: boolean;
  showTransCode: boolean;
  hasShortFormEndorsements: boolean;
  disabled: boolean;
  parentOrderID: number;
  parentProductType: string;
  onEndorsementChange?: any;
  onRequestVoid: (endorsement: Endorsement) => void;
  parentProductIndex?: number;
  formType ?: string;
}

const TranscodeCellText = styled(Typography)({
  color: colors.grey07,
  backgroundColor :"#F5F5F5",
  fontSize: fontSize.xlarge,
  padding: `${padding.xlarge21} ${padding.zero} ${padding.xsmall2} ${padding.zero}`,
});

const StyleCellText = styled(Typography)({
  color: colors.grey06,
  fontSize: fontSize.xlarge,
  padding: `${padding.small2} ${padding.zero} ${padding.xsmall2} ${padding.small2}`,
});

const EndorsementTableRow = styled(TableRow)({
  borderBottom: `${borderSize.xsmall} solid ${colors.grey17}`,
  "& .MuiTableCell-root": {
    borderBottom: borderSize.zero,
  },
  "& .cell": {
    borderRight: `${borderSize.xsmall} solid ${colors.grey04}`,
    padding: `${padding.zero} !important`,
  },
  "& .disabledIconButton": {
    opacity: 0.5,
  },
});

const EndorsementGridRow = ({
  name,
  index,
  minDate,
  endorsement: currentEndorsement,
  endorsementOptions,
  issuedMode,
  editMode,
  isRevising = false,
  handleOnRemove,
  voidable,
  showTransCode,
  hasShortFormEndorsements,
  disabled,
  parentOrderID,
  parentProductType,
  onEndorsementChange,
  onRequestVoid,
  parentProductIndex,
  formType,
}: Props) => {

  const { setValue, watch, nameString } = useFormWrapper();

  // const currentEndorsement: Endorsement = watch(nameString(`${name}.${index}`), endorsement );
  watch(nameString(`${name}.${index}.endorsementName`));
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const isPendingEndorsement = 
    currentEndorsement?.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Pending;

  const isIssuedEndorsement =
    currentEndorsement?.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Issued ||
    currentEndorsement?.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Voided;

  const isActiveEndorsement =
    currentEndorsement?.endorsementStatusTypeCode?.toUpperCase() ===
    OrderStatusType.Issued;

  const isEffectiveDateDisabled =
    isRevising && isActiveEndorsement
      ? false
      : isIssuedEndorsement ||
      (!currentEndorsement?.isSFE && !currentEndorsement?.endorsementName) ||
      (currentEndorsement?.isSFE && !currentEndorsement?.isSelected) ||
      disabled;
  
  const showTransCodeAsList = () => {
    if(isPendingEndorsement) {
      if (currentEndorsement?.isSFE) {
        return currentEndorsement?.isSelected;
      } else {
        return currentEndorsement?.endorsementName;
      }
    } else {
      return isIssuedEndorsement;
    }
  }

  const handleShortFormChecked = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {      
      setValue(`${name}.${index}.pricingDetail.transCodeKey`, "");
      setValue(`${name}.${index}.pricingDetail.transCode`, "");      
      setValue(`${name}.${index}.pricingDetail.transCodeDescription`, "");
      setValue(`${name}.${index}.effectiveDate`, null);
      setIsChecked(false)
      return;
    }

    if (!currentEndorsement?.effectiveDate)
      setValue(`${name}.${index}.effectiveDate`, minDate);
      setIsChecked(true)
  };

  const handleEndorsementOnBlur = (event: any) => {
    const selectedOption = event?.target?.value;

    if (!selectedOption) return;
    if (selectedOption) {
      const findInOptions = endorsementOptions?.find((e) => e.text === selectedOption);
      if (!findInOptions) {
        setValue(`${name}.${index}.endorsementName`, "");
      }
    }
  };

  const handleEndorsementChange = (
    _: React.ChangeEvent<{}>,
    selectedOption: SelectFieldOption
  ) => {
    // Default date only for Endorsement issued along with Jacket
    // Do not default it for PPE
    // Do not default for Standalone Endorsement
    if (
      !currentEndorsement?.effectiveDate &&
      !issuedMode &&
      parentProductType !== ProductType.StandaloneEndorsement
    ) {
      setValue(`${name}.${index}.effectiveDate`, minDate);
    }

    if (
      currentEndorsement?.endorsementStatusTypeCode !== OrderStatusType.Issued &&
      currentEndorsement?.endorsementStatusTypeCode !== OrderStatusType.Voided
    ) {
      setValue(
        `${name}.${index}.altEndorsementID`,
        selectedOption.altEndorsementID || ""
      );
      setValue(
        `${name}.${index}.endorsementVersion`,
        selectedOption.endorsementVersion
      );
      setValue(
        `${name}.${index}.opaIdentifier`,
        selectedOption.opaIdentifier || ""
      );
    }
    setValue(`${name}.${index}.pricingDetail.transCodeKey`, "");
    setValue(`${name}.${index}.pricingDetail.transCode`, "");      
    setValue(`${name}.${index}.pricingDetail.transCodeDescription`, "");
    onEndorsementChange && onEndorsementChange(parentProductIndex);
  };

  const handleRequestVoid = () => {
    onRequestVoid(currentEndorsement);
  };

  const showCheckboxColumn =
    !issuedMode && !isIssuedEndorsement && hasShortFormEndorsements;

  return (
    <>
      <EndorsementTableRow selected={isIssuedEndorsement}>
        <EndorsementCheckboxCell
          fullName={`${name}.${index}.isSelected`}
          showColumn={showCheckboxColumn}
          cellCss={"cell"}
          isShortForm={currentEndorsement?.isSFE}
          disabled={disabled}
          onChecked={handleShortFormChecked}
        />
        <EndorsementNameCell
          endorsement={currentEndorsement}
          fullName={`${name}.${index}.endorsementName`}
          cellCss={"cell"}
          disabled={disabled}
          issuedMode={issuedMode}
          options={endorsementOptions || []}
          handleEndorsementChange={handleEndorsementChange}
          handleEndorsementOnBlur={handleEndorsementOnBlur}
          checked ={isChecked}
        />
        {showTransCode &&         
        <TableCell>
          {(showTransCodeAsList()) ? (
            <EndorsementTranscodeDropDown
              fullName={`${name}.${index}.transCode`}
              formPath={`${name}.${index}`}
              parentProductType={parentProductType}
              parentProductIndex={parentProductIndex || 0}
              disabled={isEffectiveDateDisabled}
              formType={formType}
              currentEndorsement={currentEndorsement}
              isRevising={isRevising}
            />
          ) : (
            <TranscodeCellText>{currentEndorsement?.pricingDetail?.transCode}</TranscodeCellText>
          )}
        </TableCell>
        }
        <TableCell>
          <EndorsementEffectiveDateCell
            pathToValidate={name}
            fullName={`${name}.${index}.effectiveDate`}
            disabled={isEffectiveDateDisabled}
          />
        </TableCell>
        <TableCell
          classes={{ sizeSmall: "cell" }}
          hidden={!issuedMode}
        >
          <StyleCellText>
            {currentEndorsement?.issueDateTime ? formatDateTime(currentEndorsement.issueDateTime, false) : ""}
          </StyleCellText>
        </TableCell>
        <EndorsementStatusCell
          hidden={!issuedMode}
          endorsementID={currentEndorsement?.endorsementID}
          statusTypeCode={currentEndorsement?.endorsementStatusTypeCode}
          voidDate={currentEndorsement?.voidedDateTime}
        />
        <EndorsementVoidCell
          disabled={!voidable || disabled}
          hidden={!issuedMode}
          isIssued={isIssuedEndorsement}
          onRequestVoid={handleRequestVoid}
        />
        {editMode && (
          <TableCell
            classes={{ sizeSmall: "cell" }}
            style={{ width: 60 }}
            hidden={!editMode}
            align="center"
          >
            {!isIssuedEndorsement && !currentEndorsement.isSFE ? (
              <IconButton
                classes={{
                  disabled: "disabledIconButton",
                }}
                disabled={disabled}
                aria-label="delete-endorsement"
                onClick={() => handleOnRemove(index)}
              >
                <DeleteIcon htmlColor={colors.blue01} />
              </IconButton>
            ) : null}
          </TableCell>
        )}
      </EndorsementTableRow>
    </>
  );
};

export default EndorsementGridRow;
