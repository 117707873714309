import Button from "@mui/material/Button";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import { SCFile } from "entities/UIModel";
import { checkFormDirty } from "pages/file/utils/file";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import { useNavigate } from "react-router";
import { useCompanyContextActions } from "utils/context/CompanyContext";
import { useCompanyCountyActions } from "utils/context/CompanyCountiesContext";
import { useCompanyProductOptions } from "utils/context/CompanyProductOptionsContext";
import { useDuplicatedFileCheck } from "utils/context/DuplicatedFileCheckContext";
import { useFiles } from "utils/context/FilesContext";
import { useLookup } from "utils/context/LookupContext";
import { useNavigation } from "utils/context/NavigationContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { MapActionType } from "utils/data/enum";
import { useFilePropertyState } from "utils/context/FilePropertyStateContext";
import React, 
{ 
  useState, 
} from "react";
import { isCreateFilePage } from "utils/routes/pathValidator";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

const defaultConfirmationMessage = "Do you want to save this file?";
const issuedConfirmationMessage =
  "Are you sure you want to leave without submitting?";
const changeAgencyConfirmationMessage = "Are you sure you want to change agencies?";
const removeAgencyConfirmationMessage = "Are you sure you want to remove this agency?";

type Props = {
  innerRef: React.MutableRefObject<HTMLButtonElement>;
  onSave: (values: SCFile, cleanFormValues: boolean) => Promise<boolean>;
};

export default function SaveChanges({ innerRef, onSave }: Props) {
  const [confirmationDialog, confirmationDialogDispatch] = React.useReducer(
    (s: any, a: any) => ({ ...s, ...a }),
    {
      open: false,
      message: "",
      showCancelButton: true,
    }
  );

  const {
    getValues,
    clearErrors,
    setValue,
    trigger,
    resetField
  } = useFormWrapper();
  const navigate = useNavigate();
  const [
    { initialValues, isIssuedProductDirty, invokingUrl, invokingAction, isReadOnly },
    { resetInitialValues, setIssuedProductDirty, resetInvokingUrl, setIsReadOnly },
  ] = useFiles();  
  const [{ suffixes }, { resetLookups }] = useLookup();
  const [, { resetCounties }] = useCompanyCountyActions();
  const [, { resetCompanyProductOptions }] = useCompanyProductOptions();
  const [, { navigateToNextUrl }] = useNavigation();
  const [, { setIsDuplicatedFile, setPrevFileNameValueState }] =
    useDuplicatedFileCheck();
  const [, { resetPropertyState }] = useFilePropertyState();
  const [, { getAgencies }] = useCompanyContextActions();
  const [, { clearUserSelectedAgency }] = useGlobalAccess();
  const agencyActionConfirmationMessage = invokingAction === "ChangeAgency" ? changeAgencyConfirmationMessage : removeAgencyConfirmationMessage;
  const[hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
  
  const isChangeAgencyAction = isCreateFilePage(invokingUrl) && (invokingAction === "ChangeAgency" || invokingAction === "RemoveAgency");
  const resetFormCollections = () => {
    const isReadOnly = getValues("isReadOnly");
    setTimeout(() => {
      resetField("aALProducts");
      resetField("additionalParties");
      resetField("attorneyFileParties");
      resetField("busTrustFileParties");
      resetField("buyerBorrowerParties");
      resetField("sellerParties");
      resetField("coupleFileParties");
      resetField("cpls");
      resetField("documents");
      resetField("fileActivities");
      resetField("individualFileParties");
      resetField("jackets");
      resetField("lenderParties");
      resetField("productItems");
      resetField("properties");
      resetField("secondaryAgencyFileParties");
      resetField("settlementCompanyFileParties");
      resetField("standaloneEndorsements");
      resetField("users");
      resetField("combinedQNAs");
      resetField("filePricingDetails");
      resetField("pricingProducts");
      resetField("updatedInitialPricingProducts");
      resetField("validLocations");      
      resetField("agency");  
      resetField("agencyLocation");
      if(isReadOnly){
        setValue("agency", {text: "", value: ""});        
      }
    }, 500);

    setTimeout(() => { setValue("cpls", []); }, 100);
    setTimeout(() => { setValue("aALProducts", []); }, 100);
    setTimeout(() => { setValue("jackets", []); }, 100);
    setTimeout(() => { setValue("standaloneEndorsements", []); }, 100);

    if (isReadOnly) {
      getAgencies();
    }
  };

  const handleClick = () => {   
    if (isIssuedProductDirty) {
      confirmationDialogDispatch({
        open: true,
        message: issuedConfirmationMessage,
        showCancelButton: false,
      });
      return;
    }

    const mappedFile = mapUiSCFileToApiSCFile(
      initialValues,
      getValues(),
      undefined,
      MapActionType.CheckFormDirty
    );
    const isFormDirty = checkFormDirty(mappedFile);        

    if (!isFormDirty || isReadOnly) { 
      setHasPendingChanges(false);
      if(isChangeAgencyAction) {
        confirmationDialogDispatch({
          open: true,
          message: agencyActionConfirmationMessage,
          showCancelButton: false,
        });
        return;
      }
      resetFormCollections();
      resetInitialValues();
      resetCounties();
      resetLookups({ suffixes: suffixes });
      resetCompanyProductOptions();
      setIsReadOnly(true);
      setIsDuplicatedFile(false);
      
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      document.dispatchEvent(new Event("documentReset"));
      navigateToNextUrl(navigate);
      return;
    } else {
      setHasPendingChanges(true);
      confirmationDialogDispatch({
        open: true,
        message: defaultConfirmationMessage,
        showCancelButton: true,
      });
    }
  };

  const handleDialogOk = async () => {
    if (isIssuedProductDirty) {
      closeConfirmationDialog(issuedConfirmationMessage, false);
      setIssuedProductDirty(false);
      resetFormCollections();
      resetInitialValues();
      resetCounties();
      resetLookups();
      resetCompanyProductOptions();
      resetPropertyState();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      document.dispatchEvent(new Event("documentReset"));
      navigateToNextUrl(navigate);
      if (invokingAction === "RemoveAgency") {
        clearUserSelectedAgency();
      }
      resetInvokingUrl();

      return;
    }
    // If no pending changes, then execute requested action  
    if(hasPendingChanges)  {
      closeConfirmationDialog(defaultConfirmationMessage, false);
      clearErrors();
      const values: SCFile = getValues();
      const isFormValid = await runValidation({
        values,
        trigger,
        setValue,
        productAction: MapActionType.SaveFile,
      });

      if (!isFormValid) {
        resetInvokingUrl();
        return;
      }

      const wasSaved = await onSave(values, true);
      if (!wasSaved){ 
        resetInvokingUrl();
        return;      
      }
    }

    if(invokingAction === "RemoveAgency") {
      closeConfirmationDialog(agencyActionConfirmationMessage, false);
      clearUserSelectedAgency();
    }
    resetInvokingUrl();
    resetFormCollections();
    resetInitialValues();
    resetCounties();
    resetLookups({ suffixes: suffixes });
    resetCompanyProductOptions();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.dispatchEvent(new Event("documentReset"));
    setHasPendingChanges(false);
    navigateToNextUrl(navigate);
  };

  const handleDialogNoAction = () => {    
    let confirmationMsg = defaultConfirmationMessage;
    let showCancelButton = true;
    if (isIssuedProductDirty) {
      confirmationMsg = issuedConfirmationMessage;
      showCancelButton = false;
    }else if(isChangeAgencyAction){
      showCancelButton = false;
      if(!hasPendingChanges){
        confirmationMsg = agencyActionConfirmationMessage;
        closeConfirmationDialog(confirmationMsg, showCancelButton);
        resetInvokingUrl();
        return;
      }
      //confirmationMsg = agencyActionConfirmationMessage;
      resetInvokingUrl();
      if(invokingAction === "RemoveAgency") {
        clearUserSelectedAgency();
      }
    }

    closeConfirmationDialog(confirmationMsg, showCancelButton);

    setIsDuplicatedFile(false);
    setPrevFileNameValueState(Date.now());
    if (isIssuedProductDirty) return;
    resetFormCollections();
    resetInitialValues();
    resetCounties();
    resetLookups({ suffixes: suffixes });
    resetCompanyProductOptions();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.dispatchEvent(new Event("documentReset"));
    setHasPendingChanges(false);
    navigateToNextUrl(navigate);
    
  };

  const closeConfirmationDialog = (confirmationMsg: string = defaultConfirmationMessage
    , showCancelButton: boolean = true) => {
    confirmationDialogDispatch({
      open: false,
      message: confirmationMsg,
      showCancelButton: showCancelButton,
    });    
  };

  const handleCancelAction = () => {
    resetInvokingUrl();
    confirmationDialogDispatch({
      open: false,
      message: defaultConfirmationMessage,
      showCancelButton: true,
    });
  };

  return (
    <>
      <Button
        ref={innerRef}
        hidden
        onClick={handleClick}
      />
      <ConfirmationDialog
        confirmationMessage={confirmationDialog.message}
        isOpen={confirmationDialog.open}
        cancelActionButton={confirmationDialog.showCancelButton}
        onYes={handleDialogOk}
        onNo={handleDialogNoAction}
        onCancel={handleCancelAction}
      />
    </>
  );
}
