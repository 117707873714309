import ComboBoxField from "controls/global/combo-box-field";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React from "react";

type Props = {
  name: any;
  defaultValue?: string;
  succesorLanguages: SelectFieldOption[];
  isProductSection?: boolean;
  onChange?: any;
  disabled?: boolean;
  onBlur?: any;
};

const SuccessorLanguageField = ({
  succesorLanguages,
  name,
  defaultValue,
  isProductSection = false,
  onChange,
  disabled = false,
  onBlur,
}: Props) => {
  const label = `Successor Language${isProductSection ? " (optional)" : ""}`;

  return (
    <ComboBoxField
      freeSolo
      label={label}
      options={succesorLanguages}
      {...{ name, disabled, defaultValue }}
      onBlur={onBlur}
      onChange={onChange}
      roundedCorner={true}
    />
  );
};

export default SuccessorLanguageField;
