import { styled } from "@mui/material";
import React from "react";
import theme from "theme/default";
import { gaps } from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import Field from "./Field";

type Props = {
  schema: string;
  isDisabledAddress?: boolean;
  onChange?: any;
  onBlur?: () => void;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: gaps.large1,
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  "& > div": {
    flexBasis: "content",
    flexGrow: 1,
    flexShrink: 1,
  },
  "& > .address": {
    minWidth: "220px",
  },
  "& > .city": {
    maxWidth: "300px",
    minWidth: "190px",
  },
  "& > .state": {
    maxWidth: "300px",
    minWidth: "190px",
  },
  "& > .country": {
    maxWidth: "300px",
    minWidth: "190px",
  },
  "& > .address, & > .city, & > .state, & > .country": {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
});

const ForeignAddress = ({ schema, isDisabledAddress = false, onChange, onBlur }: Props) => {
  const { setValue, nameString } = useFormWrapper();

  const stateFieldName = nameString(`${schema}.foreignState`);
  const cityFieldName = nameString(`${schema}.city`);
  const countryFieldName = nameString(`${schema}.foreignCountry`);
  const addressOneFieldName = nameString(`${schema}.addressOne`);
  const addressTwoFieldName = nameString(`${schema}.addressTwo`);

  const setNotDefault = () => setValue(`${schema}.isDefault`, false);

  const getFieldName = (targetName: string) => {
    let fieldName = targetName.split(".");
    if (fieldName && fieldName.length > 0)
      return fieldName[fieldName.length - 1];
    return targetName;
  };

  const onBlurHandler = (e: any) => {
    if (e && e.target) {
      const fieldName = getFieldName(e.target.name);
      onChange && onChange(undefined, fieldName, e.target.value);
      onBlur && onBlur();
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<any>) => {
    setNotDefault();
    onChange && onChange();
  };

  return (
    <Container>
      <div className="address addressOne">
        <Field
          name={addressOneFieldName}
          label="Address Line 1"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </div>
      <div className="address addressOne">
        <Field
          name={addressTwoFieldName}
          label="Address Line 2"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </div>
      <div className="city">
        <Field
          name={cityFieldName}
          label="City"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </div>
      <div className="state">
        <Field
          name={stateFieldName}
          label="State"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </div>
      <div className="country">
        <Field
          name={countryFieldName}
          label="Country"
          isDisabled={isDisabledAddress}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      </div>
    </Container>
  );
};
export default ForeignAddress;
