import React from "react";
import TextInputField from "controls/global/text-input-field";
import { BaseAddressChangeProps } from "utils/interfaces/BaseAddress";

const AddressTwoField = ({
  name,
  label = "Address Line 2",
  isDisabled = false,
  onBlur,
  onChange,
}: BaseAddressChangeProps) => {
  return (
    <>
      <TextInputField
        label={label}
        disabled={isDisabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
      />
    </>
  );
};

export default AddressTwoField;
