import IosShareIcon from "@mui/icons-material/IosShare";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  gaps,
  margin,
} from "theme/defaultStyle";
import FileFormatOptions from "./FileFormatOptions";
import FileSelectionOptions, {
  Choice,
} from "./FileSelectionOptions";

export type SELECTION = "SELECTED" | "ALL";
export type ITEMIZATION = "PRODUCT" | "FILE";
export type FORMATS = {
  EXCEL: boolean;
  PDF: boolean;
};

const DEAULT_FORMATS = {
  EXCEL: true,
  PDF: false,
};

const DEFAULT_SELECTION = "SELECTED";
const DEFAULT_ITEMIZATION = "PRODUCT";

const TITLE = "Export File(s)";

type Props = {
  open: boolean;
  onCancel?: () => void;
  onExport?: (allFiles: boolean, itemizeProducts: boolean) => void;
  showSelectionPrompt?: boolean;
  selectionPrompt: string;
  showItemizedPrompt?: boolean;
  itemizedPrompt: string;
  showFileFormatPrompt?: boolean;
};

const StyledDialogContent = styled(StewartDialogContent)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
  justifyContent: "center",
  "& hr": {
    borderColor: colors.grey09,
    height: "1px",
    marginBottom: margin.zero,
    marginTop: margin.zero,
    width: "415px",
  },
});

function FileExportDialog({
  open,
  onExport,
  onCancel,
  showSelectionPrompt,
  selectionPrompt,
  showItemizedPrompt,
  itemizedPrompt,
  showFileFormatPrompt,
}: Props) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selection, setSelection] = useState<SELECTION>(DEFAULT_SELECTION);
  const [itemization, setItemization] = useState<ITEMIZATION>(DEFAULT_ITEMIZATION);
  const [formats, setFormats] = React.useState<FORMATS>(DEAULT_FORMATS);

  const fileSelectionChoices: Choice<SELECTION>[] = [
    { label: "Selected Only", value: "SELECTED" },
    { label: "All Files", value: "ALL" },
  ];

  const itemizationChoices: Choice<ITEMIZATION>[] = [
    { label: "Yes, display itemized products", value: "PRODUCT" },
    { label: "No, total of all products", value: "FILE" },
  ];

  const resetDialog = () => {
    setSelection(DEFAULT_SELECTION);
    setFormats(DEAULT_FORMATS);
    setDisabled(false);
  };

  const handleCancel = () => {
    resetDialog();
    onCancel?.();
  };

  const handleExport = () => {
    setDisabled(true);
    const exportAllFiles = !showSelectionPrompt === true || selection === "ALL";
    const itemizeProducts = showItemizedPrompt === true && itemization === "PRODUCT";
    onExport?.(exportAllFiles, itemizeProducts);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      handleCancel();
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (open) {
      resetDialog();
    }
  }, [open]);

  return (
    <StewartDialog
      open={open}
      onKeyDown={handleKeyDown}
      width={520}
    >
      <StewartDialogTitle type={"INFO"}>
        {TITLE}
        <DialogTitleCloseButton onClick={handleCancel} />
      </StewartDialogTitle>
      <StyledDialogContent>
        {showSelectionPrompt && (
          <>
            <FileSelectionOptions
              groupName="file-selection"
              choices={fileSelectionChoices}
              prompt={selectionPrompt}
              selected={selection}
              setSelected={setSelection}
            />
            {(showItemizedPrompt || showFileFormatPrompt) &&
              <hr />
            }
          </>
        )}
        {showItemizedPrompt && (
          <>
            <FileSelectionOptions
              groupName="itemization"
              choices={itemizationChoices}
              prompt={itemizedPrompt}
              selected={itemization}
              setSelected={setItemization}
            />
            {(showFileFormatPrompt) &&
              <hr />
            }
          </>
        )}
        {showFileFormatPrompt &&
          <FileFormatOptions
            formats={formats}
            setFormats={setFormats}
            setDisabled={setDisabled}
          />
        }
      </StyledDialogContent>
      <StewartDialogActions>
        <>
          <ActionButton
            disabled={disabled}
            color="primary"
            variant="outlined"
            onClick={handleExport}
            startIcon={<IosShareIcon
              fontSize="small"
              style={{ transform: "rotate(90deg)" }}
            />}
          >
            Export
          </ActionButton>
        </>
      </StewartDialogActions>
    </StewartDialog>
  );
}

export default FileExportDialog;
