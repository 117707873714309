import { Typography } from "@mui/material";
import AutocompleteField from "controls/global/custom-autocomplete";
import { SavedContactResponse } from "entities/UIModel";
import React from "react";
import { useUser } from "utils/context/UserContext";
import { PartyRoleType } from "utils/data/enum";

interface Props {
  name: string;
  index: number;
  value: string;
  onChange: (index: number, newValue: SavedContactResponse | string) => void;
  onBlur?: () => void;
}

const CompanyName = ({ name, index, value, onChange, onBlur }: Props) => {
  const [{ isLoading }, { getSavedContacts }] = useUser();

  const isSavedContact = (
    option: SavedContactResponse | string
  ): option is SavedContactResponse =>
    (option as SavedContactResponse).ContactCompanyName !== undefined;

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: SavedContactResponse) => (
    <Typography component="li" {...props}>{option.ContactCompanyNameAndAddress}</Typography>
  );

  const onGetContacts = (
    newInputValue: string
  ): Promise<SavedContactResponse[]> => {
    if (!newInputValue) return new Promise<SavedContactResponse[]>(() => []);
    return getSavedContacts(newInputValue, PartyRoleType.Lender);
  };

  const onValueChanged = (newValue: SavedContactResponse) => {
    onChange && onChange(index, newValue);
  };

  const getOptionLabel = (option: SavedContactResponse | string) =>
    isSavedContact(option) ? option.ContactCompanyName : option;

  const getOptionSelected = (
    option: SavedContactResponse,
    value: SavedContactResponse
  ) => option.IdentityKey === value.IdentityKey;

  return (
    <AutocompleteField
      label="Company Name"
      loading={isLoading}
      minLengthBeforeSearch={3}
      name={name}
      onGetOptions={onGetContacts}
      onValueChanged={onValueChanged}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onBlur={onBlur}
    />
  );
};

export default CompanyName;
