import ComboBoxField from "controls/global/combo-box-field/ComboBoxInputField";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Agency } from "entities/UIModel";
// import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import React, {
  useEffect, 
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useDocument } from "utils/context/DocumentContext";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface Props {
  onChange?: (value: Agency) => void;  
  setUploadDisabled?: (disabled: boolean) => void;
}

const agencyFieldName = "agency";
const AgencyField = ({onChange, setUploadDisabled}:Props) => {
  const { setValue, getValues, setError  } = useFormContext();  
  const [agency] = useWatch({
    name: [agencyFieldName],
  });

  const [{ selectedAgency, allAgencies }, {getCompanyLocationStateListByUser}] = useGlobalAccess();
  const [
    { uploadImageLastSelectedAgency },
    { setUploadImageLastSelectedAgency },
  ] = useDocument();
  const [{ profileSettings }] = useProfileSettingsCache();
  const [filteredAgencies, setFilteredAgencies] = React.useState<SelectFieldOption[]>([]); 
  
  const handleAgencyNameChange = (item: any) => {
    const agency: Agency = {
      id: item.value,
      name: item.text,
      activeContractID: item.activeContractID,
      legacyID: item.legacyID,      
    }
    setValue(agencyFieldName, agency)
    setUploadImageLastSelectedAgency(agency);
    onChange && onChange(agency);
  };

  const handleOnBlur = async (e: any) => {    
    if (typeof getValues(agencyFieldName) == "string") { 
      setTimeout(() => {
        setValue(agencyFieldName, {});        
        setUploadImageLastSelectedAgency(null);
      }, 10);

      setError(
        "agency",
        {
          type: "required",
          message: "Required",
        },
        { shouldFocus: false },
      );       
      return;
    }
  };  

  const buildAgency = (agencyOption: SelectFieldOption) => {    
    return {
      id: agencyOption.value,
      name: agencyOption.text,
      activeContractID: agencyOption.activeContractID,
      legacyID: agencyOption.legacyID,
    };
  };  

  useEffect(() => {
    if (allAgencies?.length > 0) {
      if (selectedAgency?.length > 0) {
        const defaultAgency = allAgencies?.find((a) => a.value === selectedAgency[0]?.CompanyID);      
        if (defaultAgency) {
          setUploadImageLastSelectedAgency(buildAgency(defaultAgency));
          setFilteredAgencies([defaultAgency]);
          setTimeout(() => {
            setValue(agencyFieldName, buildAgency(defaultAgency));
          }, 10);
        }        
      }
      else {
        setFilteredAgencies(allAgencies.sort((a,b) => a.text.localeCompare(b.text) ));         
        if(profileSettings?.userProfile.defaultAgencyID){
          const defaultAgency = allAgencies?.find((a) => a.value === profileSettings.userProfile.defaultAgencyID);      
          if (defaultAgency) {                            
            setTimeout(() => {
              setValue(agencyFieldName, buildAgency(defaultAgency));
            }, 10);
          } 
        }
        else{
          // Reset AgencyField when no default is present
          setTimeout(() => {
            setValue(agencyFieldName, "");        
          }, 10);
        }
      }
    }

  }, [allAgencies, selectedAgency, profileSettings])

  // Set default agency
  useEffect(() => {
    if (filteredAgencies?.length === 1) {
      setTimeout(() => {
        setValue(agencyFieldName, buildAgency(filteredAgencies[0]));      
      }, 10);
    }
    else if (uploadImageLastSelectedAgency?.id) {
      setTimeout(() => {
        setValue(agencyFieldName, uploadImageLastSelectedAgency);  
      }, 10);
    }
    else if(profileSettings?.userProfile.defaultAgencyID) {
      const defaultAgency = filteredAgencies?.find((a) => a.value === profileSettings.userProfile.defaultAgencyID);      
      if (defaultAgency) {              
        setTimeout(()=> {
          setValue(agencyFieldName, buildAgency(defaultAgency));            
        }, 10);
      }        
    }
  },[filteredAgencies])

  useEffect(() => {
    if (agency?.id) {
      setUploadDisabled && setUploadDisabled(false);
    }
    else {
      setUploadDisabled && setUploadDisabled(true);
    }
  },[agency]);

  useEffect(() => {
    getCompanyLocationStateListByUser(true);
  }, [])
  
  return (
    <ComboBoxField
        label={"Agency"}        
        options={filteredAgencies}
        {...{ name: agencyFieldName }}
        onChange={(_: any, selectedValue: any) => {
          handleAgencyNameChange(selectedValue);
        }}
        roundedCorner={true}
        onBlur={handleOnBlur}
        freeSolo={true} 
        disableClearable={true}
        controlTooltipOnMouseEvents={true}
      />
  );
};

export default AgencyField;
