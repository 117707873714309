type State = {
  revising: boolean;
  previousProduct: any;
  index: number;
};

type Action = {
  type: string;
  productIndex: number;
  payload?: any;
};

const ACTIONS = {
  START_REVISE: "start-revise",
  CANCEL_REVISE: "cancel-revise",
  SUBMIT_REVISE: "submit-revise",
};

const initialState: State = {
  revising: false,
  previousProduct: null,
  index: -1,
};

const reducer = (state: State, action: Action) => {
  const { type, payload, productIndex } = action;

  switch (type) {
    case ACTIONS.START_REVISE:
      return {
        ...state,
        revising: true,
        previousProduct: payload,
        index: productIndex,
      };
    case ACTIONS.CANCEL_REVISE:
      return { ...state, revising: false, previousProduct: null, index: -1 };
    case ACTIONS.SUBMIT_REVISE:
      return { ...state, revising: false };
    default:
      throw new Error(`Not supported action type: ${type}`);
  }
};

export { ACTIONS, initialState, reducer };
