import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import React from "react";

type State = {
  dialogOpen: boolean;
  title: string;
  content: React.ReactNode;
  dialogActions?: React.ReactNode;
  onSubmitAction?: (...args: unknown[]) => unknown;
  onCancelAction?: (...args: unknown[]) => unknown;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;
type DialogProps = {
  title: string;
  content: React.ReactNode;
  dialogActions?: React.ReactNode;
  onSubmitAction?: (...args: unknown[]) => unknown;
  onCancelAction?: (...args: unknown[]) => unknown;
};

const openDialog =
  ({ title, content, dialogActions, onSubmitAction, onCancelAction }: DialogProps) =>
  ({ setState }: StoreApi) => {
    setState({
      dialogOpen: true,
      title,
      content,
      dialogActions,
      onSubmitAction,
      onCancelAction,
    });
  };

const onModalCancel =
  () =>
  ({ setState, getState }: StoreApi) => {
    getState().onCancelAction?.();
    setState({
      dialogOpen: false,
      title: undefined,
      content: undefined,
      dialogActions: undefined,
      onSubmitAction: undefined,
      onCancelAction: undefined,
    });
  };

const onModalSubmit =
  () =>
  ({ setState, getState }: StoreApi) => {
    getState().onSubmitAction?.();
    setState({
      dialogOpen: false,
      title: undefined,
      content: undefined,
      dialogActions: undefined,
      onSubmitAction: undefined,
      onCancelAction: undefined,
    });
  };

const actions = {
  openDialog,
  onModalCancel,
  onModalSubmit,
};

const Store = createStore<State, Actions>({
  initialState: {
    dialogOpen: false,
    title: "",
    content: undefined,
    dialogActions: undefined,
  },
  actions,
  name: "Dialog",
});

const hook = createHook(Store);
export const useDialog = () => { return hook() };
