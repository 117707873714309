import * as ApiModel from "entities/ApiModel";
import {
  Agency,
  AgencyLocation,
  PricingDetail,
  PricingProduct,
  PricingProductItem,
  SCFile,
} from "entities/UIModel";
import * as withSCFile from "pages/file/utils/fromApi/scFileCreator";
import {
  OrderStatusType,
  PartyRoleType,
  PricingType,
  ProductType,
} from "utils/data/enum";
import { getFormikInitialValues } from "utils/data/formikInitialValues";
import {
  checkIfFileIsLocked,
  getSumPricingTaxes,
} from "./helper";

const setSCFile = (fileFromMiddleware: ApiModel.SCFile, isPopulatingExistingFile: boolean) => {
  let errorMessage = fileFromMiddleware.ErrorMessage;
  let fileId = fileFromMiddleware.FileID;
  let isLocked = fileFromMiddleware.IsLocked || fileFromMiddleware.IsReadOnly ? 1 : 0;
  let isReadOnly = fileFromMiddleware.IsReadOnly;
  let identityKey = fileFromMiddleware.IdentityKey;
  let integrationKey = fileFromMiddleware.IntegrationKey;
  let statusTypeCode = fileFromMiddleware.FileStatusTypeCode;
  let statusTypeName = fileFromMiddleware.FileStatusTypeName;
  let prevStatusTypeCode = fileFromMiddleware.FileStatusTypeCode;
  let fileNameNumber = fileFromMiddleware.ClientFileID;
  let filePricingID = fileFromMiddleware.FilePricingID;
  let authorityLimit = fileFromMiddleware.AuthorityLimit;
  let transactionTypeCode = fileFromMiddleware.TransactionTypeCode;
  let legacyID = fileFromMiddleware.LegacyID;
  let altFileReferenceId = fileFromMiddleware.AltFileReferenceID;
  let locationUniqueID = fileFromMiddleware.LocationUniqueID;
  let originationSource = fileFromMiddleware.OriginationSource;

  const agency: Agency = {
    id: fileFromMiddleware.AgencyID,
    name: fileFromMiddleware.AgencyName,
  };
  const agencyLocation: AgencyLocation = {
    id: fileFromMiddleware.LocationDisplayName,
    name: fileFromMiddleware.LocationDisplayName,
    legacyID: fileFromMiddleware.LegacyID,
    locationID: fileFromMiddleware.LocationID,
    locationUniqueID: fileFromMiddleware.LocationUniqueID,
    isValidationRequired: false,
  };

  const property = withSCFile.createProperties(fileFromMiddleware.Properties);
  const buyerBorrowerParties = withSCFile.createParties(
    PartyRoleType.BuyerBorrower,
    fileFromMiddleware.Parties
  );
  const fileActivites = withSCFile.createFileActivity(
    fileFromMiddleware.FileActivities || []
  );

  const sellerParties = withSCFile.createParties(
    PartyRoleType.Seller,
    fileFromMiddleware.Parties
  );
  const lenderParties = withSCFile.createParties(
    PartyRoleType.Lender,
    fileFromMiddleware.Parties
  );
  const additionalParties = withSCFile.createParties(
    PartyRoleType.AdditionalParty,
    fileFromMiddleware.Parties
  );

  const combinedQNAs = withSCFile.createCombinedQNAs(
    fileFromMiddleware.CombinedQNAs
  );

  const aALProducts = withSCFile.createAals(fileFromMiddleware.AALProducts);
  const cpls = withSCFile.createCpls(fileFromMiddleware.CPLProducts);
  const jackets = withSCFile.createJackets(fileFromMiddleware.JacketProducts);
  const standaloneEndorsements = withSCFile.createStandaloneEndorsements(
    fileFromMiddleware.StandaloneEndorsementProducts
  );

  const pricing = withSCFile.createFilePricing(
    fileFromMiddleware.FilePricing
  );

  const documentFileData = withSCFile.createDocumentFileData(
    fileFromMiddleware.DocumentFileData
  );

  let hasJacketProducts = false;
  let hasCPLProducts = false;
  let hasAALProducts = false;

  fileFromMiddleware.JacketProducts?.forEach((jp) => {
    if (
      jp.OrderStatusTypeCode === OrderStatusType.Issued ||
      jp.OrderStatusTypeCode === OrderStatusType.Voided
    ) {
      hasJacketProducts = true;
    }
  });

  fileFromMiddleware.CPLProducts?.forEach((jp) => {
    if (
      jp.OrderStatusTypeCode === OrderStatusType.Issued ||
      jp.OrderStatusTypeCode === OrderStatusType.Voided
    ) {
      hasCPLProducts = true;
    }
  });

  fileFromMiddleware.AALProducts?.forEach((jp) => {
    if (
      jp.OrderStatusTypeCode === OrderStatusType.Issued ||
      jp.OrderStatusTypeCode === OrderStatusType.Voided
    ) {
      hasAALProducts = true;
    }
  });

  if (!(hasJacketProducts || hasCPLProducts || hasAALProducts)) {
    pricing.isSimultaneousRateAllowed = false;
    pricing.isEffectiveDateAllowed = false;
  }

  const filePricingDetails = withSCFile.createFilePricingDetails(
    fileFromMiddleware.FilePricingDetails,
    fileFromMiddleware.CPLProducts,
    fileFromMiddleware.AALProducts,
    fileFromMiddleware.JacketProducts,
    fileFromMiddleware.StandaloneEndorsementProducts
  );
  const pricingProducts = withSCFile.createPricingProducts(
    checkIfFileIsLocked(fileFromMiddleware.IsLocked || (fileFromMiddleware.IsReadOnly ? 1 : 0)),
    // fileFromMiddleware.FilePricing?.IsIntegratedPricing || false,
    fileFromMiddleware.FilePricing?.ShowTax || false,
    fileFromMiddleware.JacketProducts,
    fileFromMiddleware.CPLProducts,
    fileFromMiddleware.AALProducts,
    fileFromMiddleware.StandaloneEndorsementProducts,
    fileFromMiddleware.FilePricing?.ShowFileSpecialCharge || false
  );

  const initialValuesFromApi = getFormikInitialValues(
    isPopulatingExistingFile,
    isLocked,
    isReadOnly,
    fileId,
    identityKey,
    integrationKey,
    statusTypeCode,
    statusTypeName,
    prevStatusTypeCode,
    fileNameNumber,
    filePricingID,
    transactionTypeCode,
    agency,
    agencyLocation,
    aALProducts,
    cpls,
    jackets,
    standaloneEndorsements,
    property,
    buyerBorrowerParties,
    lenderParties,
    additionalParties,
    combinedQNAs,
    filePricingDetails,
    pricingProducts,
    sellerParties,
    fileActivites,
    errorMessage,
    pricing,
    authorityLimit,
    legacyID,
    locationUniqueID,
    altFileReferenceId,
    documentFileData,
    originationSource,
  );

  initialValuesFromApi.hasIssuedProducts = false;

  initialValuesFromApi.jackets.forEach((jackets) => {
    if (
      jackets.orderStatusTypeCode === OrderStatusType.Issued ||
      jackets.orderStatusTypeCode === OrderStatusType.Voided
    ) {
      initialValuesFromApi.hasIssuedProducts = true;
    }
  });

  if (!initialValuesFromApi.hasIssuedProducts) {
    initialValuesFromApi.cpls.forEach((cpl) => {
      if (
        cpl.orderStatusTypeCode === OrderStatusType.Issued ||
        cpl.orderStatusTypeCode === OrderStatusType.Voided
      ) {
        initialValuesFromApi.hasIssuedProducts = true;
      }
    });
  }

  if (!initialValuesFromApi.hasIssuedProducts) {
    initialValuesFromApi.aALProducts &&
      initialValuesFromApi.aALProducts.forEach((aal) => {
        if (
          aal.orderStatusTypeCode === OrderStatusType.Issued ||
          aal.orderStatusTypeCode === OrderStatusType.Voided
        ) {
          initialValuesFromApi.hasIssuedProducts = true;
        }
      });
  }

  if (!initialValuesFromApi.hasIssuedProducts) {
    initialValuesFromApi.standaloneEndorsements.forEach((saEndorsement) => {
      if (
        saEndorsement.orderStatusTypeCode === OrderStatusType.Issued ||
        saEndorsement.orderStatusTypeCode === OrderStatusType.Voided
      ) {
        initialValuesFromApi.hasIssuedProducts = true;
      }
    });
  }

  initialValuesFromApi.hasIssuedJackets = !!initialValuesFromApi.jackets?.find(
    (j) => j.orderStatusTypeCode === OrderStatusType.Issued
  );

  return initialValuesFromApi;
};

const getUpdatedPricingDetail = (
  responseScfile: ApiModel.SCFile,
  productType: string,
  pricingType: string,
  productId: number,
  filePricingDetailId: number
) => {
  let updatedPricingDetail: ApiModel.FilePricingDetail | undefined;

  switch (productType) {
    case ProductType.Cpl:
      updatedPricingDetail = responseScfile.CPLProducts?.find(
        (p) =>
          (p.FilePricingDetailID || 0) > 0 &&
          p.FilePricingDetailID === filePricingDetailId
      )?.PricingDetail;
      break;

    case ProductType.Aal:
      updatedPricingDetail = responseScfile.AALProducts?.find(
        (p) =>
          (p.FilePricingDetailID || 0) > 0 &&
          p.FilePricingDetailID === filePricingDetailId
      )?.PricingDetail;
      break;

    case ProductType.Jacket:
      if (pricingType === PricingType.Product) {
        updatedPricingDetail = responseScfile.JacketProducts?.find(
          (p) =>
            (p.FilePricingDetailID || 0) > 0 &&
            p.FilePricingDetailID === filePricingDetailId
        )?.PricingDetail;
      } else if (pricingType === PricingType.ProductItem) {
        updatedPricingDetail = responseScfile.JacketProducts?.find(
          (p) => p.ProductID > 0 && p.ProductID === productId
        )?.Endorsements?.find(
          (e) =>
            e.FilePricingDetailID > 0 &&
            e.FilePricingDetailID === filePricingDetailId
        )?.PricingDetail;
      }
      break;

    case ProductType.StandaloneEndorsement:
      if (pricingType === PricingType.Product) {
        updatedPricingDetail =
          responseScfile.StandaloneEndorsementProducts?.find(
            (p) =>
              (p.FilePricingDetailID || 0) > 0 &&
              p.FilePricingDetailID === filePricingDetailId
          )?.PricingDetail;
      } else if (pricingType === PricingType.ProductItem) {
        updatedPricingDetail =
          responseScfile.StandaloneEndorsementProducts?.find(
            (p) => p.ProductID > 0 && p.ProductID === productId
          )?.Endorsements?.find(
            (e) =>
              e.FilePricingDetailID > 0 &&
              e.FilePricingDetailID === filePricingDetailId
          )?.PricingDetail;
      }
      break;
  }

  return updatedPricingDetail;
};

const updatePricingDetail = (
  pricingDetail: PricingDetail | undefined,
  responseScfile: ApiModel.SCFile,
  productType: string,
  pricingType: string,
  productId: number,
  filePricingDetailId: number,
  isIntegratedPricing: boolean,
  formType: string
) => {
  const updatedPricingDetail = getUpdatedPricingDetail(
    responseScfile,
    productType,
    pricingType,
    productId,
    filePricingDetailId
  );

  if (pricingDetail && updatedPricingDetail) {
    pricingDetail.actualFee = updatedPricingDetail.ActualFee;
    pricingDetail.calculatedFee = updatedPricingDetail.CalculatedFee;

    pricingDetail.actualRiskRate = updatedPricingDetail.ActualRiskRate;
    pricingDetail.calculatedRiskRate = updatedPricingDetail.CalculatedRiskRate;

    pricingDetail.actualRetention = updatedPricingDetail.ActualRetention;
    pricingDetail.calculatedRetention =
      updatedPricingDetail.CalculatedRetention;

    pricingDetail.actualRemittance = updatedPricingDetail.ActualRemittance;
    pricingDetail.calculatedRemittance =
      updatedPricingDetail.CalculatedRemittance;

    pricingDetail.actualPremiumTax = updatedPricingDetail.ActualPremiumTax;
    pricingDetail.calculatedPremiumTax =
      updatedPricingDetail.CalculatedPremiumTax;

    pricingDetail.chargeType = updatedPricingDetail.ChargeType;
    pricingDetail.coverageType = updatedPricingDetail.CoverageType;
    pricingDetail.coverageTypeData = updatedPricingDetail.CoverageTypeData;
    pricingDetail.pricingRateType = updatedPricingDetail.PricingRateType;
    pricingDetail.pricingRateTypeData =
      updatedPricingDetail.PricingRateTypeData;
    pricingDetail.pricingTransactionTypeCode =
      updatedPricingDetail.PricingTransactionTypeCode;
    pricingDetail.pricingFeeTypeCode = updatedPricingDetail.PricingFeeTypeCode;
    pricingDetail.altPricingReferenceID =
      updatedPricingDetail.AltPricingReferenceID;
    pricingDetail.lastErrorMessage = updatedPricingDetail.LastErrorMessage;
    pricingDetail.lastDisplayMessage = updatedPricingDetail.LastDisplayMessage;
    pricingDetail.isFeeError = updatedPricingDetail.IsFeeError;
    //pricingDetail.isManualFee = updatedPricingDetail.IsManualFee;

    // pricingDetail.isBilled = updatedPricingDetail.IsBilled;
    // robun 12/13/2021 - don't update isReportable -- because isReportable was set once the product was issued
    //pricingDetail.isReportable = updatedPricingDetail.IsReportable;
    // pricingDetail.isReadyToBeBilled = updatedPricingDetail.IsReadyToBeBilled;
  }
  return pricingDetail;
};

const updatePricingProductItem = (
  pricingProductItem: PricingProductItem,
  responseScfile: ApiModel.SCFile,
  productType: string,
  productId: number,
  filePricingDetailId: number,
  isIntegratedPricing: boolean,
  formType: string
) => {
  const updatedPricingDetail = getUpdatedPricingDetail(
    responseScfile,
    productType,
    PricingType.ProductItem,
    productId,
    filePricingDetailId
  );

  if (updatedPricingDetail) {
    pricingProductItem.actualFee = updatedPricingDetail.ActualFee || 0;
    pricingProductItem.actualFeeInitial = updatedPricingDetail.ActualFee || 0;
    pricingProductItem.calculatedFee = updatedPricingDetail.CalculatedFee || 0;

    pricingProductItem.actualRiskRate =
      updatedPricingDetail.ActualRiskRate || 0;
    pricingProductItem.actualRiskRateInitial =
      updatedPricingDetail.ActualRiskRate || 0;
    pricingProductItem.calculatedRiskRate =
      updatedPricingDetail.CalculatedRiskRate || 0;

    pricingProductItem.agentRetention =
      updatedPricingDetail.ActualRetention || 0;
    pricingProductItem.agentRetentionInitial =
      updatedPricingDetail.ActualRetention || 0;
    pricingProductItem.calculatedRetention =
      updatedPricingDetail.CalculatedRetention || 0;

    pricingProductItem.totalDue = updatedPricingDetail.ActualRemittance || 0;
    pricingProductItem.totalDueInitial =
      updatedPricingDetail.ActualRemittance || 0;
    pricingProductItem.calculatedTotalDue =
      updatedPricingDetail.CalculatedRemittance || 0;

    pricingProductItem.actualPremiumTax =
      updatedPricingDetail.ActualPremiumTax || 0;
    pricingProductItem.calculatedPremiumTax =
      updatedPricingDetail.CalculatedPremiumTax || 0;

    pricingProductItem.altPricingReferenceID =
      updatedPricingDetail.AltPricingReferenceID;
    pricingProductItem.lastErrorMessage = updatedPricingDetail.LastErrorMessage;
    pricingProductItem.lastDisplayMessage = updatedPricingDetail.LastDisplayMessage;
    pricingProductItem.isFeeError = updatedPricingDetail.IsFeeError;
    //pricingProductItem.isManualFee = updatedPricingDetail.IsManualFee;

    // pricingProductItem.isBilled = updatedPricingDetail.IsBilled;
    // robun 12/13/2021 - don't update isReportable -- because isReportable was set once the product was issued
    // pricingProductItem.isReadyToBeBilled = updatedPricingDetail.IsReadyToBeBilled;
  }
};

const getUpdatedRateTypes = (
  responseScfile: ApiModel.SCFile,
  productType: string,
  productId: number
) => {
  let rateTypes: string[] | undefined = undefined;

  // Only jacket has rate types
  switch (productType) {
    case ProductType.Jacket:
      rateTypes = responseScfile.JacketProducts?.find(
        (p) => p.ProductID && p.ProductID === productId
      )?.RateTypes;
      break;
    default:
      rateTypes = undefined;
      break;
  }

  return rateTypes;
};

const updatePricingProduct = (
  pricingProduct: PricingProduct,
  responseScfile: ApiModel.SCFile,
  productType: string,
  productId: number,
  filePricingDetailId: number,
  isIntegratedPricing: boolean,
  formType: string
) => {
  const updatedPricingDetail = getUpdatedPricingDetail(
    responseScfile,
    productType,
    PricingType.Product,
    productId,
    filePricingDetailId
  );

  if (updatedPricingDetail) {
    pricingProduct.actualFee = updatedPricingDetail.ActualFee || 0;
    pricingProduct.actualFeeInitial = updatedPricingDetail.ActualFee || 0;
    pricingProduct.calculatedFee = updatedPricingDetail.CalculatedFee || 0;

    pricingProduct.actualRiskRate = updatedPricingDetail.ActualRiskRate || 0;
    pricingProduct.isReissue = updatedPricingDetail.IsReissue || false;
    pricingProduct.actualRiskRateInitial =
      updatedPricingDetail.ActualRiskRate || 0;
    pricingProduct.calculatedRiskRate =
      updatedPricingDetail.CalculatedRiskRate || 0;

    pricingProduct.agentRetention = updatedPricingDetail.ActualRetention || 0;
    pricingProduct.agentRetentionInitial =
      updatedPricingDetail.ActualRetention || 0;
    pricingProduct.calculatedRetention =
      updatedPricingDetail.CalculatedRetention || 0;

    pricingProduct.totalDue = updatedPricingDetail.ActualRemittance || 0;
    pricingProduct.totalDueInitial = updatedPricingDetail.ActualRemittance || 0;
    pricingProduct.calculatedTotalDue =
      updatedPricingDetail.CalculatedRemittance || 0;

    pricingProduct.actualPremiumTax =
      updatedPricingDetail.ActualPremiumTax || 0;
    pricingProduct.calculatedPremiumTax =
      updatedPricingDetail.CalculatedPremiumTax || 0;

    pricingProduct.pricingRateType = updatedPricingDetail.PricingRateType;
    pricingProduct.pricingRateTypeData =
      updatedPricingDetail.PricingRateTypeData;
    pricingProduct.pricingTransactionTypeCode =
      updatedPricingDetail.PricingTransactionTypeCode;
    pricingProduct.pricingFeeTypeCode = updatedPricingDetail.PricingFeeTypeCode;
    pricingProduct.altPricingReferenceID =
      updatedPricingDetail.AltPricingReferenceID;
    pricingProduct.lastErrorMessage = updatedPricingDetail.LastErrorMessage;
    pricingProduct.lastDisplayMessage = updatedPricingDetail.LastDisplayMessage;
    pricingProduct.isFeeError = updatedPricingDetail.IsFeeError;
    //pricingProduct.isManualFee = updatedPricingDetail.IsManualFee;
    // pricingProduct.isBilled = updatedPricingDetail.IsBilled;
    // pricingProduct.isReadyToBeBilled = updatedPricingDetail.IsReadyToBeBilled;
    // robun 12/13/2021 - don't update isReportable -- because isReportable was set once the product was issued
  }
  pricingProduct.rateTypes = getUpdatedRateTypes(
    responseScfile,
    productType,
    productId
  )?.map((type) => {
    return {
      text: type,
      value: type,
    };
  });
};

const setCalculatedSCFile = (currentScfile: SCFile, responseScfile: ApiModel.SCFile) => {
  const newInitialValues = currentScfile;
  // --- Update FilePricing

  if (responseScfile?.FilePricing) {
    newInitialValues.pricing = withSCFile.createFilePricing(
      responseScfile.FilePricing
    );
  }

  const isIntegratedPricing =
    currentScfile.pricing?.isIntegratedPricing || false;
  // --- Update all products' pricing here ---
  // -- cpls
  newInitialValues.cpls
    ?.filter((p) => (p.filePricingDetailID || 0) > 0)
    ?.map((p) => {
      p.pricingDetail = updatePricingDetail(
        p.pricingDetail,
        responseScfile,
        ProductType.Cpl,
        PricingType.Product,
        p.id!,
        p.filePricingDetailID!,
        isIntegratedPricing,
        ""
      );

      return p;
    });

  // -- aals
  newInitialValues.aALProducts
    ?.filter((p) => (p.filePricingDetailID || 0) > 0)
    ?.map((p) => {
      p.pricingDetail = updatePricingDetail(
        p.pricingDetail,
        responseScfile,
        ProductType.Aal,
        PricingType.Product,
        p.id!,
        p.filePricingDetailID!,
        isIntegratedPricing,
        ""
      );

      return p;
    });

  // -- jackets
  newInitialValues.jackets
    ?.filter((p) => (p.filePricingDetailID || 0) > 0)
    ?.map((p) => {
      // update product item pricing detail (children)
      p.endorsements
        ?.filter((e) => (e.filePricingDetailID || 0) > 0)
        ?.map((e) => {
          e.pricingDetail = updatePricingDetail(
            e.pricingDetail,
            responseScfile,
            ProductType.Jacket,
            PricingType.ProductItem,
            p.id!,
            e.filePricingDetailID!,
            isIntegratedPricing,
            ""
          );

          return e;
        });

      // update product pricing detail (parent)
      p.pricingDetail = updatePricingDetail(
        p.pricingDetail,
        responseScfile,
        ProductType.Jacket,
        PricingType.Product,
        p.id!,
        p.filePricingDetailID!,
        isIntegratedPricing,
        p.formType
      );

      return p;
    });

  // -- standalone
  newInitialValues.standaloneEndorsements
    ?.filter((p) => (p.filePricingDetailID || 0) > 0)
    ?.map((p) => {
      // update product item pricing detail (children)
      p.endorsements
        ?.filter((e) => (e.filePricingDetailID || 0) > 0)
        ?.map((e) => {
          e.pricingDetail = updatePricingDetail(
            e.pricingDetail,
            responseScfile,
            ProductType.StandaloneEndorsement,
            PricingType.ProductItem,
            p.id!,
            e.filePricingDetailID!,
            isIntegratedPricing,
            ""
          );

          return e;
        });

      // update product pricing detail (parent)
      p.pricingDetail = updatePricingDetail(
        p.pricingDetail,
        responseScfile,
        ProductType.StandaloneEndorsement,
        PricingType.Product,
        p.id!,
        p.filePricingDetailID!,
        isIntegratedPricing,
        ""
      );

      return p;
    });

  // --- Update filePricingDetails ---
  newInitialValues.filePricingDetails?.map((fp) => {
    const updatedPricingDetail = responseScfile.FilePricingDetails?.find(
      (p) => p.FilePricingDetailID === fp.id
    );
    if (updatedPricingDetail) {
      fp.actualFee = updatedPricingDetail.ActualFee;
      fp.actualPremiumTax = updatedPricingDetail.ActualPremiumTax;
      fp.actualRemittance = updatedPricingDetail.ActualRemittance;
      fp.actualRetention = updatedPricingDetail.ActualRetention;
      fp.actualRiskRate = updatedPricingDetail.ActualRiskRate;
      fp.calculatedFee = updatedPricingDetail.CalculatedFee;
      fp.calculatedPremiumTax = updatedPricingDetail.CalculatedPremiumTax;
      fp.calculatedRemittance = updatedPricingDetail.CalculatedRemittance;
      fp.calculatedRetention = updatedPricingDetail.CalculatedRetention;
      fp.calculatedRiskRate = updatedPricingDetail.CalculatedRiskRate;
      fp.chargeType = updatedPricingDetail.ChargeType;
      fp.coverageType = updatedPricingDetail.CoverageType;
      fp.coverageTypeData = updatedPricingDetail.CoverageTypeData;
      fp.pricingRateType = updatedPricingDetail.PricingRateType;
      fp.pricingRateTypeData = updatedPricingDetail.PricingRateTypeData;
      fp.pricingTransactionTypeCode =
        updatedPricingDetail.PricingTransactionTypeCode;
      fp.pricingFeeTypeCode = updatedPricingDetail.PricingFeeTypeCode;
      fp.altPricingReferenceID = updatedPricingDetail.AltPricingReferenceID;
      fp.lastErrorMessage = updatedPricingDetail.LastErrorMessage;
      fp.isFeeError = updatedPricingDetail.IsFeeError;
      //fp.isManualFee = updatedPricingDetail.IsManualFee;
    }
    return fp;
  });

  // --- Update PricingProduct ---
  let sumPricingTaxes: Array<any> = [];

  // -- Update Product only
  newInitialValues.pricingProducts
    ?.filter((pp) => pp.pricingType === PricingType.Product)
    ?.map((pp) => {
      // Product
      updatePricingProduct(
        pp,
        responseScfile,
        pp.productType,
        pp.productId,
        pp.filePricingDetailId,
        isIntegratedPricing,
        pp.formType || ""
      );
      // Product Item
      pp.productItems?.map((pi) => {
        updatePricingProductItem(
          pi,
          responseScfile,
          pp.productType,
          pp.productId,
          pi.filePricingDetailId,
          isIntegratedPricing,
          ""
        );
        return pi;
      });

      const { actualPremiumTaxSum, calculatedPremiumTaxSum, actualFeeSum } =
        getSumPricingTaxes(
          pp,
          checkIfFileIsLocked(responseScfile.IsLocked)
        );

      const filePricingDetailId = pp.filePricingDetailId;
      sumPricingTaxes.push({
        filePricingDetailId,
        actualPremiumTaxSum,
        calculatedPremiumTaxSum,
        actualFeeSum,
      });
      return pp;
    });

  // -- Update Product's Tax
  newInitialValues.pricingProducts
    ?.filter((pp) => pp.pricingType === PricingType.Tax)
    ?.map((pp) => {
      const pricingTax = sumPricingTaxes?.find(
        (pt) => pt.filePricingDetailId === pp.filePricingDetailId
      );

      if (pricingTax) {
        pp.actualFee = pricingTax.actualPremiumTaxSum;
        pp.actualFeeInitial = pricingTax.actualPremiumTaxSum;
        pp.calculatedFee = pricingTax.calculatedPremiumTaxSum;

        pp.totalDue = pricingTax.actualPremiumTaxSum;
        pp.totalDueInitial = pricingTax.actualPremiumTaxSum;
        pp.calculatedTotalDue = pricingTax.calculatedPremiumTaxSum;
        pp.totalProductPremium = pricingTax.actualFeeSum;
      }

      return pp;
    });

  return newInitialValues;
};

export {
  getUpdatedPricingDetail, getUpdatedRateTypes, setCalculatedSCFile, setSCFile,
  updatePricingDetail, updatePricingProduct, updatePricingProductItem
};

