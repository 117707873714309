import React from "react";
import {
  getCacheKey,
  toSelectFieldOption,
  useEndorsementState,
} from "utils/context/CompanyEndorsementContext";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { ProductType } from "utils/data/enum";
import EndorsementGrid from "../../common/endorsement-grid/EndorsementGrid";

type Props = {
  index: number;
  formType: string | null | undefined;
  editMode: boolean;
  minDate?: Date | null;
  parentOrderID: number;
  disabled: boolean;
  issuedMode: boolean;
  isIssuable: boolean;
  handleEndorsementChange: (productIndex: number) => void;
  onVoidComplete?: (productIndex: number) => void;
  isTransCodeRequired?: boolean;
};

export default function Grid({
  index,
  formType,
  editMode,
  minDate,
  parentOrderID,
  disabled,
  issuedMode,
  isIssuable,
  handleEndorsementChange,
  onVoidComplete,
  isTransCodeRequired = false,
}: Props) {
  const { getValues } = useFormWrapper();
  const [{ formEndorsements }] = useEndorsementState(
    getCacheKey(
      getValues("agency.id"),
      formType ?? "",
      getValues("properties.0.state.abbr")
    )
  );  
  return (
    <EndorsementGrid
      name={`standaloneEndorsements.${index}`}
      minDate={minDate}
      disabled={disabled}
      endorsementOptions={toSelectFieldOption(formEndorsements)}
      issuedMode={issuedMode}
      editMode={editMode}
      parentProductType={ProductType.StandaloneEndorsement}
      parentOrderID={parentOrderID}
      showHeader
      isIssueable={isIssuable}
      onEndorsementChange={handleEndorsementChange}
      onVoidComplete={onVoidComplete}
      parentProductIndex={index}
      hasShortFormEndorsements={false}
      formType ={formType ?formType :''}      
      isTransCodeRequired={isTransCodeRequired}
    />
  );
}
