import React from "react";
import AdditionalPartiesAutocomplete from "../AdditionalPartiesAutocomplete";

type Props = {
  schema: string;
  disabled: boolean;
};

export default function ZipCode({ schema, disabled }: Props) {
  return (
    <AdditionalPartiesAutocomplete
      label="Zip Code"
      name="zipCode"
      schema={schema}
      searchProp="zip"
      disabled={disabled}
    />
  );
}
