import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Container,
  TableBody,
  Typography,
} from '@mui/material';
import { axiosSecuredInstance } from 'configurations/axiosConfig';
import EmbedViewer, {
  EmbedViewerRefType,
} from 'controls/global/embed-viewer/EmbedViewer';
import { HELP_CENTER_RELEASE_NOTE_API_PATH } from 'pages/help-center/utils';
import ReleaseNoteIcon from 'theme/icons/ReleaseNoteIcon';
import { default as cssClasses } from '../../HelpCenter.module.css';
import ReleaseNoteHistory from './ReleaseNoteHistory';

export type ReleaseNoteDto = {
  ReleaseNoteId: string;
  Name: string;
  Description: string;
  Version: string;
  PathFile: string;
  DocFileName: string;
  Tags: string;
  ReleaseNotesDate: string;
  FileBase64: string;
};

type ReleaseNoteType = {
  searchTags: string;
};

const ReleaseNote = ({ searchTags }: ReleaseNoteType) => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNoteDto[]>([]);
  const showEmbedViewerRef = useRef<EmbedViewerRefType>(null);

  async function GetReleaseNotesList(): Promise<ReleaseNoteDto[]> {
    const { data } = await axiosSecuredInstance.get(HELP_CENTER_RELEASE_NOTE_API_PATH);
    return data;
  }

  useEffect(() => {
    GetReleaseNotesList().then((result: ReleaseNoteDto[]) => {
      setReleaseNotes(result || []);
    });
  }, []);

  function getFilteredItems() {
    return releaseNotes?.filter?.(
      (rn) => !searchTags || rn.Tags.toLowerCase().includes(searchTags.trim().toLowerCase()),
    );
  }

  const filteredItems = getFilteredItems();

  return (
    <div className={cssClasses.globalReleaseNote}>
      <div className={cssClasses.releaseContainer}>
        <div className={cssClasses.title}>
          <ReleaseNoteIcon />
          <Typography
            className={cssClasses.gradientTitle}
            variant="h6"
          >
            Release Notes
          </Typography>
        </div>
      </div>
      <div className={cssClasses.mainContainer}>
        {filteredItems.length === 0 && !!searchTags ? (
          <>
            <Typography className={cssClasses.noKeywordMatch}>
              {`No matches found for keyword '${searchTags.trim()}'`}
            </Typography>
            <Typography className={cssClasses.adjustKeywords}>
              Please adjust your keyword search and try again.
            </Typography>
          </>
        ) : filteredItems.length > 0 ? (
          <Container
            key="ReleaseNoteActivities"
            sx={{ overflowY: "auto", maxHeight: "356px" }}
          >
            <TableBody>
              <ReleaseNoteHistory records={filteredItems} />
            </TableBody>
          </Container>
        ) : (
          <div className={cssClasses.emptySection}>
            <Typography className={cssClasses.emptySectionLabel}>No Records</Typography>
          </div>
        )}
      </div>
      <EmbedViewer ref={showEmbedViewerRef} />
    </div>
  );
};

export default ReleaseNote;
