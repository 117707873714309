import { styled } from "@mui/material";
import CheckboxInput from "controls/global/checkbox-field/CheckboxInput";
import React from "react";
import {
  colors,
  fontSize,
  fontWeight,
  gaps,
  margin,
} from "theme/defaultStyle";
import { FORMATS } from "./FileExportDialog";

const TITLE = "Choose document format(s):";

type Props = {
  formats: FORMATS;
  setFormats: React.Dispatch<React.SetStateAction<FORMATS>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const Title = styled("div")({
  color: colors.grey08,
  display: "flex",
  fontFamily: "Roboto",
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  justifyContent: "center",
  marginBottom: margin.small2,
});

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  gap: gaps.large2,
  justifyContent: "center",
});

function FileFormatOptions({ formats, setFormats, setDisabled }: Props) {

  const onChangeHandle =
    (format: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFormats = { ...formats, [format]: event.target.checked };
      setFormats(newFormats);

      if (!newFormats["EXCEL"] && !newFormats["PDF"]) setDisabled(true);
      else setDisabled(false);
    };

  return (
    <>
      <Title>
        <span>{TITLE}</span>
      </Title>
      <Container>
        <CheckboxInput
          checked={formats["EXCEL"]}
          disabled={false}
          onChange={onChangeHandle("EXCEL")}
          label="Excel"
        />
        <CheckboxInput
          checked={formats["PDF"]}
          disabled={true}
          onChange={onChangeHandle("PDF")}
          label="PDF"
        />
      </Container>
    </>
  );
}

export default FileFormatOptions;
