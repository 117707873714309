import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import { margin } from "theme/defaultStyle";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";

type Props = {
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  isPricingSectionUpdated: boolean;
};

const PremiumSubtotalCell = styled("div")({
  margin: `0 ${margin.xsmall1} 0 0`,
  width: "250px !important",
});

export default function TotalPremium({
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  isPricingSectionUpdated,
}: Props) {
  const {
    getPremiumSubTotal,
  } = usePricingCalculator();
  const premiumSubTotal = getPremiumSubTotal(isPricingSectionUpdated);

  return (
    <TableRow>
      <TableCell style={{ paddingLeft: 10 }}>
        <PremiumSubtotalCell>Premium Subtotal</PremiumSubtotalCell>
      </TableCell>
      {displayFieldIcon && <TableCell />}
      {displayLiabilityColumn && <TableCell />}
      {displayRateTypeColumn && <TableCell />}
      {displayReissueColumn && <TableCell />}
      {displayTransCodeColumn && <TableCell />}
      <TableCell
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={premiumSubTotal.actualFeeTotalProduct} />
        }
      </TableCell>
      {displayRiskRateColumn && (
        <TableCell
          align="right"
          style={{ maxWidth: "170px" }}
        >
          {hasIssuedProducts &&
            <TooltipCell title={premiumSubTotal.riskRateTotalProduct} />
          }
        </TableCell>
      )}
      <TableCell
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={premiumSubTotal.agentRetentionTotalProduct} />
        }
      </TableCell>
      <TableCell
        align="right"
        style={{ maxWidth: "170px" }}
      >
        {hasIssuedProducts &&
          <TooltipCell title={premiumSubTotal.totalDueTotalProduct} />
        }
      </TableCell>
    </TableRow>
  );
}
