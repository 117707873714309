import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { 
  createHook, 
  createStore, 
  StoreActionApi,
} from "react-sweet-state";
import { 
  AxiosRequestConfig, 
  CancelTokenSource,
} from "axios";

export interface AgencyLocation extends SelectFieldOption {
  id: string;
  disabled?: boolean;
  locationUniqueID?: string;
}

interface State {
  agencyProductLocations: Array<AgencyLocation>;
  isRetrievingAgencyLocations: boolean;
  error: string | null;
  isLoading: boolean;
  axiosCancelToken?: CancelTokenSource;
}

type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const notAny = (data: Array<any>) => !data || data.length <= 0;

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error: any) {
        dispatch(setError(error));
      }
    };

const setProductAgencyLocations =
  (locations: Array<any>) =>
    ({ setState }: StoreApi) => {
      // if no data in locations
      if (notAny(locations)) {
        setState({ isRetrievingAgencyLocations: false, agencyProductLocations: [] });
        return;
      }
      const agencyProductLocations = locations
        .filter((location) => location.legacyID)  // must have legacyID
        .map((location) => ({
          text: location.locationDisplayName,
          value: location.locationDisplayName,
          id: location.companyID,
          disabled: location.isDisabled || false,
          locationUniqueID: location.locationUniqueID,
        }));

      setState({
        agencyProductLocations: agencyProductLocations,
        isRetrievingAgencyLocations: false,
      });
    };

const defaultState: State = {
  agencyProductLocations: [],
  error: null,
  isLoading: false,
  isRetrievingAgencyLocations: false,
};

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getProductAgencyLocations:
      (
        agencyId: string,
        stateCode?: string,
      ) =>
        async ({ dispatch, setState }: StoreApi) => {
          if (!agencyId) return;
          
          const url = `/Company/GetLocationsByCompanyState?companyId=${agencyId}&state=${stateCode}`;
          setState({ isRetrievingAgencyLocations: true });
          return dispatch(getData(url, setProductAgencyLocations));
        },
    updateAgencyLocations:
      (locations: Array<any>) =>
        async ({ dispatch }: StoreApi) => {
          dispatch(setProductAgencyLocations(locations));
        },
  },
  name: "lookup",
});

const hook = createHook(Store);
const useProductAgenycLocations = () => {
  return hook();
};
const useProductAgencyLocationsActions = createHook(Store, {
  selector: null,
});

export { useProductAgenycLocations, useProductAgencyLocationsActions };
