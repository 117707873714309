import { Cancel } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import ActionButton from "controls/global/action-button/ActionButton";
import { Endorsement } from "entities/UIModel/Endorsement";
import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { OrderStatusType } from "utils/data/enum";

type Props = {
  fullName: string;
  isIssuable: boolean;
  submitDisabled: boolean;
  handleStandloneSubmit: () => void;
  handleStandaloneCancel: () => void;
  isProductIssued: boolean;
};
export default function StandAloneProductActions({
  fullName,
  isIssuable,
  submitDisabled,
  handleStandloneSubmit,
  handleStandaloneCancel,
  isProductIssued
}: Props) {
  const { watch } = useFormWrapper();
  const currentStandalone = watch(fullName);

  const hasPendingEndorsements = !currentStandalone?.endorsements
    ? false
    : currentStandalone?.endorsements?.filter(
      (e: Endorsement) =>
        (e.endorsementStatusTypeCode === OrderStatusType.Pending ||
          !e.endorsementID ||
          e.endorsementID === 0) &&
        e.endorsementName
    ).length > 0;

  const showCancel: boolean =
    isProductIssued &&
    currentStandalone?.endorsements?.filter(
      (e: Endorsement) => !e.endorsementID
    ).length > 0;

  return (
    <Box m={1} display="flex" justifyContent="flex-end">
      <ActionButton
        color="primary"
        disabled={!isIssuable || !hasPendingEndorsements || submitDisabled}
        onClick={handleStandloneSubmit}
        startIcon={<CheckIcon />}
        variant="outlined"
      >
        Submit
      </ActionButton>
      {showCancel ? (
        <>
          <Box m={1} />
          <ActionButton
            color="secondary"
            onClick={handleStandaloneCancel}
            startIcon={<Cancel />}
            variant="outlined"
          >
            Cancel
          </ActionButton>
        </>
      ) : null}
    </Box>
  );
}
