import React from "react";
import ActionButton from "controls/global/action-button";
import { useDialog } from "utils/context/DialogContext";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "./StewartDialog";

type Props = {
  onCancel?: () => void;
  onSubmit?: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
};

const CommonStewartDialog = ({
  onCancel,
  onSubmit,
  cancelButtonText = "Cancel",
  submitButtonText = "Submit",
}: Props) => {
  const [{ dialogOpen, title, content, dialogActions }, { onModalSubmit, onModalCancel }] =
    useDialog();

  if (!dialogOpen) return null;
  return (
    <StewartDialog
      open={dialogOpen}
      fullWidth
      maxWidth="md"
      width={425}
    >
      <StewartDialogTitle
        type={"INFO"}
      >
        {title}
      </StewartDialogTitle>
      <StewartDialogContent>
        {content}
      </StewartDialogContent>
      <StewartDialogActions>
        {dialogActions ? dialogActions : (
          <>
            <ActionButton
              variant="outlined"
              color="secondary"
              onClick={() => {
                onModalCancel();
                onCancel?.();
              }}
              startIcon={<ClearIcon />}
            >
              {cancelButtonText}
            </ActionButton>
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={() => {
                onModalSubmit();
                onSubmit?.();
              }}
              startIcon={<CheckIcon />}
            >
              {submitButtonText}
            </ActionButton>
          </>
        )}
      </StewartDialogActions>
    </StewartDialog>
  );
};

export default CommonStewartDialog;
