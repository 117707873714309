import { createTheme } from "@mui/material";
import React from "react";
import {
  borderRadius,
  borderSize,
  colors,
  defaultStyle,
  fontFamily,
  fontSize,
  fontWeight,
  gaps,
  margin,
  padding,
  zIndex,
} from "./defaultStyle";

declare module "@mui/material/styles" {
  interface Theme {
    custom: any;
  }
  interface ThemeOptions {
    custom?: any;
  }
}

declare module "react" {
  interface HTMLAttributes<T> {
    children?: React.ReactNode | Element | any[] | any | T;
  }
}

const { section, label, control, paper } = defaultStyle;

const baseRoundedTable = {
  borderCollapse: "separate",
  borderSpacing: borderSize.zero,
  border: borderSize.zero,
  margin: margin.zero,
  "& th": {
    border: borderSize.zero,
    fontStyle: "normal",
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal2,
    textTransform: "uppercase",
    letterSpacing: "0.17px",
    color: colors.grey08,
    background: colors.white,
  },
  "& td": {
    border: `1px solid ${colors.grey09}`,
    borderStyle: "none none solid none",
    padding: "6px 10px 6px 10px",
  },
  "& tr:first-child": {
    "& td:first-child": {
      borderTopLeftRadius: borderRadius.medium,
    },
    "& td:last-child": {
      borderTopRightRadius: borderRadius.medium,
    }
  },
  "& tr:last-child": {
    "& td:first-child": {
      borderBottomLeftRadius: borderRadius.medium,
    },
    "& td:last-child": {
      borderBottomRightRadius: borderRadius.medium,
    }
  },
  "& tr:first-child td": {
    borderTopStyle: "solid"
  },
  "& tr td:first-child": {
    borderLeftStyle: "solid"
  },
};

const roundedTable = {
  "&.history-table": {
    ...baseRoundedTable,
    "& tr": {
      "& td:first-child": {
        borderLeftStyle: "solid",
      },
      "& td:last-child": {
        borderRightStyle: "solid",
      }
    },
  }
};

const fileUploadStatusTable = {
  "&.file-upload-status-table": {
    ...baseRoundedTable,
    "& tr": {
      "& td:first-child": {
        borderLeftStyle: "solid",
      },
      "& td:last-child": {
        borderRightStyle: "solid",
      }
    },
  }
};


const formDetailsTable = {
  "&.form-details-table": {
    ...baseRoundedTable,
    "& tr": {
      "& td:first-child": {
        borderLeftStyle: "solid",
      },
      "& td:last-child": {
        borderRightStyle: "solid",
      }
    },
    "& th": {
      ...baseRoundedTable["& th"],
      padding: "10px 0px 5px 12px",
    },
    "& tbody": {
      "& td": {
        padding: "8px 10px 8px 10px",
      },
      "& tr:first-child td": {
        padding: "8px 10px 8px 10px",
      }
    },
  }
};

const pricingTable = {
  "&.pricing-table": {
    ...baseRoundedTable,
    "& td": {
      border: "1px solid #BDBDBD",
      borderStyle: "none none solid none",
      padding: "6px 10px 6px 10px",
    },
    "& thead th": {
      padding: "10px 8px 5px 10px",
    },
    "& tbody tr td:last-child": {
      borderStyle: "none solid solid none",
    },
    "& tbody tr:first-child td:last-child": {
      borderStyle: "solid solid solid none",
    },
    "& tbody tr:first-child td.empty-td": {
      padding: "7px",
      borderStyle: "solid solid solid solid !important",
    },
    "& tbody tr:last-child": {
      "& td:first-child": {
        borderBottomLeftRadius: "0px",
      },
      "& td:last-child": {
        borderBottomRightRadius: "0px",
        borderStyle: "none solid solid none",
      },
      "& td": {
        borderBottomColor: "black",
        borderBottom: "2px solid black",
      },
    },
    "&.pricing-table-disabled tbody tr:last-child": {
      "& td": {
        borderBottomColor: colors.grey11,
        borderBottom: `2px solid ${colors.grey11}`,
      },
    },
    "& tbody tr:first-child:is(:first-child) td:last-child": {
      borderStyle: "solid solid solid none",
    },
    "& tfoot": {
      "& tr:first-child": {
        "& td": {
          borderTopColor: colors.white
        },
        "& td:first-child": {
          borderTopLeftRadius: "0px",
        },
        "& td:last-child": {
          borderTopRightRadius: "0px",
        }
      },
      "& tr:last-child": {
        "& td:not(:last-child):first-child": {
          borderBottomLeftRadius: borderRadius.medium,
          borderLeftStyle: "solid"
        },
        "& td:not(:first-child):last-child": {
          borderBottomRightRadius: borderRadius.medium,
          borderStyle: "none solid solid none",
        }
      },
      "& tr td:last-child": {
        borderRightStyle: "solid",
      },
      "& tr td div": {
        color: colors.grey08,
        fontStyle: "normal",
        fontWeight: fontWeight.bold2,
        fontSize: fontSize.large,
        lineHeight: "140%",
      },
    },
  }
};

const endorsementsTable = {
  "&.endorsements-table": {
    ...baseRoundedTable,
    "& td": {
      border: `1px solid ${colors.grey09}`,
      borderStyle: "none none solid none",
      padding: padding.zero,
      "& p.MuiTypography-root": {
        fontSize: fontSize.large,
      },
      "& button.MuiIconButton-root": {
        padding: padding.small2,
      }
    },
    "& tr:first-child": {
      "& td:first-child": {
        borderTopLeftRadius: borderRadius.medium,
      },
      "& td:last-child": {
        borderTopRightRadius: borderRadius.medium,
      }
    },
    "& tr:last-child": {
      "& td:first-child": {
        borderBottomLeftRadius: borderRadius.medium,
      },
      "& td:last-child": {
        borderBottomRightRadius: borderRadius.medium,
      }
    },
    "& tr td:last-child": {
      borderRightStyle: "solid",
    },
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue01,
    },
    secondary: {
      main: colors.maroon01,
    },
    background: {
      default: colors.grey01,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    h4: {
      fontWeight: 700,
      fontSize: "24pt",
      marginBottom: "0px",
      marginLeft: "5px",
    },
    h6: {
      fontWeight: 700,
      fontSize: "18pt",
      marginBottom: "0px",
    },
    fontSize: 12,
    fontFamily: fontFamily.primary,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: fontSize.medium,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colors.blue01,
          "&.Mui-disabled": {
            color: colors.blue09,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: colors.grey10,
            color: colors.grey08,
            "$:hover": {
              background: colors.grey10,
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.grey03,
        },
        barColorPrimary: {
          backgroundColor: `${colors.blue01}`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: colors.white,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: "48px",
          minHeight: "48px",
          "&.Mui-selected": {
            backgroundColor: colors.grey01,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.MuiCheckbox-colorPrimary": {
            color: colors.blue01,
            "&.Mui-disabled": {
              color: colors.blue09,
            },
            "&.Mui-disabled svg": {
              color: colors.blue09,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: colors.blueDisabled,
            color: colors.white,
            opacity: 0.5,
          },
          "&.MuiButton-text": {
            fontSize: fontSize.small1,
            fontWeight: fontWeight.normal2,
            "&:hover": {
              backgroundColor: colors.blueTransparent01,
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.blue01,
          textDecorationColor: colors.blue01,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.blue01,
          "&.Mui-disabled": {
            color: colors.blue04,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          ...roundedTable,
          ...pricingTable,
          ...endorsementsTable,
          ...formDetailsTable,
          ...fileUploadStatusTable,
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: colors.grey03,
            "&:hover": {
              backgroundColor: colors.grey03,
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingBottom: "15px !important",
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root[data-shrink='false']": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "calc(100% - 20px)",
          },
          "&:has(.MuiSelect-select)": {
            "& .MuiInputLabel-root[data-shrink='false']": {
              width: "calc(100% - 35px)",
            },
          },
          "&:has(.MuiAutocomplete-inputRoot):has(.MuiAutocomplete-popupIndicator)": {
            "& .MuiInputLabel-root[data-shrink='false']": {
              width: "calc(100% - 35px)",
            },
          },
          "&:has(.date-picker-inline-custom)": {
            "& .MuiInputLabel-root[data-shrink='false']": {
              width: "calc(100% - 45px)",
            },
          }
        },
      }
    }
  },
  custom: {
    common: {
      title: {
        info: {
          color: `${colors.grey13} !important`,
          fontWeight: 600,
        },
      },
      inputBase: {
        height: "26px",
        fontSize: `${control.fontSize}`,
        paddingRight: "1px",
      },
      inputBaseRoot: {
        backgroundColor: control.backgroundColor,
      },
      divInputBaseRoot: {
        backgroundColor: control.backgroundColor,
        borderRadius: `${control.borderRadius}`,
        border: `${control.borderSize} solid ${control.borderColor}`,
      },
      formLabelRoot: {
        fontSize: `${label.inner.fontSize}`,
      },
      paper: {
        borderRadius: `${control.borderRadius}`,
        backgroundColor: paper.borderColor,
        borderColor: colors.blue01,
        maxHeight: `${paper.maxHeight}`,
      },
      paperAlt: {
        borderRadius: `${control.borderRadius}`,
        backgroundColor: paper.borderColor,
        borderColor: colors.blue01,
        maxHeight: `${paper.maxHeight}`,
        borderWidth: "1px",
        borderStyle: "solid",
      },
      paperAlt2: {
        marginTop: "0px",
      },
    },
    container: {
      outer: {
        root: {
          backgroundColor: section.backgroundColor,
          borderRadius: section.borderRadius,
          boxShadow: section.boxShadow,
          padding: section.outer.padding,
        },
        title: {
          alignItems: "center",
          display: "flex",
          gap: gaps.small2,
        },
      },
      inner: {
        root: {
          backgroundColor: section.backgroundColor,
          paddingBottom: section.inner.paddingBottom,
          paddingLeft: section.inner.paddingLeft,
          paddingRight: section.inner.paddingRight,
          paddingTop: section.inner.paddingTop,
          position: "relative",
        },
      },
    },

    textField: {
      labelPlaceholder: {
        zIndex: zIndex.positiveMin,
        paddingLeft: `${label.inner.paddingLeft}`,
        color: label.inner.color,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "90%",
        height: "100%",
        pointerEvents: "none",
        paddingRight: `${label.inner.paddingRight}`,
      },
      label: {
        zIndex: zIndex.positiveMin,
        paddingLeft: `${label.inner.paddingLeft}`,
        color: label.inner.color,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        paddingRight: `${label.inner.paddingRight}`,
      },
      labelFocus: {
        color: label.outer.color,
        overflow: "unset",
      },
      labelShrink: {
        transform: `translate(0, -2px) scale(0.78)`,
        color: label.outer.color,
        paddingLeft: `${label.outer.paddingLeft}`,
      },
      // Input
      input: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        borderRadius: `${control.borderRadius}`,
        paddingLeft: `${control.paddingLeft}`,
        paddingRight: `${control.paddingRight} !important`,
        paddingTop: `${control.paddingTop}`,
        paddingBottom: `${control.paddingBottom}`,
        backgroundColor: control.backgroundColor,
        border: `${control.borderSize} solid ${control.borderColor}`,
        height: `${control.height}`,
      },
      inputDate: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        paddingLeft: "6px",
        paddingRight: `${control.paddingRight}`,
        paddingTop: `${control.paddingTop}`,
        paddingBottom: `${control.paddingBottom}`,
        backgroundColor: control.backgroundColor,
        marginLeft: "6px",
      },
      inputHover: {
        border: `${control.borderSize} solid ${control.borderHoverColor}`,
      },
      inputFocus: {
        border: `${control.borderSize} solid ${control.borderFocusColor}`,
      },
      inputDisabled: {
        color: control.disabled.color,
        backgroundColor: control.disabled.backgroundColor,
        padding: 0,
        paddingLeft: "6px",
        paddingRight: `${control.paddingRight}`,
        height: "36px",
        "&:hover": {
          border: `${control.borderSize} solid ${control.borderColor}`,
        },
      },
      inputOutlined: {
        backgroundColor: control.backgroundColor,
        borderRadius: control.borderRadius,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      inputOutlinedDisabled: {
        color: control.disabled.color,
        backgroundColor: control.disabled.backgroundColor,
        borderRadius: control.borderRadius,
      },
      // AutoComplete
      autoComplete: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        borderRadius: `${control.borderRadius}`,
        paddingLeft: `${control.paddingLeft}`,
        paddingRight: `${control.paddingRight}`,
        paddingTop: `0px`,
        paddingBottom: `0px`,
        backgroundColor: control.backgroundColor,
        border: `${control.borderSize} solid ${control.borderColor}`,
      },
      autoCompleteHover: {
        border: `${control.borderSize} solid ${control.borderHoverColor}`,
      },
      autoCompleteFocus: {
        border: `${control.borderSize} solid ${control.borderHoverColor}`,
      },

      // DropDownList (Select)
      select: {
        overflow: "hidden",
        borderRadius: `${control.borderRadius}`,
        paddingLeft: `${control.paddingLeft}`,
        paddingRight: `${control.paddingRight}`,
        paddingTop: `${control.paddingTop}`,
        paddingBottom: `${control.paddingBottom}`,
        backgroundColor: control.backgroundColor,
        border: `${control.borderSize} solid ${control.borderColor}`,
        height: "26px",
        fontSize: `${control.fontSize}`,
        lineHeight: "26px",
        // transition: defaultTheme.transitions.create(["border-color", "box-shadow"])
      },
      selectHover: {
        border: `${control.borderSize} solid ${control.borderHoverColor}`,
      },
      selectFocus: {
        borderColor: `${control.borderFocusColor}`,
      },
      selectDisabled: {
        color: control.disabled.color,
        backgroundColor: control.disabled.backgroundColor,
        border: "none",
      },
      selectError: {
        border: `${control.borderSize} solid ${control.errorColor}`,
      },
      colorError: {
        color: control.errorColor,
      },
      arrowError: {
        fill: control.errorColor,
      },
    },
    textInputZip: {
      width: "100%",
    },
  },
});

export default theme;
