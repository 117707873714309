import React, {
  ReactNode,
} from "react";
import { SignatureLevelType } from "utils/data/enum";

type Props = {
  children?: ReactNode;
};

export type Signature = {
  signatureID: number;
  name: string;
  file: string;
  fontFamilyID: string;
  imageType: string;
  signatureLevelTypeCode?: string;
  isDefaultedByOther: number;
};

export type SignatureMode = "ADD" | "EDIT" | undefined;
type State = {
  open: boolean;
  imageSource: string;
  signature: Signature;
  mode: SignatureMode;
  isNameValid: boolean;
  isCheckingName: boolean;
};

type ActionTypes =
  | "OPEN"
  | "CLOSE"
  | "SELECT_OPTION"
  | "NAME_UPDATE"
  | "LEVEL_UPDATE"
  | "FILE_UPDATE"
  | "SIG_VALIDATE";

type Action = {
  type: ActionTypes;
  mode?: SignatureMode;
  imageSource?: string;
  signatureID?: number;
  signatureName?: string;
  signatureFile?: string;
  fontFamilyID?: string;
  imageType?: string;
  isNameValid?: boolean;
  isCheckingName?: boolean;
  signatureLevelTypeCode?: string;
  isDefaultedByOther?: number;
};

type Context = {
  state: State;
};

type DispatchContext = {
  dispatch: React.Dispatch<Action>;
};

const defaultSignatureLevelTypeCode = SignatureLevelType.AgencyWide;

const SignatureDispatchContext = React.createContext<DispatchContext | undefined>(undefined);
const SignatureContext = React.createContext<Context | undefined>(undefined);
SignatureContext.displayName = "SignatureContext";
SignatureDispatchContext.displayName = "SignatureDispatchContext";

const NEW_SIGNATURE = {
  signatureID: 0,
  file: "",
  name: "",
  fontFamilyID: "",
  imageType: "",
  signatureLevelTypeCode: defaultSignatureLevelTypeCode,
  isDefaultedByOther: 0,
};

function signatureReducer(state: State, action: Action): State {
  switch (action.type) {
    case "OPEN":
      return {
        open: true,
        signature: {
          signatureID: action.signatureID || 0,
          name: action.signatureName || "",
          file: action.signatureFile || "",
          fontFamilyID: action.fontFamilyID || "",
          imageType: action.imageType || "",
          signatureLevelTypeCode: action.signatureLevelTypeCode || SignatureLevelType.AgencyWide,
          isDefaultedByOther: action.isDefaultedByOther || 0,
        },
        mode: action.mode,
        isNameValid: true,
        isCheckingName: false,
        imageSource: action.imageSource ?? "",
      };
    case "CLOSE":
      return {
        open: false,
        signature: NEW_SIGNATURE,
        mode: undefined,
        isNameValid: true,
        isCheckingName: false,
        imageSource: action.imageSource ?? "",
      };
    case "SELECT_OPTION": {
      if (action.imageSource === state.imageSource || !action.imageSource) return state;
      return {
        ...state,
        imageSource: action.imageSource ?? "",
        signature: {
          ...state.signature,
          file: "",
        },
      };
    }
    case "NAME_UPDATE":
      return {
        ...state,
        signature: { ...state.signature, name: action.signatureName || "" },
        isNameValid: action.isNameValid ?? true,
        isCheckingName: action.isCheckingName ?? false,
      };
    case "LEVEL_UPDATE":
        return {
          ...state,
          signature: { ...state.signature, signatureLevelTypeCode: action.signatureLevelTypeCode || "" },
        };
    case "SIG_VALIDATE":
      return {
        ...state,
        isNameValid: action.isNameValid ?? true,
        isCheckingName: action.isCheckingName ?? false,
      };
    case "FILE_UPDATE":
      return {
        ...state,
        signature: {
          ...state.signature,
          file: action.signatureFile || "",
          fontFamilyID: action.fontFamilyID || "",
          imageType: action.imageType || "",
        },
      };
    default:
      throw new Error(`Not supported action type: ${action.type}`);
  }
}

function SignatureProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(signatureReducer, {
    open: false,
    mode: undefined,
    isNameValid: true,
    isCheckingName: false,
    signature: NEW_SIGNATURE,
    imageSource: "",
  });

  const safeDispatch = React.useMemo(() => ({ dispatch }), []);
  return (
    <SignatureDispatchContext.Provider value={safeDispatch}>
      <SignatureContext.Provider value={{ state }}>
        {children}
      </SignatureContext.Provider>
    </SignatureDispatchContext.Provider>
  );
}

function useSignatureProvider() {
  const context = React.useContext(SignatureContext);
  if (!context) {
    throw new Error(
      "useSignatureProvider must be used within a <SignatureProvider>"
    );
  }

  return context;
}

function useSignatureDispatchProvider() {
  const context = React.useContext(SignatureDispatchContext);
  if (!context) {
    throw new Error(
      "useSignatureDispatchProvider must be used within a <SignatureProvider>"
    );
  }

  return context;
}

export {
  SignatureProvider,
  useSignatureProvider,
  useSignatureDispatchProvider,
};
