import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import StewartDatePicker from "controls/global/stewart-datepicker";
import React from "react";
import DatePickerIcon from "theme/icons/DatePickerIcon";
import { formatDate } from "utils/shared";

type Props = {
  defaultValue?: string; //ADD SUPPORT FOR REVISION
  onChange?: (value: string) => void;
  fullWidth?: boolean;
  size?: "small" | "medium";
  className?: string;
};

export default function DateField({ defaultValue, onChange, size = "medium", fullWidth = true, className }: Props) {
  const [selectedDate, setDate] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!defaultValue) return;
    setDate(new Date(defaultValue));
  }, []);

  const handleChange = (
    date: any,
    value?: string | null | undefined
  ) => {
    setDate(date);
    setInputValue(value ?? "");
    onChange?.(date ? formatDate(date || new Date()) : "");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StewartDatePicker
        inputFormat="MM/dd/yyyy"
        value={selectedDate}
        InputProps={{
          disableUnderline: true,
        }}
        components={{
          OpenPickerIcon: DatePickerIcon
        }}
        className={className}
        onChange={handleChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth={fullWidth}
            placeholder="mm/dd/yyyy"
            size={size}
            helperText={null}
            value={inputValue}
          />
        )}
      />
    </LocalizationProvider>
  );
}
