import React from "react";
import { useFiles } from "utils/context/FilesContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import usePricing from "utils/custom-hooks/usePricing";
import { usePricingErrorMessageContext } from "../../PricingErrorMessageProvider";
import { usePricingStates } from "utils/context/PricingContext";

function PricingErrorHandler() {
  const { isFileLocked } = useCreateFile();
  const { isFileLockedWithUpdateablePricingItem } = usePricing();
  const [{ isPricingCalculated },] = usePricingStates();
  const [{ initialValues, calculatedPricingProducts }] = useFiles();
  const { dispatch: pricingErrorMessageDispatch } = usePricingErrorMessageContext();

  React.useEffect(() => {
    const pricingProducts = calculatedPricingProducts ? calculatedPricingProducts : initialValues.pricingProducts;
    // Deep clone the pricingProducts to avoid losing data after filtering 
    const clonePP = JSON.parse(JSON.stringify(pricingProducts));
    if (isFileLocked) {
      clonePP.forEach((product: any) => {
        if (product.productItems) {
          product.productItems = product.productItems.filter((item: any) => isFileLockedWithUpdateablePricingItem(item, isFileLocked));
        }
      });
    }

    pricingErrorMessageDispatch({
      type: "INITIALIZE",
      products: clonePP
    });
    return () => {
      pricingErrorMessageDispatch({ type: "RESET" });
    };
  }, [
    initialValues,
    calculatedPricingProducts,
    pricingErrorMessageDispatch,
    isFileLocked,
    isPricingCalculated,
  ]);

  return <></>;
}

export default PricingErrorHandler;