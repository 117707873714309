import { styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import React from "react";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
  padding,
} from "theme/defaultStyle";

const StyledTableFooterCell = styled(TableCell)({
  borderBottom: "none",
  borderTop: `${borderSize.xsmall} solid ${colors.black}`,
  color: colors.grey08,
  fontSize: fontSize.xlarge1,
  fontWeight: fontWeight.bold1,
  maxWidth: "170px",
  overflow: "hidden",
  paddingRight: padding.large1,
  paddingTop: padding.small2,
  textOverFlow: "ellipses",
});

type Props = {
  hasIssuedProducts: boolean;
  newTotalTotalDue: string;
};

const TotalDueNewCell = styled(TooltipCell)({
  borderBottom: `${borderSize.xlarge} solid ${colors.maroon01} !important`,
  display: "inline-block",
  fontSize: fontSize.large,
  fontStyle: "normal",
  fontWeight: fontWeight.bold2,
  lineHeight: "140%",
  maxWidth: "100px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export default function TotalDueNewTotal({ hasIssuedProducts, newTotalTotalDue }: Props) {

  return (
    <StyledTableFooterCell align="right">
      {hasIssuedProducts &&
        <TotalDueNewCell title={newTotalTotalDue} />
      }
    </StyledTableFooterCell>
  );
}
