import React from "react";
export default function CheckLightIcon() {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82923 9.22926L1.35423 5.75426L0.170898 6.92926L4.82923 11.5876L14.8292 1.5876L13.6542 0.412598L4.82923 9.22926Z"
        fill="white"
      />
    </svg>
  );
}
