import { styled } from "@mui/material";
import TextInput from "controls/global/text-input-field/TextInput";
import React from "react";
import theme from "theme/default";
import {
  gaps,
  margin,
  padding,
} from "theme/defaultStyle";

type ProductDetailsProps = {
  issueDateName: string;
  issueDate: string;
  serialNumberName: string;
  serialNumber?: string;
  statusName: string;
  status?: string;
};

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: gaps.large1,
  margin: margin.zero,
  padding: padding.zero,
  position: "relative",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
});

/**
 * Display product status information only.
 * All fields should be read only.
 * @issueDateName     only used to set the id for TextInputField component, not the object's proeprty name
 * @issueDate         the formatted product issue date
 * @serialNumberName  only used to set the id for TextInputField component, not the object's proeprty name
 * @serialNumber      product serial number
 * @statusName        only used to set the id for TextInputField component, not the object's proeprty name
 * @status            product order status type name
 * @returns
 */

const ProductStatus = (props: ProductDetailsProps) => {
  return (
    <Container>
      <TextInput
        name={props.issueDateName}
        label="Issue Date"
        disabled
        value={props.issueDate}
      />
      <TextInput
        name={props.serialNumberName}
        label="Serial Number"
        disabled
        value={props.serialNumber}
      />
      <TextInput
        name={props.statusName}
        label="Status"
        disabled
        value={props.status}
      />
    </Container>
  );
};

export default ProductStatus;
