import { StandaloneEndorsement } from "entities/UIModel";
import { v4 as uuidv4 } from "uuid";
import { OrderStatusType } from "./enum";

export function getDefaultStandaloneEndorsement(): StandaloneEndorsement {
  return {
    isLocationDefault: true,
    effectiveDate: undefined,
    endorsements: [],
    id: 0,
    identityKey: 0,
    integrationKey: uuidv4(),
    isDirty: true,
    fileID: 0,
    orderID: 0,
    originalJacketDate: null,
    originalJacketLiability: null,
    originalJacketNumber: "",
    originalJacketType: null,    
    orderStatusTypeCode: OrderStatusType.Pending,
    displayOrder: 0,
    isDefault: true,
    isValidationRequired:false,
    pricingDetail: {
      filePricingDetailID: 0,
      fileID: 0,
      filePricingID: 0,
      orderID: 0,
      liability: 0,
      integrationKey: uuidv4(),
      locationDisplayName: "",
    },
  };
}
