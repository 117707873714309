import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material";
import ActionButton from "controls/global/action-button";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React, {
  useRef,
} from "react";
import {
  gaps,
  margin,
} from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import SignatureDefaultForm from "../default-form/SignatureDefaultForm";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: gaps.small1,
  justifyContent: 'center',
  marginTop: margin.small,
});

const SignatureDefaultDialog = () => {
  const {
    mode,
    isOpen,
    saveEnabled,
    cancelDialog,
  } = useSignatureDefaultDialogProvider();
  const [, { resetState }] = useSignatureDefaults();
  const saveRef = useRef<HTMLButtonElement>(null);

  const handleCancel = () => {
    cancelDialog();
    resetState();
  };

  const handleSave = () => {
    saveRef && saveRef.current?.click();
  };

  return (
    <StewartDialog
      open={isOpen}
      height={700}
    >
      <StewartDialogTitle type={"INFO"}>
        {mode === "EDIT" ? "Edit Default Signature" : "Set Default Signature"}
        <DialogTitleCloseButton onClick={handleCancel}/>
      </StewartDialogTitle>
      <StewartDialogContent>
        <SignatureDefaultForm saveRef={saveRef} />
      </StewartDialogContent>
      <StewartDialogActions>
        <ButtonsWrapper>
          <ActionButton
            variant="outlined"
            color="primary"
            disabled={!saveEnabled}
            onClick={handleSave}
            endIcon={<SaveIcon />}
          >
            Save default
          </ActionButton>
        </ButtonsWrapper>
      </StewartDialogActions>
    </StewartDialog>
  );
};

export default SignatureDefaultDialog;
