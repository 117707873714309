const VALID_TYPES = ["image/jpeg", "image/jpg", "image/png"];
const IMG_MAX_WIDTH = 300;
const IMG_MAX_HEIGHT = 110;

const isFileTypeValid = (fileType: string) => {
  return VALID_TYPES.indexOf(fileType) >= 0;
};

const isFileSizeValid = (fileSize: number) => {
  const k = 1024;
  const sizeMiB = fileSize / k / k;
  return sizeMiB <= 1;
};

export const getImageTypeFromImageDataUrl = (imageData: string) => {
  if (!imageData) return "JPG";

  return imageData
    .split(",")[0]
    .split(";")[0]
    .split(":")[1]
    .split("/")[1]
    .toUpperCase();
};

export { isFileSizeValid, isFileTypeValid, IMG_MAX_HEIGHT, IMG_MAX_WIDTH };
