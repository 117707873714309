import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  StewartDialog,
  StewartDialogActions,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import {
  Box,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  borderRadius,
  borderSize,
  colors,
  fontSize,
  fontWeight,
  gradients,
  iconSize,
  margin,
  padding,
  scrollBar,
} from "theme/defaultStyle";
import ActionButton from "controls/global/action-button";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  FileRejection,
  FileWithPath
} from "react-dropzone";
import UploadDropZone from "controls/global/upload-files/UploadDropZone";
import {
  getFormattedErrorMessage,
  mapFileUploaResponse,
} from "pages/file/utils/fileUploadHelper";
import ConfirmationDialogWithProgressbar from "controls/global/dialogs/confirmation-dialog-with-progressbar";
import { useProcessStatusTracking } from "utils/context/ProcessStatusContext";
import { v4 as uuidv4 } from "uuid";
import {
  FileUploadAdditionalInfo,
  FileUploadStatus
} from "entities/UIModel";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { useDocument } from "utils/context/DocumentContext";
import { ProgressImageType } from "utils/data/enum";
import UploadStatusGrid from "controls/global/upload-files/UploadStatusGrid";
import ConfirmationDialogV3 from "controls/global/dialogs/confirmation-dialog/ConfirmationDialogV3";

const StyledDialog = styled(StewartDialog)({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    maxHeight: "calc(95vh)",
    maxWidth: "calc(40vw)",
    minWidth: "calc(40vw)",
    display: "flex",
    justifyContent: "flex-start",
    padding: "36px 40px 0px 40px",
    overflowX: "auto",
  },
});

const StyledDialogTitle = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "24px",
  "& .MuiTypography-root": {
    fontSize: fontSize.xlarge2,
    fontWeight: fontWeight.bold2,
  }

})

const scrollBarstyle = {
  maxHeight: "250px",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    width: "14px",
    height: scrollBar.height,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: scrollBar.borderRadius1,
    backgroundColor: colors.grey20,
    border: `${borderSize.medium} solid transparent`,
    backgroundClip: "content-box",
  },
  "&::-webkit-scrollbar-track": {
    background: colors.white,
    boxShadow: "1px 0px 0px 0px #DEDEDE inset",
    borderRadius: "0px 4px 4px 0px",
    border: "1px solid var(--Input-Default-Stoke, #BDBDBD)",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

const IconContainer = styled("div")({
  background: gradients.blueGradient09,
  borderRadius: borderRadius.large,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "56px",
  height: "56px",
  "& .MuiSvgIcon-root": {
    color: colors.white,
    fontSize: iconSize.medium2,
  },
});

const StyledDialogContent = styled(Box)({
  marginBottom: margin.xlarge1,
  "& .failed-upload-status": {
    marginTop: "16px",
    marginBottom: "16px",
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .success-upload-status": {
    marginTop: margin.large,
    marginBottom: margin.large,
    fontWeight: fontWeight.bold1,
    fontSize: fontSize.large,
  },
  "& .failed-status-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-only-grid": {
    ...scrollBarstyle
  },
  "& .failed-status-grid table, .failed-status-only-grid table": {
    "& td": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  "& .success-status-grid": {
    ...scrollBarstyle
  },
  "& .success-status-only-grid": {
    ...scrollBarstyle
  },
  "& .success-status-grid table, .success-status-only-grid table": {
    "& td": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },

});

const StyledDialogAction = styled(StewartDialogActions)({
  justifyContent: "right",
  paddingBottom: padding.xlarge22,
  paddingTop: padding.zero,
  paddingRight: padding.zero,
  paddingLeft: padding.zero,
});

const CloseIconContainer = styled("div")({
  background: colors.grey10,
  borderRadius: borderRadius.xlarge1,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  marginLeft: "auto",
  marginBottom: "auto",
  width: "32px",
  height: "32px",
  padding: "4px",
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    width: "24px",
    height: "24px",
  },
  "& .iconButton": {
    padding: padding.zero,
    backgroundColor: colors.grey10,
  },
});



type Props = {
  isOpen: boolean;
  onClose: () => void;
}

const PolicyUploadDialog = ({ isOpen, onClose }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { getValues } = useFormWrapper();
  const [, { uploadFiles, deleteDocument }] = useDocument();
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);
  const [hasSuccessUpload, setHasSuccessUpload] = useState<boolean>(false);
  const [hasFailedUpload, setHasFailedUpload] = useState<boolean>(false);

  const isProgressCompletedRef = useRef<boolean>(false);
  const successUploadFilesRef = useRef<FileUploadStatus[]>([]);
  const failedUploadFilesRef = useRef<FileUploadStatus[]>([]);
  
  // show delete confirmation
  const DEFAULT_MESSAGE = "Delete";
  const [isConfirmatinDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const tobeDeletedDocumentId = useRef<number | undefined>(undefined);

  // control show progress 
  const [, { setBypassDefaultLoader, setPercentageComplete }] = useProcessStatusTracking();
  const [openProgressDialog, setOpenProgressDialog] = React.useState<boolean>(false);
  const [runProgress, setRunProgress] = React.useState<boolean>(false);
  const [progressRequestId, setProgressRequestId] = React.useState<string>("");
  const [disable, setDisable] = React.useState<boolean>(true);


  // reference variables
  const internalFileId = useRef<number>(0);

  const handleOnCloseProgressDialog = async () => {
    // console.log("call handleOnCloseProgressDialog  - uploadCompleted:", uploadCompleted);
    isProgressCompletedRef.current = true;
    if (uploadCompleted) {
      setOpenProgressDialog(false);
      setRunProgress(false);
      setProgressRequestId("");
    }
  }

  const handleClickOnDone = () => {
    setDisable(true);
    onClose && onClose();
  }

  const handleOnUpload = async (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
    // -- Show progress dialog
    setUploadCompleted(false);
    const progressRequestId = uuidv4();
    setProgressRequestId(progressRequestId);
    setBypassDefaultLoader(true);
    setOpenProgressDialog(true);
    setRunProgress(true);

    isProgressCompletedRef.current = false;

    // -- Prepare for upload
    let fileAdditionalInfos: FileUploadAdditionalInfo[] = [];
    const formData = new FormData();
    const fileId = getValues("id") || "";
    const clientFileID = getValues("fileNameNumber") || "";
    const agency = getValues("agency");
    const companyID = agency?.id || "";
    const companyLegacyID = agency?.legacyID || "";

    formData.set("fileID", fileId);
    formData.set("clientFileID", clientFileID);
    formData.set("companyID", companyID);
    formData.set("companyLegacyID", companyLegacyID);

    let index = 0;
    acceptedFiles?.forEach((file) => {
      formData.append("files", file, file.name);
      fileAdditionalInfos.push({
        index: index,
        filePath: file.path || "",
        fileName: file.name,
        fileSize: file.size,
        errorMessage: "",
        isValidFile: true,
      })
      index++;
    })

    rejectedFiles?.forEach((rejectedFile, i) => {
      fileAdditionalInfos.push({
        index: index,
        filePath: "",  // not available
        fileName: rejectedFile.file.name,
        fileSize: rejectedFile.file.size,
        errorMessage: getFormattedErrorMessage(rejectedFile.errors),
        isValidFile: false,
      })
      index++;
    })

    formData.append("fileUploadAdditionalInfo", JSON.stringify(fileAdditionalInfos));

    // -- Start upload files 
    const data = await uploadFiles(progressRequestId, formData);

    const fileUploadStatuses = mapFileUploaResponse(data);
    const disabledone = fileUploadStatuses?.length > 0
    // -- show upload result status
    const hasSuccess = fileUploadStatuses?.some((f) => f.isUploadSuccess);
    const hasFailed = fileUploadStatuses?.some((f) => !f.isUploadSuccess);
    setHasSuccessUpload(hasSuccess);
    setHasFailedUpload(hasFailed);
    // setUploadedFiles(fileUploadStatuses?.filter((f) => f.isUploadSuccess));
    // setFailedFiles(fileUploadStatuses?.filter((f) => !f.isUploadSuccess));
    successUploadFilesRef.current = fileUploadStatuses?.filter((f) => f.isUploadSuccess);
    failedUploadFilesRef.current = fileUploadStatuses?.filter((f) => !f.isUploadSuccess);
    setDisable(!disabledone);

    setPercentageComplete(progressRequestId);
    setUploadCompleted(true);
  }

  const handleOnDelete = async (documentId: number) => {
    tobeDeletedDocumentId.current = documentId;
    const tobeDeletedFile = successUploadFilesRef.current?.find((f) => f.documentId === documentId);
    setConfirmationMessage(`${DEFAULT_MESSAGE} '${tobeDeletedFile?.documentOriginalFileName}'?`);
    setConfirmationDialogOpen(true);
  }

  const deleteUploadedImage = async () => {
    if (tobeDeletedDocumentId.current) {
      await deleteDocument(tobeDeletedDocumentId.current);
      // remove from the upload status list
      successUploadFilesRef.current = successUploadFilesRef.current?.filter((f) => f.documentId !== tobeDeletedDocumentId.current)
      setHasSuccessUpload(successUploadFilesRef.current?.length > 0);
    }
    setConfirmationDialogOpen(false);
    const disableDone = !(hasSuccessUpload || hasFailedUpload);
    setDisable(disableDone);
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    tobeDeletedDocumentId.current = undefined;
  }

  function handleOnClose() {
    setDisable(true);
    onClose && onClose();
  }

  useEffect(() => {
    setOpen(isOpen);
    setHasSuccessUpload(false);
    setHasFailedUpload(false);
    successUploadFilesRef.current = [];
    failedUploadFilesRef.current = [];
    isProgressCompletedRef.current = false;
    setDisable(true);
    if (internalFileId.current) {
      internalFileId.current = getValues("id");
    }
  }, [isOpen])

  useEffect(() => {
    // console.log("useEffect - uploadCompleted:", uploadCompleted, "isProgressCompletedRef:", isProgressCompletedRef.current, "openProgressDialog: ", openProgressDialog);
    //if (uploadCompleted && isProgressCompletedRef.current && openProgressDialog) {
    if (uploadCompleted && openProgressDialog) {
      handleOnCloseProgressDialog();
    }
  },[uploadCompleted])

  return (
    <>
      <StyledDialog
        open={open}
      >
        <StyledDialogTitle id="styledDialogTitleDebug">
          <IconContainer>
            <NoteAddIcon></NoteAddIcon>
          </IconContainer>
          <Typography id="uploadPolicyImageTitleDebug">Upload Policy Images</Typography>
          <CloseIconContainer>
            <IconButton className="iconButton" onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </CloseIconContainer>
        </StyledDialogTitle>
        <StyledDialogContent>
          <UploadDropZone handleOnUpload={handleOnUpload} />
          {hasFailedUpload && (
            <>
              <div className="failed-upload-status">
                Failed to Upload ({failedUploadFilesRef.current?.length})
              </div>
              <UploadStatusGrid
                className={hasSuccessUpload ? "failed-status-grid" : "failed-status-only-grid"}
                fileUploadStatuses={failedUploadFilesRef.current}
              />
            </>
          )}
          {hasSuccessUpload && (
            <>
              <div className="success-upload-status">
                Uploaded ({successUploadFilesRef.current?.length})
              </div>
              <UploadStatusGrid
                className={hasFailedUpload ? "success-status-grid" : "success-status-only-grid"}
                fileUploadStatuses={successUploadFilesRef.current}
                handleOnDelete={handleOnDelete}
                hideMessage={true}
              />
            </>
          )}
        </StyledDialogContent>

        <StyledDialogAction className="DialogAction">
          <ActionButton
            variant="outlined"
            color="primary"
            onClick={handleClickOnDone}
            disabled={disable}
          >
            Done
          </ActionButton>
        </StyledDialogAction>
      </StyledDialog>
      <ConfirmationDialogWithProgressbar
        autoClose={true}
        title="Please wait while uploading your images ..."
        closeButtonText="Done! Click to Continue"
        isOpen={openProgressDialog}
        onClose={handleOnCloseProgressDialog}
        requestId={progressRequestId}
        runProgress={runProgress}
        progressImageType={ProgressImageType.circular}
      />
      <ConfirmationDialogV3
        isOpen={isConfirmatinDialogOpen}
        confirmationMessage={confirmationMessage}
        cancelActionButton={true}
        onYes={() => deleteUploadedImage()}
        onNo={() => closeConfirmationDialog()}
        onCancel={() => closeConfirmationDialog()}
        singleActionButtonText={"Delete"}
        buttonNoText={"Cancel"}
      />
    </>
  )
}

export default PolicyUploadDialog;