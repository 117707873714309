import { ReportPayFile } from "entities/UIModel/ReportPayFile";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import { convertToNumber } from "utils/shared";

function getReportPayByCriteria(
  reportPayFile: ReportPayFile,
  filters: ReportAndPayCriteriaSearch
) {
  let isMatch = true;

  if (filters.clientFileId)
    isMatch =
      isMatch &&
      reportPayFile.clientFileId
        .toLowerCase()
        .includes(filters.clientFileId.toLowerCase());

  if (filters.propertyAddress)
    isMatch =
      isMatch &&
      reportPayFile.propertyAddresses
        .toLowerCase()
        .includes(filters.propertyAddress.toLowerCase());

  if (filters.locationLegacyID)
    isMatch =
      isMatch &&
      reportPayFile.locationLegacyIDs
        .toLowerCase()
        .includes(filters.locationLegacyID.toLowerCase());

  if (filters.buyerName)
    isMatch =
      isMatch &&
      reportPayFile.buyerNames
        .toLowerCase()
        .includes(filters.buyerName.toLowerCase());

  if (filters.agencyID)
    isMatch =
      isMatch &&
      reportPayFile.agencyID
        .toLowerCase()
        .includes(filters.agencyID.toLowerCase());

  if (filters.locationDisplayName)
    isMatch =
      isMatch &&
      reportPayFile.locationDisplayNames
        .toLowerCase()
        .includes(filters.locationDisplayName.toLowerCase());

  if (filters.agencyName)
    isMatch =
      isMatch &&
      reportPayFile.agencyName
        .toLowerCase()
        .includes(filters.agencyName.toLowerCase());

  if (filters.totalActualRetention)
    isMatch =
      isMatch &&
      reportPayFile.totalActualRetention?.toString()
        .toLowerCase()
        .includes(filters.totalActualRetention.toString().toLowerCase());

  if (filters.sellerName)
    isMatch =
      isMatch &&
      reportPayFile.sellerNames
        .toLowerCase()
        .includes(filters.sellerName.toLowerCase());

  if (filters.lenderName)
    isMatch =
      isMatch &&
      reportPayFile.lenderNames
        .toLowerCase()
        .includes(filters.lenderName.toLowerCase());

  if (filters.fileStatusTypeCode) {
    isMatch =
      isMatch &&
      filters.fileStatusTypeCode.toLowerCase()
        .split("|")
        .includes(reportPayFile.fileStatusTypeCode.toLowerCase());
  }

  if (filters.productName)
    isMatch =
      isMatch &&
      reportPayFile.productNames
        .toLowerCase()
        .includes(filters.productName.toLowerCase());

  if (filters.firstJacketIssueDate)
    isMatch =
      isMatch &&
      reportPayFile.firstJacketIssueDate?.toDateString() ===
        filters.firstJacketIssueDate.toDateString();

  if (filters.firstJacketEffectiveDate)
    isMatch =
      isMatch &&
      reportPayFile.firstJacketEffectiveDate?.toDateString() ===
        filters.firstJacketEffectiveDate.toDateString();

  if (filters.stateAbbr)
    isMatch =
      isMatch &&
      reportPayFile.stateAbbr
        .toLowerCase()
        .includes(filters.stateAbbr.toLowerCase());

  if (filters.propertyTypeCode)
    isMatch =
      isMatch &&
      reportPayFile.propertyTypeCodes
        .toLowerCase()
        .includes(filters.propertyTypeCode.toLowerCase());

  if (filters.transactionTypeCode)
    isMatch =
      isMatch &&
      reportPayFile.transactionTypeCode
        .toLowerCase()
        .includes(filters.transactionTypeCode.toLowerCase());

  if (filters.totalActualRiskRate)
    isMatch =
      isMatch &&
      !!reportPayFile.totalActualRiskRate?.toString()
        .toLowerCase()
        .includes(filters.totalActualRiskRate?.toLowerCase());

  if (filters.totalActualFee)
    isMatch =
      isMatch &&
      convertToNumber(reportPayFile.totalActualFee) ===
        convertToNumber(filters.totalActualFee);

  if (filters.totalAmountDue)
    isMatch =
      isMatch &&
      convertToNumber(reportPayFile.totalAmountDue) ===
        convertToNumber(filters.totalAmountDue);

  return isMatch;
}

export { getReportPayByCriteria };
