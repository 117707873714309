import { useMemo } from "react";
import useFormWrapper from "./useFormWrapper";
import { useFiles } from "utils/context/FilesContext";

let disabledStaticFieldsList: string[] = [
  "agencyLocation",
  "transactionTypeCode",
  "fileNameNumber",
  "statusTypeCode",
  "ClearButton",
  "AddPropertyButton",
  "AddBuyerBorrowerButton",
  "BuyerBorrowerClearButton",
  "SellerClearButton",
  "LenderClearButton",
  "AddSellerButton",
  "AddLenderButton",
  "AddAdditionalPartyButton",
  "AddAALButton",
  "AddCPLButton",
  "AddJACKETButton",
  "AddStandaloneEndorsmentButton",
  "CPLReviseButton",
  "CPLVoidButton",
  "LetterAddresseeResetButton",
  "LetterBuyerResetButton",
  "LetterSellerResetButton",
  "JACKETInsuredNameResetButton",
  "JACKETReviseButton",
  "JACKETVoidButton",
  "pricing.isSimultaneousRate",
  // "pricing.isOverride",
  // "pricing.overrideReason",
  // "pricing.rateEffectiveDate",
  "pricing.isCoInsurance",
  "AALDeleteButton",
  "JACKETDeleteButton",
  "StandaloneEndorsmentDeleteButton",
  "PricingTableContainer",
];

const getIndexFromFiled = (fieldName: string) =>
  avoidNull(fieldName.match(/\d+/g));

const avoidNull = (index: RegExpMatchArray | null) => {
  if (index !== null) return index;
  return [];
};

const getDinamicAndStaticList = (fields: string[], index: string[]) => {
  return [
    ...fields,
    `properties.${index[0]}.county`,
    `properties.${index[0]}.state`,
    `properties.${index[0]}.addressOne`,
    `properties.${index[0]}.addressTwo`,
    `properties.${index[0]}.propertyType`,
    `properties.${index[0]}.legalDescription`,
    `properties.${index[0]}.zipCode`,
    `properties.${index[0]}.city`,
    `properties.${index[0]}.taxCode`,
    `PropertyDeleteOption${index[0]}`,

    `buyerBorrowerParties.${index[0]}.partyTypeCode`,
    `buyerBorrowerParties.${index[0]}.companyName`,
    `buyerBorrowerParties.${index[0]}.partyFirstName`,
    `buyerBorrowerParties.${index[0]}.partyMiddleName`,
    `buyerBorrowerParties.${index[0]}.partyLastName`,
    `buyerBorrowerParties.${index[0]}.suffixType`,
    `buyerBorrowerParties.${index[0]}.addressOne`,
    `buyerBorrowerParties.${index[0]}.addressTwo`,
    `buyerBorrowerParties.${index[0]}.city`,
    `buyerBorrowerParties.${index[0]}.state`,
    `buyerBorrowerParties.${index[0]}.zipCode`,
    `buyerBorrowerParties.${index[0]}.isExcludedOnCPL`,
    `buyerBorrowerParties.${index[0]}.isExcludedOnAAL`,
    `buyerBorrowerParties.${index[0]}.usePropertyAddress`,
    `buyerBorrowerParties.${index[0]}.isForeignAddress`,
    `buyerBorrowerParties.${index[0]}.foreignAddressOne`,
    `buyerBorrowerParties.${index[0]}.foreignAddressTwo`,
    `buyerBorrowerParties.${index[0]}.foreignCity`,
    `buyerBorrowerParties.${index[0]}.foreignState`,
    `buyerBorrowerParties.${index[0]}.foreignCountry`,

    `sellerParties.${index[0]}.partyTypeCode`,
    `sellerParties.${index[0]}.companyName`,
    `sellerParties.${index[0]}.partyFirstName`,
    `sellerParties.${index[0]}.partyMiddleName`,
    `sellerParties.${index[0]}.partyLastName`,
    `sellerParties.${index[0]}.suffixType`,
    `sellerParties.${index[0]}.usePropertyAddress`,
    `sellerParties.${index[0]}.addressOne`,
    `sellerParties.${index[0]}.addressTwo`,
    `sellerParties.${index[0]}.city`,
    `sellerParties.${index[0]}.state`,
    `sellerParties.${index[0]}.zipCode`,
    `sellerParties.${index[0]}.isForeignAddress`,
    `sellerParties.${index[0]}.foreignState`,
    `sellerParties.${index[0]}.foreignCountry`,

    `lenderParties.${index[0]}.companyName`,
    `lenderParties.${index[0]}.individualAttorney`,
    `lenderParties.${index[0]}.saveToContacts`,
    `lenderParties.${index[0]}.addressOne`,
    `lenderParties.${index[0]}.addressTwo`,
    `lenderParties.${index[0]}.city`,
    `lenderParties.${index[0]}.state`,
    `lenderParties.${index[0]}.zipCode`,
    `lenderParties.${index[0]}.loanNumber`,
    `lenderParties.${index[0]}.successorLanguage`,
    `lenderParties.${index[0]}.attentionTo`,
    `lenderParties.${index[0]}.addresseeNames`,

    `additionalParties.${index[0]}.partyTypeCode`,
    `additionalParties.${index[0]}.companyName`,
    `additionalParties.${index[0]}.individualAttorney`,
    `additionalParties.${index[0]}.addressOne`,
    `additionalParties.${index[0]}.addressTwo`,
    `additionalParties.${index[0]}.city`,
    `additionalParties.${index[0]}.zipCode`,
    `additionalParties.${index[0]}.state`,

    `aALProducts.${index[0]}.coveredParty`,
    `aALProducts.${index[0]}.form`,
    `aALProducts.${index[0]}.addresseeNames`,
    `aALProducts.${index[0]}.addressOne`,
    `aALProducts.${index[0]}.addressTwo`,
    `aALProducts.${index[0]}.city`,
    `aALProducts.${index[0]}.state`,
    `aALProducts.${index[0]}.zipCode`,
    `aALProducts.${index[0]}.loanNumber`,
    `aALProducts.${index[0]}.successorLanguage`,
    `aALProducts.${index[0]}.attentionTo`,
    `aALProducts.${index[0]}.buyerBorrower`,
    `aALProducts.${index[0]}.counsel`,
    `aALProducts.${index[0]}.pricingDetail.locationDisplayName`,
    `aALProducts.${index[0]}.isLocationDefault`,
    `aALProducts.${index[0]}.pricingDetail.liability`,
    `aALProducts.${index[0]}.effectiveDate`,
    `aALProducts.${index[0]}.addlParties`,
    `aALProducts.${index[0]}.printAttorneyOnly`,
    `aALProducts.${index[0]}.isForeignAddress`,
    `aALProducts.${index[0]}.foreignState`,
    `aALProducts.${index[0]}.foreignCountry`,

    `cpls.${index[0]}.issueDateName`,
    `cpls.${index[0]}.serialNumberName`,
    `cpls.${index[0]}.statusName`,
    `cpls.${index[0]}.effectiveDate`,
    `cpls.${index[0]}.coveredParty`,
    `cpls.${index[0]}.addresseeNames`,
    `cpls.${index[0]}.form`,
    `cpls.${index[0]}.includeAllBranches`,
    `cpls.${index[0]}.addressOne`,
    `cpls.${index[0]}.addressTwo`,
    `cpls.${index[0]}.city`,
    `cpls.${index[0]}.state`,
    `cpls.${index[0]}.zipCode`,
    `cpls.${index[0]}.loanNumber`,
    `cpls.${index[0]}.successorLanguage`,
    `cpls.${index[0]}.attentionTo`,
    `cpls.${index[0]}.buyerBorrower`,
    `cpls.${index[0]}.seller`,
    `cpls.${index[0]}.pricingDetail.locationDisplayName`,
    `cpls.${index[0]}.isLocationDefault`,
    `cpls.${index[0]}.addlParties`,
    `cpls.${index[0]}.printAttorneyOnly`,
    `cpls.${index[0]}.isForeignAddress`,
    `cpls.${index[0]}.foreignAddressOne`,
    `cpls.${index[0]}.foreignAddressTwo`,
    `cpls.${index[0]}.foreignCity`,
    `cpls.${index[0]}.foreignState`,
    `cpls.${index[0]}.foreignCountry`,

    `jackets.${index[0]}.issueDateName`,
    `jackets.${index[0]}.productReferenceID`,
    `jackets.${index[0]}.statusName`,
    `jackets.${index[0]}.effectiveDate`,
    `jackets.${index[0]}.formType`,
    `jackets.${index[0]}.form`,
    `jackets.${index[0]}.pricingDetail.isSimultaneous`,
    `jackets.${index[0]}.insuredNames`,
    `jackets.${index[0]}.pricingDetail.transCodeKey`,
    `jackets.${index[0]}.pricingDetail.liability`,
    `jackets.${index[0]}.jacketSignatures.0`,
    `jackets.${index[0]}.pricingDetail.locationDisplayName`,

    `standaloneEndorsements.${index[0]}.originalJacketNumber`,
    `standaloneEndorsements.${index[0]}.originalJacketDate`,
    `standaloneEndorsements.${index[0]}.originalJacketType`,
    `standaloneEndorsements.${index[0]}.originalJacketLiability`,
    `standaloneEndorsements.${index[0]}.locationDisplayName`,
    `standaloneEndorsements.${index[0]}.effectiveDate`,
    `standaloneEndorsements.${index[0]}.pricingDetail.locationDisplayName`,

    `pricingProducts.${index[0]}.pricingRateType`,
    `pricingProducts.${index[0]}.isReissue`,
    `pricingProducts.${index[0]}.pricingRateType`,
    `pricingProducts.${index[0]}.actualFee`,
    `pricingProducts.${index[0]}.actualRiskRate`,
    `pricingProducts.${index[0]}.agentRetention`,
    `pricingProducts.${index[0]}.totalDue`,
    `pricingProducts.${index[0]}.productItems.${index[1]}.actualFee`,
    `pricingProducts.${index[0]}.productItems.${index[1]}.actualRiskRate`,
    `pricingProducts.${index[0]}.productItems.${index[1]}.agentRetention`,
    `pricingProducts.${index[0]}.productItems.${index[1]}.totalDue`,
  ];
};

function useFieldDisabler(
  fieldName: string,
  forceEnable: boolean | undefined = false
): boolean {
  const { getValues } = useFormWrapper();
  const [{ isReadOnly }] = useFiles();  

  const isLocked = getValues("isLocked");
  const isFileLocked = isLocked === 1;  
  const index = getIndexFromFiled(fieldName);

  let extendedList = useMemo(
    () => getDinamicAndStaticList(disabledStaticFieldsList, index),
    [index]
  );
    
  return extendedList.includes(fieldName) && (isFileLocked || isReadOnly) && !forceEnable;
}

export default useFieldDisabler;
