import {
  styled,
  TextField,
} from "@mui/material";
import React, {
  useEffect,
  useState,
} from "react";
import NumberFormat from "react-number-format";
import {
  colors,
  defaultStyle,
} from "theme/defaultStyle";
import theme from "theme/default";

interface IProps {
  hasError?: boolean;
  name: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  allowNegative?: boolean;
  value: string;
  onBlur?: (value: string) => void;
  onChange: (e: any, val: number | string) => void;
}

const CssTextField = styled(TextField)((props) => ({
  "& input.MuiInputBase-input": theme.custom.textField.inputOutlined,
  "& input.MuiInputBase-input.Mui-disabled": {
    ...theme.custom.textField.inputOutlinedDisabled,
    color: props.error ? defaultStyle.control.errorColor : colors.grey13,
  },
}));

const CurrencyFormatCustom = ({
  inputRef,
  onChange,
  name,
  decimalScale,
  ...rest
}: any) => {
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      isNumericString
      prefix="$"
      name={name}
      style={{ paddingRight: "10px" }}
    />
  );
};

const CurrencyInput = ({
  hasError = false,
  name,
  label,
  type,
  allowNegative = true,
  disabled = false,
  value,
  onBlur,
  onChange,
}: IProps) => {
  const [internalValue, setInternalValue] = useState(value || "");
  const [shrinkable, setShrinkable] = useState<boolean>(false);

  const handleOnBlur = (event: any) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setShrinkable(false);
      return;
    }

    onBlur && onBlur(newValue);
  };

  const handleOnChange = (e: any) => {
    const newValue = e.target.value;
    setShrinkable(newValue !== "");
    setInternalValue(newValue);
    onChange && onChange(e, newValue);
  };

  useEffect(() => {
    setShrinkable(internalValue !== undefined && internalValue !== "");
  }, [internalValue]);

  useEffect(() => {
    //hack to clean the value after issuing a product
    if (disabled && Number(value) === 0) setInternalValue("");

    //hack to set correct value when revise a product
    if (Number(value) !== Number(internalValue)) setInternalValue(value);
  }, [disabled, internalValue, value]);

  return (
    <CssTextField
      data-testid={name}
      style={{
        borderColor: "#dc3545",
      }}
      error={hasError}
      value={internalValue}
      {...{ label, name, type, disabled }}
      onBlur={handleOnBlur}
      onFocus={() => setShrinkable(true)}
      fullWidth
      InputProps={{
        disableUnderline: true,
        inputComponent: CurrencyFormatCustom,
        inputProps: {
          allowNegative: allowNegative,
          "aria-errormessage": hasError ? "rhfvalidationerror" : "",
        },
      }}
      variant="outlined"
      InputLabelProps={{
        shrink: hasError || shrinkable,
      }}
      onChange={handleOnChange}
    />
  );
};

export default CurrencyInput;
