import ClearIcon from "@mui/icons-material/Clear";
import { 
  DialogActions, 
  styled,
} from "@mui/material";
import ActionButton from "controls/global/action-button";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useIsActionInProgressFiles,
  useIsFileLoadingFiles,
} from "utils/context/FilesContext";
import {
  useProcessStatusTracking,
  useProcessStatusTrackingState,
} from "utils/context/ProcessStatusContext";
import { useInterval } from "utils/custom-hooks/useInterval";
import LinearProgressWithLabel from "../../linear-progress-with-label";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle
} from "../stewart-dialog/StewartDialog";
import CircularProgressWithLabel from "controls/global/circular-progress-with-label";
import { ProgressImageType } from "utils/data/enum";
import { zIndex } from "theme/defaultStyle";

const maxDelayCounter: number = 90; // Counter to reset Interval for polling

const CircularProgressContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  left: "50%",
  position: "fixed",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: zIndex.positiveMax,
  "& .MuiCircularProgress-circleDeterminate": {
    transition: "stroke-dashoffset 0ms",
  },
  // "& #progressContainer": {
  //   width: "600px",
  //   height: "200px",
  //   backgroundColor: "#92C4DB1A",
  //   borderRadius: "0px 0px 0px 0px",
  // }
});

type Props = {
  title: string;
  confirmationMessage?: string;
  closeButtonText?: string;
  isOpen: boolean;
  onClose: any;
  autoClose: boolean;
  requestId: string;
  onCloseAction?: any;
  runProgress?: boolean;
  progressBaseline?: number;
  progressImageType?: string;
};

const ConfirmationDialogWithProgressbar = ({
  closeButtonText,
  onClose,
  onCloseAction,
  requestId,
  isOpen,
  runProgress,
  autoClose,
  progressBaseline,
  title,
  confirmationMessage,
  progressImageType = ProgressImageType.linear,
}: Props) => {
  const trackProcessStatusRef = React.useRef<boolean>(false);
  const [{ maxWait, pctComplete }] = useProcessStatusTrackingState();
  const [, { getProcessStatus, reset }] = useProcessStatusTracking();
  const [progressLineValue, setProgressLineValue] = useState(0);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [{ isFileLoading }] = useIsFileLoadingFiles();
  const [{ isActionInProgress }] = useIsActionInProgressFiles();
  const isLoadingSomething = isFileLoading || isActionInProgress;
  // console.log("isFileLoading:", isFileLoading, "isActionInProgress:", isActionInProgress);

  useEffect(() => {
    if (isOpen) {
      trackProcessStatusRef.current = true;
    }
    return () => {
      trackProcessStatusRef.current = false;
      reset();
    };
  }, [isOpen, reset]);

  useInterval(
    async () => {
      await getProcessStatus(requestId, trackProcessStatusRef.current);
    },
    isOpen && runProgress && pctComplete < 100 && maxWait < maxDelayCounter
      ? 1000
      : null
  );

  const closeDialog = useCallback(() => {
    onClose();
    onCloseAction && onCloseAction();
  }, [onClose, onCloseAction]);

  const completedProcess = React.useCallback(() => {
    // console.log("completedProcess - pctComplete:", pctComplete);
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
    }, 3500);
    setTimeout(() => {
      autoClose ? closeDialog() : setShowCloseButton(true);
    }, 3000);
  }, [autoClose, closeDialog]);

  useEffect(() => {
    // console.log("-- UseEffect -- pctComplete:", pctComplete, "isClosing:", isClosing, "isLoadingSomething:",isLoadingSomething );
    if (runProgress) {
      if (
        //isCompleted
        !isLoadingSomething &&
        !isClosing &&
        (pctComplete === 100 || maxWait >= maxDelayCounter)
      ) {
        // console.log("--went in to CompleteProcess--");
        completedProcess();
      }
    } else {
      setShowCloseButton(false);
    }
  }, [
    completedProcess,
    isClosing,
    isLoadingSomething,
    maxWait,
    pctComplete,
    runProgress,
  ]);

  React.useEffect(function updateProgressLineValue() {
    // console.log("pctComplete:", pctComplete);
    const calculatedProgress =
      progressBaseline !== undefined && pctComplete < progressBaseline
        ? progressBaseline
        : pctComplete;
    if (isOpen) {
      setProgressLineValue(calculatedProgress);
    }
    else {
      setProgressLineValue(0)
    }
  }, [isOpen, pctComplete, runProgress, progressBaseline]);

  // console.log("-- Rerender -- pctComplete:", pctComplete, "isClosing:", isClosing, "isLoadingSomething:",isLoadingSomething );
  return (
    <StewartDialog
      open={isOpen}
      maxWidth="md"
      width={580}
    >
      {progressImageType === ProgressImageType.linear ? (
        <>
          <StewartDialogTitle type={"INFO"}>
            {title}
          </StewartDialogTitle>
          <StewartDialogContent>
            {confirmationMessage}
            <LinearProgressWithLabel value={progressLineValue} />
          </StewartDialogContent>
          {autoClose || !showCloseButton ? (<DialogActions />) : (
            <StewartDialogActions>
              <ActionButton
                variant="outlined"
                color="primary"
                onClick={closeDialog}
                startIcon={<ClearIcon />}
              >
                {closeButtonText ?? "Close"}
              </ActionButton>
            </StewartDialogActions>
          )}        
          </>
      ):(
        <>
          <CircularProgressContainer>
            <CircularProgressWithLabel value={progressLineValue} />  
          </CircularProgressContainer>
        </>
      )}
    
    </StewartDialog> 
  );
};

export default ConfirmationDialogWithProgressbar;
