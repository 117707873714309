import React  from "react";
import TableFooter from "@mui/material/TableFooter";
import TotalPremium from "./TotalPremium";
import TotalTax from "./TotalTax";
import TotalAll from "./TotalAll";
import NewTotalAll from "./NewTotalAll";

type Props = {
  isIntegratedPricing: boolean;
  showTax: boolean;
  hasIssuedProducts: boolean;
  displayLiabilityColumn: boolean;
  displayRateTypeColumn: boolean;
  displayReissueColumn: boolean;
  displayTransCodeColumn: boolean;
  displayRiskRateColumn: boolean;
  displayFieldIcon: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  isPricingSectionUpdated: boolean;
};

function PricingTableFooter({
  isIntegratedPricing,
  showTax,
  hasIssuedProducts,
  displayLiabilityColumn,
  displayRateTypeColumn,
  displayReissueColumn,
  displayTransCodeColumn,
  displayRiskRateColumn,
  displayFieldIcon,
  hasFileLockedWithUpdateablePricingItem,
  isPricingSectionUpdated,
}: Props) {

  return (
    <TableFooter>
      {showTax && hasIssuedProducts ? (
        <>
          <TotalPremium
            hasIssuedProducts={hasIssuedProducts}
            displayLiabilityColumn={displayLiabilityColumn}
            displayRateTypeColumn={displayRateTypeColumn}
            displayReissueColumn={displayReissueColumn}
            displayTransCodeColumn={displayTransCodeColumn}
            displayRiskRateColumn={displayRiskRateColumn}
            displayFieldIcon={displayFieldIcon}
            isPricingSectionUpdated={isPricingSectionUpdated}
          />
          <TotalTax
            hasIssuedProducts={hasIssuedProducts}
            displayLiabilityColumn={displayLiabilityColumn}
            displayRateTypeColumn={displayRateTypeColumn}
            displayReissueColumn={displayReissueColumn}
            displayTransCodeColumn={displayTransCodeColumn}
            displayRiskRateColumn={displayRiskRateColumn}
            displayFieldIcon={displayFieldIcon}
            isPricingSectionUpdated={isPricingSectionUpdated}
          />
        </>
      ) : null}
      <TotalAll
        hasIssuedProducts={hasIssuedProducts}
        displayLiabilityColumn={displayLiabilityColumn}
        displayRateTypeColumn={displayRateTypeColumn}
        displayReissueColumn={displayReissueColumn}
        displayTransCodeColumn={displayTransCodeColumn}
        displayRiskRateColumn={displayRiskRateColumn}
        displayFieldIcon={displayFieldIcon}
        hasFileLockedWithUpdateablePricingItem={hasFileLockedWithUpdateablePricingItem}
        isPricingSectionUpdated={isPricingSectionUpdated}
      />
      {hasFileLockedWithUpdateablePricingItem ? (
        <NewTotalAll
          hasIssuedProducts={hasIssuedProducts}
          displayLiabilityColumn={displayLiabilityColumn}
          displayRateTypeColumn={displayRateTypeColumn}
          displayReissueColumn={displayReissueColumn}
          displayTransCodeColumn={displayTransCodeColumn}
          displayRiskRateColumn={displayRiskRateColumn}
          displayFieldIcon={displayFieldIcon}
          isPricingSectionUpdated={isPricingSectionUpdated}
        />
      ) : null}
    </TableFooter>
  );
}

export default PricingTableFooter;
