import { styled } from "@mui/material";
import LightTooltip from "controls/global/light-tooltip";
import React, {
  useState,
} from "react";
import { margin } from "theme/defaultStyle";
import DocsIcon from "theme/icons/DocsIcon";

interface Props {
  taxDocument: string;
}

const PointerDiv = styled("div")({
  cursor: "pointer",
  marginTop: margin.medium,
  textAlign: "center",
});

function TaxDocumentLink({ taxDocument }: Props) {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleOpenTooltip = (open: boolean) => {
    setOpenTooltip(open);
  };

  return (
    <div
      onMouseEnter={() => handleOpenTooltip(true)}
      onMouseLeave={() => handleOpenTooltip(false)}
    >
      <LightTooltip
        title="View Tax Codes"
        arrow
        open={openTooltip}
        placement="top-end"
      >
        <PointerDiv>
          <a href={taxDocument} target="_blank" rel="noreferrer">
            <DocsIcon />
          </a>
        </PointerDiv>
      </LightTooltip>
    </div>
  );
}

export default TaxDocumentLink;
