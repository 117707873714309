import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import { Endorsement } from "entities/UIModel/company/Endorsement";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = {
  endorsements: Record<string, Endorsement[] | undefined>;
};
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const getCacheKey = (
  companyId: string,
  formType: string,
  stateAbbr: string,
  formId?: string | undefined
) => `${companyId}|${formType}|${stateAbbr}|${formId}`;

function toSelectFieldOption(
  endorsements: Endorsement[] | undefined
): SelectFieldOption[] {
  if (!endorsements) return [];

  return endorsements.map((e) => ({
    text: e.endorsementName,
    value: e.endorsementName,
    altEndorsementID: e.endorsementID,
    endorsementVersion: e.endorsementVersion,
    opaIdentifier: e.opaIdentifier,
  }));
}

const buildEndorsements = (response: any) => () => {
  const data: Endorsement[] = response.data?.map((d: any) => {
    return {
      endorsementID: d.endorsementID,
      endorsementName: d.endorsementName,
      endorsementVersion: parseInt(d.endorsementVersion),
      opaIdentifier: d.opaIdentifier,
      endorsementCurrent: d.endorsementCurrent,
      isSFE: d.isSFE,
      order: d.order,
      default: d.default,
    } as Endorsement;
  });

  return data;
};

const actions = {
  getEndorsements:
    (
      companyId: string,
      formType: string | undefined = "",
      stateAbbr: string,
      formId?: string | undefined
    ) =>
      async ({ getState, setState, dispatch }: StoreApi) => {
        try {
          const key = getCacheKey(companyId, formType, stateAbbr, formId);
          const { endorsements } = getState();

          if (endorsements[key]) {
            return;
          }

          // Don't call this api when JacketFormType is undefined or null
          if (!formType) {
            return;
          }

          const params = {
            CompanyID: companyId,
            JacketFormType: formType,
            State: stateAbbr,
            FormID: formId
          };

          const response = await axiosSecuredInstance.get<Endorsement[]>('/Company/GetJacketEndorsements', { params });
          const data = dispatch(buildEndorsements(response));

          const { endorsements: newEndorsements } = getState();
          if (newEndorsements[key]) {
            return;
          }

          const copyCache = { ...newEndorsements };
          copyCache[key] = data;

          setState({ endorsements: copyCache });
        } catch (error) {
          console.error(error);
        }
      },
};

const Store = createStore<State, Actions>({
  initialState: {
    endorsements: {},
  },
  actions,
  name: "companyEndorsementContext",
});

const useEndorsementState = createHook(Store, {
  selector: ({ endorsements }: State, key: string) => ({
    nonShortFormEndorsements: endorsements[key]?.filter((e) => !e.isSFE),
    shortFormEndorsements: endorsements[key]?.filter((e) => e.isSFE),
    formEndorsements: endorsements[key],
  }),
});
const useEndorsementActions = createHook(Store, { selector: null });

export {
  getCacheKey,
  toSelectFieldOption, useEndorsementActions, useEndorsementState
};

