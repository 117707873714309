import { 
  FilePricingDetail,
  Pricing,
  PricingProduct,
} from "entities/UIModel";
import { PricingType } from "utils/data/enum";
import { 
  convertToNumber,
  formatCurrency,
} from "utils/shared";
import useCreateFile from "./useCreateFile";
import useFormWrapper from "./useFormWrapper";
import usePricing from "./usePricing";

export default function usePricingCalculator() {
  const { getValues } = useFormWrapper();
  const {isFileLockedWithUpdateablePricingItem} = usePricing();
  const {isFileLocked} = useCreateFile();

  const getPremiumSubTotal = (isPricingSectionUpdated: boolean) => {
    let actualFeeTotalProduct = 0;
    let riskRateTotalProduct = 0;
    let agentRetentionTotalProduct = 0;
    let totalDueTotalProduct = 0;

    if (!isPricingSectionUpdated || isFileLocked) {
      const pricing: Pricing = getValues("pricing");

      if (!isFileLocked) {
        actualFeeTotalProduct = convertToNumber(pricing?.totalActualFee);
        riskRateTotalProduct = convertToNumber(pricing?.totalActualRiskRate);
        agentRetentionTotalProduct = convertToNumber(pricing?.totalActualRetention);
        totalDueTotalProduct = convertToNumber(pricing?.totalActualRemittance);
      }
      else {
        actualFeeTotalProduct = convertToNumber(pricing?.totalBilledActualFee);
        riskRateTotalProduct = convertToNumber(pricing?.totalBilledActualRiskRate);
        agentRetentionTotalProduct = convertToNumber(pricing?.totalBilledActualRetention);
        totalDueTotalProduct = convertToNumber(pricing?.totalBilledActualRemittance);
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");

      pricingProducts
        ?.filter((p) => p.pricingType === PricingType.Product)
        ?.forEach((p) => {
          if ((!isFileLocked || (isFileLocked && p.isBilled === 1)) && p.isReadyToBeBilled === 1) {
            actualFeeTotalProduct += convertToNumber(p.actualFee);
            riskRateTotalProduct += convertToNumber(p.actualRiskRate);
            agentRetentionTotalProduct += convertToNumber(p.agentRetention);
            totalDueTotalProduct += convertToNumber(p.totalDue);
          }

          p.productItems
            ?.filter(
              (pi) => (!isFileLocked || (isFileLocked && pi.isBilled === 1)) && pi.isReadyToBeBilled === 1
            )
            .forEach((pi) => {
              actualFeeTotalProduct += convertToNumber(pi.actualFee);
              riskRateTotalProduct += convertToNumber(pi.actualRiskRate);
              agentRetentionTotalProduct += convertToNumber(pi.agentRetention);
              totalDueTotalProduct += convertToNumber(pi.totalDue);
            });
        });
    }

    return {
      actualFeeTotalProduct: formatCurrency(actualFeeTotalProduct),
      riskRateTotalProduct: formatCurrency(riskRateTotalProduct),
      agentRetentionTotalProduct: formatCurrency(agentRetentionTotalProduct),
      totalDueTotalProduct: formatCurrency(totalDueTotalProduct),
    };
  };

  const getPricingTotal = (isPricingSectionUpdated: boolean, hasFileLockedWithUpdateablePricingItem: boolean, displayRiskRateColumn: boolean) => {
    let actualFeeTotal = 0;
    let riskRateTotal = 0;
    let agentRetentionTotal = 0;
    let totalDueTotal = 0;

    if (!isPricingSectionUpdated ) {
      const pricing: Pricing = getValues("pricing");

      if (hasFileLockedWithUpdateablePricingItem) {
        if (displayRiskRateColumn) {
          actualFeeTotal = convertToNumber(pricing?.totalBilledActualFee);
          riskRateTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalBilledActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalBilledAmountDue);
        }
        else {
          actualFeeTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          riskRateTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalBilledActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalBilledAmountDue);
        }

      } else {
        if (displayRiskRateColumn) {
          actualFeeTotal = convertToNumber(pricing?.totalActualFee);
          riskRateTotal = convertToNumber(pricing?.totalActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalAmountDue);
        } else {
          actualFeeTotal = convertToNumber(pricing?.totalActualCombinedFee);
          riskRateTotal = convertToNumber(pricing?.totalActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalAmountDue);
        }
      }

    } else {
      if (hasFileLockedWithUpdateablePricingItem) {
        const pricing: Pricing = getValues("pricing");
        if (displayRiskRateColumn) {
          actualFeeTotal = convertToNumber(pricing?.totalBilledActualFee);
          riskRateTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalBilledActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalBilledAmountDue);
        }
        else {
          actualFeeTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          riskRateTotal = convertToNumber(pricing?.totalBilledActualCombinedFee);
          agentRetentionTotal = convertToNumber(pricing?.totalBilledActualRetention);
          totalDueTotal = convertToNumber(pricing?.totalBilledAmountDue);
        }        
      }
      else {
        const pricingProducts: PricingProduct[] = getValues("pricingProducts");
        // let actualFeeTotalTax = 0;
        pricingProducts
          // ?.filter((p) => p.pricingType === PricingType.Product)
          ?.forEach((p) => {
            // if ((!isFileLocked || (isFileLocked && p.isBilled === 1)) && p.isReadyToBeBilled === 1) {
            if ((isFileLocked && p.isBilled === 1) || (!isFileLocked && p.isReadyToBeBilled === 1)) {            
              actualFeeTotal += convertToNumber(p.actualFee);
              // actualFeeTotalTax += convertToNumber(p.actualPremiumTax);
              riskRateTotal += convertToNumber(p.actualRiskRate);
              agentRetentionTotal += convertToNumber(p.agentRetention);
              totalDueTotal += convertToNumber(p.totalDue);
            }

            p.productItems
              ?.filter(
                (pi) => 
                  // (!isFileLocked || (isFileLocked && pi.isBilled === 1)) && pi.isReadyToBeBilled === 1
                  (isFileLocked && pi.isBilled === 1) || (!isFileLocked && pi.isReadyToBeBilled === 1)                
              )
              .forEach((pi) => {
                actualFeeTotal += convertToNumber(pi.actualFee);
                // actualFeeTotalTax += convertToNumber(pi.actualPremiumTax);
                riskRateTotal += convertToNumber(pi.actualRiskRate);
                agentRetentionTotal += convertToNumber(pi.agentRetention);
                totalDueTotal += convertToNumber(pi.totalDue);
              });
          });

        // actualFeeTotal = actualFeeTotal + actualFeeTotalTax;
        // riskRateTotal = riskRateTotal + actualFeeTotalTax;
        // totalDueTotal = totalDueTotal + actualFeeTotalTax;
      }
    }

    return {
      actualFeeTotal : formatCurrency(actualFeeTotal),
      riskRateTotal : formatCurrency(riskRateTotal),
      agentRetentionTotal : formatCurrency(agentRetentionTotal),
      totalDueTotal : formatCurrency(totalDueTotal),
    };
  };

  const getPricingNewTotal = (isPricingSectionUpdated: boolean, displayRiskRateColumn: boolean) => {
    let newTotalActualFee = 0;
    let newTotalRiskRate = 0;
    let newTotalAgentRetention = 0;
    let newTotalTotalDue = 0;

    if (!isPricingSectionUpdated) {
      // New Total row is only visible for the locked file
      const pricing: Pricing = getValues("pricing");

      if (displayRiskRateColumn) {
        newTotalActualFee = convertToNumber(pricing?.totalActualFee);
        newTotalRiskRate = convertToNumber(pricing?.totalActualCombinedFee);
        newTotalAgentRetention = convertToNumber(pricing?.totalActualRetention);
        newTotalTotalDue = convertToNumber(pricing?.totalAmountDue);
      }
      else {
        newTotalActualFee = convertToNumber(pricing?.totalActualCombinedFee);
        newTotalRiskRate = convertToNumber(pricing?.totalActualCombinedFee);
        newTotalAgentRetention = convertToNumber(pricing?.totalActualRetention);
        newTotalTotalDue = convertToNumber(pricing?.totalAmountDue);
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");

      pricingProducts.forEach((p) => {
        if (isFileLockedWithUpdateablePricingItem(p, isFileLocked)) {
          newTotalActualFee += convertToNumber(p.actualFee);
          newTotalRiskRate += convertToNumber(p.actualRiskRate);
          newTotalAgentRetention += convertToNumber(p.agentRetention);
          newTotalTotalDue += convertToNumber(p.totalDue);
        }

        p.productItems
          ?.filter((pi) =>
            isFileLockedWithUpdateablePricingItem(pi, isFileLocked)
          )
          .forEach((pi) => {
            newTotalActualFee += convertToNumber(pi.actualFee);
            newTotalRiskRate += convertToNumber(pi.actualRiskRate);
            newTotalAgentRetention += convertToNumber(pi.agentRetention);
            newTotalTotalDue += convertToNumber(pi.totalDue);
          });
      });
    }

    return {
      newTotalActualDue : formatCurrency(newTotalActualFee),
      newTotalRiskRate : formatCurrency(newTotalRiskRate),
      newTotalAgentRetention : formatCurrency(newTotalAgentRetention),
      newTotalTotalDue : formatCurrency(newTotalTotalDue),
    };
  };

  const getPricingTaxTotal = (isPricingSectionUpdated: boolean) => {
    // Initialize
    let actualFeeTotalTax = 0;
    let riskRateTotalTax = 0;
    let agentRetentionTotalTax = 0;
    let totalDueTotalTax = 0;

    if (!isPricingSectionUpdated  || isFileLocked) {
      const pricing: Pricing = getValues("pricing");

      if (!isFileLocked) {
        actualFeeTotalTax = convertToNumber(pricing?.totalActualPremiumTax);
        riskRateTotalTax = convertToNumber(pricing?.totalActualPremiumTax);
        agentRetentionTotalTax = 0.00;
        totalDueTotalTax = convertToNumber(pricing?.totalActualPremiumTax);
      }
      else {
        actualFeeTotalTax = convertToNumber(pricing?.totalBilledActualPremiumTax);
        riskRateTotalTax = convertToNumber(pricing?.totalBilledActualPremiumTax);
        agentRetentionTotalTax = 0.00;
        totalDueTotalTax = convertToNumber(pricing?.totalBilledActualPremiumTax);
      }

    } else {
      const pricingProducts: PricingProduct[] = getValues("pricingProducts");

      if (isFileLocked) {
        pricingProducts
          ?.filter((p) => p.pricingType === PricingType.Product)
          ?.forEach((p) => {
            if (p.isBilled && p.isBilled === 1 && p.isReadyToBeBilled === 1) {
              actualFeeTotalTax += convertToNumber(p.actualPremiumTax);
            }

            p.productItems
              ?.filter((pi) => pi.isBilled === 1 && pi.isReadyToBeBilled === 1)
              ?.forEach((pi) => {
                actualFeeTotalTax += convertToNumber(pi.actualPremiumTax);
              });
          });

        riskRateTotalTax = actualFeeTotalTax;
        agentRetentionTotalTax = 0;
        totalDueTotalTax = actualFeeTotalTax;

      } else {
        pricingProducts
          ?.filter((p) => p.pricingType === PricingType.Tax)
          ?.forEach((p) => {
            if (p.isReadyToBeBilled === 1) {
              actualFeeTotalTax += convertToNumber(p.actualFee);
              riskRateTotalTax += convertToNumber(p.actualRiskRate);
              agentRetentionTotalTax += convertToNumber(p.agentRetention);
              totalDueTotalTax += convertToNumber(p.totalDue);
            }
          });
      }
    }

    return {
      actualFeeTotalTax : formatCurrency(actualFeeTotalTax),
      riskRateTotalTax : formatCurrency(riskRateTotalTax),
      agentRetentionTotalTax : formatCurrency(agentRetentionTotalTax),
      totalDueTotalTax : formatCurrency(totalDueTotalTax),
    };
  }

  const hasPartialPaymentSheet = () => {
    const filePricingDetails: FilePricingDetail[] =  getValues("filePricingDetails");
    
    if (filePricingDetails) {
      const billableCount = filePricingDetails.filter(fpd => fpd.isBillable === 1).length;
      const billableNoPaymentSheetCount = filePricingDetails.filter(fpd => fpd.isBillable === 1  && (fpd.paymentSheetID || 0) === 0).length;
      const paymentSheetCount = filePricingDetails.filter(fpd => fpd.isBilled === 1  && (fpd.paymentSheetID || 0) !== 0).length;

      const hasPartial = billableCount !== billableNoPaymentSheetCount && paymentSheetCount > 0;

      // const hasBilledItemdsWithPaymentSheet = filePricingDetails.some(fpd => fpd.isBilled === 1 && (fpd.paymentSheetID || 0) === 0);
      // const hasBilledItemsWithNoPaymentSheet = filePricingDetails.some(fpd => fpd.isBilled === 1 && !fpd.paymentSheetID);
      // return (hasBilledItemdsWithPaymentSheet && hasBilledItemsWithNoPaymentSheet);
      return hasPartial;
    }
    else {
      return false;
    }
  };

  const hasBilledWithNoPaymentSheet = () => {
    const filePricingDetails: FilePricingDetail[] =  getValues("filePricingDetails");
    // console.log("filePricingDetails", filePricingDetails);
    if (filePricingDetails) {
      const billedCount = filePricingDetails.filter(fpd => fpd.isBilled === 1).length;
      const paymentSheetCount = filePricingDetails.filter(fpd => fpd.isBilled === 1  && (fpd.paymentSheetID || 0) !== 0).length;
      // console.log(billedCount, paymentSheetCount);
      return billedCount > paymentSheetCount
    }
    else {
      return false;
    }
  }

  const hasBilledWithPartialPaymentSheet = () => {
    const filePricingDetails: FilePricingDetail[] =  getValues("filePricingDetails");
    if (filePricingDetails) {
      const billedCount = filePricingDetails.filter(fpd => fpd.isBilled === 1).length;
      const paymentSheetCount = filePricingDetails.filter(fpd => fpd.isBilled === 1  && (fpd.paymentSheetID || 0) !== 0).length;
      return billedCount > paymentSheetCount && paymentSheetCount > 0
    }
    else {
      return false;
    }
  }  

  const getBilledWithNoPaymentSheetTotalAmountDue = () => {
    let totalAmountDue = 0;
    const filePricingDetails: FilePricingDetail[] = getValues("filePricingDetails");

    filePricingDetails
    ?.filter(fpd => fpd.isBilled === 1 && (fpd.paymentSheetID || 0) === 0)
    ?.forEach(fpd => {
      totalAmountDue += fpd.amountDue || 0;
    });

    return formatCurrency(totalAmountDue);
  }

 

  const getTotalBilledAmountDue = () => {
    const pricing: Pricing = getValues("pricing");
    const totalBilledAmountDue = convertToNumber(pricing?.totalBilledAmountDue);

    return formatCurrency(totalBilledAmountDue);
  }


  // const getReportTotalAmountDue = () => {
  //   let totalAmountDue = 0;
  //   const filePricingDetails: FilePricingDetail[] =  getValues("filePricingDetails");
    
  //   if (filePricingDetails) {
  //     filePricingDetails
  //       ?.filter((fpd) => (fpd.isBillable === 1 && fpd.isReadyToBeBilled === 1 && fpd.isBilled === 0))
  //       ?.forEach((fpd) => {
  //         totalAmountDue += fpd.amountDue || 0;
  //       });      
  //   }

  //   return formatCurrency(totalAmountDue);    
  // }

  // const getPaymentSheetFileTotalAmountDue = () => {
  //   let totalAmountDue = 0;
  //   const filePricingDetails: FilePricingDetail[] =  getValues("filePricingDetails");
    
  //   if (filePricingDetails) {
  //     filePricingDetails
  //       ?.filter((fpd) => (fpd.isBillable === 1 && fpd.isBilled === 1 ) || (fpd.isBillable === 1 && fpd.isReadyToBeBilled === 1))
  //       ?.forEach((fpd) => {
  //         totalAmountDue += fpd.amountDue || 0;
  //       });      
  //   }

  //   return formatCurrency(totalAmountDue);
  // }
  
  return {
    getPremiumSubTotal,
    getPricingTaxTotal,
    getPricingTotal,
    getPricingNewTotal,
    hasPartialPaymentSheet,
    // getPartialPaymentSheetTotalAmountDue,
    getTotalBilledAmountDue,
    hasBilledWithNoPaymentSheet,
    hasBilledWithPartialPaymentSheet,
    getBilledWithNoPaymentSheetTotalAmountDue,
    // getReportTotalAmountDue,
    // getPaymentSheetFileTotalAmountDue,
  };
}
