import { Box } from "@mui/material";
import { styled } from "@mui/material";
import { sumBy } from "lodash";
import React from "react";
import { formatCurrency } from "utils/shared";
import {
  borderSize,
  colors,
  fontSize,
  fontWeight,
} from "theme/defaultStyle";
import { PendingPayFile } from "entities/UIModel/PendingPayFile";

const TotalBox = styled(Box)({
  borderBottom: `${borderSize.xlarge} solid ${colors.maroon02}`,
  "& div.MuiBox-root": {
    color: colors.grey08,
    display: "inline-block",
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold2,
    maxWidth: "100%",
    overflow: "hidden",
    textAlign: "right",
    textOverflow: "ellipsis",
    "&.totalDue": {
      verticalAlign: "top",
    },
  },
});

interface Props {
  rows: PendingPayFile[];
}

const PendingPayFooterTotal = ({ rows }: Props) => {
  const count = rows.length;
  const total = sumBy(rows, row => row.totalBilledAmountDue || 0);

  return (
    <TotalBox>
      <Box className="totalDue">Total ({count}) - &nbsp;</Box>
      <Box>
        {formatCurrency(total, true, true)}
      </Box>
    </TotalBox>
  );
};

export default PendingPayFooterTotal;
