import ReactJson from "react-json-view";
import { SCFile } from "entities/UIModel";
import React from "react";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";

function SpyGlass() {
  const [file, setFile] = React.useState<SCFile | undefined>();
  const { getValues } = useFormWrapper();

  const refreshData = () => {
    const file: SCFile = getValues();
    setFile(file);
  };

  return (
    <div>
      <button type="button" onClick={refreshData}>
        Refresh
      </button>
      {file && <ReactJson src={file} collapsed={true} enableClipboard={true} />}
    </div>
  );
}

export default SpyGlass;
