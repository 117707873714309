import {
  Endorsement,
  InsuredNamesData,
  JacketFormDetail,
  JacketSignature,
} from "entities/UIModel";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

export interface RevisionValues {
  effectiveDate?: Date | null;
  transCode?: string | null;
  transCodeKey?: string | null;
  transCodeDescription?: string | null;
  insuredNames?: string;
  insuredNamesData: InsuredNamesData;
  liability?: number | null;
  isSimultaneous?: boolean | null;
  isLeasehold?: boolean | null;
  isReissue?: boolean | null;
  originalJacketDate?: Date | null;
  originalJacketNumber?: string | null;
  originalJacketType?: string | null;
  originalJacketLiability?: number | null;
  originalJacketUnderwriterTypeCode?: string | null;
  originalJacketState?: string | null;
  premium?: number;
  endorsements?: Array<Endorsement>;
  formDetails?: Array<JacketFormDetail>;
  jacketSignatures?: Array<JacketSignature>;
  locationDisplayName: string | undefined;
}
interface State {
  isRevisingMode: boolean;
  originalValues: RevisionValues;
  scrollToAfterReload: boolean;
  scrollToSideNavigationItemRef?: HTMLElement;
}

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const actions = {
  setRevisingMode: (isRevising: boolean) =>
    ({ setState }: StoreApi) => {
      setState({ isRevisingMode: isRevising });
    },
  setOriginalValues: (values: RevisionValues) =>
    ({ setState }: StoreApi) => {
      setState({ originalValues: values });
    },
  setScrollTo: (scrollToAfterReload: boolean, scrollToSideNavigationItemRef?: HTMLElement) => 
    ({setState}: StoreApi) => {
      setState({
        scrollToAfterReload,
        scrollToSideNavigationItemRef,
      });
    }
};

const Store = createStore<State, Actions>({
  initialState: {
    isRevisingMode: false,
    originalValues: {} as RevisionValues,
    scrollToAfterReload: false,
    scrollToSideNavigationItemRef: undefined,
  },
  actions,
  name: "filePage"
});

const hook = createHook(Store);
export const useFilePage = () => { return hook(); };
