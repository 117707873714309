import { AxiosRequestConfig } from "axios";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SelectFieldOption } from "controls/global/select-field/SelectInput";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";
import { PartyType } from "utils/data/enum";

interface State {
  additionalPartyTypes: Array<SelectFieldOption>;
  partyTypes: Array<SelectFieldOption>;
  basicPartyTypes: Array<SelectFieldOption>;
  sellerPartyTypes: Array<SelectFieldOption>;
}

const defaultState: State = {
  additionalPartyTypes: [],
  partyTypes: [],
  basicPartyTypes: [],
  sellerPartyTypes: [],
};

type StoreApi = StoreActionApi<State>;

const setDefaultState =
  () =>
    ({ setState }: StoreApi) => {
      setState(defaultState);
    };

const hasAny = (data: Array<any>) => !data || data.length <= 0;

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error: any) {
        console.log(error);
      }
    };

const setPartyTypes =
  (propTypes: Array<any>) =>
    ({ setState }: StoreApi) => {
      if (hasAny(propTypes)) {
        setState(defaultState);
        return;
      }

      const partyTypes: Array<SelectFieldOption> = propTypes.map((type) => {
        return {
          text: type.partyTypeName,
          value: type.partyTypeCode,
          selected: propTypes.length === 1,
        };
      });

      const basicPartyTypes = partyTypes.filter((partyType) => {
        return (
          partyType.value === PartyType.Individual ||
          partyType.value === PartyType.BusTrustEstate
        );
      });

      const sellerPartyTypes = partyTypes.filter((partyType) => {
        return (
          partyType.value === "INDIVIDUAL" || partyType.value === "BUSTRUSTESTATE"
        );
      });

      const additionalPartyTypes = partyTypes.filter((partyType) => {
        return (
          partyType.value !== PartyType.Individual &&
          partyType.value !== PartyType.BusTrustEstate
        );
      });

      setState({
        partyTypes: partyTypes,
        basicPartyTypes: basicPartyTypes,
        sellerPartyTypes: sellerPartyTypes,
        additionalPartyTypes: [
          { selected: true, value: "", text: "" },
          ...additionalPartyTypes.map((pt) => {
            return { text: pt.text, value: pt.value };
          }),
        ],
      });
    };

const Store = createStore<State, any>({
  initialState: defaultState,
  actions: {
    getPartyTypes:
      () =>
        async ({ dispatch }: StoreApi) => {
          return dispatch(getData("/lookup/getlist/PartyType", setPartyTypes));
        },
    resetPartyTypes:
      () =>
        ({ dispatch }: StoreApi) => {
          dispatch(setDefaultState());
        },
  },
  name: "partyTypes",
});

const hook = createHook(Store);
export const usePartyTypes = () => {
  return hook();
};
