import React from "react";
import Typography from "@mui/material/Typography";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { styled } from "@mui/material";

interface TextWithRedAsterisksProps {
  textLines: string[];
}

const RedAsterisk = styled("span")({
  color:colors.red01
})

const RedAsteriskTypography = styled(Typography)({
  "&&": {
    fontSize: fontSize.large,
    fontWeight: fontWeight.bold1,
    marginTop: margin.xlarge,
    paddingTop: padding.medium1,
    paddingBottom: padding.medium2,
  },
});

const TextWithRedAsterisks: React.FC<TextWithRedAsterisksProps> = ({ textLines }) => {
  return (
    <RedAsteriskTypography>
      {textLines.map((line, index) => (
        <>
          <RedAsterisk>*</RedAsterisk> {line}
        </>
      ))}
    </RedAsteriskTypography>
  );
};

const DisplayedMessage: React.FC = () => {
  const textLines = [
    "I acknowledge that I am authorized, or have been provided authorization, to change (Add/Delete) our Stewart Connect users . I understand that my request will only be processed if I acknowledge the same.",
  ];

  return <TextWithRedAsterisks textLines={textLines} />;
};

export default DisplayedMessage;
