import {
  InputAdornment,
  styled,
} from "@mui/material";
import React from "react";
import { colors } from "theme/defaultStyle";
import SearchIcon from "theme/icons/SearchIcon";

const StyledSearchIcon = styled(SearchIcon)({
    width: 20,
    height: 20,
    color: colors.grey06
});

const InputSearchAdornment = () => (
    <InputAdornment position="end" >
        <StyledSearchIcon />
    </InputAdornment>
);

export default InputSearchAdornment;
