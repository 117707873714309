import React, { 
  useCallback 
} from "react";
import { 
  styled, 
  Box 
} from "@mui/material";
import { 
  borderRadius,
  colors, 
  fontSize, 
  fontWeight, 
  margin
} from "theme/defaultStyle";
import FileUploadIcon from "theme/icons/FileUploadIcon";
import { 
  ErrorCode, 
  FileRejection, 
  FileWithPath, 
  useDropzone 
} from "react-dropzone";
import { useConfigContext } from "utils/context/ConfigContextProvider";

const Container = styled(Box)({
  "& .drop-zone": {
    marginTop: margin.xlarge,
    height: "201px",
    border: "1px dashed #384EB74D", 
    borderRadius: borderRadius.small,
    background: "#92C4DB1A", 
    minWidth: "100%",
    display: "flex",
  },
  "& .drop-zone-text": {
    margin: "auto",
    textAlign: "center",
    height: "129px",
    width: "300px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  "& .drop-zone-text .file-upload-icon": {
    margin: "auto",
  },
  "& .drop-zone-text .drag-and-drop": {
    fontWeight: fontWeight.normal2,
    fontSize: fontSize.large,
  },
  "& .drop-zone-text .choose-files": {
    fontWeight: fontWeight.normal2,
    fontSize: fontSize.large,
    color: colors.blue01,
    textDecoration: "underline",
  },
  "& .drop-zone-text .supported-formats": {
    fontWeight: fontWeight.normal1,
    fontSize: fontSize.small,
  },
});

type Props = {
  disabled?: boolean;
  handleOnUpload: (acceptedFiles:FileWithPath[], fileRejections: FileRejection[]) => void;
}

const UploadDropZone = ({disabled = false, handleOnUpload}:Props) => {
  const { generalConfig } = useConfigContext();  
  // console.log("generalConfig.policyUploadConfiguration:", generalConfig.policyUploadConfiguration);

  const allowedFileSize = generalConfig?.policyUploadConfiguration?.allowedFileSize ? generalConfig?.policyUploadConfiguration?.allowedFileSize : 50;
  const maxFileSize = (allowedFileSize * 1024 * 1024); 
  function maxFileSizeValidator(file:FileWithPath) {    
    if (file.size > maxFileSize) {
      return {
        code: ErrorCode.FileTooLarge,
        message: "File size is too large",
      }
    }

    if (file.size <= 0) {      
      return {
        code: ErrorCode.FileTooSmall,
        message: "File size is too small",
      }
    }
  
    return null;
  }
  
  const getAcceptMimeTypes = () => {
    const accept = new Map();
    if (generalConfig?.policyUploadConfiguration?.acceptMimeTypes && 
      generalConfig?.policyUploadConfiguration?.acceptMimeTypes.length > 0
    ) {
      generalConfig.policyUploadConfiguration.acceptMimeTypes.map((acceptMimeType) => {
        const extensions = acceptMimeType.extensions.map((extension) => extension.extension);
        accept.set(acceptMimeType.mimeType,extensions);
      })
    }
    else {
      accept.set('application/pdf',['.pdf']);
    }

    return accept;
  }

  const getSupportedExtensions = () => {

    let supportedExtensions = "";
    if (generalConfig?.policyUploadConfiguration?.acceptMimeTypes && 
      generalConfig?.policyUploadConfiguration?.acceptMimeTypes.length > 0
    ) {
      
      generalConfig.policyUploadConfiguration.acceptMimeTypes.map((acceptMimeType) => {
        const extensions = acceptMimeType.extensions.map((extension) => extension.extension);
        if (supportedExtensions.length > 0) {
          supportedExtensions += ", ";
        }
        supportedExtensions += extensions.join(", ");
      })
    }
    else {
      supportedExtensions = ".pdf";
    }
    return supportedExtensions;
  }

  // console.log('accept:', getAcceptMimeTypes());
  // console.log('accept object:', Object.fromEntries(getAcceptMimeTypes()));
  // console.log('getSupportedExtensions:', getSupportedExtensions());

  const onDrop = useCallback(async (acceptedFiles:FileWithPath[], fileRejections: FileRejection[]) => {
    await handleOnUpload(acceptedFiles, fileRejections);
  }, [])  

  // TODO - will be updated based onfiguration
  const { getRootProps, getInputProps  } = useDropzone({
    // accept: { 
    //   // 'image/png': ['.png'],
    //   // 'image/jpeg': ['.jpeg', '.jpg'],
    //   'application/pdf': ['.pdf'],
    //   // 'application/msword': ['.doc'],
    //   // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    // },    
    accept: Object.fromEntries(getAcceptMimeTypes()),
    validator: maxFileSizeValidator,
    onDrop,
    disabled: disabled,
  });   
    
  return (
    <>
      <Container>
          <div className="drop-zone" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="drop-zone-text">
              <div className="file-upload-icon">
                <FileUploadIcon />
              </div>
              <div className="drag-and-drop">
                Drag and drop or&nbsp;
                <span className="choose-files">choose files</span>
              </div>
              <div className="supported-formats">
                Supported formats: {getSupportedExtensions()} with a maximum size of {allowedFileSize} MB
              </div>
            </div>
          </div>        
      </Container>
    </>
  )

}

export default UploadDropZone;