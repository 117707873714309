import React from "react";
import InputMask from "react-input-mask";

const StewartMaskInput = React.forwardRef((props: any, ref: any) => {
  return (
    <InputMask
      {...props}
      inputRef={ref}
      mask="(999) 999-9999"
      maskChar=' '
    />
  );
});

StewartMaskInput.displayName = "StewartMaskInput";

export default StewartMaskInput;