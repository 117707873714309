import React from "react";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import GlobalAccessHome from "pages/globalAccess/GlobalAccessHome";
import HomePage from "pages/home/HomePage";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useFiles } from "utils/context/FilesContext";
import { SessionStorageKeys } from "utils/data/enum";

const SelectedHomePage: React.FC = () => {
  const [{ companyLocationStates }] = useGlobalAccess();
  const [{ profileSettings }] = useProfileSettingsCache();
  const [{ invokingUrl, invokingAction }] = useFiles();   
  
  if ((!profileSettings.isStaffUser || companyLocationStates.length <= 1) ||
      (profileSettings.isStaffUser &&
        (profileSettings.userProfile?.skipAgencySelection === "Yes" && invokingUrl === '/' && !invokingAction)
      )){
        sessionStorage.setItem(SessionStorageKeys.LANDING_PAGE, "HomePage");        
  }else{
    sessionStorage.setItem(SessionStorageKeys.LANDING_PAGE, "GlobalAccessHome");        
  }  
  return <>{            
            (!profileSettings.isStaffUser || companyLocationStates.length <= 1) ||
            (profileSettings.isStaffUser &&
              (profileSettings.userProfile?.skipAgencySelection === "Yes" && invokingUrl === '/' && !invokingAction)
            ) ? <HomePage /> : <GlobalAccessHome />            
          }</>;
};

export default SelectedHomePage;
