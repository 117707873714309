import * as yup from "yup";

const requiredErrorMessage: string = "Field is Required";

const registrationValidationSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required("Field is required"),
      lastName: yup.string().required("Field is required"),
      email: yup.string().email("Invalid email format").required("Field is required"),
      timeZone: yup.string().required("Field is required"),
    }),
  ),
  agencyName: yup.string().required(requiredErrorMessage),
  agencyID: yup.string().required(requiredErrorMessage),
  state:  yup.mixed().test("state-validation", requiredErrorMessage, function (value) {
    if (Array.isArray(value)) {
      if (value.length === 0) return false;
      return value.every((item) => item && item.text);
    } else if (typeof value === "string") {
      return value.trim() !== "";
    } else if (value && value.text) {
      return true;
    } else {
      return false;
    }
  }),
  radioSelection: yup.string().required(requiredErrorMessage),
  agency: yup.mixed().test("agency-validation", requiredErrorMessage, function (value) {
    if (Array.isArray(value)) {
      if (value.length === 0) return false;
      return value.every((item) => item && item.text);
    } else if (typeof value === "string") {
      return value.trim() !== "";
    } else if (value && value.text) {
      return true;
    } else {
      return false;
    }
  }),
  tpsSoftware: yup.string().required("Field is required"),
});

export default registrationValidationSchema;
