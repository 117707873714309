import React from 'react';
import {
  styled,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import StewartSortLabel from 'controls/global/stewart-table/StewartSortLabel';
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from 'theme/defaultStyle';
import { Order } from 'utils/sorting';
import { ReleaseNoteDto } from './ReleaseNote';
interface Props {
  order: Order;
  orderBy: keyof ReleaseNoteDto;
  onRequestSort: (property: keyof ReleaseNoteDto) => void;
}

interface HeadCell {
  id: keyof ReleaseNoteDto;
  label: string;
  width: string;
}

const StyledTableHead = styled(TableHead)({
  "& .MuiTableRow-root": {
    height: "36px",
    "& th": {
      border: "none",
      paddingBottom: padding.zero,
      paddingLeft: padding.medium1,
      paddingRight: padding.zero,
      paddingTop: padding.zero,
    },
  },
});

const StyledTitle = styled("div")({
  color: colors.black,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const headCells: HeadCell[] = [{ id: "DocFileName", label: "Description", width: "40%" }];

const StyledTableCell = styled(TableCell)({
  fontSize: fontSize.large,
  fontWeight: fontWeight.bold1,
});

const ReleaseNoteHead = ({ order, orderBy, onRequestSort }: Props) => {
  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((cell) => (
          <StyledTableCell
            key={cell.id}
            style={{ width: cell.width }}
          >
            <StewartSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : "asc"}
              onRequestSort={() => onRequestSort(cell.id)}
            >
              <StyledTitle>{cell.label}</StyledTitle>
            </StewartSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export default ReleaseNoteHead;
