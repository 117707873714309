import React from 'react';
import { TableBody } from '@mui/material';
import {
  Order,
  getComparator,
  stableSort,
} from 'utils/sorting';
import { ReleaseNoteDto } from './ReleaseNote';
import ReleaseNoteBodyRow from './ReleaseNoteBodyRow';
interface Props {
  order: Order;
  orderBy: keyof ReleaseNoteDto;
  records: ReleaseNoteDto[];
}

const ReleaseNoteBody = ({ order, orderBy, records }: Props) => {
  const noRecords = Boolean(records.length === 0);

  if (noRecords)
    return (
      <TableBody>
        <ReleaseNoteBodyRow />
      </TableBody>
    );

  return (
    <TableBody>
      {stableSort(records, getComparator(order, orderBy)).map((rec) => (
        <ReleaseNoteBodyRow
          key={rec.ReleaseNoteId}
          record={rec}
        />
      ))}
    </TableBody>
  );
};

export default ReleaseNoteBody;
