import React, { 
  useEffect,
 } from "react";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

interface AuthenticatedAppProps {
  children: React.ReactNode;
}

export function AuthenticatedApp({ children }: AuthenticatedAppProps) {
  const [ { profileSettings }, { getProfileSettings }] = useProfileSettingsCache();
  const [ { showChangeAgency, selectedAgency }, 
          { getCompanyLocationStateListByUser, getStateListByUser, setShowChangeAgency, }] = useGlobalAccess();

  useEffect(() => {
    async function fetchSettings() {
      await getProfileSettings();
      await getCompanyLocationStateListByUser();
      await getStateListByUser();
    }
    fetchSettings();
  }, [getProfileSettings, getCompanyLocationStateListByUser, getStateListByUser]);

  useEffect(() => {

    // if(selectedAgency?.length <= 0 &&
    //   profileSettings?.userProfile?.defaultAgencyID &&
    //   profileSettings?.isStaffUser){
    //     setUserSelectedAgency([{
    //       CompanyID: profileSettings.userProfile.defaultAgencyID,
    //       CompanyName: ""
    //     }]);
    // }    

    if(!profileSettings?.isStaffUser) {
      if (showChangeAgency) {
        setShowChangeAgency(false);
      }
    }
  }, [profileSettings, selectedAgency?.length, setShowChangeAgency, showChangeAgency,]);

  return <>{children}</>;
}
