import PaymentSheetContentMessage from "pages/payments/components/payment-sheet-option/PaymentSheetContentMessage";
import ReportAndPayByCheckButton from "controls/global/report-and-pay-by-check/ReportAndPayByCheckButton";
import { ReportAndPayCriteria } from "entities/ApiModel/ReportAndPayCriteria";
import { SCFile } from "entities/UIModel";
import { ReportingResult } from "entities/UIModel/ReportingResult";
import mapUiSCFileToApiSCFile from "pages/file/utils/toApi/mapUiSCFileToApiSCFile";
import { runValidation } from "pages/file/utils/yup/validator";
import React, {
  useRef, 
} from "react";
import { useAutomaticProgressDialogActions } from "utils/context/AutomaticProgressDialogContext";
import { useDialog } from "utils/context/DialogContext";
import { useFiles } from "utils/context/FilesContext";
import { usePayment } from "utils/context/PaymentContext";
import { useProgressbar } from "utils/context/ProgressbarContext";
import useCreateFile from "utils/custom-hooks/useCreateFile";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import usePricingCalculator from "utils/custom-hooks/usePricingCalculator";
import {
  MapActionType,
  // PERMISSIONS,
  PaymentSheetOptionType,
  ReportAndPayAction,
} from "utils/data/enum";
import { v4 as uuidv4 } from "uuid";
import { 
  PAYMENT_SHEET_OPTION_TYPE, 
  PaymentSheetOption,
} from "pages/payments/components/payment-sheet-option/PaymentSheetSelectionOptions";
import { 
  convertToNumber, 
  formatCurrency,
} from "utils/shared";
import usePricing from "utils/custom-hooks/usePricing";
// import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";

export const DIALOG_MESSAGE =
  "By clicking <strong>Submit</strong>, a payment sheet will be generated and the file will be reported and closed.";

interface Props {
  disabled: boolean;
  isPricingSectionUpdated: boolean;
  hasFileLockedWithUpdateablePricingItem: boolean;
  displayRiskRateColumn: boolean;
  onReportingComplete: (reportingResult: ReportingResult) => void;
}
export default function PricingReportAndPayByCheck({
  disabled,
  isPricingSectionUpdated,
  hasFileLockedWithUpdateablePricingItem,
  displayRiskRateColumn,
  onReportingComplete,
}: Props) {
  const { setValue, getValues, trigger } = useFormWrapper();
  const [, { openDialog }] = useDialog();
  const [, { openProgressbar, closeProgresbar }] = useProgressbar();
  const [{ initialValues }, { saveFile }] = useFiles();
  const [, { reportPricing }] = usePayment();
  const { isFileLocked, reloadFile } = useCreateFile();
  const { getPricingTotal, getPricingNewTotal, hasPartialPaymentSheet, getBilledWithNoPaymentSheetTotalAmountDue } = usePricingCalculator();
  const { getSelectedPaymentSheetItems } = usePricing()
  
  const [, { openAutomaticProgressDialog, closeAutomaticProgressDialog }] =
    useAutomaticProgressDialogActions();
  const selectedPaymentSheetOption = useRef<PAYMENT_SHEET_OPTION_TYPE>(PaymentSheetOptionType.Product)  
  const fileId = getValues("id");
  const reportingResultRef = useRef<ReportingResult>({});

  let totalDueTotal = "";
  if (isFileLocked) {
    const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);
    totalDueTotal = pricingNewTotal.newTotalTotalDue;
  } else {
    const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);
    totalDueTotal = pricingTotal.totalDueTotal;
  }

  const setSelectedPaymentSheetOption = (paymentSheetOptionType:PAYMENT_SHEET_OPTION_TYPE) => {
    selectedPaymentSheetOption.current = paymentSheetOptionType;
  }

  const getFileTotalDue = () => {
    const pricingTotal = getPricingTotal(isPricingSectionUpdated, hasFileLockedWithUpdateablePricingItem, displayRiskRateColumn);
    const pricingNewTotal = getPricingNewTotal(isPricingSectionUpdated, displayRiskRateColumn);

    const fileTotalDue = formatCurrency(convertToNumber(pricingTotal.totalDueTotal) + convertToNumber(pricingNewTotal.newTotalTotalDue));
    return fileTotalDue;    
  }
  
  const hasPaymentSheetOptions = hasFileLockedWithUpdateablePricingItem && hasPartialPaymentSheet();
  let helperText = "";
  if (hasPaymentSheetOptions) {
    helperText = "By clicking <strong>Submit</strong>, a payment sheet will be generated and the file will be reported and closed.";
  }
  else {
    const billedWithNoPaymentSheetAmountDue =  getBilledWithNoPaymentSheetTotalAmountDue();
    const billedWithNoPaymentSheetTotalAmountDue = formatCurrency(convertToNumber(totalDueTotal) + convertToNumber(billedWithNoPaymentSheetAmountDue));
    helperText = `By clicking <strong>Submit</strong>, a payment sheet for this file in the amount of ${billedWithNoPaymentSheetTotalAmountDue} will be generated and the file will be reported and closed.`;
  }

  const getPaymentSheetSelectionOptions = () => {
    const paymentSheetSelectionOptions: PaymentSheetOption[] = [];
    const billedWithNoPaymentSheetAmountDue =  getBilledWithNoPaymentSheetTotalAmountDue();
    const billedWithNoPaymentSheetTotalAmountDue = formatCurrency(convertToNumber(totalDueTotal) + convertToNumber(billedWithNoPaymentSheetAmountDue));

    if (hasPartialPaymentSheet()) {
      paymentSheetSelectionOptions.push({
        label: `Added endorsement(s) only - ${billedWithNoPaymentSheetTotalAmountDue}`,
        value: PaymentSheetOptionType.Product,        
      })
    }

    paymentSheetSelectionOptions.push({
      label: `Entire file - ${getFileTotalDue()}`, 
      value: PaymentSheetOptionType.File,      
    })

    return paymentSheetSelectionOptions;
  }

  const getPaymentSheetOptionDefault = () => {
    if (hasPartialPaymentSheet()) {
      return PaymentSheetOptionType.Product;
    }
    else {
      return PaymentSheetOptionType.File;
    }
  }
  
  const handleReportAndPayByCheck = () => {
    openDialog({
      title: "Report & Pay By Check",
      content: (
        <PaymentSheetContentMessage 
          mainText={`Are you ready to report this file for ${totalDueTotal}?`}
          helperText={helperText}
          showPaymentSheetSelectionOptions={hasPaymentSheetOptions}
          paymentSheetSelectionTitle="Report and generate a payment sheet for:"
          paymentSheetSelectionOptions={getPaymentSheetSelectionOptions()}
          paymentSheetOptionDefault={getPaymentSheetOptionDefault()} 
          setSelectedPaymentSheetOption={setSelectedPaymentSheetOption}          
        />
      ),
      onSubmitAction: submitReportAndPayByCheck,
    });
  };

  const submitReportAndPayByCheck = async () => {
    const requestId = uuidv4();

    const formValues: SCFile = getValues();

    const isFormValid = await runValidation({
      values: formValues,
      trigger,
      setValue,
      productAction: MapActionType.ProductAction,
    });

    if (!isFormValid) {      
      return;
    }

    openProgressbar({
      requestId,
      title: "Please wait while reporting your premiums...",
      // onCloseAction: handleReportComplete,
    });

    const fileToSave = mapUiSCFileToApiSCFile(
      initialValues,
      formValues,
      undefined,
      MapActionType.SaveFile
    );
            
    await saveFile(fileToSave, false);

    const reportAndPayCriteria: ReportAndPayCriteria = {
      PaymentSheetOptionTypeCode: selectedPaymentSheetOption.current,
      PendingPaymentItems: getSelectedPaymentSheetItems(selectedPaymentSheetOption.current),
    }

    const result = await reportPricing(reportAndPayCriteria, requestId, ReportAndPayAction.ReportAndPayByCheck);
    reportingResultRef.current = result;
    closeProgresbar();
    handleReportComplete();
  };

  const handleReportComplete = async () => {
    openAutomaticProgressDialog();
    await reloadFile(fileId);
    closeAutomaticProgressDialog();

    onReportingComplete(reportingResultRef.current);
  };

  // const [{ profileSettings }] = useProfileSettingsCache();

  // const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);

  // const hasPayByCheckAccess = permissionCodes?.includes(PERMISSIONS.BILLING_RPTPAYCHECK);

  return (
    <ReportAndPayByCheckButton
      onClick={handleReportAndPayByCheck}
      // disabled={disabled || !hasPayByCheckAccess}
      disabled={disabled}
    />
  );
}
