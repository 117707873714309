import React, {
  useEffect,
  useState,
} from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import FaqItem from "./faq-item";
import FAQIcon from "../../../../theme/icons/FAQIcon";
import { axiosSecuredInstance } from "../../../../configurations/axiosConfig";
import cssClasses from "../../HelpCenter.module.css";

export type HelpCenterGetFaqItemsData = {
  FaqId?: string;
  Question: string;
  Answer: string;
};

type FAQType = {
  searchTags: string;
};

const FAQ = ({ searchTags }: FAQType) => {
  const [faq, setFaq] = useState<HelpCenterGetFaqItemsData[]>([]);

  async function getFaqItems(): Promise<HelpCenterGetFaqItemsData[]> {
    const { data } = await axiosSecuredInstance.get("HelpCenter/faqs");
    return data;
  }

  useEffect(() => {
    getFaqItems().then((result: HelpCenterGetFaqItemsData[]) => {
      setFaq(result || []);
    });
  }, []);

  function getFilteredItems() {
    return faq?.filter?.(
      (f) =>
        !searchTags ||
        f.Answer.toLowerCase().includes(searchTags.trim().toLowerCase()) ||
        f.Question.toLowerCase().includes(searchTags.trim().toLowerCase())
    );
  }

  return (
    <Box className={cssClasses.global}>
      <Box className={cssClasses.container}>
        <Box className={cssClasses.title}>
          <FAQIcon />
          <Typography className={cssClasses.gradientTitle} variant="h6">FAQs</Typography>
        </Box>
      </Box>
      <Box className={cssClasses.questionsContainer}>
        {getFilteredItems().length === 0 && !!searchTags ? (
          <>
            <Typography className={cssClasses.noKeywordMatch}>
              {`No matches found for keyword '${searchTags.trim()}'`}{" "}
              <Typography className={cssClasses.adjustKeywords}>
                Please adjust your keyword search and try again.
              </Typography>
            </Typography>
          </>
        ) : (
          getFilteredItems().map(
            (
              { Question, Answer, FaqId }: HelpCenterGetFaqItemsData,
              idx: number
            ) => {
              return (
                <FaqItem
                  key={Question + Answer + String(idx)}
                  Question={Question}
                  Answer={Answer}
                />
              );
            }
          )
        )}
        {getFilteredItems().length === 0 && !searchTags && (
          <Box className={cssClasses.emptySection}>
            <Typography className={cssClasses.emptySectionLabel}>
              No Records
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FAQ;
