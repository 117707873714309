import React from "react";
import DrawSignature from "../draw-signature";
import TypeSignature from "../type-signature";
import UploadSignature from "../upload-signature";
import { useSignatureProvider } from "pages/signatures/providers/manage-signature/SignatureProvider";

export default function SignatureTypes() {
  const {
    state: { imageSource },
  } = useSignatureProvider();

  const renderComponent = () => {
    const source = imageSource.toLowerCase();
    if (source.includes("upload")) {
      return <UploadSignature />;
    }
    else if (source.includes("type")) {
      return <TypeSignature />;
    }
    else if (source.includes("draw")) {
      return <DrawSignature />;
    }
    else {
      return <></>;
    }
  }

  return (
    <>
      {renderComponent()}
    </>
  );
}
