import { Property } from "entities/UIModel";
import { v4 as uuidv4 } from "uuid";

export function getDefaultPropertyObject(isAddClicked?: boolean): Property {
  const iKey: string = uuidv4();
  if(!isAddClicked)
  {
    isAddClicked = false;
  }
  return {
    isDefault: !isAddClicked,
    isDirty: isAddClicked,
    id: 0,
    fileID: 0,
    identityKey: 0,
    addressOne: "",
    addressTwo: "",
    city: "",
    county: { code: "", name: "" },
    legalDescription: "",
    propertyType: "",
    state: { code: "", abbr: "" },
    zipCode: "",
    integrationKey: iKey,
    displayOrder: 0,
    taxCode: { value: "", text: ""},
  };
}
