import { TableBody } from "@mui/material";
import { FileActivity } from "entities/UIModel";
import React from "react";
import {
  Order,
  getComparator,
  stableSort,
} from "utils/sorting";
import FileHistoryBodyRow from "./FileHistoryBodyRow";

interface Props {
  order: Order;
  orderBy: keyof FileActivity;
  records: FileActivity[];
}

const FileHistoryBody = ({ order, orderBy, records }: Props) => {
  const noRecords = Boolean(records.length === 0);

  if (noRecords)
    return (
      <TableBody>
        <FileHistoryBodyRow />
      </TableBody>
    );

  return (
    <TableBody>
      {stableSort(records, getComparator(order, orderBy)).map((rec) => (
        <FileHistoryBodyRow key={rec.fileActivityID} record={rec} />
      ))}
    </TableBody>
  );
};

export default FileHistoryBody;
