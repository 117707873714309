import React, {
  useEffect,
  useState,
} from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StewartDatePicker from "controls/global/stewart-datepicker";
import DatePickerIcon from "theme/icons/DatePickerIcon";
import {
  dateWithoutTime,
  addLeadingZerosToDate,
} from "utils/shared";
import { TextField } from "@mui/material";
import useDocumentStore from "utils/context/ImageArchiveContext";
import { PageType } from "utils/data/enum";

export interface Props {
  value?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  hasError?: boolean;
  header?: boolean; //TRUE: DISPLAYED AS A COLUMN FILTER
  disabled?: boolean;
  handleDateChange: (newDate: Date | null) => void;
  onError?: (error: boolean) => void;
  size?: "small" | "medium";
  reset?: number;
  page? :PageType,
}

const DatePickerSearch = ({
  value,
  minDate,
  maxDate,
  hasError,
  header,
  disabled,
  handleDateChange,
  onError,
  size = "medium",
  reset,
  page,
}: Props) => {
  const [date, setDate] = useState<Date | null>(null);
  const [acceptedDate, setAcceptedDate] = useState<Date | null>(null);
  const [enteredDateValue, setEnteredDateValue] = useState<string | undefined>(undefined);
  const [{ isAdvanceSearch }, { setShowToast }] = useDocumentStore();

  const isValidDate = (date: Date | null) =>
    date ? !isNaN(date?.getTime()) : false;

  const handleChange = (selectedDate: any, keyboardInputValue?: string) => {
    setEnteredDateValue(keyboardInputValue);
    setAcceptedDate(null);

    const isValid = isValidDate(selectedDate);
    const updatedDate = selectedDate && isValid ? dateWithoutTime(selectedDate) : selectedDate;

    // if (isValid && onError) onError(false);
    setDate(updatedDate);
    handleDateChange(updatedDate);  

    // check popup error here
    if (
      keyboardInputValue?.length === 10 &&
      isValid &&
      page === PageType.ImageArchive &&
      isAdvanceSearch &&
      minDate &&
      maxDate &&
      !(updatedDate >= minDate && updatedDate <= maxDate)
    ) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!date || !isNaN(date.getTime())) return;

    const input = event.target.value;
    const inputDate = addLeadingZerosToDate(input);

    if (!inputDate || !isValidDate(inputDate)) {
      onError && onError(true);
      setDate(null);
      handleDateChange(null);
      return;
    }
  
    onError && onError(false);
    setDate(inputDate);
    handleDateChange(inputDate);
  };

  const handleError = (errors: string | null) => {
    if (
      (enteredDateValue?.length === 10 || acceptedDate) &&
      page === PageType.ImageArchive &&
      isAdvanceSearch &&
      (errors?.includes("minDate") || errors?.includes("maxDate"))
    ) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  };

  const handleOnAccept = (date: any) => {
    setAcceptedDate(date);
    setEnteredDateValue(undefined);
  };

  useEffect(() => {
    setDate(value ? value : null);
  }, [reset]);

  useEffect(() => {
    if (disabled) {
      setDate(null);
    }
  }, [disabled]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StewartDatePicker
        disabled={disabled}
        inputFormat="MM/dd/yyyy"
        onChange={handleChange}
        onAccept={handleOnAccept}
        InputProps={{ disableUnderline: true }}
        components={{
          OpenPickerIcon: DatePickerIcon
        }}
        value={date}
        minDate={minDate ?? undefined}
        maxDate={maxDate ?? undefined}
        renderInput={(params: any) => <TextField
          fullWidth
          placeholder="mm/dd/yyyy"
          helperText={null}
          size={size}
          error={hasError}
          onBlur={handleBlur}
          {...params}
        />}
        onError={(errorMessage : string|null) => handleError(errorMessage)}
      />
    </LocalizationProvider>
  );
};

export default DatePickerSearch;
