import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { styled } from "@mui/material";
import StewartCheckbox from "controls/global/stewart-checkbox";
import { ReportAndPayCriteriaSearch } from "entities/UIModel/ReportAndPayCriteriaSearch";
import React, {
  useEffect,
  useState,
} from "react";
import { colors } from "theme/defaultStyle";
import { FileStatusType } from "utils/data/enum";
import { capitalize } from "utils/shared";

interface Props {
  name: keyof ReportAndPayCriteriaSearch;
  defaultValue: Array<FileStatusType>;
  disabled?: boolean;
  onChange: (name: keyof ReportAndPayCriteriaSearch, value: string) => void;
}

const StyledSelect = styled(Select)({
  maxWidth: "100px",
  width: "auto",
  "&.Mui-disabled": {
    backgroundColor: colors.grey10,
    "& .MuiSelect-root": {
      color: colors.grey09,
    },
    "& .MuiSvgIcon-root": {
      color: colors.blue11,
    },
  },
});

const options = [
  { id: FileStatusType.Open, label: "Open" },
  { id: FileStatusType.Inactive, label: "Inactive" },
  { id: FileStatusType.ReportPending, label: "Reported/Pending" },
];

const SelectStatus = ({ name, defaultValue, disabled, onChange }: Props) => {
  const [selectedValues, setSelectedValues] = useState<FileStatusType[]>(defaultValue);

  const handleOnChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
    const elValue = event.target.value as FileStatusType[];
    const selectedValues = elValue.length > 0
      ? elValue.join(",")
      : [FileStatusType.Open, FileStatusType.Inactive, FileStatusType.ReportPending].join(",");

    setSelectedValues(elValue);
    onChange(name, selectedValues);
  };

  const getRenderValue = (selected: unknown) => {
    const statuses = (selected as FileStatusType[]).map((s) => {
      if (s === FileStatusType.ReportPending) return "Reported/Pending";
      return capitalize(s);
    });

    return statuses.join(", ");
  };

  useEffect(() => {
    if (disabled) {
      setSelectedValues(defaultValue);
    }
  }, [disabled]);

  return (
    <FormControl size="small">
      <StyledSelect
        name={name}
        disableUnderline
        fullWidth
        disabled={disabled}
        multiple
        variant="outlined"
        value={selectedValues}
        onChange={handleOnChange}
        renderValue={getRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map((opt) => (
          <MenuItem
            key={opt.id}
            value={opt.id}
          >
            <StewartCheckbox checked={selectedValues.indexOf(opt.id) > -1} />
            <ListItemText primary={opt.label} />
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectStatus;
